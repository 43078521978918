import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { searchMetadataPopulateStatusChange } from './searchMetadataPopulateStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: string | null = null;

export const searchMetadataPopulateDataSlice = createSlice({
  name: 'searchMetadataPopulateData',
  initialState,
  reducers: {
    // action has a payload with string (could be an email address for populating search metadata for a specific user, 
    // or empty string if request is to populate search metadata for all users)
    searchMetadataPopulateRequest(state, action) {
      // set searchMetadataPopulate data into state for use during the searchMetadataPopulate process
      // return action.payload;

      // Hmmm. When action.payload was returned, an error occurred: "Error: A case reducer on a non-draftable value must not return undefined"
      return null;
    },

    searchMetadataPopulateDataClear(state) {
      return null; 
    },
  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(searchMetadataPopulateStatusChange, (state, action: PayloadAction<enumWorkflowState | null>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            // do nothing
            break;

          case enumWorkflowState.Failure:
          case enumWorkflowState.Success:
            // clear the searchMetadataPopulate data from state
            // state = null;
            break;

          default:
            // state = null;
            break;
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { searchMetadataPopulateRequest, searchMetadataPopulateDataClear } = searchMetadataPopulateDataSlice.actions;

// export the core reducer for the slice
export const searchMetadataPopulateDataReducer = searchMetadataPopulateDataSlice.reducer;

export default searchMetadataPopulateDataSlice.reducer;
