import { Auth, connectAuthEmulator, getAuth } from 'firebase/auth';
import { FirebaseApp } from 'firebase/app';
import { FirebaseAppSingleton } from '../../firebaseServices/cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { IFirebaseAuthAndUserAuthenticationStatus } from './hookReturnObjects/';
import { FirebaseEmulatorSettings } from '../../firebaseServices/cloudServices/googleFirebaseServices/FirebaseConfigurationVariables';

export function useFirebaseAuth(): IFirebaseAuthAndUserAuthenticationStatus {

  // call method to get the Firebase auth property
  const firebaseAuth: Auth = getFirebaseAuth();

  const userAuthenticated: boolean = firebaseAuth.currentUser !== null;


  const firebaseAuthAndUserAuthenticationStatus: IFirebaseAuthAndUserAuthenticationStatus = {
    auth: firebaseAuth,
    userAuthenticated
  }

  return firebaseAuthAndUserAuthenticationStatus;
}

function getFirebaseAuth(): Auth {

  // get the Firebase auth property from the FirebaseSingleton object
  const firebaseApp: FirebaseApp = FirebaseAppSingleton.getInstance().firebaseApp;
  const firebaseAuth: Auth = getAuth(firebaseApp);
  // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
  if (FirebaseEmulatorSettings.useEmulators) {
    if (FirebaseEmulatorSettings.authEmulatorPort) {
      connectAuthEmulator(firebaseAuth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
    }
  }

  return firebaseAuth;
}
