import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const cardGridViewItemStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  card: {
    border: theme.cardViewCard.border,
    background: theme.cardViewCard.background,
    boxShadow: theme.boxShadow,
    '&:hover': {
      background: theme.cardViewCard.hoverBackground,
      boxShadow: theme.hoverBoxShadow,
      transform: theme.cardViewCard.hoverTransform,
    }
  },
  cardTitle: {
    margin: theme.spacing(0.5, 0.5, -1, 0.5),
    color: theme.cardViewCard.titleColor
  },
  cardOccurrenceTimestamp: {
    color: theme.cardViewCard.occurrenceTimestampColor,
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
  cardDescription: {
    color: theme.cardViewCard.descriptionColor
  },
  cardOwnerSection: {
    margin: theme.spacing(1, 1, 1, 2),
    color: theme.cardViewCard.descriptionColor
  },
  digitalMediaObjectContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
  },
  digitalMediaObject: {
    // The following combination works in both desktop and mobile browsers, but height is a hardcoded guesstimate and may cause width of image to shrink
    maxWidth: '100%',
    maxHeight: '250px',
    objectFit: 'contain',  // 'contain' & 'scale-down' seem to work similarly
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  drillDownButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    marginTop: theme.spacing(1.5)
  },
  drillDownButton: {
    background: theme.cardViewCard.drillDownButton.background,
    color: theme.cardViewCard.drillDownButton.textColor,
    '&:hover': {
      background: theme.cardViewCard.drillDownButton.hoverBackground,
    },
  },
  documentIcon: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    border: theme.documentIcon.border,
    color: theme.documentIcon.textColor,
    '&:hover': {
      background: theme.documentIcon.hover.background,
      color: theme.documentIcon.hover.textColor,
    }
  },
  hyperLinkIcon: {
    marginLeft: theme.spacing(1),
    border: theme.hyperlinkIcon.border,
    color: theme.hyperlinkIcon.textColor,
    '&:hover': {
      background: theme.hyperlinkIcon.hover.background,
      color: theme.hyperlinkIcon.hover.textColor,
    }
  },
  cardActionsContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(-1.0),
  },
  actionIconButton: {
    borderRadius: theme.shape.borderRadius,
  },
  drillDownIconButton: {
    border: theme.cardViewCard.drillDownIconButton.border,
    background: theme.cardViewCard.drillDownIconButton.background,
    color: theme.cardViewCard.drillDownIconButton.iconColor,
    '&:hover': {
      border: theme.cardViewCard.drillDownIconButton.hoverBorder,
      background: theme.cardViewCard.drillDownIconButton.hoverBackground,
      color: theme.cardViewCard.drillDownIconButton.hoverIconColor,
    },
  },
  editIconButton: {
    border: theme.cardViewCard.editIconButton.border,
    background: theme.cardViewCard.editIconButton.background,
    color: theme.cardViewCard.editIconButton.iconColor,
    '&:hover': {
      border: theme.cardViewCard.editIconButton.hoverBorder,
      background: theme.cardViewCard.editIconButton.hoverBackground,
      color: theme.cardViewCard.editIconButton.hoverIconColor,
    },
  },
  deleteIconButton: {
    border: theme.cardViewCard.deleteIconButton.border,
    background: theme.cardViewCard.deleteIconButton.background,
    color: theme.cardViewCard.deleteIconButton.iconColor,
    '&:hover': {
      border: theme.cardViewCard.deleteIconButton.hoverBorder,
      background: theme.cardViewCard.deleteIconButton.hoverBackground,
      color: theme.cardViewCard.deleteIconButton.hoverIconColor,
    },
  },
  navigationIconButton: {
    marginLeft: theme.spacing(1),
    border: theme.cardViewCard.navigationIconButton.border,
    background: theme.cardViewCard.navigationIconButton.background,
    color: theme.cardViewCard.navigationIconButton.iconColor,
    '&:hover': {
      border: theme.cardViewCard.navigationIconButton.hoverBorder,
      background: theme.cardViewCard.navigationIconButton.hoverBackground,
      color: theme.cardViewCard.navigationIconButton.hoverIconColor,
    },
  },
  shareIconButton: {
    marginLeft: theme.spacing(1),
    border: theme.cardViewCard.shareIconButton.border,
    background: theme.cardViewCard.shareIconButton.background,
    color: theme.cardViewCard.shareIconButton.iconColor,
    '&:hover': {
      border: theme.cardViewCard.shareIconButton.hoverBorder,
      background: theme.cardViewCard.shareIconButton.hoverBackground,
      color: theme.cardViewCard.shareIconButton.hoverIconColor,
    },
  },
  reactPlayerContainer: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
    height: '250px',
    width: 'auto'
  },
  reactPlayer: {
    position: 'relative'
  },
  reactPlayerContainerForAudio: {
    margin: theme.spacing(2, 0),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10000px', // to give it completely rounded sides
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
    height: '3rem',
    width: 'auto'
  },
  reactPlayerForAudio: {
    height: '3rem',
    position: 'relative'
  },
}));