import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption, typeImageLinkType } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { IImageLink, IImageLinkAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { BaseMediaFileLink } from '../BaseMediaFileLink';

/** 
 * @class ImageLink represents a link to an image that can be attached to something
 */
export class ImageLink extends BaseMediaFileLink implements IImageLink {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {typeImageLinkType} imageLinkType The type of imageLink
   * @param {string} baseStoragePath The source Url (path & filename) that points to the media file
   * @param {string} downloadUrl A full Url for downloading the media file from the storage source that has security (Google Cloud Storage, AWS S3, etc.),
   *   or even a direct location (some file store)
   * @param {string} mediaType The type of media
   * @param {string} description A description of the media file
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    imageLinkType: typeImageLinkType,
    baseStoragePath: string,
    downloadUrl: string,
    mediaType: string,
    description: string,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.ImageLink, enumPersistableObjectType.ImageLink, id, parentObjectType, parentId, objectState, baseStoragePath, downloadUrl, mediaType, description, userPersistenceData);
    this.imageLinkType = imageLinkType;
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeImageLinkType} _imageLinkType The type of imageLink
   */
  private _imageLinkType: typeImageLinkType = undefined;

  /**
   * @method imageUrl Getter method for _imageLinkType
   */
  get imageLinkType() {
    return this._imageLinkType;
  }

  /**
   * @method imageLinkType Setter method for _imageLinkType
   * @param {typeImageLinkType} value The value for setting _imageLinkType
   */
  set imageLinkType(value) {
    this._imageLinkType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IImageLink} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IImageLink {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IImageLink = Object.create(ImageLink.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IImageLinkAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IImageLinkAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.imageLinkType = this.imageLinkType === undefined ? 'undefined' : this.imageLinkType;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IImageLinkAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IImageLinkAsJson, includeContainedObjects: boolean = true): IImageLink {
    try {
      // create a new instance of this class
      let imageLinkObject: ImageLink = Object.create(ImageLink.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      imageLinkObject = super.fromJSONProtected(imageLinkObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      imageLinkObject.imageLinkType = (jsonObject.imageLinkType === 'undefined' ? undefined : jsonObject.imageLinkType);

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return imageLinkObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/


}
