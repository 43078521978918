import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchTopicsStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchTopicsStatusSlice = createSlice({
  name: 'fetchTopicsStatus',
  initialState,
  reducers: {
    setFetchTopicsStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchTopicsStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchTopicsStatus, clearFetchTopicsStatus } = fetchTopicsStatusSlice.actions;

// export the core reducer for the slice
export const fetchTopicsStatusReducer = fetchTopicsStatusSlice.reducer;

export default fetchTopicsStatusSlice.reducer;