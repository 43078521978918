import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  // CardHeader,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  List as ListIcon,
} from '@mui/icons-material';
import { ITopic } from '../../../../dataObjects/models/topics/Topic';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import { IUserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { setCurrentTopic } from "../../../../uiMiddleware-redux/slices/topic/currentTopicSlice";
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';

// The next line is preserved for update to React 18, once peer dependencies will allow the update
// export interface ITopicsCardGridViewItemProps extends PropsWithChildren<unknown> {
export interface ITopicsCardGridViewItemProps {
  topic: ITopic;
  userPermissions: IUserAccessPermissionsForObject;
  onDelete: (topic: ITopic) => void;
}


export const TopicsCardGridViewItem: React.FC<ITopicsCardGridViewItemProps> = (props: ITopicsCardGridViewItemProps) => {

  const { onDelete, topic, userPermissions } = props;
  const { id, name, description } = topic;

  const classes = cardGridViewItemStyles();

  const dispatch = useAppDispatch();

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // memoized function that handles click for drilldown to topicItems
  const handleTopicItemsDrilldownClicked: () => void = useCallback((): void => {
    // first, dispatch a request to set the current Topic in Redux state to be the one passed to this component
    dispatch(setCurrentTopic(topic));

    // then, use the navigate object to navigate to the TopicItems page
    navigate(`/${id}/topicItems`)
  },
    [dispatch, navigate, id, topic],
  );

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {name}
        </Typography>

        <CardContent>
          <Typography className={classes.cardDescription} variant="body1">
            {description}
          </Typography>

          <div className={classes.drillDownButtonContainer}>
            <Tooltip
              // the tooltip for the drilldown button will either be "Drill down" (if user has sufficient permission) or "Drill down disallowed" (insufficient permission)
              title={!userPermissions.hasViewPermission ? TooltipStringAssets.drillDown_Disallowed : TooltipStringAssets.drillDown}
              arrow
            >
              <span>
                <Button
                  // className={classes.drillDownIconButton}
                  className={classes.drillDownButton}
                  aria-label="topic Items"
                  variant='contained'
                  // color='primary'
                  size='medium'
                  startIcon={<ListIcon />}
                  disabled={!userPermissions.hasViewPermission}
                  onClick={() => handleTopicItemsDrilldownClicked()}
                >
                  {`View ${topic.topicItemNameAliasPlural}`}
                </Button>
              </span>
            </Tooltip>
          </div>
        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
            title={!userPermissions.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!userPermissions.hasEditPermission}
                onClick={() => navigate(`/topic/${id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>

          <Tooltip
            // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
            title={!userPermissions.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                disabled={!userPermissions.hasEditPermission}
                onClick={() => topic && onDelete(topic)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}