import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const mediaFileUploadListItemStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  // tableRow: {
  //   paddingLeft: theme.spacing(1),
  //   paddingBottom: theme.spacing(0.5),
  //   '&:nth-child(even)': {
  //     background: theme.palette.mediaFileViewTable.detailRow.evenRowBackground,
  //     border: theme.palette.mediaFileViewTable.detailRow.evenRowBorder,
  //     '&:hover': {
  //       background: theme.palette.mediaFileViewTable.detailRow.evenRowHoverBackground,
  //       border: theme.palette.mediaFileViewTable.detailRow.evenRowHoverBorder,
  //     },
  //   },
  //   '&:nth-child(odd)': {
  //     background: theme.palette.mediaFileViewTable.detailRow.oddRowBackground,
  //     border: theme.palette.mediaFileViewTable.detailRow.oddRowBorder,
  //     '&:hover': {
  //       background: theme.palette.mediaFileViewTable.detailRow.oddRowHoverBackground,
  //       border: theme.palette.mediaFileViewTable.detailRow.oddRowHoverBorder,
  //     },
  //   },
  // },
  // mediaIcon: {
  //   marginTop: theme.spacing(1),
  //   color: theme.palette.primary.main,
  // },
  // name: {
  //   margin: theme.spacing(0.5, 1, -1, 0),
  //   color: theme.palette.mediaFileViewTable.detailRow.titleColor
  // },
  // size: {
  //   margin: theme.spacing(0.5, 1, -1, 0),
  //   color: theme.palette.mediaFileViewTable.detailRow.titleColor
  // },
  // actionButton: {
  //   borderRadius: theme.shape.borderRadius,
  //   marginTop: theme.spacing(0.5),
  // },
  // deleteIconButton: {
  //   border: theme.palette.mediaFileViewTable.detailRow.deleteIconButton.border,
  //   background: theme.palette.mediaFileViewTable.detailRow.deleteIconButton.background,
  //   color: theme.palette.mediaFileViewTable.detailRow.deleteIconButton.iconColor,
  //   '&:hover': {
  //     border: theme.palette.mediaFileViewTable.detailRow.deleteIconButton.hoverBorder,
  //     background: theme.palette.mediaFileViewTable.detailRow.deleteIconButton.hoverBackground,
  //     color: theme.palette.mediaFileViewTable.detailRow.deleteIconButton.hoverIconColor,
  //   },
  // },
}));