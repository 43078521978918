import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const channelsFetchFailureSlice = createSlice({
  name: 'channelsFetchFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    channelsFetchFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    channelsFetchFailureClear(state, action) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { channelsFetchFailureChange, channelsFetchFailureClear } = channelsFetchFailureSlice.actions;

// export the core reducer for the slice
export const channelsFetchFailureReducer = channelsFetchFailureSlice.reducer;

export default channelsFetchFailureSlice.reducer;