import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ISaveDocumentLinkRequest } from '../../../dataObjects/models/serviceRequests/documentLink/SaveDocumentLinkRequest';
import { ISaveDocumentLinkWorkflowState } from '../../store/SaveDocumentWorkflowState';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions';
import { IDocumentLink } from '../../../dataObjects/models/digitalMedia/DocumentLink';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: enumWorkflowState | null = null;

export const documentLinkSaveStatusSlice = createSlice({
  name: 'documentLinkSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    documentLinkSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    documentLinkSaveWithoutFileUploadRequest: (state, action: PayloadAction<ISaveDocumentLinkRequest>) => {
      const saveDocumentLinkRequest: ISaveDocumentLinkRequest = action.payload;
      const newState: ISaveDocumentLinkWorkflowState = {
        documentLink: saveDocumentLinkRequest.documentLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.documentLink_SaveRequested,
        fileUploadRequest: undefined
      }

      // call internal base function to set the newState into the IStoreState variable
      documentLinkSaveWorkflowStateChange(newState);
    },
    documentLinkSaveWithFileUploadRequest: (state, action: PayloadAction<ISaveDocumentLinkRequest>) => {
      const saveDocumentLinkRequest: ISaveDocumentLinkRequest = action.payload;
      const newState: ISaveDocumentLinkWorkflowState = {
        documentLink: saveDocumentLinkRequest.documentLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.documentLink_SaveRequested,
        fileUploadRequest: saveDocumentLinkRequest.fileUploadRequest
      }

      // call internal base function to set the newState into the IStoreState variable
      documentLinkSaveWorkflowStateChange(newState);
    },
    documentLinkFileUploadRequest: (state, action: PayloadAction<IFileUploadRequest>) => {
      // we can only add a file upload request if the state variable in IStoreState has been set to an object value

      if (state) {
        const newState: ISaveDocumentLinkWorkflowState = {
          ...(state as ISaveDocumentLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.documentLink_UploadingDocument,
          fileUploadRequest: action.payload
        }

        // call internal base function to set the newState into the IStoreState variable
        documentLinkSaveWorkflowStateChange(newState);
      }
    },
    documentLinkFileUploadProgressUpdate: (state, action: PayloadAction<IFileUploadProgress>) => {
      // we can only add a file upload progress update if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadProgress: IFileUploadProgress = action.payload;
        const workflowStateMessage = `File upload is ${fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% complete`;

        const newState: ISaveDocumentLinkWorkflowState = {
          ...(state as ISaveDocumentLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadProgress: fileUploadProgress
        }

        // call internal base function to set the newState into the IStoreState variable
        documentLinkSaveWorkflowStateChange(newState);
      }
    },
    documentLinkFileUploadResult: (state, action: PayloadAction<ICloudStorageFileUploadResult>) => {
      // we can only add a file upload result if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadResult: ICloudStorageFileUploadResult = action.payload;
        const fileUploadTimeInSeconds: number = fileUploadResult.totalMilliseconds / 1000;
        const workflowStateMessage = `File (${fileUploadResult.file.name}) was successfully uploaded in ${fileUploadTimeInSeconds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} seconds`;

        const newState: ISaveDocumentLinkWorkflowState = {
          ...(state as ISaveDocumentLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadResult: fileUploadResult,
        }

        // call internal base function to set the newState into the IStoreState variable
        documentLinkSaveWorkflowStateChange(newState);
      }
    },
    documentLinkFileUploadVerify: (state) => {
      // we can only add a file upload verification if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.documentLink_FileUploadVerifying;

        const newState: ISaveDocumentLinkWorkflowState = {
          ...(state as ISaveDocumentLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        documentLinkSaveWorkflowStateChange(newState);
      }
    },
    documentLinkSaveSuccess: (state, action: PayloadAction<IDocumentLink>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.documentLink_SaveSuccess;

        const newState: ISaveDocumentLinkWorkflowState = {
          ...(state as ISaveDocumentLinkWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        documentLinkSaveWorkflowStateChange(newState);
      }
    },
    documentLinkSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.documentLink_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveDocumentLinkWorkflowState = {
          ...(state as ISaveDocumentLinkWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        documentLinkSaveWorkflowStateChange(newState);
      }
    },
    documentLinkSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      documentLinkSaveWorkflowStateChange(null);
    },

  },
})

// Action creators are generated for each function in reducer)
export const {
  documentLinkSaveWorkflowStateChange,
  documentLinkSaveWithoutFileUploadRequest,
  documentLinkSaveWithFileUploadRequest,
  documentLinkFileUploadRequest,
  documentLinkFileUploadProgressUpdate,
  documentLinkFileUploadResult,
  documentLinkFileUploadVerify,
  documentLinkSaveSuccess,
  documentLinkSaveError,
  documentLinkSaveStatusClear,
} = documentLinkSaveStatusSlice.actions;

// export the core reducer for the slice
export const documentLinkSaveStatusReducer = documentLinkSaveStatusSlice.reducer;

export default documentLinkSaveStatusSlice.reducer;