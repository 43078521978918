import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { CategoriesCardGridViewItem } from './CategoriesCardGridViewItem';


export interface ICategoriesCardGridViewProps extends PropsWithChildren<unknown> {
  categories: Array<ICategory>;
  onDelete: (category: ICategory) => void;
}


export const CategoriesCardGridView: React.FC<ICategoriesCardGridViewProps> = (props: ICategoriesCardGridViewProps) => {
  CategoriesCardGridView.displayName = 'Categories Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render CategoriesList`);

  const { categories } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          categories.map(category =>
            <Grid item key={category.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <CategoriesCardGridViewItem category={category} onDelete={props.onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}