import { typeUniqueId } from "../../../../dataObjects/types"
import { IThemeSpecs, IThemeSpecsAsJson } from "../../../../dataObjects/models/themes/ThemeSpecs";
import { IFirestoreThemeSpecsRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreThemeSpecsRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";


export function getUserDefinedThemeSpecsUsingId(themeSpecsId: typeUniqueId): Promise<IThemeSpecs | undefined> {
  return new Promise<IThemeSpecs | undefined>(async (resolve, reject) => {
    try {
      // get a ThemeSpecs repository from the repository factory
      const firestoreThemeSpecsRepository: IFirestoreThemeSpecsRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ThemeSpecs) as
        IFirestoreBaseRepository<IThemeSpecs, IThemeSpecsAsJson>;

      // make request of the repository to get the ThemeSpecs object associated with the Id
      let themeSpecs: IThemeSpecs | undefined = await firestoreThemeSpecsRepository.get(themeSpecsId);

      resolve(themeSpecs);
    } catch (error: any) {
      reject(error);
    }
  });
}