import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import type { PayloadAction } from '@reduxjs/toolkit';
import { Beacon, IBeacon, IBeaconAsJson } from '../../../dataObjects/models/alerts/Beacon';
import { enumBeaconType } from '../../../dataObjects/enums';
import { MessagesStringAssets, NotificationStringAssets } from '../../../assets/stringAssets';

export interface IBeaconState {
  value: IBeacon | null,
}

// const initialState: IBeaconState = {
//   value: undefined,
// }
// const initialState: string | IBeacon | IBeaconAsJson | null = "Initial State"; // null;
// const beacon: IBeacon = new Beacon(undefined, enumBeaconType.Success, 'Initial State', 'Message for initial state');
// const initialState: string | IBeacon | IBeaconAsJson | null = beacon;
const initialState: IBeacon | null = null;
// const beaconAsJson: IBeaconAsJson = beacon.toJSON(true);
// const initialState: string | IBeacon | IBeaconAsJson | null = beaconAsJson;

export const beaconSlice = createSlice({
  name: 'beacon',
  initialState,
  reducers: {
    // action has a payload with the new Beacon object, or null
    beaconChange(state, action) {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { beaconChange } = beaconSlice.actions;

// export the core reducer for the slice
export const beaconReducer = beaconSlice.reducer;

export default beaconSlice.reducer;
