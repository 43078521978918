import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { listViewItemStyles } from '../../../styles';
import { IChannelSharingUser } from '../../../../dataObjects/viewModels/channelSharing/ChannelSharingUser';
import { KeyValuePairsStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingUserListViewItemProps extends PropsWithChildren<unknown> {
  channelSharingUser: IChannelSharingUser;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (user: IChannelSharingUser) => void;
}

export const ChannelSharingUserListViewItem: React.FC<IChannelSharingUserListViewItemProps> = (props: IChannelSharingUserListViewItemProps) => {
  ChannelSharingUserListViewItem.displayName = 'Channel Sharing User List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render ChannelSharingUsersListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { channelSharingUser, currentUserChannelSharingViewModel, onRevoke } = props;
  const { userId, userEmail, userFirstName, userLastName } = channelSharingUser;

  // will hold setting indicating whether the currentUser has Admin privilege to the channel
  const [currentUserHasAdminAccessToChannel, setCurrentUserHasAdminAccessToChannel] = useState<boolean>(false);

  // define an effect that will set whether the currentUser has Admin privilege to the channel
  useEffect(() => {
    setCurrentUserHasAdminAccessToChannel(currentUserChannelSharingViewModel.currentUserChannelSharingPermission === enumSharingPermission.Admin);
  }, [currentUserChannelSharingViewModel]);


  // will indicate whether to enable or disable the Edit and Revoke buttons (start with least privileges)
  const [enableEditButton, setEnableEditButton] = useState<boolean>(false);
  const [enableRevokeButton, setEnableRevokeButton] = useState<boolean>(false);

  // will hold the tooltips for the Edit and Revoke buttons
  const [editButtonTooltipToDisplay, setEditButtonTooltipToDisplay] = useState<string>(TooltipStringAssets.channelSharingUser_EditSharing);
  const [revokeButtonTooltipToDisplay, setRevokeButtonTooltipToDisplay] = useState<string>(TooltipStringAssets.channelSharingUser_RevokeSharing);

  // define an effect that will respond to changes for whether the user has Admin access to the channel, allowing
  // use to set up tooltip messages for Edit and Revoke buttons
  useEffect(() => {
    // // we need a defined currentUser
    // assume buttons will be disabled
    let enableButtons: boolean = false;

    // start with default messages that indicate the user has permission to Edit and Revoke
    let editButtonTooltip: string = TooltipStringAssets.channelSharingUser_EditSharing;
    let revokeButtonTooltip: string = TooltipStringAssets.channelSharingUser_RevokeSharing;

    // if the ChannelSharingUser is the same as the currentUser, change the tooltips
    if (currentUserChannelSharingViewModel.currentUser.id === channelSharingUser.userId) {
      editButtonTooltip = TooltipStringAssets.channelSharingUser_EditDisallowedUserOwnsChannel;
      revokeButtonTooltip = TooltipStringAssets.channelSharingUser_RevokeDisallowedUserOwnsChannel;
    } else if (!currentUserHasAdminAccessToChannel) {
      // otherwise, if the user doesn't have Admin permission, change the tooltips
      editButtonTooltip = TooltipStringAssets.channelSharingUser_EditDisallowedForInsufficientPermissions;
      revokeButtonTooltip = TooltipStringAssets.channelSharingRequest_RevokeDisallowedForInsufficientPermissions;
    } else {
      enableButtons = true;
    }

    setEditButtonTooltipToDisplay(editButtonTooltip);
    setRevokeButtonTooltipToDisplay(revokeButtonTooltip);

    setEnableEditButton(enableButtons);
    setEnableRevokeButton(enableButtons);

  }, [currentUserHasAdminAccessToChannel]);


  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.tableRow} key={userId}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* User Name */}
          <Grid item container xs={8} sm={10} >
            <Typography className={classes.title} variant="h6">
              {`${userLastName}, ${userFirstName}`}
            </Typography>
          </Grid>

          {/* Action Buttons */}
          <Grid item container xs={4} sm={2} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Edit button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit sharing" (if it's not the owner of the channel) or "Edit disallowed" 
                // (if it IS the owner of the channel)
                title={editButtonTooltipToDisplay}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    // this button will be disabled if the displayed user is the owner of the channel
                    disabled={!enableEditButton}
                    onClick={() => navigate(`/channelSharingUser/${channelSharingUser.channelId}/${channelSharingUser.userId}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              <Tooltip
                // the tooltip for the revoke button will either be "Revoke sharing" (if it's not the owner of the channel) or "Revoke disallowed" 
                // (if it IS the owner of the channel)
                title={revokeButtonTooltipToDisplay}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    // this button will be disabled if the displayed user is the owner of the channel
                    disabled={!enableRevokeButton}
                    onClick={() => onRevoke(channelSharingUser)}
                  >
                    <ClearIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Email Address and (Permission)  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {`${userEmail} (${KeyValuePairsStringAssets.getValueFromKey(KeyValuePairsStringAssets.sharingPermissionKeyValuePairs, channelSharingUser.permission)})`}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
      {/* </span> */}
    </>
  );

}

export default ChannelSharingUserListViewItem;