import { createContext } from 'react';
import { enumAuthenticationStatus } from '../../enums';
import { ICurrentUserContextData } from './';

/**
 * @context CurrentUserContext A React Context that can be used to provide a React component tree with information for the
 *   current user. This context provides an ICurrentUserContextData that indicates whether a user has been logged in and
 *   the data associated with the user (the currentUser value can be undefined if data is being fetched from the database)
 */
export const CurrentUserContext: React.Context<ICurrentUserContextData> = 
    createContext<ICurrentUserContextData>({authenticationStatus: enumAuthenticationStatus.None, currentUser: undefined});
