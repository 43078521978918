/**
 * @enum {enumVersionedObjectTrackingOperationType} Represents the different possible operations that can be 
 * performed for a Version-Aware Persistable (VAP) type of object
 */
export enum enumVersionedObjectTrackingOperationType {
  /**
   * The operation was not defined (to be used as a default for version tracking operation)
   */
  Undefined = 'Undefined',
  /**
   * Version tracking was initiated on the object
   */
  Initiated = 'Initiated',
  /**
   * A modified version of the object was added to tracking
   */
  Modified = 'Modified',
  /**
   * The object was deleted (soft-deleted). An Undelete operation can still be performed.
   */
  Deleted = 'Deleted',
  /**
   * The object was undeleted, meaning that updated versions can be applied again
   */
  Undeleted = 'Undeleted',
  /**
   * The object was expunged (completely erased), the last state for object versioning.
   */
  Expunged = 'Expunged',
}
