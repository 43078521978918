import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface ITennisRacketWilsonBladeParms {
  overallClassName: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  stringMain: {
    fill: 'none',
    strokeMiterlimit: 10,
    stroke: '#3f3f3f',
    strokeWidth: '1px',
  },

  stringCross: {
    fill: 'none',
    strokeMiterlimit: 10,
    stroke: '#3f3f3f',
    strokeWidth: '1px',
  },

  overGrip: {
    fill: '#767ebb',
  },

  gripTape: {
    fill: '#e13743',
  },

  buttCap: {
    stroke: '#e1371b',
    strokeWidth: '45px',
    fill: 'none',
    strokeMiterlimit: 10,
  },

  racketThroat: {
    fill: 'none',
    strokeMiterlimit: 10,
    stroke: '#000',
    strokeWidth: '31.72px',
    fillRule: 'evenodd',
  },

  racketSideHighlight: {
    fill: 'none',
    strokeMiterlimit: 10,
    strokeWidth: '30.5px',
    stroke: '#505050',
  },

  racketTopHighlight: {
    fill: 'none',
    strokeMiterlimit: 10,
    stroke: '#50ff50',
    strokeWidth: '13.99px',
  },

}));


// const TennisRacketWilsonBlade: React.FC<ITennisRacketWilsonBladeParms> = (props: ITennisRacketWilsonBladeParms) => {

// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
// const TennisRacketWilsonBlade: React.ForwardRefRenderFunction<SVGSVGElement, ITennisRacketWilsonBladeParms> = React.forwardRef((props: ITennisRacketWilsonBladeParms, ref?: React.Ref<SVGSVGElement>) => {
const TennisRacketWilsonBlade = React.forwardRef((props: ITennisRacketWilsonBladeParms, ref?: React.Ref<SVGSVGElement>) => {

  const { overallClassName } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={overallClassName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 747.9 1965.75" >
      <defs>
      </defs>

      <g id="layerStrings">
        <g id="groupStringsMain">
          <line id="stringMain18" className={classes.stringMain} x1="654.3" y1="205.05" x2="654.3" y2="795.45" />
          <line id="stringMain17" className={classes.stringMain} x1="610.88" y1="142.57" x2="610.88" y2="862.57" />
          <line id="stringMain16" className={classes.stringMain} x1="575.88" y1="104.75" x2="575.88" y2="896.75" />
          <line id="stringMain15" className={classes.stringMain} x1="541.83" y1="81.58" x2="541.83" y2="916.78" />
          <line id="stringMain14" className={classes.stringMain} x1="510.5" y1="58.79" x2="510.5" y2="937.19" />
          <line id="stringMain13" className={classes.stringMain} x1="479.55" y1="43.59" x2="479.55" y2="954.39" />
          <line id="stringMain12" className={classes.stringMain} x1="448.98" y1="35.42" x2="448.98" y2="957.02" />
          <line id="stringMain11" className={classes.stringMain} x1="419.5" y1="31.38" x2="419.5" y2="960.18" />
          <line id="stringMain10" className={classes.stringMain} x1="389.23" y1="24.81" x2="389.23" y2="967.63" />
          <line id="stringMain09" className={classes.stringMain} x1="357.77" y1="24.37" x2="357.77" y2="967.19" />
          <line id="stringMain08" className={classes.stringMain} x1="326.5" y1="31.38" x2="326.5" y2="960.18" />
          <line id="stringMain07" className={classes.stringMain} x1="294.6" y1="34.98" x2="294.6" y2="956.58" />
          <line id="stringMain06" className={classes.stringMain} x1="263.64" y1="47.59" x2="263.64" y2="958.39" />
          <line id="stringMain05" className={classes.stringMain} x1="231.07" y1="60.98" x2="231.07" y2="939.38" />
          <line id="stringMain04" className={classes.stringMain} x1="197.6" y1="81.39" x2="197.6" y2="916.59" />
          <line id="stringMain03" className={classes.stringMain} x1="161.26" y1="99.78" x2="161.26" y2="891.78" />
          <line id="stringMain02" className={classes.stringMain} x1="126.48" y1="142.99" x2="126.48" y2="862.99" />
          <line id="stringMain01" className={classes.stringMain} x1="91.93" y1="216.46" x2="91.93" y2="806.86" />
        </g>
        <g id="groupStringsCross">
          <line id="stringCross20" className={classes.stringCross} x1="120.47" y1="828.24" x2="624.47" y2="828.24" />
          <line id="stringCross19" className={classes.stringCross} x1="82.93" y1="777.64" x2="666.13" y2="777.64" />
          <line id="stringCross18" className={classes.stringCross} x1="52.7" y1="734.34" x2="693.5" y2="734.34" />
          <line id="stringCross17" className={classes.stringCross} x1="52.07" y1="696.03" x2="692.87" y2="696.03" />
          <line id="stringCross16" className={classes.stringCross} x1="46.93" y1="657.73" x2="702.13" y2="657.73" />
          <line id="stringCross15" className={classes.stringCross} x1="37.67" y1="619.43" x2="707.27" y2="619.43" />
          <line id="stringCross14" className={classes.stringCross} x1="28.93" y1="579.67" x2="720.13" y2="579.67" />
          <line id="stringCross13" className={classes.stringCross} x1="26.87" y1="539.91" x2="718.07" y2="539.91" />
          <line id="stringCross12" className={classes.stringCross} x1="24.3" y1="503.21" x2="722.7" y2="503.21" />
          <line id="stringCross11" className={classes.stringCross} x1="24.3" y1="466.49" x2="722.7" y2="466.49" />
          <line id="stringCross10" className={classes.stringCross} x1="24.3" y1="429.76" x2="722.7" y2="429.76" />
          <line id="stringCross09" className={classes.stringCross} x1="27.9" y1="393.04" x2="719.1" y2="393.04" />
          <line id="stringCross08" className={classes.stringCross} x1="38.7" y1="358.33" x2="708.3" y2="358.33" />
          <line id="stringCross07" className={classes.stringCross} x1="45.9" y1="322.62" x2="701.1" y2="322.62" />
          <line id="stringCross06" className={classes.stringCross} x1="63.9" y1="289.29" x2="683.1" y2="289.29" />
          <line id="stringCross05" className={classes.stringCross} x1="74.7" y1="259.58" x2="672.3" y2="259.58" />
          <line id="stringCross04" className={classes.stringCross} x1="92.7" y1="229.76" x2="654.3" y2="229.76" />
          <line id="stringCross03" className={classes.stringCross} x1="110.4" y1="194.7" x2="636.6" y2="194.7" />
          <line id="stringCross02" className={classes.stringCross} x1="123.81" y1="152.48" x2="623.19" y2="152.48" />
          <line id="stringCross01" className={classes.stringCross} x1="171.12" y1="107.39" x2="575.88" y2="107.39" />
        </g>
      </g>
      <g id="layerTennisRacket">
        <g id="racket">
          <g id="racketHandle">
            <rect id="handleShaft" className={classes.overGrip} x="328.5" y="1432" width="90" height="531" />
            <rect id="butt" className={classes.overGrip} x="319.5" y="1918.5" width="108" height="45" />
            <rect id="buttBevel" className={classes.overGrip} x="319.5" y="1901" width="108" height="36" rx="18" />
            <line id="buttCap" className={classes.buttCap} x1="319.5" y1="1963.5" x2="427.5" y2="1963.5" />
            <rect id="gripTape" className={classes.gripTape} x="327.47" y="1432" width="90" height="27" />
          </g>
          <g id="racketThroat">
            <rect id="racketThroatBase" x="333" y="1360" width="81" height="72" />
            <g id="racketThroatSides">
              <path id="racketThroatRight" className={classes.racketThroat}
                d="M665.39,820.8c-72.63,96.65-154.82,231.71-209.76,405.72a1331.13,1331.13,0,0,0-39,155.88"
                transform="translate(-18 -17)" />
              <path id="racketThroatLeft" className={classes.racketThroat}
                d="M117.64,820.8c72.63,96.65,154.82,231.71,209.76,405.72a1331.13,1331.13,0,0,1,39,155.88"
                transform="translate(-18 -17)" />
            </g>
          </g>
          <g id="racketHeadAndHighlights">
            <g id="racketHead">
              <path
                d="M391.5,48.5c45,0,88.7,11.73,130,34.88,40.65,22.78,77.32,55.61,109,97.58,32.16,42.61,57.45,92.39,75.18,147.94C724.13,386.83,733.5,448.43,733.5,512s-9.37,125.17-27.86,183.1c-17.73,55.55-43,105.33-75.18,147.94-31.66,42-68.33,74.8-109,97.58-41.28,23.15-85,34.88-130,34.88s-88.7-11.73-130-34.88C220.87,917.84,184.2,885,152.54,843c-32.16-42.61-57.45-92.39-75.18-147.94C58.87,637.17,49.5,575.57,49.5,512s9.37-125.17,27.86-183.1c17.73-55.55,43-105.33,75.18-147.94,31.66-42,68.33-74.8,109-97.58,41.28-23.15,85-34.88,130-34.88m0-31.5C185.22,17,18,238.62,18,512s167.22,495,373.5,495S765,785.38,765,512,597.78,17,391.5,17Z"
                transform="translate(-18 -17)" />
            </g>
            <g id="racketSideHighlights">
              <path id="racketSideHighlightRight" className={classes.racketSideHighlight}
                d="M693.17,256.17a595.57,595.57,0,0,1,57.19,240.54,597.42,597.42,0,0,1-45.52,244.21"
                transform="translate(-18 -17)" />
              <path id="racketSideHighlightLeft" className={classes.racketSideHighlight}
                d="M90.11,256.17A608.16,608.16,0,0,0,78.66,740.92" transform="translate(-18 -17)" />
            </g>
            <path id="racketTopHighlight" className={classes.racketTopHighlight}
              d="M98.45,257.18c18.74-39.3,81.09-157.39,205-200.68,44-15.39,80.15-15.83,87.68-15.85,97.15-.26,166,56.41,184.36,71.49,21,17.25,35.22,33.64,45,45a434.84,434.84,0,0,1,65,101"
              transform="translate(-18 -17)" />
          </g>
        </g>
      </g>

    </svg >
  )
});

export default TennisRacketWilsonBlade;
