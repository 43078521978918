import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumDigitalMediaType } from '../../../dataObjects/enums';

const initialState: enumDigitalMediaType | null = enumDigitalMediaType.Image;

export const selectedDigitalMediaTypeSlice = createSlice({
  name: 'selectedDigitalMediaType',
  initialState,
  reducers: {
    setSelectedDigitalMediaType(state, action) {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSelectedDigitalMediaType } = selectedDigitalMediaTypeSlice.actions;

// export the core reducer for the slice
export const selectedDigitalMediaTypeReducer = selectedDigitalMediaTypeSlice.reducer;

export default selectedDigitalMediaTypeSlice.reducer;
