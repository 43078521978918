/**
 * @enum enumSharingPermission Represents different types of permissions for collaboration sharing.
 * (Note: Try to keep the enumerations in alphabetical order to simplify viewing.)
 */
export enum enumSharingPermission {
  Admin = 'Admin',  // Adminstrator privilege (can view objects, edit objects, and manage collaborators)
  EditContent = 'EditContent',  // Edit priviege (can view edit content)
  None = 'None', // There is NO privilege granted
  ViewContent = 'ViewContent',  // ViewContent (can only view content)
}
