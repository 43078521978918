import { take } from "redux-saga/effects";
import { saveImageLinkWithFileUploadWorkerSaga } from "./saveImageLinkWithFileUploadWorkerSaga";
import { imageLinkSaveWithFileUploadRequest } from "../../slices/imageLink/imageLinkSaveStatusSlice";

/**
 * @function saveImageLinkWithFileUploadWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an ImageLink along with a file upload associated with it.
 */
export function* saveImageLinkWithFileUploadWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the imageLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveImageLinkRequestData = yield take(imageLinkSaveWithFileUploadRequest);
    const { payload: saveImageLinkRequest } = saveImageLinkRequestData;

    yield saveImageLinkWithFileUploadWorkerSaga(saveImageLinkRequest);
  }
}