import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlHasAValidImageFileExtension Returns whether the URL has a valid extension for a supported image file
 * @param url
 */
export const urlHasAValidImageFileExtension: (url: string) => boolean = (url: string) => {

  // get the regular expression patter for a valid image url
  const regEx: RegExp = RegularExpressions.ValidImageUrl;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
