import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumNoParentObjectType, enumObjectPersistenceState, enumPersistableObjectType, enumSharingPermission } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { PageAndViewTitleStringAssets, MessagesStringAssets } from '../../../../assets/stringAssets';
import ThemeSpecsForm from '../../../forms/ThemeSpecsForm/ThemeSpecsForm';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IThemeSpecs, ThemeSpecs } from '../../../../dataObjects/models/themes/ThemeSpecs';
import { createDefaultThemeSpecsViewModel, IAppThemesContextData, useAppThemesContext } from '../../../providersAndContexts/themes';
import { IThemeSpecsViewModel } from '../../../../dataObjects/viewModels/themeSpecsViewModel';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { themeSpecsSaveRequest, themeSpecsSaveStatusChange } from '../../../../uiMiddleware-redux/slices/themeSpecs/themeSpecsSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/**
 * @interface IManageCustomThemePageProps Input properties for the ManageCustomThemePage
 */
export interface IManageCustomThemePageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId  // if the Id is given, it means that the ThemeSpecs already exists
}

// const ManageCustomThemePageNew: React.FC<IManageCustomThemePageProps> = (props: IManageCustomThemePageProps) => {
const ManageCustomThemePage: React.FC<IManageCustomThemePageProps> = (props: IManageCustomThemePageProps) => {

  ManageCustomThemePage.displayName = "Manage Custom Theme Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const params = useParams();

  displayConsoleLogs && console.log(`ManageCustomThemePage. Entering component. params.id. params.id value: ${params.id}`);

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [themeSpecsId, setThemeSpecsId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the themeSpecsId anytime the params.id changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageCustomThemePage. Entered useEffect() for [themeSpecsId, params.id]. params.id value: ${params.id}`);
    if (themeSpecsId !== params.id) {
      displayConsoleLogs && console.log(`ManageCustomThemePage. In useEffect() for [themeSpecsId, params.id]. params.id value: ${params.id}. Ready to call setThemeSpecsId()`);
      setThemeSpecsId(params.id);
    }

    return () => {
      // process for 'unmounting'
      displayConsoleLogs && console.log(`ManageCustomThemePage. In 'unmounting' area of useEffect() for [themeSpecsId, params.id]`);
    }

  }, [themeSpecsId, params.id]);

  // will be set to the ThemeSpecs to be managed, either a new ThemeSpecs or an existing one
  const [themeSpecs, setThemeSpecs] = useState<IThemeSpecs | undefined>(undefined);

  // whether we are going to be managing (creating) a new ThemeSpecs
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveThemeSpecsStatus: () => void = useCallback(() => {
    dispatch(themeSpecsSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveThemeSpecsStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveThemeSpecsStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveThemeSpecsStatus]);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  const currentUser: IUser | undefined = currentUserContextData.currentUser;

  // use a custom hook to get the App Themes information from the AppThemesProvider
  const appThemesContextData: IAppThemesContextData = useAppThemesContext();

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveThemeSpecsStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveThemeSpecsFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.themeSpecs_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveThemeSpecs,
    notificationSuccessMessage: MessagesStringAssets.themeSpecs_SaveSuccess,
  }


  // if there is defined themeSpecs, append the themeSpecs name to the message for success
  if (themeSpecs !== undefined) {
    displayConsoleLogs && console.log(`ManageCustomThemePage. 'themeSpecs' IS defined. name: ${themeSpecs.name}. params.id: ${params.id}`);
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.themeSpecs_SaveSuccess} (${themeSpecs.name})`;
  } else {
    displayConsoleLogs && console.log(`ManageCustomThemePage. 'themeSpecs' is NOT yet defined. params.id: ${params.id}`);
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewThemeSpecs Prepares/creates a new ThemeSpecs that will be used if user is requesting to create/add a new ThemeSpecs.
   * @param  
   */
  const prepareNewThemeSpecs: (user: IUser, userSettings: IUserSettings) => IThemeSpecs = useCallback(
    (user: IUser, userSettings: IUserSettings) => {
      // we will use the default themeSpecs as the new themeSpecs
      let defaultThemeSpecsViewModel: IThemeSpecsViewModel = appThemesContextData.state.defaultThemeSpecsViewModel ?? createDefaultThemeSpecsViewModel();

      let newThemeSpecs: IThemeSpecs;

      const newThemeSpecsId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newThemeSpecs = new ThemeSpecs(user.id, newThemeSpecsId, enumNoParentObjectType.NoParent, undefined, enumObjectPersistenceState.New,
        '', defaultThemeSpecsViewModel.themeBaseColors, undefined, userPersistenceData);

      return newThemeSpecs;
    }, []
  );

  // define an effect based on a change to the currentUser, themeSpecsId, or prepareNewThemeSpecs. 
  // Having a defined themeSpecsId means that we will be editing an existing ThemeSpecs
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageCustomThemePage. Entered useEffect() for [currentUser, themeSpecsId, prepareNewThemeSpecs]. currentUser: ${JSON.stringify(currentUser)}; themeSpecsId value: ${themeSpecsId}`);

    // if currentUser information has been established...
    if (currentUser !== null && currentUser !== undefined) {
      // if an Id was provided, we will be editing an existing themeSpecs; therefore, if we haven't already fetched the themeSpecs...
      if ((themeSpecs === undefined) && (themeSpecsId !== undefined)) {
        displayConsoleLogs && console.log(`ManageCustomThemePage. In useEffect() for [currentUser, themeSpecsId, prepareNewThemeSpecs] (${themeSpecsId}). Ready to call getSingleObjectById().`);
        // try to find a ThemeSpecs object with the Id given in the URL
        getSingleObjectById(themeSpecsId, enumPersistableObjectType.ThemeSpecs, onThemeSpecsFetched);
      } else {
        // if we haven't already created a ThemeSpecs object...
        if (themeSpecs === undefined) {
          displayConsoleLogs && console.log(`ManageCustomThemePage. In useEffect() for [currentUser, themeSpecsId, prepareNewThemeSpecs] & themeSpecsId is undefined). Ready to call prepareNewThemeSpecs().`);
          // otherwise, we will be creating a new ThemeSpecs, so prepare a new one
          setThemeSpecs(prepareNewThemeSpecs(currentUser, currentUser.userSettings));
        }
      }
    }
  }, [currentUser, themeSpecs, themeSpecsId, prepareNewThemeSpecs]);

  // callback function for fetching the current ThemeSpecs for editing
  function onThemeSpecsFetched(themeSpecsFetched: IThemeSpecs | undefined): void {
    displayConsoleLogs && console.log(`ManageCustomThemePage. Entered onThemeSpecsFetched()`);
    if (themeSpecsFetched !== undefined) {
      displayConsoleLogs && console.log(`ManageCustomThemePage. In onThemeSpecsFetched(). themeSpecsFetched.name: ${themeSpecsFetched.name}`);
      // set the returned ThemeSpecs to be the one we are editing
      setThemeSpecs(themeSpecsFetched);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing themeSpecs (default was set to 'true')
      setIsNewObject(false);
    }
  }

  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(themeSpecsToSubmit: IThemeSpecs): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`ManageCustomThemePage. Entered handleFormSubmit(). themeSpecsToSubmit.name: ${themeSpecsToSubmit.name}. params.id: ${params.id}`);
        // dispatch an action to request saving the themeSpecs
        dispatch(themeSpecsSaveRequest(themeSpecsToSubmit));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  displayConsoleLogs && console.log(`ManageCustomThemePage. Before 'return' call, themeSpecs: ${themeSpecs === undefined ? 'undefined' : 'id: ' + themeSpecs.id + '; name: ' + themeSpecs.name}. params.id: ${params.id}`);

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageCustomTheme_New : PageAndViewTitleStringAssets.pageTitle_ManageCustomTheme_Existing}
      onCloseAlert={resetSaveThemeSpecsStatus}
    >
      {themeSpecs &&
        <ThemeSpecsForm
          themeSpecs={themeSpecs}
          saveRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  )

}

export default ManageCustomThemePage;