import { enumSharingPermission } from "../../../enums";
import { IChannel } from "../../../models/channels/Channel";
import { IUser } from "../../../models/users/User";
import { ICurrentUserChannelSharingViewModel } from "./";


export class CurrentUserChannelSharingViewModel implements ICurrentUserChannelSharingViewModel {

  /**
   * @method Constructor method
   * @param {IUser} currentUser A reference to the associated Current User object
   * @param {IChannel} channel A reference to the underlying Channel object
   */
  constructor(
    currentUser: IUser,
    channel: IChannel,
  ) {

    // assign properties from ctor parameters
    this._currentUser = currentUser;
    this._channel = channel;

    // derive the currentUserChannelSharingPermission from the channel and currentUser objects
    this._currentUserChannelSharingPermission = channel.getUserSharingPermission(currentUser.id);
  }

  /*-----------------------------------------------*/
  /**
   * @property {IUser | undefined} _currentUser A reference to the associated Current User object
   */
  private _currentUser: IUser;

  /**
   * @method currentUser Getter method for _currentUser
   */
  get currentUser(): IUser {
    return this._currentUser;
  }

  /**
   * @method currentUser Setter method for _currentUser
   * @param {IUser | undefined} value The input value for setting _currentUser
   */
  set currentUser(value: IUser) {
    this._currentUser = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IChannel} _channel A reference to the underlying Channel object
   */
  private _channel: IChannel;

  /**
   * @method channel Getter method for _channel
   */
  get channel(): IChannel {
    return this._channel;
  }

  /**
   * @method channel Setter method for _channel
   * @param {IChannel} value The input value for setting _channel
   */
  set channel(value: IChannel) {
    this._channel = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSharingPermission} _currentUserChannelSharingPermission A reference to the associated Category object
   */
  private _currentUserChannelSharingPermission: enumSharingPermission;

  /**
   * @method currentUserChannelSharingPermission Getter method for _currentUserChannelSharingPermission
   */
  get currentUserChannelSharingPermission(): enumSharingPermission {
    return this._currentUserChannelSharingPermission;
  }

  //  /**
  //   * @method currentUserChannelSharingPermission Setter method for _currentUserChannelSharingPermission
  //   * @param {enumSharingPermission | undefined} value The input value for setting _currentUserChannelSharingPermission
  //   */
  //  set currentUserChannelSharingPermission(value: enumSharingPermission | undefined) {
  //      this._currentUserChannelSharingPermission = value;
  //  }
  /*-----------------------------------------------*/

}