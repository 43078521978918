import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchCategoriesFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const categoriesFetchFailureSlice = createSlice({
  name: 'categoriesFetchFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    categoriesFetchFailureChange(state, action) {
      return action.payload;
    },
    categoriesFetchFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { categoriesFetchFailureChange, categoriesFetchFailureClear } = categoriesFetchFailureSlice.actions;

// export the core reducer for the slice
export const categoriesFetchFailureReducer = categoriesFetchFailureSlice.reducer;

export default categoriesFetchFailureSlice.reducer;