import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const topicSaveFailureSlice = createSlice({
  name: 'topicSaveFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    topicSaveFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    topicSaveFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { topicSaveFailureChange, topicSaveFailureClear } = topicSaveFailureSlice.actions;

// export the core reducer for the slice
export const topicSaveFailureReducer = topicSaveFailureSlice.reducer;

export default topicSaveFailureSlice.reducer;