import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { enumSharableObjectType, enumSharingRequestStatus } from '../../../../dataObjects/enums';
import { FirestoreObjectSharingRequestTrackerRepository_Ext, IFirestoreObjectSharingRequestTrackerRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreObjectSharingRequestTrackerRepository';
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";


/**
 * @function getSharingRequestsByTypeForRecipient_onSnapshot Triggers a request for a snapshot with a collection of live IObjectSharingTracker objects for a given User
 * @param {enumSharableObjectType} sharingObjectType The type of object that is shared
 * @param {string} recipientUserEmail The email address of the recipient user
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback method that receives document data as a parm and has a void return value 
 * @returns {() => void} An 'unsubscribe callback' method that is to be called when the snapshot is no longer needed.
 */
export function getSharingRequestsByTypeForRecipient_onSnapshot(sharingObjectType: enumSharableObjectType, recipientUserEmail: string, callback: (snapshot: QuerySnapshot<DocumentData>) => void, statusValues?: Array<enumSharingRequestStatus>) : Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      const firestoreChannelRepository_Ext: IFirestoreObjectSharingRequestTrackerRepository_Ext = new FirestoreObjectSharingRequestTrackerRepository_Ext(firestoreObj);

      const unsubscribeCallback: () => void = await firestoreChannelRepository_Ext.getSharingRequestsByTypeForRecipient_onSnapshot(sharingObjectType, recipientUserEmail, callback, statusValues);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}
