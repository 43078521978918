import { useContext } from 'react';
import { UserCategoriesAndChannelsContext, IUserCategoriesAndChannelsContextData } from '.';

/**
 * @hook useUserCategoriesAndChannelsContext  A custom React hook that simplies the process for obtaining the Categories and
 *       Channels associated with the current user value from within a React component tree that includes a 
 *       UserCategoriesAndChannelsProvider at a higher level in the tree.
 */
 export const useUserCategoriesAndChannelsContext = (): IUserCategoriesAndChannelsContextData => {

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed useUserCategoriesAndChannelsContext hook', 'background: #008080; color: #fff');

  // get the context data
  const userCategoriesAndChannelsContextData: IUserCategoriesAndChannelsContextData = useContext(UserCategoriesAndChannelsContext);

  displayConsoleLogs && console.log(`%c In useUserCategoriesAndChannelsContext. userCategoriesAndChannelsContextData: ${JSON.stringify(userCategoriesAndChannelsContextData)}`, 'background: #008080; color: #fff');

  return userCategoriesAndChannelsContextData;
}
