import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IObjectSharingRequestTracker } from '../../../dataObjects/models/collaboration/ObjectSharingTracker';

// export interface ISaveObjectSharingRequestTrackerStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const objectSharingRequestTrackerSaveStatusSlice = createSlice({
  name: 'objectSharingRequestTrackerSaveStatus',
  initialState,
  reducers: {
    // The objectSharingRequestTrackerSaveRequest triggers the saving of an ObjectSharingRequestTracker. It's not really associated with the Store State
    // for the 'saveObjectSharingRequestTrackerStatus' store variable; however, it is used to both trigger the ObjectSharingRequestTracker Save Request -AND-
    // to call the objectSharingRequestTrackerSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design pattern, the most likely alternative would require creating another Store State
    // variable (eg, 'saveObjectSharingRequestTrackerData') and create another slice with a objectSharingRequestTrackerSaveRequest action. 
    objectSharingRequestTrackerSaveRequest: (state, action: PayloadAction<IObjectSharingRequestTracker>) => {
      objectSharingRequestTrackerSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IObjectSharingRequestTracker object or null
    objectSharingRequestTrackerSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { objectSharingRequestTrackerSaveRequest, objectSharingRequestTrackerSaveStatusChange } = objectSharingRequestTrackerSaveStatusSlice.actions;

// export the core reducer for the slice
export const objectSharingRequestTrackerSaveStatusReducer = objectSharingRequestTrackerSaveStatusSlice.reducer;

export default objectSharingRequestTrackerSaveStatusSlice.reducer;