import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const imageLinkDeleteFailureSlice = createSlice({
  name: 'imageLinkDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    imageLinkDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    imageLinkDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { imageLinkDeleteFailureChange, imageLinkDeleteFailureClear } = imageLinkDeleteFailureSlice.actions;

// export the core reducer for the slice
export const imageLinkDeleteFailureReducer = imageLinkDeleteFailureSlice.reducer;

export default imageLinkDeleteFailureSlice.reducer;