import React, { PropsWithChildren } from 'react';
import _ from 'lodash';
import { Grid, Box, Typography } from '@mui/material';
import { listViewStyles } from '../../../styles';
import ChannelSharingUserListViewItem from './ChannelSharingRequestListViewItem';
import { IObjectSharingRequestTracker } from '../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingRequestsListViewProps extends PropsWithChildren<unknown> {
  channelSharingRequests: Array<IObjectSharingRequestTracker> | undefined;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (channelSharingRequest: IObjectSharingRequestTracker) => void;
}

export const ChannelSharingRequestsListView: React.FC<IChannelSharingRequestsListViewProps> = (props: IChannelSharingRequestsListViewProps) => {
  ChannelSharingRequestsListView.displayName = 'Channel Sharing Requests List View';

  // eslint-disable-next-line no-console
  // console.info(`Render ChannelSharingRequestsListView`);

  const classes: Record<string, string> = listViewStyles();

  const { channelSharingRequests, currentUserChannelSharingViewModel, onRevoke } = props;

  const channelSharingRequestsSorted = _.sortBy(channelSharingRequests, ['recipientEmail']);


  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Recipient Email & Action Buttons
          - Data Row 2: Recipient Name (with Permission)
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid item container className={classes.tableHeader} xs={12} direction="row">
          <Grid item xs={12} md={10} >
            <Typography variant='h6'>
              Recipient Email / Name (Permission)
            </Typography>
          </Grid>
          <Grid item container className={classes.actionButtonLabelContainer} md={1} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Edit
              </Typography>
            </Box>
          </Grid>
          <Grid item container className={classes.actionButtonLabelContainer} md={1} justifyContent='center' >
            {/* Only display the next label if the screen size is Medium or larger */}
            <Box sx={{ display: { xs: 'none', md: 'block' } }} >
              <Typography variant='h6'>
                Revoke
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {
          channelSharingRequestsSorted &&
          channelSharingRequestsSorted.map((channelSharingRequest, idx) => (
            <ChannelSharingUserListViewItem
              key={channelSharingRequest.id}
              // currentUser={currentUser}
              // channel={channel}
              currentUserChannelSharingViewModel={currentUserChannelSharingViewModel}
              channelSharingRequest={channelSharingRequest}
              onRevoke={onRevoke}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default ChannelSharingRequestsListView;
