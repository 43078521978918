import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';

/**
 * @function requestBackupDataViaHttp Requests to backup data on the server (transactional, digital media files, search metadata)
 * @param emailAddress The user account email address to be used in the search operation
 * @returns An IUser object or undefined
 */
export function requestBackupDataViaHttp(): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c Entered requestBackupDataViaHttp()', 'background: #055; color: #fff');

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const backupData =
        httpsCallable<void>(functions, 'backupData');
        backupData()
        .then((result) => {

          displayConsoleLogs && console.log(`%c requestBackupDataViaHttp(). Returned from backupData() Http call. result: ${JSON.stringify(result)}`, 'background: #055; color: #fff');

          resolve();
        });
    } catch (error: any) {
      alert(`Error. userAccountActivities.requestBackupDataViaHttp failed: (${error})`);
      reject(error);
    }
  });
}
