import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IObjectSharingRequestTracker, IObjectSharingRequestTrackerAsJson } from "../../../../dataObjects/models/collaboration/ObjectSharingTracker";
import { IFirestoreObjectSharingRequestTrackerRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreObjectSharingRequestTrackerRepository";


export function saveObjectSharingRequestTracker(objectSharingRequestTracker: IObjectSharingRequestTracker): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // // *** FOR TESTING PURPOSES: Induce an artificial error ***
      // await setTimeout(() => {
      //   // do nothing
      // }, 2000);
      // // throw an exception
      // throw new MdbError('(saveObjectSharingRequestTracker) This is an artificial error induced for testing purposes.')
      // // *** END OF TESTING BLOCK

      // get an ObjectSharingRequestTracker repository from the repository factory
      const firestoreObjectSharingRequestTrackerRepository: IFirestoreObjectSharingRequestTrackerRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ObjectSharingRequestTracker) as
        IFirestoreBaseRepository<IObjectSharingRequestTracker, IObjectSharingRequestTrackerAsJson>;

      // determine if the ObjectSharingRequestTracker already exists by calling the repository get() method to try and get the object
      const existingObjectSharingRequestTracker: IObjectSharingRequestTracker | undefined = await firestoreObjectSharingRequestTrackerRepository.get(objectSharingRequestTracker.id);

      // If the ObjectSharingRequestTracker wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingObjectSharingRequestTracker === undefined) {
        // call repository method to create the ObjectSharingRequestTracker
        await firestoreObjectSharingRequestTrackerRepository.create(objectSharingRequestTracker);
      } else {
        // call repository method to update the ObjectSharingRequestTracker
        await firestoreObjectSharingRequestTrackerRepository.update(objectSharingRequestTracker);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}