import { put } from "redux-saga/effects";
import { saveSocialMediaPost } from "../../../firebaseServices/dataServices/dataServiceActions/socialMediaPostActions";
import { ISocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { ISaveSocialMediaPostRequest } from '../../../dataObjects/models/serviceRequests/socialMediaPost/SaveSocialMediaPostRequest';
import { ISaveSocialMediaPostWorkflowState } from "../../store/SaveSocialMediaPostWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";
import { socialMediaPostSaveError, socialMediaPostSaveSuccess, socialMediaPostSaveWorkflowStateChange } from "../../slices/socialMediaPost/socialMediaPostSaveStatusSlice";

/**
 * @function saveSocialMediaPostWorkerSaga A "worker" saga function that performs the work for a request to
 *   save a SocialMediaPost .
 * @param action 
 */
  export function* saveSocialMediaPostWorkerSaga(saveSocialMediaPostRequest: ISaveSocialMediaPostRequest) {
    try {
    // // extract the ISaveSocialMediaPostRequest from the action payload
    // const saveSocialMediaPostRequest: ISaveSocialMediaPostRequest | undefined = action.saveSocialMediaPostRequest;

    // if the ISaveSocialMediaPostRequest is defined...
    if (saveSocialMediaPostRequest !== undefined) {
      
      // update store state...      
      const saveSocialMediaPostWorkflowState: ISaveSocialMediaPostWorkflowState = {
        socialMediaPost: saveSocialMediaPostRequest.socialMediaPost,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.socialMediaPost_SaveRequested,
      }
      yield put(socialMediaPostSaveWorkflowStateChange(saveSocialMediaPostWorkflowState));

      // get the SocialMediaPost from ISaveSocialMediaPostRequest
      const socialMediaPost: ISocialMediaPost | undefined = saveSocialMediaPostRequest.socialMediaPost;

      // if the SocialMediaPost is defined...
      if (socialMediaPost !== undefined) {
        // call services method to save the socialMediaPost
        yield saveSocialMediaPost(socialMediaPost)

        // dispatch an action to indicate that the save operation was successful
        yield put(socialMediaPostSaveSuccess(socialMediaPost));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveSocialMediaPostWorkflowState: ISaveSocialMediaPostWorkflowState = {
          socialMediaPost: saveSocialMediaPostRequest.socialMediaPost,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.socialMediaPost_SaveSuccess,
        }

        saveSocialMediaPostWorkflowState.workflowState = enumWorkflowState.Success;
        saveSocialMediaPostWorkflowState.workflowStateMessage = MessagesStringAssets.socialMediaPost_SaveSuccess;
        yield put(socialMediaPostSaveWorkflowStateChange(saveSocialMediaPostWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(socialMediaPostSaveError(error));
  }
}