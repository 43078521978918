import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IAudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';

const initialState: enumWorkflowState | null = null;

export const audioLinkDeleteStatusSlice = createSlice({
  name: 'audioLinkDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IAudioLink
    audioLinkDeleteRequest: (state, action: PayloadAction<IAudioLink>) => {
      audioLinkDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    audioLinkDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { audioLinkDeleteRequest, audioLinkDeleteStatusChange } = audioLinkDeleteStatusSlice.actions;

// export the core reducer for the slice
export const audioLinkDeleteStatusReducer = audioLinkDeleteStatusSlice.reducer;

export default audioLinkDeleteStatusSlice.reducer;