import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ColorResult } from 'react-color';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IThemeSpecs } from '../../../dataObjects/models/themes/ThemeSpecs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ColorSwatch } from '../../controls/colors/ColorSwatch/ColorSwatch';
import { ColorPicker } from '../../controls/colors/ColorPicker/ColorPicker';
import { enumColorPickerType } from '../../enums';
import { styled } from '@mui/system';
import { IThemeColorSpectrum } from '../../../dataObjects/models/themes';
import { generateThemeColorSpectrumFromSingleColor } from '../../../dataObjects/utilities/colors';

const COLOR_SWATCH_HEIGHT: string = '2rem';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing a fieldset control to surround details for color selections
const StyledBoxForFieldSet = styled((props) => (
  <Box
    component='fieldset'
    {...props}
  />
))(({ theme }) => ({
  border: theme.fieldSet.border,
  borderRadius: theme.fieldSet.borderRadius,
  marginTop: '0.3rem',
}));

// a styled Box (equivalent to a <div>), providing a legend control to associate with the fieldset
const StyledBoxForLegend = styled((props) => (
  <Box
    component='legend'
    {...props}
  />
))(({ theme }) => ({
  color: theme.fieldSet.legendFontColor,
  fontSize: theme.fieldSet.legendFontSize,
}));

// a styled Box (equivalent to a <div>), providing an area for displaying the spectrum of colors for a Basic ThemeSpecs color
const StyledBoxForColorSpectrumArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  // flexWrap: 'wrap',
  justifyContent: 'space-evenly',
  margin: '4px 0',
  padding: '2px',
  border: theme.fieldSet.border,
}));

// a styled Box (equivalent to a <div>), providing an area for displaying a single color within a spectrum of colors
const StyledBoxForColorSpectrumColor = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  // margin: '0 4px 4px 0',
  flex: '1 1 0px',
  minWidth: '1rem',
  // maxWidth: '2rem',
  height: '2rem',
  // border: theme.colorBarInSwatch.border,
}));


// a styled Box (equivalent to a <div>), providing an area for color selection details
const StyledBoxForColorSelectorsArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: '4px 0px',
  flexWrap: 'wrap',
  // justifyContent: "space-between",
  justifyContent: 'start',
}));

// a styled Box (equivalent to a <div>), providing an area for displaying a color swatch with a heading
const StyledBoxForColorSwatchWithHeading = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  // Note: The flexGrow, flexShrink, and flexBasis are all needed to have columns occupy equal horizontal
  //       widths. 'flex: 1 1 0px' is a shorthand for the same representation.
  // flexGrow: 1,
  // flexShrink: 1,
  // flexBasis: '0px',
  flex: '1 1 0px', // to facilitate causing the individual color picker (with swatch) areas to be the same width
  minWidth: '7rem', // to facilitate causing the individual color picker (with swatch) areas to be the same width
  maxWidth: '7rem', // to facilitate causing the individual color picker (with swatch) areas to be the same width

  margin: '5px',
  display: "flex",
  flexDirection: "column",
}));

// a styled Typography for displaying a heading for the Color Spectrum area
const StyledTypographyForColorSpectrumHeading = styled((props) => (
  <Typography
    variant="body1"
    {...props}
  />
))(({ theme }) => ({
  alignSelf: 'center',
  color: theme.labelField.fontColor,
  fontSize: theme.labelField.fontSize,
  margin: '2px 2px 0px 2px',
}));

// a styled Box (equivalent to a <div>), providing an overall area for presenting a Color Picker
const StyledBoxForColorPickerArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
}));

// a styled Box (equivalent to a <div>), providing a space for presenting a Color Picker with its Header
const StyledBoxForColorPickerWithHeader = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignContent: "center",
}));

// a styled Typography for displaying a header for the Color Picker
const StyledTypographyForColorPickerHeading = styled((props) => (
  <Typography
    variant="body1"
    {...props}
  />
))(({ theme }) => ({
  alignSelf: "center",
  color: theme.labelField.fontColor,
  fontSize: theme.labelField.fontSize,
}));

interface IThemeSpecsFormValues {
  name: string;
  description: string;
  baseColorForPrimary: string;
  baseColorForSecondary: string;
  baseColorForCancel: string;
  baseColorForError: string;
  baseColorForHighlight: string;
  baseColorForInfo: string;
  baseColorForSuccess: string;
  baseColorForWarning: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  name: yup
    .string()
    .required(ControlsStringAssets.themeSpecsNameRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.themeSpecsDescriptionRequired),
  baseColorForPrimary: yup
    .string()
    .required(),
  baseColorForSecondary: yup
    .string()
    .required(),
  baseColorForCancel: yup
    .string()
    .required(),
  baseColorForError: yup
    .string()
    .required(),
  baseColorForHighlight: yup
    .string()
    .required(),
  baseColorForInfo: yup
    .string()
    .required(),
  baseColorForSuccess: yup
    .string()
    .required(),
  baseColorForWarning: yup
    .string()
    .required(),
});


/**
 * @interface IThemeSpecsFormProps Properties for the ThemeSpecsForm component
 */
export interface IThemeSpecsFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IThemeSpecs} themeSpecs The ThemeSpecs details for the form (will have blank properties values if we're creating a new record)
   */
  themeSpecs: IThemeSpecs,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(themeSpecs: IThemeSpecs) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (themeSpecs: IThemeSpecs) => Promise<void>,
}

const ThemeSpecsForm: React.FC<IThemeSpecsFormProps> = (props: IThemeSpecsFormProps) => {
  ThemeSpecsForm.displayName = 'ThemeSpecs Form';

  // get required arguments from props
  const { themeSpecs, onSubmit } = props;

  console.log(`ThemeSpecsForm. After assignment from props. themeSpecs.name: ${themeSpecs.name}`);

  // capture whether the user is on a mobile device (anything with size small or below)
  let theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const colorPickerPresetColors = ['#FFFFFF', '#DDDDDD', '#BBBBBB', '#999999',  // shades of WHITE & LIGHT GRAY
    '#777777', '#555555', '#333333', '#000000',  // shades of DARK GRAY & BLACK
    '#FFF0F0', '#FFACAC', '#FF5050', '#FF0000',  // shades of RED
    '#FFF0E8', '#FFC1A1', '#FF8E54', '#FF7400',  // shades of ORANGE
    '#FEFFF0', '#F9FFA8', '#F3FF55', '#EDFF00',  // shades of YELLOW
    '#EDFEE6', '#9BD982', '#599442', '#2B770D',  // shades of GREEN
    '#E6F2FE', '#6CA4E0', '#325A86', '#1B426B',  // shades of BLUE
    '#F2E6FE', '#BD8DEC', '#8743CB', '#4C2178',  // shades of PURPLE
  ];


  // for capturing the current values of the Name and Description fields so that they can be displayed in the Preview area
  const [nameCurrentValue, setNameCurrentValue] = useState<string>(themeSpecs.name);
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>(themeSpecs.description);

  // whether the Color Picker is being shown
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  // The current color to be displayed in the Color Picker
  const [currentColorPickerColor, setCurrentColorPickerColor] = useState<string>("#ccc");

  // The current color for each of the theme's basic colors
  const [currentPrimaryColor, setCurrentPrimaryColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForPrimary);
  const [currentSecondaryColor, setCurrentSecondaryColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForSecondary);
  const [currentCancelColor, setCurrentCancelColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForCancel);
  const [currentErrorColor, setCurrentErrorColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForError);
  const [currentHighlightColor, setCurrentHighlightColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForHighlight);
  const [currentInfoColor, setCurrentInfoColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForInfo);
  const [currentSuccessColor, setCurrentSuccessColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForSuccess);
  const [currentWarningColor, setCurrentWarningColor] = useState<string>(themeSpecs.themeBaseColors.baseColorForWarning);

  // The current color spectrum to be displayed
  const [currentColorSpectrum, setCurrentColorSpectrum] = useState<IThemeColorSpectrum | undefined>(undefined);

  // The spectrums of colors for each basic color
  const [colorSpectrumForPrimaryColor, setColorSpectrumForPrimaryColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForSecondaryColor, setColorSpectrumForSecondaryColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForCancelColor, setColorSpectrumForCancelColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForErrorColor, setColorSpectrumForErrorColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForHighlightColor, setColorSpectrumForHighlightColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForInfoColor, setColorSpectrumForInfoColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForSuccessColor, setColorSpectrumForSuccessColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForWarningColor, setColorSpectrumForWarningColor] = useState<IThemeColorSpectrum | undefined>(undefined);

  // boolean flags indicating which color is currently being edited
  const [editingPrimaryColor, setEditingPrimaryColor] = useState<boolean>(false);
  const [editingSecondaryColor, setEditingSecondaryColor] = useState<boolean>(false);
  const [editingCancelColor, setEditingCancelColor] = useState<boolean>(false);
  const [editingErrorColor, setEditingErrorColor] = useState<boolean>(false);
  const [editingHighlightColor, setEditingHighlightColor] = useState<boolean>(false);
  const [editingInfoColor, setEditingInfoColor] = useState<boolean>(false);
  const [editingSuccessColor, setEditingSuccessColor] = useState<boolean>(false);
  const [editingWarningColor, setEditingWarningColor] = useState<boolean>(false);

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<IThemeSpecsFormValues>({
    defaultValues: {
      name: themeSpecs.name,
      description: themeSpecs.description,
      baseColorForPrimary: themeSpecs.themeBaseColors.baseColorForPrimary,
      baseColorForSecondary: themeSpecs.themeBaseColors.baseColorForSecondary,
      baseColorForCancel: themeSpecs.themeBaseColors.baseColorForCancel,
      baseColorForError: themeSpecs.themeBaseColors.baseColorForError,
      baseColorForHighlight: themeSpecs.themeBaseColors.baseColorForHighlight,
      baseColorForInfo: themeSpecs.themeBaseColors.baseColorForInfo,
      baseColorForSuccess: themeSpecs.themeBaseColors.baseColorForSuccess,
      baseColorForWarning: themeSpecs.themeBaseColors.baseColorForWarning,
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  // 
  const { ref: nameReg, ...nameProps } = register("name", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });


  // execute the first time the form comes into being (is mounted)
  useEffect(() => {
    // add a click handler that will be used to cause the Color Picker to be closed if the user clicks anywhere in the 
    // document other than one of the color buttons (the color buttons' click handler(s) will need to stop propagation of their click event)
    document.addEventListener('click', handleClickOnDocument);

    // unmount logic
    return () => {
      document.removeEventListener('click', handleClickOnDocument);
    }
  }, []);

  // useEffect to respond to a change in the Current Primary Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentPrimaryColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForPrimaryColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentPrimaryColor]);

  // useEffect to respond to a change in the Current Secondary Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentSecondaryColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForSecondaryColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentSecondaryColor]);

  // useEffect to respond to a change in the Current Cancel Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentCancelColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForCancelColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentCancelColor]);

  // useEffect to respond to a change in the Current Error Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentErrorColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForErrorColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentErrorColor]);

  // useEffect to respond to a change in the Current Highlight Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentHighlightColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForHighlightColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentHighlightColor]);

  // useEffect to respond to a change in the Current Info Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentInfoColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForInfoColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentInfoColor]);

  // useEffect to respond to a change in the Current Success Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentSuccessColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForSuccessColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentSuccessColor]);

  // useEffect to respond to a change in the Current Warning Color
  useEffect(() => {
    // create a spectrum of colors for the new value of the color
    generateThemeColorSpectrumFromSingleColor(currentWarningColor).then((colorSpectrum: IThemeColorSpectrum) => {
      setColorSpectrumForWarningColor(colorSpectrum);
    }).catch((error) => {
      throw error;
    })
  }, [currentWarningColor]);

  function handleClickOnDocument() {
    // turn off editing for all colors and hide Color Picker
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    setCurrentColorSpectrum(undefined);

    setShowColorPicker(false);
  }

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IThemeSpecsFormValues) => {

    setSaveInProgress(true);

    console.info(`Preparing to submit for save. data.name: ${data.name}; data.description: ${data.description}`);

    // fill in name & description of the ThemeSpecs object passed in
    themeSpecs.name = data.name;
    themeSpecs.description = data.description;
    themeSpecs.themeBaseColors.baseColorForPrimary = currentPrimaryColor;
    themeSpecs.themeBaseColors.baseColorForSecondary = currentSecondaryColor;
    themeSpecs.themeBaseColors.baseColorForCancel = currentCancelColor;
    themeSpecs.themeBaseColors.baseColorForError = currentErrorColor;
    themeSpecs.themeBaseColors.baseColorForHighlight = currentHighlightColor;
    themeSpecs.themeBaseColors.baseColorForInfo = currentInfoColor;
    themeSpecs.themeBaseColors.baseColorForSuccess = currentSuccessColor;
    themeSpecs.themeBaseColors.baseColorForWarning = currentWarningColor;

    // call the onSubmit handler passed in, supplying the ThemeSpecs object
    await onSubmit(themeSpecs);
  }

  function handleNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setNameCurrentValue(event.target.value);
  }

  function handleDescriptionChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDescriptionCurrentValue(event.target.value);
  }

  function handleClickSetPrimaryColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetPrimaryColor(). editingPrimaryColor: ${editingPrimaryColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Primary Color, this click event will have us editing it, so set the current Color Picker color to the Primary Color
    setCurrentColorPickerColor(!editingPrimaryColor ? currentPrimaryColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Primary Color
    setShowColorPicker(!editingPrimaryColor);

    // toggle the flag indicating whether we are currently editing the Primary Color
    setEditingPrimaryColor(!editingPrimaryColor);

    // turn off editing for other colors
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForPrimaryColor);
  }

  function handleClickSetSecondaryColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetSecondaryColor(). editingSecondaryColor: ${editingSecondaryColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Secondary Color, this click event will have us editing it, so set the current Color Picker color to the Secondary Color
    setCurrentColorPickerColor(!editingSecondaryColor ? currentSecondaryColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Secondary Color
    setShowColorPicker(!editingSecondaryColor);

    // toggle the flag indicating whether we are currently editing the Secondary Color
    setEditingSecondaryColor(!editingSecondaryColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForSecondaryColor);
  }

  function handleClickSetCancelColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetCancelColor(). editingCancelColor: ${editingCancelColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Cancel Color, this click event will have us editing it, so set the current Color Picker color to the Cancel Color
    setCurrentColorPickerColor(!editingCancelColor ? currentCancelColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Cancel Color
    setShowColorPicker(!editingCancelColor);

    // toggle the flag indicating whether we are currently editing the Cancel Color
    setEditingCancelColor(!editingCancelColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForCancelColor);
  }

  function handleClickSetErrorColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetErrorColor(). editingErrorColor: ${editingErrorColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Error Color, this click event will have us editing it, so set the current Color Picker color to the Error Color
    setCurrentColorPickerColor(!editingErrorColor ? currentErrorColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Error Color
    setShowColorPicker(!editingErrorColor);

    // toggle the flag indicating whether we are currently editing the Error Color
    setEditingErrorColor(!editingErrorColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForErrorColor);
  }

  function handleClickSetHighlightColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetHighlightColor(). editingHighlightColor: ${editingHighlightColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Highlight Color, this click event will have us editing it, so set the current Color Picker color to the Highlight Color
    setCurrentColorPickerColor(!editingHighlightColor ? currentHighlightColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Highlight Color
    setShowColorPicker(!editingHighlightColor);

    // toggle the flag indicating whether we are currently editing the Highlight Color
    setEditingHighlightColor(!editingHighlightColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForHighlightColor);
  }

  function handleClickSetInfoColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetInfoColor(). editingInfoColor: ${editingInfoColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Info Color, this click event will have us editing it, so set the current Color Picker color to the Info Color
    setCurrentColorPickerColor(!editingInfoColor ? currentInfoColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Info Color
    setShowColorPicker(!editingInfoColor);

    // toggle the flag indicating whether we are currently editing the Info Color
    setEditingInfoColor(!editingInfoColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingSuccessColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForInfoColor);
  }

  function handleClickSetSuccessColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetSuccessColor(). editingSuccessColor: ${editingSuccessColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Success Color, this click event will have us editing it, so set the current Color Picker color to the Success Color
    setCurrentColorPickerColor(!editingSuccessColor ? currentSuccessColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Success Color
    setShowColorPicker(!editingSuccessColor);

    // toggle the flag indicating whether we are currently editing the Success Color
    setEditingSuccessColor(!editingSuccessColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingWarningColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForSuccessColor);
  }

  function handleClickSetWarningColor(event: React.MouseEvent<HTMLElement>) {
    console.info(`In handleClickSetWarningColor(). editingWarningColor: ${editingWarningColor}`);
    event.preventDefault();
    event.stopPropagation(); // don't bubble event up to parent object

    // if we're currently not editing the Warning Color, this click event will have us editing it, so set the current Color Picker color to the Warning Color
    setCurrentColorPickerColor(!editingWarningColor ? currentWarningColor : "#000");

    // indicate whether to show the Color Picker based upon whether we are currently editing the Warning Color
    setShowColorPicker(!editingWarningColor);

    // toggle the flag indicating whether we are currently editing the Warning Color
    setEditingWarningColor(!editingWarningColor);

    // turn off editing for other colors
    setEditingPrimaryColor(false);
    setEditingSecondaryColor(false);
    setEditingCancelColor(false);
    setEditingErrorColor(false);
    setEditingHighlightColor(false);
    setEditingInfoColor(false);
    setEditingSuccessColor(false);

    // set the current color spectrum to be displayed
    setCurrentColorSpectrum(colorSpectrumForWarningColor);
  }

  function handleOnColorPickerChange(color: ColorResult) {
    setCurrentColorPickerColor(color.hex);
  }

  function handleOnColorPickerChangeComplete(color: ColorResult) {
    setCurrentColorPickerColor(color.hex);

    // set the appropriate swatch to the current color, based on which color is currently being edited
    if (editingPrimaryColor) {
      setCurrentPrimaryColor(color.hex);
    } else if (editingSecondaryColor) {
      setCurrentSecondaryColor(color.hex);
    } else if (editingCancelColor) {
      setCurrentCancelColor(color.hex);
    } else if (editingErrorColor) {
      setCurrentErrorColor(color.hex);
    } else if (editingHighlightColor) {
      setCurrentHighlightColor(color.hex);
    } else if (editingInfoColor) {
      setCurrentInfoColor(color.hex);
    } else if (editingSuccessColor) {
      setCurrentSuccessColor(color.hex);
    } else if (editingWarningColor) {
      setCurrentWarningColor(color.hex);
    }
  }


  function handleClickInColorPickerArea(event: React.MouseEvent<HTMLElement>) {
    // we merely want to stop propagation of the mouse click as we don't want the color picker to be closed when clicking on it
    event.stopPropagation();
  }

  console.log(`editingPrimaryColor: ${editingPrimaryColor}`);

  function getColorPickerName(): string {
    let name = 'Color Picker';

    if (editingPrimaryColor) {
      name = 'Primary';
    } else if (editingSecondaryColor) {
      name = 'Secondary';
    } else if (editingCancelColor) {
      name = 'Cancel';
    } else if (editingErrorColor) {
      name = 'Error';
    } else if (editingHighlightColor) {
      name = 'Highlight';
    } else if (editingInfoColor) {
      name = 'Information';
    } else if (editingSuccessColor) {
      name = 'Success';
    } else if (editingWarningColor) {
      name = 'Warning';
    }

    return name;
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.themeSpecs_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={nameReg}
          {...nameProps}
          type="text"
          autoFocus
          label={ControlsStringAssets.themeSpecsNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          // we use a defined onChange handler to display the 'name' value in the preview pane
          onChange={handleNameChanged}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          type="text"
          label={ControlsStringAssets.themeSpecsDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          // we use a defined onChange handler to display the 'description' value in the preview pane
          onChange={handleDescriptionChanged}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <StyledBoxForFieldSet>
          <StyledBoxForLegend>Appearance Preview and Color Settings</StyledBoxForLegend>
          {currentColorSpectrum &&
            <>
              <StyledTypographyForColorSpectrumHeading>Color Spectrum for Selected Color</StyledTypographyForColorSpectrumHeading>
              <StyledBoxForColorSpectrumArea >
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[25]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[50]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[100]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[200]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[300]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[400]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[500]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[600]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[700]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[800]} />
                <StyledBoxForColorSpectrumColor bgcolor={currentColorSpectrum[900]} />
              </StyledBoxForColorSpectrumArea>
            </>
          }

          <StyledBoxForColorSelectorsArea>
            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Primary Color */}
              <ColorSwatch background={currentPrimaryColor} height={COLOR_SWATCH_HEIGHT} text={'Primary'} textColor={colorSpectrumForPrimaryColor && colorSpectrumForPrimaryColor.contrastTextForMainBackground} selected={editingPrimaryColor} onClick={handleClickSetPrimaryColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Secondary Color */}
              <ColorSwatch background={currentSecondaryColor} height={COLOR_SWATCH_HEIGHT} text={'Secondary'} textColor={colorSpectrumForSecondaryColor && colorSpectrumForSecondaryColor.contrastTextForMainBackground} selected={editingSecondaryColor} onClick={handleClickSetSecondaryColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Cancel Color */}
              <ColorSwatch background={currentCancelColor} height={COLOR_SWATCH_HEIGHT} text={'Cancel'} textColor={colorSpectrumForCancelColor && colorSpectrumForCancelColor.contrastTextForMainBackground} selected={editingCancelColor} onClick={handleClickSetCancelColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Error Color */}
              <ColorSwatch background={currentErrorColor} height={COLOR_SWATCH_HEIGHT} text={'Error'} textColor={colorSpectrumForErrorColor && colorSpectrumForErrorColor.contrastTextForMainBackground} selected={editingErrorColor} onClick={handleClickSetErrorColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Highlight Color */}
              <ColorSwatch background={currentHighlightColor} height={COLOR_SWATCH_HEIGHT} text={'Highlight'} textColor={colorSpectrumForHighlightColor && colorSpectrumForHighlightColor.contrastTextForMainBackground} selected={editingHighlightColor} onClick={handleClickSetHighlightColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Info Color */}
              <ColorSwatch background={currentInfoColor} height={COLOR_SWATCH_HEIGHT} text={'Information'} textColor={colorSpectrumForInfoColor && colorSpectrumForInfoColor.contrastTextForMainBackground} selected={editingInfoColor} onClick={handleClickSetInfoColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Success Color */}
              <ColorSwatch background={currentSuccessColor} height={COLOR_SWATCH_HEIGHT} text={'Success'} textColor={colorSpectrumForSuccessColor && colorSpectrumForSuccessColor.contrastTextForMainBackground} selected={editingSuccessColor} onClick={handleClickSetSuccessColor} />
            </StyledBoxForColorSwatchWithHeading>

            <StyledBoxForColorSwatchWithHeading>
              {/* Color Swatch for the Warning Color */}
              <ColorSwatch background={currentWarningColor} height={COLOR_SWATCH_HEIGHT} text={'Warning'} textColor={colorSpectrumForWarningColor && colorSpectrumForWarningColor.contrastTextForMainBackground} selected={editingWarningColor} onClick={handleClickSetWarningColor} />
            </StyledBoxForColorSwatchWithHeading>
          </StyledBoxForColorSelectorsArea>

          {
            showColorPicker &&

            <StyledBoxForColorPickerArea onClick={handleClickInColorPickerArea} >
              <StyledBoxForColorPickerWithHeader onClick={handleClickInColorPickerArea}>

                <StyledTypographyForColorPickerHeading>
                  {getColorPickerName()}
                </StyledTypographyForColorPickerHeading>

                <Box>
                  <ColorPicker
                    // if running on a small mobile device, use one type of Color Picker; 
                    // otherwise, use a fuller featured picker on a larger device
                    colorPickerType={isMobile ? enumColorPickerType.TwitterPicker : enumColorPickerType.SketchPicker}
                    currentColor={currentColorPickerColor}
                    colorPalette={colorPickerPresetColors}
                    onChange={handleOnColorPickerChange}
                    onChangeComplete={handleOnColorPickerChangeComplete}
                  />
                </Box>
              </StyledBoxForColorPickerWithHeader>
            </StyledBoxForColorPickerArea>
          }

        </StyledBoxForFieldSet>

      </FormWithActionBar>
    </>

  );
}

export default ThemeSpecsForm;