import { typeUniqueId } from "../../../../dataObjects/types";
import { generateIdForEmailRequest } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../dataObjects/models/persistence/UserPersistenceData";

/**
 * @function generateEmailRequestForRecoverUserEmailAddress Generates an email request to notify that the email address on
 *                            the user's account has been recovered (changed back to the email address before it was changed)
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} recoverEmailAddress The email address that will be recovered (reverted to) and the target of the email message.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} priorEmailAddress The email address prior to the recover operation (email address to which it had been changed).
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForRecoverUserEmailAddress(userId: typeUniqueId, recoverEmailAddress: string, firstName: string, lastName: string, priorEmailAddress: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForRecoverUserEmailAddress`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      // request to generate an emailRequestId
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userId, enumEmailRequestType.RecoverEmailAddress);

      displayConsoleLogs && console.log(`%c generateEmailRequestForRecoverUserEmailAddress. emailRequestId: ${emailRequestId}`, 'background: #055; color: #fff');

      const recipientUserName: string = `${firstName} ${lastName}`;
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userId, recipientUserName, 'web browser', enumLocale.English);

      // compose an EmailRequest object to send to the old email address for the user prior to updating the email address
      const emailRequest: IEmailRequest = new EmailRequest(userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.RecoverEmailAddress, userId, undefined, undefined, recoverEmailAddress, recipientUserName,
        enumAppPlatformType.Web, `https://${baseDomain}`, emailRequestSubmittedTimestamp, undefined, undefined, priorEmailAddress, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForRecoverUserEmailAddress. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #055; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForRecoverUserEmailAddress. Error encountered: ${error}`, 'background: #550; color: #fff');
      reject(error);
    }
  });
}