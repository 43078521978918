import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const documentLinkDeleteFailureSlice = createSlice({
  name: 'documentLinkDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    documentLinkDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    documentLinkDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { documentLinkDeleteFailureChange, documentLinkDeleteFailureClear } = documentLinkDeleteFailureSlice.actions;

// export the core reducer for the slice
export const documentLinkDeleteFailureReducer = documentLinkDeleteFailureSlice.reducer;

export default documentLinkDeleteFailureSlice.reducer;