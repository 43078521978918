import React, { PropsWithChildren } from 'react';
import { ControlsStringAssets } from '../../../assets/stringAssets';
import { Box, Button } from '@mui/material';
import Form from '../Form/Form';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Action Buttons Area
const StyledBoxForActionButtonsArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
}));

// a styled Button for the 'Proceed to Sign-in' Button
const StyledButtonForProceedToSigninButton = styled((props) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

export interface IVerifyAndChangeEmailResultsFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {boolean} emailVerificationSuccessful Whether the email verification process was successful
   */
  verifyAndChangeEmailSuccessful: boolean,
}

const VerifyAndChangeEmailResultsForm: React.FC<IVerifyAndChangeEmailResultsFormProps> = (props: IVerifyAndChangeEmailResultsFormProps) => {
  VerifyAndChangeEmailResultsForm.displayName = 'Verify and Change Email Results Form';

  const { verifyAndChangeEmailSuccessful } = props;

  const navigate = useNavigate();

  // handles a login (submit) request from the form
  const handleSubmitStub = () => {

    // do nothing in this method
  }

  return (
    <>
      <Form
        onSubmit={handleSubmitStub}
      >
        {verifyAndChangeEmailSuccessful &&
          <>
            <p>MyDigiBrain has successfully changed and verified the email address for your account!
              You may now automatically sign in by pressing the button below.
            </p>
            <StyledBoxForActionButtonsArea>
              <StyledButtonForProceedToSigninButton
                onClick={() => navigate('/')}
              >
                {ControlsStringAssets.automaticallySigninButtonText}
              </StyledButtonForProceedToSigninButton>
            </StyledBoxForActionButtonsArea>
          </>
        }

        {!verifyAndChangeEmailSuccessful &&
          <>
            <p>The attempt to verify change and your email address, and then verify it was unsuccessful. This is likely due to an invalid or expired verification code.
              To submit another request to change your email address, please sign in to your account with the email address you used
              before you attempted to change it, go to the settings page, and attempt to change your email address again.
            </p>
            <p>
              If you continue to experience problems, please contact support at <a href='mailto:support@mydigibrain.com'>support@mydigibrain.com</a>
            </p>
            <StyledBoxForActionButtonsArea>
              <StyledButtonForProceedToSigninButton
                onClick={() => navigate('/login')}
              >
                {ControlsStringAssets.proceedToSigninButtonText}
              </StyledButtonForProceedToSigninButton>
            </StyledBoxForActionButtonsArea>
          </>
        }

      </Form>
    </>

  );
}

export default VerifyAndChangeEmailResultsForm;