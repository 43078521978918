import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { PageHeader } from '../../headerAndFooter/PageHeader/PageHeader';
import AlertBar from '../../headerAndFooter/AlertBar/AlertBar';
import { IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { useGetAlertInfo } from '../../customHooks/useGetAlertInfo';
import { styled } from '@mui/system';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


// a styled Container for this Generic Page
const StyledContainerForGenericPage = styled((props) => (
  <Container
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: theme.spacing(2, 2, 2, 2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));


export interface IGenericPageContainerProps extends PropsWithChildren<unknown> {
  /**
   * @property {boolean} showBackButton (optional) Whether to show a "Back" button on the page
   */
  showBackButton?: boolean;
  /**
   * @property {string} objectSpecificPageHeaderCategory If provided, represents an object-specific page header category to be 
   *                                                     displayed above the page title.
   * @example "Channel" in "Channel: Private Channel"
   */
  objectSpecificPageHeaderCategory?: string;
  /**
   * @property {string} objectSpecificPageHeaderText If provided, represents text for an object-specific page header to be 
   *                                                 displayed above the page title.
   * @example "Private Channel" in "Channel: Private Channel"
   */
  objectSpecificPageHeaderText?: string;
  /**
   * @property {string} pageTitle Title to be displayed for the page
   */
  pageTitle: string;
  /**
   * @property {string} pageSubtitle Subtitle to be displayed for the page
   */
  pageSubtitle?: string;
  /**
   * @property {boolean} showCategoriesOption (optional) Whether to show the Categories area in the PageHeader
   */
  showCategoriesOption?: boolean;
  /**
   * @property {() => void} onClickEditCategories (optional) Callback function to call when the Edit Categories button is clicked
   */
  onClickEditCategories?: () => void;
  /**
   * @property {false | "sm" | "xs" | "md" | "lg" | "xl" | undefined} maxWidth (optional) "Maximum Width" specification for the page. If not provided, defaults to "am"
   */
  maxWidth?: false | "sm" | "xs" | "md" | "lg" | "xl" | undefined;
  /**
   * @property {() => void} onCloseAlert (optional) Callback function to call when an Alert gets closed
   */
  onCloseAlert?: () => void;
  /**
   * @property {boolean} showTrainingVideosButton (optional) Whether to show a "Training Videos" button on the page
   */
  showTrainingVideosButton?: boolean;
  /**
   * @property {string} trainingVideosUrl (optional) The navigation Url to use if "Training Videos" button is clicked
   */
  trainingVideosUrl?: string;
}

// const GenericPageContainer: React.FC<any> = ({ children, ...props }) => {
// const GenericPageContainer: React.FC<IGenericPageContainerProps> = ({ children, showBackButton, pageTitle, pageSubtitle, showCategoriesOption, onClickEditCategories, maxWidth, onCloseAlert: onClearError, ...otherProps }) => {
const GenericPageContainer: React.FC<IGenericPageContainerProps> = (props) => {

  const {
    children,
    showBackButton,
    objectSpecificPageHeaderCategory,
    objectSpecificPageHeaderText,
    pageTitle,
    pageSubtitle,
    showCategoriesOption,
    onClickEditCategories,
    maxWidth,
    onCloseAlert: onClearError,
    showTrainingVideosButton,
    trainingVideosUrl,
    ...otherProps
  } = props;

  const dispatch = useAppDispatch();

  const [alertInfoForDisplay, setAlertInfoForDisplay] = useState<IAlertInfo | null>(null);

  // Get AlertInfo from Redux state
  const alertInfoFromState: IAlertInfo | null = useGetAlertInfo();

  // clears all alerts that are currently active for this page
  const handleCloseAlert: () => void = useCallback(() => {
    // dispatch(clearAlertInfo());
    dispatch(alertInfoChange(null));
  }, [dispatch]);

  // anytime the alertInfoFromState changes, set the alertInfoForDisplay in local state
  useEffect(() => {
    // perform cleanup when the component unmounts, including:
    //   o Closing all alerts
    return () => {
      handleCloseAlert();
    }
  }, [handleCloseAlert]);

  if (alertInfoForDisplay !== alertInfoFromState) {
    setAlertInfoForDisplay(alertInfoFromState);
  }

  return (
    <>
      {/* display the Page Header */}
      <PageHeader
        showBackButton={showBackButton ?? false}
        objectSpecificPageHeaderCategory={objectSpecificPageHeaderCategory}
        objectSpecificPageHeaderText={objectSpecificPageHeaderText}
        pageTitle={pageTitle}
        pageSubtitle={pageSubtitle}
        showCategoriesOption={showCategoriesOption}
        onClickEditCategories={onClickEditCategories}
        showTrainingVideosButton={showTrainingVideosButton}
        trainingVideosUrl={trainingVideosUrl}
      />
      {
        alertInfoForDisplay && alertInfoForDisplay.showAlert &&
        <Box key='AlertBarContainerBox'>
          {
            <AlertBar key='AlertBar' alertInfo={alertInfoForDisplay} onCloseAlert={handleCloseAlert} />
          }
        </Box>
      }

      {/* pass 'props' to Container */}
      <StyledContainerForGenericPage maxWidth={props.maxWidth === undefined ? undefined : props.maxWidth ?? "sm"}  {...otherProps}>
        {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
        <>
          {children && children}
        </>
      </StyledContainerForGenericPage>
    </>
  )
}

export default GenericPageContainer;