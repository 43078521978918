import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { listViewStyles } from '../../../styles';
import { IHyperLink } from '../../../../dataObjects/models/digitalMedia/HyperLink';
import HyperLinksListViewItem from './HyperLinksListViewItem';
import { IHyperLinkViewModel } from '../../../../dataObjects/viewModels/hyperLinkViewModel';

export interface IHyperLinksListViewProps extends PropsWithChildren<unknown> {
  hyperLinkViewModels: Array<IHyperLinkViewModel>;
  onDelete: (hyperLink: IHyperLink) => void;
}

export const HyperLinksListView: React.FC<IHyperLinksListViewProps> = (props: IHyperLinksListViewProps) => {
  HyperLinksListView.displayName = 'HyperLinks List View';

  // eslint-disable-next-line no-console
  // console.info(`Render HyperLinksListView`);

  const classes: Record<string, string> = listViewStyles();

  const { hyperLinkViewModels, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Title (with HyperLink) & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='titleAndContentLabel' item xs={12} md={10} >
            <Typography variant='h6'>
              Title (HyperLink) / Description
            </Typography>
          </Grid>
          <Grid item container md={2} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='editLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          hyperLinkViewModels.map((hyperLinkViewModel, idx) => (
            <HyperLinksListViewItem
              key={hyperLinkViewModel.hyperLink.id}
              hyperLinkViewModel={hyperLinkViewModel}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default HyperLinksListView;
