/**
 * @enum enumTopicItemsListOperation represents the different types of operations that
 * can be performed on a Topic Item List.
 */
export enum enumTopicItemsListOperation {
  // Add an item to the list with Title Only
  AddItemWithTitleOnly,
  // Add an item to the list
  AddTopicItem,
  // Update an item in the list
  UpdateTopicItem,
  // Delete an item from the list
  DeleteTopicItem,
}
