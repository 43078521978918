import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { ITopic } from '../../../dataObjects/models/topics/Topic';

interface ITopicFormValues {
  name: string;
  description: string;
  topicItemNameAliasSingular: string;
  topicItemNameAliasPlural: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  name: yup
    .string()
    .required(ControlsStringAssets.topicNameRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.topicDescriptionRequired),
  topicItemNameAliasSingular: yup
    .string()
    .required(ControlsStringAssets.topicTopicItemNameAliasSingularRequired),
  topicItemNameAliasPlural: yup
    .string()
    .required(ControlsStringAssets.topicTopicItemNameAliasPluralRequired),
});

export interface ITopicFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {ITopic} topic The Topic details for the form (will have blank properties values if we're creating a new record)
   */
  topic: ITopic,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(topic: ITopic) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (topic: ITopic) => Promise<void>,
}

const TopicForm: React.FC<ITopicFormProps> = (props: ITopicFormProps) => {
  TopicForm.displayName = 'Topic Form';

  // get required arguments from props
  const { topic, onSubmit } = props;

  console.log(`TopicForm. After assignment from props. topic.name: ${topic.name}`);

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<ITopicFormValues>({
    defaultValues: {
      name: topic.name,
      description: topic.description,
      topicItemNameAliasSingular: topic.topicItemNameAliasSingular,
      topicItemNameAliasPlural: topic.topicItemNameAliasPlural
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });
  const { ref: nameReg, ...nameProps } = register("name", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });
  const { ref: topicItemNameAliasSingularReg, ...topicItemNameAliasSingularProps } = register("topicItemNameAliasSingular", { required: true });
  const { ref: topicItemNameAliasPluralReg, ...topicItemNameAliasPluralProps } = register("topicItemNameAliasPlural", { required: true });

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: ITopicFormValues) => {

    setSaveInProgress(true);

    // fill in name & description of the Topic object passed in
    topic.name = data.name;
    topic.description = data.description;
    topic.topicItemNameAliasSingular = data.topicItemNameAliasSingular;
    topic.topicItemNameAliasPlural = data.topicItemNameAliasPlural;

    // call the onSubmit handler passed in, supplying the Topic object
    await onSubmit(topic);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.topic_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={nameReg}
          {...nameProps}
          autoFocus
          label={ControlsStringAssets.topicNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          label={ControlsStringAssets.topicDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={topicItemNameAliasSingularReg}
          {...topicItemNameAliasSingularProps}
          label={ControlsStringAssets.topicTopicItemNameAliasSingularLabel}
          margin='normal'
          fullWidth
          error={!!errors.topicItemNameAliasSingular}
          helperText={errors?.topicItemNameAliasSingular?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={topicItemNameAliasPluralReg}
          {...topicItemNameAliasPluralProps}
          label={ControlsStringAssets.topicTopicItemNameAliasPluralLabel}
          margin='normal'
          fullWidth
          error={!!errors.topicItemNameAliasPlural}
          helperText={errors?.topicItemNameAliasPlural?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default TopicForm;