import { take } from "redux-saga/effects";
import { saveAudioLinkWithFileUploadWorkerSaga } from "./saveAudioLinkWithFileUploadWorkerSaga";
import { audioLinkSaveWithFileUploadRequest } from "../../slices/audioLink/audioLinkSaveStatusSlice";

/**
 * @function saveAudioLinkWithFileUploadWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an AudioLink along with a file upload associated with it.
 */
export function* saveAudioLinkWithFileUploadWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the audioLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveAudioLinkRequestData = yield take(audioLinkSaveWithFileUploadRequest);
    const { payload: saveAudioLinkRequest } = saveAudioLinkRequestData;

    yield saveAudioLinkWithFileUploadWorkerSaga(saveAudioLinkRequest);
  }
}