import { typeUniqueId } from "../../../../dataObjects/types"
import { enumPersistableObjectType } from '../../../../dataObjects/enums';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { IPersistable } from '../../../../dataObjects/models/persistence/Persistable';
import { getDigitalMediaObjectUsingId } from '../digitalMediaActions';
import { getTopicItemObjectUsingId } from '../topicItemActions';
import { getTopicObjectUsingId } from '../topicActions';
import { getChannelObjectUsingId } from '.';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';


/*-----------------------------------------------*/
/**
* @function getChannelFromDescendantObjectId Determines (resolves) the id of a Channel for an object that descends from a Channel.
* @param {typeUniqueId} descendantObjectId The Id of the object that descends from a Channel (topicId, topicItemId, digitalMediaId, imageId, etc.).
* @param {enumPersistableObjectType} descendantObjectType The type of object that descends from Channel (need this to know the upward traversal path)
* @returns {Promise<IChannel | undefined>} A Promise (to provide asynchrounous capability) with a typeUniqueId that represents the channelId, or 
*                                              undefined if an ancestor Channel cannot be determined.
*/
export function getChannelFromDescendantObjectId(descendantObjectId: typeUniqueId, descendantObjectType: enumPersistableObjectType): Promise<IChannel | undefined> {
  return new Promise<IChannel | undefined>(async (resolve, reject) => {
    try {
      // // object to be returned
      // let channel: IChannel | undefined = undefined;

      // // initialize a Firestore object instance
      // const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // // instantiate a FirestoreDbContext
      // const dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);

      let descendantObject: IPersistable | undefined = undefined;
      let expectedParentObjectType: enumPersistableObjectType | undefined = undefined;

      // // From the database, fetch the object that represents the type being sought, based on the descendant object type
      // if (descendantObjectType === enumPersistableObjectType.DigitalMedia) {
      //   const firestoreDigitalMediaRepository: IFirestoreDigitalMediaRepository = new FirestoreDigitalMediaRepository(dbContext);
      //   expectedParentObjectType = enumPersistableObjectType.TopicItem;
      //   descendantObject = await getParentedObjectFromDb<IDigitalMedia, IDigitalMediaAsJson>(descendantObjectId, descendantObjectType, expectedParentObjectType, firestoreDigitalMediaRepository);
      // } else if (descendantObjectType === enumPersistableObjectType.TopicItem) {
      //   const firestoreTopicItemRepository: IFirestoreTopicItemRepository = new FirestoreTopicItemRepository(dbContext);
      //   expectedParentObjectType = enumPersistableObjectType.Topic;
      //   descendantObject = await getParentedObjectFromDb<ITopicItem, ITopicItemAsJson>(descendantObjectId, descendantObjectType, expectedParentObjectType, firestoreTopicItemRepository);
      // } else if (descendantObjectType === enumPersistableObjectType.Topic) {
      //   const firestoreTopicRepository: IFirestoreTopicRepository = new FirestoreTopicRepository(dbContext);
      //   expectedParentObjectType = enumPersistableObjectType.Channel;
      //   descendantObject = await getParentedObjectFromDb<ITopic, ITopicAsJson>(descendantObjectId, descendantObjectType, expectedParentObjectType, firestoreTopicRepository);
      // }

      // From the database, fetch the object that represents the type being sought, based on the descendant object type
      switch (descendantObjectType) {
        case enumPersistableObjectType.Channel:
          const channelObject = await getChannelObjectUsingId(descendantObjectId);
          // return (resolve) the channel object
          resolve(channelObject);
          break;

        case enumPersistableObjectType.DigitalMedia:
          descendantObject = await getDigitalMediaObjectUsingId(descendantObjectId);
          expectedParentObjectType = enumPersistableObjectType.TopicItem;
          break;

        case enumPersistableObjectType.Topic:
          descendantObject = await getTopicObjectUsingId(descendantObjectId);
          expectedParentObjectType = enumPersistableObjectType.Channel;
          break;

        case enumPersistableObjectType.TopicItem:
          descendantObject = await getTopicItemObjectUsingId(descendantObjectId);
          expectedParentObjectType = enumPersistableObjectType.Topic;
          break;

        default:
          // do nothing
          break;
      }

      if (descendantObject === undefined) {
        throw new MdbError(`Unable to get ${descendantObjectType} object from DB with the following id: ${descendantObjectId}`, enumMdbErrorType.InconsistentData);
      } else if (descendantObject.parentObjectType === undefined) {
        throw new MdbError(`Parent type of ${descendantObjectType} object is undefined`, enumMdbErrorType.InconsistentData);
      } else if (descendantObject.parentObjectType !== expectedParentObjectType) {
        throw new MdbError(`Expected parent type of ${descendantObjectType} object: ${expectedParentObjectType}; Actual parent type: ${descendantObject.parentObjectType}`, enumMdbErrorType.InconsistentData);
      } else if (descendantObject.parentId === undefined) {
        throw new MdbError(`${descendantObjectType} parentId is undefined. Cannot proceed.`, enumMdbErrorType.InconsistentData);
      } else {
        // recursively call this method (if the object sought was a Channel, we will have already returned that object via the resolve above)
        resolve(await getChannelFromDescendantObjectId(descendantObject.parentId, descendantObject.parentObjectType));
      }

    } catch (error: any) {
      reject(error);
    }
  });
}

// function getParentedObjectFromDb<TEntity extends IPersistable, TEntityAsJson extends IPersistableAsJson>
//   (id: typeUniqueId, objectType: enumPersistableObjectType, expectedParentType: enumPersistableObjectType, repository: IFirestoreBaseRepository<TEntity, TEntityAsJson>): Promise<TEntity> {
//   return new Promise<TEntity>(async (resolve, reject) => {
//     try {
//       const entityObj: TEntity | undefined = await repository.get(id);

//       if (entityObj === undefined) {
//         throw new MdbError(`Unable to get ${objectType} object from DB with the following id: ${id}`);
//       } else if (entityObj.parentObjectType !== expectedParentType) {
//         throw new MdbError(`Expected parent type of ${objectType} object: ${expectedParentType}; Actual parent type: ${entityObj.parentObjectType}`);
//       } else if (entityObj.parentId === undefined) {
//         throw new MdbError(`${objectType} parentId is undefined. Cannot proceed.`);
//       }

//       resolve(entityObj);
//     } catch (error: any) {
//       reject(error);
//     }
//   });
// }
