import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const hyperLinkDeleteFailureSlice = createSlice({
  name: 'hyperLinkDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    hyperLinkDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    hyperLinkDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { hyperLinkDeleteFailureChange, hyperLinkDeleteFailureClear } = hyperLinkDeleteFailureSlice.actions;

// export the core reducer for the slice
export const hyperLinkDeleteFailureReducer = hyperLinkDeleteFailureSlice.reducer;

export default hyperLinkDeleteFailureSlice.reducer;