import { IAlertInfo } from '../../dataObjects/models/alerts/AlertInfo';
import { IStoreState } from '../../uiMiddleware-redux/store/IStoreState';
import { useAppSelector } from '../../uiMiddleware-redux/store/configureStore';

/**
 * @function useGetAlertInfo Gets (and returns) the current AlertInfo state for the application
 */
export function useGetAlertInfo(): IAlertInfo | null {
  // Fetch the AlertInfo from Redux state
  const alertInfo: IAlertInfo | null = useAppSelector((state: IStoreState) => state.alertInfo);

  return alertInfo;
}
