import React, { PropsWithChildren } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  List as DrillDownIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { listViewItemStyles } from '../../../styles';
import { ITopicItem } from '../../../../dataObjects/models/topics/TopicItem';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { IUserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';


export interface ITopicItemsListViewItemProps extends PropsWithChildren<unknown> {
  topicItem: ITopicItem;
  userPermissions: IUserAccessPermissionsForObject;
  onDelete: (topicItem: ITopicItem) => void;
}

export const TopicItemsListViewItem: React.FC<ITopicItemsListViewItemProps> = (props: ITopicItemsListViewItemProps) => {
  TopicItemsListViewItem.displayName = 'TopicItems List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render TopicItemsListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { topicItem, userPermissions, onDelete } = props;
  const { id, name, description, occurrenceTimestamp } = topicItem;

  // we're going to apply the 'useNavigate' hook to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* Name */}
          <Grid item container xs={8} sm={9} >
            <Typography className={classes.title} variant="h6">
              {name}
            </Typography>
          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={4} sm={3} direction="row" justifyContent="center" alignContent="flex-start">
            {/* TopicItems button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the drilldown button will either be "Drill down" (if user has sufficient permission) or "Drill down disallowed" (insufficient permission)
                title={!userPermissions.hasViewPermission ? TooltipStringAssets.drillDown_Disallowed : TooltipStringAssets.digitalMedia_View}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.drillDownIconButton}`}
                    disabled={!userPermissions.hasViewPermission}
                    onClick={() => id && navigate(`/${id}/digitalMedia`)}
                  >
                    <DrillDownIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Edit button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!userPermissions.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    disabled={!userPermissions.hasEditPermission}
                    onClick={() => id && navigate(`/topicItem/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!userPermissions.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    disabled={!userPermissions.hasEditPermission}
                    onClick={() => topicItem && onDelete(topicItem)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Occurrence Timestamp  */}
        <Grid item container xs={12} >
          <Typography className={classes.occurrenceTimestamp} variant="body1">
            {moment(occurrenceTimestamp).calendar()}
          </Typography>
        </Grid>
        {/* Data Row 3: Description  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default TopicItemsListViewItem;