interface INameValueAndVersion {
  name: string;
  value: string;
  version: string;
}

interface INameAndVersion {
  name: string;
  version: number;
}


const operatingSystemChoices: Array<INameValueAndVersion> = [
  { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
  { name: 'Windows', value: 'Win', version: 'NT' },
  { name: 'iPhone', value: 'iPhone', version: 'OS' },
  { name: 'iPad', value: 'iPad', version: 'OS' },
  { name: 'Kindle', value: 'Silk', version: 'Silk' },
  { name: 'Android', value: 'Android', version: 'Android' },
  { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
  { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
  { name: 'Macintosh', value: 'Mac', version: 'OS X' },
  { name: 'Linux', value: 'Linux', version: 'rv' },
  { name: 'Palm', value: 'Palm', version: 'PalmOS' }
];

const browserChoices: Array<INameValueAndVersion> = [
  { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
  { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
  { name: 'Safari', value: 'Safari', version: 'Version' },
  { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
  { name: 'Opera', value: 'Opera', version: 'Opera' },
  { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
  { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
];

export function getDeviceAndBrowserInfo(): string {
  const platform: string = navigator.platform;
  const userAgent: string = navigator.userAgent;
  const appVersion: string = navigator.appVersion;
  const vendor: string = navigator.vendor;

  const allNavigatorInfo: string = [platform, userAgent, appVersion, vendor].join(' ');
  // console.log(`(newGetDeviceAndBrowserInfo) allNavigatorInfo: ${allNavigatorInfo}`);

  const osNameAndVersion: INameAndVersion = teaseOutMatchingNameAndVersion(allNavigatorInfo, operatingSystemChoices);
  const browserNameAndVersion: INameAndVersion = teaseOutMatchingNameAndVersion(allNavigatorInfo, browserChoices);

  let deviceAndBrowserInfo = '';
  deviceAndBrowserInfo += 'os.name = ' + osNameAndVersion.name + '; ';
  deviceAndBrowserInfo += 'os.version = ' + osNameAndVersion.version + '; ';
  deviceAndBrowserInfo += 'browser.name = ' + browserNameAndVersion.name + '; ';
  deviceAndBrowserInfo += 'browser.version = ' + browserNameAndVersion.version + '; ';

  deviceAndBrowserInfo += 'navigator.userAgent = ' + userAgent + '; ';
  deviceAndBrowserInfo += 'navigator.appVersion = ' + appVersion + '; ';
  deviceAndBrowserInfo += 'navigator.platform = ' + platform + '; ';
  deviceAndBrowserInfo += 'navigator.vendor = ' + vendor + '; ';

  return deviceAndBrowserInfo;
}

/**
 * @function teaseOutMatchingNameAndVersion From all navigator info and an array of name/value/version details, teases out the matching Name and Version details.
 * @param {string} allNavigatorInfo Concatenated navitor information (navigator.platform, navigator.userAgent , navigator.appVersion , navigator.vendor)
 * @param {Array<INameValueAndVersion>} nameValueAndVersionArray An array of Name, Value, and Version information
 */
function teaseOutMatchingNameAndVersion(allNavigatorInfo: string, nameValueAndVersionArray: Array<INameValueAndVersion>): INameAndVersion {
  // set up a default object to return, in case no match is found
  let nameAndVersionToReturn: INameAndVersion = { name: 'unknown', version: 0 };

  // loop through the array of NameValueAndVersion information to try and find a match in the 'allNavigatorInfo' string
  for (let i: number = 0; i < nameValueAndVersionArray.length; i += 1) {

    // try to match the current 'Value' string with something in the 'allNavigatorInfo' string
    const regex: RegExp = new RegExp(nameValueAndVersionArray[i].value, 'i');
    const match: boolean = regex.test(allNavigatorInfo);

    // if we found a match
    if (match) {
      // parse out the version for a match
      const regexv: RegExp = new RegExp(nameValueAndVersionArray[i].version + '[- /:;]([\\d._]+)', 'i');
      let regExMatches: RegExpMatchArray | null = allNavigatorInfo.match(regexv);

      // declare variables to use in matching up the version
      let matchedResultsString: string = '';

      if (regExMatches) {
        if (regExMatches[1]) {
          matchedResultsString = regExMatches[1];
        }
      }

      // default the version to an empty value, but if the actual version number is found, it will be reformatted to a XXX.YYYYYYYY format
      let version = '';
      let matchedResultsStringArray: Array<string>;

      // normalize the version to a format of XXX.YYYYYYYY (eg, if the original format is something like 84.0.4147.89, the normalized form will be 84.0414789)
      if (matchedResultsString) {
        matchedResultsStringArray = matchedResultsString.split(/[._]+/);
        for (let j: number = 0; j < matchedResultsStringArray.length; j += 1) {
          if (j === 0) {
            version += matchedResultsStringArray[j] + '.';
          } else {
            version += matchedResultsStringArray[j];
          }
        }
      } else {
        version = '0';
      }

      nameAndVersionToReturn = { name: nameValueAndVersionArray[i].name, version: parseFloat(version) };
      break; // we have the information we need, so break from 'for (let i = ...)' loop
    }
  }

  return nameAndVersionToReturn;
}