import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import { useController } from 'react-hook-form';
// import * as yup from 'yup';
// import { Control, DefaultValues, Resolver, useController, UseFormTrigger } from 'react-hook-form';
// import { ControlsStringAssets } from '../../../assets/stringAssets';

// const schema = yup.object().shape({
//   email: yup
//     .string()
//     .email(ControlsStringAssets.emailInvalid)
//     .required(ControlsStringAssets.emailRequired),
// });

// type ControlValue = yup.InferType<typeof schema>;

// // interface IEmailInputProps extends PropsWithChildren<unknown> {
// interface IEmailInputProps {
//   control: Control<ControlValue>;
//   trigger: UseFormTrigger<ControlValue>;
//   defaultValue: DefaultValues<ControlValue>["email"];
// }

// This component is a TextField that is typed as an email input. It uses the react-hook-form 'useController' hook to enable 
// finer-grained evaluation of the field.
// The primary purpose for using this component is to have greater control over the field's validation, particularly when the
// browser performs an Autofill on the field.
const EmailInput: React.FC<any> = (({
  control,
  defaultValue,
  trigger,
  ...remainingProps
}) => {
  const { field, fieldState } = useController({ control, name: "email" });

  // The following useEffect is intended to cause the field to be evaluated/validated, via trigger() even if the field has
  // not been "touched" (visited or changed) but has an Autofill value supplied by the browser. 
  // Note: It's unclear whether this works for every type of browser.
  // Reference: https://codesandbox.io/s/browser-autofill-validation-vds0d?file=/src/App.tsx:851-1056
  useEffect(() => {
    if (field.value !== defaultValue && !fieldState.isTouched) {
      trigger("email", { shouldFocus: true });
    }
  }, [defaultValue, field.value, fieldState.isTouched, trigger]);
  
  // An 'onChange' handler for the field
  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void {
    field.onChange(event.target.value); // send data back to hook form
  }

  return (
    <TextField
      {...field} // includes 'name' and 'value' properties for the field
      {...remainingProps} // additional properties for the control that were passed in via the Form
      onChange={event => handleChange(event)} // 'Change' handler for the field
    />
  );
});

export default EmailInput;
