import { put } from "redux-saga/effects";
import { ISaveVideoLinkRequest } from '../../../dataObjects/models/serviceRequests/videoLink/SaveVideoLinkRequest';
import { IVideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult, uploadFileToCloudStorage } from "../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions";
import { ISaveVideoLinkWorkflowState } from "../../store/SaveVideoWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";
import { videoLinkFileUploadResult, videoLinkSaveError, videoLinkSaveWithoutFileUploadRequest, videoLinkSaveWorkflowStateChange } from "../../slices/videoLink/videoLinkSaveStatusSlice";


export function* saveVideoLinkWithFileUploadWorkerSaga(saveVideoLinkRequest: ISaveVideoLinkRequest) {
  try {
    // console.info('(saveVideoLinkWithFileUploadWorkerSaga) Entered saveVideoLinkWithFileUploadWorkerSaga');

    // // used for debugging timing
    // const startTime: number = Date.now();

    // if the ISaveVideoLinkRequest is defined...
    if (saveVideoLinkRequest !== undefined) {
      // get the IFileUploadRequest from the ISaveVideoLinkRequest object
      const fileUploadRequest: IFileUploadRequest | undefined = saveVideoLinkRequest.fileUploadRequest;

      // get the VideoLink object from the request
      const videoLink: IVideoLink | undefined = saveVideoLinkRequest.videoLink;

      // only proceed if both the IFileUploadRequest and VideoLink objects have been defined
      if (fileUploadRequest !== undefined && videoLink !== undefined) {
        const fileUploadResult: ICloudStorageFileUploadResult = yield uploadFileToCloudStorage(fileUploadRequest.fileToUpload, fileUploadRequest.userId,
          fileUploadRequest.fileClass, fileUploadRequest.fileUniqueId, fileUploadRequest.uploadProgressCallback);

        // console.info(`(saveVideoLinkWithFileUploadWorkerSaga) Elapsed time after uploading file: ${Date.now() - startTime} milliseconds`);

        // update store state...      
        const saveVideoLinkWorkflowState: ISaveVideoLinkWorkflowState = {
          videoLink: saveVideoLinkRequest.videoLink,
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.videoLink_SaveRequested,
          fileUploadRequest: fileUploadRequest
        }
        yield put(videoLinkSaveWorkflowStateChange(saveVideoLinkWorkflowState));

        // dispatch action to indicate that there is a fileUploadResult
        yield put(videoLinkFileUploadResult(fileUploadResult));

        // console.info(`(saveVideoLinkWithFileUploadWorkerSaga) Elapsed time after dispatching fileUploadResult: ${Date.now() - startTime} milliseconds`);

        // set the baseStoragePath and downloadUrl for the VideoLink object
        saveVideoLinkRequest.videoLink.baseStoragePath = fileUploadResult.baseStoragePath;
        saveVideoLinkRequest.videoLink.downloadUrl = fileUploadResult.downloadUrl;
        saveVideoLinkRequest.videoLink.mediaType = fileUploadResult.mediaType;

        // // console.info(`(saveVideoLinkWithFileUploadWorkerSaga) Elapsed time after verifying small generated files through call(): ${Date.now() - startTime} milliseconds`);

        // now, dispatch an action to save the VideoLink object (since the video file save operation has already taken place)
        yield put(videoLinkSaveWithoutFileUploadRequest(saveVideoLinkRequest));
      }

    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(videoLinkSaveError(error));
  }
}