import { AccessPermissions, IAccessPermissions } from "../../models/collaboration/ObjectUserPermissions";
import { IAudioLink } from "../../models/digitalMedia/AudioLink";
import { typeUniqueId } from "../../types";
import { IAudioLinkViewModel } from ".";


export class AudioLinkViewModel implements IAudioLinkViewModel {

  /**
   * @method Constructor method
   * @param {IAudioLink} audioLink A reference to the underlying AudioLink object
   * @param {typeUniqueId} parentTopicItemId Id of the TopicItem that is the parent of the digital media object
   * @param {typeUniqueId} ancestorTopicId Id of the Topic that is a direct ancestor of the digital media object
   * @param {typeUniqueId} parentTopicItemId Id of the Channel that is a direct ancestor of the digital media object
   * @param {IAccessPermissions} (optional) accessPermissionToAncestorChannel The current user's access permission to the ancestor channel.
   */
  constructor(
    audioLink: IAudioLink,
    parentTopicItemId: typeUniqueId,
    ancestorTopicId: typeUniqueId,
    ancestorChannelId: typeUniqueId,
    accessPermissionToAncestorChannel?: IAccessPermissions
  ) {

    // assign properties from ctor parameters
    this._audioLink = audioLink;
    this._parentTopicItemId = parentTopicItemId;
    this._ancestorTopicId = ancestorTopicId;
    this._ancestorChannelId = ancestorChannelId;

    if (accessPermissionToAncestorChannel) {
      this._accessPermissionToAncestorChannel = accessPermissionToAncestorChannel;
    }
  }


  /*-----------------------------------------------*/
  /**
   * @property {IAudioLink} _audioLink A reference to the underlying AudioLink object
   */
  private _audioLink: IAudioLink;

  /**
   * @method audioLink Getter method for _audioLink
   */
  get audioLink(): IAudioLink {
    return this._audioLink;
  }

  /**
   * @method audioLink Setter method for _audioLink
   * @param {IAudioLink} value The input value for setting _audioLink
   */
  set audioLink(value: IAudioLink) {
    this._audioLink = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _parentTopicItemId Id of the TopicItem that is the parent of the digital media object
   */
  private _parentTopicItemId: typeUniqueId;

  /**
   * @method parentTopicItemId Getter method for _parentTopicItemId
   */
  get parentTopicItemId(): typeUniqueId {
    return this._parentTopicItemId;
  }

  /**
   * @method parentTopicItemId Setter method for _parentTopicItemId
   * @param {typeUniqueId} value The input value for setting _parentTopicItemId
   */
  set parentTopicItemId(value: typeUniqueId) {
    this._parentTopicItemId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _ancestorTopicId Id of the Topic that is a direct ancestor of the digital media object
   */
  private _ancestorTopicId: typeUniqueId;

  /**
   * @method ancestorTopicId Getter method for _ancestorTopicId
   */
  get ancestorTopicId(): typeUniqueId {
    return this._ancestorTopicId;
  }

  /**
   * @method ancestorTopicId Setter method for _ancestorTopicId
   * @param {typeUniqueId} value The input value for setting _ancestorTopicId
   */
  set ancestorTopicId(value: typeUniqueId) {
    this._ancestorTopicId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _ancestorChannelId Id of the Channel that is a direct ancestor of the digital media object
   */
  private _ancestorChannelId: typeUniqueId;

  /**
   * @method ancestorChannelId Getter method for _ancestorChannelId
   */
  get ancestorChannelId(): typeUniqueId {
      return this._ancestorChannelId;
  }

  /**
   * @method ancestorChannelId Setter method for _ancestorChannelId
   * @param {typeUniqueId} value The input value for setting _ancestorChannelId
   */
  set ancestorChannelId(value: typeUniqueId) {
      this._ancestorChannelId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IAccessPermissions} _accessPermissionToAncestorChannel The current user's access permission to the ancestor channel.
   */
  private _accessPermissionToAncestorChannel: IAccessPermissions = new AccessPermissions();

  /**
   * @method accessPermissionToAncestorChannel Getter method for _accessPermissionToAncestorChannel
   */
  get accessPermissionToAncestorChannel(): IAccessPermissions {
      return this._accessPermissionToAncestorChannel;
  }

  /**
   * @method accessPermissionToAncestorChannel Setter method for _accessPermissionToAncestorChannel
   * @param {IUserAccessPermissionsForObject} value The input value for setting _accessPermissionToAncestorChannel
   */
  set accessPermissionToAncestorChannel(value: IAccessPermissions) {
      this._accessPermissionToAncestorChannel = value;
  }
  /*-----------------------------------------------*/

}