import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userAccountExpungeStatusChange } from './userAccountExpungeStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: string | null = null;

export const userAccountExpungeDataSlice = createSlice({
  name: 'userAccountExpungeData',
  initialState,
  reducers: {
    // action has a payload with string (an email address)
    userAccountExpungeRequest(state, action) {
      // set userAccountExpunge data into state for use during the userAccountExpunge process
      return action.payload;
    },

    userAccountExpungeDataClear(state) {
      return null; // action.payload;
    },
  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(userAccountExpungeStatusChange, (state, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            // do nothing
            break;

          case enumWorkflowState.Failure:
          case enumWorkflowState.Success:
            // clear the userAccountExpunge data from state
            state = null;
            break;
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { userAccountExpungeRequest, userAccountExpungeDataClear } = userAccountExpungeDataSlice.actions;

// export the core reducer for the slice
export const userAccountExpungeDataReducer = userAccountExpungeDataSlice.reducer;

export default userAccountExpungeDataSlice.reducer;
