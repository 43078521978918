import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUser } from '../../../dataObjects/models/users/User';

// export interface ISaveUserStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const userSaveStatusSlice = createSlice({
  name: 'userSaveStatus',
  initialState,
  reducers: {
    // The userSaveRequest triggers the saving of a User. It's not really associated with the Store State
    // for the 'saveUserStatus' store variable; however, it is used to both trigger the User Save Request -AND-
    // to call the userSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveUserData') and create another slice with a userSaveRequest action. 
    userSaveRequest: (state, action: PayloadAction<IUser>) => {
      userSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IUser object or null
    userSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userSaveRequest, userSaveStatusChange } = userSaveStatusSlice.actions;

// export the core reducer for the slice
export const userSaveStatusReducer = userSaveStatusSlice.reducer;

export default userSaveStatusSlice.reducer;