import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { ICategoryAssociation, ICategoryAssociationAsJson } from '.';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';


/**
 * @class CategoryAssociation Defines an association between a Category and an associated Object, including the Object's category type
 * {@linkcode ICategoryAssociation}
 */
export class CategoryAssociation extends VersionAwarePersistable implements ICategoryAssociation {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or category) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {typeUniqueId} categoryId The category instance involved in the association
  //  * @param {enumCategoryType} categoryType The type of category represented by the associated Object
   * @param {typeUniqueId} objectId The Id of the object (eg, User, Channel, Topic, TopicItem) associated with (a parent of) the category
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    categoryId: typeUniqueId,
    // categoryType: enumCategoryType,
    objectId: typeUniqueId,
    userPersistenceData?: IUserPersistenceData,
  ) {
    super(ownerId, enumPersistableObjectClassName.CategoryAssociation, enumPersistableObjectType.CategoryAssociation, id, parentObjectType, parentId, objectState, userPersistenceData);

    this._categoryId = categoryId;
    // this._categoryType = categoryType;
    this._objectId = objectId;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} categoryId The category instance involved in the association
   */
  private _categoryId: typeUniqueId = '';

  // getter for _propName
  get categoryId(): typeUniqueId {
    return this._categoryId;
  }

  // setter for _propName
  set categoryId(value: typeUniqueId) {
    this._categoryId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _objectId The Id of the object (eg, User, Channel, Topic, TopicItem) associated with (a parent of) the category
   */
  private _objectId: string = '';

  /**
   * @method objectId Getter method for _objectId
   */
  get objectId(): typeUniqueId {
    return this._objectId;
  }

  /**
   * @method objectId Setter method for _objectId
   * @param {typeUniqueId} value Input value for setting _objectId
   */
  set objectId(value: typeUniqueId) {
    this._objectId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ICategoryAssociation} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ICategoryAssociation {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ICategoryAssociation = Object.create(CategoryAssociation.prototype);
    Object.assign(copyOfObject, this);

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ICategoryAssociationAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: ICategoryAssociationAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.categoryId = this.categoryId;
      // jsonObject.categoryType = this.categoryType;
      jsonObject.objectId = this.objectId;

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ICategoryAssociationAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ICategoryAssociationAsJson, includeContainedObjects: boolean = true): ICategoryAssociation {
    try {
      // create a new instance of this class
      let categoryObject: CategoryAssociation = Object.create(CategoryAssociation.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      categoryObject = super.fromJSONProtected(categoryObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.categoryId) {
        categoryObject.categoryId = jsonObject.categoryId;
      }

      // if (jsonObject.categoryType) {
      //   categoryObject.categoryType = (jsonObject.categoryType === 'undefined' ? enumCategoryType.Unassigned : jsonObject.categoryType);
      // }

      if (jsonObject.objectId) {
        categoryObject.objectId = jsonObject.objectId;
      }

      return categoryObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
