import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchTopicItemsFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchTopicItemsFailureSlice = createSlice({
  name: 'fetchTopicItemsFailure',
  initialState,
  reducers: {
    setFetchTopicItemsFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchTopicItemsFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchTopicItemsFailure, clearFetchTopicItemsFailure } = fetchTopicItemsFailureSlice.actions;

// export the core reducer for the slice
export const fetchTopicItemsFailureReducer = fetchTopicItemsFailureSlice.reducer;

export default fetchTopicItemsFailureSlice.reducer;