import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreChannelRepository_Ext, IFirestoreChannelRepository_Ext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository";


/**
 * @function getChannelsForUser_onSnapshot Triggers a request for a snapshot with a collection of Channels for a given User
 * @param {typeUniqueId} userId {}
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback method that receives document data as a parm and has a void return value 
 * @returns {() => void} An 'unsubscribe callback' method that is to be called when the snapshot is no longer needed.
 */
export function getChannelsForUser_onSnapshot(userId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      const firestoreChannelRepository_Ext: IFirestoreChannelRepository_Ext = new FirestoreChannelRepository_Ext(firestoreObj);

      const unsubscribeCallback: () => void = await firestoreChannelRepository_Ext.getChannelsForUser_onSnapshot(userId, callback);
      // console.log(`getChannelsForUser_onSnapshot (UI middleware service method) before returning, unsubscribeCallback: ${unsubscribeCallback}`);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}
