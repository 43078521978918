import { enumWorkflowState } from '../../dataObjects/enums';
import { IStoreState } from '../../uiMiddleware-redux/store/IStoreState';
import { IReduxWorkflowState } from './hookReturnObjects/ReduxWorkflowState';
import { IMdbError } from '../../errorObjects/MdbError';
import { useAppSelector } from '../../uiMiddleware-redux/store/configureStore';

export function useReduxWorkflowState(
  workflowStateObj: (state: IStoreState) => enumWorkflowState | null | undefined,
  failureErrorStateObj: (state: IStoreState) => IMdbError | null | undefined
): IReduxWorkflowState {

  const workflowState: enumWorkflowState | null | undefined = useAppSelector(workflowStateObj) as enumWorkflowState | null | undefined;
  const errorState: IMdbError | null | undefined = useAppSelector(failureErrorStateObj) as IMdbError | null | undefined;

  const reduxWorkflowStateObj: IReduxWorkflowState = {
    workflowState,
    errorState
  }

  return reduxWorkflowStateObj;

}
