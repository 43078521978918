import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchTopicItemsStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchTopicItemsStatusSlice = createSlice({
  name: 'fetchTopicItemsStatus',
  initialState,
  reducers: {
    setFetchTopicItemsStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchTopicItemsStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchTopicItemsStatus, clearFetchTopicItemsStatus } = fetchTopicItemsStatusSlice.actions;

// export the core reducer for the slice
export const fetchTopicItemsStatusReducer = fetchTopicItemsStatusSlice.reducer;

export default fetchTopicItemsStatusSlice.reducer;