import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IUser, IUserAsJson } from "../../../../dataObjects/models/users/User";
import { IFirestoreUserRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository";


export function saveUser(user: IUser): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // // *** FOR TESTING PURPOSES: Induce an artificial error ***
      // await setTimeout(() => {
      //   // do nothing
      // }, 2000);
      // // throw an exception
      // throw new MdbError('(saveUser) This is an artificial error induced for testing purposes.')
      // // *** END OF TESTING BLOCK

      // get a User repository from the repository factory
      const firestoreUserRepository: IFirestoreUserRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
        IFirestoreBaseRepository<IUser, IUserAsJson>;

      // determine if the User already exists by calling the repository get() method to try and get the object
      const existingUser: IUser | undefined = await firestoreUserRepository.get(user.id);

      // If the User wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingUser === undefined) {
        // call repository method to create the User
        await firestoreUserRepository.create(user);
      } else {
        // call repository method to update the User
        await firestoreUserRepository.update(user);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}