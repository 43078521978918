import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IUser, IUserAsJson } from "../../../../dataObjects/models/users/User";
import { IFirestoreUserRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository";
import { typeUniqueId } from "../../../../dataObjects/types";
import { enumThemeMode } from "../../../../dataObjects/enums";
import { enumMdbErrorType } from "../../../../errorObjects/enums";
import { MdbError } from "../../../../errorObjects/MdbError";


export function setThemeModeForUserId(userId: typeUniqueId, newThemeMode: enumThemeMode): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // // *** FOR TESTING PURPOSES: Induce an artificial error ***
      // await setTimeout(() => {
      //   // do nothing
      // }, 2000);
      // // throw an exception
      // throw new MdbError('(saveUser) This is an artificial error induced for testing purposes.')
      // // *** END OF TESTING BLOCK

      // get a User repository from the repository factory
      const firestoreUserRepository: IFirestoreUserRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
        IFirestoreBaseRepository<IUser, IUserAsJson>;

      // determine if the User exists by calling the repository get() method to try and get the object
      const existingUser: IUser | undefined = await firestoreUserRepository.get(userId);

      // if the User was found...
      if (existingUser !== undefined) {
        // if userSettings for the user is a valid object
        if (existingUser.userSettings !== undefined) {
          // set the themeMode in the userSettings
          existingUser.userSettings.themeMode = newThemeMode;

          // call repository method to update the User
          await firestoreUserRepository.update(existingUser);
        } else {
          throw new MdbError(`The userSettings for the user with Id of ${userId} is not valid; therefore, cannot set user's themeMode to '${newThemeMode}'`, enumMdbErrorType.InconsistentData);
        }
      } else {
        throw new MdbError(`Unable to find user with Id of ${userId}; therefore, cannot set user's themeMode to '${newThemeMode}'`, enumMdbErrorType.RequiredDataNotFound);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}