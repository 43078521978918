import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { enumAlertType } from '../../../enums';
import { ISocialMediaPost } from '../../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import GenericToggleViewContainer from '../../GenericToggleViewContainer/GenericToggleViewContainer';
import { SocialMediaPostsCardGridView } from '../SocialMediaPostsCardGridView/SocialMediaPostsCardGridView';
import SocialMediaPostsListView from '../SocialMediaPostsListView/SocialMediaPostsListView';
import { styled } from '@mui/system';
import { ISocialMediaPostViewModel } from '../../../../dataObjects/viewModels/socialMediaPostViewModel';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { socialMediaPostDeleteRequest, socialMediaPostDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/socialMediaPost/socialMediaPostDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area padding at the top of the view
const StyledBoxForPaddingAtopView = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// a styled Container for the View Container
const StyledContainerForViewContainer = styled((props) => (
  <Container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface ISocialMediaPostsViewProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId; // the parent DigitalMedia for the SocialMediaPosts
  socialMediaPostsToDisplay: Array<ISocialMediaPostViewModel>;
  allowAddingNewItems: boolean;
  socialMediaPostsDataLoading: boolean;
}

const SocialMediaPostsView: React.FC<ISocialMediaPostsViewProps> = (props: ISocialMediaPostsViewProps) => {

  SocialMediaPostsView.displayName = 'SocialMediaPosts View';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { parentId, socialMediaPostsToDisplay, allowAddingNewItems, socialMediaPostsDataLoading } = props;

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the SocialMediaPosts associcated with the current Digital Media
  // const [socialMediaPosts, setSocialMediaPosts] = useState<Array<ISocialMediaPost>>(socialMediaPostsToDisplay);
  const [socialMediaPostViewModels, setSocialMediaPostViewModels] = useState<Array<ISocialMediaPostViewModel>>(socialMediaPostsToDisplay);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(socialMediaPostsDataLoading);
  // if user is deleting a SocialMediaPost, the SocialMediaPost to delete
  const [socialMediaPostToDelete, setSocialMediaPostToDelete] = useState<ISocialMediaPost | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  /**
   * @function resetDeleteSocialMediaPostStatus Resets the Delete status in Redux state
   */
  const resetDeleteSocialMediaPostStatus: () => void = useCallback(() => {
    dispatch(socialMediaPostDeleteStatusChange(null));
  }, [dispatch])

  // use a custom hook to evaluate the redux state for the deleteSocialMediaPost workflow and any deleteSocialMediaPost error
  const { workflowState: deleteSocialMediaPostStatus, errorState: deleteSocialMediaPostFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteSocialMediaPostStatus, (state: IStoreState) => state.deleteSocialMediaPostFailure);

  useEffect(() => {
    if (socialMediaPostViewModels !== socialMediaPostsToDisplay) {
      setSocialMediaPostViewModels(socialMediaPostsToDisplay);
    }
  }, [socialMediaPostsToDisplay]);

  useEffect(() => {
    if (dataLoading !== socialMediaPostsDataLoading) {
      setDataLoading(socialMediaPostsDataLoading);
    }
  }, [socialMediaPostsDataLoading]);

  /**
   * @method handleSocialMediaPostDelete Handles requests to delete a Channel
   * @param socialMediaPost The SocialMediaPost to be deleted
   */
  async function handleSocialMediaPostDelete(socialMediaPost: ISocialMediaPost): Promise<void> {
    // set the socialMediaPost to be deleted into local state
    setSocialMediaPostToDelete(socialMediaPost);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleSocialMediaPostDeleteConfirmed(): void {
    // using the socialMediaPost object to be deleted from the local state, dispatch an action to delete the socialMediaPost object
    if (socialMediaPostToDelete !== undefined) {
      dispatch(socialMediaPostDeleteRequest(socialMediaPostToDelete));
    }

    // call method to reset the socialMediaPost delete local state details
    socialMediaPostDeleteReset();
  }

  function handleSocialMediaPostDeleteCanceled(): void {
    // call method to reset the socialMediaPost delete local state details
    socialMediaPostDeleteReset();
  }

  function socialMediaPostDeleteReset() {
    // reset the socialMediaPost to be deleted to 'undefined' in local state, since we're done with the object
    setSocialMediaPostToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteTopicStatus
      resetDeleteSocialMediaPostStatus();

      // notify user via a Beacon notification that the audioLink has been deleted
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.socialMediaPost_DeleteConfirmationHeader, MessagesStringAssets.socialMediaPost_DeleteSuccess)));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteSocialMediaPostStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.socialMediaPosts_FetchingRequested;
  } else {
    // if the workflow is in the midst of performing deletion of a socialMediaPost, prepare an alert message fragment to display progress message(s)
    if (deleteSocialMediaPostStatus !== undefined && deleteSocialMediaPostStatus !== null) {
      if (deleteSocialMediaPostStatus === enumWorkflowState.Requested || deleteSocialMediaPostStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.socialMediaPost_DeleteRequested;
      } else if (deleteSocialMediaPostStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteSocialMediaPostStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.socialMediaPost_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteSocialMediaPostFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteSocialMediaPostFailure && deleteSocialMediaPostFailure instanceof Error) {
          failureMessage = deleteSocialMediaPostFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }


  // Establish the text to be displayed on the '+ New' button
  let addNewButtonText: string = '';
  if (allowAddingNewItems) {
    addNewButtonText = ControlsStringAssets.socialMediaPostNew;
  }


  return (
    <>
      <StyledBoxForPaddingAtopView />
      <GenericToggleViewContainer
        viewTitle={PageAndViewTitleStringAssets.viewTitle_SocialMediaPosts}
        addNewButtonText={addNewButtonText}
        urlForAddNewButtonNavigation={`/${parentId}/socialMediaPost`}
      >

        {/* Container for embedded data view */}
        <StyledContainerForViewContainer >

          {/* If there are SocialMediaPosts to display and the current view selection is CardsGridView, display the SocialMediaPostsCardGridView */}
          {socialMediaPostViewModels &&
            (collectionViewType === enumCollectionViewType.CardsGridView) &&
            <SocialMediaPostsCardGridView socialMediaPostViewModels={socialMediaPostViewModels} onDelete={handleSocialMediaPostDelete} />
          }

          {/* If there are SocialMediaPosts to display and the current view selection is ListView, display the SocialMediaPostsListView */}
          {socialMediaPostViewModels &&
            (collectionViewType === enumCollectionViewType.ListView) &&
            <SocialMediaPostsListView socialMediaPostViewModels={socialMediaPostViewModels} onDelete={handleSocialMediaPostDelete} />
          }
        </StyledContainerForViewContainer>

        {/* Delete SocialMediaPost Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.socialMediaPost_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.socialMediaPost_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleSocialMediaPostDeleteConfirmed}
          onNonAcceptance={handleSocialMediaPostDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericToggleViewContainer>
    </>
  );
}

export default SocialMediaPostsView;
