import { take } from "redux-saga/effects";
import { saveDocumentLinkWithFileUploadWorkerSaga } from "./saveDocumentLinkWithFileUploadWorkerSaga";
import { documentLinkSaveWithFileUploadRequest } from "../../slices/documentLink/documentLinkSaveStatusSlice";

/**
 * @function saveDocumentLinkWithFileUploadWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an DocumentLink along with a file upload associated with it.
 */
export function* saveDocumentLinkWithFileUploadWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the documentLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveDocumentLinkRequestData = yield take(documentLinkSaveWithFileUploadRequest);
    const { payload: saveDocumentLinkRequest } = saveDocumentLinkRequestData;

    yield saveDocumentLinkWithFileUploadWorkerSaga(saveDocumentLinkRequest);
  }
}