import { typeUniqueId } from "../../../../dataObjects/types";
import { expungeUserAccountAndAllResources } from "../../../securityServices/userAccount/userAccountActions";

/**
 * @function expungeUserAccountFromUserId Expunges a User Account and all associated resources, given a userId as a parameter
 * @param {typeUniqueId} userId userId for the user account to be expunged
 * @returns {Promise<void>} A Promise, providing for an asynchronous process.
 */
export function expungeUserAccountFromUserId(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      await expungeUserAccountAndAllResources(userId);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

