import React, { useState } from 'react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import AppHeader from './components/headerAndFooter/AppHeader/AppHeader';
import { RoutesFragment } from './components/jsxFragments';
import CollapsibleMenuDrawer from './components/headerAndFooter/CollapsibleMenuDrawer/CollapsibleMenuDrawer';
import { Box, CssBaseline, Paper } from '@mui/material';
import { AppFooter } from './components/headerAndFooter/AppFooter/AppFooter';
import SnackbarWrapper from './components/notifications/SnackbarWrapper/SnackbarWrapper';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import { IAppThemesContextData, useAppThemesContext } from './components/providersAndContexts/themes';
import { HeaderAndFooterStringAssets } from './assets/stringAssets';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Paper for the overall application page
const StyledPaperForOverallPage = styled((props) => (
  <Paper
    {...props}
  />
))(({ theme }) => ({
  height: '100vh'
}));

// a styled Box (equivalent to a <div>), providing an area for the overall view of the application
const StyledBoxForOverallViewArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}));

// a styled Box (equivalent to a <div>), providing an area for the App Header
const StyledBoxForAppHeaderArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Box (equivalent to a <div>), providing an area for presenting details of a specific feature
const StyledBoxForFeatureDetails = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  zIndex: 8,
  position: 'relative',
  minWidth: '100%',
  minHeight: '100%',
}));

// a styled Box (equivalent to a <div>), providing an area for the App Footer
const StyledBoxForAppFooterArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  zIndex: 8,
  justifySelf: 'flex-end',
  marginTop: 'auto',
}));

// declare a default unsubscribe callback function that is an empty (No Op) function. Will be used for setting a default value.
const defaultUnsubscribeCallback: () => void = () => {
  // some default no-op function
  const i: number = 0;
};


// const App: React.FC = () => {
const App: React.FC = (props) => {

  App.displayName = 'App';

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('Entered/Refreshed App component');

  // // This code can be used to perform a forced logout
  // const currentUserContext: ICurrentUserContextData = useCurrentUserContext();
  // if (currentUserContext.authenticationStatus !== enumAuthenticationStatus.None) {
  //   (async () => {
  //     try {
  //       displayConsoleLogs && console.log(`%c App. Ready to call Logout service function.`, 'background: #606; color: #fff');

  //       const logoutStatus = await serviceLogout();

  //       displayConsoleLogs && console.log(`%c App. Returned from call to Logout service function. logoutStatus: ${logoutStatus}`, 'background: #606; color: #fff');
  //     } catch (error: any) {
  //       displayConsoleLogs && console.error(`%c App. Error after calling Logout service function: ${error.toString()}`, 'background: #606; color: #fff');
  //     }
  //   })();
  // }

  // use a custom hook to get the App Themes information from the AppThemesProvider
  const appThemesContextData: IAppThemesContextData = useAppThemesContext();

  displayConsoleLogs && console.log(`%c App. Fetched appThemesContextData with useAppThemesContext(): \n${JSON.stringify(appThemesContextData)}`, 'background: #606; color: #fff');

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  function logout() {
    // close the Menu Drawer
    showMenuDrawer(false);

    // route to the Logout Page
    navigate('/logout');
  }

  /**
   * @function showMenuDrawer Shows or hides Menu Drawer
   * @param show 
   */
  function showMenuDrawer(show: boolean): void {
    // console.log(`App: showMenuDrawer setting openDrawer to ${show}`);
    setOpenDrawer(show);
  }

  function closeMenuDrawer(): void {
    showMenuDrawer(false);
  }

  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  displayConsoleLogs && console.log(`%c App. Before rendering. appThemesContextData.themePreparationComplete: ${appThemesContextData.state.themePreparationComplete}; appThemesContextData.currentTheme: \n${JSON.stringify(appThemesContextData.state.currentTheme)}`, 'background: #606; color: #fff');

  const appTitle: string = HeaderAndFooterStringAssets.appTitle;

  return (
    <>
      <StyledEngineProvider injectFirst>
        {/* <AppThemesProvider> */}
        {/* {themePreparationComplete && currentTheme && */}
        {/* {currentTheme &&
          <ThemeProvider theme={currentTheme}> */}
        {appThemesContextData.state.currentTheme &&
          <ThemeProvider theme={appThemesContextData.state.currentTheme}>

            {/* Apply CssBaseline to allow Material UI to control the entire page (consistent with theming, eliminating weird padding, etc.) */}
            <CssBaseline />

            <StyledPaperForOverallPage>
              <StyledBoxForOverallViewArea>

                <StyledBoxForAppHeaderArea>
                  {/* <AppHeader onShowMenuDrawer={showMenuDrawer} onLogout={logout} appTitle='MyDigiBrain' /> */}
                  <AppHeader onShowMenuDrawer={showMenuDrawer} onLogout={logout} appTitle={appTitle} />
                  {/* <AppHeader onShowMenuDrawer={showMenuDrawer} onLogout={logout} appTitle={HeaderAndFooterStringAssets.appTitle} /> */}
                </StyledBoxForAppHeaderArea>

                <SnackbarWrapper />

                <main>
                  <CollapsibleMenuDrawer onCloseMenuDrawer={closeMenuDrawer} onLogout={logout} open={openDrawer}></CollapsibleMenuDrawer>

                  <StyledBoxForFeatureDetails>
                    < RoutesFragment />
                  </StyledBoxForFeatureDetails>
                </main>

                <StyledBoxForAppFooterArea>
                  <AppFooter />
                </StyledBoxForAppFooterArea>
              </StyledBoxForOverallViewArea>
            </StyledPaperForOverallPage>
          </ThemeProvider>
        }
        {!appThemesContextData.state.currentTheme &&
          <>
            Initializing...
          </>
        }
        {/* </AppThemesProvider> */}
      </StyledEngineProvider>
    </>
  );
}

export default App;
