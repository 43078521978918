import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBeacon } from '../../../dataObjects/models/alerts/Beacon';

// export interface IBeaconState {
//   value: Array<IBeacon> | null,
// }

const initialState: Array<IBeacon> | null = null;

export const liveBeaconsSlice = createSlice({
  name: 'liveBeacons',
  initialState,
  reducers: {
    // action payload may be an array of IBeacons or null
    liveBeaconsChange(state, action) {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { liveBeaconsChange } = liveBeaconsSlice.actions;

// export the core reducer for the slice
export const liveBeaconsReducer = liveBeaconsSlice.reducer;

export default liveBeaconsSlice.reducer;
