import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ActionTextStringAssets, ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { ILoginData } from '../../../dataObjects/models/registerAndLogin/LoginData';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, LinearProgress, TextField, Typography } from '@mui/material';
import SubmitButton from '../../controls/buttons/SubmitButton/SubmitButton';
import Form from '../Form/Form';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import TwoButtonAcceptanceDialog from '../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { composeMessageUsingStringAsset } from '../../messages';
import { RegularExpressions } from '../../../assets/regularExpressionAssets';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall Progress Bar Area
const StyledBoxForProgressBarArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));

// a styled LinearProgress for the Progress Bar
const StyledLinearProgressForProgressBar = styled((props) => (
  <LinearProgress
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
}));

// a styled Box (equivalent to a <div>), providing an area to display the Progress Label Area
const StyledBoxForProgressLabelArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
}));

// a styled Typography for the Progress Label
const StyledTypographyForProgressLabel = styled((props) => (
  <Typography
    variant="caption"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Box (equivalent to a <div>), providing an area to display the Action Buttons Area
const StyledBoxForActionButtonsArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
}));

// a styled Box (equivalent to a <div>), providing an area to display a Pseudo Horizontal Rule
const StyledBoxForPseudoHorizontalRule = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(3),
  textAlign: 'center',
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  lineHeight: '0.1em',
  margin: '20px 0px',
}));

// a styled Box (equivalent to a <div>), providing an area to display Text within the Pseudo Horizontal Rule
const StyledBoxForTextInPseudoHorizontalRule = styled((props) => (
  <Box
    component='span'
    {...props}
  />
))(({ theme }) => ({
  background: '#fff',
  padding: '0 10px',
}));

// a styled Box (equivalent to a <div>), providing an area to display Text within the Pseudo Horizontal Rule
const StyledBoxForActionText = styled((props) => (
  <Box
    component='div'
    {...props}
  />
))(({ theme }) => ({
  color: theme.actionText.color,
  cursor: theme.actionText.cursor,
  fontSize: theme.actionText.fontSize,
  textDecorationLine: theme.actionText.textDecorationLine,
  padding: '0 10px',
  '&:hover': {
    color: theme.actionText.hoverColor,
    textDecorationColor: theme.actionText.hoverTextDecorationColor,
  },
}));

// a styled Button for the Register Button
const StyledButtonForRegisterButton = styled((props) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

interface ILoginFormValues {
  email: string;
  password: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  email: yup
    .string()
    .email(ControlsStringAssets.emailInvalid)
    .required(ControlsStringAssets.emailRequired),
  password: yup
    .string()
    .min(8, ControlsStringAssets.passwordMinLength)
    .matches(RegularExpressions.Password, ControlsStringAssets.passwordPattern)
    .required(ControlsStringAssets.passwordRequired),
});

// type FormFields = yup.InferType<typeof schema>;

export interface ILoginFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {ILoginData} loginData The LoginData details for the form (in production, the fields will be blank; in development, default credentials may be provided)
   */
  loginData: ILoginData,
  /**
   * @property {boolean} accountNotVerified Whether it has been determined that the account has not been verified
   */
  accountNotVerified: boolean,
  /**
   * @property {string} emailAtLastSubmit The email address provided at the last Login submit
   */
  emailAtLastSubmit?: string,
  /**
   * @property {boolean} loginRequestInProgress Whether a login request is in progress
   */
  loginRequestInProgress?: boolean,
  /**
   * @property {(loginData: ILoginData) => Promise<void>} onSubmit Method to call for submitting the form for a login operation
   */
  onSubmit: (loginData: ILoginData) => Promise<void>,
  /**
   * @property {(emailAddress: string) => Promise<void>} onRequestAccountVerificationEmail Method to call when user requests to re-send
   *                                                     account verification email
   */
  onRequestAccountVerificationEmail: (emailAddress: string) => Promise<void>,
  /**
   * @property {(emailAddress: string) => Promise<void>} onRequestPasswordResetEmail Method to call when user requests to send a
   *                                                     password reset email
   */
  onRequestPasswordResetEmail: (emailAddress: string) => Promise<void>,
  /**
   * @property {(emailAddress: string) => Promise<void>} onRequestSigninWithEmailLinkEmail Method to call when user requests to send a
   *                                                     sign-in with email link email
   */
  onRequestSigninWithEmailLinkEmail: (emailAddress: string) => Promise<void>,
}

const LoginForm: React.FC<ILoginFormProps> = (props: ILoginFormProps) => {
  LoginForm.displayName = 'Login Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed LoginForm component', 'background: #055; color: #fff');

  const { loginData, loginRequestInProgress, accountNotVerified, emailAtLastSubmit, onSubmit,
    onRequestAccountVerificationEmail, onRequestPasswordResetEmail, onRequestSigninWithEmailLinkEmail } = props;

  const navigate = useNavigate();

  // set up details for ReactHookForm
  const { control, formState, formState: { errors }, getValues, handleSubmit, register, trigger } = useForm<ILoginFormValues>({
    // const { control, formState, formState: { errors }, getValues, handleSubmit, register, trigger } = useForm<FormFields>({
    defaultValues: {
      email: loginData.email ? loginData.email : '',
      password: loginData.password
    },
    mode: "all", // trigger validation of the form for all modes
    // resolver: yupResolver(schema)
    resolver: yupResolver(schema)
  });

  const { ref: emailReg, ...emailProps } = register("email", { required: true });
  const { ref: passwordReg, ...passwordProps } = register("password", { required: true });

  // capture the current email address in the 'email' form field (used in a case where submission had resulted in an "Account Not Verified" error)
  const [currentEmailAddress, setCurrentEmailAddress] = useState<string>(loginData.email ?? '');

  // whether to show the Re-send Account Verification Email Confirmation dialog
  const [showResendVerificationEmailConfirmationDlg, setShowResendVerificationEmailConfirmationDlg] = useState<boolean>(false);

  // whether to show the Password Reset Confirmation dialog
  const [showSendPasswordResetEmailConfirmationDlg, setShowSendPasswordResetEmailConfirmationDlg] = useState<boolean>(false);

  // whether to show the Send Sign-in With Email Link Confirmation dialog
  const [showSendSigninWithEmailLinkEmailConfirmationDlg, setShowSendSigninWithEmailLinkEmailConfirmationDlg] = useState<boolean>(false);

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  // useEffect(() => {
  //   displayConsoleLogs && console.log(`%c LoginForm. In useEffect for getValues('email'). getValues('email'): ${getValues('email')}`, 'background: #055; color: #fff');

  //   if (isEmail(getValues('email'))) {

  //     (async () => {
  //       try {
  //         const triggerResultsPassword: boolean = await trigger('password');
  //         displayConsoleLogs && console.log(`%c LoginForm. In useEffect for getValues('email'). Completed call to trigger('password'). triggerResultsPassword: ${triggerResultsPassword}`, 'background: #055; color: #fff');

  //         if (triggerResultsPassword) {
  //           setFormIsValid(true);
  //         }
  //       } catch (error: any) {
  //         displayConsoleLogs && console.error(`%c LoginForm. In useEffect for getValues('email'). Error attempting to trigger validation of the form: ${error.toString()}`, 'background: #055; color: #fff');
  //       }
  //     })();

  //   }
  // }, [getValues('email')]);

  useEffect(() => {
    setFormIsValid(isValid);
  }, [isValid]);

  // whether a request is currently being processed (eg, "Re-send Account Verification Email", "Request Email Sign-in", "Reset Password", etc.)
  const [requestBeingProcessed, setRequestBeingProcessed] = useState<boolean>(false);

  // label for a request currently being processed
  const [requestBeingProcessedLabel, setRequestBeingProcessedLabel] = useState<string>('');

  // state value indicating whether a login is in progress
  const [loginInProgress, setLoginInProgress] = useState<boolean>(loginRequestInProgress ?? false);

  // useEffect hook for setting the 'loginInProgress' local state based on whether a login is currently in progress
  useEffect(() => {
    setLoginInProgress(loginRequestInProgress ?? false);
  }, [loginRequestInProgress]);

  // handles a login (submit) request from the form
  const handleLoginSubmit = async (data: ILoginFormValues) => {

    setLoginInProgress(true);

    // fill in email & password within the loginData object passed in
    loginData.email = data.email;
    loginData.password = data.password;

    // call the onSubmit handler passed in, supplying the Channel object
    await onSubmit(loginData);
  }

  // // An 'onChange' handler for the Email field
  // function handleEmailChange(emailAddressFieldValue: string): void {
  //   displayConsoleLogs && console.log(`%c LoginForm.handleEmailChange. emailAddressFieldValue: ${emailAddressFieldValue}`, 'background: #055; color: #fff');

  //   if (isEmail(emailAddressFieldValue)) {
  //     if (getValues('email') !== emailAddressFieldValue) {
  //       setCurrentEmailAddress(emailAddressFieldValue);
  //       displayConsoleLogs && console.log(`%c LoginForm. getValues('email') has been set.`, 'background: #055; color: #fff');
  //     }
  //   }
  // }

  const handleResendVerificationEmail = async () => {
    setShowResendVerificationEmailConfirmationDlg(true);
  }

  const handleResendVerificationEmailConfirmed = async () => {
    // set flag and label to display a progress indicator
    setRequestBeingProcessed(true);
    setRequestBeingProcessedLabel(MessagesStringAssets.login_RequestBeingProcessed);

    // call method to reset the local state details for hiding/showing the Re-send Verification Email dialog
    resendVerificationEmailConfirmationReset();

    // call the provided method to request creating an email request for re-sending an account verification email
    await onRequestAccountVerificationEmail(getValues('email'));

    resetRequestBeingProcessed();
  }

  function handleResendVerificationEmailCanceled(): void {
    // call method to reset the local state details for hiding/showing the Re-send Verification Email dialog
    resendVerificationEmailConfirmationReset();
  }

  function resendVerificationEmailConfirmationReset() {
    // set flag to hide the delete confirmation dialog
    setShowResendVerificationEmailConfirmationDlg(false);
  }

  const handleSendSigninWithEmailLinkEmail = async () => {
    setShowSendSigninWithEmailLinkEmailConfirmationDlg(true);
  }

  const handlePasswordResetEmail = async () => {
    setShowSendPasswordResetEmailConfirmationDlg(true);
  }

  const handlePasswordResetEmailConfirmed = async () => {
    // set flag and label to display a progress indicator
    setRequestBeingProcessed(true);
    setRequestBeingProcessedLabel(MessagesStringAssets.login_RequestBeingProcessed);

    // call method to reset the local state details for hiding/showing the Password Reset Email dialog
    sendPasswordResetEmailConfirmationReset();

    // call the provided method to request creating an email request for sending the Password Reset email
    await onRequestPasswordResetEmail(getValues('email'));

    resetRequestBeingProcessed();
  }

  function handleSendPasswordResetEmailCanceled(): void {
    // call method to reset the local state details for hiding/showing the Password Reset Email dialog
    sendPasswordResetEmailConfirmationReset();
  }

  function sendPasswordResetEmailConfirmationReset() {
    // set flag to hide the delete confirmation dialog
    setShowSendPasswordResetEmailConfirmationDlg(false);
  }

  const handleSendSigninWithEmailLinkEmailConfirmed = async () => {
    // set flag and label to display a progress indicator
    setRequestBeingProcessed(true);
    setRequestBeingProcessedLabel(MessagesStringAssets.login_RequestBeingProcessed);

    // call method to reset the local state details for hiding/showing the Sign-in With Email Link Email dialog
    sendSigninWithEmailLinkEmailConfirmationReset();

    // call the provided method to request creating an email request for sending the Sign-in With Email Link email
    // await onRequestSigninWithEmailLinkEmail(getValues('email'));
    await onRequestSigninWithEmailLinkEmail(getValues('email'));

    resetRequestBeingProcessed();
  }

  function handleSendSigninWithEmailLinkEmailCanceled(): void {
    // call method to reset the local state details for hiding/showing the Sign-in With Email LInk Email dialog
    sendSigninWithEmailLinkEmailConfirmationReset();
  }

  function sendSigninWithEmailLinkEmailConfirmationReset() {
    // set flag to hide the delete confirmation dialog
    setShowSendSigninWithEmailLinkEmailConfirmationDlg(false);
  }

  function resetRequestBeingProcessed() {
    setRequestBeingProcessed(false);
    setRequestBeingProcessedLabel('');
  }

  displayConsoleLogs && console.log(`%c LoginForm. Before return statement. accountNotVerified: ${accountNotVerified}; getValues('email'): ${getValues('email')}; emailAtLastSubmit: ${emailAtLastSubmit}`, 'background: #055; color: #fff');

  return (
    <>
      <Form
        onSubmit={handleSubmit(handleLoginSubmit)}
      >
        {/* if there's an action in progress, display the progress bar area */}
        {requestBeingProcessed &&
          <StyledBoxForProgressBarArea>
            <StyledLinearProgressForProgressBar />
            <StyledBoxForProgressLabelArea>
              {/* if an action progress label has been provided, display it */}
              {requestBeingProcessedLabel &&
                <StyledTypographyForProgressLabel>{requestBeingProcessedLabel}</StyledTypographyForProgressLabel>
              }
            </StyledBoxForProgressLabelArea>
          </StyledBoxForProgressBarArea>
        }

        {/* <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { isTouched, isDirty } }) => (
            <>
              <EmailInput
                control={control}
                // defaultValue={loginData.email ? loginData.email : ''}
                value={currentEmailAddress}
                trigger={trigger}
                autoFocus
                label={ControlsStringAssets.emailLabel}
                margin='normal'
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
                InputLabelProps={{
                  required: true  // this will cause an asterisk ('*') to appear at the end of the label text
                }}
                onChange={e => {setCurrentEmailAddress(e.target.value)}}
              />
            </>
          )}
        /> */}
        <TextField
          inputRef={emailReg}
          {...emailProps}
          autoFocus
          label={ControlsStringAssets.emailLabel}
          margin='normal'
          fullWidth
          error={!!errors.email}
          helperText={errors?.email?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
          onChange={e => setCurrentEmailAddress(e.target.value)}
        />

        { /* If we had detected an "Account Not Verified" error -AND- the email address hasn't changed since the last submit... */
          accountNotVerified && (emailAtLastSubmit === getValues('email')) &&
          <StyledBoxForActionText onClick={handleResendVerificationEmail}>
            {ActionTextStringAssets.resendAccountVerificationEmail}
          </StyledBoxForActionText>
        }

        <StyledBoxForActionText onClick={handleSendSigninWithEmailLinkEmail}>
          {ActionTextStringAssets.sendSigninWithEmailLinkEmail}
        </StyledBoxForActionText>

        {/* Confirm Re-sending Account Verification Email Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showResendVerificationEmailConfirmationDlg}
          headerText={MessagesStringAssets.login_ResendVerificationEmailConfirmationHeader}
          bodyText={composeMessageUsingStringAsset(MessagesStringAssets.login_ResendVerificationEmailConfirmation, getValues('email'), MessagesStringAssets.substitutionKeyword)}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleResendVerificationEmailConfirmed}
          onNonAcceptance={handleResendVerificationEmailCanceled}
        />

        {/* Confirm Sending Password Reset Email Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showSendPasswordResetEmailConfirmationDlg}
          headerText={MessagesStringAssets.login_SendPasswordResetEmailConfirmationHeader}
          bodyText={composeMessageUsingStringAsset(MessagesStringAssets.login_SendPasswordResetEmailConfirmation, getValues('email'), MessagesStringAssets.substitutionKeyword)}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handlePasswordResetEmailConfirmed}
          onNonAcceptance={handleSendPasswordResetEmailCanceled}
        />

        {/* Confirm Sending Sign-in With Email Link Email Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showSendSigninWithEmailLinkEmailConfirmationDlg}
          headerText={MessagesStringAssets.login_SendSigninWithEmailLinkEmailConfirmationHeader}
          bodyText={composeMessageUsingStringAsset(MessagesStringAssets.login_SendSigninWithEmailLinkEmailConfirmation, getValues('email'), MessagesStringAssets.substitutionKeyword)}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleSendSigninWithEmailLinkEmailConfirmed}
          onNonAcceptance={handleSendSigninWithEmailLinkEmailCanceled}
        />

        {/* <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <PasswordInput
              control={control}
              defaultValue={loginData.password}
              trigger={trigger}
              label={ControlsStringAssets.passwordLabel}
              margin='normal'
              fullWidth
              error={!!errors.password}
              helperText={errors?.password?.message}
              InputLabelProps={{
                required: true  // this will cause an asterisk ('*') to appear at the end of the label text
              }}
            />
          )}
        /> */}

        <TextField
          inputRef={passwordReg}
          {...passwordProps}
          type="password"
          label={ControlsStringAssets.passwordLabel}
          margin='normal'
          fullWidth
          error={!!errors.password}
          helperText={errors?.password?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <StyledBoxForActionText onClick={handlePasswordResetEmail}>
          {ActionTextStringAssets.forgotPassword}
        </StyledBoxForActionText>

        {/* if there's a login in progress, display the progress bar area */}
        {loginInProgress &&
          <StyledBoxForProgressBarArea>
            <StyledLinearProgressForProgressBar />
            <StyledBoxForProgressLabelArea>
              {/* if an action progress label has been provided, display it */}
              <Typography variant="caption">{MessagesStringAssets.login_Requested}</Typography>
            </StyledBoxForProgressLabelArea>
          </StyledBoxForProgressBarArea>
        }
        {/* display the action buttons ("Login" & "Create New Account") */}

        <StyledBoxForActionButtonsArea>
          <SubmitButton
            fullWidth
            disabled={!formIsValid || loginInProgress}
          >
            {ControlsStringAssets.loginButtonText}
          </SubmitButton>

          <StyledBoxForPseudoHorizontalRule>
            <StyledBoxForTextInPseudoHorizontalRule>OR</StyledBoxForTextInPseudoHorizontalRule>
          </StyledBoxForPseudoHorizontalRule>

          <StyledButtonForRegisterButton
            disabled={loginInProgress}
            onClick={() => navigate('/createNewAccount')}
          >
            {ControlsStringAssets.createNewAccountButtonText}
          </StyledButtonForRegisterButton>
        </StyledBoxForActionButtonsArea>

      </Form>
    </>

  );
}

export default LoginForm;