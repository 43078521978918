import { collection, CollectionReference, deleteDoc, doc, DocumentData, DocumentSnapshot, Firestore, getDoc, getDocs, onSnapshot, query, Query, QuerySnapshot, setDoc, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumObjectPersistenceState } from '../../../../../../dataObjects/enums';
import { enumFirestoreCollectionNames } from '../enums';
import { JsonConverter } from '../../../../../../dataObjects/utilities/JsonConverter';
import { IBaseDbContext } from '../../../../../dataServices/dbContexts/BaseDbContext';
import { IThemeSpecs, IThemeSpecsAsJson, ThemeSpecs } from '../../../../../../dataObjects/models/themes/ThemeSpecs';
import { IFirestoreThemeSpecsRepository } from '.';
import { FirestoreBaseRepository } from '../FirestoreBaseRepository';
import { enumMdbErrorType } from '../../../../../../errorObjects/enums';
import { MdbError } from '../../../../../../errorObjects/MdbError';

/**
 * @class FirestoreThemeSpecsRepository Manages database repository operations related to a ThemeSpecs
 */
export class FirestoreThemeSpecsRepository
  extends FirestoreBaseRepository<IThemeSpecs, IThemeSpecsAsJson>
  implements IFirestoreThemeSpecsRepository {
  /**
   * @method Constructor method
   * @param {IBaseDbContext<Firestore>} firestoreDbContext A Firestore DB Context
   * @param {boolean} enableOfflinePersistence (Optional) Whether to enable offline persistence for Firestore
   */
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    firestoreDbContext: IBaseDbContext<Firestore>,
    // enableOfflinePersistence?: boolean
  ) {
    // super(firestoreDbContext, undefined, enableOfflinePersistence);
    super(firestoreDbContext, undefined);
  }

  /*-----------------------------------------------*/
  /**
   * @method create Creates (persists) an object in the database.
   * @param {IThemeSpecs} themeSpecs The object to be persisted in the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void).
   */
  create(themeSpecs: IThemeSpecs): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {

        const throwErrorForTesting: boolean = false;

        if (throwErrorForTesting) {
          throw new MdbError('Intentional error from FirestoreThemeSpecsRepository.create()');
        } else {
          // get the reference to the Firestore DB instance
          const firestoreDb: Firestore = this.dbContext
            .dbReference;

          // get a reference to the appropriate collection
          const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

          // convert the IThemeSpecs object to a JSON object
          const themeSpecsAsJson: IThemeSpecsAsJson = themeSpecs.toJSON(true);

          // update the versionToken and persistence state
          themeSpecsAsJson.versionToken = Date.now();
          themeSpecsAsJson.objectState = enumObjectPersistenceState.New;

          // insert a themeSpecs document into the collection, using the themeSpecs's Id as the primary key
          await setDoc(doc(themeSpecsCollection, themeSpecs.id), themeSpecsAsJson);

          // initiate version tracking of the object (via a call to the base class initiateTracking() method)
          await this.initiateTracking(themeSpecsAsJson);
        }
        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method update Updates an object in the database.
   * @param {IThemeSpecs} themeSpecs The object to be updated in the database. An object of the same
   * type and with the same unique Id must already exist in the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). An error is thrown
   * if a current object (to be updated) could not be found in the database or if the update operation failed.
   */
  update(themeSpecs: IThemeSpecs): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this.dbContext
          .dbReference;

        // get a reference to the appropriate collection
        const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

        // call get() function to get the object, both to ensure it exists, and to obtain the full object representation
        const existingThemeSpecs: IThemeSpecs | undefined = await this.get(themeSpecs.id);

        if (existingThemeSpecs === undefined) {
          reject(
            `Unable to locate a ThemeSpecs record with the given Id: (${themeSpecs.id})`
          );
        } else {
          // ensure that the versionToken for the object passed in is not less than the object that already exists in the DB
          if (themeSpecs.versionToken < existingThemeSpecs.versionToken) {
            reject(
              `Unable to update. The version of the ThemeSpecs in the database was updated since the ThemeSpecs object was retrieved from the database.`
            );
          } else {
            // convert the IThemeSpecs object to a JSON object
            const themeSpecsAsJson: IThemeSpecsAsJson = themeSpecs.toJSON(true);

            // update the versionToken and persistence state
            themeSpecsAsJson.versionToken = Date.now();
            themeSpecsAsJson.objectState = enumObjectPersistenceState.Modified;

            // Update the record in the DB, using the themeSpecs's Id as the primary key. An error will be thrown
            // if no document was found with the given Id -OR- if the update operation fails for any other reason
            await setDoc(doc(themeSpecsCollection, themeSpecs.id), themeSpecsAsJson);

            // append this new version of the object (via a call to the base class addNewVersionToTracking() method)
            await this.addNewVersionToTracking(themeSpecsAsJson);
          }
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method get Retrieves a single object from the database.
   * @param {typeUniqueId} id The unique Id of the object to retrieve.
   * @returns {Promise<IThemeSpecs | undefined>} A Promise (to provide asynchrounous capability) with an object instance (if
   * an object was found with the given Id) or 'undefined' (if an object wasn't found with the given Id).
   */
  get(id: typeUniqueId): Promise<IThemeSpecs | undefined> {
    return new Promise<IThemeSpecs | undefined>(async (resolve, reject) => {
      try {
        // object to return
        let themeSpecs: IThemeSpecs | undefined = undefined;

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this.dbContext
          .dbReference;

        // get a reference to the appropriate collection
        const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

        // attempt to get a reference to a document with the given Id
        const docSnap: DocumentSnapshot<DocumentData> = await getDoc(doc(themeSpecsCollection, id));

        // if a document was found (exists)...
        if (docSnap.exists()) {
          // fetch the data from the document, which should be a JSON version of the correct type of object
          const themeSpecsAsJson: IThemeSpecsAsJson = docSnap.data() as IThemeSpecsAsJson;

          // convert the JSON object to a Typescript object
          themeSpecs = JsonConverter.fromJSON(ThemeSpecs, themeSpecsAsJson, true);
        }

        resolve(themeSpecs);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method get_onSnapshot Sets up a snapshot for retrieval of a single object from the database associated with the Id of the object, along 
   *   with dynamic notification of changes to the results of the query.
   * @param {typeUniqueId} id The unique Id of the object to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A reference to a callback method on the client 
   *   side that will receive onSnapshot notifications when data on the server changes.
   * @returns {() => void>} A reference to an unsubscribeCallback method that is to be called when the caller/client has completed its work with the onSnapshot
   *   notifications. In React, this callback function will likely be called when a specific React component is unmounted (e.g., in the return statement of a 
   *   useEffect() hook for a React function component or in the ComponentDidUnmount method of a React class component.)
   */
  get_onSnapshot(id: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };
        // console.log(`FirestoreThemeSpecsRepository:get_onSnapshot before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this.dbContext.dbReference;

        // get a reference to the appropriate collection
        const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

        // prepare a query that will get a single document with the Id and in either the 'New' or 'Modified' state
        const documentQuery: Query<DocumentData> =
          query(themeSpecsCollection,
            where("id", "==", id),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);
        // console.log(`FirestoreThemeSpecsRepository:get_onSnapshot after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // return the 'unsubscribeCallback' function
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllForParent Retrieves all objects from the database associated with the parent of those objects.
   * @param {typeUniqueId} parentId The unique Id of the parent of the objects to retrieve.
   * @returns {Promise<Array<IThemeSpecs> | undefined>} A Promise (to provide asynchrounous capability) with an array of object instances (if
   * one or more objects were found to be associated with the given parentId) or 'undefined' (if no objects were found to
   * be associated with the given parentId).
   */
  getAllForParent(parentId: typeUniqueId): Promise<Array<IThemeSpecs> | undefined> {
    return new Promise<Array<IThemeSpecs> | undefined>(async (resolve, reject) => {
      try {
        // array of objects to return
        let categories: Array<IThemeSpecs> | undefined = undefined;

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this.dbContext.dbReference;

        // get a reference to the appropriate collection
        const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

        // proceed to get all documents with the parentId and in either the 'New' or 'Modified' state, starting with a query
        const documentQuery: Query<DocumentData> =
          query(themeSpecsCollection,
            where("parentId", "==", parentId),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // next, get a snapshot of the document references resulting from executing the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(documentQuery);

        // now, from the snapshot, get the data for each doc and add the data into a JSON objects array
        let categoriesAsJson: Array<IThemeSpecsAsJson> = [];
        querySnapshot.forEach((doc) => {
          categoriesAsJson.push(doc.data() as IThemeSpecsAsJson);
        });

        // next, convert the array of JSON objects for return
        categories = JsonConverter.arrayFromJSONArray(ThemeSpecs, categoriesAsJson);

        resolve(categories);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllForParent_onSnapshot Makes an onSnapshot() request to firebase for retrieving all objects from the database associated with 
   *   the parent of those objects. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the 
   * @param {typeUniqueId} parentId The unique Id of the parent of the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getAllForParent_onSnapshot(parentId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        let unsubscribeCallback: () => void = () => { };

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this.dbContext.dbReference;

        // get a reference to the appropriate collection
        const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

        // prepare a query that will get all documents with the parentId and in either the 'New' or 'Modified' state
        const documentQuery: Query<DocumentData> =
          query(themeSpecsCollection,
            where("parentId", "==", parentId),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);

        // return the 'unsubscribeCallback' function
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllIdsForParent Retrieves just the Ids for all objects from the database associated with the parent of those objects.
   * @param {typeUniqueId} parentId The unique Id of the parent of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of typeUniqueIds (if
   * one or more objects were found to be associated with the given parentId) or 'undefined' (if no objects were found to
   * be associated with the given parentId).
   */
  getAllIdsForParent(
    parentId: typeUniqueId
  ): Promise<Array<typeUniqueId> | undefined> {
    return new Promise<Array<typeUniqueId> | undefined>(
      async (resolve, reject) => {
        // merely return undefined, since a ThemeSpecs has no inherent parent
        resolve(undefined);
      }
    );
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllIdsForOwner Retrieves just the Ids for all objects from the database associated with the owner (user) of those objects.
   * @param {typeUniqueId} ownerId The unique Id of the owner of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of typeUniqueIds (if
   * one or more objects were found to be associated with the given ownerId) or 'undefined' (if no objects were found to
   * be associated with the given ownerId).
   */
  getAllIdsForOwner(
    ownerId: typeUniqueId
  ): Promise<Array<typeUniqueId> | undefined> {
    return new Promise<Array<typeUniqueId> | undefined>(
      async (resolve, reject) => {
        try {
          // array of Ids to return
          let themeSpecsIds: Array<typeUniqueId> | undefined = undefined;

          // get the reference to the Firestore DB instance
          const firestoreDb: Firestore = this.dbContext
            .dbReference;

          // get a reference to the appropriate collection
          const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

          // proceed to get all documents with the ownerId, starting with a query
          const documentQuery: Query<DocumentData> =
            query(themeSpecsCollection,
              where("ownerId", "==", ownerId)
            );

          // next, get a snapshot of the document references resulting from executing the query (there should be, at most, only 1 document in the case of a AudioLink)
          const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(documentQuery);

          // now, from the snapshot, get the data for each doc and add just the doc ID into an array
          themeSpecsIds = [];
          querySnapshot.forEach((doc) => {
            themeSpecsIds!.push(doc.id);
          });

          resolve(themeSpecsIds);
        } catch (error: any) {
          reject(error);
        }
      }
    );
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method delete Performs a "soft delete" on an object in the database. A "soft delete" marks the object state as
   * "Deleted", and doesn't expunge the object from the database.
   * @param {typeUniqueId} id The unique Id of the object to be soft-deleted from the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if an object is not found with the given Id.
   */
  delete(id: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {

        const throwErrorForTesting: boolean = false;

        if (throwErrorForTesting) {
          throw new MdbError('Intentional error from FirestoreThemeSpecsRepository.delete()', enumMdbErrorType.ForcedError);
        } else {
          // get the reference to the Firestore DB instance
          const firestoreDb: Firestore = this.dbContext
            .dbReference;

          // get a reference to the appropriate collection
          const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

          // call get() function to get the object, both to ensure it exists, and to obtain the full object representation
          const themeSpecs: IThemeSpecs | undefined = await this.get(id);

          if (themeSpecs !== undefined) {
            // convert the IThemeSpecs object to a JSON object
            const themeSpecsAsJson: IThemeSpecsAsJson = themeSpecs?.toJSON(true);

            // update the versionToken and persistence state
            themeSpecsAsJson.versionToken = Date.now();
            themeSpecsAsJson.objectState = enumObjectPersistenceState.Deleted;

            // Update the record in the DB, using the themeSpecs's Id as the primary key. An error will be thrown
            // if no document was found with the given Id -OR- if the update operation fails for any other reason
            await setDoc(doc(themeSpecsCollection, themeSpecs?.id), themeSpecsAsJson);

            // TODO: Perform a "soft-delete" on all child objects (Topics are the only child objects at this time)

            // append this new version of the object (via a call to the base class addNewVersionToTracking() method)
            await this.addNewVersionToTracking(themeSpecsAsJson);
          }
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method deleteAllForParent Performs a "soft delete" on all objects in the database related to a given parent.
   * @param {typeUniqueId} parentId The unique Id of the parent for the objects to be soft-deleted.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if no objects are found to be related to the given parentId.
   */
  deleteAllForParent(parentId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      // merely return a void, since a ThemeSpecs has no inherent parent
      resolve();
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method deleteAllForOwner Performs a "soft delete" on all objects in the database related to a given owner (user).
   * @param {typeUniqueId} ownerId The unique Id of the owner for the objects to be soft-deleted.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if no objects are found to be related to the given ownerId.
   */
  deleteAllForOwner(ownerId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      // call getAllIdsForOwner() method to get all of the object Ids for the given ownerId
      const objectIds:
        | Array<typeUniqueId>
        | undefined = await this.getAllIdsForOwner(ownerId);

      // if any object Ids were found...
      if (objectIds !== undefined) {
        // iterate the array of Ids and call delete() for each
        objectIds.forEach((id) => this.delete(id));
      }

      resolve();
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expunge Expunges (completely removes) an object from the database.
   * @param {typeUniqueId} id The unique Id of the object to be expunged from the database.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if an object is not found with the given Id.
   */
  expunge(id: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this.dbContext
          .dbReference;

        // get a reference to the collection
        const themeSpecsCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ThemeSpecsCollection);

        // call get() function to get the object, both to ensure it exists, and to obtain the full object representation
        const themeSpecs: IThemeSpecs | undefined = await this.get(id);

        if (themeSpecs !== undefined) {
          // convert the IThemeSpecs object to a JSON object
          const themeSpecsAsJson: IThemeSpecsAsJson = themeSpecs.toJSON(true);

          // Attempt to delete a document with the given Id. An error will be thrown if the delete operation
          // fails for any reason
          await deleteDoc(doc(themeSpecsCollection, id));

          // TODO: Expunge all child objects (Topics are the only child objects at this time)

          // append this new version of the object (via a call to the base class addNewVersionToTracking() method)
          // The versionToken and objectState need to be set. (What about the LastUpdated and ThemeSpecs properties?)
          themeSpecsAsJson.versionToken = Date.now();
          themeSpecsAsJson.objectState = enumObjectPersistenceState.Expunged;
          await this.addNewVersionToTracking(themeSpecsAsJson);
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expungeAllForParent Expunges (completely removes) all objects in the database related to a given parent.
   * @param {typeUniqueId} parentId The unique Id of the parent for the objects to be expunged.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if no objects are found to be related to the given parentId.
   */
  expungeAllForParent(parentId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      // merely return a void, since a ThemeSpecs has no inherent parent
      resolve();
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expungeAllForOwner Expunges (completely removes) all objects in the database related to a given owner (user).
   * @param {typeUniqueId} ownerId The unique Id of the owner for the objects to be expunged.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if no objects are found to be related to the given ownerId.
   */
  expungeAllForOwner(ownerId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      // call getAllIdsForOwner() method to get all of the object Ids for the given ownerId
      const objectIds:
        | Array<typeUniqueId>
        | undefined = await this.getAllIdsForOwner(ownerId);

      // if any object Ids were found...
      if (objectIds !== undefined) {
        // iterate the array of Ids and call expunge() for each
        objectIds.forEach((id) => this.expunge(id));
      }

      resolve();
    });
  }
  /*-----------------------------------------------*/
}
