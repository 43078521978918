import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface IMyDigiBrainLogoParms {
  className?: string;
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  personHead: {
    fill: theme.myDigiBrainLogo ? theme.myDigiBrainLogo.personHeadFill : theme.palette.primary.light,
    stroke: theme.myDigiBrainLogo ? theme.myDigiBrainLogo.personHeadStroke : theme.palette.primary.dark,
    strokeWidth: '9px'
  },
  noFill: {
    fill: 'none'
  },
  otherObjects: {
    fill: theme.myDigiBrainLogo ? theme.myDigiBrainLogo.otherObjectsFill : 'black',
    stroke: theme.myDigiBrainLogo ? theme.myDigiBrainLogo.otherObjectsStroke : 'black',
  },
  connectors: {
    fill: 'none',
    stroke: theme.myDigiBrainLogo ? theme.myDigiBrainLogo.otherObjectsStroke : 'black',
    strokeMiterLimit: 10,
    strokeWidth: '40px',
  },

}));


// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const MyDigiBrainLogo = React.forwardRef((props: IMyDigiBrainLogoParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3257.35 3609.58" >
      <defs>
      </defs>

      <g id="Human_Head_Small_Light_Blue" data-name="Human Head Small Light Blue">
        <path className={classes.personHead}
          d="M463.79,2508.09C375.05,2152.93-85.8,1958.41,20.26,1124c181.8-1430.38,2302.1-1384.29,2697.3-457.49,184.16,431.86,61.52,429.32,128,566.54,42.51,87.68,167.28,201.48,341.65,456.61,212.85,311.45-157.82,273.9-184,345.69-37.61,103,65.56,172.76,65.56,221.63,0,64.51-41.05,99.31-84.66,110.84,0,0,111.81,81.73,32,158-104.5,99.91-29.71,241.34-6.21,323,43.72,152-65,283.15-246,298.18-153.17,12.69-361.24,1-464.59,3.23-126.14,2.75-132,168.09-189,454.88H351.44S536.48,2799,463.79,2508.09Z" />
      </g>

      <g id="Connectors">
        <line className={classes.connectors} x1="791.41" y1="1136.23" x2="1176.64" y2="1136.23" />
        <line className={classes.connectors} x1="1725.46" y1="1136.18" x2="2110.69" y2="1136.18" />
        <line className={classes.connectors} x1="1107.89" y1="821.88" x2="1247.29" y2="928.03" />
        <line className={classes.connectors} x1="1655.76" y1="1228.84" x2="1795.16" y2="1335" />
        <line className={classes.connectors} x1="1056.52" y1="1395.12" x2="1195.91" y2="1288.96" />
        <line className={classes.connectors} x1="1601.79" y1="959.04" x2="1741.19" y2="852.88" />
      </g>

      <g id="Swirl">
        <g id="Swirl-2" data-name="Swirl">
          <path className={classes.noFill} d="m1625.27,1066.45c0,82.24,0,164.32.03,246.4,0,1.18-.26,1.44-1.44,1.44-132.37-.03-264.75-.03-397.12,0-1.18,0-1.44-.26-1.44-1.44.03-132.37.03-264.75,0-397.12,0-1.18.26-1.44,1.44-1.44,132.37.03,264.75.03,397.12,0,1.18,0,1.44.26,1.44,1.44-.03,50.19-.03,100.38-.03,150.72Z" />
          <path className={classes.otherObjects} d="m1499.61,1147.67c1.62-3.72,3.44-7.42,5.15-11.17,6.33-13.92,10.85-28.38,12.65-43.61.57-4.82,1.01-9.66.89-14.52-.35-13.99-3.14-27.43-8.94-40.24-4.5-9.94-10.46-18.93-17.57-27.12-8.28-9.54-17.75-17.81-28.09-25.09-9.94-7-20.42-13.06-31.39-18.26-8.5-4.03-17.14-7.73-26.16-10.47-1.3-.4-1.61-.92-1.05-2.25,5.33-12.72,10.59-25.46,15.88-38.2.73-1.77.9-1.91,2.65-1.06,3.62,1.76,7.08,3.82,10.46,5.99,8.8,5.66,17.19,11.85,25.26,18.51,7.98,6.59,15.64,13.53,22.8,20.99,15.47,16.11,28.82,33.76,38.16,54.18,5.64,12.33,9.49,25.19,10.9,38.74,1.92,18.44-.61,36.23-7.4,53.47-5.7,14.47-13.76,27.53-23.49,39.6-.16.2-.38.35-.72.52Z" />
          <path className={classes.otherObjects} d="m1523.71,1167.22c9.39-8.77,16.68-18.98,22.37-30.31,5.13-10.22,8.83-20.97,11.43-32.11,2.17-9.32,3.57-18.75,4.44-28.28.78-8.54,1.07-17.1.85-25.66-.15-6.15-.55-12.3-1.31-18.42-.16-1.26.26-1.68,1.43-1.83.99-.12,1.97-.22,2.96-.24,4.96-.1,9.92-.18,14.88-.35,7.51-.26,15.01-.6,22.52-.9.31-.01.63,0,.94,0,1.97.03,2.23.23,2.19,2.15-.08,3.86-.54,7.7-1.05,11.53-1.74,12.87-4.64,25.48-8.31,37.92-3.41,11.54-7.48,22.83-12.44,33.79-5.1,11.25-10.96,22.07-18.01,32.23-3.96,5.71-8.31,11.11-12.95,16.29-6.9,7.72-14.67,14.41-23.21,20.21-7.15,4.86-14.8,8.81-22.95,11.78-9.72,3.54-19.71,5.74-30.03,6.68-12.27,1.11-24.39.18-36.48-1.83-4.44-.74-8.81-1.77-13.18-2.76-.45-.1-.89-.26-1.74-.51,1.25-.45,2.08.01,2.9,0,9.24-.06,18.47.19,27.7-.86,10.16-1.15,20.15-3.03,29.81-6.38,12.65-4.4,24.25-10.7,34.36-19.58.94-.83,1.86-1.67,2.88-2.59Z" />
          <path className={classes.otherObjects} d="m1558.58,1087.92c-1.72,9.69-4.48,18.93-8.33,27.84-4.36,10.09-10.08,19.36-17.27,27.68-9.48,10.98-20.65,19.88-33.33,26.94-8.75,4.87-17.91,8.77-27.32,12.12-.64.23-1.29.43-1.94.62-.14.04-.29,0-.74,0,2.81-1.72,5.39-3.28,7.95-4.85,7.98-4.91,15.47-10.47,22.55-16.6,8.58-7.43,16.09-15.8,22.31-25.28,8.38-12.77,13.62-26.74,15.65-41.92.87-6.5,1.16-13.02.82-19.54-.58-11.05-2.67-21.84-5.85-32.44-3.82-12.72-9.17-24.77-15.45-36.43-5.39-10-11.59-19.48-18.39-28.57-1.44-1.92-2.94-3.8-4.49-5.63-.83-.98-.67-1.56.35-2.26,7.39-5.1,14.76-10.25,22.13-15.38,3.95-2.74,7.89-5.48,11.86-8.2,1.6-1.1,1.9-1.01,2.92.7,4.9,8.2,8.54,16.97,11.93,25.86,3.95,10.36,7.26,20.93,9.93,31.68,2.89,11.62,4.93,23.39,6.22,35.3,1.03,9.54,1.07,19.09.79,28.64-.19,6.56-1.01,13.11-2.3,19.71Z" />
          <path className={classes.otherObjects} d="m1434.72,995.05c-9.96-3.81-20.12-6.39-30.57-7.78-5.43-.72-10.87-1.29-16.36-1.5-3.81-.15-7.62-.33-11.43-.24-12.86.29-25.62,1.48-38.27,3.85-4.78.9-9.51,1.94-14.22,3.17-2.02.53-2.21.41-2.58-1.66-2.44-13.51-4.84-27.03-7.31-40.54-.25-1.35.28-1.86,1.44-2.01,1.29-.17,2.59-.34,3.89-.44,7.77-.59,15.53-.2,23.3.23,14.16.78,28.15,2.74,42,5.69,7.92,1.69,15.73,3.8,23.45,6.27,10.52,3.37,20.75,7.45,30.53,12.57,9.88,5.18,19.12,11.31,27.62,18.58,10.39,8.89,18.98,19.24,25.61,31.17,5.8,10.44,9.66,21.61,12.02,33.33,2.35,11.65,2.82,23.42,2.45,35.23-.12,3.85-.35,7.71-1.08,11.93-.63-1.8-.52-3.39-.69-4.89-1.85-16.44-5.5-32.41-12.26-47.59-5.83-13.08-13.66-24.77-24.14-34.57-5.93-5.54-12.47-10.29-19.58-14.26-4.44-2.48-9.04-4.56-13.84-6.54Z" />
          <path className={classes.otherObjects} d="m1407.03,1206.58c-8.5-6.32-16.45-13.07-23.66-21.09,1.09-.17,1.46.37,1.89.67,8.32,5.82,17.02,11,26.18,15.38,12.78,6.11,26.13,10.25,40.26,11.76,18.14,1.95,35.46-1.05,52.09-8.46,11.8-5.26,22.3-12.47,32.05-20.86,12.15-10.45,22.47-22.52,31.68-35.59,6.45-9.16,12.19-18.73,17.2-28.75.92-1.85.98-1.91,2.79-.84,8.99,5.32,17.95,10.69,26.93,16.03,2.69,1.6,5.4,3.18,8.09,4.78,1.86,1.11,1.88,1.2.71,2.97-3,4.55-6.59,8.63-10.16,12.71-4.65,5.31-9.76,10.19-14.81,15.11-7.19,7-14.86,13.44-22.84,19.52-11.33,8.64-23.24,16.35-35.97,22.75-11.28,5.67-22.98,10.28-35.29,13.19-8.2,1.94-16.49,3.07-24.93,3.44-15.6.69-30.58-2.01-45.04-7.69-9.67-3.8-18.65-8.9-27.17-15.04Z" />
          <path className={classes.otherObjects} d="m1285.2,1114.08c4.66-11.34,10.63-21.8,18.17-31.31,11.09-14,24.76-24.8,41.01-32.21,12.29-5.61,25.23-9.06,38.62-10.84,5.02-.67,10.07-1.01,15.11-1.46.98-.09,1.97-.01,3.36-.01-1.33.73-2.43.76-3.47,1.03-10.93,2.86-21.64,6.34-31.89,11.17-8.01,3.77-15.67,8.14-22.75,13.45-14.2,10.64-25.16,23.93-32.36,40.19-4.39,9.9-7.08,20.27-8.59,31-1.25,8.84-1.43,17.71-1.1,26.57.45,12.23,2.32,24.3,5.11,36.23,2.88,12.33,6.58,24.4,11.59,36.05.62,1.45-.02,1.94-1.27,2.34-11.72,3.81-23.44,7.66-35.15,11.5-1.39.46-2.8.87-4.16,1.4-1.23.48-1.84.06-2.18-1.15-.99-3.53-1.64-7.12-2.19-10.74-1.11-7.28-1.79-14.59-2.29-21.94-.54-8.03-.76-16.07-.52-24.11.29-9.8,1.05-19.58,2.37-29.31,1.72-12.67,4.49-25.08,8.55-37.2,1.19-3.56,2.44-7.12,4.05-10.65Z" />
          <path className={classes.otherObjects} d="m1485.31,1061.03c-2.38-2.15-4.25-4.87-6.42-7.33-4.49-5.1-9.07-10.08-14.1-14.67-4.83-4.41-9.82-8.59-15.18-12.34-6.96-4.86-14.32-8.99-22.18-12.24-9.63-3.99-19.66-6.39-30.03-7.24-12.99-1.06-25.75.5-38.26,4.02-15.67,4.41-30.09,11.55-43.74,20.34-9.29,5.98-18.02,12.68-26.3,19.96-5.49,4.82-10.86,9.78-15.71,15.27-1.5,1.69-1.56,1.58-3.06-.03-3.23-3.49-6.51-6.92-9.73-10.41-6.17-6.67-12.3-13.36-18.47-20.02-.89-.96-.43-1.51.36-2.16,4.97-4.11,10.4-7.54,15.89-10.88,12.08-7.34,24.71-13.59,37.78-18.93,15.13-6.18,30.66-11.03,46.76-13.98,6.22-1.14,12.47-2.01,18.78-2.41,4.27-.27,8.54-.68,12.82-.58,15.52.36,30.66,2.72,45.14,8.57,11.43,4.62,21.77,11.03,31.13,19.05,12.58,10.77,22.32,23.79,30.43,38.11,1.44,2.54,2.75,5.16,4.1,7.89Z" />
          <path className={classes.otherObjects} d="m1434.75,1231.56c7.22,1.64,14.4,2.42,21.68,2.78,9.24.46,18.36-.35,27.44-1.78,17.03-2.69,33.31-7.88,49.06-14.85,8.47-3.74,16.72-7.91,24.64-12.7,1.15-.7,1.7-.53,2.28.64,6.17,12.4,12.36,24.79,18.59,37.16.69,1.37.21,1.93-1.05,2.46-8.71,3.65-17.81,5.99-26.98,8.12-8.5,1.97-17.09,3.49-25.75,4.57-6.31.79-12.64,1.44-19.01,1.75-5.16.25-10.33.61-15.49.54-17.71-.23-35.23-2.11-52.25-7.29-16.16-4.92-31.01-12.36-43.98-23.35-11.11-9.41-19.89-20.64-26.73-33.44-7.48-14-12.06-28.99-15.01-44.52-.05-.24,0-.49.26-.83.25.59.52,1.18.76,1.78,4.64,11.58,10.29,22.62,17.3,32.96,5.32,7.86,11.32,15.16,18.31,21.58,13.15,12.07,28.34,20.39,45.94,24.42Z" />
          <path className={classes.otherObjects} d="m1341.39,1271.91c-5.28-7.98-10.08-16.12-14.48-24.5-5.81-11.09-10.71-22.55-14.61-34.43-3.74-11.4-6.26-23.06-7.36-35.03-.54-5.84-.81-11.69-.5-17.52.52-9.93,2.11-19.69,5.15-29.22,4.66-14.64,12.05-27.73,21.97-39.42,8.59-10.12,18.4-18.93,29.21-26.63.36-.26.69-.64,1.26-.52.04.7-.55.97-.92,1.36-10.15,10.83-19.06,22.53-26.08,35.67-6.52,12.21-10.9,25.08-12.66,38.8-1.43,11.19-.75,22.3,1.64,33.31,2.86,13.15,8.08,25.34,14.92,36.88,5.4,9.1,11.68,17.54,18.6,25.54,3.46,3.99,7,7.9,10.77,11.57,9.47,9.22,19.45,17.86,30.46,25.26,1.56,1.05,1.56,1.32.37,2.77-4.83,5.9-9.63,11.81-14.44,17.73-3.82,4.7-7.62,9.41-11.46,14.09-1.28,1.56-1.58,1.54-3.09.09-8.91-8.59-16.49-18.29-23.65-28.34-1.73-2.42-3.36-4.91-5.1-7.47Z" />
          <path className={classes.otherObjects} d="m1462.19,1301.36c-11.26-3.83-22.1-8.32-32.71-13.44-15.25-7.36-29.64-16.11-43.03-26.47-4.58-3.54-8.92-7.38-13.15-11.36-8.21-7.73-15.55-16.17-21.78-25.57-9.26-13.96-15.33-29.18-17.86-45.76-.67-4.38-.94-8.81-1.11-13.25-.65-17.22,2.73-33.74,8.29-49.91,1.08-3.14,2.18-6.27,3.92-9.2-.34,2.02-.67,4.04-1.01,6.05-1.02,5.97-1.64,11.99-2.11,18.03-.46,5.9-.47,11.82-.19,17.7.68,14.13,3.43,27.85,9.28,40.85,4.52,10.04,10.44,19.11,17.91,27.23,8.32,9.06,17.97,16.36,28.61,22.4,17.45,9.9,36.27,15.97,55.9,19.74,8.88,1.7,17.81,2.92,26.84,3.55,2.76.19,5.53.08,8.28.5,1.23.19,1.72.46,1.56,1.84-1.09,9.38-2.11,18.77-3.15,28.16-.45,4.1-.9,8.2-1.31,12.3-.23,2.31-.52,2.61-2.84,2.19-4.17-.76-8.27-1.81-12.32-3.03-2.65-.8-5.28-1.68-8.03-2.55Z" />
          <path className={classes.otherObjects} d="m1277.12,1131.74c-2.47,8.39-4.6,16.74-6.02,25.25-.29,1.76-.57,1.92-2.29,1.47-13.04-3.39-26.08-6.77-39.13-10.15-2.62-.68-2.69-.76-1.85-3.26,3.11-9.3,7.5-18.02,12.18-26.6,3.48-6.38,7.2-12.61,11.17-18.69,5.22-8.01,10.8-15.76,16.84-23.18,5.84-7.18,12.16-13.92,18.81-20.34,8.9-8.58,18.55-16.19,29.15-22.59,10.35-6.25,21.33-11.05,33.03-14.09,7.39-1.92,14.91-2.97,22.57-3.35,19.69-.97,38.22,3.56,56.11,11.36,5.8,2.53,11.43,5.39,16.88,8.6.53.31,1.04.66,1.47,1.25-2.03-.66-4.06-1.31-6.09-1.97-10.81-3.52-21.87-5.98-33.17-7.18-18.61-1.97-36.82-.35-54.29,6.83-10.27,4.22-19.6,10-28,17.29-12.35,10.7-21.98,23.52-29.92,37.7-7.38,13.19-13,27.1-17.46,41.65Z" />
          <path className={classes.otherObjects} d="m1499.54,1147.74c-.08.23-.18.44-.29.65-.24-.25.03-.41.16-.66.05-.05.1,0,.13.01Z" />
          <path className={classes.otherObjects} d="m1485.36,1061.12s.07.04.09.06c-.04.01-.08.03-.14.02-.02-.02.03-.06.05-.08Z" />
        </g>
      </g>

      <g id="Digital_Media_Icons" data-name="Digital Media Icons">
        <g id="Hyperlink">
          <path className={classes.otherObjects} d="m597.12,986.18h-85.6c-5.6,0-10.9,2.3-14.8,6.4s-6.1,9.7-6.1,15.5,2.2,11.4,6.1,15.5,9.3,6.4,14.8,6.4h85.6c27,0,52.9,11.2,72,31.1,19.1,19.9,29.8,47,29.8,75.1s-10.7,55.2-29.8,75.1c-19.1,19.9-45,31.1-72,31.1h-85.6c-5.6,0-10.9,2.3-14.8,6.4-3.9,4.1-6.1,9.7-6.1,15.5s2.2,11.4,6.1,15.5,9.3,6.4,14.8,6.4h85.6c190.6-7.9,190.7-291.8,0-300Z" />
          <path className={classes.otherObjects} d="m369.52,1242.48h-85.6c-27,0-52.9-11.2-72-31.1-19.1-19.9-29.8-47-29.8-75.1s10.7-55.2,29.8-75.1c19.1-19.9,45-31.1,72-31.1h85.6c5.6,0,10.9-2.3,14.8-6.4s6.1-9.7,6.1-15.5-2.2-11.4-6.1-15.5-9.3-6.4-14.8-6.4h-85.6c-38.1,0-74.7,15.8-101.7,43.9-27,28.1-42.1,66.2-42.1,106s15.1,77.9,42.1,106.1c27,28.1,63.5,43.9,101.7,43.9h85.6c5.6,0,10.9-2.3,14.8-6.4s6.1-9.7,6.1-15.5-2.2-11.4-6.1-15.5c-3.9-4-9.3-6.3-14.8-6.3h0Z" />
          <path className={classes.otherObjects} d="m299.62,1136.18c0,5.8,2.2,11.4,6.1,15.5s9.3,6.4,14.8,6.4h239.8c5.6,0,10.9-2.3,14.8-6.4,3.9-4.1,6.1-9.7,6.1-15.5s-2.2-11.4-6.1-15.5c-3.9-4.1-9.3-6.4-14.8-6.4h-239.7c-5.6,0-10.9,2.3-14.8,6.4-4,4.1-6.2,9.7-6.2,15.5Z" />
        </g>
        <g id="Notes">
          <g id="Rounded">
            <g id="AV">
              <g id="_-Round-_-AV-_-note" data-name="-Round-/-AV-/-note">
                <g>
                  <rect id="Rectangle-Copy-68" className={classes.noFill} x="664.14" y="1342.04" width="600" height="600" />
                  <path id="_Icon-Color" data-name="🔹Icon-Color" className={classes.otherObjects} d="m1199.39,1577.29l-120.75-120.75c-9.25-9.25-22-14.5-35.25-14.5h-279.25c-27.5,0-50,22.5-50,50v300.25c0,27.5,22.25,49.75,49.75,49.75h400.25c27.5,0,50-22.5,50-50v-179.25c0-13.25-5.25-26-14.75-35.5Zm-160.25-97.75l137.5,137.5h-112.5c-13.75,0-25-11.25-25-25v-112.5Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Documents">
          <path className={classes.otherObjects} d="m1933.3,1844.51h-339.81v-408.92c0-11.15-9.71-18.59-24.27-18.59s-24.27,7.43-24.27,18.59v427.51c0,11.15,9.71,18.59,24.27,18.59h364.08c14.56,0,24.27-7.43,24.27-18.59s-12.14-18.59-24.27-18.59Z" />
          <path className={classes.otherObjects} d="m2044.95,1491.35v-5.58h0c0-1.86-2.43-3.72-4.85-5.58l-123.79-94.8c-2.43-1.86-4.85-1.86-7.28-3.72h-257.28c-14.56,0-24.27,7.43-24.27,18.59v408.92c0,11.15,9.71,18.59,24.27,18.59h368.93c14.56,0,24.27-7.43,24.27-18.59v-317.84c0,1.86,0,0,0,0Zm-92.23,157.99h-218.45c-14.56,0-24.27-7.43-24.27-18.59s9.71-18.59,24.27-18.59h218.45c14.56,0,24.27,7.43,24.27,18.59s-9.71,18.59-24.27,18.59Zm0-55.76h-218.45c-14.56,0-24.27-7.43-24.27-18.59s9.71-18.59,24.27-18.59h218.45c14.56,0,24.27,7.43,24.27,18.59s-9.71,18.59-24.27,18.59Z" />
        </g>
        <g id="Audio">
          <polygon className={classes.otherObjects} points="2536.23 1386.23 2536.23 886.23 2317.48 1042.48 2223.73 1042.48 2223.73 1229.98 2317.48 1229.98 2536.23 1386.23" />
          <path className={classes.otherObjects} d="m2567.48,1320.92c88.66-14.91,156.25-91.81,156.25-184.69s-67.59-169.78-156.25-184.69v31.59c71.22,14.53,125,77.62,125,153.09s-53.78,138.56-125,153.09v31.59Z" />
          <path className={classes.otherObjects} d="m2567.48,1190.04v34.19c36.34-12.91,62.5-47.25,62.5-88s-26.16-75.09-62.5-88v34.19c18.59,10.84,31.25,30.78,31.25,53.81s-12.66,42.97-31.25,53.81Z" />
        </g>
        <g id="Video">
          <g id="Rounded-2" data-name="Rounded">
            <g id="AV-2" data-name="AV">
              <g id="_-Round-_-AV-_-video_library" data-name="-Round-/-AV-/-video_library">
                <g>
                  <rect id="Rectangle-Copy-80" className={classes.noFill} x="1476.26" y="271.88" width="600" height="600" />
                  <path id="_Icon-Color-2" data-name="🔹Icon-Color" className={classes.otherObjects} d="m1551.26,421.88c-13.75,0-25,11.25-25,25v325c0,27.5,22.5,50,50,50h325c13.75,0,25-11.25,25-25s-11.25-25-25-25h-300c-13.75,0-25-11.25-25-25v-300c0-13.75-11.25-25-25-25Zm425-100h-300c-27.5,0-50,22.5-50,50v300c0,27.5,22.5,50,50,50h300c27.5,0,50-22.5,50-50v-300c0-27.5-22.5-50-50-50Zm-200,312.5v-225l136.75,102.5c6.75,5,6.75,15,0,20l-136.75,102.5Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
        <g id="Image">
          <g id="Rounded-3" data-name="Rounded">
            <g id="Image-2" data-name="Image">
              <g id="_-Round-_-Image-_-photo" data-name="-Round-/-Image-/-photo">
                <g>
                  <polygon id="Path" className={classes.noFill} points="693.94 315.08 1293.94 315.08 1293.94 815.08 693.94 815.08 693.94 315.08" />
                  <path id="_-Icon-Color" data-name="🔹-Icon-Color" className={classes.otherObjects} d="m1218.94,710.92v-291.67c0-22.92-22.5-41.67-50-41.67h-350c-27.5,0-50,18.75-50,41.67v291.67c0,22.92,22.5,41.67,50,41.67h350c27.5,0,50-18.75,50-41.67Zm-302.5-104.58l52.5,52.71,77.5-83.12c5-5.42,15-5.42,20,.21l87.75,97.5c6.25,6.88.25,16.67-10,16.67h-299.75c-10.5,0-16.25-10-9.75-16.87l62.25-66.67c4.75-5.42,14.25-5.63,19.5-.42Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>

    </svg>
  )
});

export default MyDigiBrainLogo;