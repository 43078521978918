import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';
import { loginStatusChange } from './loginStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { loginSuccess } from './loginDataSlice';
import { ILoginData } from '../../../dataObjects/models/registerAndLogin/LoginData';

// export interface ILoginFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const loginFailureSlice = createSlice({
  name: 'loginFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    loginFailure(state, action) {
      return action.payload;
    },

    // action has a payload with a null value
    loginFailureClear(state, action) {
      return null; // action.payload;
    },
  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(loginStatusChange, (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
          case enumWorkflowState.Success:
            // clear the Failure info from state
            return null;
            break;

          case enumWorkflowState.Failure:
            // do nothing
            break;
        }
      })
      .addCase(loginSuccess, (state: ILoginData | null, action: PayloadAction<ILoginData>) => {
        // clear the Failure info from state
        return null;
      })
  },
})

// Action creators are generated for each function in reducer)
export const { loginFailure, loginFailureClear } = loginFailureSlice.actions;

// export the core reducer for the slice
export const loginFailureReducer = loginFailureSlice.reducer;

export default loginFailureSlice.reducer;