import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';


const initialState: IMdbError | null = null;

export const sharingRequestDeclineFailureSlice = createSlice({
  name: 'sharingRequestDeclineFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    sharingRequestDeclineFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    sharingRequestDeclineFailureClear(state) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { sharingRequestDeclineFailureChange, sharingRequestDeclineFailureClear } = sharingRequestDeclineFailureSlice.actions;

// export the core reducer for the slice
export const sharingRequestDeclineFailureReducer = sharingRequestDeclineFailureSlice.reducer;

export default sharingRequestDeclineFailureSlice.reducer;