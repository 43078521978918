import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchTopicsFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchTopicsFailureSlice = createSlice({
  name: 'fetchTopicsFailure',
  initialState,
  reducers: {
    setFetchTopicsFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchTopicsFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchTopicsFailure, clearFetchTopicsFailure } = fetchTopicsFailureSlice.actions;

// export the core reducer for the slice
export const fetchTopicsFailureReducer = fetchTopicsFailureSlice.reducer;

export default fetchTopicsFailureSlice.reducer;