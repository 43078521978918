import { put } from "redux-saga/effects";
import { saveVideoLink } from "../../../firebaseServices/dataServices/dataServiceActions/videoLinkActions";
import { IVideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { ISaveVideoLinkRequest } from '../../../dataObjects/models/serviceRequests/videoLink/SaveVideoLinkRequest';
import { ISaveVideoLinkWorkflowState } from "../../store/SaveVideoWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";
import { videoLinkSaveError, videoLinkSaveSuccess, videoLinkSaveWorkflowStateChange } from "../../slices/videoLink/videoLinkSaveStatusSlice";

/**
 * @function saveVideoLinkOnlyWorkerSaga A "worker" saga function that performs the work for a request to
 *   save an VideoLink only (without a file upload).
 * @param action 
 */
export function* saveVideoLinkOnlyWorkerSaga(saveVideoLinkRequest: ISaveVideoLinkRequest) {
  try {
    // // extract the ISaveVideoLinkRequest from the action payload
    // const saveVideoLinkRequest: ISaveVideoLinkRequest | undefined = saveVideoLinkRequest;

    // if the ISaveVideoLinkRequest is defined...
    if (saveVideoLinkRequest !== undefined) {

      // update store state...      
      const saveVideoLinkWorkflowState: ISaveVideoLinkWorkflowState = {
        videoLink: saveVideoLinkRequest.videoLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.videoLink_SaveRequested,
        fileUploadRequest: undefined
      }
      yield put(videoLinkSaveWorkflowStateChange(saveVideoLinkWorkflowState));

      // get the VideoLink from ISaveVideoLinkRequest
      const videoLink: IVideoLink | undefined = saveVideoLinkRequest.videoLink;

      // if the VideoLink is defined...
      if (videoLink !== undefined) {
        // call services method to save the videoLink
        yield saveVideoLink(videoLink)

        // dispatch an action to indicate that the save operation was successful
        yield put(videoLinkSaveSuccess(videoLink));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveVideoLinkWorkflowState: ISaveVideoLinkWorkflowState = {
          videoLink: saveVideoLinkRequest.videoLink,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.videoLink_SaveSuccess,
        }

        saveVideoLinkWorkflowState.workflowState = enumWorkflowState.Success;
        saveVideoLinkWorkflowState.workflowStateMessage = MessagesStringAssets.videoLink_SaveSuccess;
        yield put(videoLinkSaveWorkflowStateChange(saveVideoLinkWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(videoLinkSaveError(error));
  }
}