/**
 * @class ConstantStringAssets A collection of string assets that pertain to constants
 */
export class ConstantStringAssets {

  static uncategorizedCategoryId: string = "00000";
  static uncategorizedCategoryName: string = "Uncategorized";
  static uncategorizedCategoryDescription: string = "Channels that have not been assigned a category";
  static uncategorizedCategoryBackground: string = "#F9FFA8"; // a light yellow color
  static uncategorizedCategoryFontColor: string = "#222";
  static uncategorizedCategoryBorder: string = "#222";

}