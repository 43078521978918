import React, { PropsWithChildren } from 'react';
import { Accept, DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { Paper, styled } from '@mui/material';
import {
  CloudUpload as CloudUploadIcon,
} from '@mui/icons-material';
import { ControlsStringAssets } from '../../../assets/stringAssets';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Paper for the Drag & Drop Area while dragging a file
const StyledPaperForDragAndDropAreaWhileDragging = styled((props) => (
  <Paper
    variant="outlined"
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  minHeight: '100px',
  marginTop: theme.spacing(1),
  padding: theme.spacing(1, 2),
  background: theme.fileDragAnDropArea.hoverBackground,
  border: theme.fileDragAnDropArea.hoverBorder,
  color: theme.fileDragAnDropArea.hoverTextColor,
  fontWeight: 'bolder',
  cursor: theme.fileDragAnDropArea.hoverCursor,
}));

// a styled Paper for the Drag & Drop Area while NOT dragging a file
const StyledPaperForDragAndDropAreaWhileNotDragging = styled((props) => (
  <Paper
    variant="outlined"
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  minHeight: '100px',
  marginTop: theme.spacing(1),
  padding: theme.spacing(1, 2),
  background: theme.fileDragAnDropArea.background,
  border: theme.fileDragAnDropArea.border,
  color: theme.fileDragAnDropArea.textColor,
  fontWeight: 'bolder',
  cursor: theme.fileDragAnDropArea.cursor,
  '&:hover': {
    background: theme.fileDragAnDropArea.hoverBackground,
    border: theme.fileDragAnDropArea.hoverBorder,
    color: theme.fileDragAnDropArea.hoverTextColor,
    fontWeight: theme.fileDragAnDropArea.hoverFontWeight,
    cursor: theme.fileDragAnDropArea.hoverCursor,
  },
}));

// a styled Icon representing uploading to the cloud
const StyledCloudUploadIcon = styled((props) => (
  <CloudUploadIcon
    {...props}
  />
))(({ theme }) => ({
  color: theme.fileDragAnDropArea.iconColor,
}));

export interface IFileInputProps extends PropsWithChildren<unknown> {
  // control: Control<any>;
  /**
  //  * @property {UseFormMethods['control']} control A React Hook Form 'control' object
  //  */
  control?: any;
  /**
   * @property {string} name The HTML name to be assigned to this FileInput control
   */
  name: string;
  /**
   * @property {string} prompt (optional) A prompt to display in the drop zone when dragging not in effect (default: a pre-defined string)
   */
  promptNotDragging?: string;
  /**
   * @property {string} prompt (optional) A prompt to display in the drop zone when dragging is in effect (default: a pre-defined string)
   */
  promptWhileDragging?: string;
  /**
   * @property {number} maxFileSize (optional) The maximum size of a file that will be allowed (default: 10MB)
   */
  maxFileSize?: number;
  /**
   * @property {boolean} allowMultipleFiles (optional) Whether to allow multiple files to be dropped in drop-zone (default: false)
   */
  allowMultipleFiles?: boolean;
  /**
   * @property {string} acceptedFileTypes (optional) File types that will be accepted (eg, for all image files: "image/*", or for certain types
   *                                      of images: "image/png,image/x-png,image/jpg,image/jpeg,image/bmp,image/gif")
   */
  acceptedFileTypes?: Accept;

  onFilesSelectedForUpload?: (<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void);
}


const FileInput: React.FC<IFileInputProps> = (props: IFileInputProps) => {

  const { promptNotDragging, promptWhileDragging, maxFileSize, allowMultipleFiles, acceptedFileTypes, onFilesSelectedForUpload } = props;

  function onDrop<T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) {
    if (onFilesSelectedForUpload) {
      onFilesSelectedForUpload(acceptedFiles, fileRejections, event);
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ accept: acceptedFileTypes, onDrop, multiple: allowMultipleFiles ?? false, maxSize: maxFileSize ?? undefined });

  return (
    <>
      {
        // depending on whether dragging is currently active, display a Paper indicating dragging or not
        isDragActive ?
          <StyledPaperForDragAndDropAreaWhileDragging {...getRootProps()} >
            <StyledCloudUploadIcon fontSize='large' />
            <input {...getInputProps()} />
            <span>{promptWhileDragging ?? ControlsStringAssets.fileDropZonePromptWhileDragging}</span>
          </StyledPaperForDragAndDropAreaWhileDragging>
          :
          <StyledPaperForDragAndDropAreaWhileNotDragging {...getRootProps()} >
            <StyledCloudUploadIcon fontSize='large' />
            <input {...getInputProps()} />
            <span>{promptNotDragging ?? ControlsStringAssets.fileDropZonePromptNotDragging}</span>
          </StyledPaperForDragAndDropAreaWhileNotDragging>
      }
    </>
  );
}

export default FileInput;