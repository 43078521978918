import React, { useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { enumWorkflowState, enumObjectPersistenceState, enumPersistableObjectType, enumDocumentLinkType, enumBeaconType } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { typeUniqueId } from '../../../dataObjects/types';
import { IDocumentLink, DocumentLink } from '../../../dataObjects/models/digitalMedia/DocumentLink';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { IUser } from '../../../dataObjects/models/users/User';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { ISaveDocumentLinkRequest } from '../../../dataObjects/models/serviceRequests/documentLink/SaveDocumentLinkRequest';
import { ISaveDocumentLinkWorkflowState } from '../../../uiMiddleware-redux/store/SaveDocumentWorkflowState';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import DocumentLinkForm from '../../forms/DocumentLinkForm/DocumentLinkForm';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { documentLinkSaveStatusClear, documentLinkSaveWithFileUploadRequest, documentLinkSaveWithoutFileUploadRequest, documentLinkSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/documentLink/documentLinkSaveStatusSlice';

/**
 * @interface IManageDocumentLinkPage Input properties for the ManageDocumentLinkPage
 */
export interface IManageDocumentLinkPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the DocumentLink already exists
  parentId?: typeUniqueId  // if the parentId is given (parentId of the DocumentLink parent object), it means that a new DocumentLink is to be created
}

/**
 * @function ManageDocumentLinkPage A React Function Component for manageing (creating or editing) a DocumentLink.
 * @param {IManageDocumentLinkPageProps} props Input properties
 */
const ManageDocumentLinkPage: React.FC<IManageDocumentLinkPageProps> = (props: IManageDocumentLinkPageProps) => {

  ManageDocumentLinkPage.displayName = "Manage Document Link Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [documentLinkId, setDocumentLinkId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the documentLinkId anytime the params.id changes
  useEffect(() => {
    setDocumentLinkId(params.id);
  }, [params.id]);

  // define an effect based on a change to the documentLinkId. Having a defined documentLinkId means that we will 
  // be editing an existing DocumentLink
  useEffect(() => {
    if (documentLinkId !== undefined) {
      // try to find an DocumentLink object with the Id given in the URL
      getSingleObjectById(documentLinkId, enumPersistableObjectType.DocumentLink, onDocumentLinkFetched);
    }
  }, [documentLinkId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [documentLinkParentId, setDocumentLinkParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the documentLinkParentId anytime the params.parentId changes
  useEffect(() => {
    setDocumentLinkParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the documentLink to be managed, either a new documentLink or an existing one
  const [documentLink, setDocumentLink] = useState<IDocumentLink | undefined>(undefined);

  // whether we are going to be managing a new documentLink
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveDocumentLinkStatus: () => void = useCallback(() => {
    dispatch(documentLinkSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveDocumentLinkStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveDocumentLinkStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveDocumentLinkStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving an DocumentLink
  const [saveDocumentWorkflowState, setSaveDocumentWorkflowState] = useState<ISaveDocumentLinkWorkflowState | null | undefined>(undefined);

  // indicates whether the page has been successfully completed (for saving new object or updating existing)
  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the documentLink save status store state variable
      dispatch(documentLinkSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // the success message for DocumentLink will merely be the defined constant
      const successMessage: string = MessagesStringAssets.documentLink_SaveSuccess;
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveDocumentLink, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, navigate]);

  // get the Redux workflow state for saving an DocumentLink
  const currentSaveDocumentLinkWorkflowState: ISaveDocumentLinkWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveDocumentLinkStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveDocumentWorkflowState(currentSaveDocumentLinkWorkflowState);
  }, [currentSaveDocumentLinkWorkflowState]);

  /**
   * @function prepareNewDocumentLink Prepares/creates a new documentLink that will be used if user is requesting to create/add a new documentLink.
   * @param parentChannel 
   */
  const prepareNewDocumentLink: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => IDocumentLink = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newDocumentLink: IDocumentLink;

      const newDocumentLinkId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newDocumentLink = new DocumentLink(user.id, newDocumentLinkId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        enumDocumentLinkType.GoogleCloudStorage, '', '', '', '', '', userPersistenceData);

      return newDocumentLink;
    }, []
  );

  // define an effect based on a change to the documentLinkParentId & currentUser. Having a defined documentLinkParentId means that we will
  // be creating a new DocumentLink
  useEffect(() => {
    if (documentLinkParentId !== undefined && currentUser) {
      setDocumentLink(prepareNewDocumentLink(documentLinkParentId, currentUser, currentUser.userSettings));
    }
  }, [documentLinkParentId, currentUser, prepareNewDocumentLink]);

  function onDocumentLinkFetched(documentLink: IDocumentLink | undefined): void {
    if (documentLink !== undefined) {
      // set the returned DocumentLink to be the one we are editing
      setDocumentLink(documentLink);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
    * @method handleFormSubmit Handles a submit request from the form in this page
    * @param {ISaveDocumentLinkRequest} saveDocumentLinkRequest A Submit event
    */
  function handleFormSubmit(saveDocumentLinkRequest: ISaveDocumentLinkRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {

        // dispatch an appropriate action to request saving the documentLink
        // if there is a file to be uploaded, dispatch an action that includes a file upload; 
        // otherwise, merely dispatch an action to save the documentLink only
        if (saveDocumentLinkRequest.fileUploadRequest !== undefined) {
          dispatch(documentLinkSaveWithFileUploadRequest(saveDocumentLinkRequest));
        } else {
          dispatch(documentLinkSaveWithoutFileUploadRequest(saveDocumentLinkRequest));
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold a progress message
  let progressMessage: string | undefined = undefined;

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;
  if (saveDocumentWorkflowState !== null && saveDocumentWorkflowState !== undefined) {

    if (saveDocumentWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveDocumentWorkflowState.workflowState === enumWorkflowState.InProgress || saveDocumentWorkflowState.workflowState === enumWorkflowState.Requested) {
      // get message from the workflow state to display as a progress message
      progressMessage = saveDocumentWorkflowState.workflowStateMessage ?? undefined;

      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveDocumentWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveDocumentWorkflowState.workflowError && saveDocumentWorkflowState.workflowError instanceof Error) {
        failureMessage = saveDocumentWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageDocumentLink_New : PageAndViewTitleStringAssets.pageTitle_ManageDocumentLink_Existing}
        onCloseAlert={resetSaveDocumentLinkStatus}
      >
        {documentLink && currentUser &&
          <DocumentLinkForm
            userId={currentUser.id}
            documentLink={documentLink}
            saveRequestInProgress={saveInProgress}
            progressMessage={progressMessage}
            onSubmit={handleFormSubmit}
          />
        }

      </GenericPageContainer>
    </>
  );

} // const ManageDocumentLinkUploadPage...

export default ManageDocumentLinkPage;
