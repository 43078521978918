import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IDocumentLink } from '../../../dataObjects/models/digitalMedia/DocumentLink';

const initialState: enumWorkflowState | null = null;

export const documentLinkDeleteStatusSlice = createSlice({
  name: 'documentLinkDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IDocumentLink
    documentLinkDeleteRequest: (state, action: PayloadAction<IDocumentLink>) => {
      documentLinkDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    documentLinkDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { documentLinkDeleteRequest, documentLinkDeleteStatusChange } = documentLinkDeleteStatusSlice.actions;

// export the core reducer for the slice
export const documentLinkDeleteStatusReducer = documentLinkDeleteStatusSlice.reducer;

export default documentLinkDeleteStatusSlice.reducer;