import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { INote } from '../../../../dataObjects/models/digitalMedia/Note';
import { NotesCardGridViewItem } from './NotesCardGridViewItem';
import { INoteViewModel } from '../../../../dataObjects/viewModels/noteViewModel';


export interface INotesCardGridViewProps extends PropsWithChildren<unknown> {
  noteViewModels: Array<INoteViewModel>;
  onDelete: (note: INote) => void;
}


export const NotesCardGridView: React.FC<INotesCardGridViewProps> = (props: INotesCardGridViewProps) => {
  NotesCardGridView.displayName = 'Notes Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render NotesList`);

  const { noteViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          noteViewModels.map(noteViewModel =>
            <Grid item key={noteViewModel.note.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <NotesCardGridViewItem noteViewModel={noteViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}