import { collection, CollectionReference, DocumentData, Firestore, onSnapshot, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames } from '../enums';
import { IFirestoreAudioLinkRepository_Ext } from '.';
import { NumericConstants } from '../../../../../../assets/numericAssets';

/** 
 * @class FirestoreAudioLinkRepository_Ext Provides extended functionality beyond the FirestoreAudioLinkRepository class
 * *** IMPORTANT NOTE: FirestoreAudioLinkRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestorePersistenceMetadataRepository is focused on other features.
 *   Therefore, 
 *     1) FirestoreAudioLinkRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreAudioLinkRepository_Ext.
 */
export class FirestoreAudioLinkRepository_Ext implements IFirestoreAudioLinkRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method getObjectsForIds_onSnapshot Makes 1 or more onSnapshot() requests to firestore for retrieving AudioLink objects from the database
   *   matching Ids passed in. Each onSnapshot request will deliver an unsubscribe callback function to be used for unsubscribing from 
   *   the onSnapshot request. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {Array<typeUniqueId>} ids The array of unique Ids associated with the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<Array<() => void>>} A collection of callbacks for unsubscribing from the firebase onSnapshots request.
   * @summary We can only request up to 30 objects at a time because Firestore allows up to 30 values within an 'in' clause. Therefore,
   *          if there are more than 30 ids provided, multiple onSnapshot requests will be made with each including up to 30 ids.
   */
  getObjectsForIds_onSnapshot(ids: Array<typeUniqueId>, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Array<() => void>> {
    return new Promise<Array<() => void>>(async (resolve, reject) => {
      try {
        // whether to display console logs (displayConsoleLogs && console.log statements)
        const displayConsoleLogs: boolean = false;

        displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. # of ids: ${ids.length}`, 'background: #660; color: #fff');
        displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. ids: ${ids}`, 'background: #660; color: #fff');
        displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. JSON.stringify(ids): ${JSON.stringify(ids)}`, 'background: #660; color: #fff');

        // Declare an empty array that will hold one or more unsubscribe callback function pointers
        const unsubscribeCallbacks: Array<() => void> = [];

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the AudioLink collection
        const imageLinkCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.AudioLinkCollection);

        // There will be one or more queries, depending on the number of Ids provided and the size of each query group.
        // Firestore has a limit on the maximum number of search criteria that can be included as part of an "IN" clause, so
        // the queries will potentially have to be chunked.
        // Establish the size of each query group.
        const queryGroupSize: number = NumericConstants.maxCriteriaForAFirestoreInClause;

        // Loop to establish the number of groups and the Ids to use in each query, and then perform the query
        for (let idxGroup = 0; idxGroup < (ids.length / queryGroupSize); idxGroup++) {
          const startingIdIndexForGroup = 0 + (idxGroup * queryGroupSize);
          const endingIdIndexForGroup = startingIdIndexForGroup + Math.min(queryGroupSize, (ids.length - (idxGroup * queryGroupSize)));

          displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. For Group index (${idxGroup}), StartingIdIndex: ${startingIdIndexForGroup}; EndingIndex: ${endingIdIndexForGroup}`, 'background: #660; color: #fff');

          const idsInGroupQuery: Array<typeUniqueId> = ids.slice(startingIdIndexForGroup, endingIdIndexForGroup);

          displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. idsInGroupQuery ${idsInGroupQuery}`, 'background: #660; color: #fff');
          displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. JSON.stringify(idsInGroupQuery) ${JSON.stringify(idsInGroupQuery)}`, 'background: #660; color: #fff');

          displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. [...idsInGroupQuery] ${[...idsInGroupQuery]}`, 'background: #660; color: #fff');
          displayConsoleLogs && console.log(`%c FirestoreAudioLinkRepository_Ext.getObjectsForIds_onSnapshot. JSON.stringify([...idsInGroupQuery]) ${JSON.stringify([...idsInGroupQuery])}`, 'background: #660; color: #fff');

          // Prepare a query that will get documents corresponding to the Ids provided.
          // Note: We don't need to worry about the state of the documents as SearchMetadata should only be present for documents 
          //       in either the 'New' or 'Modified' state. 
          const documentQuery: Query<DocumentData> =
            query(imageLinkCollection,
              where("id", "in", [...idsInGroupQuery])
            );

          // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
          // 'unsubscribe' callback function
          unsubscribeCallbacks.push(onSnapshot(documentQuery, callback));
        }

        // return the 'unsubscribeCallbacks' array with the Promise, via the 'resolve'
        resolve(unsubscribeCallbacks);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }

}
