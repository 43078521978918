/** 
 * @class JsonConverter A base class for converting class instances to/from JSON.
 * (Note: This class is 'abstract' and cannot be instantiated.)
 */
export abstract class JsonConverter {

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of a class, along with any child objects (transforms an instance of the class to a JSON object).
   */
  abstract toJSON(includeContainedObjects: boolean): any;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSONArray Serializes an array of objects to JSON objects.
   * @param convertibleToJsonObjects An array of objects that are to be converted to JSON objects.
   * @param includeContainedObjects Whether to include contained objects, for each object in the array.
   */
  static toJSONArray(convertibleToJsonObjects: Array<any>, includeContainedObjects: boolean = true): Array<any> {
    try {
      // create an array that will hold the JSON objects to be returned
      let array = new Array<any>();

      // for each object in the array, ...
      convertibleToJsonObjects.forEach(convertibleToJsonObject => {
        // ...call the object's 'toJSON()' method to convert the object to JSON and...
        const jsonObject = convertibleToJsonObject.toJSON(includeContainedObjects);
        // ...add the JSON object to the array
        array.push(jsonObject);
      })

      return array;
    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON A static method that initiates a conversion from a JSON object to the type of class indicated by the
   * 'classType' parameter, calling the static 'fromJSON' method of that class.
   * @param classType The type of class (e.g., ImageLink, TopicItem) to receive the converted JSON object (for reflection use).
   * @param jsonObject The JSON object to be converted.
   * @param includeContainedObjects Whether to include contained objects, for the object being converted.
   */
  static fromJSON(classType: any, jsonObject: any, includeContainedObjects: boolean = true): any {
    try {
      // using JavaScript-based reflection, call the static fromJSON() in the class to receive conversion. It will be 
      // responsible for creating an instance of its own type, and converting from the JSON object.
      const convertibleFromJsonObject = classType.fromJSON(jsonObject, includeContainedObjects);

      // merely return the object that has been converted
      return convertibleFromJsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method arrayFromJSONArray Converts an array of JSON objects to class instances for the classType (e.g., ImageLink, TopicItem, etc.)
   * @param classType The type of class to use for converting each object in the array (for use with reflection within the fromJSON() method).
   * @param jsonObjectsArray An array of JSON objects to be converted.
   * @param includeContainedObjects Whether to include contained objects, for each object in the array.
   */
  static arrayFromJSONArray(classType: any, jsonObjectsArray: Array<any>, includeContainedObjects: boolean = true): Array<any> {
    try {
      // declare an empty array that will be returned
      let convertibleFromJsonObjectsArray: Array<any> = new Array<any>();

      // traverse the array of JSON objects...
      jsonObjectsArray.forEach(jsonObject => {
        // ... and call the JsonConverter.fromJSON method to convert from the JSON object
        const convertibleFromJsonObject = JsonConverter.fromJSON(classType, jsonObject, includeContainedObjects);

        // add to the array of persistable objects
        convertibleFromJsonObjectsArray.push(convertibleFromJsonObject);
      });

      // return the array of objects
      return convertibleFromJsonObjectsArray;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
