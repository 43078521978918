import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumNoParentObjectType } from "../../../enums";
import { IPersistable, IPersistableAsJson } from "../Persistable";
import { IPersistableArray } from ".";
import { FieldValueValidation } from "../../../validations/FieldValueValidation";
import { JsonConverter } from "../../../utilities/JsonConverter";

/**
 * @class PersistableCollection Manages an array of Persistable objects (of the same type)
 * and can self serialize/de-serialize, also offering convenience methods for adding, updating, 
 * and removing objects in the array.
 * 
 * The key purpose of this class is to provide a collection container for members of a parent object.
 * For example, an AttachedResource object contains collections of ImageLinks, VideoLinks, AudioLinss, etc.
 * This collection will be used as a data type for each of the aforementioned collections.
 */
export class PersistableArray<T extends IPersistable> implements IPersistableArray<T> {
  constructor(
    classType: any,
    parentObjectType?: typePersistableParentObjectType,
    parentId?: typeUniqueId,
    persistableObjects?: Array<T>
  ) {
    this.classType = classType;

    if (parentObjectType !== undefined) {
      this.parentObjectType = parentObjectType;
    }

    if (parentId !== undefined) {
      this.parentId = parentId;
    }

    if (persistableObjects && persistableObjects.length > 0) {
      this.addObjectsArray(persistableObjects);
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {any} _classType A Persistable-derived class (e.g., ImageLink, VideoLink, etc.)
   */
  private _classType: any;

  /**
   * @method classType Returns the value of the _classType property
   */
  get classType(): any {
    return this._classType;
  }

  /**
   * @method classType Sets the value of the _classType property
   * @param {any} value An array of Persistable objects of the appropriate type (can be an empty array)
   */
  set classType(value: any) {
    this._classType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueIdWithUndefinedOption} _parentId Id of the parent of this collection
   */
  // We are forced to set a default value here or in the constructor. In the constructor, we call this property's 
  // setter method, so that the value can be validated.
  private _parentId: typeUniqueIdWithUndefinedOption = undefined;

  /**
   * @method parentId is an optional getter method for _parentId
   */
  get parentId() {
    return this._parentId;
  }

  /**
   * @method parentId is an optional setter method for _parentId
   * @param {typeUniqueIdWithUndefinedOption} value is the input value for setting _parentId
   */
  set parentId(value: typeUniqueIdWithUndefinedOption) {
    // validate that the value is not an empty string
    FieldValueValidation.validateUniqueIdHasValueIfDefined(value, 'A parentId cannot be an empty string.');
    this._parentId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typePersistableParentObjectType} _parentObjectType ObjectType of the parent of this collection
   */
  // We are forced to set a default value here or in the constructor. In the constructor, we call this property's 
  // setter method, so that the value can be validated.
  private _parentObjectType: typePersistableParentObjectType = enumNoParentObjectType.NoParent;

  /**
   * @method parentObjectType is an optional getter method for _parentId
   */
  get parentObjectType() {
    return this._parentObjectType;
  }

  /**
   * @method parentObjectType is an optional setter method for _parentId
   * @param {typePersistableParentObjectType} value is the input value for setting _parentId
   */
  set parentObjectType(value: typePersistableParentObjectType) {
    // validate that the value is not an empty string
    //FieldValueValidation.validateParentObjectTypeIsValid(value, 'A parentObjectType must be a legitimate value and cannot be undefined.');
    this._parentObjectType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<T>} persistableObjects An array of Persistable objects
   */
  private _persistableObjects: Array<T> = [];

  /**
   * @method persistableObjects A getter for _persistableObjects
   */
  get persistableObjects(): Array<T> {
    return this._persistableObjects;
  }

  /**
   * @method persistableObjects A setter for _persistableObjects
   */
  set persistableObjects(value: Array<T>) {
    this._persistableObjects = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method length The number of Persistable objects currently in the array
   */
  get length(): number {
    return this.persistableObjects.length;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IPersistableArray<T>} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IPersistableArray<T> {
    // construct a new instance of an array object, using core property values from this instance
    const copyOfObject: IPersistableArray<T> = new PersistableArray<T>(this.classType, this.parentObjectType, this.parentId);

    // copy the objects in the array
    if (this.persistableObjects !== undefined && this.persistableObjects.length > 0) {
      this.persistableObjects.forEach(persistableObject => {
        copyOfObject.addObject(persistableObject.copy() as T);
      });

    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts 'this' instance's array of Persistable objects to an array of 'IPersistableAsJson' JSON objects
   * @param includeContainedObjects Whether to include contained objects
   */
  toJSON(includeContainedObjects: boolean = true): Array<IPersistableAsJson> {
    try {
      // delegate to the JsonConverter.toJSONArray() method, forwarding 'this.classType', 'this.persistableObjects' array, 
      // and the 'includeContainedObjects' flag
      return JsonConverter.toJSONArray(this.persistableObjects, includeContainedObjects);
    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Sets 'this' instance's array of Persistable objects from an array of 'IPersistableAsJson' JSON objects 
   * @param jsonObjectsArray An array of 'IPersistableAsJson' JSON objects
   * @param includeContainedObjects Whether to include contained objects
   */
  fromJSON(jsonObjectsArray: Array<IPersistableAsJson>, includeContainedObjects: boolean = true): Array<T> {
    try {
      // delegate to the JsonConverter.arrayFromJSONArray() method, forwarding 'this.classType', the 'jsonObjectsArray', 
      // and the 'includeContainedObjects' flag
      return JsonConverter.arrayFromJSONArray(this.classType, jsonObjectsArray, includeContainedObjects);
    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Sets 'this' instance's array of Persistable objects from an array of 'IPersistableAsJson' JSON objects 
   * @param jsonObjectsArray An array of 'IPersistableAsJson' JSON objects
   * @param includeContainedObjects Whether to include contained objects
   */
  addFromJSON(jsonObjectsArray: Array<IPersistableAsJson>, includeContainedObjects: boolean = true): Array<T> {
    try {
      // convert the JSON objects array by delegating to the JsonConverter.arrayFromJSONArray() method, 
      // forwarding 'this.classType', the 'jsonObjectsArray', and the 'includeContainedObjects' flag
      return JsonConverter.arrayFromJSONArray(this.classType, jsonObjectsArray, includeContainedObjects);
    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method alignChildrenWithParentSpecs Aligns all child nodes in the array with the specs of the parent ('parentId' and
   * 'parentObjectType').
   * This is to ensure data integrity.  All objects in the current instance must belong properly to the same parent object.
   */
  protected alignChildrenWithParentSpecs(): void {
    // 
    this.persistableObjects.forEach(po => po.parentObjectType = this.parentObjectType);
    this.persistableObjects.forEach(po => po.parentId = this.parentId);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addObject Adds a Persistable object to the array
   * @param {T} persistableObject A Persistable object to be added to the array
   */
  addObject(persistableObject: T): boolean {
    try {
      // push the additional object to 'this.persistableObjects'
      this.persistableObjects.push(persistableObject);

      // ensure all objects in the internal array point to the same parent
      this.alignChildrenWithParentSpecs();

      // if we made it this far, return 'true' indicating that the operation was successful
      return true;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addPersistableObjectsArray Adds (appends) an array of similarly-typed Persistable objects to 'this'
   * instance's existing array of Persistable objects
   * @param {Array<T>} persistableObjectsArray An array of objects to add to the current set of objects in the array
   * @param {boolean} includeContainedObjects Whether to include contained objects in the set of objects added
   * @returns {number} A count of the number of objects added
   */
  addObjectsArray(persistableObjectsArray: Array<T>): number {
    try {
      const countOfObjectsAdded = persistableObjectsArray.length;

      if (countOfObjectsAdded > 0) {
        // push the array of additional objects to 'this.persistableObjects'
        // (use the JavaScript ES6 destructuring (...) to perform the operation in one fell swoop)
        this.persistableObjects.push(...persistableObjectsArray);

        // ensure all objects in the internal array point to the same parent
        this.alignChildrenWithParentSpecs();
      }

      return countOfObjectsAdded;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method replaceWithObjectsArray Replaces the current array of Persistable objects with a new array of Persistable objects
   * @param {Array<T>} persistableObjectsArray An array of objects to use in replacing the current set of objects in the array
   * @param {boolean} includeContainedObjects Whether to include contained objects in the set of objects added
   * @returns {number} A count of the number of objects in the replacement set
   */
  replaceWithObjectsArray(persistableObjectsArray: Array<T>): number {
    try {
      // remove all objects from the internal array
      this.removeAllObjects();

      // call the method to add objects from an array
      const countOfObjectsAdded = this.addObjectsArray(persistableObjectsArray)

      // ensure all objects in the internal array point to the same parent
      this.alignChildrenWithParentSpecs();

      return countOfObjectsAdded;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addJsonObjectsArray Adds (appends) the array of JSON object to this.persistableObjects, after converting
   * each of the JSON objects to the appropriate type of object.
   * @param {Array<IPersistableAsJson>} jsonObjectsArray An array of JSON objects to convert and add to the 
   * @param {boolean} includeContainedObjects Whether to include contained objects in the set of objects added
   * @returns {number} A count of the number of objects added
   */
  addJsonObjectsArray(jsonObjectsArray: Array<IPersistableAsJson>, includeContainedObjects: boolean = true): number {
    try {
      const countOfObjectsAdded = jsonObjectsArray.length;

      if (countOfObjectsAdded > 0) {
        // convert the JSON objects array by delegating to the JsonConverter.arrayFromJSONArray() method, 
        // forwarding 'this.classType', the 'jsonObjectsArray', and the 'includeContainedObjects' flag
        let additionalPersistableObjectsArray: Array<T> =
          JsonConverter.arrayFromJSONArray(this.classType, jsonObjectsArray, includeContainedObjects);

        // call the addPersistableObjectsArray to append to 'this.persistableObjects'
        this.addObjectsArray(additionalPersistableObjectsArray);

        // ensure all objects in the internal array point to the same parent
        this.alignChildrenWithParentSpecs();
      }

      return countOfObjectsAdded;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method replaceWithJsonObjectsArray Replaces the current array of Persistable objects with a new array of Persistable objects
   * converted from a JSON array
   * @param {Array<T>} persistableObjectsArray An array of JSON objects to use in replacing the current set of objects in the array
   * @param {boolean} includeContainedObjects Whether to include contained objects in the set of objects added
   * @returns {number} A count of the number of objects in the replacement set
   */
  replaceWithJsonObjectsArray(jsonObjectsArray: Array<IPersistableAsJson>, includeContainedObjects: boolean = true): number {
    try {
      // remove all objects from the internal array
      this.removeAllObjects();

      // call the method to add JSSON objects from an array
      let countOfObjectsAdded = this.addJsonObjectsArray(jsonObjectsArray);

      // ensure all objects in the internal array point to the same parent
      this.alignChildrenWithParentSpecs();

      return countOfObjectsAdded;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method updateObject Updates a single object in the array, if an item with the given Id is found.
   * @param {typeUniqueId} id The unique Id of the object to be updated
   * @returns {T | undefined} If an object with the given Id was found and updated, a copy of the updated object,
   * before being updated, is returned. If the object wasn't found (by its Id), 'undefined' is returned.
   */
  updateObject(id: typeUniqueId, persistableObject: T): T | undefined {
    try {
      let objectUpdated: T | undefined = undefined;

      // get the array index of the object to update
      const idxObjectToUpdate = this.persistableObjects.findIndex(po => po.id === id);
      if (idxObjectToUpdate !== -1) {
        // capture the object that will be updated
        objectUpdated = this.persistableObjects[idxObjectToUpdate];

        // replace the object instance at the given index
        this.persistableObjects[idxObjectToUpdate] = persistableObject;

        // ensure all objects in the internal array point to the same parent
        this.alignChildrenWithParentSpecs();
      }

      return objectUpdated;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method removeObject Removes a single object from the array.
   * @param {typeUniqueId} id The unique Id of the object to be removed
   * @returns {T | undefined} If an object with the given Id was found and removed, a copy of the removed object
   * is returned. If the object wasn't found, 'undefined' is returned.
   */
  removeObject(id: typeUniqueId): T | undefined {
    try {
      // search for the object in the array
      const objectToRemove: T | undefined = this.persistableObjects.find(po => po.id === id) as T | undefined;

      if (objectToRemove !== undefined) {
        // filter out the object to be removed, per its Id, which removes the object from the array
        this.persistableObjects = this.persistableObjects.filter(po => po.id !== id);
      }

      return objectToRemove;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method removeAllObjects Removes all objects from the array
   * @returns {void} No return value
   */
  removeAllObjects(): void {
    try {
      // merely assign a new empty array to this.persistableObjects
      this.persistableObjects = [];
    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}