import { getStorage, ref, FirebaseStorage, StorageReference, ListResult, listAll, deleteObject } from "firebase/storage";
import { typeUniqueId } from "../../../dataObjects/types";
import { FirebaseAppSingleton } from '../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';

/**
 * @function deleteAllCloudStorageFilesForUserId Deletes the Cloud Storage files for a given userId
 * @param {typeUniqueId} userId The userId for which Cloud Storage Files are to be deleted
 * @returns {Promise<void>} Returns a promise with void value, providing asynchronous functionality.
 */
export function deleteAllCloudStorageFilesForUserId(userId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // formulate the root pathname of the folder that holds all files for a user (per the userId)
      // Path format: '{storageRef}/userFiles/{userId}/{fileClass}/{fileUniqueId}/{fileName}
      const storageBasePath: string = `userFiles/${userId}`;

      // get a reference to the Firebase Cloud Storage Service
      const storageService: FirebaseStorage = getStorage(FirebaseAppSingleton.getInstance().firebaseApp);

      // Create a reference to the base user folder from which files are to be deleted
      const folderRef: StorageReference = ref(storageService, storageBasePath);

      // // get a list of all files in the folder
      // const listResult: ListResult = await listAll(folderRef);

      // if the folderRef reference is valid...
      if ((await listAll(folderRef)).items.length > 0) {
        // call function that will recursively delete all objects for a logical cloud storage folder
        await deleteAllObjectsForCloudStorageFolder(folderRef);

        // finally, delete the logical root folder for the user
        await deleteObject(folderRef);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

function deleteAllObjectsForCloudStorageFolder(folderRef: StorageReference): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // using the Cloud Storage 'listAll' feature, list all of the objects within the given folder
      const listResult: ListResult = await listAll(folderRef);

      // each 'item' in the results represents a storage file, so traverse the items and delete the item
      listResult.items.forEach(async (itemRef: StorageReference, index: number) => {
        await deleteObject(itemRef);
      });

      // each 'prefix' in the results represents another logical 'folder' reference in the hiearchy
      listResult.prefixes.forEach(async (childFolderRef: StorageReference, index: number) => {
        // recursively call this current function to delete all objects in the logical child folder
        await deleteAllObjectsForCloudStorageFolder(childFolderRef);

        // delete the logical child folder
        await deleteObject(childFolderRef);
      });

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}