import { take } from "redux-saga/effects";
import { saveVideoLinkWithFileUploadWorkerSaga } from "./saveVideoLinkWithFileUploadWorkerSaga";
import { videoLinkSaveWithFileUploadRequest } from "../../slices/videoLink/videoLinkSaveStatusSlice";

/**
 * @function saveVideoLinkWithFileUploadWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an VideoLink along with a file upload associated with it.
 */
export function* saveVideoLinkWithFileUploadWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the videoLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveVideoLinkRequestData = yield take(videoLinkSaveWithFileUploadRequest);
    const { payload: saveVideoLinkRequest } = saveVideoLinkRequestData;

    yield saveVideoLinkWithFileUploadWorkerSaga(saveVideoLinkRequest);
  }
}