import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId } from '../../../types';
import { enumLocale } from "../../../enums";
import { IUserPersistenceData, IUserPersistenceDataAsJson } from '.';
import { getCurrentDeviceLocale, getDeviceAndBrowserInfo } from '../../../utilities/deviceAndLocale';

/** 
 * @class UserPersistenceData Represents a class with user-related data used in capturing persistence tracking for objects
 */
export class UserPersistenceData implements IUserPersistenceData {
  /**
   * @method Constructor method
   * @param {typeUniqueId} userId The Id of the user responsible for triggering the persistence operation
   * @param {string} userName The name of the user at the time that the persistence operation was triggered 
   * @param {string} deviceInfo Information about the device and browser being used when the persistence operation was triggered
   * @param {string} deviceLocale The locale setting on the device when the persistence operation was triggered
   */
  constructor(
    userId: typeUniqueId,
    userName: string,
    deviceInfo?: string,
    deviceLocale?: string
  ) {
    this.userId = userId;
    this.userName = userName;

    if (deviceInfo !== undefined) {
      this.deviceInfo = deviceInfo;
    } else {
      this.deviceInfo = getDeviceAndBrowserInfo();
    }

    // if a deviceLocale was provided, use it; otherwise, get the locale for the current device
    if (deviceLocale !== undefined) {
      this.deviceLocale = deviceLocale;
    } else {
      this.deviceLocale = getCurrentDeviceLocale();
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _userId The Id of the user responsible for triggering the persistence operation
   */
  private _userId: typeUniqueId = RandomId.newId();

  /**
   * @method userId is an optional getter method for _userId
   */
  get userId() {
    return this._userId;
  }

  /**
   * @method userId is an optional setter method for _userId
   * @param {typeUniqueId} value The value to use in setting _userId
   */
  set userId(value: typeUniqueId) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _userName The name of the user at the time that the persistence operation was triggered
   */
  private _userName: string = '';

  /**
   * @method userName Getter method for _userName
   */
  get userName(): string {
    return this._userName;
  }

  /**
   * @method userName Setter method for _userName
   * @param {string} value The value to be used in setting _userName
   */
  set userName(value: string) {
    this._userName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _deviceInfo Information about the device and browser being used when the persistence operation was triggered
   */
  private _deviceInfo: string = '';

  /**
   * @method deviceInfo Getter method for _deviceInfo
   */
  get deviceInfo(): string {
    return this._deviceInfo;
  }

  /**
   * @method deviceInfo Setter method for _deviceInfo
   * @param {string} value The value to be used in setting _deviceInfo
   */
  set deviceInfo(value: string) {
    this._deviceInfo = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _deviceLocale The locale setting on the device when the persistence operation was triggered
   */
  private _deviceLocale: string = enumLocale.English;

  /**
   * @method deviceLocale Getter method for _deviceLocale
   */
  get deviceLocale(): string {
    return this._deviceLocale;
  }

  /**
   * @method deviceLocale Setter method for _deviceLocale
   * @param {string} value The value to be used in setting _deviceLocale
   */
  set deviceLocale(value: string) {
    this._deviceLocale = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the Typescript object instance to a JSON object instance
   * @returns {IUserPersistenceDataAsJson} A JSON object instance
   */
  toJSON(): IUserPersistenceDataAsJson {
    try {
      // declare a JSON object to be returned
      const jsonObject: IUserPersistenceDataAsJson = {
        userId: this.userId,
        userName: this.userName,
        deviceInfo: this.deviceInfo,
        deviceLocale: this.deviceLocale
      };

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUserPersistenceData} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserPersistenceData {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserPersistenceData = Object.create(UserPersistenceData.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a JSON object instance to a Typescript object instance
   * @param {IUserPersistenceDataAsJson} jsonObject A JSON object instance
   * @returns {IUserPersistenceData} A Typescript object instance
   */
  static fromJSON(jsonObject: IUserPersistenceDataAsJson): IUserPersistenceData {
    try {
      // create a new instance of this class
      let userPersistenceData: IUserPersistenceData = Object.create(UserPersistenceData.prototype);

      // copy field values from the json object 
      userPersistenceData.userId = jsonObject.userId;
      userPersistenceData.userName = jsonObject.userName;
      userPersistenceData.deviceInfo = jsonObject.deviceInfo;
      userPersistenceData.deviceLocale = jsonObject.deviceLocale;

      // return the object
      return userPersistenceData;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
