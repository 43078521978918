import { typeUniqueId } from "../../../../dataObjects/types";
import { generateIdForEmailRequest } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../dataObjects/models/persistence/UserPersistenceData";
import { IObjectSharingRequestTracker } from "../../../../dataObjects/models/collaboration/ObjectSharingTracker";

/**
 * @function generateEmailRequestForObjectSharingRequest Generates an email request when a user is sharing an object (eg, a Channel)
 *                                                       with another user
 * @param {IObjectSharingRequestTracker} objectSharingRequestTracker Object Sharing information with details needed for an email message
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForObjectSharingRequest(objectSharingRequestTracker: IObjectSharingRequestTracker): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForObjectSharingRequest`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      displayConsoleLogs && console.log(`%c generateEmailRequestForObjectSharingRequest. Ready to create objects to submit an email request.`, 'background: #550; color: #fff');

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(objectSharingRequestTracker.requestorUserId, objectSharingRequestTracker.requestorName, 'web browser', enumLocale.English);

      // the Email Request Id will be a concatenation of the ObjectSharingRequestTracker Id, the Request Type, and a unique Id
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(objectSharingRequestTracker.id, enumEmailRequestType.ObjectSharingRequest);
      const requestorUserName: string = objectSharingRequestTracker.requestorName;
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();
      const emailRequest: IEmailRequest = new EmailRequest(objectSharingRequestTracker.requestorUserId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.ObjectSharingRequest, objectSharingRequestTracker.requestorUserId, requestorUserName, objectSharingRequestTracker.recipientUserId, objectSharingRequestTracker.recipientEmail, objectSharingRequestTracker.recipientName,
        enumAppPlatformType.Web, `https://${baseDomain}`, emailRequestSubmittedTimestamp, objectSharingRequestTracker.sharingObjectName, objectSharingRequestTracker.sharingObjectType, 
        undefined, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForObjectSharingRequest. Finished creating email request.`, 'background: #550; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForObjectSharingRequest. Error encountered: ${error}`, 'background: #550; color: #fff');

      reject(error);
    }
  });
}