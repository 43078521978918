import { FirebaseApp } from 'firebase/app';
import { BaseRepository } from '../../../../../dataServices/dataRepositories/BaseRepository';
import { IPersistable } from '../../../../../../dataObjects/models/persistence/Persistable';
import { IFirebaseBaseRepository } from './IFirebaseBaseRepository';
import { IBaseDbContext } from '../../../../../dataServices/dbContexts/BaseDbContext';
import { FirebaseAppSingleton } from '../../../FirebaseAppSingleton';

export abstract class FirebaseBaseRepository<TEntity extends IPersistable, DbType> extends BaseRepository<TEntity, DbType> implements IFirebaseBaseRepository<TEntity, DbType> {
  /**
   * @method Constructor method
   * @param {IBaseDbContext<DbType>} dbContext The Database Context to be used by the derived class 
   * @param {FirebaseApp} firebaseApp A Firebase Application object that has been initialized, if provided
   */
  constructor(
    dbContext: IBaseDbContext<DbType>,
    firebaseApp?: FirebaseApp
  ) {
    super(dbContext);

    // if a firebaseApp was supplied, assign to our data member; otherwise, create a firebaseApp and assign it
    // debugger;
    if (firebaseApp !== undefined) {
      this._firebaseApp = firebaseApp;
    } else {
      // const firebaseConfig: IFirebaseConfiguration = new FirebaseConfiguration();
      // this._firebaseApp = firebase.initializeApp(firebaseConfig.toJSON());
      this._firebaseApp = FirebaseAppSingleton.getInstance().firebaseApp;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {FirebaseApp} _firebaseApp A Firebase Application object that has been initialized, if provided
   */
  private _firebaseApp: FirebaseApp;

  /**
   * @method firebaseApp Getter method for the Firebase Application object
   */
  get firebaseApp() {
    return this._firebaseApp;
  }

  /**
   * @method firebaseApp Setter method for the Firebase Application object
   * @param {FirebaseApp} value Value to use in setting the Firebase Application object
   */
  set firebaseApp(value: FirebaseApp) {
    this._firebaseApp = value;
  }
  /*-----------------------------------------------*/

}