import { IFirestoreNoteRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreNoteRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { INote, INoteAsJson } from "../../../../dataObjects/models/digitalMedia/Note";


export function deleteNote(note: INote): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Note repository from the repository factory
      const firestoreNoteRepository: IFirestoreNoteRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Note) as
        IFirestoreBaseRepository<INote, INoteAsJson>;

      // call the repository delete() method to delete the object
      await firestoreNoteRepository.delete(note.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}