import { take, put } from 'redux-saga/effects';
import { saveTopicItem } from '../../../firebaseServices/dataServices/dataServiceActions/topicItemActions';
import { topicItemSaveRequest, topicItemSaveStatusChange } from '../../slices/topicItem/topicItemSaveStatusSlice';
import { topicItemSaveFailureChange } from '../../slices/topicItem/topicItemSaveFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
// import { saveTopicItem } from '@tkent_dna/mytennisbrain-firebaseservices';

/**
 * @function saveTopicItemRequestedSaga A saga workflow pertaining to a user having requested to save a topicItem.
 */
export function* saveTopicItemRequestedSaga() {
  // console.info('(saveTopicItemRequestedSaga) entered saveTopicItemRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the topicItemSaveRequest action to be dispatched
      const topicItemSaveRequestData = yield take(topicItemSaveRequest);
      const { payload: topicItem } = topicItemSaveRequestData;

      // make a request to save the topicItem
      yield saveTopicItem(topicItem)

      // dispatch an action to indicate that the save operation was successful
      yield put(topicItemSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(topicItemSaveStatusChange(enumWorkflowState.Failure));
      yield put(topicItemSaveFailureChange(error));
    }
  }
}
