import { enumDocumentLinkType } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumDocumentLinkTypeConvert Provides to/from string conversions for enumDocumentLinkType values.
 */
export class enumDocumentLinkTypeConvert {
  public static toString(documentLinkTypeEnum: enumDocumentLinkType): string {
    return documentLinkTypeEnum.toString();
  }

  public static fromString(documentLinkTypeString: string): enumDocumentLinkType {
    let documentLinkTypeEnum: enumDocumentLinkType = enumDocumentLinkType.GoogleCloudStorage;

    switch (documentLinkTypeString) {
      case enumDocumentLinkTypeConvert.toString(enumDocumentLinkType.GoogleCloudStorage):
        documentLinkTypeEnum = enumDocumentLinkType.GoogleCloudStorage;
        break;

      case enumDocumentLinkTypeConvert.toString(enumDocumentLinkType.ExternalSource):
        documentLinkTypeEnum = enumDocumentLinkType.ExternalSource;
        break;

      default:
        throw new MdbError(`enumDocumentLinkTypeConvert.fromString. Unrecognized/unsupported enumDocumentLinkType string: ${documentLinkTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return documentLinkTypeEnum;
  }
}