import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IThemeSpecs } from '../../../dataObjects/models/themes/ThemeSpecs';

// export interface ISaveThemeSpecsStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const themeSpecsSaveStatusSlice = createSlice({
  name: 'themeSpecsSaveStatus',
  initialState,
  reducers: {
    // The themeSpecsSaveRequest triggers the saving of a ThemeSpecs. It's not really associated with the Store State
    // for the 'saveThemeSpecsStatus' store variable; however, it is used to both trigger the ThemeSpecs Save Request -AND-
    // to call the themeSpecsSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveThemeSpecsData') and create another slice with a themeSpecsSaveRequest action. 
    themeSpecsSaveRequest: (state, action: PayloadAction<IThemeSpecs>) => {
      themeSpecsSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IThemeSpecs object or null
    themeSpecsSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { themeSpecsSaveRequest, themeSpecsSaveStatusChange } = themeSpecsSaveStatusSlice.actions;

// export the core reducer for the slice
export const themeSpecsSaveStatusReducer = themeSpecsSaveStatusSlice.reducer;

export default themeSpecsSaveStatusSlice.reducer;