import { enumSharingPermission } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumSharingPermissionConvert Provides to/from string conversions for enumSharingPermission values.
 */
export class enumSharingPermissionConvert {
  public static toString(sharingPermissionEnum: enumSharingPermission): string {
    return sharingPermissionEnum.toString();
  }

  public static fromString(sharingPermissionString: string): enumSharingPermission {
    let sharingPermissionEnum: enumSharingPermission = enumSharingPermission.None;

    switch (sharingPermissionString) {
      case enumSharingPermissionConvert.toString(enumSharingPermission.None):
        sharingPermissionEnum = enumSharingPermission.None;
        break;

      case enumSharingPermissionConvert.toString(enumSharingPermission.Admin):
        sharingPermissionEnum = enumSharingPermission.Admin;
        break;

      case enumSharingPermissionConvert.toString(enumSharingPermission.EditContent):
        sharingPermissionEnum = enumSharingPermission.EditContent;
        break;

      case enumSharingPermissionConvert.toString(enumSharingPermission.ViewContent):
        sharingPermissionEnum = enumSharingPermission.ViewContent;
        break;

      default:
        throw new MdbError(`enumSharingPermissionConvert.fromString. Unrecognized/unsupported enumSharingPermission string: ${sharingPermissionString}`, enumMdbErrorType.InvalidOperation);
    }

    return sharingPermissionEnum;
  }
}