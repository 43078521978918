import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumWorkflowState } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { PageAndViewTitleStringAssets, MessagesStringAssets } from '../../../../assets/stringAssets';
import { composeMessageUsingStringAsset } from '../../../messages';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { AlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../../enums';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import UserSettingsForm from '../../../forms/UserSettingsForm/UserSettingsForm';
import { useReduxWorkflowState } from '../../../customHooks';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { IMdbError, MdbError } from '../../../../errorObjects/MdbError';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { userSaveRequest, userSaveStatusChange } from '../../../../uiMiddleware-redux/slices/user/userSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';


/**
 * @interface IManageUserSettingsPageProps Input properties for the ManageUserSettingsPage
 */
export interface IManageUserSettingsPageProps extends PropsWithChildren<unknown> {
}

const ManageUserSettingsPage: React.FC<IManageUserSettingsPageProps> = (props: IManageUserSettingsPageProps) => {

  ManageUserSettingsPage.displayName = "Manage User Settings Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  // whether a save operation is currently in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  // // currentUser, from state.firebase.profile
  // const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

  // the status of saving operation
  const [saveStatus, setSaveStatus] = useState<enumWorkflowState | null | undefined>(null);

  // the failure error from save operation
  const [saveFailureError, setSaveFailureError] = useState<IMdbError | null | undefined>(null);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  const resetSaveUserStatus: () => void = useCallback(() => {
    dispatch(userSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear the saveUserStatus in Redux state to start with a clean slate
    resetSaveUserStatus();

    // reset (clear) AlertInfo from Redux state to start with a clean slate
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveUserStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveUserStatus]);

  // // anytime the auth and/or currentUserAsJson objects change in state, we'll set the current user
  // useEffect(() => {
  //   // set whether there is a current user
  //   if (currentUserAsJson && currentUserAsJson.userSettings) {
  //     setCurrentUser(JsonConverter.fromJSON(User, currentUserAsJson, true));
  //   }
  // }, [auth, currentUserAsJson]);


  // use a custom hook to evaluate the redux state for the saveUser workflow and any saveUser error
  const { workflowState: saveUserStatus, errorState: saveUserFailure } = useReduxWorkflowState((state: IStoreState) => state.saveUserStatus, (state: IStoreState) => state.saveUserFailure);

  // if the save status in Redux state is different from our local state, set our local state
  if (saveStatus !== saveUserStatus) {
    setSaveStatus(saveUserStatus);
  }

  // if the save failure error in Redux state is different from our local state, set our local state
  if (saveUserStatus === null && saveFailureError !== null) {
    setSaveFailureError(null);
  } else if (saveFailureError !== saveUserFailure) {
    setSaveFailureError(saveUserFailure);
  }

  // function (memoized with the useCallback hook) to be called when processing a change to the save operation status
  const processSuccessSaveStatus = useCallback(
    () => {
      // set the saveInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (saveInProgress) {
        setSaveInProgress(false);
      }

      // notify user via a Beacon notification that the user has been saved
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveUserSettings, MessagesStringAssets.user_SaveSuccess)));

      // dispatch an action to reset the saveUserStatus
      dispatch(userSaveStatusChange(null));

      // navigate back to page that led to this page
      navigate(-1);
    },
    [saveInProgress, dispatch, navigate],
  )

  const processInProgressSaveStatus = useCallback(
    () => {
      // set the saveInProgress state to 'true' if it's not already in that state (don't want an extra render if not necessary)
      if (!saveInProgress) {
        setSaveInProgress(true);
      }
    },
    [saveInProgress],
  )

  const processFailureSaveStatus = useCallback(
    () => {
      const saveFailureMessage: string = composeMessageUsingStringAsset(MessagesStringAssets.user_SaveFailure, saveFailureError ? saveFailureError.message : '', MessagesStringAssets.substitutionKeyword);
      dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Error, saveFailureMessage)));

      // set the saveInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (saveInProgress) {
        setSaveInProgress(false);
      }
    },
    [dispatch, saveInProgress, saveFailureError],
  )

  const processPerSaveStatus = useCallback(
    () => {
      if (saveStatus) {
        switch (saveStatus) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            processInProgressSaveStatus();
            break;

          case enumWorkflowState.Success:
            processSuccessSaveStatus();
            break;

          case enumWorkflowState.Failure:
            processFailureSaveStatus();
            break;
        }
      }
    },
    [saveStatus, processInProgressSaveStatus, processSuccessSaveStatus, processFailureSaveStatus],
  )

  // whenever the 'saveStatus' value changes, perform processing per that value
  useEffect(() => {
    processPerSaveStatus();
  }, [saveStatus, processPerSaveStatus]);


  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(userSettings: IUserSettings): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        if (currentUser) {
          // attach the given UserSettings to the currentUser object
          currentUser.userSettings = userSettings;

          // dispatch an action to request saving the user
          dispatch(userSaveRequest(currentUser));

          resolve();
        } else {
          throw new MdbError('Unable to save user settings. There is no current user.', enumMdbErrorType.CurrentUserRequired)
        }
      } catch (error: any) {
        reject(error);
      }
    });
  }

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={PageAndViewTitleStringAssets.pageTitle_UserSettings}
      onCloseAlert={resetSaveUserStatus}
    >
      {currentUser && currentUser.userSettings &&
        <UserSettingsForm
          userSettings={currentUser.userSettings}
          saveRequestInProgress={saveInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  )

}

export default ManageUserSettingsPage;