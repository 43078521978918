import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchImageLinksStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchImageLinksStatusSlice = createSlice({
  name: 'fetchImageLinksStatus',
  initialState,
  reducers: {
    setFetchImageLinksStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchImageLinksStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchImageLinksStatus, clearFetchImageLinksStatus } = fetchImageLinksStatusSlice.actions;

// export the core reducer for the slice
export const fetchImageLinksStatusReducer = fetchImageLinksStatusSlice.reducer;

export default fetchImageLinksStatusSlice.reducer;