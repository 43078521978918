import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreDbContext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDbContext";
import { IFirestoreVideoLinkRepository, FirestoreVideoLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreVideoLinkRepository";


export function getVideoLinksForParentId_onSnapshot(parentId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // instantiate a FirestoreDbContext
      const dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);

      const firestoreVideoLinkRepository: IFirestoreVideoLinkRepository = new FirestoreVideoLinkRepository(dbContext);

      // fetch the topics for the pertinent channelId
      const unsubscribeCallback: () => void = await firestoreVideoLinkRepository.getAllForParent_onSnapshot(parentId, callback);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}