import { take } from "redux-saga/effects";
import { saveImageLinkOnlyWorkerSaga } from "./saveImageLinkOnlyWorkerSaga";
import { imageLinkSaveWithoutFileUploadRequest } from "../../slices/imageLink/imageLinkSaveStatusSlice";

/**
 * @function saveImageLinkOnlyWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an ImageLink only (without a file upload).
 */
export function* saveImageLinkOnlyWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the imageLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveImageLinkRequestData = yield take(imageLinkSaveWithoutFileUploadRequest);
    const { payload: saveImageLinkRequest } = saveImageLinkRequestData;

    yield saveImageLinkOnlyWorkerSaga(saveImageLinkRequest);
  }
}