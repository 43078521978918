/**
 * @enum {enumWorkflowState} Enumerated values for the status of a workflow.
 * Example use cases: User Registration, Login, Logout, Saving something
 */
export enum enumWorkflowState {
  Requested = 'Requested',
  InProgress = 'InProgress',
  Success = 'Success',
  Failure = 'Failure'
}

