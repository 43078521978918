import { take } from "redux-saga/effects";
import { saveHyperLinkWorkerSaga } from "./saveHyperLinkWorkerSaga";
import { hyperLinkSaveRequest } from "../../slices/hyperLink/hyperLinkSaveStatusSlice";

/**
 * @function saveHyperLinkWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save a HyperLink.
 */
export function* saveHyperLinkWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the hyperLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveHyperLinkRequestData = yield take(hyperLinkSaveRequest);
    const { payload: saveHyperLinkRequest } = saveHyperLinkRequestData;

    yield saveHyperLinkWorkerSaga(saveHyperLinkRequest);
  }
}