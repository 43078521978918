import React, { PropsWithChildren, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { IVideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { enumDigitalMediaDisplayEnvironment, enumVideoLinkType } from '../../../dataObjects/enums';
import { convertGoogleDriveFileUrlForFileLink, urlIsAGoogleDriveLink } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/googleDriveUtilities';
import { MdbError } from '../../../errorObjects/MdbError';
import ReactPlayer from 'react-player';
import { DigitalMediaNotAvailableDisplay } from '../DigitalMediaNotAvailableDisplay/DigitalMediaNotAvailableDisplay';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { urlIsADailyMotionAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/dailyMotionUtilities';
import { urlIsAKalturaAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/kalturaUtilities';
import { urlIsAStreamableAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/streamableUtilities';
import { urlIsATwitchAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/twitchUtilities';
import { urlIsAVidyardAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/vidyardUtilities';
import { urlIsAVimeoAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/vimeoUtilities';
import { urlIsAYouTubeAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/youtubeUtilities';
import { urlIsARumbleAddress } from '../../utilities/digitalMediaUtilities/externalSourceUtilities/rumbleUtilities';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>) that will serve as a base container in a Card View
const StyledContainerForCardView = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a Form View
const StyledContainerForFormView = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '250px',
  width: 'auto',
  minWidth: '250px'
}));

// a styled Box (equivalent to a <div>) that will serve as a base container in a List View
const StyledContainerForListView = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  margin: theme.spacing(0, 0, 2, 0),
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: '1px',
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  flexGrow: 1,
  position: 'relative',
  height: '100%',
  width: '100%',

  [theme.breakpoints.down('xs')]: {
    maxWidth: '300px',
  },
  [theme.breakpoints.up('sm')]: {
    maxWidth: '350px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '500px',
  },
}));


// a styled Box (equivalent to a <div>), providing a background for a not available message
const VideoNotAvailableBox = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  background: `linear-gradient(135deg, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light}, #BBB, #EEE, ${theme.palette.primary.light})`,
  display: 'flex',
  justifyContent: 'center',
}));

/**
 * @interface IVideoMediaDisplayProps declares any input properties required for this component.
 */
export interface IVideoMediaDisplayProps extends PropsWithChildren<unknown> {
  videoLink: IVideoLink;
  displayEnvironment: enumDigitalMediaDisplayEnvironment;
}

/**
 * @function VideoMediaDisplay is a React functional component for displaying a simple video (via an IVideoLink).
 * @param {} props are the properties passed into the component (currently no input properties).
 */
export const VideoMediaDisplay: React.FC<IVideoMediaDisplayProps> = (props: IVideoMediaDisplayProps) => {
  VideoMediaDisplay.displayName = 'Video Media Display';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  displayConsoleLogs && console.log(`VideoMediaDisplay. Entered VideoMediaDisplay`);

  const { videoLink, displayEnvironment } = props;

  // whether the video source is supported
  const [supportedVideoSource, setSupportedVideoSource] = useState<boolean>(false);

  // whether the video should be displayed in an IFrame
  const [useIFrame, setUseIFrame] = useState<boolean>(false);

  // the height of the video display component
  const [componentHeight, setComponentHeight] = useState<string>('3rem');

  // the URL that will be set for the video display object
  const [videoUrl, setVideoUrl] = useState<string>('');

  const videoNotAvailableWidth: string = `100%`;
  const videoNotAvailableHeight: string = `100%`;

  // useEffect to establish container and video display component parameters based on the videoLink and display environment
  useEffect(() => {
    // The variables that can change are the:
    //   - Whether the Url provided is from a supported video source
    //   - Whether the video should be displayed in an IFrame
    //   - Url of the video being displayed
    //   - Height of the container
    let localSupportedVideoSource: boolean = false;
    let localUseIFrame: boolean = false;
    let localVideoUrl: string = videoLink.downloadUrl;
    let localComponentHeight: string = '3rem'; // default height

    switch (videoLink.videoLinkType) {
      // if the currently selected Video Type is file upload (GoogleCloudStorage)...
      case enumVideoLinkType.GoogleCloudStorage:
        localSupportedVideoSource = true;
        localComponentHeight = '250px';
        break;

      // if the currently selected Video Type is from an external source...
      case enumVideoLinkType.ExternalSource:
        // if the Url is a known supported URL Address where no Url conversion is required: 
        // YouTube, DailyMotion, Streamable, Twitch, Vimeo, YouTube...
        if (urlIsADailyMotionAddress(videoLink.downloadUrl) ||
          urlIsAKalturaAddress(videoLink.downloadUrl) ||
          urlIsAStreamableAddress(videoLink.downloadUrl) ||
          urlIsATwitchAddress(videoLink.downloadUrl) ||
          urlIsAVidyardAddress(videoLink.downloadUrl) ||
          urlIsAVimeoAddress(videoLink.downloadUrl) ||
          urlIsAYouTubeAddress(videoLink.downloadUrl)) {
          localSupportedVideoSource = true;
          localComponentHeight = '250px';
        } else
          if (urlIsARumbleAddress(videoLink.downloadUrl)) {
            localSupportedVideoSource = true;
            localUseIFrame = true;
            localComponentHeight = '250px';
          } else
            // if the Url is a Google Drive address...
            if (urlIsAGoogleDriveLink(videoLink.downloadUrl)) {
              // not a supported source at this time, so no change to the supported source flag (should be 'false')

              // we'll convert the Url anyway, in case we support in the future
              localVideoUrl = convertGoogleDriveFileUrlForFileLink(videoLink.downloadUrl);

              localComponentHeight = '3rem';
            }

        break;

      default:
        throw new MdbError(`Unhandled video type (${videoLink.videoLinkType}) in VideoMediaDisplay`);
    }

    displayConsoleLogs && console.log(`VideoMediaDisplay. useEffect for setting component specs. componentHeight to be set to: ${localComponentHeight}`);

    setSupportedVideoSource(localSupportedVideoSource);
    setUseIFrame(localUseIFrame);
    setVideoUrl(localVideoUrl);
    setComponentHeight(localComponentHeight);

  }, [videoLink.videoLinkType, videoLink.downloadUrl]);

  /**
   * @function renderVideoElement Renders the JSX element of the video object to be displayed on the UI.
   */
  function renderVideoElement(): JSX.Element {

    // default the video element to displaying no video
    let videoElement: JSX.Element =
      <>
        <VideoNotAvailableBox
          sx={{ width: videoNotAvailableWidth, height: videoNotAvailableHeight }}
        />
      </>;

    // if there's an established Url for the object, perform logic
    if (videoUrl) {

      // if (supportedSource) {
      if (supportedVideoSource) {
        if (useIFrame) {
          videoElement =
            <>
              <iframe
                src={videoUrl}
                title={videoLink.description}
                width='100%'
                height='100%'
              />
            </>
        } else {
          videoElement =
            <>
              <ReactPlayer
                url={videoUrl}
                crossOrigin="anonymous"
                playsinline={true}
                controls={true}
                position='relative'
                objectFit={true}
                width='100%'
                height='100%'
              />
            </>
        }
      } else {
        videoElement =
          <>
            <DigitalMediaNotAvailableDisplay displayText={MessagesStringAssets.videoSourceOrFormatNotSupported} />
          </>
      }
    }

    return videoElement;
  }

  /**
   * @function renderVideoContainerAndVideoDisplay Renders the JSX element of the video element container and video element to be displayed on the UI.
   */
  function renderVideoContainerAndVideoDisplay(): JSX.Element {

    let videoContainerAndVideoDisplay: JSX.Element =
      <>
      </>

    displayConsoleLogs && console.log(`VideoMediaDisplay.renderVideoContainerAndVideoDisplay(). displayEnvironment: ${displayEnvironment}; componentHeight: ${componentHeight}`);

    switch (displayEnvironment) {
      case enumDigitalMediaDisplayEnvironment.CardView:
        videoContainerAndVideoDisplay =
          <StyledContainerForCardView sx={{ height: componentHeight }} >
            {renderVideoElement()}
          </StyledContainerForCardView>
        break;

      case enumDigitalMediaDisplayEnvironment.FormView:
        videoContainerAndVideoDisplay =
          <StyledContainerForFormView sx={{ height: componentHeight }} >
            {renderVideoElement()}
          </StyledContainerForFormView>
        break;

      case enumDigitalMediaDisplayEnvironment.ListView:
        videoContainerAndVideoDisplay =
          <StyledContainerForListView sx={{ height: componentHeight }} >
            {renderVideoElement()}
          </StyledContainerForListView>
        break;

      default:
        throw new MdbError(`Unhandled display environoment (${displayEnvironment}) in VideoMediaDisplay`);
    }

    return videoContainerAndVideoDisplay;
  }

  return (
    <>
      {renderVideoContainerAndVideoDisplay()}
    </>
  )
}
