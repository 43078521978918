import { createSlice } from '@reduxjs/toolkit';
import { ITopic } from '../../../dataObjects/models/topics/Topic';

// export interface ICurrentTopicState {
//   value: ITopic | null,
// }

const initialState: ITopic | null = null;

export const currentTopicSlice = createSlice({
  name: 'currentTopic',
  initialState,
  reducers: {
    setCurrentTopic(state, action) {
      return action.payload;
    // setCurrentTopic: (state: ITopic | null, action: PayloadAction<ITopic>) => {
    //   state = action.payload;
    },
    clearCurrentTopic(state) {
      return null;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCurrentTopic, clearCurrentTopic } = currentTopicSlice.actions;

// export the core reducer for the slice
export const currentTopicReducer = currentTopicSlice.reducer;

export default currentTopicSlice.reducer;
