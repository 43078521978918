
export enum enumTextInputType {
  Text = 'text',
  File = 'file',
  TextArea = 'textarea',
  DateTimeLocal = 'datetime-local',
  Email = 'email',
  Telephone = 'tel',
  Password = 'password',

}
