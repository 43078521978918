import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDigitalMediaSearchResults } from '../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchResults';

const initialState: IDigitalMediaSearchResults | null = null;

export const digitalMediaSearchResultsSlice = createSlice({
  name: 'digitalMediaSearchResults',
  initialState,
  reducers: {
    // action has a payload with IDigitalMediaSearchResults 
    digitalMediaSetSearchResults: (state: IDigitalMediaSearchResults | null, action) => {
      return action.payload;
    },
    digitalMediaClearSearchResults: (state: IDigitalMediaSearchResults | null) => {
      return null;
    },
  },
})

// Action creators are generated for each case reducer function
export const { digitalMediaSetSearchResults, digitalMediaClearSearchResults } = digitalMediaSearchResultsSlice.actions;

// export the core reducer for the slice
export const digitalMediaSearchResultsReducer = digitalMediaSearchResultsSlice.reducer;

export default digitalMediaSearchResultsSlice.reducer;
