import { take, put } from 'redux-saga/effects';
import { saveCategory } from '../../../firebaseServices/dataServices/dataServiceActions/categoryActions';
import { categorySaveRequest, categorySaveStatusChange } from '../../slices/category/categorySaveStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { categorySaveFailureChange } from '../../slices/category/categorySaveFailureSlice';

/**
 * @function saveCategoryRequestedSaga A saga workflow pertaining to a user having requested to save a topic.
 */
export function* saveCategoryRequestedSaga() {
  // console.info('(saveCategoryRequestedSaga) entered saveCategoryRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the categorySaveRequest action to be dispatched and get the payload data...
      const categorySaveRequestData = yield take(categorySaveRequest);
      const { payload: category } = categorySaveRequestData;

      // make a request to save the Category
      yield saveCategory(category);

      // dispatch an action to indicate that the save operation was successful
      yield put(categorySaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(categorySaveStatusChange(enumWorkflowState.Failure));
      yield put(categorySaveFailureChange(error));
    }
  }
}
