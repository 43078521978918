// import dotenv from 'dotenv';
import { IFirebaseConfiguration, IFirebaseConfigurationAsJson } from './IFirebaseConfiguration';
import { FirebaseConfigurationVariables } from '../FirebaseConfigurationVariables';

// dotenv.config();

/** 
 * @class FirebaseConfiguration Represents Firebase Configuration information (required to use Firebase services).
 * The key purpose of this class is to provide all of the Firebase configuration information in a single class, and a class
 * that can render itself to a JSON object on demand.
 * (NOTE: The default value of each property will be set from environment variables, but the user of the class can choose to override
 * any of them. For those constructor parameters (configuration variables) that should not be changed, merely set the constructor parameter
 * to 'undefined' (without the quote marks).)
 */
export class FirebaseConfiguration implements IFirebaseConfiguration {
  /**
   * @method Constructor method
   * @param {string} apiKey (Optional) The Google Firebase API Key
   * @param {string} authDomain (Optional) Authorized domain within Firebase
   * @param {string} databaseStoragePath (Optional) A URL that points to the Firestore Database instance
   * @param {string} projectId (Optional) Google Firebase Project Id
   * @param {string} storageBucket (Optional) A URL pointing to a Google Firebase Storage Bucket for the project
   * @param {string} messagingSenderId (Optional) Id for sending messages from Google Firebase
   * @param {string} appId (Optional) Id for the Google Firebase application
   * @param {string} measurementId (Optional) An assigned Id required to use Google (Firebase) Analytics
   * @param {boolean} enableOfflineSupport (Optional) hether to enable offline support (authentication & persistence), as required by Progressive Web Apps
   */
  constructor(
    apiKey?: string,
    authDomain?: string,
    databaseStoragePath?: string,
    projectId?: string,
    storageBucket?: string,
    messagingSenderId?: string,
    appId?: string,
    measurementId?: string,
    enableOfflineSupport?: boolean
  ) {
    if (apiKey !== undefined) {
      this._apiKey = apiKey;
    }

    if (authDomain !== undefined) {
      this._authDomain = authDomain;
    }

    if (databaseStoragePath !== undefined) {
      this._databaseStoragePath = databaseStoragePath;
    }

    if (projectId !== undefined) {
      this._projectId = projectId;
    }

    if (storageBucket !== undefined) {
      this._storageBucket = storageBucket;
    }

    if (messagingSenderId !== undefined) {
      this._messagingSenderId = messagingSenderId;
    }

    if (appId !== undefined) {
      this._appId = appId;
    }

    if (measurementId !== undefined) {
      this._measurementId = measurementId;
    }

    if (enableOfflineSupport !== undefined) {
      this._enableOfflineSupport = enableOfflineSupport;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _apiKey The Google Firebase API Key
   */
  private _apiKey: string = FirebaseConfigurationVariables.apiKey;

  /**
   * @method apiKey Getter method for the Google Firebase API Key
   */
  get apiKey() {
    return this._apiKey;
  }

  /**
   * @method apiKey Setter method for the Google Firebase API Key
   * @param {string} value The Google Firebase API Key string
   */
  set apiKey(value: string) {
    this._apiKey = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} authDomain Authorized domain within Firebase
   */
  private _authDomain: string = FirebaseConfigurationVariables.authDomain;

  /**
   * @method authDomain Getter method for the Authorized domain within Firebase
   */
  get authDomain() {
    return this._authDomain;
  }

  /**
   * @method authDomain Setter method for the Authorized domain within Firebase
   * @param {string} value Value for the Authorized domain within Firebase
   */
  set authDomain(value: string) {
    this._authDomain = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} databaseStoragePath A URL that points to the Firestore Database instance
   */
  private _databaseStoragePath: string = FirebaseConfigurationVariables.databaseStoragePath;

  /**
   * @method databaseStoragePath Getter method for a URL that points to the Firestore Database instance
   */
  get databaseStoragePath() {
    return this._databaseStoragePath;
  }

  /**
   * @method databaseStoragePath Setter method for a URL that points to the Firestore Database instance
   * @param {string} value A URL that points to the Firestore Database instance
   */
  set databaseStoragePath(value: string) {
    this._databaseStoragePath = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} projectId Google Firebase Project Id
   */
  private _projectId: string = FirebaseConfigurationVariables.projectId;

  /**
   * @method projectId Getter method for the Google Firebase Project Id
   */
  get projectId() {
    return this._projectId;
  }

  /**
   * @method projectId Setter method for the Google Firebase Project Id
   * @param {string} value The Google Firebase Project Id string
   */
  set projectId(value: string) {
    this._projectId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} storageBucket A URL pointing to a Google Firebase Storage Bucket for the project
   */
  private _storageBucket: string = FirebaseConfigurationVariables.storageBucket;

  /**
   * @method storageBucket Getter method for a URL pointing to a Google Firebase Storage Bucket for the project
   */
  get storageBucket() {
    return this._storageBucket;
  }

  /**
   * @method storageBucket Setter method for a URL pointing to a Google Firebase Storage Bucket for the project
   * @param {string} value A URL pointing to a Google Firebase Storage Bucket for the project
   */
  set storageBucket(value: string) {
    this._storageBucket = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} messagingSenderId Id for sending messages from Google Firebase
   */
  private _messagingSenderId: string = FirebaseConfigurationVariables.messagingSenderId;

  /**
   * @method messagingSenderId Getter method for the Id for sending messages from Google Firebase
   */
  get messagingSenderId() {
    return this._messagingSenderId;
  }

  /**
   * @method messagingSenderId Setter method for the Id for sending messages from Google Firebase
   * @param {string} value Id for sending messages from Google Firebase
   */
  set messagingSenderId(value: string) {
    this._messagingSenderId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} appId Id for the Google Firebase application
   */
  private _appId: string = FirebaseConfigurationVariables.appId;

  /**
   * @method appId Getter method for the Id for the Google Firebase application
   */
  get appId() {
    return this._appId;
  }

  /**
   * @method appId Setter method for the Id for the Google Firebase application
   * @param {string} value Id for the Google Firebase application
   */
  set appId(value: string) {
    this._appId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} measurementId An assigned Id required to use Google (Firebase) Analytics
   */
  private _measurementId: string = FirebaseConfigurationVariables.measurementId;

  /**
   * @method measurementId Getter method for an assigned Id required to use Google (Firebase) Analytics
   */
  get measurementId() {
    return this._measurementId;
  }

  /**
   * @method measurementId Setter method for an assigned Id required to use Google (Firebase) Analytics
   * @param {string} value An assigned Id required to use Google (Firebase) Analytics
   */
  set measurementId(value: string) {
    this._measurementId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} persistence Whether to enable offline support
   */
  private _enableOfflineSupport: boolean = true; // default to true to support Progressive Web App configuration by default

  /**
   * @method enableOfflineSupport Getter method for whether to enable offline support
   */
  get enableOfflineSupport() {
    return this._enableOfflineSupport;
  }

  /**
   * @method enableOfflineSupport Setter method for whether to enable offline support
   * @param {boolean} value The value to assign for whether to enable offline support
   */
  set enableOfflineSupport(value: boolean) {
    this._enableOfflineSupport = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the class instance to a JSON representation
   */
  toJSON(): IFirebaseConfigurationAsJson {
    const jsonObject: IFirebaseConfigurationAsJson = {
      apiKey: this.apiKey,
      authDomain: this.authDomain,
      databaseStoragePath: this.databaseStoragePath,
      projectId: this.projectId,
      storageBucket: this.storageBucket,
      messagingSenderId: this.messagingSenderId,
      appId: this.appId,
      measurementId: this.measurementId,
      enableOfflineSupport: this.enableOfflineSupport
    };

    return jsonObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a class instance from a JSON representation
   */
  static fromJSON(jsonObject: IFirebaseConfigurationAsJson): IFirebaseConfiguration {
    // create a new instance of this class
    let firebaseConfigObject: FirebaseConfiguration = Object.create(FirebaseConfiguration.prototype);

    // assign all properties from the JSON object to the data members
    firebaseConfigObject.apiKey = jsonObject.apiKey;
    firebaseConfigObject.authDomain = jsonObject.authDomain;
    firebaseConfigObject.databaseStoragePath = jsonObject.databaseStoragePath;
    firebaseConfigObject.projectId = jsonObject.projectId;
    firebaseConfigObject.storageBucket = jsonObject.storageBucket;
    firebaseConfigObject.messagingSenderId = jsonObject.messagingSenderId;
    firebaseConfigObject.appId = jsonObject.appId;
    firebaseConfigObject.measurementId = jsonObject.measurementId;
    firebaseConfigObject.enableOfflineSupport = jsonObject.enableOfflineSupport;

    return firebaseConfigObject;
  }
  /*-----------------------------------------------*/
}
