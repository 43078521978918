import { take, put } from 'redux-saga/effects';
import { deleteTopicItem } from '../../../firebaseServices/dataServices/dataServiceActions/topicItemActions';
import { topicItemDeleteRequest, topicItemDeleteStatusChange } from '../../slices/topicItem/topicItemDeleteStatusSlice';
import { topicItemDeleteFailureChange } from '../../slices/topicItem/topicItemDeleteFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteTopicItemRequestedSaga A saga workflow pertaining to a user having requested to delete a topicItem.
 */
export function* deleteTopicItemRequestedSaga() {
  // console.info('(deleteTopicItemRequestedSaga) entered deleteTopicItemRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the topicItemDeleteRequest action to be dispatched
      const topicItemDeleteRequestData = yield take(topicItemDeleteRequest);
      const { payload: topicItem } = topicItemDeleteRequestData;

      // make a request to delete the topicItem
      yield deleteTopicItem(topicItem)

      // dispatch an action to indicate that the delete operation was successful
      yield put(topicItemDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(topicItemDeleteStatusChange(enumWorkflowState.Failure));
      yield put(topicItemDeleteFailureChange(error));
    }
  }
}
