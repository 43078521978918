import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { userUpdateEmailAddressStatusChange } from './userUpdateEmailAddressStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUserAndEmailAddress } from '../../../dataObjects/models/users/UserAndEmailAddress';

const initialState: IUserAndEmailAddress | null = null;

export const userUpdateEmailAddressDataSlice = createSlice({
  name: 'userUpdateEmailAddressData',
  initialState,
  reducers: {
    // action has a payload with IUserAndEmailAddress
    userUpdateEmailAddressRequest(state, action) {
      // set userUpdateEmailAddress data into state for use during the userUpdateEmailAddress process
      return action.payload;
    },

    userUpdateEmailAddressDataClear(state) {
      return null; // action.payload;
    },
  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(userUpdateEmailAddressStatusChange, (state, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            // do nothing
            break;

          case enumWorkflowState.Failure:
          case enumWorkflowState.Success:
            // clear the userUpdateEmailAddress data from state
            state = null;
            break;
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { userUpdateEmailAddressRequest, userUpdateEmailAddressDataClear } = userUpdateEmailAddressDataSlice.actions;

// export the core reducer for the slice
export const userUpdateEmailAddressDataReducer = userUpdateEmailAddressDataSlice.reducer;

export default userUpdateEmailAddressDataSlice.reducer;
