import { constants } from "buffer";
import { register } from "../../../serviceWorker";
import { IRgbColor } from "./IRgbColor";
import { enumMdbErrorType } from "../../../errorObjects/enums";
import { MdbError } from "../../../errorObjects/MdbError";

/**
 * @function convertHexColorStringToIRgb Converts a Hex color string, along with opacity, to an RGB structure
 * @param hexColorString A hex color string
 * @param alpha The desired resulting alpha (opacity) component
 */
export const convertHexColorStringToIRgb: (hexColorString: string, alpha?: number) => IRgbColor = (hexColorString: string, alpha?: number) => {

    hexColorString = hexColorString.replace(/#/g, '');
    if (hexColorString.length === 3) {
      hexColorString = hexColorString.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
    // validate hex format
    const result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hexColorString);
    if (result) {
      const red: number = parseInt(result[1], 16);
      const green: number = parseInt(result[2], 16);
      const blue: number = parseInt(result[3], 16);
      // alpha must be a positive number between 0 & 1 (decimal value less than 1 is valid). If it's not, set it to full opacity (a value of 1).
      const alphaComponent: number = ((alpha === undefined) || (alpha < 0) || (alpha > 1)) ? 1 : alpha;

      const rgba: IRgbColor = {
        red: red,
        green: green,
        blue: blue,
        alpha: alphaComponent
      }

      return rgba;
    } else {
      // invalid color
      throw new MdbError(`The hex color value of ${hexColorString} is an invalid color`, enumMdbErrorType.BadData);
    }

}
