import { IDigitalMediaSearchCriteria, IDigitalMediaSearchCriteriaAsJson } from ".";
import { typeUniqueId } from "../../../types";

/** 
 * @class DigitalMediaSearchCriteria Search criteria that can be used to search for digital media objects
 */
export class DigitalMediaSearchCriteria implements IDigitalMediaSearchCriteria {
  /**
   * @method Constructor method
   * @param {string} searchPhrase Search phrase to be used in the search
   * @param {boolean} searchAllAccessibleChannels Whether search is to be performed across all accessible channels
   * @param {boolean} includeAllDigitalMediaTypes Whether to include all Digital Media Types in the search
   * @param {Array<typeUniqueId>} channelIds A collection of Channel Ids to be used in the search
   * @param {Array<string>} digitalMediaTypes A collection of Digital Media Types to be used in the search
   */
  constructor(
    searchPhrase: string,
    searchAllAccessibleChannels: boolean,
    includeAllDigitalMediaTypes: boolean,
    channelIds?: Array<typeUniqueId>,
    digitalMediaTypes?: Array<string>,
  ) {
    this.searchPhrase = searchPhrase;
    this.searchAllAccessibleChannels = searchAllAccessibleChannels;
    this.includeAllDigitalMediaTypes = includeAllDigitalMediaTypes;
    this.channelIds = channelIds ?? [];
    this.digitalMediaTypes = digitalMediaTypes ?? [];
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _searchPhrase Search phrase to be used in the search
   */
  private _searchPhrase: string = '';

  /**
   * @method searchPhrase Getter method for _searchPhrase
   */
  get searchPhrase(): string {
    return this._searchPhrase;
  }

  /**
   * @method searchPhrase Setter method for _searchPhrase
   * @param {string} value is the input value for setting _searchPhrase
   */
  set searchPhrase(value: string) {
    this._searchPhrase = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} _searchAllAccessibleChannels Whether search is to be performed across all accessible channels
   */
  private _searchAllAccessibleChannels: boolean = true;

  /**
   * @method searchAllAccessibleChannels Getter method for _searchAllAccessibleChannels
   */
  get searchAllAccessibleChannels(): boolean {
    return this._searchAllAccessibleChannels;
  }

  /**
   * @method searchAllAccessibleChannels Setter method for _searchAllAccessibleChannels
   * @param {boolean} value is the input value for setting _searchAllAccessibleChannels
   */
  set searchAllAccessibleChannels(value: boolean) {
    this._searchAllAccessibleChannels = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} _includeAllDigitalMediaTypes Whether to include all Digital Media Types in the search
   */
  private _includeAllDigitalMediaTypes: boolean = true;

  /**
   * @method includeAllDigitalMediaTypes Getter method for _includeAllDigitalMediaTypes
   */
  get includeAllDigitalMediaTypes(): boolean {
    return this._includeAllDigitalMediaTypes;
  }

  /**
   * @method includeAllDigitalMediaTypes Setter method for _includeAllDigitalMediaTypes
   * @param {boolean} value is the input value for setting _includeAllDigitalMediaTypes
   */
  set includeAllDigitalMediaTypes(value: boolean) {
    this._includeAllDigitalMediaTypes = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<typeUniqueId>} _channelIds A collection of Channel Ids to be used in the search
   */
  private _channelIds: Array<typeUniqueId> = [];

  /**
   * @method channelIds Getter method for _channelIds
   */
  get channelIds(): Array<typeUniqueId> {
    return this._channelIds;
  }

  /**
   * @method channelIds Setter method for _channelIds
   * @param {Array<typeUniqueId>} value is the input value for setting _channelIds
   */
  set channelIds(value: Array<typeUniqueId>) {
    this._channelIds = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<string>} _digitalMediaTypes A collection of Digital Media Types to be used in the search
   */
  private _digitalMediaTypes: Array<string> = [];

  /**
   * @method digitalMediaTypes Getter method for _digitalMediaTypes
   */
  get digitalMediaTypes(): Array<string> {
    return this._digitalMediaTypes;
  }

  /**
   * @method digitalMediaTypes Setter method for _digitalMediaTypes
   * @param {Array<string>} value is the input value for setting _digitalMediaTypes
   */
  set digitalMediaTypes(value: Array<string>) {
    this._digitalMediaTypes = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IDigitalMediaSearchCriteria} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IDigitalMediaSearchCriteria {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IDigitalMediaSearchCriteria = Object.create(DigitalMediaSearchCriteria.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the class instance to a JSON representation
   */
  toJSON(): IDigitalMediaSearchCriteriaAsJson {
    const jsonObject: IDigitalMediaSearchCriteriaAsJson = {
      searchPhrase: this.searchPhrase,
      searchAllAccessibleChannels: this.searchAllAccessibleChannels,
      includeAllDigitalMediaTypes: this.includeAllDigitalMediaTypes,
      channelIds: this.channelIds,
      digitalMediaTypes: this.digitalMediaTypes,
    };

    return jsonObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a class instance from a JSON representation
   */
  static fromJSON(jsonObject: IDigitalMediaSearchCriteriaAsJson): IDigitalMediaSearchCriteria {
    // create a new instance of this class
    let digitalMediaSearchCriteria: IDigitalMediaSearchCriteria = Object.create(DigitalMediaSearchCriteria.prototype);

    // assign all properties from the JSON object to the data members
    digitalMediaSearchCriteria.searchPhrase = jsonObject.searchPhrase;
    digitalMediaSearchCriteria.searchAllAccessibleChannels = jsonObject.searchAllAccessibleChannels;
    digitalMediaSearchCriteria.includeAllDigitalMediaTypes = jsonObject.includeAllDigitalMediaTypes;
    digitalMediaSearchCriteria.channelIds = jsonObject.channelIds;
    digitalMediaSearchCriteria.digitalMediaTypes = jsonObject.digitalMediaTypes;

    return digitalMediaSearchCriteria;
  }
  /*-----------------------------------------------*/

}
