import { FirebaseApp, initializeApp } from 'firebase/app';
import { Auth, beforeAuthStateChanged, browserLocalPersistence, connectAuthEmulator, getAuth, onAuthStateChanged, setPersistence } from 'firebase/auth';
import { connectFirestoreEmulator, Firestore, getFirestore } from 'firebase/firestore';
import { IFirebaseConfiguration, FirebaseConfiguration } from '../FirebaseConfiguration';
import { FirebaseEmulatorSettings } from '../FirebaseConfigurationVariables';

/** 
 * @class FirebaseAppSingleton A singleton class that wraps a firebase.app.App
 */
export class FirebaseAppSingleton {
  /**
   * @method Constructor method
   * @param {firebase.app.App} firebaseApp A Firebase Application object that has been initialized, if provided
   */
  private constructor(
    firebaseConfig?: IFirebaseConfiguration
  ) {
    // if a FirebaseConfiguration has been provided, use it; otherwise, create a default FirebaseConfiguration to use
    const _firebaseConfig: IFirebaseConfiguration = (firebaseConfig !== undefined) ? firebaseConfig : new FirebaseConfiguration();

    this._firebaseApp = initializeApp(_firebaseConfig.toJSON(), 'MyTennisBrain (singleton)');

    // set persistence for the Firebase App's authentication to use browser local storage so that
    // the authentication will be retained over different browser sessions for the user
    (async () => {
      // enable persistence for Firebase Auth
      try {
        // Set the Firebase Auth persistence to be session-based, meaning that multiple users may be logged into the 
        // app on the same device (in different sessions). We do this by calling the firebase/auth 'setPersistence', 
        // passing it the Firebase Auth object and the 'browserSessionPersistence' Persistence object.
        // If we desired to have only a single user across browser sessions, the second parameter would be 'browserLocalPersistence'.
        // NOTE: It seems that the Firebase Auth behavior is such that logging a user out of one session causes all 
        // const auth: Auth = getAuth(this.firebaseApp);
        const auth: Auth = getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        // await setPersistence(auth, browserSessionPersistence);
        // await setPersistence(auth, browserLocalPersistence);

        await setPersistence(auth, browserLocalPersistence).then(() => {
          // set member flag indicating that the persistence setting has been completed
          this.persistenceSettingComplete = true
        });

        beforeAuthStateChanged(auth, (user) => {

        })

        onAuthStateChanged(auth, (user) => {
          // if (user) {
          //   // User is signed in, see docs for a list of available properties
          //   // https://firebase.google.com/docs/reference/js/firebase.User
          //   const uid = user.uid;
          //   // ...
          // } else {
          //   // User is signed out
          //   // ...
          // }
          this.fullyInitialized = true;
        });

      }
      catch (error) {
        // TODO: Log this error
        throw error;
      }
    })();

    // FirebaseAppSingleton._firestore = FirebaseAppSingleton.getFirestore();
    // FirebaseAppSingleton._firestore = this._firebaseApp.getFirestore();
    FirebaseAppSingleton._firestore = getFirestore(this._firebaseApp);
    // if the environment is configured to use emulators and the firestore emulator port is configured, connect to the emulator
    if (FirebaseEmulatorSettings.useEmulators) {
      if (FirebaseEmulatorSettings.firestoreEmulatorPort) {
        connectFirestoreEmulator(FirebaseAppSingleton._firestore, `localhost`, parseInt(FirebaseEmulatorSettings.firestoreEmulatorPort));
      }
    }


    // if the configuration calls for offline support...
    // NOTE: There seem to be problems with Firestore offline support at this time, so we won't attempt to set up for offline support
    if (_firebaseConfig.enableOfflineSupport) {
      (async () => {
        // enable offline support of Firestore data
        try {
          // await enableIndexedDbPersistence(FirebaseAppSingleton._firestore);

          // enable offline support of Firestore data that supports the app running multiple tabs within a single browser session
          // await enableMultiTabIndexedDbPersistence(FirebaseAppSingleton._firestore);
        }
        catch (error) {
          // TODO: Log this error
          throw error;
        }

        // // enable persistence for Firebase Auth
        // const auth: Auth = await getAuth(this.firebaseApp);
        // await setPersistence(auth, browserLocalPersistence).catch(error => {
        //   // TODO: Log this error
        //   throw error;
        // });

      })();

      // enable peristence for Firestore
      // const settings: firebase.firestore.PersistenceSettings = { synchronizeTabs: true };
      // const settings: PersistenceSettings = { synchronizeTabs: true };
      // enableIndexedDbPersistence(getFirestore(this._firebaseApp), settings);
      // this._firebaseApp.firestore().enablePersistence(settings).catch(error => {
      //   // TODO: Log this error
      //   throw error;
      // });
    }
  }

  /**
   * @property {FirebaseApp} _instance A private static instance
   */
  private static _instance: FirebaseAppSingleton;

  /*-----------------------------------------------*/
  /**
   * @property {FirebaseApp} _firebaseApp A Firebase Application object that has been initialized, if provided
   */
  private _firebaseApp: FirebaseApp;

  /**
   * @method firebaseApp Getter method for the Firebase Application object
   */
  get firebaseApp(): FirebaseApp {
    return this._firebaseApp;
  }

  /**
   * @method firebaseApp Setter method for the Firebase Application object
   * @param {FirebaseApp} value Value to use in setting the Firebase Application object
   */
  set firebaseApp(value: FirebaseApp) {
    this._firebaseApp = value;
  }

  /*-----------------------------------------------*/
  /**
   * @property {boolean} _persistenceSettingComplete Whether the setting of the persistence for the FirebaseAppSingleton object has been completed
   */
  private _persistenceSettingComplete: boolean = false;

  /**
   * @method firebaseApp Getter method for the _persistenceSettingComplete member
   */
  get persistenceSettingComplete(): boolean {
    return this._persistenceSettingComplete;
  }

  /**
   * @method persistenceSettingComplete Setter method for the _persistenceSettingComplete object
   * @param {boolean} value Value to use in setting the _persistenceSettingComplete object
   */
  private set persistenceSettingComplete(value: boolean) {
    this._persistenceSettingComplete = value;
  }

  /*-----------------------------------------------*/
  /**
   * @property {boolean} _fullyInitialized Whether the FirebaseAppSingleton object has been fully initialized and is ready to be used
   */
  private _fullyInitialized: boolean = false;

  /**
   * @method firebaseApp Getter method for the _fullyInitialized member
   */
  get fullyInitialized(): boolean {
    return this._fullyInitialized;
  }

  /**
   * @method fullyInitialized Setter method for the _fullyInitialized object
   * @param {boolean} value Value to use in setting the _fullyInitialized object
   */
  private set fullyInitialized(value: boolean) {
    this._fullyInitialized = value;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestore A Firebase Firestore database object that is associated with the current instance
   */
  private static _firestore: Firestore;

  /**
   * @method firestore Getter method for the Firestore database object
   */
  static get firestore(): Firestore {
    return this._firestore;
  }

  /**
   * @method firebaseApp Setter method for the Firestore database object
   * @param {Firestore} value Value to use in setting the Firestore database object
   */
  private set firestore(value: Firestore) {
    FirebaseAppSingleton._firestore = value;
  }
  /*-----------------------------------------------*/

  static getInstance(firebaseConfig?: IFirebaseConfiguration): FirebaseAppSingleton {
    if (!FirebaseAppSingleton._instance) {
      FirebaseAppSingleton._instance = new FirebaseAppSingleton(firebaseConfig);
    }

    return FirebaseAppSingleton._instance;
  }
  /*-----------------------------------------------*/
  /**
   * @method getFirestore Obtains and returns the Firestore database instance associated with the FirebaseAppSingleton object
   * @return The Firestore database instance associated with the FirebaseAppSingleton object 
   */
  private static getFirestore(): Firestore {
    // call Firebase API getFirestore() to obtain the Firestore object
    const firestoreObj: Firestore = getFirestore(FirebaseAppSingleton._instance.firebaseApp);
    // if the environment is configured to use emulators and the firestore emulator port is configured, connect to the emulator
    if (FirebaseEmulatorSettings.useEmulators) {
      if (FirebaseEmulatorSettings.firestoreEmulatorPort) {
        connectFirestoreEmulator(FirebaseAppSingleton._firestore, `localhost`, parseInt(FirebaseEmulatorSettings.firestoreEmulatorPort));
      }
    }

    return firestoreObj;
  }

}

