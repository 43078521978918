import {
  UserCredential,
} from "firebase/auth";
import { IFirebaseAuthentication, FirebaseAuthentication } from "../../../cloudServices/googleFirebaseServices/authentication/FirebaseAuthentication";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { IMdbError, MdbError } from '../../../../errorObjects/MdbError';


export function loginWithEmailAndPassword(email: string, password: string, requireVerifiedAccount: boolean = true): Promise<UserCredential> {
  return new Promise<UserCredential>(async (resolve, reject) => {
    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c (loginWithEmailAndPassword) Entered loginWithEmailAndPassword.', 'background: #0BB; color: #fff');

    try {
      // if we haven't been provided with both email and password, reject
      if (!email || !password) {
        displayConsoleLogs && console.log('%c (loginWithEmailAndPassword) Ready to throw intentional exception.', 'background: #0BB; color: #fff');
        reject(new MdbError(`Sign-in requires both email and password`, enumMdbErrorType.InvalidEmailAndOrPassword));
      } else {
        const throwErrorForTesting: boolean = false;

        if (throwErrorForTesting) {
          throw new MdbError('Intentional error from loginWithEmailAndPassword()', enumMdbErrorType.ForcedError);
        } else {

          // create a FirebaseAuthentication instance
          let firebaseAuthObj: IFirebaseAuthentication | null = new FirebaseAuthentication(FirebaseAppSingleton.getInstance().firebaseApp);

          // testing whether setting persistence on firebase.auth() to LOCAL will allow offline authentication
          // firebaseAuthObj.firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
          // firebaseAuthObj.firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

          // attempt a login with email and password
          const userAccount: UserCredential = await firebaseAuthObj!.loginWithEmailAndPassword(email, password);

          // if flag indicates that the user account must be verified...
          if (requireVerifiedAccount) {
            // ...if the user account has not undergone email verification...
            if (!userAccount.user?.emailVerified) {
              // ...logout and throw an exception
              await firebaseAuthObj!.logout();
              // throw new MdbError('Logging in to this user account is not allowed until the account has been verified via the link sent to the email address for this account.');
              // throw new MdbError('Signing in to this user account is not allowed until the account has been verified via the link sent to the email address for this account.', enumMdbErrorType.AccountNotVerified);
              const accountNotVerifiedError: IMdbError = new MdbError('Signing in to this user account is not allowed until the account has been verified via the link sent to the email address for this account.', enumMdbErrorType.AccountNotVerified, email);
              displayConsoleLogs && console.log(`%c (loginWithEmailAndPassword) Encountered 'Account Not Verified' Error. \nReady to throw MdbError: ${JSON.stringify(accountNotVerifiedError)}`, 'background: #0BB; color: #fff');
              throw accountNotVerifiedError;
            }
          }

          resolve(userAccount);
        }
      }

    } catch (error: any) {
      // alert(`Error. User login failed: (${error})`);
      // if we are catching an MdbError (details already included), merely reject with that error...
      if (error instanceof MdbError) {
        reject(error);
      } else { // otherwise, formulate a new error to reject
        reject(new MdbError(error, enumMdbErrorType.UnknownError));
      }
    }
  });
}