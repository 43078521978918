/**
 * @enum {enumMdbErrorType} Possible error types for MyDigiBrain (Mdb) errors
 */

export enum enumMdbErrorType {
  AccountNotVerified = 'AccountNotVerified', // a user attempted sign-in, but the user's account hasn't been verified
  AuthenticationError = 'AuthenticationError', // authentication (sign-in) failed for some reason
  BadData = 'BadData', // bad data has been encountered
  ColorValueIsInvalid = 'ColorValueIsInvalid', // a caolor value is invalid
  CurrentUserRequired = 'CurrentUserRequired', // operation requires a current user to be signed in
  DataNotFound = 'DataNotFound', // unable to find data requested
  DataFormatInvalid = 'DataFormatInsta', // unable to find data requested
  ForcedError = 'ForcedError', // an error that was forced by the system (to simulate an error condition)
  InconsistentData = 'InconsistentData', // data in database is inconsistent with what was expected
  InvalidEmailAndOrPassword = 'InvalidEmailAndOrPassword', // the user attempted to sign in with invalid email and/or password
  InvalidOperation = 'InvalidOperation', // the operation being attempted is invalid
  InvalidUrl = 'InvalidUrl', // the URL is invalid
  MissingData = 'MissingData', // necessary data is missing
  ObjectTypeIsInvalid = 'ObjectTypeIsInvalid', // the type of object is invalid or undefined
  RequiredDataNotFound = 'RequiredDataNotFound', // required information in the database cannot be found
  RequiredInputIsMissing = 'RequiredInputIsMissing', // required input information is missing
  ServiceReturnValueInvalid = 'ServiceReturnValueInvalid', // the value returned from a service is invalid
  TimeValueIsInvalid = 'TimeValueIsInvalid', // a time value is invalid
  UnknownError = 'UnknownError', // it is unknown what caused the error
  UnsupportedFeature = 'UnsupportedFeature', // the feature is unsupported
}

