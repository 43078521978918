// import dotenv from 'dotenv';

// dotenv.config();


/**
 * @class FirebaseConfigurationVariables Provides all of the Firebase configuration variables 
 */
export class FirebaseConfigurationVariables {
  /*-----------------------------------------------*/
  /**
   * @property {string} _apiKey The Google Firebase API Key
   */
  static apiKey: string = (process.env.REACT_APP_GOOGLE_FIREBASE_API_KEY ? process.env.REACT_APP_GOOGLE_FIREBASE_API_KEY : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} authDomain Authorized domain within Firebase
   */
  static authDomain: string = (process.env.REACT_APP_GOOGLE_FIREBASE_AUTH_DOMAIN ? process.env.REACT_APP_GOOGLE_FIREBASE_AUTH_DOMAIN : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} databaseStoragePath A URL that points to the Firestore Database instance
   */
  static databaseStoragePath: string = (process.env.REACT_APP_GOOGLE_FIREBASE_DATABASE_URL ? process.env.REACT_APP_GOOGLE_FIREBASE_DATABASE_URL : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} projectId Google Firebase Project Id
   */
  static projectId: string = (process.env.REACT_APP_GOOGLE_FIREBASE_PROJECT_ID ? process.env.REACT_APP_GOOGLE_FIREBASE_PROJECT_ID : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} storageBucket A URL pointing to a Google Firebase Storage Bucket for the project
   */
  static storageBucket: string = (process.env.REACT_APP_GOOGLE_FIREBASE_STORAGE_BUCKET ? process.env.REACT_APP_GOOGLE_FIREBASE_STORAGE_BUCKET : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} messagingSenderId Id for sending messages from Google Firebase
   */
  static messagingSenderId: string = (process.env.REACT_APP_GOOGLE_FIREBASE_MESSAGING_SENDER_ID ? process.env.REACT_APP_GOOGLE_FIREBASE_MESSAGING_SENDER_ID : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} appId Id for the Google Firebase application
   */
  static appId: string = (process.env.REACT_APP_GOOGLE_FIREBASE_APP_ID ? process.env.REACT_APP_GOOGLE_FIREBASE_APP_ID : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} measurementId An assigned Id required to use Google (Firebase) Analytics
   */
  static measurementId: string = (process.env.REACT_APP_GOOGLE_FIREBASE_MEASUREMENT_ID ? process.env.REACT_APP_GOOGLE_FIREBASE_MEASUREMENT_ID : '');
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} functionsDomain The domain for Firebase Functions (including the prefix 'https://')
   */
  static functionsDomain: string = (process.env.REACT_APP_GOOGLE_FIREBASE_FUNCTIONS_DOMAIN ? process.env.REACT_APP_GOOGLE_FIREBASE_FUNCTIONS_DOMAIN : '');
  /*-----------------------------------------------*/

}
