import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { IPersistable, IPersistableAsJson } from "../../../../dataObjects/models/persistence/Persistable";
import { typeUniqueId } from "../../../../dataObjects/types";
import { enumPersistableObjectType } from "../../../../dataObjects/enums";
import { getSingleObjectById_onSnapshot } from './';


// export function getMultipleObjectsByIds_onSnapshot<T extends IPersistable, TasJson extends IPersistableAsJson>(ids: Array<typeUniqueId>, objectType: enumPersistableObjectType, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Array<() => void>> {
//   return new Promise<Array<() => void>>(async (resolve, reject) => {

//     return Promise.all(ids.map((id: typeUniqueId) => {
//       return getSingleObjectById_onSnapshot(id, objectType, callback);
//     }))
//   });
// }

/*-----------------------------------------------*/
/**
 * @method getMultipleObjectsByIds_onSnapshot Requests the snapshot retrieval of zero or more objects of a specific type from the database, based on
 *                                     the identification values (Ids) of the objects in the database. A snapshot retrieval will provided notifications
 *                                     if objects retrieved have changed (live updates).
 * @param {Array<typeUniqueId>} ids An array of Ids for the objects to be retrieved.
 * @param {enumPersistableObjectType} objectType The type of objects to be retrieved (User, Category, Channel, etc.)
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void)} callback The callback function that Firestore
 *                                     will use to notify of object live updates.
 * @returns {Promise<Array<() => void>>} A Promise (to provide asynchrounous capability) with an array (potentially
 *                                    an empty array) with function objects for unsubscribing from the snapshot notifications.
 * @example Example usage: 
 *     // Use an IIFE to enable async/await and perform an asynchronous operation that waits on results
 *     (async () => {
 *       try {
 *          let unsubscribeCallbacksForUsers: Array<() => void> = await getMultipleObjectsByIds_onSnapshot(uniqueOwnerIds, enumPersistableObjectType.User, onUserSnapshotCallback);*
 *        } catch (error: any) {
 *          console.error(`HomePage. Error getting multiple User/Owner records (call to getMultipleObjectsByIds_onSnapshot): ${error.toString()}`);
 *        }
 *      })();
 */
/*-----------------------------------------------*/
export function getMultipleObjectsByIds_onSnapshot<T extends IPersistable, TasJson extends IPersistableAsJson>(ids: Array<typeUniqueId>, objectType: enumPersistableObjectType, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Array<() => void>> {
  return new Promise<Array<() => void>>(async (resolve, reject) => {

    // if there are Ids provided...
    if (ids.length > 0) {
      try {
        // use the array .map() function to iterate the given Ids, make a request to fetch the object/record for each Id, and join
        // the array of resulting promises by calling the Promise.all() function, returning an array (via resolve()) of Firestore unsubscribe 
        // callback functions (so that client code can unsubscribe from the Firestore snapshots, which are live updates).
        resolve(Promise.all(ids.map((id: typeUniqueId) => {
          return getSingleObjectById_onSnapshot<T, TasJson>(id, objectType, callback);
        })))
      }
      // if there's any error, reject the error, which is the equivalent of throwing an exception
      catch (error: any) {
        reject(error);
      }
    } else {
      // since there are no Ids, merely return an empty array of results
      resolve([]);
    }
  });
}
