import { take, put } from 'redux-saga/effects';
import { deleteNote } from '../../../firebaseServices/dataServices/dataServiceActions/noteActions';
import { noteDeleteRequest, noteDeleteStatusChange } from '../../slices/note/noteDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { noteDeleteFailureChange } from '../../slices/note/noteDeleteFailureSlice';

/**
 * @function deleteNoteRequestedSaga A saga workflow pertaining to a user having requested to delete a note.
 */
export function* deleteNoteRequestedSaga() {
  // console.info('(deleteNoteRequestedSaga) entered deleteNoteRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the noteDeleteRequest action to be dispatched and get the payload data...
      const noteDeleteRequestData = yield take(noteDeleteRequest);
      const { payload: note } = noteDeleteRequestData;

      // make a request to delete the note
      yield deleteNote(note);

      // dispatch an action to indicate that the delete operation was successful
      yield put(noteDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(noteDeleteStatusChange(enumWorkflowState.Failure));
      yield put(noteDeleteFailureChange(error));
    }
  }
}
