import { typeUniqueId } from "../../../../dataObjects/types"
import { ITopic, ITopicAsJson } from "../../../../dataObjects/models/topics/Topic";
import { IFirestoreTopicRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";


export function getTopicObjectUsingId(topicId: typeUniqueId): Promise<ITopic | undefined> {
  return new Promise<ITopic | undefined>(async (resolve, reject) => {
    try {
      // get a Topic repository from the repository factory
      const firestoreTopicRepository: IFirestoreTopicRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Topic) as
        IFirestoreBaseRepository<ITopic, ITopicAsJson>;

      // make request of the repository to get the Topic object associated with the Id
      const topic: ITopic | undefined = await firestoreTopicRepository.get(topicId);

      resolve(topic);
    } catch (error: any) {
      reject(error);
    }
  });
}