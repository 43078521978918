import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { ISocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { ISaveSocialMediaPostRequest } from '../../../dataObjects/models/serviceRequests/socialMediaPost/SaveSocialMediaPostRequest';
import { RegularExpressions } from '../../../assets/regularExpressionAssets';
import { styled } from '@mui/system';
import { SocialMediaPostDisplay } from '../../controls/digitalMediaDisplays/SocialMediaPostDisplay';
import { enumDigitalMediaDisplayEnvironment } from '../../../dataObjects/enums';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Preview Container
const StyledBoxForPreviewContainer = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginBottom: theme.spacing(2),
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexGrow: 1,
  position: 'relative',
}));

// a styled Box (equivalent to a <div>), providing an area to display the Preview Item
const StyledBoxForPreviewItemArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  objectFit: 'contain',  // 'contain' & 'scale-down' seem to work similarly
}));

interface ISocialMediaPostFormValues {
  url: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  url: yup
    .string()
    .matches(RegularExpressions.GenericUrl, ControlsStringAssets.socialMediaPostUrlInvalid)
    // .url({message: ControlsStringAssets.socialMediaPostUrlInvalid, regex: RegularExpressions.GenericUrl})
    .required(ControlsStringAssets.socialMediaPostUrlRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.socialMediaPostDescriptionRequired),
});

export interface ISocialMediaPostFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {ISocialMediaPost} socialMediaPost The SocialMediaPost details for the form (will have blank properties values if we're creating a new record)
   */
  socialMediaPost: ISocialMediaPost,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(socialMediaPost: ISocialMediaPost) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (socialMediaPost: ISaveSocialMediaPostRequest) => Promise<void>,
}

const SocialMediaPostForm: React.FC<ISocialMediaPostFormProps> = (props: ISocialMediaPostFormProps) => {
  SocialMediaPostForm.displayName = 'SocialMediaPost Form';

  // get required arguments from props
  const { socialMediaPost, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<ISocialMediaPostFormValues>({
    defaultValues: {
      url: socialMediaPost.url,
      description: socialMediaPost.description
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: urlReg, ...urlProps } = register("url", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  const [socialMediaPostUrlCurrentValue, setSocialMediaPostUrlCurrentValue] = useState<string>(socialMediaPost.url);

  // for capturing the current value of the Description field
  const [descriptionCurrentValue, setDescriptionCurrentValue] = useState<string>(socialMediaPost.description);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);


  function handleSocialMediaPostUrlCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setSocialMediaPostUrlCurrentValue(event.target.value);
  }


  function handleDescriptionCurrentValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setDescriptionCurrentValue(event.target.value);
  }

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: ISocialMediaPostFormValues) => {

    setSaveInProgress(true);

    // fill in title & description of the SocialMediaPost object passed in
    socialMediaPost.url = data.url;
    socialMediaPost.description = data.description;

    // prepare a request for saving, starting with just the videoLink
    let saveSocialMediaPostRequest: ISaveSocialMediaPostRequest = {
      socialMediaPost: socialMediaPost
    };

    // call the onSubmit handler passed in, supplying the SocialMediaPost object
    await onSubmit(saveSocialMediaPostRequest);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.socialMediaPost_SaveRequested}
        formIsValid={formIsValid}
      >

        <TextField
          inputRef={urlReg}
          {...urlProps}
          label={ControlsStringAssets.socialMediaPostUrlLabel}
          margin='normal'
          fullWidth
          onChange={handleSocialMediaPostUrlCurrentValueChanged}
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.url}
          helperText={errors?.url?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <StyledBoxForPreviewContainer>
          <StyledBoxForPreviewItemArea>
            <SocialMediaPostDisplay socialMediaPost={{ ...socialMediaPost, url: socialMediaPostUrlCurrentValue, description: descriptionCurrentValue}} 
              displayEnvironment={enumDigitalMediaDisplayEnvironment.FormView}
            />

          </StyledBoxForPreviewItemArea>
        </StyledBoxForPreviewContainer>

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          label={ControlsStringAssets.socialMediaPostDescriptionLabel}
          margin='normal'
          fullWidth
          onChange={handleDescriptionCurrentValueChanged}
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default SocialMediaPostForm;