import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ISocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';

const initialState: enumWorkflowState | null = null;

export const socialMediaPostDeleteStatusSlice = createSlice({
  name: 'socialMediaPostDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with ISocialMediaPost
    socialMediaPostDeleteRequest: (state, action: PayloadAction<ISocialMediaPost>) => {
      socialMediaPostDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    socialMediaPostDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { socialMediaPostDeleteRequest, socialMediaPostDeleteStatusChange } = socialMediaPostDeleteStatusSlice.actions;

// export the core reducer for the slice
export const socialMediaPostDeleteStatusReducer = socialMediaPostDeleteStatusSlice.reducer;

export default socialMediaPostDeleteStatusSlice.reducer;