import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const topicItemDeleteFailureSlice = createSlice({
  name: 'topicItemDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    topicItemDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    topicItemDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { topicItemDeleteFailureChange, topicItemDeleteFailureClear } = topicItemDeleteFailureSlice.actions;

// export the core reducer for the slice
export const topicItemDeleteFailureReducer = topicItemDeleteFailureSlice.reducer;

export default topicItemDeleteFailureSlice.reducer;