import { enumImageFileSize } from '../../../dataObjects/enums';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

export function convertImageFileSizeToFilenameSuffix(fileSize: enumImageFileSize): string {
  // variable to hold the suffix to return
  let suffix: string = '_**unknown**';

  switch (fileSize) {
    case enumImageFileSize.Thumbnail:
      suffix = '_100x100';
      break;

    case enumImageFileSize.Small:
      suffix = '_300x300';
      break;

    case enumImageFileSize.Medium:
      suffix = '_500x500';
      break;

    case enumImageFileSize.Large:
      suffix = '_1000x1000';
      break;

    default:
      throw new MdbError(`Unknown file size value: (${fileSize}) encountered in convertImageFileSizeToFilenameSuffix()`, enumMdbErrorType.InvalidOperation);
  }

  return suffix;
}