import { constants } from "buffer";
import { register } from "../../../serviceWorker";
import { convertHexColorStringToIRgb } from "./convertHexColorStringToIRgb";
import { IRgbColor } from "./IRgbColor";

/**
 * @function convertHexColorStringToRgbString Converts a Hex color string, along with opacity, to an RGB string
 * @param hexColorString A hex color string
 * @param alpha The desired resulting alpha (opacity) component
 */
export const convertHexColorStringToRgbaString: (hexColorString: string, alpha?: number) => string = (hexColorString: string, alpha?: number) => {

  const rgbaStructure: IRgbColor = convertHexColorStringToIRgb(hexColorString, alpha);

  const rgbaString: string = `rgb(${rgbaStructure.red}, ${rgbaStructure.green}, ${rgbaStructure.blue}, ${rgbaStructure.alpha})`;

  return rgbaString;
}
