import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsAYouTubeAddress Returns whether the URL is a YouTube address
 * @param url
 */
export const urlIsAYouTubeAddress: (url: string) => boolean = (url: string) => {

  // get the regular expression pattern for the website
  const regEx: RegExp = RegularExpressions.YouTube;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
