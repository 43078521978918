import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import { IconButton, InputAdornment } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Event as EventCalendarIcon } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { ITopicItem } from '../../../dataObjects/models/topics/TopicItem';
import dayjs, { Dayjs } from 'dayjs';
import { DateTimeValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers/models';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

interface ITopicItemFormValues {
  name: string;
  description: string;
  occurrenceTimestamp: Date;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  name: yup
    .string()
    .required(ControlsStringAssets.topicItemNameRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.topicItemDescriptionRequired),
  occurrenceTimestamp: yup
    .date()
    .required(ControlsStringAssets.topicItemOccurrenceTimestampRequired)
    .default(() => (new Date()))
});

export interface ITopicItemFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {ITopicItem} topicItem The TopicItem details for the form (will have blank properties values if we're creating a new record)
   */
  topicItem: ITopicItem,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(topic: ITopicItem) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (topicItem: ITopicItem) => Promise<void>,
}

const TopicItemForm: React.FC<ITopicItemFormProps> = (props: ITopicItemFormProps) => {
  TopicItemForm.displayName = 'TopicItem Form';

  // get required arguments from props
  const { topicItem, onSubmit } = props;

  // const [someDate, setSomeDate] = useState<string>("2020-02-04 8:45");

  const dateTimePickerFormat = "YYYY-MM-DD hh:mm A";

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<ITopicItemFormValues>({
    defaultValues: {
      name: topicItem.name,
      description: topicItem.description,
      occurrenceTimestamp: topicItem.occurrenceTimestamp
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: nameReg, ...nameProps } = register("name", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });
  const { ref: occurrenceTimestampReg } = register("occurrenceTimestamp", { required: true });

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // const [occurrenceTimestampValue, setOccurrenceTimestampValue] = useState<Date | undefined>(new Date());
  // const [occurrenceTimestampDateValue, setOccurrenceTimestampDateValue] = useState<Date | null | undefined>(new Date());
  const [occurrenceTimestampDateValue, setOccurrenceTimestampDateValue] = useState<Dayjs | null | undefined>(dayjs());
  // const [occurrenceTimestampStringValue, setOccurrenceTimestampStringValue] = useState<string | null | undefined>(new Date().toLocaleString());

  // useEffect hook for setting the 'occurrenceTimestampDateValue' local state based on the value of topicItem.occurrenceTimestamp
  useEffect(() => {
    // setOccurrenceTimestampDateValue(topicItem.occurrenceTimestamp);
    setOccurrenceTimestampDateValue(dayjs(topicItem.occurrenceTimestamp));
  }, [topicItem.occurrenceTimestamp]);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: ITopicItemFormValues) => {

    setSaveInProgress(true);

    // fill in name & description of the Topic object passed in
    topicItem.name = data.name;
    topicItem.description = data.description;
    topicItem.occurrenceTimestamp = occurrenceTimestampDateValue ? occurrenceTimestampDateValue.toDate() : dayjs().toDate();

    // call the onSubmit handler passed in, supplying the Topic object
    await onSubmit(topicItem);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.topicItem_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={nameReg}
          {...nameProps}
          autoFocus
          label={ControlsStringAssets.topicItemNameLabel}
          margin='normal'
          fullWidth
          error={!!errors.name}
          helperText={errors?.name?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          label={ControlsStringAssets.topicItemDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <p />

        <LocalizationProvider dateAdapter={AdapterDayjs} >
          <DateTimePicker
            // // renderInput={props => <TextField {...props} fullWidth />}
            // slotProps={{ textField: { variant: 'outlined' } }}
            // // slotProps={{ textField: { fullWidth } }}
            // // slotProps={props => { textField: { ...props fullWidth } }}
            label={ControlsStringAssets.topicItemOccurrenceTimestampLabel}
            inputRef={occurrenceTimestampReg}
            // inputFormat="YYYY/MM/DD hh:mm a"
            disablePast={false}
            // value={occurrenceTimestampDateValue}
            value={occurrenceTimestampDateValue ? dayjs(occurrenceTimestampDateValue) : null}
            // value={null}
            // onChange={(date: unknown, keyboardInputValue?: string | undefined) => { setOccurrenceTimestampDateValue(date as Date) }}
            onChange={(value: Dayjs | null, context: PickerChangeHandlerContext<DateTimeValidationError>) => { setOccurrenceTimestampDateValue(value) }}
            views={["year", "month", "day", "hours", "minutes"]}
            viewRenderers={{
              hours: renderTimeViewClock,
              minutes: renderTimeViewClock,
            }}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton>
            //         <EventCalendarIcon />
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
          >
          </DateTimePicker>
        </LocalizationProvider >

      </FormWithActionBar>
    </>

  );
}

export default TopicItemForm;