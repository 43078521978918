import { IFirestoreTopicRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ITopic, ITopicAsJson } from "../../../../dataObjects/models/topics/Topic";


export function saveTopic(topic: ITopic): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Topic repository from the repository factory
      const firestoreTopicRepository: IFirestoreTopicRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Topic) as
        IFirestoreBaseRepository<ITopic, ITopicAsJson>;

      // determine if the topic already exists by calling the repository get() method to try and get the object
      const existingTopic: ITopic | undefined = await firestoreTopicRepository.get(topic.id);

      // If the topic wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingTopic === undefined) {
        // call repository method to create the topic
        await firestoreTopicRepository.create(topic);
      } else {
        // call repository method to update the topic
        await firestoreTopicRepository.update(topic);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}