import { take } from "redux-saga/effects";
import { saveNoteWorkerSaga } from "./saveNoteWorkerSaga";
import { noteSaveRequest } from "../../slices/note/noteSaveStatusSlice";

/**
 * @function saveNoteWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save a Note.
 */
export function* saveNoteWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the noteSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveNoteRequestData = yield take(noteSaveRequest);
    const { payload: saveNoteRequest } = saveNoteRequestData;

    yield saveNoteWorkerSaga(saveNoteRequest);
  }
}