import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function convertGoogleDriveFileUrlForDownload Given a Google Drive File URL, converts the URL to represent a download for the file
 * @param url
 * 
 * Steps to convert a Google Drive link to a Google Drive Direct Download Link:
 *   1. The url should be similar to this one: https://drive.google.com/file/d/1RG3CAPiwiFlFATUlIIwhk0RrbEU4PgVP/view?usp=sharing
 *   2. Each Google Drive file url has a unique file ID in the link, this is the text between d/ and /view: "1RG3CAPiwiFlFATUlIIwhk0RrbEU4PgVP" in this case
 *   3. We merely want to remove anything after the 'view' near the end of the url
 *   4. The resulting url would look like this: https://drive.google.com/uc?id=1RG3CAPiwiFlFATUlIIwhk0RrbEU4PgVP/view
 * 
 */
export const convertGoogleDriveFileUrlForDownload: (url: string) => string = (url: string) => {
	// declare a url to return and default it to the url parameter
	let convertedUrl: string = url;

	if (url) {
		// using a regular expression, get the url truncated after the 'view' directive
		const regEx: RegExp = RegularExpressions.DownloadableUrlFromGoogleDriveShareLink;
		const regExResults: RegExpExecArray | null = regEx.exec(url);

    // if something was extracted (regExResults array) and the array has only one element, it must have matched the url pattern
		if (regExResults && (regExResults.length === 1)) {
      // create a revised Url that includes the url truncated after the 'view' directive
			convertedUrl = regExResults[0];
		}
	}

	return convertedUrl;
}
