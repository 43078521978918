import { useContext } from 'react';
import { AppVersionContext, IAppVersionContextData } from '.';

/**
 * @hook useAppThemeContext  A custom React hook that simplifies the process for obtaining the Application Version information
 *       associated with the application.
 */
 export const useAppVersionContext = (): IAppVersionContextData => {

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed useAppVersionContext hook', 'background: #008080; color: #fff');

  // get the context data
  const appVersionContextData: IAppVersionContextData = useContext(AppVersionContext);

  displayConsoleLogs && console.log(`%c In useAppVersionContext. appVersionContextData: ${JSON.stringify(appVersionContextData)}`, 'background: #008080; color: #fff');

  return appVersionContextData;
}
