import { AccessPermissions, IAccessPermissions } from "../../models/collaboration/ObjectUserPermissions";
import { IHyperLink } from "../../models/digitalMedia/HyperLink";
import { typeUniqueId } from "../../types";
import { IHyperLinkViewModel } from ".";


export class HyperLinkViewModel implements IHyperLinkViewModel {

  /**
   * @method Constructor method
   * @param {IHyperLink} hyperLink A reference to the underlying HyperLink object
   * @param {typeUniqueId} parentTopicItemId Id of the TopicItem that is the parent of the digital media object
   * @param {typeUniqueId} ancestorTopicId Id of the Topic that is a direct ancestor of the digital media object
   * @param {typeUniqueId} parentTopicItemId Id of the Channel that is a direct ancestor of the digital media object
   * @param {IAccessPermissions} (optional) accessPermissionToAncestorChannel The current user's access permission to the ancestor channel.
   */
  constructor(
    hyperLink: IHyperLink,
    parentTopicItemId: typeUniqueId,
    ancestorTopicId: typeUniqueId,
    ancestorChannelId: typeUniqueId,
    accessPermissionToAncestorChannel?: IAccessPermissions
  ) {

    // assign properties from ctor parameters
    this._hyperLink = hyperLink;
    this._parentTopicItemId = parentTopicItemId;
    this._ancestorTopicId = ancestorTopicId;
    this._ancestorChannelId = ancestorChannelId;

    if (accessPermissionToAncestorChannel) {
      this._accessPermissionToAncestorChannel = accessPermissionToAncestorChannel;
    }
  }


  /*-----------------------------------------------*/
  /**
   * @property {IHyperLink} _hyperLink A reference to the underlying HyperLink object
   */
  private _hyperLink: IHyperLink;

  /**
   * @method hyperLink Getter method for _hyperLink
   */
  get hyperLink(): IHyperLink {
    return this._hyperLink;
  }

  /**
   * @method hyperLink Setter method for _hyperLink
   * @param {IHyperLink} value The input value for setting _hyperLink
   */
  set hyperLink(value: IHyperLink) {
    this._hyperLink = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _parentTopicItemId Id of the TopicItem that is the parent of the digital media object
   */
  private _parentTopicItemId: typeUniqueId;

  /**
   * @method parentTopicItemId Getter method for _parentTopicItemId
   */
  get parentTopicItemId(): typeUniqueId {
    return this._parentTopicItemId;
  }

  /**
   * @method parentTopicItemId Setter method for _parentTopicItemId
   * @param {typeUniqueId} value The input value for setting _parentTopicItemId
   */
  set parentTopicItemId(value: typeUniqueId) {
    this._parentTopicItemId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _ancestorTopicId Id of the Topic that is a direct ancestor of the digital media object
   */
  private _ancestorTopicId: typeUniqueId;

  /**
   * @method ancestorTopicId Getter method for _ancestorTopicId
   */
  get ancestorTopicId(): typeUniqueId {
    return this._ancestorTopicId;
  }

  /**
   * @method ancestorTopicId Setter method for _ancestorTopicId
   * @param {typeUniqueId} value The input value for setting _ancestorTopicId
   */
  set ancestorTopicId(value: typeUniqueId) {
    this._ancestorTopicId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _ancestorChannelId Id of the Channel that is a direct ancestor of the digital media object
   */
  private _ancestorChannelId: typeUniqueId;

  /**
   * @method ancestorChannelId Getter method for _ancestorChannelId
   */
  get ancestorChannelId(): typeUniqueId {
      return this._ancestorChannelId;
  }

  /**
   * @method ancestorChannelId Setter method for _ancestorChannelId
   * @param {typeUniqueId} value The input value for setting _ancestorChannelId
   */
  set ancestorChannelId(value: typeUniqueId) {
      this._ancestorChannelId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IAccessPermissions} _accessPermissionToAncestorChannel The current user's access permission to the ancestor channel.
   */
  private _accessPermissionToAncestorChannel: IAccessPermissions = new AccessPermissions();

  /**
   * @method accessPermissionToAncestorChannel Getter method for _accessPermissionToAncestorChannel
   */
  get accessPermissionToAncestorChannel(): IAccessPermissions {
      return this._accessPermissionToAncestorChannel;
  }

  /**
   * @method accessPermissionToAncestorChannel Setter method for _accessPermissionToAncestorChannel
   * @param {IUserAccessPermissionsForObject} value The input value for setting _accessPermissionToAncestorChannel
   */
  set accessPermissionToAncestorChannel(value: IAccessPermissions) {
      this._accessPermissionToAncestorChannel = value;
  }
  /*-----------------------------------------------*/

}