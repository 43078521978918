import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreUserCategoriesRepository_Ext, IFirestoreUserCategoriesRepository_Ext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserCategoriesRepository";
import { FirestoreCategoryRepository_Ext, IFirestoreCategoryRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository';


/**
 * @function getUserCategoriesForUser_onSnapshot Triggers a request for a snapshot with a collection of UserCategories for a given User
 * @param {typeUniqueId} userId {}
 * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback method that receives document data as a parm and has a void return value 
 * @returns {() => void} An 'unsubscribe callback' method that is to be called when the snapshot is no longer needed.
 */
export function getUserCategoriesForUser_onSnapshot(userId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      // const firestoreUserCategoryRepository_Ext: IFirestoreUserCategoriesRepository_Ext = new FirestoreUserCategoriesRepository_Ext(firestoreObj);
      const firestoreCategoryRepository_Ext: IFirestoreCategoryRepository_Ext = new FirestoreCategoryRepository_Ext(firestoreObj);

      // const unsubscribeCallback: () => void = await firestoreUserCategoryRepository_Ext.getUserCategoriesForUser_onSnapshot(userId, callback);
      const unsubscribeCallback: () => void = await firestoreCategoryRepository_Ext.getCategoriesForUser_onSnapshot(userId, callback);
      // console.log(`getUserCategoriesForUser_onSnapshot (UI middleware service method) before returning, unsubscribeCallback: ${unsubscribeCallback}`);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}