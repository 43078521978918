import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchSocialMediaPostsFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchSocialMediaPostsFailureSlice = createSlice({
  name: 'fetchSocialMediaPostsFailure',
  initialState,
  reducers: {
    setFetchSocialMediaPostsFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchSocialMediaPostsFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchSocialMediaPostsFailure, clearFetchSocialMediaPostsFailure } = fetchSocialMediaPostsFailureSlice.actions;

// export the core reducer for the slice
export const fetchSocialMediaPostsFailureReducer = fetchSocialMediaPostsFailureSlice.reducer;

export default fetchSocialMediaPostsFailureSlice.reducer;