import { IFirestoreImageLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreImageLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IImageLink, IImageLinkAsJson } from "../../../../dataObjects/models/digitalMedia/ImageLink";


export function saveImageLink(imageLink: IImageLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a ImageLink repository from the repository factory
      const firestoreImageLinkRepository: IFirestoreImageLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ImageLink) as
        IFirestoreBaseRepository<IImageLink, IImageLinkAsJson>;

      // determine if the imageLink already exists by calling the repository get() method to try and get the object
      const existingImageLink: IImageLink | undefined = await firestoreImageLinkRepository.get(imageLink.id);

      // If the imageLink wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingImageLink === undefined) {
        // call repository method to create the imageLink
        await firestoreImageLinkRepository.create(imageLink);
      } else {
        // call repository method to update the imageLink
        await firestoreImageLinkRepository.update(imageLink);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}