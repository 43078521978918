import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { ITopicItem, ITopicItemAsJson, TopicItem } from '../../../../dataObjects/models/topics/TopicItem';
import { typeUniqueId } from '../../../../dataObjects/types';
import { getTopicItemsForParentId_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/topicItemActions/getTopicItemsForParentId_onSnapshot';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumPersistableObjectType, enumWorkflowState } from '../../../../dataObjects/enums';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import TopicItemsListView from '../../../views/topicItems/TopicItemsListView/TopicItemsListView';
import { TopicItemsCardGridView } from '../../../views/topicItems/TopicItemsCardGridView/TopicItemsCardGridView';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { ITopic, ITopicAsJson, Topic } from '../../../../dataObjects/models/topics/Topic';
import { styled } from '@mui/system';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../../enums';
import { getSingleObjectById_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { useAppDispatch, useAppSelector } from '../../../../uiMiddleware-redux/store/configureStore';
import { setCurrentTopic } from "../../../../uiMiddleware-redux/slices/topic/currentTopicSlice";
import { topicItemDeleteRequest, topicItemDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/topicItem/topicItemDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
// import { useAppDispatch } from '../../../customHooks/useAppDispatch';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area of padding at the top of the page
const StyledBoxForPaddingAtopPage = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface ITopicItemsPageProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId
}

const TopicItemsPage: React.FC<ITopicItemsPageProps> = (props: ITopicItemsPageProps) => {

  TopicItemsPage.displayName = 'TopicItems Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  const navigate = useNavigate();
  const params = useParams();

  const parentId = params.parentId; // from the path '/:parentId/topicItems

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the TopicItems associcated with the current user account
  const [topicItems, setTopicItems] = useState<Array<ITopicItem>>([]);
  // whether we are currently fetching the current topic
  const [fetchingCurrentTopic, setFetchingCurrentTopic] = useState<boolean>(false);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a TopicItem, the TopicItem to delete
  const [topicItemToDelete, setTopicItemToDelete] = useState<ITopicItem | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // to assess the status of data preparation for user categories and channels
  const [userCategoriesAndChannelsDataPrepStatus, SetUserCategoriesAndChannelsDataPrepStatus] = useState<enumCategoriesAndChannelsDataPreparationStatus>(enumCategoriesAndChannelsDataPreparationStatus.None);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();
  const userId: typeUniqueId | undefined = currentUser?.id;

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();
  const currentChannel: IChannel | undefined = userCategoriesAndChannelsContext.state.currentChannel;
  displayConsoleLogs && console.log(`%c TopicItemsPage. currentChannel: \n${JSON.stringify(currentChannel)}`, 'background: #006; color: #fff');
  if (userCategoriesAndChannelsDataPrepStatus !== userCategoriesAndChannelsContext.state.status) {
    SetUserCategoriesAndChannelsDataPrepStatus(userCategoriesAndChannelsContext.state.status);
  }

  // get the current Topic from Redux state
  const currentTopic: ITopic | null | undefined = useAppSelector((state: IStoreState) => state.currentTopic);

  displayConsoleLogs && console.log(`%c TopicItemsPage. currentTopic: \n${JSON.stringify(currentTopic)}`, 'background: #006; color: #fff');

  // If (there is no current topic -OR- the current topic's id doesn't match the parentId specified for the topic items) -AND- 
  // a valid parentId is provided -AND- we're not currently fetching the topic, we need to 
  // fetch it using the parentId. This is the case when the user attempt to navigate to this page via a navigation link (URL) as
  // opposed to clicking on one of the Topics for the Channel).
  if ((!currentTopic || (currentTopic.id !== parentId)) && parentId && !fetchingCurrentTopic) {
    displayConsoleLogs && console.log(`%c TopicItemsPage. Ready to fetch (current) Topic using parentId: \n${parentId}`, 'background: #006; color: #fff');

    // subscribe to onShapshot updates for the Topic, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
    getSingleObjectById_onSnapshot(parentId, enumPersistableObjectType.Topic, onSnapshotCallback_Topic);

    setFetchingCurrentTopic(true);
  }

  // set up a useEffect() hook to get the topicItems for the given parentId and get data updates via onSnapshot
  useEffect(() => {
    // if there is a current topic and we were previously fetching it, indicate that we are no longer fetching it
    if (currentTopic && fetchingCurrentTopic) {
      // reset the flag to indicate that we are not fetching it
      setFetchingCurrentTopic(false);
    }
  }, [parentId, currentTopic, fetchingCurrentTopic])

  /**
   * @function onSnapshotCallback_Topic A callback method to receive firestore Topic data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback_Topic(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.log(`%c TopicItemsPage. onSnapshotCallback_Topic: \n${JSON.stringify(snapshot)}`, 'background: #006; color: #fff');

    // get the Topic document (there should one, and only one, Topic document)
    const topicDataAsJson: Array<ITopicAsJson> = [];

    snapshot.forEach(doc => topicDataAsJson.push({ ...doc.data(), id: doc.id } as ITopicAsJson));

    if (topicDataAsJson.length === 1) {
      const topic: ITopic = JsonConverter.fromJSON(Topic, topicDataAsJson[0], false);
      if (!_.isEqual(currentTopic, topic)) {
        dispatch(setCurrentTopic(topic));
      }
    }
    // if !==1, we need to do something to flag a problem, since we need the DigitalMedia base object to proceed
  }

  /**
   * @function resetDeleteTopicStatus Resets the Delete status in Redux state
   */
  const resetDeleteTopicItemStatus: () => void = useCallback(() => {
    dispatch(topicItemDeleteStatusChange(null));
  }, [dispatch])

  // a useEffect hook for to evaluate changes to the userCategoriesAndChannelsDataPrepStatus state variable
  useEffect(() => {
    // If there is no currentChannel specified in the Categories & Channels context, inform the context
    // to resolve the current channel from the Id of the Topic that has been passed to this component as the parentId.
    // (The currentChannel in the context could be undefined if the browser was refreshed after landing on this page.)
    if ((userCategoriesAndChannelsContext.state.currentChannel === undefined) && (userCategoriesAndChannelsContext.actions.resolveCurrentChannel !== undefined) && (parentId !== undefined)) {
      userCategoriesAndChannelsContext.actions.resolveCurrentChannel(parentId, enumPersistableObjectType.Topic);
    }
  }, [userCategoriesAndChannelsDataPrepStatus]);

  // set up a useEffect() hook to get the topicItems for the given parentId and get data updates via onSnapshot
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallback: () => void = () => { };

    if (parentId) {
      displayConsoleLogs && console.log(`%c TopicItemsPage. useEffect for [parentId, userId, resetDeleteTopicItemStatus]. Ready to request Topic Items for parentId: \n${parentId}`, 'background: #006; color: #fff');

      // subscribe to onShapshot updates for the TopicItems, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      // console.log(`TopicItemsPage: before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      getTopicItemsForParentId_onSnapshot(parentId, onSnapshotCallback_TopicItems).then((unsubscribe: () => void) => {
        unsubscribeCallback = unsubscribe;
        // console.log(`TopicItemsPage: after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // if the user is still logged in...
      // console.log(`TopicItemsPage: unmounting (useEffect return). userId: ${userId}`);
      // console.log(`TopicItemsPage: unmounting (useEffect return). unsubscribeCallback: ${unsubscribeCallback}`);
      if (userId) {
        // Call the unsubscribe() callback to unsubscribe from the onSnapshot . If it was never 
        // console.log(`TopicItemsPage. Ready to call 'unsubscribeCallback'`);
        unsubscribeCallback();
        // console.log(`TopicItemsPage. Completed call to 'unsubscribeCallback'`);

        // reset the DeleteTopicStatus in Redux state to return to a clean slate
        resetDeleteTopicItemStatus();
      }
    }
  }, [parentId, userId, resetDeleteTopicItemStatus])

  // use a custom hook to evaluate the redux state for the deleteTopicItem workflow and any deleteTopicItem error
  const { workflowState: deleteTopicItemStatus, errorState: deleteTopicItemFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteTopicItemStatus, (state: IStoreState) => state.deleteTopicItemFailure);

  /**
   * @function onSnapshotCallback_TopicItems A callback method to receive firestore TopicItems data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onSnapshotCallback_TopicItems(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.log(`%c TopicItemsPage. onSnapshotCallback_TopicItems: \n${JSON.stringify(snapshot)}`, 'background: #006; color: #fff');
    // console.log(`TopicItemsPage:onSnapshotCallback(). Entered callback function.`);
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);
    const topicItemDataAsJson: Array<ITopicItemAsJson> = [];
    // console.log(`TopicItemsPage:onSnapshotCallback(). Ready to get doc data.`);
    snapshot.forEach(doc => topicItemDataAsJson.push({ ...doc.data(), id: doc.id } as ITopicItemAsJson));
    // console.log(`TopicItemsPage:onSnapshotCallback(). Completed getting doc data.`);

    // create an array of TopicItem objects from the JSON data
    let topicItemsData = JsonConverter.arrayFromJSONArray(TopicItem, topicItemDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    // topicItemsData = _.orderBy(topicItemsData, ['name'], ['asc']);
    topicItemsData = _.orderBy(topicItemsData, [topicItem => topicItem.name.toUpperCase()], ['asc']);

    setTopicItems(topicItemsData);
  }

  /**
   * @method handleTopicItemDelete Handles requests to delete a Channel
   * @param topicItem The TopicItem to be deleted
   */
  async function handleTopicItemDelete(topicItem: ITopicItem): Promise<void> {
    // set the topicItem to be deleted into local state
    setTopicItemToDelete(topicItem);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleTopicItemDeleteConfirmed(): void {
    // using the topicItem object to be deleted from the local state, dispatch an action to delete the topicItem object
    if (topicItemToDelete !== undefined) {
      dispatch(topicItemDeleteRequest(topicItemToDelete));
    }

    // call method to reset the topicItem delete local state details
    topicItemDeleteReset();
  }

  function handleTopicItemDeleteCanceled(): void {
    // call method to reset the topicItem delete local state details
    topicItemDeleteReset();
  }

  function topicItemDeleteReset() {
    // reset the topicItem to be deleted to 'undefined' in local state, since we're done with the object
    setTopicItemToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }

  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteTopicItemStatus
      resetDeleteTopicItemStatus();

      // notify user via a Beacon notification that the topicItem has been deleted
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.topicItem_DeleteConfirmationHeader, MessagesStringAssets.topicItem_DeleteSuccess);
      dispatch(beaconChange(beacon));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteTopicItemStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.topicItem_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a topicItem, prepare an alert message fragment to display progress message(s)
    if (deleteTopicItemStatus !== undefined && deleteTopicItemStatus !== null) {
      if (deleteTopicItemStatus === enumWorkflowState.Requested || deleteTopicItemStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.topicItem_DeleteRequested;
      } else if (deleteTopicItemStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteTopicItemStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.topicItem_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteTopicItemFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteTopicItemFailure && deleteTopicItemFailure instanceof Error) {
          failureMessage = deleteTopicItemFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }


  // Establish the page title to be displayed. Once we have retrieved the parent Topic, apply the topicItemNameAliasPlural; otherwise, show nothing
  const pageTitle: string = currentTopic ? currentTopic.topicItemNameAliasPlural : PageAndViewTitleStringAssets.pageTitle_TopicItems;

  // Establish the text to be displayed on the 'Add New' button

  // Determine the user's permissions relative to the current channel
  const userPermissions: IUserAccessPermissionsForObject | undefined = currentUser && currentChannel ? new UserAccessPermissionsForObject(currentUser.id, currentChannel) : undefined;

  // Establish the text to be displayed on the '+ New' button
  const userHasEditPermission: boolean = (userPermissions !== undefined) && userPermissions.hasEditPermission;
  // const addNewButtonText: string = currentTopic ? `New ${currentTopic.topicItemNameAliasSingular}` : ControlsStringAssets.topicItemNew;
  let addNewButtonText: string = '';
  if (userHasEditPermission && currentChannel) {
    addNewButtonText = currentTopic && currentChannel ? `New ${currentTopic.topicItemNameAliasSingular}` : ControlsStringAssets.topicItemNew;
  }

  return (
    <>
      {/* only display the page after we have retrieved the Topic */}
      {currentTopic && currentChannel && currentUser &&
        <>
          <StyledBoxForPaddingAtopPage />
          <GenericPageContainer
            // maxWidth="xl"
            showBackButton={true}
            objectSpecificPageHeaderCategory={currentChannel.topicNameAliasSingular}
            objectSpecificPageHeaderText={currentTopic.name}
            pageTitle={pageTitle}
            onCloseAlert={resetDeleteTopicItemStatus}
          >

            {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
            <DataViewControls
              addNewItemButtonText={addNewButtonText}
              onClickAddNewItemButton={() => navigate(`/${parentId}/topicItem`)}
            >
            </DataViewControls>

            <StyledBoxForHorizontalDivider />

            {/* Container for embedded data view */}
            <StyledContainerForDataView>
              {/* If there are TopicItems to display and the current view selection is CardsGridView, display the TopicItemsCardGridView */}
              {topicItems && currentUser &&
                (collectionViewType === enumCollectionViewType.CardsGridView) &&
                <TopicItemsCardGridView topicItems={topicItems} currentUser={currentUser} channel={currentChannel} onDelete={handleTopicItemDelete} />
              }

              {/* If there are TopicItems to display and the current view selection is ListView, display the TopicItemsListView */}
              {topicItems && currentUser &&
                (collectionViewType === enumCollectionViewType.ListView) &&
                <TopicItemsListView topicItems={topicItems} currentUser={currentUser} channel={currentChannel} onDelete={handleTopicItemDelete} />
              }
            </StyledContainerForDataView>

            {/* Delete TopicItem Confirmation Dialog */}
            <TwoButtonAcceptanceDialog
              showDialog={showDeleteConfirmationDlg}
              headerText={MessagesStringAssets.topicItem_DeleteConfirmationHeader}
              bodyText={MessagesStringAssets.topicItem_DeleteConfirmation}
              acceptanceButtonText={ControlsStringAssets.confirmButtonText}
              nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
              onAcceptance={handleTopicItemDeleteConfirmed}
              onNonAcceptance={handleTopicItemDeleteCanceled}
            />

            {activityIndicatorData.showActivityIndicator &&
              <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
            }

          </GenericPageContainer>
        </>
      }
    </>
  );
}

export default TopicItemsPage;
