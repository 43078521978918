import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const audioLinkDeleteFailureSlice = createSlice({
  name: 'audioLinkDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    audioLinkDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    audioLinkDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { audioLinkDeleteFailureChange, audioLinkDeleteFailureClear } = audioLinkDeleteFailureSlice.actions;

// export the core reducer for the slice
export const audioLinkDeleteFailureReducer = audioLinkDeleteFailureSlice.reducer;

export default audioLinkDeleteFailureSlice.reducer;