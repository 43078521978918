import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumLocale, enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { VersionAwarePersistable } from "../../persistence/VersionAwarePersistable";
import { IPostalAddress, PostalAddress } from "../../address/PostalAddress";
import { IUserSettings, IUserSettingsAsJson } from ".";
import { JsonConverter } from "../../../utilities/JsonConverter";
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { getCurrentDeviceLocale } from '../../../utilities/deviceAndLocale';
import { enumThemeMode } from '../../../enums/enumThemeMode';
import { enumThemeModeConvert } from '../../../enums/conversions/enumThemeModeConvert';

/** 
 * @class UserSettings Represents user settings and preferences
 */
export class UserSettings extends VersionAwarePersistable implements IUserSettings {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {string} firstName The first name of the user
   * @param {string} lastName The last name of the user
   * @param {string} email The email address of the user
   * @param {string} mobilePhone The mobile phone number of the user
   * @param {string} preferredLocale The preferred locale of the user
   * @param {string} postalAddress The postal address of the user
   * @param {string} themeCategoryId Category of the selected UI theme for user
   * @param {string} themeId Id of the selected UI theme for user
   * @param {enumThemeMode} themeMode Selected UI theme mode (light or dark) for user
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userPersistenceData?: IUserPersistenceData,
    firstName?: string,
    lastName?: string,
    email?: string,
    mobilePhone?: string,
    preferredLocale?: string,
    postalAddress?: IPostalAddress,
    themeCategoryId?: string,
    themeId?: string,
    themeMode?: enumThemeMode
  ) {
    super(ownerId, enumPersistableObjectClassName.UserSettings, enumPersistableObjectType.UserSettings, id, parentObjectType, parentId, objectState, userPersistenceData);

    if (firstName !== undefined) {
      this.firstName = firstName;
    }

    if (lastName !== undefined) {
      this.lastName = lastName;
    }

    if (email !== undefined) {
      this.email = email;
    }

    if (mobilePhone !== undefined) {
      this.mobilePhone = mobilePhone;
    }

    // if a preferredLocale was provided, use it; otherwise, get the locale for the current device and use it
    if (preferredLocale !== undefined) {
      this.preferredLocale = preferredLocale;
    } else {
      this.preferredLocale = getCurrentDeviceLocale();
    }

    if (postalAddress !== undefined) {
      this.postalAddress = postalAddress;
    }

    if (themeCategoryId !== undefined) {
      this.themeCategoryId = themeCategoryId;
    }

    if (themeId !== undefined) {
      this.themeId = themeId;
    }

    if (themeMode !== undefined) {
      this.themeMode = themeMode;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _firstName The user's first name
   */
  private _firstName: string = '';

  /**
   * @method firstName Getter method for the user's first name
   */
  get firstName() {
    return this._firstName;
  }

  /**
   * @method firstName Setter method for the user's first name
   * @param {string} value The value to be used to set the user's first name
   */
  set firstName(value: string) {
    this._firstName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _lastName The user's last name
   */
  private _lastName: string = '';

  /**
   * @method lastName Getter method for the user's last name
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @method lastName Setter method for the user's last name
   * @param {string} value The value to be used to set the user's last name
   */
  set lastName(value: string) {
    this._lastName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _email The user's email address
   */
  private _email: string = '';

  /**
   * @method email Getter method for the user's email address
   */
  get email() {
    return this._email;
  }

  /**
   * @method email Setter method for the user's email address
   * @param {string} value The value to be used to set the user's email address
   */
  set email(value: string) {
    this._email = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _mobilePhone The user's mobile phone number
   */
  private _mobilePhone: string | undefined = undefined;

  /**
   * @method mobilePhone Getter method for the user's mobile phone number
   */
  get mobilePhone() {
    return this._mobilePhone;
  }

  /**
   * @method mobilePhone Setter method for the user's mobile phone number
   * @param {string | undefined} value The value to be used to set the user's mobile phone number
   */
  set mobilePhone(value: string | undefined) {
    this._mobilePhone = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _preferredLocale The user's preferred locale (language)
   */
  private _preferredLocale: string = enumLocale.English_US;

  /**
   * @method preferredLocale Getter method for the user's preferred locale (language)
   */
  get preferredLocale(): string {
    return this._preferredLocale;
  }

  /**
   * @method preferredLocale Setter method for the user's preferred locale (language)
   * @param {string} value The value to be used to set the user's preferred locale (language)
   */
  set preferredLocale(value: string) {
    this._preferredLocale = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IPostalAddress | undefined} _postalAddress The user's postal address
   */
  private _postalAddress: IPostalAddress | undefined = undefined;

  /**
   * @method postalAddress Getter method for the user's postal address
   */
  get postalAddress() {
    return this._postalAddress;
  }

  /**
   * @method postalAddress Setter method for the user's postal address
   * @param {IPostalAddress | undefined} value The value to be used to set the user's postal address
   */
  set postalAddress(value: IPostalAddress | undefined) {
    this._postalAddress = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _themeCategoryId Category Id of the selected UI theme for user
   */
  private _themeCategoryId: string = 'general';

  /**
   * @method themeCategoryId Getter method for the user's selected UI theme catetory Id
   */
  get themeCategoryId(): string {
    return this._themeCategoryId;
  }

  /**
   * @method themeCategoryId Setter method for the user's selected UI theme category Id
   * @param {string} value The value to be used to set the user's selected UI theme category Id
   */
  set themeCategoryId(value: string) {
    this._themeCategoryId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _themeId Id of the selected UI theme for the user
   */
  private _themeId: string = 'default';

  /**
   * @method themeId Getter method for the user's selected UI theme
   */
  get themeId(): string {
    return this._themeId;
  }

  /**
   * @method themeId Setter method for the user's selected UI theme
   * @param {string} value The value to be used to set the user's selected UI theme
   */
  set themeId(value: string) {
    this._themeId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumThemeMode} _themeMode Selected UI theme mode for the user
   */
  private _themeMode: enumThemeMode = enumThemeMode.Light;

  /**
   * @method theme Getter method for the user's selected UI theme mode
   */
  get themeMode(): enumThemeMode {
    return this._themeMode;
  }

  /**
   * @method theme Setter method for the user's selected UI theme mode
   * @param {enumThemeMode} value The value to be used to set the user's selected UI theme mode
   */
  set themeMode(value: enumThemeMode) {
    this._themeMode = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUserSettings} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserSettings {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserSettings = Object.create(UserSettings.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.postalAddress !== undefined) {
      copyOfObject.postalAddress = Object.create(PostalAddress.prototype);
      copyOfObject.postalAddress = this.postalAddress.copy();
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IUserSettingsAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IUserSettingsAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.firstName = this.firstName;
      jsonObject.lastName = this.lastName;
      jsonObject.email = this.email;
      jsonObject.mobilePhone = (this.mobilePhone === undefined ? 'undefined' : this.mobilePhone);
      jsonObject.preferredLocale = this.preferredLocale;
      jsonObject.postalAddress = (this.postalAddress === undefined ? 'undefined' : this.postalAddress.toJSON());
      jsonObject.themeCategoryId = this.themeCategoryId;
      jsonObject.themeId = this.themeId;
      jsonObject.themeMode = this.themeMode;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects for this class
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IUserSettingsAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IUserSettingsAsJson, includeContainedObjects: boolean = true): IUserSettings {
    try {
      // create a new instance of this class
      let settingsObject: UserSettings = Object.create(UserSettings.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      settingsObject = super.fromJSONProtected(settingsObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.firstName !== undefined) {
        settingsObject.firstName = jsonObject.firstName;
      }

      if (jsonObject.lastName !== undefined) {
        settingsObject.lastName = jsonObject.lastName;
      }

      if (jsonObject.email !== undefined) {
        settingsObject.email = jsonObject.email;
      }

      if (jsonObject.mobilePhone !== undefined) {
        settingsObject.mobilePhone = (jsonObject.mobilePhone === 'undefined' ? undefined : jsonObject.mobilePhone);
      }

      if (jsonObject.preferredLocale !== undefined) {
        settingsObject.preferredLocale = jsonObject.preferredLocale;
      }

      if (jsonObject.postalAddress !== undefined) {
        settingsObject.postalAddress = (jsonObject.postalAddress === 'undefined' ? undefined : JsonConverter.fromJSON(PostalAddress, jsonObject.postalAddress, includeContainedObjects));
      }

      if (jsonObject.themeCategoryId !== undefined) {
        settingsObject.themeCategoryId = (jsonObject.themeCategoryId === 'undefined' ? 'general' : jsonObject.themeCategoryId);
      }

      if (jsonObject.themeId !== undefined) {
        settingsObject.themeId = (jsonObject.themeId === 'undefined' ? 'default' : jsonObject.themeId);
      }

      if (jsonObject.themeMode !== undefined) {
        settingsObject.themeMode = (jsonObject.themeMode === 'undefined' ? enumThemeMode.Light : enumThemeModeConvert.fromString(jsonObject.themeMode));
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // there are no contained objects to include
      }

      return settingsObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
