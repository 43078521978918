import React, { PropsWithChildren } from 'react';
import { useNavigate } from "react-router-dom";
import { Fab as FloatingActionButton } from '@mui/material';
import { 
  School as TrainingIcon, 
  VideoCameraFront as VideoCameraFrontIcon 
} from '@mui/icons-material';
import { styled } from '@mui/system';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled FloatingActionButton object to represent the button
const StyledFloatingActionButton = styled((props) => (
  <FloatingActionButton
    {...props}
  />
))(({ theme }) => ({
  // MuiFab theming isn't responding properly, so directly theme the hover properties
  '&:hover': {
    background: theme.floatingActionButton.hover.background,
    border: theme.floatingActionButton.hover.border,
    color: theme.floatingActionButton.hover.color,
  },

  [theme.breakpoints.up('xs')]: {
    transform: 'scale(.80) translate(-20%)',
  },
  [theme.breakpoints.up('sm')]: {
    transform: 'scale(.85) translate(-15%)',
  },
  [theme.breakpoints.up('md')]: {
    transform: 'scale(.90) translate(-10%)',
  },
  [theme.breakpoints.up('lg')]: {
    transform: 'scale(.95) translate(-5%)',
  },
  [theme.breakpoints.up('xl')]: {
    transform: 'scale(1.0)',
  },
}));

/**
 * @interface ITrainingVideosButtonProps declares any input properties required for this component.
 */
export interface ITrainingVideosButtonProps extends PropsWithChildren<unknown> {
  // Url for navigating when the button is clicked
  navigationUrl: string;
}

/**
 * @function TrainingVideosButton is a React functional component representing a button that will appear on a page and its only 
 *           action is to navigate to a given Url if pressed.
 *           (NOTE: Appearance styling for the Fab (FloatingActionButton), but not sizing, is addressed in the global theme.)
 * @param {} props are the properties passed into the component.
 */
export const TrainingVideosButton: React.FC<ITrainingVideosButtonProps> = (props: ITrainingVideosButtonProps) => {
  TrainingVideosButton.displayName = 'Page Back Button';

  const { navigationUrl } = props;

  const navigate = useNavigate();

  return (
    <>
      <StyledFloatingActionButton onClick={() => navigate(navigationUrl)}>
        <TrainingIcon />
      </StyledFloatingActionButton>
    </>
  )
}
