import React, { useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { enumWorkflowState, enumObjectPersistenceState, enumPersistableObjectType, enumImageLinkType, enumBeaconType } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { typeUniqueId } from '../../../dataObjects/types';
import { IImageLink, ImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { IUser } from '../../../dataObjects/models/users/User';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import ImageLinkForm from '../../forms/ImageLinkForm/ImageLinkForm';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { ISaveImageLinkRequest } from '../../../dataObjects/models/serviceRequests/imageLink/SaveImageLinkRequest';
import { ISaveImageLinkWorkflowState } from '../../../uiMiddleware-redux/store/SaveImageWorkflowState';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { imageLinkSaveStatusClear, imageLinkSaveWithFileUploadRequest, imageLinkSaveWithoutFileUploadRequest, imageLinkSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/imageLink/imageLinkSaveStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';

/**
 * @interface IManageImageLinkPage Input properties for the ManageImageLinkPage
 */
export interface IManageImageLinkPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the ImageLink already exists
  parentId?: typeUniqueId  // if the parentId is given (parentId of the ImageLink parent object), it means that a new ImageLink is to be created
}

/**
 * @function ManageImageLinkPage A React Function Component for manageing (creating or editing) a ImageLink.
 * @param {IManageImageLinkPageProps} props Input properties
 */
const ManageImageLinkPage: React.FC<IManageImageLinkPageProps> = (props: IManageImageLinkPageProps) => {

  ManageImageLinkPage.displayName = "Manage Image Link Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [imageLinkId, setImageLinkId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the imageLinkId anytime the params.id changes
  useEffect(() => {
    setImageLinkId(params.id);
  }, [params.id]);

  // define an effect based on a change to the imageLinkId. Having a defined imageLinkId means that we will 
  // be editing an existing ImageLink
  useEffect(() => {
    if (imageLinkId !== undefined) {
      // try to find an ImageLink object with the Id given in the URL
      getSingleObjectById(imageLinkId, enumPersistableObjectType.ImageLink, onImageLinkFetched);
    }
  }, [imageLinkId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [imageLinkParentId, setImageLinkParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the imageLinkParentId anytime the params.parentId changes
  useEffect(() => {
    setImageLinkParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the imageLink to be managed, either a new imageLink or an existing one
  const [imageLink, setImageLink] = useState<IImageLink | undefined>(undefined);

  // whether we are going to be managing a new imageLink
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveImageLinkStatus: () => void = useCallback(() => {
    dispatch(imageLinkSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence (mounts) and goes out of existence (unmounts)
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveImageLinkStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveImageLinkStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveImageLinkStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving an ImageLink
  const [saveImageWorkflowState, setSaveImageWorkflowState] = useState<ISaveImageLinkWorkflowState | null | undefined>(undefined);

  // indicates whether the page has been successfully completed (for saving new object or updating existing)
  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the imageLink save status store state variable
      dispatch(imageLinkSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // the success message for ImageLink will merely be the defined constant
      const successMessage: string = MessagesStringAssets.imageLink_SaveSuccess;
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveImageLink, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, navigate]);

  // get the Redux workflow state for saving an ImageLink
  const currentSaveImageLinkWorkflowState: ISaveImageLinkWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveImageLinkStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveImageWorkflowState(currentSaveImageLinkWorkflowState);
  }, [currentSaveImageLinkWorkflowState]);

  /**
   * @function prepareNewImageLink Prepares/creates a new imageLink that will be used if user is requesting to create/add a new imageLink.
   * @param parentChannel 
   */
  const prepareNewImageLink: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => IImageLink = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newImageLink: IImageLink;

      const newImageLinkId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newImageLink = new ImageLink(user.id, newImageLinkId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        enumImageLinkType.GoogleCloudStorage, '', '', '', '', userPersistenceData);

      return newImageLink;
    }, []
  );

  // define an effect based on a change to the imageLinkParentId & currentUser. Having a defined imageLinkParentId means that we will
  // be creating a new ImageLink
  useEffect(() => {
    if (imageLinkParentId !== undefined && currentUser) {
      setImageLink(prepareNewImageLink(imageLinkParentId, currentUser, currentUser.userSettings));
    }
  }, [imageLinkParentId, currentUser, prepareNewImageLink]);


  function onImageLinkFetched(imageLink: IImageLink | undefined): void {
    if (imageLink !== undefined) {
      // set the returned ImageLink to be the one we are editing
      setImageLink(imageLink);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param {ISaveImageLinkRequest} saveImageLinkRequest A Submit event
   */
  function handleFormSubmit(saveImageLinkRequest: ISaveImageLinkRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an appropriate action to request saving the imageLink
        // if there is a file to be uploaded, dispatch an action that includes a file upload; 
        // otherwise, merely dispatch an action to save the imageLink only
        if (saveImageLinkRequest.fileUploadRequest !== undefined) {
          dispatch(imageLinkSaveWithFileUploadRequest(saveImageLinkRequest));
        } else {
          dispatch(imageLinkSaveWithoutFileUploadRequest(saveImageLinkRequest));
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold a progress message
  let progressMessage: string | undefined = undefined;

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;

  if (saveImageWorkflowState !== null && saveImageWorkflowState !== undefined) {

    if (saveImageWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveImageWorkflowState.workflowState === enumWorkflowState.InProgress || saveImageWorkflowState.workflowState === enumWorkflowState.Requested) {
      // get message from the workflow state to display as a progress message
      progressMessage = saveImageWorkflowState.workflowStateMessage ?? undefined;

      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveImageWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveImageWorkflowState.workflowError && saveImageWorkflowState.workflowError instanceof Error) {
        failureMessage = saveImageWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }


  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageImageLink_New : PageAndViewTitleStringAssets.pageTitle_ManageImageLink_Existing}
        onCloseAlert={resetSaveImageLinkStatus}
      >
        {imageLink && currentUser &&
          <ImageLinkForm
            userId={currentUser.id}
            imageLink={imageLink}
            saveRequestInProgress={saveInProgress}
            progressMessage={progressMessage}
            onSubmit={handleFormSubmit}
          />
        }

      </GenericPageContainer>
    </>
  );

} // const ManageImageLinkUploadPage...

export default ManageImageLinkPage;
