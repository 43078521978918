import React, { PropsWithChildren } from 'react';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Close as DeclineIcon,
  DoneOutline as AcceptanceIcon,
} from '@mui/icons-material';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import { ISharingRequestViewModel } from '../../../../dataObjects/viewModels/sharingRequestViewModel';
import { enumSharableObjectType } from '../../../../dataObjects/enums';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';

export interface ISharingRequestsCardGridViewItemProps extends PropsWithChildren<unknown> {
  sharingRequestViewModel: ISharingRequestViewModel;
  onAccept: (sharingRequestViewModel: ISharingRequestViewModel) => void;
  onDecline: (sharingRequestViewModel: ISharingRequestViewModel) => void;
}


export const SharingRequestsCardGridViewItem: React.FC<ISharingRequestsCardGridViewItemProps> = (props: ISharingRequestsCardGridViewItemProps) => {
  SharingRequestsCardGridViewItem.displayName = 'SharingRequests Card Grid View Item';

  const { sharingRequestViewModel, onAccept, onDecline } = props;
  const { sharingRequest, sharingRequestObject } = sharingRequestViewModel;
  const { sharingObjectType } = sharingRequest;

  // set the name and description to display, based on the sharingObjectType
  let name: string = '';
  let description: string = '';
  switch (sharingObjectType) {
    case enumSharableObjectType.Channel:
      name = sharingRequestObject.name;
      description = sharingRequestObject.description;
      break;

    default:
      throw new MdbError('unknown sharingObjectType', enumMdbErrorType.InvalidOperation);
  }

  const classes = cardGridViewItemStyles();

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {name}
        </Typography>

        <CardContent>
          <Typography className={classes.cardDescription} variant="body1">
            {description}
          </Typography>

        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip title="Accept" arrow>
            <IconButton
              className={`${classes.actionIconButton} ${classes.editIconButton}`}
              size="small"
              aria-label="accept"
              onClick={() => sharingRequestViewModel && onAccept(sharingRequestViewModel)}
            >
              <AcceptanceIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title="Decline"
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="decline"
                onClick={() => sharingRequestViewModel && onDecline(sharingRequestViewModel)} >
                <DeclineIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}