import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: enumWorkflowState | null = null;

export const userUpdateEmailAddressStatusSlice = createSlice({
  name: 'userUpdateEmailAddressStatus',
  initialState,
  reducers: {
    // action has a payload with the new enumWorkflowState value or null
    userUpdateEmailAddressStatusChange(state, action) {
      // change status of expunge process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userUpdateEmailAddressStatusChange } = userUpdateEmailAddressStatusSlice.actions;

// export the core reducer for the slice
export const userUpdateEmailAddressStatusReducer = userUpdateEmailAddressStatusSlice.reducer;

export default userUpdateEmailAddressStatusSlice.reducer;