import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';

/**
 * @function getBackendVersionViaHttp Requests the version of the firebase functions backend code (via Http)
 * @returns void
 */
export function getBackendVersionViaHttp(): Promise<string> {
  return new Promise<string>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c Entered getBackendVersionViaHttp()', 'background: #055; color: #fff');

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const getBackendVersion =
        httpsCallable<void, string>(functions, 'getBackendVersion');
        getBackendVersion()
        .then((result) => {

          displayConsoleLogs && console.log(`%c getBackendVersionViaHttp(). Returned from getBackendVersion() Http call. result: ${JSON.stringify(result)}`, 'background: #055; color: #fff');

          // resolve();
          resolve(result.data);
        });
    } catch (error: any) {
      alert(`Error. getBackendVersionViaHttp failed: (${error})`);
      reject(error);
    }
  });
}
