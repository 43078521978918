import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';

/**
 * @function pingSearchMetadataDbViaHttp Requests to ping the SearchMetadata database (via Http)
 * @returns void
 */
// export function pingSearchMetadataDbViaHttp(): Promise<boolean> {
//   return new Promise<boolean>(async (resolve, reject) => {
export function pingSearchMetadataDbViaHttp(): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c Entered pingSearchMetadataDbViaHttp()', 'background: #055; color: #fff');

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const pingSearchMetadataDb =
        httpsCallable<void, boolean>(functions, 'pingSearchMetadataDb');
        pingSearchMetadataDb()
        .then((result) => {

          displayConsoleLogs && console.log(`%c pingSearchMetadataDbViaHttp(). Returned from pingSearchMetadataDb() Http call. result: ${JSON.stringify(result)}`, 'background: #055; color: #fff');

          // resolve();
          resolve(result.data);
        });
    } catch (error: any) {
      alert(`Error. pingSearchMetadataDbViaHttp failed: (${error})`);
      reject(error);
    }
  });
}
