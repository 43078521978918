import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchThemeSpecsStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchThemeSpecsStatusSlice = createSlice({
  name: 'fetchThemeSpecsStatus',
  initialState,
  reducers: {
    setFetchThemeSpecsStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchThemeSpecsStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchThemeSpecsStatus, clearFetchThemeSpecsStatus } = fetchThemeSpecsStatusSlice.actions;

// export the core reducer for the slice
export const fetchThemeSpecsStatusReducer = fetchThemeSpecsStatusSlice.reducer;

export default fetchThemeSpecsStatusSlice.reducer;