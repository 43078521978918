import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessagesStringAssets } from '../../assets/stringAssets';
import { enumBeaconType, enumWorkflowState } from '../../dataObjects/enums';
import { Beacon } from '../../dataObjects/models/alerts/Beacon';
import { enumAlertType } from '../enums';
import { AlertInfo } from '../../dataObjects/models/alerts/AlertInfo';
import { composeMessageUsingStringAsset } from '../messages';
import { IProcessPerManageObjectPageStatusInput } from './hookInputObjects/ProcessPerManageObjectPage/IProcessPerManageObjectPageStatusInput';
import { IProcessPerManageObjectPageStatusResult } from './hookReturnObjects/ProcessPerManageObjectPageStatusResult';
import { IMdbError } from '../../errorObjects/MdbError';
import { useReduxWorkflowState } from '.';
import { useAppDispatch } from '../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';

/**
 * @function useProcessPerManageObjectPageStatus A custom hook that handles the processing of status for an object within a ManageXXXPage.
 * @param {IProcessPerManageObjectPageStatusInput} processPerManageObjectPageStatusInput A collection of properties that are page-specific for a ManageXXXPage.
 */
export function useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput): IProcessPerManageObjectPageStatusResult {

  const {
    workflowStateObj,
    failureErrorStateObj,
    notificationFailureMessageTemplate,
    notificationSuccessTitle,
    notificationSuccessMessage
  } = processPerManageObjectPageStatusInput;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // whether an operation is currently in progress
  const [operationInProgress, setOperationInProgress] = useState<boolean>(false);

  // the status of the operation
  const [operationStatus, setOperationStatus] = useState<enumWorkflowState | null | undefined>(null);

  // the failure error from the operation
  const [operationFailureError, setOperationFailureError] = useState<IMdbError | null | undefined>(null);

  // use the useReduxWorkflowState custom hook to obtain the redux state for the operationXXX (object) workflow and any associated error
  // const { workflowState: operationObjectStatus, errorState: operationObjectFailure } = useReduxWorkflowState(workflowStateObj, failureErrorStateObj);
  const { workflowState: operationObjectStatus, errorState: operationObjectFailure } = useReduxWorkflowState(workflowStateObj, failureErrorStateObj);

  // if the operation status in Redux state is different from our local state, set our local state
  if (operationStatus !== operationObjectStatus) {
    setOperationStatus(operationObjectStatus);
  }

  // if the operation failure error in Redux state is different from our local state, set our local state
  if (operationObjectStatus === null && operationFailureError !== null) {
    setOperationFailureError(null);
  } else if (operationFailureError !== operationObjectFailure) {
    setOperationFailureError(operationObjectFailure);
  }

  // indicates whether the operation process has been successfully completed 
  const [successfullyCompletedOperationProcess, setSuccessfullyCompletedOperationProcess] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedOperationProcess state changes
  useEffect(() => {
    if (successfullyCompletedOperationProcess) {
      // return to previous page
      navigate(-1);

      // notify user via a Beacon notification that the operation has been completed
      // dispatch(setBeacon(new Beacon(undefined, enumBeaconType.Success, notificationSuccessTitle, notificationSuccessMessage)));
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, notificationSuccessTitle, notificationSuccessMessage)));
    }
  }, [successfullyCompletedOperationProcess, dispatch, navigate, notificationSuccessTitle, notificationSuccessMessage]);


  /**
   * @function processSuccessOperationStatus A function (memoized with the useCallback hook) to be called when the operation status has become "Success"
   */
  const processSuccessOperationStatus = useCallback(
    () => {
      // set the operationInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (operationInProgress) {
        setOperationInProgress(false);
      }

      // set flag to indicate that the operation process has been successfully completed, which will force a re-render to allow for finalizing work for the page
      setSuccessfullyCompletedOperationProcess(true);
    },
    [operationInProgress],
  );

  /**
   * @function processInProgressSaveStatus A function (memoized with the useCallback hook) to be called when the operation status has become "InProgress"
   */
  const processInProgressOperationStatus = useCallback(
    () => {
      // set the operationInProgress state to 'true' if it's not already in that state (don't want an extra render if not necessary)
      if (!operationInProgress) {
        setOperationInProgress(true);
      }
    },
    [operationInProgress],
  );


  /**
   * @function processFailureOperationStatus A function (memoized with the useCallback hook) to be called when the operation status has become "Failure"
   */
  const processFailureOperationStatus = useCallback(
    () => {
      const operationFailureMessage: string = composeMessageUsingStringAsset(notificationFailureMessageTemplate, operationFailureError ? operationFailureError.message : '', MessagesStringAssets.substitutionKeyword);
      // dispatch(setAlertInfo(new AlertInfo(true, enumAlertType.Error, operationFailureMessage)));
      dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Error, operationFailureMessage)));

      // set the operationInProgress state to 'false' if it's not already in that state (don't want an extra render if not necessary)
      if (operationInProgress) {
        setOperationInProgress(false);
      }
    },
    [dispatch, operationInProgress, operationFailureError, notificationFailureMessageTemplate],
  );

  /**
   * @function processPerOperationStatus A function (memoized with the useCallback hook) that is called to process the status of an object operation, 
   *                                per different status options (Requested, InProgress, Falure, Success).
   */
  const processPerOperationStatus = useCallback(
    () => {
      if (operationStatus) {
        switch (operationStatus) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
            processInProgressOperationStatus();
            break;

          case enumWorkflowState.Success:
            processSuccessOperationStatus();
            break;

          case enumWorkflowState.Failure:
            processFailureOperationStatus();
            break;
        }
      }
    },
    [operationStatus, processInProgressOperationStatus, processSuccessOperationStatus, processFailureOperationStatus],
  );

  // whenever the 'operationStatus' value changes, perform processing per that value
  useEffect(() => {
    processPerOperationStatus();
  }, [operationStatus, processPerOperationStatus]);


  // prepare the return value from this custom hook
  const processPerManageObjectPageStatusResult: IProcessPerManageObjectPageStatusResult = {
    operationInProgress,
    // alertInfo,
  }

  return processPerManageObjectPageStatusResult;
}
