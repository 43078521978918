import { take, put } from 'redux-saga/effects';
import { logoutStatusChange } from '../../slices/logout/logoutSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { loginDataClear } from '../../slices/login/loginDataSlice';

/**
 * @function logoutSuccessSaga A saga workflow pertaining to the user successfully logging out of the application.
 */
export function* logoutSuccessSaga() {
  // console.info('(logoutSuccessSaga) entered logoutSuccessSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the actionTypeEnums.LOGOUT_SUCCESS action to be dispatched
    const logoutStatusChangeData = yield take(logoutStatusChange);
    const { payload: logoutStatusAsAny } = logoutStatusChangeData;
    const logoutStatus: enumWorkflowState = logoutStatusAsAny as enumWorkflowState;

    // if the status change was to indicate a successful logout...
    if (logoutStatus === enumWorkflowState.Success) {

      // dispatch an action to reset logout status to 'null'
      yield put(logoutStatusChange(null));

      // dispatch an action to reset login data to 'null'
      yield put(loginDataClear(null));

      // // dispatch to redirect the browser to the Login page route
      // yield put(push("/login"));
    }
  }
}
