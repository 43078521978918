import { IThemeBaseColors, ThemeBaseColors } from "../../../dataObjects/models/themes/ThemeBaseColors";
import { IThemeSpecsViewModel, ThemeSpecsViewModel } from "../../../dataObjects/viewModels/themeSpecsViewModel";

/**
 * @function createDefaultThemeSpecsViewModel Creates a default IThemeSpecsViewModel for the application
 * @returns {IThemeSpecsViewModel} An IThemeSpecsViewModel object
 */
export function createDefaultThemeSpecsViewModel(): IThemeSpecsViewModel {

  const themeBaseColorsForViewModel: IThemeBaseColors = new ThemeBaseColors(
    "#0000aa", // baseColorForPrimary
    "#3030ff", // baseColorForSecondary
    "#dd0800", // baseColorForCancel
    "#f44336", // baseColorForError
    "#ffc107", // baseColorForHighlight
    "#5f73d3", // baseColorForInfo
    "#12A561", // baseColorForSuccess
    "#ffeb3b"  // baseColorForWarning
  );

  const defaultThemeSpecsViewModel: IThemeSpecsViewModel = new ThemeSpecsViewModel('default', 'default', themeBaseColorsForViewModel, 'default');

  return defaultThemeSpecsViewModel;
}
