import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { KeyValuePairsStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { IObjectSharingRequestTracker } from '../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingRequestCardGridViewItemProps extends PropsWithChildren<unknown> {
  channelSharingRequest: IObjectSharingRequestTracker;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (channelSharingRequest: IObjectSharingRequestTracker) => void;
}


export const ChannelSharingRequestCardGridViewItem: React.FC<IChannelSharingRequestCardGridViewItemProps> = (props: IChannelSharingRequestCardGridViewItemProps) => {

  // const { channelSharingRequest, currentUserChannelSharingViewModel, currentUser, channel, onRevoke } = props;
  const { channelSharingRequest, currentUserChannelSharingViewModel, onRevoke } = props;

  const classes = cardGridViewItemStyles();

  // will hold setting indicating whether the currentUser has Admin privilege to the channel
  const [currentUserHasAdminAccessToChannel, setCurrentUserHasAdminAccessToChannel] = useState<boolean>(false);

  // define an effect that will set whether the currentUser has Admin privilege to the channel
  useEffect(() => {
    setCurrentUserHasAdminAccessToChannel(currentUserChannelSharingViewModel.currentUserChannelSharingPermission === enumSharingPermission.Admin);
  }, [currentUserChannelSharingViewModel]);

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>

        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {channelSharingRequest.recipientEmail}
        </Typography>

        <CardContent>
          <Typography className={classes.cardDescription} variant="body1">
            {channelSharingRequest.recipientName}
          </Typography>
          <Typography className={classes.cardDescription} variant="body1">
            {`Permission: ${KeyValuePairsStringAssets.getValueFromKey(KeyValuePairsStringAssets.sharingPermissionKeyValuePairs, channelSharingRequest.sharingPermission)}`}
          </Typography>
        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit sharing" (if it's not the owner of the channel) or "Edit disallowed" 
            // (if it IS the owner of the channel)
            title={currentUserHasAdminAccessToChannel ? TooltipStringAssets.channelSharingRequest_EditRequest : TooltipStringAssets.channelSharingRequest_EditDisallowedForInsufficientPermissions}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!currentUserHasAdminAccessToChannel}
                onClick={() => navigate(`/channelSharingRequest/${channelSharingRequest.id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            // the tooltip for the revoke button will either be "Revoke sharing" (if the current user is the owner of the channel -OR- the 
            // current user has Admin permission to the channel) or "Revoke disallowed" // (if the current user isn't the owner of 
            // the channel -AND- doesn't have Admin permission to the channel)
            title={currentUserHasAdminAccessToChannel ? TooltipStringAssets.channelSharingRequest_RevokeSharing : TooltipStringAssets.channelSharingRequest_RevokeDisallowedForInsufficientPermissions}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="revoke"
                // enable only if the current user has Admin permission for this Channel
                disabled={!currentUserHasAdminAccessToChannel}
                onClick={() => channelSharingRequest && onRevoke(channelSharingRequest)} >
                <ClearIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>

      </Card>
    </>
  );

}