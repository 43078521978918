import { take, put } from 'redux-saga/effects';
import { saveObjectSharingRequestTracker } from '../../../firebaseServices/dataServices/dataServiceActions/collaborationActions';
import { sharingRequestAcceptanceRequest, sharingRequestAcceptanceStatusChange } from '../../slices/collaboration/sharingRequestAcceptanceStatusSlice';
import { enumSharingRequestStatus, enumWorkflowState } from '../../../dataObjects/enums';
import { sharingRequestAcceptanceFailureChange } from '../../slices/collaboration/sharingRequestAcceptanceFailureSlice';

/**
 * @function acceptSharingRequestSaga A saga workflow pertaining to a user having requested to accept a Sharing Request.
 */
export function* acceptSharingRequestSaga() {
  // console.info('(acceptSharingRequestSaga) entered acceptSharingRequestSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the sharingRequestAcceptanceRequest action to be dispatched
      const sharingRequestAcceptanceRequestData = yield take(sharingRequestAcceptanceRequest);
      const { payload: objectSharingRequestTracker } = sharingRequestAcceptanceRequestData;

      // capture whether the request is in a "New" state (if it is, we will set the status to "Requested", and then send email to the recipient)
      const requestIsNew: boolean = objectSharingRequestTracker.status === enumSharingRequestStatus.New;

      // the initial state to save for a new request is "Requested"
      if (requestIsNew) {
        objectSharingRequestTracker.status = enumSharingRequestStatus.Requested;
      }

      // make a request to save the objectSharingRequestTracker
      yield saveObjectSharingRequestTracker(objectSharingRequestTracker)

      // dispatch an action to indicate that the save operation was successful
      yield put(sharingRequestAcceptanceStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(sharingRequestAcceptanceStatusChange(enumWorkflowState.Failure));
      yield put(sharingRequestAcceptanceFailureChange(error));
    }
  }
}
