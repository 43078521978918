import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ISocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { ISaveSocialMediaPostRequest } from '../../../dataObjects/models/serviceRequests/socialMediaPost/SaveSocialMediaPostRequest';
import { ISaveSocialMediaPostWorkflowState } from '../../store/SaveSocialMediaPostWorkflowState';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface ISaveSocialMediaPostStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const socialMediaPostSaveStatusSlice = createSlice({
  name: 'socialMediaPostSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    socialMediaPostSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    socialMediaPostSaveRequest: (state, action: PayloadAction<ISaveSocialMediaPostRequest>) => {
      const saveSocialMediaPostRequest: ISaveSocialMediaPostRequest = action.payload;
      const newState: ISaveSocialMediaPostWorkflowState = {
        socialMediaPost: saveSocialMediaPostRequest.socialMediaPost,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.socialMediaPost_SaveRequested,
      }

      // call internal base function to set the newState into the IStoreState variable
      socialMediaPostSaveWorkflowStateChange(newState);
    },
    socialMediaPostSaveSuccess: (state, action: PayloadAction<ISocialMediaPost>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.socialMediaPost_SaveSuccess;

        const newState: ISaveSocialMediaPostWorkflowState = {
          ...(state as ISaveSocialMediaPostWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        socialMediaPostSaveWorkflowStateChange(newState);
      }
    },
    socialMediaPostSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.socialMediaPost_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveSocialMediaPostWorkflowState = {
          ...(state as ISaveSocialMediaPostWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        socialMediaPostSaveWorkflowStateChange(newState);
      }
    },
    socialMediaPostSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      socialMediaPostSaveWorkflowStateChange(null);
    },
  },
})

// Action creators are generated for each function in reducer)
export const {
  socialMediaPostSaveWorkflowStateChange,
  socialMediaPostSaveRequest,
  socialMediaPostSaveSuccess,
  socialMediaPostSaveError,
  socialMediaPostSaveStatusClear,
} = socialMediaPostSaveStatusSlice.actions;

// export the core reducer for the slice
export const socialMediaPostSaveStatusReducer = socialMediaPostSaveStatusSlice.reducer;

export default socialMediaPostSaveStatusSlice.reducer;