import { collection, CollectionReference, DocumentData, Firestore, onSnapshot, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames, enumFirestoreDataRepositoryDataType } from '../enums';
import { IFirestoreUserRepository } from '../FirestoreUserRepository';
import { FirestoreDataRepositoryFactory } from '../FirestoreDataRepositoryFactory';
import { IFirestoreBaseRepository } from '../FirestoreBaseRepository';
import { IUser, IUserAsJson } from '../../../../../../dataObjects/models/users/User';
import { enumObjectPersistenceState } from '../../../../../../dataObjects/enums';
import { IFirestoreChannelRepository_Ext } from '.';
import { enumMdbErrorType } from '../../../../../../errorObjects/enums';
import { MdbError } from '../../../../../../errorObjects/MdbError';
import { NumericConstants } from '../../../../../../assets/numericAssets';

/** 
 * @class FirestoreChannelRepository_Ext Provides extended functionality beyond the FirestoreChannelRepository class
 * *** IMPORTANT NOTE: FirestoreChannelRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestorePersistenceMetadataRepository is focused on other features.
 *   Therefore, 
 *     1) FirestoreChannelRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreChannelRepository_Ext.
 */
export class FirestoreChannelRepository_Ext implements IFirestoreChannelRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method getObjectsForIds_onSnapshot Makes 1 or more onSnapshot() requests to firestore for retrieving Channel objects from the database
   *   matching Ids passed in. Each onSnapshot request will deliver an unsubscribe callback function to be used for unsubscribing from 
   *   the onSnapshot request. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {Array<typeUniqueId>} ids The array of unique Ids associated with the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<Array<() => void>>} A collection of callbacks for unsubscribing from the firebase onSnapshots request.
   * @summary We can only request up to 30 objects at a time because Firestore allows up to 30 values within an 'in' clause. Therefore,
   *          if there are more than 30 ids provided, multiple onSnapshot requests will be made with each including up to 30 ids.
   */
  getObjectsForIds_onSnapshot(ids: Array<typeUniqueId>, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Array<() => void>> {
    return new Promise<Array<() => void>>(async (resolve, reject) => {
      try {
        // whether to display console logs (displayConsoleLogs && console.log statements)
        const displayConsoleLogs: boolean = false;

        displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. # of ids: ${ids.length}`, 'background: #660; color: #fff');
        displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. ids: ${ids}`, 'background: #660; color: #fff');
        displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. JSON.stringify(ids): ${JSON.stringify(ids)}`, 'background: #660; color: #fff');

        // Declare an empty array that will hold one or more unsubscribe callback function pointers
        const unsubscribeCallbacks: Array<() => void> = [];

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the Channel collection
        const imageLinkCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ChannelCollection);

        // There will be one or more queries, depending on the number of Ids provided and the size of each query group.
        // Firestore has a limit on the maximum number of search criteria that can be included as part of an "IN" clause, so
        // the queries will potentially have to be chunked.
        // Establish the size of each query group.
        const queryGroupSize: number = NumericConstants.maxCriteriaForAFirestoreInClause;

        // Loop to establish the number of groups and the Ids to use in each query, and then perform the query
        for (let idxGroup = 0; idxGroup < (ids.length / queryGroupSize); idxGroup++) {
          const startingIdIndexForGroup = 0 + (idxGroup * queryGroupSize);
          const endingIdIndexForGroup = startingIdIndexForGroup + Math.min(queryGroupSize, (ids.length - (idxGroup * queryGroupSize)));

          displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. For Group index (${idxGroup}), StartingIdIndex: ${startingIdIndexForGroup}; EndingIndex: ${endingIdIndexForGroup}`, 'background: #660; color: #fff');

          const idsInGroupQuery: Array<typeUniqueId> = ids.slice(startingIdIndexForGroup, endingIdIndexForGroup);

          displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. idsInGroupQuery ${idsInGroupQuery}`, 'background: #660; color: #fff');
          displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. JSON.stringify(idsInGroupQuery) ${JSON.stringify(idsInGroupQuery)}`, 'background: #660; color: #fff');

          displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. [...idsInGroupQuery] ${[...idsInGroupQuery]}`, 'background: #660; color: #fff');
          displayConsoleLogs && console.log(`%c FirestoreChannelRepository_Ext.getObjectsForIds_onSnapshot. JSON.stringify([...idsInGroupQuery]) ${JSON.stringify([...idsInGroupQuery])}`, 'background: #660; color: #fff');

          // Prepare a query that will get documents corresponding to the Ids provided.
          // Note: We don't need to worry about the state of the documents as SearchMetadata should only be present for documents 
          //       in either the 'New' or 'Modified' state. 
          const documentQuery: Query<DocumentData> =
            query(imageLinkCollection,
              where("id", "in", [...idsInGroupQuery])
            );

          // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
          // 'unsubscribe' callback function
          unsubscribeCallbacks.push(onSnapshot(documentQuery, callback));
        }

        // return the 'unsubscribeCallbacks' array with the Promise, via the 'resolve'
        resolve(unsubscribeCallbacks);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }


  /*-----------------------------------------------*/
  /**
   * @method getChannelsForUser_onSnapshot Makes an onSnapshot() request to firestore for retrieving all Channels from the database associated with 
   *   a given User. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {typeUniqueId} userId The unique Id of the User of the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is able to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getChannelsForUser_onSnapshot(userId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };
        // console.log(`FirestoreChannelRepository:getChannelsForUser_onSnapshot before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // get a User repository from the repository factory
        const firestoreUserRepository: IFirestoreUserRepository =
          FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
          IFirestoreBaseRepository<IUser, IUserAsJson>;

        const user: IUser | undefined = await firestoreUserRepository.get(userId);

        if (user === undefined) {
          throw new MdbError(`Unable to find a User with the given Id: ${userId}`, enumMdbErrorType.RequiredDataNotFound);
        }
        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the Channel collection
        const channelCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ChannelCollection);

        // prepare a query that will get all Channel documents where the userId appears in the Channel's sharedUsers array -and- the Channel
        // is in either the 'New' or 'Modified' state
        const documentQuery: Query<DocumentData> =
          query(channelCollection,
            where("sharedUsers", "array-contains", userId),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);
        // await this.sleep(2000);
        // console.log(`FirestoreChannelRepository:getChannelsForUser_onSnapshot after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // return the 'unsubscribeCallback' with the Promise, via the 'resolve'
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }

}
