import { IFirestoreAudioLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreAudioLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IAudioLink, IAudioLinkAsJson } from "../../../../dataObjects/models/digitalMedia/AudioLink";


export function deleteAudioLink(audioLink: IAudioLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a AudioLink repository from the repository factory
      const firestoreAudioLinkRepository: IFirestoreAudioLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.AudioLink) as
        IFirestoreBaseRepository<IAudioLink, IAudioLinkAsJson>;

      // call the repository delete() method to delete the object
      await firestoreAudioLinkRepository.delete(audioLink.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}