import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { VersionAwarePersistable } from "../../persistence/VersionAwarePersistable";
import { IUserSettings, UserSettings } from "../UserSettings";
import { IUser, IUserAsJson } from ".";
import { JsonConverter } from "../../../utilities/JsonConverter";
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { Channel } from '../../channels/Channel';

/** 
 * @class User Represents a User in the application, along with associated information about the user
 */
export class User extends VersionAwarePersistable implements IUser {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {IUserSettings} userSettings Settings and preferences for the user
   */
  /**
   * @method Constructor method
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userPersistenceData?: IUserPersistenceData,
    userSettings?: IUserSettings
  ) {
    super(ownerId, enumPersistableObjectClassName.User, enumPersistableObjectType.User, id, parentObjectType, parentId, objectState, userPersistenceData);

    if (userSettings !== undefined) {
      this.userSettings = userSettings;
    }

    // associate the user with the "Private Channel" (Private) that will be associated with this user. The Channel class has a static method to define an Id relative to the 
    // user's id
    this.associateUserWithChannel(Channel.userPrivateChannelIdFromUserId(this.id));
  }

  /*-----------------------------------------------*/
  /**
   * @property {IUserSettings} _userSettings Settings and preferences for the user
   */
  private _userSettings: IUserSettings = new UserSettings(this.ownerId, RandomId.newId(), this.objectType, this.id, this.objectState);

  /**
   * @method userSettings is an optional getter method for _userSettings
   */
  get userSettings() {
    return this._userSettings;
  }

  /**
   * @method userSettings is an optional setter method for _userSettings
   * @param {IUserSettings} value The userSettings value to be used to set _userSettings
   */
  set userSettings(value: IUserSettings) {
    // ensure that the userSettings object has the correct 'parentObject', 'parentObjectType' and 'parentId'
    value.parentObject = this;
    value.parentObjectType = this.objectType;
    value.parentId = this.id;
    this._userSettings = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<typeUniqueId>} _channels Channels of which the user is a member, including the user's default private channel
   */
  private _channels: Array<typeUniqueId> = new Array<typeUniqueId>();

  /**
   * @method channels Getter method for the user's channel associations
   */
  get channels() {
    return this._channels;
  }

  /**
   * @method channels Setter method for the user's channel associations
   * @param {Array<typeUniqueId>} value is the input value for setting _channels
   */
  set channels(value: Array<typeUniqueId>) {
    this._channels = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} sa Whether user is a Super Admin user
   */
  private _sa: boolean = false;

  /**
   * @method sa Getter method for the sa property
   */
  get sa(): boolean {
    return this._sa;
  }

  /**
   * @method sa Setter method for the sa property
   * @param {boolean} value is the input value for setting _sa
   */
  set sa(value: boolean) {
    this._sa = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method associateUserWithChannel Associates the user with the channel indicated by the 'channelId' parameter
   * @param {typeUniqueId} channelId The Id of the channel with which to associate the user.
   */
  associateUserWithChannel(channelId: typeUniqueId): boolean {
    try {
      let userAssocaitedWithChannel = false;

      // checke whether the user is already associated with the channel
      const existingChannelId = this.channels.find((ugId: string) => ugId === channelId);

      // if the user isn't yet associated with the channel
      if (existingChannelId === undefined) {
        // use the spread operator to construct a new array that includes all 
        // existing associations, plus the new one
        this.channels = [...this.channels, channelId];
      }

      // indicate that the user is associated with the channel, regardless of whether we 
      // associated here or the user was already associated
      userAssocaitedWithChannel = true;

      return userAssocaitedWithChannel;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method disassociateUserFromChannel Disassociates the user from the channel indicated by the 'channelId' parameter
   * @param {typeUniqueId} channelId The Id of the channel from which to associate the user.
   */
  disassociateUserFromChannel(channelId: typeUniqueId): boolean {
    try {
      let userDisassocaitedFromChannel = false;

      // On allow disassociation if the channelId is NOT this user's Id, since a user cannot be disassociated with the user's own private channel
      if (channelId !== this.id) {

        // check whether the user is associated with the channel
        const existingChannelId = this.channels.find((ugId: string) => ugId === channelId);

        // if the user is associated with the channel
        if (existingChannelId !== undefined) {
          // use the filter operation to filter out just the channelId from which the 
          // user is to be disassociated
          this.channels = this.channels.filter(ugId => ugId !== channelId);
        }

        // indicate that the user is disassociated from the channel, regardless of whether we 
        // disassociated here or the user was not associated when the method was called
        userDisassocaitedFromChannel = true;
      }

      return userDisassocaitedFromChannel;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUser} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUser {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUser = Object.create(User.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    if (this.userSettings !== undefined) {
      copyOfObject.userSettings = Object.create(UserSettings.prototype);
      copyOfObject.userSettings = this.userSettings.copy();
    }

    copyOfObject.channels = [...this.channels];

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IUserAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IUserAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      // jsonObject.userId = this.userId;
      jsonObject.sa = this.sa;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        jsonObject.userSettings = this.userSettings.toJSON(includeContainedObjects);
        jsonObject.channels = this.channels;
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IUserAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IUserAsJson, includeContainedObjects: boolean = true): IUser {
    try {
      // create a new instance of this class
      let userObject: User = Object.create(User.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      userObject = super.fromJSONProtected(userObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      // if (jsonObject.userId) {
      //   userObject.userId = jsonObject.userId;
      // }

      userObject.sa = jsonObject.sa ?? false;

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.userSettings) {
          userObject.userSettings = JsonConverter.fromJSON(UserSettings, jsonObject.userSettings, includeContainedObjects);
        }

        if (jsonObject.channels) {
          userObject.channels = jsonObject.channels;
        }
      }

      return userObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
