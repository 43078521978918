import { FileError, FileRejection } from "react-dropzone";

/**
 * @function useErrorMessageForDropZoneRejections Custom hook to construct a comprehensive error message from React DropZone FileRejections
 * @param {FileRejection[]} fileRejections Array of file rejection information
 */
export function constructDropZoneFileRejectionsErrorMessage(fileRejections: FileRejection[]): string {

  // start with an empty string
  let errorMessage: string = '';

  // if there are any file rejections...
  if (fileRejections.length > 0) {

    // iterate each file rejection to construct a comprehensive error message
    fileRejections.forEach((fileRejection: FileRejection, idxFileRejections: number) => {

      // if there are multiple file rejections, pre-pend a file rejection index number (1-based) into the error message
      if (fileRejections.length > 1) {
        errorMessage += `${idxFileRejections + 1}) `;
      }

      fileRejection.errors.forEach((error: FileError, idxErrors: number) => {
        // add the message from the error to the overall error message being constructed 
        errorMessage += error.message + ' (' + fileRejection.file.name + ')';

        // if this is not the last error in the list, separate with semi-colon
        if (idxErrors !== fileRejection.errors.length - 1) {
          errorMessage += '; ';
        }
      });
    });
  }

  return errorMessage;
}