import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAlertInfo, IAlertInfoAsJson } from '../../../dataObjects/models/alerts/AlertInfo';

export interface IAlertInfoState {
  value: IAlertInfo | IAlertInfoAsJson | null,
}

const initialState: IAlertInfo | IAlertInfoAsJson | null = null;

export const alertInfoSlice = createSlice({
  name: 'alertInfo',
  initialState,
  reducers: {
    // action has a payload with the new AlertInfo object, or null
    alertInfoChange(state, action) {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { alertInfoChange } = alertInfoSlice.actions;

// export the core reducer for the slice
export const alertInfoReducer = alertInfoSlice.reducer;

export default alertInfoSlice.reducer;
