import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { IHyperLink } from '../../../dataObjects/models/digitalMedia/HyperLink';
import { ISaveHyperLinkRequest } from '../../../dataObjects/models/serviceRequests/hyperLink/SaveHyperLinkRequest';
import { RegularExpressions } from '../../../assets/regularExpressionAssets';

interface IHyperLinkFormValues {
  title: string;
  hyperLinkUrl: string;
  description: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  title: yup
    .string()
    .required(ControlsStringAssets.hyperLinkTitleRequired),
  // hyperLinkUrl: yup
  //   .string()
  //   .url(ControlsStringAssets.hyperLinkHyperLinkUrlInvalid)
  //   .required(ControlsStringAssets.hyperLinkHyperLinkUrlRequired),
  hyperLinkUrl: yup
    .string()
    .matches(RegularExpressions.GenericUrl, ControlsStringAssets.hyperLinkHyperLinkUrlInvalid)
    .required(ControlsStringAssets.hyperLinkHyperLinkUrlRequired),
  description: yup
    .string()
    .required(ControlsStringAssets.hyperLinkDescriptionRequired),
});

export interface IHyperLinkFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IHyperLink} hyperLink The HyperLink details for the form (will have blank properties values if we're creating a new record)
   */
  hyperLink: IHyperLink,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(hyperLink: IHyperLink) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (hyperLink: ISaveHyperLinkRequest) => Promise<void>,
}

const HyperLinkForm: React.FC<IHyperLinkFormProps> = (props: IHyperLinkFormProps) => {
  HyperLinkForm.displayName = 'HyperLink Form';

  // get required arguments from props
  const { hyperLink, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<IHyperLinkFormValues>({
    defaultValues: {
      title: hyperLink.title,
      hyperLinkUrl: hyperLink.hyperLinkUrl,
      description: hyperLink.description
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: titleReg, ...titleProps } = register("title", { required: true });
  const { ref: hyperLinkUrlReg, ...hyperLinkUrlProps } = register("hyperLinkUrl", { required: true });
  const { ref: descriptionReg, ...descriptionProps } = register("description", { required: true });

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);

  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IHyperLinkFormValues) => {

    setSaveInProgress(true);

    // fill in title & description of the HyperLink object passed in
    hyperLink.title = data.title;
    hyperLink.hyperLinkUrl = data.hyperLinkUrl;
    hyperLink.description = data.description;

    // prepare a request for saving, starting with just the videoLink
    let saveHyperLinkRequest: ISaveHyperLinkRequest = {
      hyperLink: hyperLink
    };

    // call the onSubmit handler passed in, supplying the HyperLink object
    await onSubmit(saveHyperLinkRequest);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.hyperLink_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={titleReg}
          {...titleProps}
          name="title"
          autoFocus
          label={ControlsStringAssets.hyperLinkTitleLabel}
          margin='normal'
          fullWidth
          error={!!errors.title}
          helperText={errors?.title?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={hyperLinkUrlReg}
          {...hyperLinkUrlProps}
          label={ControlsStringAssets.hyperLinkHyperLinkUrlLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.hyperLinkUrl}
          helperText={errors?.hyperLinkUrl?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={descriptionReg}
          {...descriptionProps}
          label={ControlsStringAssets.hyperLinkDescriptionLabel}
          margin='normal'
          fullWidth
          multiline={true}
          minRows={3}
          maxRows={5}
          error={!!errors.description}
          helperText={errors?.description?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default HyperLinkForm;