import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const topicItemSaveFailureSlice = createSlice({
  name: 'topicItemSaveFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    topicItemSaveFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    topicItemSaveFailureClear(state) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { topicItemSaveFailureChange, topicItemSaveFailureClear } = topicItemSaveFailureSlice.actions;

// export the core reducer for the slice
export const topicItemSaveFailureReducer = topicItemSaveFailureSlice.reducer;

export default topicItemSaveFailureSlice.reducer;