import { take, put } from 'redux-saga/effects';
import { deleteDocumentLink } from '../../../firebaseServices/dataServices/dataServiceActions/documentLinkActions';
import { documentLinkDeleteRequest, documentLinkDeleteStatusChange } from '../../slices/documentLink/documentLinkDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { documentLinkDeleteFailureChange } from '../../slices/documentLink/documentLinkDeleteFailureSlice';

/**
 * @function deleteDocumentLinkRequestedSaga A saga workflow pertaining to a user having requested to delete a documentLink.
 */
export function* deleteDocumentLinkRequestedSaga() {
  // console.info('(deleteDocumentLinkRequestedSaga) entered deleteDocumentLinkRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the actionTypeEnums.DELETE_DOCUMENTLINK_REQUESTED action to be dispatched
      const documentLinkDeleteRequestData = yield take(documentLinkDeleteRequest);
      const { payload: documentLink } = documentLinkDeleteRequestData;

      // make a request to delete the documentLink
      yield deleteDocumentLink(documentLink);

      // dispatch an action to indicate that the delete operation was successful
      yield put(documentLinkDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(documentLinkDeleteStatusChange(enumWorkflowState.Failure));
      yield put(documentLinkDeleteFailureChange(error));
    }
  }
}
