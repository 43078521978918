import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId } from '../../../types';
import { enumPersistableObjectType } from '../../../enums';
import { IPersistenceMetadata, IPersistenceMetadataAsJson } from '.';
import { Persistable } from '../Persistable';
import { IVersionedObjectTrackingRecord, VersionedObjectTrackingRecord } from '../VersionedObjectTrackingRecord';
import { IVersionAwarePersistableAsJson } from '../VersionAwarePersistable';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';

/** 
 * @class PersistenceMetadata represents metadata used when persisting an object.
 * 
 * This class is designed to track the navigate of an IVersionAwarePersistable object (a trackedObjectAsJson) and all of its versions.
 * An instance of this class will have its own Id and its parentObjectType & parentId  will be the objectType & Id of the tracked object.
 * The objectTrackingRecords array will be used to capture the different versions of the tracked object.
 * 
 * Note: PersistenceMetadata does NOT need to have its versions tracked, and that's why it extends Persistable, not VersionAwarePersistable
 * 
 * Tip: When persisting a PersistenceMetadata instance in a NoSQL database, it may make sense for the document's primary key be the 
 *      Id of the object being tracked, effectively making it an extension of the record of the object being tracked, as it makes it simple 
 *      to find and access the proper PersistenceMetadata document for a tracked object.
 */
export class PersistenceMetadata extends Persistable implements IPersistenceMetadata {
  /**
   * @method Constructor method
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {IVersionAwarePersistableAsJson} trackedObjectAsJson A Persistable object for which tracking will occur (will use 
   *                                                             the tracked object's ObjectType & Id for & parentObjectType & parentId)
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data related to the instantiation of the tracked object
   */
  constructor(
    id: typeUniqueId = RandomId.newId(),
    trackedObjectAsJson: IVersionAwarePersistableAsJson,
    // userPersistenceData: IUserPersistenceData
  ) {
    super(trackedObjectAsJson.ownerId, PersistenceMetadata.name, enumPersistableObjectType.PersistenceMetadata, id,
      trackedObjectAsJson.objectType, trackedObjectAsJson.id);

    this._trackedObjectClassName = trackedObjectAsJson.className;

    // this.initiateTracking(trackedObjectAsJson, userPersistenceData);
    this.initiateTracking(trackedObjectAsJson);
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _trackedObjectClassName The class type of the object being tracked with PersistenceMetadata.
   */
  private _trackedObjectClassName: string;

  /**
   * @method trackedObjectClassType Getter method for _trackedObjectClassType
   */
  get trackedObjectClassName(): string {
    return this._trackedObjectClassName;
  }

  /**
   * @method trackedObjectClassName Setter method for _trackedObjectClassType
   * @param {string} value The value to be used for setting _trackedObjectClassType
   */
  set trackedObjectClassName(value: string) {
    this._trackedObjectClassName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<IVersionedObjectTrackingRecord>} _objectTrackingRecords An array of records for tracking versions of the object
   */
  private _objectTrackingRecords: Array<IVersionedObjectTrackingRecord> = [];

  /**
 * @method objectTrackingRecords is an optional getter method for _objectTrackingRecord
   */
  get objectTrackingRecords() {
    return this._objectTrackingRecords;
  }

  /**
 * @method objectTrackingRecords is an optional setter method for _objectTrackingRecord
   * @param {Array<IVersionedObjectTrackingRecord>} value is the input value for setting _objectTrackingRecord
   */
  set objectTrackingRecords(value: Array<IVersionedObjectTrackingRecord>) {
    this._objectTrackingRecords = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method initiateTracking Initiates version tracking for an object to be tracked, using the information provided in the 
   * tracking record object parameter.
   * @param {IVersionAwarePersistableAsJson} trackedObjectAsJson The IVersionAwarePersistable object instance to be tracked.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data related to the instantiation of the tracked object
   */
  // private initiateTracking(objectToTrack: IPersistable, trackingRecord: IVersionedObjectTrackingRecord): void {
  private initiateTracking(trackedObjectAsJson: IVersionAwarePersistableAsJson): void {
    // create new IVersionedObjectTrackingRecord
    // const trackingRecord: IVersionedObjectTrackingRecord =
    //   new VersionedObjectTrackingRecord(enumVersionedObjectTrackingOperationType.Initiated, new Date(), userPersistenceData.userId, userPersistenceData.userName,
    //     userPersistenceData.deviceInfo, userPersistenceData.deviceLocale, trackedObjectAsJson);


    let trackingRecord: IVersionedObjectTrackingRecord;

    if (trackedObjectAsJson === undefined) {
      throw new MdbError('Cannot initiate tracking on an undefined object', enumMdbErrorType.MissingData);
    } else {
      if (trackedObjectAsJson.userPersistenceData === undefined || trackedObjectAsJson.userPersistenceData === "undefined") {
        throw new MdbError('Cannot initiate tracking on an object that does not include UserPersistenceData', enumMdbErrorType.MissingData);
      } else {
        // let userPersistenceData = trackedObjectAsJson.userPersistenceData;
        trackingRecord = new VersionedObjectTrackingRecord(new Date(), trackedObjectAsJson);
      }
    }

    // append the version tracking record to the collection
    this.appendTrackingRecord(trackingRecord);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method validateTrackedObjectSpecs Validates that the specs of the embedded tracked object are consistent with 
   * this overall metadata instance.
   * @param trackedObject 
   */
  private validateTrackedObjectSpecs(trackedObject: IVersionAwarePersistableAsJson): void {
    // validate that the ObjectType of the trackedObject matches the 'parentObjectType' of this metadata
    if (trackedObject.objectType !== this.parentObjectType) {
      const errorMsg = `Internal error. The trackedObject ObjectType must be identical to the parentObjectType of this PersistenceMetadata instance.`;
      // TODO: log the error
      throw new MdbError(errorMsg, enumMdbErrorType.InconsistentData);
    }

    // validate that the Id of the trackedObject matches the 'parentId' of this metadata
    if (trackedObject.id !== this.parentId) {
      const errorMsg = `Internal error. The trackedObject Id must be identical to the parentId of this PersistenceMetadata instance.`;
      // TODO: log the error
      throw new MdbError(errorMsg, enumMdbErrorType.InconsistentData);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method appendTrackingRecord Appends a tracking record to the collection of tracking records
   * @param {IVersionAwarePersistableAsJson} trackedObjectInstance A new version of the tracked object to be appended.
   * @param {IVersionedObjectTrackingRecord} trackingRecord A tracking record with information to be used for tracking the new version.
   */
  appendTrackingRecord(trackingRecord: IVersionedObjectTrackingRecord): void {

    // validate the core specs of the trackedObjectInstance
    this.validateTrackedObjectSpecs(trackingRecord.trackedObjectAsJson);

    // Note: It's not a requirement to ensure that the versionToken of this new tracked object is greater than all others already
    //       in the array. It's possible and feasible that another record from a later time was added.

    // add the record to the collection
    this.objectTrackingRecords.push(trackingRecord);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IPersistenceMetadata} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IPersistenceMetadata {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IPersistenceMetadata = Object.create(PersistenceMetadata.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IPersistenceMetadataAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IPersistenceMetadataAsJson = super.toJSON(includeContainedObjects);

      // fill the remaining properties of the object that pertain to this base class
      jsonObject.trackedObjectClassName = this.trackedObjectClassName;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        jsonObject.objectTrackingRecords = VersionedObjectTrackingRecord.toJSONArray(this.objectTrackingRecords);
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IPersistenceMetadataAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IPersistenceMetadataAsJson, includeContainedObjects: boolean = true): IPersistenceMetadata {
    try {
      // create a new instance of this class
      let persistenceMetadata: PersistenceMetadata = Object.create(PersistenceMetadata.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      persistenceMetadata = super.fromJSONProtected(persistenceMetadata, jsonObject, includeContainedObjects);

      // copy class-specific fields from the json object 
      if (jsonObject.trackedObjectClassName !== undefined) {
        persistenceMetadata.trackedObjectClassName = jsonObject.trackedObjectClassName;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.objectTrackingRecords !== undefined) {
          persistenceMetadata.objectTrackingRecords = VersionedObjectTrackingRecord.arrayFromJSONArray(jsonObject.objectTrackingRecords);
        }
      }

      return persistenceMetadata;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
