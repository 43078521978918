import { createContext } from 'react';
import { createDefaultThemeSpecsViewModel, IAppThemesContextData } from '.';

/**
 * @context ThemesContext A React Context that can be used to provide a React component tree with 
 *          Themes available to the current user, including the current theme.
 */
export const AppThemesContext: React.Context<IAppThemesContextData> =
  createContext<IAppThemesContextData>(
    {
      state: {
        themePreparationComplete: false,
        currentTheme: undefined,
        defaultThemeSpecsViewModel: undefined,
        // builtInThemeSpecsViewModels: undefined,
        builtInCategorizedThemeSpecsViewModels: undefined,
        userDefinedThemeSpecsViewModels: undefined,
      },
      actions: {
        // createDefaultThemeSpecsViewModel: createDefaultThemeSpecsViewModel
      }
    }
  );
