import React, { PropsWithChildren, useEffect } from 'react';
import { Color, ColorChangeHandler, ColorResult, SketchPicker, TwitterPicker } from 'react-color';
import { enumColorPickerType } from '../../../enums';


/**
 * @interface IColorPickerProps declares any input properties required for this component.
 */
export interface IColorPickerProps extends PropsWithChildren<unknown> {
  colorPickerType: enumColorPickerType; // indicates which type of Color Picker to display
  currentColor?: Color | undefined; // the currently selected color (eg, "#FFC17B"). Note: Color is defined as string | HSLColor | RGBColor
  colorPalette?: string[] | undefined; // the currently selected color (eg, "#FFC17B"). Note: Color is defined as string | HSLColor | RGBColor
  onChange?: ColorChangeHandler | undefined; // (optional) Callback function to be called whenever a color changes. This is called on drag events 
  // that can happen quite frequently. If you just need to get the color once use onChangeComplete.
  onChangeComplete?: ColorChangeHandler | undefined; // (optional) Callback function to be called once a color change is complete.
}

/**
 * @function ColorPicker is a React functional component, representing a control for selecting a color.
 * @param {IColorPickerProps} props are the properties passed into the component.
 */
export const ColorPicker: React.FC<IColorPickerProps> = (props: IColorPickerProps) => {
  ColorPicker.displayName = 'Color Picker';

  const { colorPickerType, currentColor, colorPalette, onChange, onChangeComplete } = props;

  // for first-time initialization...
  useEffect(() => {
    window.addEventListener('blur', handleOnBlur);
  });


  /**
  * @function handleOnBlur Handler method to be called when the control loses focus
  *                        *** For potential future use ***
  */
  function handleOnBlur() {

  }


  return (
    <>
      {/* based on the Color Picker Type parameter provided, set up the appropripate type of color picker */}
      {(colorPickerType === enumColorPickerType.TwitterPicker) ?
        <TwitterPicker
          key='colorPicker'
          color={currentColor}
          colors={colorPalette}
          onChange={(newColor: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(newColor, event)}
          onChangeComplete={(color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => onChangeComplete && onChangeComplete(color, event)}
        />

        // } else {
        :
        <SketchPicker
          key='colorPicker'
          disableAlpha={false}
          color={currentColor}
          presetColors={colorPalette}
          onChange={(newColor: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(newColor, event)}
          onChangeComplete={(color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => onChangeComplete && onChangeComplete(color, event)}
        />
      }
    </>
  )
}