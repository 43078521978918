import React, { useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { enumWorkflowState, enumObjectPersistenceState, enumPersistableObjectType, enumVideoLinkType, enumBeaconType } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { typeUniqueId } from '../../../dataObjects/types';
import { IVideoLink, VideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { IUser } from '../../../dataObjects/models/users/User';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import VideoLinkForm from '../../forms/VideoLinkForm/VideoLinkForm';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { ISaveVideoLinkRequest } from '../../../dataObjects/models/serviceRequests/videoLink/SaveVideoLinkRequest';
import { ISaveVideoLinkWorkflowState } from '../../../uiMiddleware-redux/store/SaveVideoWorkflowState';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { videoLinkSaveStatusClear, videoLinkSaveWithFileUploadRequest, videoLinkSaveWithoutFileUploadRequest, videoLinkSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/videoLink/videoLinkSaveStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';

/**
 * @interface IManageVideoLinkPage Input properties for the ManageVideoLinkPage
 */
export interface IManageVideoLinkPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the VideoLink already exists
  parentId?: typeUniqueId  // if the parentId is given (parentId of the VideoLink parent object), it means that a new VideoLink is to be created
}

/**
 * @function ManageVideoLinkPage A React Function Component for manageing (creating or editing) a VideoLink.
 * @param {IManageVideoLinkPageProps} props Input properties
 */
const ManageVideoLinkPage: React.FC<IManageVideoLinkPageProps> = (props: IManageVideoLinkPageProps) => {

  ManageVideoLinkPage.displayName = "Manage Video Link Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [videoLinkId, setVideoLinkId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the videoLinkId anytime the params.id changes
  useEffect(() => {
    setVideoLinkId(params.id);
  }, [params.id]);

  // define an effect based on a change to the videoLinkId. Having a defined videoLinkId means that we will 
  // be editing an existing VideoLink
  useEffect(() => {
    if (videoLinkId !== undefined) {
      // try to find an VideoLink object with the Id given in the URL
      getSingleObjectById(videoLinkId, enumPersistableObjectType.VideoLink, onVideoLinkFetched);
    }
  }, [videoLinkId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [videoLinkParentId, setVideoLinkParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the videoLinkParentId anytime the params.parentId changes
  useEffect(() => {
    setVideoLinkParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the videoLink to be managed, either a new videoLink or an existing one
  const [videoLink, setVideoLink] = useState<IVideoLink | undefined>(undefined);

  // whether we are going to be managing a new videoLink
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveVideoLinkStatus: () => void = useCallback(() => {
    dispatch(videoLinkSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveVideoLinkStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveVideoLinkStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveVideoLinkStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving an VideoLink
  const [saveVideoWorkflowState, setSaveVideoWorkflowState] = useState<ISaveVideoLinkWorkflowState | null | undefined>(undefined);

  // indicates whether the page has been successfully completed (for saving new object or updating existing)
  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the imageLink save status store state variable
      // dispatch(imageLinkSaveStatusClear());
      dispatch(videoLinkSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // the success message for VideoLink will merely be the defined constant
      const successMessage: string = MessagesStringAssets.videoLink_SaveSuccess;
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveVideoLink, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, navigate]);

  // get the Redux workflow state for saving an VideoLink
  const currentSaveVideoLinkWorkflowState: ISaveVideoLinkWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveVideoLinkStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveVideoWorkflowState(currentSaveVideoLinkWorkflowState);
  }, [currentSaveVideoLinkWorkflowState]);

  /**
   * @function prepareNewVideoLink Prepares/creates a new videoLink that will be used if user is requesting to create/add a new videoLink.
   * @param parentChannel 
   */
  const prepareNewVideoLink: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => IVideoLink = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newVideoLink: IVideoLink;

      const newVideoLinkId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newVideoLink = new VideoLink(user.id, newVideoLinkId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        enumVideoLinkType.GoogleCloudStorage, '', '', '', '', undefined, undefined, undefined, userPersistenceData);

      return newVideoLink;
    }, []
  );

  // define an effect based on a change to the videoLinkParentId & currentUser. Having a defined videoLinkParentId means that we will
  // be creating a new VideoLink
  useEffect(() => {
    if (videoLinkParentId !== undefined && currentUser) {
      setVideoLink(prepareNewVideoLink(videoLinkParentId, currentUser, currentUser.userSettings));
    }
  }, [videoLinkParentId, currentUser, prepareNewVideoLink]);

  function onVideoLinkFetched(videoLink: IVideoLink | undefined): void {
    if (videoLink !== undefined) {
      // set the returned VideoLink to be the one we are editing
      setVideoLink(videoLink);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
    * @method handleFormSubmit Handles a submit request from the form in this page
    * @param {ISaveVideoLinkRequest} saveVideoLinkRequest A Submit event
    */
  function handleFormSubmit(saveVideoLinkRequest: ISaveVideoLinkRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {

        // dispatch an appropriate action to request saving the videoLink
        // if there is a file to be uploaded, dispatch an action that includes a file upload; 
        // otherwise, merely dispatch an action to save the videoLink only
        if (saveVideoLinkRequest.fileUploadRequest !== undefined) {
          dispatch(videoLinkSaveWithFileUploadRequest(saveVideoLinkRequest));
        } else {
          dispatch(videoLinkSaveWithoutFileUploadRequest(saveVideoLinkRequest));
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold a progress message
  let progressMessage: string | undefined = undefined;

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;
  if (saveVideoWorkflowState !== null && saveVideoWorkflowState !== undefined) {

    if (saveVideoWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveVideoWorkflowState.workflowState === enumWorkflowState.InProgress || saveVideoWorkflowState.workflowState === enumWorkflowState.Requested) {
      // get message from the workflow state to display as a progress message
      progressMessage = saveVideoWorkflowState.workflowStateMessage ?? undefined;

      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveVideoWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveVideoWorkflowState.workflowError && saveVideoWorkflowState.workflowError instanceof Error) {
        failureMessage = saveVideoWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageVideoLink_New : PageAndViewTitleStringAssets.pageTitle_ManageVideoLink_Existing}
        onCloseAlert={resetSaveVideoLinkStatus}
      >
        {videoLink && currentUser &&
          <VideoLinkForm
            userId={currentUser.id}
            videoLink={videoLink}
            saveRequestInProgress={saveInProgress}
            progressMessage={progressMessage}
            onSubmit={handleFormSubmit}
          />
        }

      </GenericPageContainer>
    </>
  );

} // const ManageVideoLinkUploadPage...

export default ManageVideoLinkPage;
