import { delay, put, take } from 'redux-saga/effects';
// import { actionTypeEnums, deleteLiveBeacon } from '../../actions';
import { beaconChange } from '../../slices/beacon/beaconSlice';

/**
 * @function livBeaconAddedSaga A saga workflow that will set a timer for each live beacon added, and then delete 
 * the beacon after the timer has expired.
 */
export function* livBeaconAddedSaga() {
  // // console.info('(livBeaconAddedSaga) entered livBeaconAddedSaga');
  // // loop to keep the saga running throughout the life of the application
  // while (true) {
  //   // wait on the beaconChangeData action to be dispatched and get the payload data...
  //   const beaconChangeData = yield take(beaconChange);
  //   const { payload: beacon } = beaconChangeData;

  //   // if there's an actual beacon (as opposed to the beacon change being a null value)
  //   if (beacon) {
  //     // if the beacon has an autoTimeoutMilliseconds value, use that to set a timer and automatically delete the beacon after the timer has expired
  //     if (beacon.autoTimeoutMilliseconds !== undefined) {
  //       yield delay(beacon.autoTimeoutMilliseconds);

  //       // dispatch a request to delete the beacon
  //       yield put(deleteLiveBeacon(beacon.id));
  //     }
  //   }
  // }
}