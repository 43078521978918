import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IThemeSpecs } from '../../../dataObjects/models/themes/ThemeSpecs';

const initialState: enumWorkflowState | null = null;

export const themeSpecsDeleteStatusSlice = createSlice({
  name: 'themeSpecsDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IThemeSpecs
    themeSpecsDeleteRequest: (state, action: PayloadAction<IThemeSpecs>) => {
      themeSpecsDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    themeSpecsDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { themeSpecsDeleteRequest, themeSpecsDeleteStatusChange } = themeSpecsDeleteStatusSlice.actions;

// export the core reducer for the slice
export const themeSpecsDeleteStatusReducer = themeSpecsDeleteStatusSlice.reducer;

export default themeSpecsDeleteStatusSlice.reducer;