import { take, put } from 'redux-saga/effects';
import { deleteChannel } from '../../../firebaseServices/dataServices/dataServiceActions/channelActions';
import { channelDeleteFailureChange } from '../../slices/channel/channelDeleteFailureSlice';
import { channelDeleteRequest, channelDeleteStatusChange } from '../../slices/channel/channelDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteChannelRequestedSaga A saga workflow pertaining to a user having requested to delete a channel.
 */
export function* deleteChannelRequestedSaga() {
  // console.info('(deleteChannelRequestedSaga) entered deleteChannelRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the channelDeleteRequest action to be dispatched and get the payload data...
      const channelDeleteRequestData = yield take(channelDeleteRequest);
      const { payload: channel } = channelDeleteRequestData;

      // make a request to delete the channel
      yield deleteChannel(channel)

      // dispatch an action to indicate that the delete operation was successful
      yield put(channelDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(channelDeleteStatusChange(enumWorkflowState.Failure));
      yield put(channelDeleteFailureChange(error));
    }
  }
}
