import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IHyperLink } from '../../../dataObjects/models/digitalMedia/HyperLink';

const initialState: enumWorkflowState | null = null;

export const hyperLinkDeleteStatusSlice = createSlice({
  name: 'hyperLinkDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IHyperLink
    hyperLinkDeleteRequest: (state, action: PayloadAction<IHyperLink>) => {
      hyperLinkDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    hyperLinkDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { hyperLinkDeleteRequest, hyperLinkDeleteStatusChange } = hyperLinkDeleteStatusSlice.actions;

// export the core reducer for the slice
export const hyperLinkDeleteStatusReducer = hyperLinkDeleteStatusSlice.reducer;

export default hyperLinkDeleteStatusSlice.reducer;