import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { listViewItemStyles } from '../../../styles';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { TooltipStringAssets } from '../../../../assets/stringAssets';


export interface CategoriesListViewItemProps extends PropsWithChildren<unknown> {
  category: ICategory;
  onDelete: (category: ICategory) => void;
}

export const CategoriesListViewItem: React.FC<CategoriesListViewItemProps> = (props: CategoriesListViewItemProps) => {
  CategoriesListViewItem.displayName = 'Categories List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render CategoriesListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { category, onDelete } = props;
  const { id, name, description } = category;

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <span className={classes.tableRow} key={id}> */}
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* <Grid className={classes.tableRow} item container xs={12} direction="row" > */}
          {/* Name */}
          <Grid item container xs={8} sm={10} >
            <div style={{
              margin: '5px 5px 5px 0px', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '8px',
              backgroundColor: `${category.displayBackground}`, border: `1px solid ${category.displayBorder}`
            }}>
              <Typography className={classes.title} variant="h6" style={{ color: `${category.displayFontColor}` }}>
                {/* Display the name with a bounding box that reflect the colors (background, border, font) */}
                {name}
              </Typography>
            </div>

          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={4} sm={2} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Edit button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              <Tooltip title={TooltipStringAssets.edit} arrow>
                <IconButton
                  size="small"
                  className={`${classes.actionButton} ${classes.editIconButton}`}
                  onClick={() => id && navigate(`/category/${id}`)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if not the user's Uncategorized category) or "Delete disallowed" (for the user's Uncategorized category)
                title={category.id === category.ownerId ? "Delete disallowed" : "Delete"}
                arrow
              >
                <span>

                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    // this button will be disabled if the Category is the user's Uncategorized category
                    disabled={category.id === category.ownerId}
                    onClick={() => category && onDelete(category)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Description  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
      {/* </span> */}
    </>
  );

}

export default CategoriesListViewItem;