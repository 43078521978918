import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchAudioLinksFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchAudioLinksFailureSlice = createSlice({
  name: 'fetchAudioLinksFailure',
  initialState,
  reducers: {
    fetchAudioLinksFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    fetchAudioLinksFailureClear: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { fetchAudioLinksFailure, fetchAudioLinksFailureClear } = fetchAudioLinksFailureSlice.actions;

// export the core reducer for the slice
export const fetchAudioLinksFailureReducer = fetchAudioLinksFailureSlice.reducer;

export default fetchAudioLinksFailureSlice.reducer;