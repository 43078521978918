import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';

/**
 * @function populateRecordsToSearchMetadataDb Makes request, via Http, to a function that populates the SearchMetadataDb
 * @returns number The number of records written to the SearchMetadataDb
 */
export function populateRecordsToSearchMetadataDb(userId?: string): Promise<number> {
  return new Promise<number>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered populateRecordsToSearchMetadataDb(). userId: ${JSON.stringify(userId)}`, 'background: #055; color: #fff');

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const populateSearchMetadataDb =
        httpsCallable<string | undefined, number>(functions, 'populateSearchMetadataDb');
        populateSearchMetadataDb(userId)
        .then((result) => {

          displayConsoleLogs && console.log(`%c populateRecordsToSearchMetadataDb(). Returned from populateRecordsToSearchMetadataDb() Http call. result: ${result}`, 'background: #055; color: #fff');

          // convert the results from JSON to native object for return (via resolve())
          // const searchResultsToReturn: IDigitalMediaSearchResults = JsonConverter.fromJSON(DigitalMediaSearchResults, result.data);
          // resolve(searchResultsToReturn);

          // return the number of records written to the SearchMetadataDb
          resolve(result.data);
        });
    } catch (error: any) {
      alert(`Error. populateRecordsToSearchMetadataDb failed: (${error})`);
      reject(error);
    }

  });
}
