import { enumCollectionViewType } from '../../dataObjects/enums';
import { IStoreState } from '../../uiMiddleware-redux/store/IStoreState';
import { useAppSelector } from '../../uiMiddleware-redux/store/configureStore';

/**
 * @function useGetCollectionViewType Gets (and returns) the current Collection View Type (enumCollectionViewType) for the application
 */
export function useGetCollectionViewType(): enumCollectionViewType {
  // Fetch the view type from Redux state
  const collectionViewType: enumCollectionViewType = useAppSelector((state: IStoreState) => state.collectionViewType);

  return collectionViewType;
}
