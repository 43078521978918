import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IThemeSpecs, IThemeSpecsAsJson } from "../../../../dataObjects/models/themes/ThemeSpecs";
import { IFirestoreThemeSpecsRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreThemeSpecsRepository";


export function saveThemeSpecs(themeSpecs: IThemeSpecs): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // // *** FOR TESTING PURPOSES: Induce an artificial error ***
      // await setTimeout(() => {
      //   // do nothing
      // }, 2000);
      // // throw an exception
      // throw new MdbError('(saveThemeSpecs) This is an artificial error induced for testing purposes.')
      // // *** END OF TESTING BLOCK

      // get a ThemeSpecs repository from the repository factory
      const firestoreThemeSpecsRepository: IFirestoreThemeSpecsRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ThemeSpecs) as
        IFirestoreBaseRepository<IThemeSpecs, IThemeSpecsAsJson>;

      // determine if the ThemeSpecs already exists by calling the repository get() method to try and get the object
      const existingThemeSpecs: IThemeSpecs | undefined = await firestoreThemeSpecsRepository.get(themeSpecs.id);

      // If the ThemeSpecs wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingThemeSpecs === undefined) {
        // call repository method to create the ThemeSpecs
        await firestoreThemeSpecsRepository.create(themeSpecs);
      } else {
        // call repository method to update the ThemeSpecs
        await firestoreThemeSpecsRepository.update(themeSpecs);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}