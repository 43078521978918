import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption, typeDocumentLinkType } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumDocumentLinkType, enumPersistableObjectClassName } from '../../../enums';
import { IDocumentLink, IDocumentLinkAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { BaseMediaFileLink } from '../BaseMediaFileLink';

/** 
 * @class DocumentLink A document link that can be associated with something
 */
export class DocumentLink extends BaseMediaFileLink implements IDocumentLink {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {enumDocumentLinkType} documentLinkType The type of document link
   * @param {string} baseStoragePath The source Url (path & filename) that points to the media file
   * @param {string} downloadUrl A full Url for downloading the media file from the storage source that has security (Google Cloud Storage, AWS S3, etc.),
   *   or even a direct location (some file store)
   * @param {string} mediaType The type of media
   * @param {string} name The name of the document
   * @param {string} description A description of the media file
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    documentLinkType: enumDocumentLinkType,
    baseStoragePath: string,
    downloadUrl: string,
    mediaType: string,
    name: string,
    description: string,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.DocumentLink, enumPersistableObjectType.DocumentLink, id, parentObjectType, parentId, objectState, baseStoragePath, downloadUrl, mediaType, description, userPersistenceData);

    this.name = name;
    this.documentLinkType = documentLinkType;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} name represents the name of the document
   */
  private _name: string = '';

  // getter for _name
  get name(): string {
    return this._name;
  }

  // setter for _name
  set name(value: string) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeDocumentLinkType} _documentLinkType property The type of document link (Google Cloud Storage, rawLink, youTubeEmbed, etc.)
   */
  private _documentLinkType: typeDocumentLinkType = undefined;

  /**
   * @method documentLinkType Getter method for _documentLinkType
   */
  get documentLinkType(): typeDocumentLinkType {
    return this._documentLinkType;
  }

  /**
   * @method documentLinkType Setter method for _documentLinkType
   * @param {typeDocumentLinkType} value The value for setting _documentLinkType
   */
  set documentLinkType(value: typeDocumentLinkType) {
    this._documentLinkType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IDocumentLink} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IDocumentLink {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IDocumentLink = Object.create(DocumentLink.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IDocumentLinkAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IDocumentLinkAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.name = this.name === undefined ? 'undefined' : this.name;
      jsonObject.documentLinkType = this.documentLinkType === undefined ? 'undefined' : this.documentLinkType;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IDocumentLinkAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IDocumentLinkAsJson, includeContainedObjects: boolean = true): IDocumentLink {
    try {
      // create a new instance of this class
      let documentLinkObject: DocumentLink = Object.create(DocumentLink.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      documentLinkObject = super.fromJSONProtected(documentLinkObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      documentLinkObject.name = jsonObject.name ?? 'undefined';
      documentLinkObject.documentLinkType = (jsonObject.documentLinkType === 'undefined' ? undefined : jsonObject.documentLinkType);

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return documentLinkObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
