import { IFirestoreHyperLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreHyperLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IHyperLink, IHyperLinkAsJson } from "../../../../dataObjects/models/digitalMedia/HyperLink";


export function deleteHyperLink(hyperLink: IHyperLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a HyperLink repository from the repository factory
      const firestoreHyperLinkRepository: IFirestoreHyperLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.HyperLink) as
        IFirestoreBaseRepository<IHyperLink, IHyperLinkAsJson>;

      // call the repository delete() method to delete the object
      await firestoreHyperLinkRepository.delete(hyperLink.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}