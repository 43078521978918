import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableObjectType, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState } from "../../../enums";
import { IBaseMediaFileLink, IBaseMediaFileLinkAsJson } from ".";
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';

/** 
 * @class VersionAwarePersistable A Persistable object that also has versioning information
 */
export abstract class BaseMediaFileLink extends VersionAwarePersistable implements IBaseMediaFileLink {
  /**
   * @method Constructor For VersionAwarePersistable
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {string} className Name of the class behind the instance
   * @param {typePersistableObjectType} objectType Type of object behind the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} baseStoragePath The source Url (path & filename) that points to the media file
   * @param {string} downloadUrl A full Url for downloading the media file from the storage source that has security (Google Cloud Storage, AWS S3, etc.),
   *   or even a direct location (some file store)
   * @param {string} mediaType The type of media
   * @param {string} description A description of the media file
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {IVersionAwarePersistable} parentObject A live instance of the object's parent
   */
  constructor(
    ownerId: typeUniqueId,
    className: string,
    objectType: typePersistableObjectType,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    baseStoragePath: string,
    downloadUrl: string,
    mediaType: string,
    description: string,
    userPersistenceData?: IUserPersistenceData,
  ) {
    super(ownerId, className, objectType, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.baseStoragePath = baseStoragePath;
    this.downloadUrl = downloadUrl;
    this.mediaType = mediaType;
    this.description = description;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseStoragePath The source Url (path & filename) that points to the media file
   */
  private _baseStoragePath: string = '';

  /**
   * @method baseStoragePath A getter method for _baseStoragePath
   */
  get baseStoragePath() {
    return this._baseStoragePath;
  }

  /**
   * @method baseStoragePath A setter method for _baseStoragePath
   * @param {string} value The value for setting _baseStoragePath
   */
  set baseStoragePath(value) {
    // validate the Url
    // FieldValueValidation.validateImageUrlValue(value, `The imageUrl must be set to a valid url for an image file (imageUrl provided: '${value}')`);
    this._baseStoragePath = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _downloadUrl A full Url for downloading the media file from the storage source that has security (Google Cloud Storage, AWS S3, etc.),
   *   or even a direct location (some file store)
   */
  private _downloadUrl: string = '';

  /**
   * @method downloadUrl A getter method for _downloadUrl
   */
  get downloadUrl() {
    return this._downloadUrl;
  }

  /**
   * @method downloadUrl A setter method for _downloadUrl
   * @param {string} value The value for setting _downloadUrl
   */
  set downloadUrl(value) {
    // validate the Url
    // FieldValueValidation.validateImageUrlValue(value, `The imageUrl must be set to a valid url for an image file (imageUrl provided: '${value}')`);
    this._downloadUrl = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _mediaType The type of media
   */
  private _mediaType: string = '';

  /**
   * @method mediaType A getter method for _mediaType
   */
  get mediaType() {
    return this._mediaType;
  }

  /**
   * @method mediaType A setter method for _mediaType
   * @param {string} value The value for setting _mediaType
   */
  set mediaType(value) {
    this._mediaType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description A description of the media file
   */
  private _description: string = '';

  /**
   * @method description A getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description A setter method for _description
   * @param {string} value The value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IBaseMediaFileLink} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IBaseMediaFileLink {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IBaseMediaFileLink = Object.create(BaseMediaFileLink.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of the derived class instance to JSON, along with all of its descendants. 
   * (Note: This method will be called if JSON.stringify() is executed on an instance.)
   * @returns A JSON object with serialized data from 'this' instance of the derived class.
   */
  // abstract toJSON(includeDescendants: boolean): IVersionAwarePersistableAsJson;
  toJSON(includeContainedObjects: boolean): IBaseMediaFileLinkAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IBaseMediaFileLinkAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.baseStoragePath = this.baseStoragePath;
      jsonObject.downloadUrl = this.downloadUrl;
      jsonObject.mediaType = this.mediaType;
      jsonObject.description = this.description;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects to include
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
  //  * objects (if requested).
  //  * @param {IBaseMediaFileLinkAsJson} jsonObject A JSON version of a class instance.
  //  * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
  //  * @returns A BaseMediaFileLink instance with values copied from the jsonObject
  //  */
  // static fromJSON(jsonObject: IBaseMediaFileLinkAsJson, includeContainedObjects: boolean = true): IBaseMediaFileLink {
  //   try {
  //     // create a new instance of this class
  //     let baseMediaFileLinkObject: BaseMediaFileLink = Object.create(BaseMediaFileLink.prototype);

  //     // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
  //     baseMediaFileLinkObject = super.fromJSONProtected(baseMediaFileLinkObject, jsonObject, includeContainedObjects);

  //     // copy any additional field values from the json object 
  //     if (jsonObject.baseStoragePath !== undefined) {
  //       baseMediaFileLinkObject.baseStoragePath = jsonObject.baseStoragePath;
  //     }

  //     // copy any additional field values from the json object 
  //     if (jsonObject.downloadUrl !== undefined) {
  //       baseMediaFileLinkObject.downloadUrl = jsonObject.downloadUrl;
  //     }

  //     // copy any additional field values from the json object 
  //     if (jsonObject.description !== undefined) {
  //       baseMediaFileLinkObject.description = jsonObject.description;
  //     }

  //     // if request is to include contained objects, copy additional fields
  //     if (includeContainedObjects) {
  //       // this class doesn't have any contained objects to add
  //     }

  //     return baseMediaFileLinkObject;

  //   } catch (error: any) {
  //     // TODO: log error
  //     // re-throw error
  //     throw error;
  //   }
  // }
  // /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSONProtected A static method that converts the properties of this base class from a JSON object to an instance of a 
   * derived class.
   * @param classType 
   * @param jsonObject 
   * @param includeContainedObjects 
   */
  protected static fromJSONProtected(baseMediaFileLinkObject: BaseMediaFileLink, jsonObject: IBaseMediaFileLinkAsJson, includeContainedObjects: boolean = true): any {
    try {
      // // create a new instance of this class
      // let baseMediaFileLinkObject: BaseMediaFileLink = Object.create(BaseMediaFileLink.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      baseMediaFileLinkObject = super.fromJSONProtected(baseMediaFileLinkObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.baseStoragePath !== undefined) {
        baseMediaFileLinkObject.baseStoragePath = jsonObject.baseStoragePath;
      }

      if (jsonObject.downloadUrl !== undefined) {
        baseMediaFileLinkObject.downloadUrl = jsonObject.downloadUrl;
      }

      if (jsonObject.mediaType !== undefined) {
        baseMediaFileLinkObject.mediaType = jsonObject.mediaType;
      }

      if (jsonObject.description !== undefined) {
        baseMediaFileLinkObject.description = jsonObject.description;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return baseMediaFileLinkObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/
}
