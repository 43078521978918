import { put, delay } from "redux-saga/effects";
import { ISaveImageLinkRequest } from '../../../dataObjects/models/serviceRequests/imageLink/SaveImageLinkRequest';
import { IImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult, uploadFileToCloudStorage } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions';
import { imageLinkFileUploadResult, imageLinkSaveError, imageLinkSaveWithoutFileUploadRequest, imageLinkSaveWorkflowStateChange } from "../../slices/imageLink/imageLinkSaveStatusSlice";
import { ISaveImageLinkWorkflowState } from "../../store/SaveImageWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";


export function* saveImageLinkWithFileUploadWorkerSaga(saveImageLinkRequest: ISaveImageLinkRequest) {
  try {
    // console.info('(saveImageLinkWithFileUploadWorkerSaga) Entered saveImageLinkWithFileUploadWorkerSaga');

    // // used for debugging timing
    // const startTime: number = Date.now();

    // if the ISaveImageLinkRequest is defined...
    if (saveImageLinkRequest !== undefined) {
      // get the IFileUploadRequest from the ISaveImageLinkRequest object
      const fileUploadRequest: IFileUploadRequest | undefined = saveImageLinkRequest.fileUploadRequest;

      // get the ImageLink object from the request
      const imageLink: IImageLink | undefined = saveImageLinkRequest.imageLink;

      // only proceed if both the IFileUploadRequest and ImageLink objects have been defined
      if (fileUploadRequest !== undefined && imageLink !== undefined) {
        const fileUploadResult: ICloudStorageFileUploadResult = yield uploadFileToCloudStorage(fileUploadRequest.fileToUpload, fileUploadRequest.userId,
          fileUploadRequest.fileClass, fileUploadRequest.fileUniqueId, fileUploadRequest.uploadProgressCallback);

        // console.info(`(saveImageLinkWithFileUploadWorkerSaga) Elapsed time after uploading file: ${Date.now() - startTime} milliseconds`);

        // update store state...      
        const saveImageLinkWorkflowState: ISaveImageLinkWorkflowState = {
          imageLink: saveImageLinkRequest.imageLink,
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.imageLink_SaveRequested,
          fileUploadRequest: fileUploadRequest
        }
        yield put(imageLinkSaveWorkflowStateChange(saveImageLinkWorkflowState));

        // dispatch action to indicate that there is a fileUploadResult
        yield put(imageLinkFileUploadResult(fileUploadResult));

        // console.info(`(saveImageLinkWithFileUploadWorkerSaga) Elapsed time after dispatching fileUploadResult: ${Date.now() - startTime} milliseconds`);

        // set the baseStoragePath and downloadUrl for the ImageLink object
        saveImageLinkRequest.imageLink.baseStoragePath = fileUploadResult.baseStoragePath;
        saveImageLinkRequest.imageLink.downloadUrl = fileUploadResult.downloadUrl;
        saveImageLinkRequest.imageLink.mediaType = fileUploadResult.mediaType;

        // // add a delay to give the backend some time to generate files
        yield delay(3000);

        // console.info(`(saveImageLinkWithFileUploadWorkerSaga) Elapsed time after delay, following uploading file: ${Date.now() - startTime} milliseconds`);

        // // Verify that necessary generated images have been created. We'll do this by making a 'fork' (non-blocking) call to a separate method
        // // that will get the downloadUrl for each desired image file size, capturing an array of Task objects that we will then 'join' before proceeding.
        // const imageFileSizes: Array<enumImageFileSize> = [enumImageFileSize.Thumbnail, enumImageFileSize.Small]; // these are the necessary file sizes to check
        // const tasks: Array<Task> = []; // begin with an empty array of tasks
        // // iterate through the file sizes and request the downloadUrl for each by a 'fork' call
        // for (let imageFileSize of imageFileSizes) {
        //   const task: Task = yield fork(verifyDownloadUrlForGeneratedFile, fileUploadResult.baseStoragePath, imageFileSize)
        //   tasks.push(task);
        // }

        // const results: Array<string | undefined> = yield join(tasks);

        // // console.info(`(saveImageLinkWithFileUploadWorkerSaga) Elapsed time after verifying small generated files through fork'd processes: ${Date.now() - startTime} milliseconds`);

        // // // const undefinedResults: Array<string | undefined> =
        // // //   results.filter((downloadUrl: string | undefined, index: number, array: Array<string | undefined>) => downloadUrl === undefined);
        // // // if (undefinedResults.length > 0) {
        // // //   alert('Some downloadUrls are not yet available');
        // // // }

        // const downloadUrlThumbnail: string | undefined = yield call(verifyDownloadUrlForGeneratedFile, fileUploadResult.baseStoragePath, enumImageFileSize.Thumbnail);
        // const downloadUrlSmall: string | undefined = yield call(verifyDownloadUrlForGeneratedFile, fileUploadResult.baseStoragePath, enumImageFileSize.Small);

        // // console.info(`(saveImageLinkWithFileUploadWorkerSaga) Elapsed time after verifying small generated files through call(): ${Date.now() - startTime} milliseconds`);

        // now, dispatch an action to save the ImageLink object (since the image file save operation has already taken place)
        yield put(imageLinkSaveWithoutFileUploadRequest(saveImageLinkRequest));
      }

    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(imageLinkSaveError(error));
  }
}