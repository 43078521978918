import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ICategory } from '../../../dataObjects/models/categories/Category';

// export interface ISaveCategoryStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const categorySaveStatusSlice = createSlice({
  name: 'saveCategoryStatus',
  initialState,
  reducers: {
    // The categorySaveRequest triggers the saving of a Category. It's not really associated with the Store State
    // for the 'saveCategoryStatus' store variable; however, it is used to both trigger the Category Save Request -AND-
    // to call the categorySaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveCategoryData') and create another slice with a categorySaveRequest action. 
    categorySaveRequest: (state, action: PayloadAction<ICategory>) => {
      categorySaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    categorySaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { categorySaveRequest, categorySaveStatusChange } = categorySaveStatusSlice.actions;

// export the core reducer for the slice
export const categorySaveStatusReducer = categorySaveStatusSlice.reducer;

export default categorySaveStatusSlice.reducer;