import { Firestore } from 'firebase/firestore';
import { IFirestoreDigitalMediaRepository, FirestoreDigitalMediaRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDigitalMediaRepository";
import { ITopicItem } from "../../../../dataObjects/models/topics/TopicItem";
import { IDigitalMedia, DigitalMedia } from "../../../../dataObjects/models/digitalMedia/DigitalMedia";
import { typeUniqueId } from "../../../../dataObjects/types";
import { RandomId } from "../../../../dataObjects/utilities/RandomId";
import { enumObjectPersistenceState } from "../../../../dataObjects/enums";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreDbContext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDbContext";

/**
 * @function createDigitalMediaBaseFromTopicItem Creates an DigitalMedia base object in the database, associating it with the 
 *    provided topicItem object
 * @param {ITopicItem} topicItem The topicItem with which the new DigitalMedia item is to be associated.
 */
export function createDigitalMediaBaseFromTopicItem(topicItem: ITopicItem): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // construct an DigitalMedia base object from the data found in the topicItem object
      const digitalMediaId: typeUniqueId = RandomId.newId();
      const digitalMedia: IDigitalMedia = new DigitalMedia(topicItem.ownerId, digitalMediaId, topicItem.objectType, topicItem.id,
        enumObjectPersistenceState.New, topicItem.userPersistenceData);

      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // instantiate a FirestoreDbContext
      const dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);

      const firestoreDigitalMediaRepository: IFirestoreDigitalMediaRepository = new FirestoreDigitalMediaRepository(dbContext);

      // call repository method to create the DigitalMedia object
      await firestoreDigitalMediaRepository.create(digitalMedia);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}