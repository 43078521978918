import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../../dataObjects/models/users/User';
import { ITopic, Topic } from '../../../../dataObjects/models/topics/Topic';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumObjectPersistenceState, enumPersistableObjectType } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets } from '../../../../assets/stringAssets';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import TopicForm from '../../../forms/TopicForm/TopicForm';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage/IProcessPerManageObjectPageStatusInput';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { topicSaveRequest, topicSaveStatusChange } from '../../../../uiMiddleware-redux/slices/topic/topicSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';

/**
 * @interface IManageTopicPageProps Input properties for the ManageTopicPage
 */
export interface IManageTopicPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the Topic already exists
  parentId?: typeUniqueId,  // if the parentId is given, it means that a new Topic is to be created
}

/**
 * @function ManageTopicPage A React Function Component for manageing (creating or editing) a Topic.
 * @param {IManageTopicPageProps} props Input properties
 */
const ManageTopicPage: React.FC<IManageTopicPageProps> = (props: IManageTopicPageProps) => {

  ManageTopicPage.displayName = "Manage Topic Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const params = useParams();

  displayConsoleLogs && console.log(`ManageTopicPage. Entering component. params.id. params.id value: ${params.id}`);

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [topicId, setTopicId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the topicId anytime the params.id changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageTopicPage. Entered useEffect() for params.id. params.id value: ${params.id}`);
    setTopicId(params.id);
  }, [params.id]);

  // define an effect based on a change to the topicId. Having a defined topicId means that we will 
  // be editing an existing Topic
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageTopicPage. Entered useEffect() for topicId. topicId value: ${topicId}`);
    if (topicId !== undefined) {
      displayConsoleLogs && console.log(`ManageTopicPage. In useEffect() for topicId (${topicId}). Ready to call getSingleObjectById().`);
      // try to find an Topic object with the Id given in the URL
      getSingleObjectById(topicId, enumPersistableObjectType.Topic, onTopicFetched);
    }
  }, [topicId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [topicParentId, setTopicParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the topicParentId anytime the match?.params.parentId changes
  useEffect(() => {
    setTopicParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the topic to be managed, either a new topic or an existing one
  const [topic, setTopic] = useState<ITopic | undefined>(undefined);

  // whether we are going to be managing a new topic
  const [isNewObject, setIsNewObject] = useState<boolean | undefined>(undefined);


  const resetSaveTopicStatus: () => void = useCallback(() => {
    dispatch(topicSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveTopicStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveTopicStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveTopicStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();
  const currentChannel: IChannel | undefined = userCategoriesAndChannelsContext.state.currentChannel;
  displayConsoleLogs && console.log(`%c ManageTopicPage. currentChannel: \n${JSON.stringify(currentChannel)}`, 'background: #006; color: #fff');

  const [pageTitle, setPageTitle] = useState<string>('');

  const preparePageTitle: () => void = useCallback(
    () => {
      const preparedPageTitle: string = (isNewObject ? 'New ' : 'Modify ') + currentChannel?.topicNameAliasSingular;
      setPageTitle(preparedPageTitle);
    },
    [currentChannel, isNewObject],
  );

  useEffect(() => {
    preparePageTitle();
  }, [preparePageTitle]);

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveTopicStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveTopicFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.topic_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveTopic,
    notificationSuccessMessage: MessagesStringAssets.topic_SaveSuccess,
  }

  if (topic !== undefined) {
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.topic_SaveSuccess} (${topic.name})`;
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewTopic Prepares/creates a new topic that will be used if user is requesting to create/add a new topic.
   * @param parentChannel 
   */
  const prepareNewTopic: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => ITopic = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newTopic: ITopic;

      const newTopicId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const topicItemNameAliasSingular: string = 'Topic Item';
      const topicItemNameAliasPlural: string = 'Topic Items';
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newTopic = new Topic(user.id, newTopicId, enumPersistableObjectType.Channel, parentId, enumObjectPersistenceState.New,
        '', '', '', userPersistenceData, topicItemNameAliasSingular, topicItemNameAliasPlural);

      return newTopic;
    }, []
  );

  // define an effect based on a change to the topicParentId & currentUser. Having a defined topicParentId means that we will
  // be creating a new Topic
  useEffect(() => {
    if (topicParentId && currentUser) {
      setTopic(prepareNewTopic(topicParentId, currentUser, currentUser.userSettings));

      setIsNewObject(true);
    }
  }, [topicParentId, currentUser, prepareNewTopic]);

  /**
   * @function onTopicFetched A callback function that will be used when requesting a data object to be managed
   * @param {ITopic | undefined} topicFetched The data object to be managed
   */
  function onTopicFetched(topicFetched: ITopic | undefined): void {
    displayConsoleLogs && console.log(`ManageTopicPage. Entered onTopicFetched()`);
    if (topicFetched !== undefined) {
      displayConsoleLogs && console.log(`ManageTopicPage. In onTopicFetched(). topicFetched.name: ${topicFetched.name}`);
      // set the returned Topic to be the one we are editing
      setTopic(topicFetched);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param event A Submit event
   */
  function handleFormSubmit(topic: ITopic): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request saving the topic
        dispatch(topicSaveRequest(topic));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  displayConsoleLogs && console.log(`ManageTopicPage. Before 'return' call, topic: ${topic === undefined ? 'undefined' : 'id: ' + topic.id + '; name: ' + topic.name}`);

  return (
    <>
      {currentChannel && (isNewObject !== undefined) &&
        <>
          <GenericPageContainer
            maxWidth="sm"
            showBackButton={true}
            // pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageTopic_New : PageAndViewTitleStringAssets.pageTitle_ManageTopic_Existing}
            pageTitle={pageTitle}
            onCloseAlert={resetSaveTopicStatus}
          >
            {topic &&
              <TopicForm
                topic={topic}
                saveRequestInProgress={operationInProgress}
                onSubmit={handleFormSubmit}
              />
            }

          </GenericPageContainer>
        </>
      }
    </>
  );

} // const ManageTopicPage...

export default ManageTopicPage;
