import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const userUpdateEmailAddressFailureSlice = createSlice({
  name: 'userUpdateEmailAddressFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userUpdateEmailAddressFailure(state, action) {
      return action.payload;
    },

    // action has a payload with a null value
    userUpdateEmailAddressFailureClear(state, action) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userUpdateEmailAddressFailure, userUpdateEmailAddressFailureClear } = userUpdateEmailAddressFailureSlice.actions;

// export the core reducer for the slice
export const userUpdateEmailAddressFailureReducer = userUpdateEmailAddressFailureSlice.reducer;

export default userUpdateEmailAddressFailureSlice.reducer;