import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const socialMediaPostDeleteFailureSlice = createSlice({
  name: 'socialMediaPostDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    socialMediaPostDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    socialMediaPostDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { socialMediaPostDeleteFailureChange, socialMediaPostDeleteFailureClear } = socialMediaPostDeleteFailureSlice.actions;

// export the core reducer for the slice
export const socialMediaPostDeleteFailureReducer = socialMediaPostDeleteFailureSlice.reducer;

export default socialMediaPostDeleteFailureSlice.reducer;