
export enum enumLocale {
  English = 'en',
  English_US = 'en-US',
  English_UK = 'en-GB',
  French = 'fr',
  German = 'de',
  Italian = 'it'
}

