import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchAudioLinksStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchAudioLinksStatusSlice = createSlice({
  name: 'fetchAudioLinksStatus',
  initialState,
  reducers: {
    fetchAudioLinksStatusChange: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    fetchAudioLinksStatusClear: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { fetchAudioLinksStatusChange, fetchAudioLinksStatusClear } = fetchAudioLinksStatusSlice.actions;

// export the core reducer for the slice
export const fetchAudioLinksStatusReducer = fetchAudioLinksStatusSlice.reducer;

export default fetchAudioLinksStatusSlice.reducer;