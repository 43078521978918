import { getStorage, ref, FirebaseStorage, StorageReference, getDownloadURL } from "firebase/storage";
import _ from 'lodash';
import { enumImageFileSize } from '../../../dataObjects/enums';
import { FirebaseAppSingleton } from "../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { convertImageFileSizeToFilenameSuffix } from ".";


export function getDownloadUrlsForImageSizes(baseStoragePath: string, fileSizes: Array<enumImageFileSize>): Promise<Map<string, string>> {
  return new Promise<Map<string, string>>(async (resolve, reject) => {
    try {
      // create a Map object that will be returned via 'resolve'
      let downloadUrlsMap: Map<string, string> = new Map<string, string>();

      // split the baseStoragePath (e.g., 'userFiles/S033Yuu/imageFiles/5993JOL2/IMG_00385.jpeg') into 2 parts:
      //  1) The baseStoragePath with filename, but without the extension (e.g., 'userFiles/S033Yuu/imageFiles/5993JOL2/IMG_00385'); and
      //  2) The file extension with preceding period ('.') (e.g., '.jpeg')
      const indexOfFilenameExtension: number = baseStoragePath.lastIndexOf('.');
      const pathUpToFilenameExtension: string = baseStoragePath.substr(0, indexOfFilenameExtension);
      const filenameExtension: string = baseStoragePath.substr(indexOfFilenameExtension, baseStoragePath.length - indexOfFilenameExtension);

      // get a reference to the Firebase Cloud Storage Service
      const storageService: FirebaseStorage = getStorage(FirebaseAppSingleton.getInstance().firebaseApp);

      let fileSizesForEvaluation: Array<enumImageFileSize> = [];

      if (fileSizes !== undefined && fileSizes.length > 0) {
        fileSizesForEvaluation = _.cloneDeep(fileSizes);
      } else {
        fileSizesForEvaluation = [enumImageFileSize.Thumbnail, enumImageFileSize.Small, enumImageFileSize.Medium, enumImageFileSize.Large];
      }

      // iterate the array of file sizes to build a storage path for each, and request from Google Cloud Storage the download URL for each
      for (let idx: number = 0; idx < fileSizesForEvaluation.length; idx++) {
        // get the suffix to apply to the filename (e.g., '_100x100' for thumbnail)
        const filenameSuffix: string = convertImageFileSizeToFilenameSuffix(fileSizesForEvaluation[idx]);

        // build the destination storage path for the image file (e.g., 'userFiles/S033Yuu/imageFiles/5993JOL2/IMG_00385_100x100.jpeg')
        const destinationPath: string = pathUpToFilenameExtension + filenameSuffix + filenameExtension;

        // obtain a reference to the file, based on the destination path
        const fileCloudStorageRef: StorageReference = ref(storageService, destinationPath);

        // get the Url for the file that now sits in Cloud Storage (note: the url includes QueryString parameters for alt=media & token=XXX) 
        const downloadUrl: string = await getDownloadURL(fileCloudStorageRef);

        // if the downloadUrl is a non-null, non-undefined, non-empty string, add the downloadUrl value to the Map object using the fileSize as the key
        if (downloadUrl) {
          downloadUrlsMap.set(fileSizesForEvaluation[idx], downloadUrl);
        }

      }
      resolve(downloadUrlsMap);

    } catch (error: any) {
      reject(error);
    }
  });
}
