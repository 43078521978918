import { IPersistable, IPersistableAsJson } from "../../../../dataObjects/models/persistence/Persistable";
import { typeUniqueId } from "../../../../dataObjects/types";
import { enumPersistableObjectType } from "../../../../dataObjects/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { enumMdbErrorType } from "../../../../errorObjects/enums";
import { MdbError } from "../../../../errorObjects/MdbError";


export function getSingleObjectById<T extends IPersistable, TasJson extends IPersistableAsJson>(id: typeUniqueId, objectType: enumPersistableObjectType, callback: (object: T | undefined) => void): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {

    try {
      // declare a variable to hold the repository definition
      let firestoreRepository: IFirestoreBaseRepository<T, TasJson>;
      let objectToReturn: T | undefined;

      switch (objectType) {
        case enumPersistableObjectType.AudioLink:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.AudioLink) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.Category:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.Channel:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.DocumentLink:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.DocumentLink) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.HyperLink:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.HyperLink) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.ImageLink:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ImageLink) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.Note:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Note) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.ObjectSharingRequestTracker:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ObjectSharingRequestTracker) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.SocialMediaPost:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.SocialMediaPost) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.ThemeSpecs:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ThemeSpecs) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.Topic:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Topic) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.TopicItem:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.TopicItem) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.User:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.UserCategories:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.UserCategories) as IFirestoreBaseRepository<T, TasJson>;
          break;

        case enumPersistableObjectType.VideoLink:
          firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.VideoLink) as IFirestoreBaseRepository<T, TasJson>;
          break;

        default:
          throw new MdbError(`getSingleObjectById() does not yet provide support for this type: ${objectType}`, enumMdbErrorType.InvalidOperation);
      }

      objectToReturn = await firestoreRepository.get(id);

      callback(objectToReturn);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}

// export function getSingleObjectById<T extends IPersistable, TasJson extends IPersistableAsJson>(id: typeUniqueId, objectType: enumPersistableObjectType): Promise<T | undefined> {
//   return new Promise<T | undefined>(async (resolve, reject) => {
//     try {
//       // declare a variable to hold the repository definition
//       let firestoreRepository: IFirestoreBaseRepository<T, TasJson>;
//       let objectToReturn: T | undefined;

//       switch (objectType) {
//         case enumPersistableObjectType.User:
//           firestoreRepository = FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as IFirestoreBaseRepository<T, TasJson>;
//           objectToReturn = await firestoreRepository.get(id);
//           break;

//         default:
//           throw new MdbError(`getSingleObjectById() does not yet provide support for this type: ${objectType}`);
//       }

//       resolve(objectToReturn);
//     } catch (error: any) {
//       reject(error);
//     }
//   });
// }