import { enumLocale } from '../../../enums';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { ILocaleTranslation, ILocaleTranslationAsJson } from '.';

/** 
 * @class LocaleTranslation represents the translated value of something to a given locale
 */
export class LocaleTranslation<T> extends JsonConverter implements ILocaleTranslation<T> {
  /**
   * @method Constructor method
   * @param {enumLocale} locale The locale that the translated value represents
   * @param {T} translatedValue The translated value
   */
  constructor(
    locale: enumLocale,
    translatedValue: T | undefined
  ) {
    super();

    this.locale = locale;
    this.translatedValue = translatedValue;
  }

  /*-----------------------------------------------*/
  /**
   * @property {enumLocale} _locale property The locale that the translated value represents
   */
  private _locale: enumLocale = enumLocale.English_US;

  /**
   * @method locale is an optional getter method for _locale
   */
  get locale() {
    return this._locale;
  }

  /**
   * @method locale is an optional setter method for _locale
   * @param {string} value is the input value for setting _locale
   */
  set locale(value) {
    this._locale = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {T | undefined} _translatedValue property is the translated value
   */
  private _translatedValue: T | undefined = undefined;

  /**
   * @method translatedValue is an optional getter method for _translatedValue
   */
  get translatedValue() {
    return this._translatedValue;
  }

  /**
   * @method translatedValue is an optional setter method for _translatedValue
   * @param {T | undefined} value is the input value for setting _translatedValue
   */
  set translatedValue(value) {
    this._translatedValue = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ILocaleTranslation<T>} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ILocaleTranslation<T> {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ILocaleTranslation<T> = Object.create(LocaleTranslation.prototype);
    Object.assign(copyOfObject, this);


    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ILocaleTranslationAsJson<T> {
    try {
      let jsonObject: ILocaleTranslationAsJson<T> = {
        locale: this._locale,
        translatedValue: this._translatedValue
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ILocaleTranslationAsJson<string>} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ILocaleTranslationAsJson<string>, includeContainedObjects: boolean = true): ILocaleTranslation<string> {
    try {
      let localeTranslationObject: ILocaleTranslation<string> = Object.create(LocaleTranslation.prototype);

      // copy all the fields from the json object
      localeTranslationObject.locale = jsonObject.locale;
      localeTranslationObject.translatedValue = jsonObject.translatedValue;

      return localeTranslationObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/


}
