import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface ISaveChannelFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const channelSaveFailureSlice = createSlice({
  name: 'channelSaveFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    channelSaveFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    channelSaveFailureClear(state, action) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { channelSaveFailureChange, channelSaveFailureClear } = channelSaveFailureSlice.actions;

// export the core reducer for the slice
export const channelSaveFailureReducer = channelSaveFailureSlice.reducer;

export default channelSaveFailureSlice.reducer;