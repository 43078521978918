import React, { useCallback } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  List as DrillDownIcon,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { listViewItemStyles } from '../../../styles';
import { ITopic } from '../../../../dataObjects/models/topics/Topic';
import { IUserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { TooltipStringAssets } from '../../../../assets/stringAssets';
import { setCurrentTopic } from "../../../../uiMiddleware-redux/slices/topic/currentTopicSlice";
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';


// The next line is preserved for update to React 18, once peer dependencies will allow the update
// export interface TopicsListViewItemProps extends PropsWithChildren<unknown> {
export interface TopicsListViewItemProps {
  topic: ITopic;
  userPermissions: IUserAccessPermissionsForObject;
  onDelete: (topic: ITopic) => void;
}

export const TopicsListViewItem: React.FC<TopicsListViewItemProps> = (props: TopicsListViewItemProps) => {
  TopicsListViewItem.displayName = 'Topics List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render TopicsListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { onDelete, topic, userPermissions } = props;
  const { id, name, description } = topic;

  const dispatch = useAppDispatch();

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // memoized function that handles click for drilldown to topicItems
  const handleTopicItemsDrilldownClicked: () => void = useCallback((): void => {
    // first, dispatch a request to set the current Topic in Redux state to be the one passed to this component
    dispatch(setCurrentTopic(topic));

    // then, use the navigate object to navigate to the TopicItems page
    navigate(`/${id}/topicItems`)
  },
    [dispatch, navigate, id, topic],
  );

  return (
    <>
      <div className={classes.tableRow} key={id}>
        {/* Data Row 1: Name & Action Buttons  */}
        <Grid item container xs={12} direction="row" >
          {/* Name */}
          <Grid item container xs={8} sm={9} >
            <Typography className={classes.title} variant="h6">
              {name}
            </Typography>
          </Grid>
          {/* Action Buttons */}
          <Grid item container xs={4} sm={3} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Topics button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} justifyContent='center' >
              {/* the tooltip for the drilldown button will either be "Drill down" (if user has sufficient permission) or "View XXX" (insufficient permission) */}
              <Tooltip title={!userPermissions.hasViewPermission ? TooltipStringAssets.drillDown_Disallowed : `View ${topic.topicItemNameAliasPlural}`} arrow>
                <IconButton
                  size="small"
                  className={`${classes.actionButton} ${classes.drillDownIconButton}`}
                  disabled={!userPermissions.hasViewPermission}
                  onClick={() => handleTopicItemsDrilldownClicked()}
                >
                  <DrillDownIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* Edit button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} justifyContent='center' >
              <Tooltip
                // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
                title={!userPermissions.hasEditPermission ? TooltipStringAssets.edit_Disallowed : TooltipStringAssets.edit}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.editIconButton}`}
                    disabled={!userPermissions.hasEditPermission}
                    onClick={() => id && navigate(`/topic/${id}`)}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={4} justifyContent='center' >
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (if user has sufficient permission) or "Delete disallowed" (insufficient permission)
                title={!userPermissions.hasEditPermission ? "Delete disallowed" : "Delete"}
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    disabled={!userPermissions.hasEditPermission}
                    onClick={() => topic && onDelete(topic)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
        {/* Data Row 2: Description  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default TopicsListViewItem;