import { IFirestoreThemeSpecsRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreThemeSpecsRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IThemeSpecs, IThemeSpecsAsJson } from "../../../../dataObjects/models/themes/ThemeSpecs";


export function deleteThemeSpecs(category: IThemeSpecs): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a ThemeSpecs repository from the repository factory
      const firestoreThemeSpecsRepository: IFirestoreThemeSpecsRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ThemeSpecs) as
        IFirestoreBaseRepository<IThemeSpecs, IThemeSpecsAsJson>;

      // call the repository delete() method to delete the object
      await firestoreThemeSpecsRepository.delete(category.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}