import { IAccessPermissions } from ".";

/** 
 * @class AccessPermissions A class that provides details about a User's permissions for accessing a specific object
 */
export class AccessPermissions implements IAccessPermissions {
  /**
   * @method Constructor method
   * 
   * @param {boolean} hasViewPermission Whether View permission has been granted.
   * @param {boolean} hasEditPermission Whether Edit permission has been granted.
   * @param {boolean} hasAdminPermission Whether Administrative permission has been granted.
   */
  /**
   * @method Constructor method
   */
  constructor(
    hasViewPermission?: boolean,
    hasEditPermission?: boolean,
    hasAdminPermission?: boolean,
  ) {

    if (hasViewPermission !== undefined) {
      this._hasViewPermission = hasViewPermission;
    }

    if (hasEditPermission !== undefined) {
      this._hasEditPermission = hasEditPermission;
    }

    if (hasAdminPermission !== undefined) {
      this._hasAdminPermission = hasAdminPermission;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {boolean} hasViewPermission Whether View permission has been granted.
   */
  private _hasViewPermission: any = false;

  /**
   * @method hasViewPermission A getter method for _hasViewPermission
   * @returns {any} The value of _hasViewPermission.
   */
  get hasViewPermission(): any {
    return this._hasViewPermission;
  }

  /**
   * @method hasViewPermission Setter method for _hasViewPermission
   * @param {IImageLink} value The input value for setting _hasViewPermission
   */
  set hasViewPermission(value: boolean) {
    this._hasViewPermission = value;
  }

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} hasEditPermission Whether Edit permission has been granted.
   */
  private _hasEditPermission: any = false;

  /**
   * @method hasEditPermission A getter method for _hasEditPermission
   * @returns {any} The value of _hasEditPermission.
   */
  get hasEditPermission(): any {
    return this._hasEditPermission;
  }

  /**
   * @method hasEditPermission Setter method for _hasEditPermission
   * @param {IImageLink} value The input value for setting _hasEditPermission
   */
  set hasEditPermission(value: boolean) {
    this._hasEditPermission = value;
  }

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} hasAdminPermission Whether Administrative permission has been granted.
   */
  private _hasAdminPermission: any = false;

  /**
   * @method hasAdminPermission A getter method for _hasAdminPermission
   * @returns {boolean} The value of _hasAdminPermission.
   */
  get hasAdminPermission(): boolean {
    return this._hasAdminPermission;
  }

  /**
   * @method hasAdminPermission Setter method for _hasAdminPermission
   * @param {IImageLink} value The input value for setting _hasAdminPermission
   */
  set hasAdminPermission(value: boolean) {
    this._hasAdminPermission = value;
  }

  /*-----------------------------------------------*/

}
