/**
 * @class PageAndViewTitleStringAssets A collection of string assets that provide page & view titles
 */
export class PageAndViewTitleStringAssets {

  // REGISTRATION
  static pageTitle_Registration: string = `User Registration`;

  // LOGIN
  static pageTitle_Login: string = `Sign in`;

  // LOGOUT
  static pageTitle_Logout: string = `Sign out`;

  // BACKUP
  static pageTitle_BackupDataFiles: string = `Backup Data Files`;

  // About
  static pageTitle_About: string = `About MyTennisBrain`;

  // AUDIOLINKS
  static viewTitle_Audios: string = `Audio Clips`;
  static pageTitle_ManageAudioLink_New: string = `New Audio Clip`;
  static pageTitle_ManageAudioLink_Existing: string = `Edit Audio Clip Info`;

  // AUTHENTICATION/VERIFICATION PAGES
  static pageTitle_AuthenticationVerification: string = `Authentication / Verification`;
  static pageTitle_EmailChangeAndVerification: string = `Email Address Change & Verification`;
  static pageTitle_EmailVerification: string = `Email Address Verification`;
  static pageTitle_RecoverEmail: string = `Recover Email`;
  static pageTitle_ResetPassword: string = `Reset Password`;
  static pageTitle_SigninWithEmailLink: string = `Sign-in With Email Link`;

  // CATEGORIES
  static pageTitle_Categories: string = `Your Categories`;
  static pageTitle_ManageCategory_New: string = `New Category`;
  static pageTitle_ManageCategory_Existing: string = `Edit Category`;

  // CHANNELS
  static pageTitle_Channels: string = `Your Channels`;
  static pageTitle_ManageChannel_New: string = `New Channel`;
  static pageTitle_ManageChannel_Existing: string = `Edit Channel`;
  static objectSpecificCategory_Channel: string = 'Channel';

  // CHANNEL SHARING
  static pageTitle_ChannelSharing: string = `Channel Sharing`;
  static pageTitle_ManageChannelSharing: string = `Manage Channel Sharing`;
  static pageTitle_ManageChannelSharing_New: string = `New Channel Sharing Request`;
  static pageTitle_ManageChannelSharing_Existing: string = `Edit Channel Sharing Request`;
  static pageTitle_ManageChannelSharingUser_Existing: string = `Edit Channel Sharing User`;

  // DIGITAL MEDIA
  static pageTitle_DigitalMedia: string = `Digital Media`;
  static pageTitle_DigitalMediaSearchCriteria: string = `Search for Digital Media`;
  static pageTitle_DigitalMediaSearchResults: string = `Digital Media Search Results`;
  static objectSpecificCategory_SearchPhrase: string = 'Search Phrase';

  // DOCUMENTLINKS
  static viewTitle_Documents: string = `Documents`;
  static pageTitle_ManageDocumentLink_New: string = `New Document`;
  static pageTitle_ManageDocumentLink_Existing: string = `Edit Document Info`;

  // HYPERLINKS
  static viewTitle_HyperLinks: string = `Hyperlinks`;
  static pageTitle_ManageHyperLink_New: string = `New Hyperlink`;
  static pageTitle_ManageHyperLink_Existing: string = `Edit Hyperlink Info`;

  // IMAGELINKS
  static viewTitle_Images: string = `Images`;
  static pageTitle_ManageImageLink_New: string = `New Image`;
  static pageTitle_ManageImageLink_Existing: string = `Edit Image Info`;

  // NOTES
  static viewTitle_Notes: string = `Notes`;
  static pageTitle_ManageNote_New: string = `New Note`;
  static pageTitle_ManageNote_Existing: string = `Edit Note Info`;

  // POPULATE SEARCH METADATA
  static pageTitle_PopulateSearchMetadata: string = `Populate Search Metadata`;

  // SHARING REQUESTS
  static pageTitle_SharingRequests: string = `Sharing Requests`;

  // SOCIAL MEDIA
  static viewTitle_SocialMediaPosts: string = `Social Media Posts`;
  static pageTitle_ManageSocialMediaPost_New: string = `New Post`;
  static pageTitle_ManageSocialMediaPost_Existing: string = `Edit Post Info`;

  // THEMES
  static pageTitle_CustomTheme: string = `Custom Themes`;
  static pageTitle_ManageCustomTheme_New: string = `New Custom Theme`;
  static pageTitle_ManageCustomTheme_Existing: string = `Edit Custom Theme`;
  static pageTitle_SetTheme: string = `Set Theme`;

  // TOPICS
  static pageTitle_Topics: string = `Topics`;
  static pageTitle_ManageTopic_New: string = `New Topic`;
  static pageTitle_ManageTopic_Existing: string = `Edit Topic`;

  // TOPIC ITEMS
  static pageTitle_TopicItems: string = `Topic Items`;
  static pageTitle_ManageTopicItem_New: string = `New Topic Item`;
  static pageTitle_ManageTopicItem_Existing: string = `Edit Topic Item`;

  // USER REGISTRATION
  static pageTitle_UserRegistration: string = `Create New Account`;

  // USER SETTINGS
  static pageTitle_UserSettings: string = `User Settings`;

  // UPDATE EMAIL ADDRESS
  static pageTitle_UpdateUserEmailAddress: string = `Update Email Address`;

  // EXPUNGE USER
  static pageTitle_ExpungeUserAccount: string = `Expunge User Account`;

  // VIDEOLINKS
  static viewTitle_Videos: string = `Videos`;
  static pageTitle_ManageVideoLink_New: string = `New Video`;
  static pageTitle_ManageVideoLink_Existing: string = `Edit Video Info`;

}