import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { TooltipStringAssets } from '../../../../assets/stringAssets';


export interface ICategoriesCardGridViewItemProps extends PropsWithChildren<unknown> {
  category: ICategory;
  onDelete: (category: ICategory) => void;
}


export const CategoriesCardGridViewItem: React.FC<ICategoriesCardGridViewItemProps> = (props: ICategoriesCardGridViewItemProps) => {

  const { onDelete, category } = props;

  const classes = cardGridViewItemStyles();

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>
        {/* <div style={{ margin: '5px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: 'yellow', border: '1px solid black', color: 'black' }}>
          <Typography variant='caption' align='left'>
            {'Uncategorized'}
          </Typography>
        </div> */}

        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <div style={{
          margin: '5px', paddingLeft: '5px', paddingRight: '5px', paddingBottom: '8px',
          backgroundColor: `${category.displayBackground}`, border: `1px solid ${category.displayBorder}`
        }}>
          <Typography className={classes.cardTitle} variant='h6' align='center' style={{ color: `${category.displayFontColor}` }}>
            {category.name}
          </Typography>
        </div>

        <CardContent>
          {/* <Typography variant="body1" color="textPrimary" component="p"> */}
          <Typography className={classes.cardDescription} variant="body1">
            {category.description}
          </Typography>

        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip title={TooltipStringAssets.edit} arrow>
            <IconButton
              className={`${classes.actionIconButton} ${classes.editIconButton}`}
              size="small"
              aria-label="edit"
              onClick={() => navigate(`/category/${category.id}`)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            // the tooltip for the delete button will either be "Delete" (if not the user's Uncategorized category) or "Delete disallowed" (for the user's Uncategorized category)
            title={category.id === category.ownerId ? "Delete disallowed" : "Delete"}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                // this button will be disabled if the Category is the user's Uncategorized category
                disabled={category.id === category.ownerId}
                onClick={() => category && onDelete(category)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>
      </Card>
    </>
  );

}