import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import DigitalMediaSearchCriteriaForm from '../../../forms/DigitalMediaSearchCriteriaForm/DigitalMediaSearchCriteriaForm';
import { DigitalMediaSearchCriteria, IDigitalMediaSearchCriteria } from '../../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchCriteria';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { searchForDigitalMediaObjects } from '../../../../firebaseServices/dataServices/dataServiceActions/cloudSqlActions/searchForDigitalMediaObjects';
import { IDigitalMediaSearchResults } from '../../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchResults';
import OneButtonDialog from '../../../dialogs/OneButtonDialog/OneButtonDialog';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { useAppDispatch, useAppSelector } from '../../../../uiMiddleware-redux/store/configureStore';
import { digitalMediaClearSearchResults, digitalMediaSetSearchResults } from '../../../../uiMiddleware-redux/slices/digitalMediaSearch/digitalMediaSearchResultsSlice';
import { digitalMediaSetSearchCriteria } from '../../../../uiMiddleware-redux/slices/digitalMediaSearch/digitalMediaSearchCriteriaSlice';


/**
 * @interface IDigitalMediaSearchCriteriaPageProps Input properties for the ManageTopicPage
 */
export interface IDigitalMediaSearchCriteriaPageProps extends PropsWithChildren<unknown> {
}

/**
 * @function DigitalMediaSearchCriteriaPage A React Function Component for manageing (creating or editing) a TopicItem.
 * @param {IDigitalMediaSearchCriteriaPageProps} props Input properties
 */
const DigitalMediaSearchCriteriaPage: React.FC<IDigitalMediaSearchCriteriaPageProps> = (props: IDigitalMediaSearchCriteriaPageProps) => {

  DigitalMediaSearchCriteriaPage.displayName = "Digital Media Search Criteria Page";

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('Entered/Refreshed DigitalMediaSearchCriteriaPage component');

  const dispatch = useAppDispatch();

  const params = useParams();
  
  // whether to show a dialog indicating that no search results were found
  const [showNoSearchResultsDialog, setShowNoSearchResultsDialog] = useState<boolean>(false);

  /**
   * @function prepareNewTopic Prepares/creates a new topicItem that will be used if user is requesting to create/add a new topicItem.
   * @param parentChannel 
   */
  const prepareDefaultDigitalMediaSearchCriteria: () => IDigitalMediaSearchCriteria = useCallback(
    () => {
      let newDigitalMediaSearchCriteria: IDigitalMediaSearchCriteria;

      newDigitalMediaSearchCriteria = new DigitalMediaSearchCriteria('', true, true);

      return newDigitalMediaSearchCriteria;
    }, []
  );

  // fetch from redux state whatever digital media search criteria that has been saved
  const digitalMediaSearchCriteria: IDigitalMediaSearchCriteria | null | undefined = useAppSelector((state: IStoreState) => state.digitalMediaSearchCriteria);

  displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaPage after retrieving from IStoreState. digitalMediaSearchCriteria: ${JSON.stringify(digitalMediaSearchCriteria)}`);

  // ensure that the search criteria object is a DigitalMediaSearchCriteria object
  const digitalMediaSearchCriteriaObj: IDigitalMediaSearchCriteria = new DigitalMediaSearchCriteria(
    digitalMediaSearchCriteria?.searchPhrase ?? '',
    digitalMediaSearchCriteria?.searchAllAccessibleChannels ?? false,
    digitalMediaSearchCriteria?.includeAllDigitalMediaTypes ?? false,
    digitalMediaSearchCriteria?.channelIds,
    digitalMediaSearchCriteria?.digitalMediaTypes
  );

  // digital media search criteria to be passed to the search criteria form
  const [userDigitalMediaSearchCriteria, setUserDigitalMediaSearchCriteria] = useState<IDigitalMediaSearchCriteria>(digitalMediaSearchCriteriaObj ?? prepareDefaultDigitalMediaSearchCriteria());

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param event A Submit event
   */
  function handleFormSubmit(requestedDigitalMediaSearchCriteria: IDigitalMediaSearchCriteria): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {

        displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaPage.handleFormSubmit. requestedDigitalMediaSearchCriteria: ${JSON.stringify(requestedDigitalMediaSearchCriteria)}`);

        // dispatch an action to clear the current search results in redux state
        dispatch(digitalMediaClearSearchResults());

        // dispatch an action to clear the current search criteria in redux state (for some reason, a call to merely set the new
        // search criteria is not getting set to the browser's Application Local Storage; therefore, we make this extra call)
        dispatch(digitalMediaSetSearchCriteria(prepareDefaultDigitalMediaSearchCriteria()));

        // dispatch an action to save the current search criteria submitted by the user
        dispatch(digitalMediaSetSearchCriteria(requestedDigitalMediaSearchCriteria));

        // call service function to request a search for digital media objects using the search criteria
        const digitalMediaSearchResults: IDigitalMediaSearchResults = await searchForDigitalMediaObjects(requestedDigitalMediaSearchCriteria);

        displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaPage.handleFormSubmit. digitalMediaSearchResults: ${JSON.stringify(digitalMediaSearchResults)}`);

        // if there are no search results -OR- if there are too many search results, set up a message for the form to diaplay;
        // otherwise, navigate to the search results display page
        if (digitalMediaSearchResults.searchResults.length === 0) {
          displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaPage.handleFormSubmit. No search results found.`);
          setShowNoSearchResultsDialog(true);
        } else {
          // dispatch an action to set results into redux store
          dispatch(digitalMediaSetSearchResults(digitalMediaSearchResults));

          // Navigate to the search results page
          navigate('/digitalMediaSearchResults');
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  function handleNoSearchResultsDialogButtonClick() {
    setShowNoSearchResultsDialog(false);
  }

  displayConsoleLogs && console.log(`In DigitalMediaSearchCriteriaPage before return{} call. userDigitalMediaSearchCriteria: ${JSON.stringify(userDigitalMediaSearchCriteria)}`);

  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_DigitalMediaSearchCriteria}
      >
        
          {/* Dialog to display message that no search results were found */}
          <OneButtonDialog
            showDialog={showNoSearchResultsDialog}
            headerText={MessagesStringAssets.searchResults_NoDigitalMediaFoundForSearchCriteriaHeader}
            bodyText={MessagesStringAssets.searchResults_NoDigitalMediaFoundForSearchCriteria}
            buttonText={ControlsStringAssets.okButtonText}
            onButtonClick={handleNoSearchResultsDialogButtonClick}
          />

        <DigitalMediaSearchCriteriaForm
          digitalMediaSearchCriteria={userDigitalMediaSearchCriteria}
          // saveRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit} />

      </GenericPageContainer>
    </>
  );

} // const DigitalMediaSearchCriteriaPage...

export default DigitalMediaSearchCriteriaPage;
