import { take, put } from 'redux-saga/effects';
// import { actionTypeEnums, logoutFailure } from '../../actions';
import { logout } from '../../../firebaseServices/securityServices/userAccount/userAccountActions';
// import { logoutSuccess } from '../../actions/logout/logoutSuccess';
import { logoutStatusChange } from '../../slices/logout/logoutSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function logoutRequestedSaga A saga workflow pertaining to a request to log user out of the application.
 */
export function* logoutRequestedSaga() {
  // console.info('(logoutRequestedSaga) entered logoutRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    // // wait on the actionTypeEnums.LOGOUT_REQUESTED action to be dispatched
    // yield take(actionTypeEnums.LOGOUT_REQUESTED);
    // wait on the 'logoutRequest' action to be dispatched
    const logoutStatusChangeData = yield take(logoutStatusChange);
    const { payload: logoutStatusAsAny } = logoutStatusChangeData;
    const logoutStatus: enumWorkflowState = logoutStatusAsAny as enumWorkflowState;

    // if the status change was a request to logout...
    if (logoutStatus === enumWorkflowState.Requested) {
      try {
        // dispatch an action to indicate that the logout in progress
        yield put(logoutStatusChange(enumWorkflowState.InProgress));

        // make a request to logout the user
        yield logout();

        // // dispatch an action to indicate that the logout was successful
        // yield put(logoutSuccess());
        // dispatch an action to indicate that the logout was successful
        yield put(logoutStatusChange(enumWorkflowState.Success));
      } catch (error: any) {
        // // dispatch an action to indicate that the logout failed
        // yield put(logoutFailure());
        // dispatch an action to indicate that the logout failed
        yield put(logoutStatusChange(enumWorkflowState.Failure));
      }
    }
  }
}
