import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { ControlsStringAssets, MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { useProcessPerManageObjectPageStatus } from '../../customHooks';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import PopulateSearchDataForm from '../../forms/PopulateSearchDataForm/PopulateSearchDataForm';
import TwoButtonAcceptanceDialog from '../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { composeMessageUsingStringAsset } from '../../messages';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { searchMetadataPopulateStatusChange } from '../../../uiMiddleware-redux/slices/searchMetadata/searchMetadataPopulateStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { searchMetadataPopulateRequest } from '../../../uiMiddleware-redux/slices/searchMetadata/searchMetadataPopulateDataSlice';


/**
 * @interface IPopulateSearchDataPageProps Input properties for the PopulateSearchDataPage
 */
export interface IPopulateSearchDataPageProps extends PropsWithChildren<unknown> {
}

const PopulateSearchDataPage: React.FC<IPopulateSearchDataPageProps> = (props: IPopulateSearchDataPageProps) => {

  PopulateSearchDataPage.displayName = "Populate Search Data Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  // the email address for the user account where search metadata will be populated
  const [emailForUserAccountToPopulate, setEmailForUserAccountToPopulate] = useState<string | undefined>(undefined);

  // whether to display a confirmation dialog for populating search metadata
  const [showPopulateSearchMetadataConfirmationDlg, setShowPopulateSearchMetadataConfirmationDlg] = useState<boolean>(false);

  const resetPopulateSearchMetadataStatus: () => void = useCallback(() => {
    dispatch(searchMetadataPopulateStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetPopulateSearchMetadataStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetPopulateSearchMetadataStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetPopulateSearchMetadataStatus]);

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.populateSearchMetadataStatus,
    failureErrorStateObj: (state: IStoreState) => state.populateSearchMetadataFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.searchData_PopulateSearchMetadataFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_PopulateSearchMetadata,
    notificationSuccessMessage: MessagesStringAssets.searchData_PopulateSearchMetadataSuccess,
  }

  // if there is defined email address, append the email address to the message for success
  if (emailForUserAccountToPopulate !== undefined) {
    displayConsoleLogs && console.log(`PopulateSearchDataPage. 'emailForUserAccountToPopulate' IS defined: ${emailForUserAccountToPopulate}`);
    processPerManageObjectPageStatusInput.notificationSuccessMessage = MessagesStringAssets.searchData_PopulateSearchMetadataSuccess;
  } else {
    displayConsoleLogs && console.log(`PopulateSearchDataPage. 'emailForUserAccountToPopulate' is NOT yet defined.`);
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  // function that embedded form component is to call when submitting for a populate SearchMetadataDb operation
  function handleFormSubmit(email?: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`PopulateSearchDataPage. Entered handleFormSubmit(). email address: ${email}`);

        setEmailForUserAccountToPopulate(email);

        // set flag to show the Populate User Account Confirmation dialog
        setShowPopulateSearchMetadataConfirmationDlg(true);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  const handlePopulateSearchMetadataConfirmed = () => {
    displayConsoleLogs && console.log(`PopulateSearchDataPage. Entered handlePopulateSearchMetadataConfirmed(). email address of user account to populate: ${emailForUserAccountToPopulate}`);

    // dispatch an action to request expunging the user account
    dispatch(searchMetadataPopulateRequest(emailForUserAccountToPopulate));

    // call method to reset the local state details for hiding/showing the Populate User Account Confirmation dialog
    populateSearchMetadataDbConfirmationReset();
  }

  function handlePopulateSearchDataAccountCanceled(): void {
    // call method to reset the local state details for hiding/showing the Populate User Account Confirmation dialog
    populateSearchMetadataDbConfirmationReset();
  }

  function populateSearchMetadataDbConfirmationReset() {
    // set flag to hide the delete confirmation dialog
    setShowPopulateSearchMetadataConfirmationDlg(false);
  }

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={PageAndViewTitleStringAssets.pageTitle_PopulateSearchMetadata}
      onCloseAlert={resetPopulateSearchMetadataStatus}
    >
      { /* Only show the form if we're not displaying the confirmation dialog. This is to cause a refresh of the form after release of the confirmation dialog */
        !showPopulateSearchMetadataConfirmationDlg &&
        <PopulateSearchDataForm
          email={emailForUserAccountToPopulate ?? ''}
          populateRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit}
        />
      }

      {/* Confirm Re-sending Account Verification Email Confirmation Dialog */}
      <TwoButtonAcceptanceDialog
        showDialog={showPopulateSearchMetadataConfirmationDlg}
        headerText={MessagesStringAssets.searchData_PopulateSearchMetadataConfirmationHeader}
        bodyText={composeMessageUsingStringAsset(MessagesStringAssets.searchData_PopulateSearchMetadataConfirmation, emailForUserAccountToPopulate, MessagesStringAssets.substitutionKeyword)}
        acceptanceButtonText={ControlsStringAssets.confirmButtonText}
        nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
        onAcceptance={handlePopulateSearchMetadataConfirmed}
        onNonAcceptance={handlePopulateSearchDataAccountCanceled}
      />

    </GenericPageContainer>
  )

}

export default PopulateSearchDataPage;