import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IDeleteCategoryFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const categoryDeleteFailureSlice = createSlice({
  name: 'categoryDeleteFailure',
  initialState,
  reducers: {
    categoryDeleteFailureChange: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    categoryDeleteFailureClear: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { categoryDeleteFailureChange, categoryDeleteFailureClear } = categoryDeleteFailureSlice.actions;

// export the core reducer for the slice
export const categoryDeleteFailureReducer = categoryDeleteFailureSlice.reducer;

export default categoryDeleteFailureSlice.reducer;