import { take, put } from 'redux-saga/effects';
import { saveChannel } from '../../../firebaseServices/dataServices/dataServiceActions/channelActions';
import { channelSaveRequest, channelSaveStatusChange } from '../../slices/channel/channelSaveStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { channelSaveFailureChange } from '../../slices/channel/channelSaveFailureSlice';

/**
 * @function saveChannelRequestedSaga A saga workflow pertaining to a user having requested to save a channel.
 */
export function* saveChannelRequestedSaga() {
  // console.info('(saveChannelRequestedSaga) entered saveChannelRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the channelSaveRequest action to be dispatched and get the payload data...
      const channelSaveRequestData = yield take(channelSaveRequest);
      const { payload: channelAndParentCategoryIdData } = channelSaveRequestData;
      const { channel, parentCategoryId } = channelAndParentCategoryIdData;

      // make a request to save the Channel
      yield saveChannel(channel, parentCategoryId);

      // dispatch an action to indicate that the save operation was successful
      yield put(channelSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(channelSaveStatusChange(enumWorkflowState.Failure));
      yield put(channelSaveFailureChange(error));
    }
  }
}
