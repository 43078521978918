import { take, put } from 'redux-saga/effects';
import { saveUser } from '../../../firebaseServices/dataServices/dataServiceActions/userActions';
import { userSaveRequest, userSaveStatusChange } from '../../slices/user/userSaveStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { userSaveFailureChange } from '../../slices/user/userSaveFailureSlice';

/**
 * @function saveUserRequestedSaga A saga workflow pertaining to saving a user record.
 */
export function* saveUserRequestedSaga() {
  // console.info('(saveUserRequestedSaga) entered saveUserRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the userSaveRequest action to be dispatched
      const userSaveRequestData = yield take(userSaveRequest);
      const { payload: user } = userSaveRequestData;

      // make a request to save the user
      yield saveUser(user)

      // dispatch an action to indicate that the save operation was successful
      yield put(userSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(userSaveStatusChange(enumWorkflowState.Failure));
      yield put(userSaveFailureChange(error));
    }
  }
}
