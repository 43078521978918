
export function convertCloudStorageErrorCodeToMessage(errorCode: string): string {
  // declare a variable that will hold an error message
  let errorMessage: string | null = null;

  // use a 'switch' statement to determine what error occurred and to set an error message
  switch (errorCode) {
    case 'storage/unknown':
      errorMessage = 'An unknown error occurred.';
      break;

    case 'storage/object-not-found':
      errorMessage = 'No object exists at the desired reference.';
      break;

    case 'storage/bucket-not-found':
      errorMessage = 'No bucket is configured for Cloud Storage';
      break;

    case 'storage/project-not-found':
      errorMessage = 'No project is configured for Cloud Storage';
      break;

    case 'storage/quota-exceeded':
      errorMessage = 'Quota on your Cloud Storage bucket has been exceeded. If you are on the free tier, upgrade to a paid plan. If you are on a paid plan, reach out to Firebase support.';
      break;

    case 'storage/unauthenticated':
      errorMessage = 'User is unauthenticated, please authenticate and try again.';
      break;

    case 'storage/unauthorized':
      errorMessage = 'User is not authorized to perform the desired action, check your security rules to ensure they are correct.';
      break;

    case 'storage/retry-limit-exceeded':
      errorMessage = 'The maximum time limit on an operation (upload, download, delete, etc.) has been excceded. Try uploading again.';
      break;

    case 'storage/invalid-checksum':
      errorMessage = 'File on the client does not match the checksum of the file received by the server. Try uploading again.';
      break;

    case 'storage/canceled':
      errorMessage = 'User canceled the operation.';
      break;

    case 'storage/invalid-event-name':
      errorMessage = 'Invalid event name provided. Must be one of [`running`, `progress`, `pause`]';
      break;

    case 'storage/invalid-url':
      errorMessage = 'Invalid URL provided to refFromURL(). Must be of the form: gs://bucket/object or https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>';
      break;

    case 'storage/invalid-argument':
      errorMessage = 'The argument passed to put() must be `File`, `Blob`, or `UInt8` Array. The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string.';
      break;

    case 'storage/no-default-bucket':
      errorMessage = 'No bucket has been set in the configuration storageBucket property.';
      break;

    case 'storage/cannot-slice-blob':
      errorMessage = 'Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn not changed.';
      break;

    case 'storage/server-file-wrong-size':
      errorMessage = 'File on the client does not match the size of the file recieved by the server. Try uploading again.';
      break;

    default:
      errorMessage = 'Unknown error';
      break;
  }

  return errorMessage;
}