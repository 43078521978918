import React from 'react';
import { useFirebaseAuth } from '../customHooks';
import { IFirebaseAuthAndUserAuthenticationStatus } from '../customHooks/hookReturnObjects';

export interface IAuthIsLoadedProps {
  children: JSX.Element
}

/**
 * @function AuthIsLoaded A wrapper for the child component hierarchy to guard whether the Firebase Authentication has
 *   been loaded.  The reason for wrapping the child components is to avoid having the UIAppHeader menu bar from rendering
 *   while the Firebase ('firebase') state property is getting set up (as is the case when the screen is refreshed).
 * @param props The child components hierarchy surrounded by the AuthIsLoaded function component.
 * @returns {JSX.Element} A JSX fragment to be displayed, either a notification (the default case) or the children in the
 *   underlying child components object (the children of the input parameter).
 */
// export function AuthIsLoaded(childComponentsHierarchy: { children: JSX.Element }) {
export const AuthIsLoaded: React.FC<IAuthIsLoadedProps> = (props: IAuthIsLoadedProps): JSX.Element => {
  // prepare a default JSX fragment to render. This will be displayed until the Firebase Authentication information has
  // been loaded into state
  let elementToRender: JSX.Element = <div>Loading...</div>;

  // get the Firebase auth state using a custom hook
  const {auth: firebaseAuth, userAuthenticated: userIsAuthenticated}: IFirebaseAuthAndUserAuthenticationStatus = useFirebaseAuth();

  // if the Firebase Authentication information has been loaded into state...
  // if (isLoaded(auth)) {
  if (firebaseAuth) {
    // prepare for rendering the child components hierarchy (the children) that is wrapped by the AuthIsLoaded component
    const { children } = props;
    elementToRender = children;
  }

  return elementToRender;
}