import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsAVimeoAddress Returns whether the URL is a Vimeo address
 * @param url
 */
export const urlIsAVimeoAddress: (url: string) => boolean = (url: string) => {

  // get the regular expression pattern for the website
  const regEx: RegExp = RegularExpressions.Vimeo;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
