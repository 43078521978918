import React, { PropsWithChildren, useCallback, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import TennisBallWithMyTennisBrainLogo from '../../images/TennisBallWithMyTennisBrainLogo/TennisBallWithMyTennisBrainLogo';
import TennisRacketWilsonBlade from '../../images/TennisRacketWilsonBlade/TennisRacketWilsonBlade';
import TennisCourtPlayingSurface from '../../images/TennisCourt/TennisCourtPlayingSurface';

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  paddingAtopPage: {
    marginTop: theme.spacing(2),
  },

  animationPlayground: {
    border: 'none', // '1px solid red',
    width: '100%',
    height: '100%',
    position: 'relative',
    transition: '500ms ease-in',
  },

  tennisBallwithLogo: {
    position: 'absolute',
    height: '10%', // start ball height at 10% of its parent element
    // we'll use left and transform to position the ball in the horizontal center of the parent element
    left: '50%',  // set left side of ball in center of the parent element
    top: '10%',  // place the ball 10% below the top of the parent element
    transform: 'translate(-50%, 0%)',  // move the ball left by 50% of its width
  },

  tennisRacket: {
    position: 'absolute',
    height: '100%',  // start racket height at 100% of its parent element
    // we'll use left and transform to position the racket in the horizontal center of the parent element
    left: '50%',  // set left side of racket in center of the parent element
    top: '0%',  // place the top of the racket at the top of the parent element
    transform: 'translate(-50%, 0%)',  // move the racket left by 50% of its width
  },

  tennisCourt: {
    position: 'absolute',
    height: '75%',  // set court height to be 75% of its parent element
    // we'll use left and transform to position the court in the horizontal & vertical center of the parent element
    left: '50%',  // set left side of court in center of the parent element
    top: '12.5%',  // place the top of the court at 12.5% of the parent element height ((100%-75%)/2)
    transform: 'translate(-50%, -0%)',  // move the court left by 50% of its width
    opacity: 0,  // make the court invisible to start
  },

}));

export interface ITennisRacketBallAndCourtAnimationProps extends PropsWithChildren<unknown> {

}

const TennisRacketBallAndCourtAnimation: React.FC<ITennisRacketBallAndCourtAnimationProps> = (props: ITennisRacketBallAndCourtAnimationProps) => {

  TennisRacketBallAndCourtAnimation.displayName = 'About Page';

  const classes: Record<string, string> = useStyles();


  const tennisBall: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const tennisRacket: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const tennisCourt: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);

  const performAnimation: () => void = useCallback((): void => {

    // set up a timeline object that will be used to coordinating and sequencing the animation objects
    const timeline: gsap.core.Timeline = gsap.timeline();

    // establish the start of animation with a label, kicking off the animation after a short delay
    timeline.addLabel("startOfAnimation", "+=1");

    // rotate the tennis racket to a hitting position, and reduce size (scale) to give appearance of a backswing
    timeline.to(tennisRacket.current, { duration: 2, rotation: "-30", y: "+=10", scale: 0.7 }, "startOfAnimation");

    // add a label to the end of the timeline, indicating that we have completed the backswing
    timeline.addLabel("completedBackSwing");

    // while we're in the midst of the backswing, start the ball rotating in preparation for being struck
    timeline.to(tennisBall.current, { duration: 4.1, x: "-=25", y: "+=25", rotation: 360, ease: "ease-in" }, "startOfAnimation");

    // simulate a forward swing by increasing the size (scale) of the racket
    timeline.to(tennisRacket.current, { duration: 2.2, scale: 1.1, y: "-=10", ease: "power2.in" }, "completedBackSwing");

    // add a label to the timeline, indicating that we have completed the backswing
    // we'll have this label start just before the entire forward swing has completed
    timeline.addLabel("strikingBall", "-=0.5");

    // to simulate the striking of the ball with appearance of it coming towards the viewer, increase the size of the ball as it rotates
    // use 'power2.in' easing to start ball moving quickly
    timeline.to(tennisBall.current, { duration: 2, scale: 5.0, rotation: 1080, ease: "power2.in" }, "strikingBall");

    // while the ball is coming towards the viewer, and after a slight delay, drop the ball down, easing out with a bounce
    timeline.to(tennisBall.current, { duration: 2, y: "+=60", ease: "bounce", delay: 0.5 }, "strikingBall");

    // to finish off, have the tennis court start appearing, maximizing at 75% opacity, after as slight delay from the ball being struck
    timeline.to(tennisCourt.current, { duration: 4, opacity: 1.0, delay: 0.5 }, "strikingBall");

  }, []);

  useEffect(() => {
    performAnimation();
  }, [performAnimation]);

  return (
    <>

      <div className={classes.animationPlayground}>

        <div>
          <TennisCourtPlayingSurface ref={tennisCourt} overallClassName={classes.tennisCourt} />
        </div>

        <div>
          <TennisRacketWilsonBlade ref={tennisRacket} overallClassName={classes.tennisRacket} />
        </div>

        <div>
          <TennisBallWithMyTennisBrainLogo ref={tennisBall} overallClassName={classes.tennisBallwithLogo} />
        </div>

      </div>

    </>
  );

}

export default TennisRacketBallAndCourtAnimation;
