import { IFirestoreVideoLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreVideoLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IVideoLink, IVideoLinkAsJson } from "../../../../dataObjects/models/digitalMedia/VideoLink";


export function saveVideoLink(videoLink: IVideoLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a VideoLink repository from the repository factory
      const firestoreVideoLinkRepository: IFirestoreVideoLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.VideoLink) as
        IFirestoreBaseRepository<IVideoLink, IVideoLinkAsJson>;

      // determine if the videoLink already exists by calling the repository get() method to try and get the object
      const existingVideoLink: IVideoLink | undefined = await firestoreVideoLinkRepository.get(videoLink.id);

      // If the videoLink wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingVideoLink === undefined) {
        // call repository method to create the videoLink
        await firestoreVideoLinkRepository.create(videoLink);
      } else {
        // call repository method to update the videoLink
        await firestoreVideoLinkRepository.update(videoLink);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}