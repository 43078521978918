import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchVideoLinksFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchVideoLinksFailureSlice = createSlice({
  name: 'fetchVideoLinksFailure',
  initialState,
  reducers: {
    setFetchVideoLinksFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchVideoLinksFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchVideoLinksFailure, clearFetchVideoLinksFailure } = fetchVideoLinksFailureSlice.actions;

// export the core reducer for the slice
export const fetchVideoLinksFailureReducer = fetchVideoLinksFailureSlice.reducer;

export default fetchVideoLinksFailureSlice.reducer;