import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IHyperLink } from '../../../dataObjects/models/digitalMedia/HyperLink';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { ISaveHyperLinkRequest } from '../../../dataObjects/models/serviceRequests/hyperLink/SaveHyperLinkRequest';
import { ISaveHyperLinkWorkflowState } from '../../store/SaveHyperLinkWorkflowState';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface ISaveHyperLinkStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const hyperLinkSaveStatusSlice = createSlice({
  name: 'hyperLinkSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    hyperLinkSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    hyperLinkSaveRequest: (state, action: PayloadAction<ISaveHyperLinkRequest>) => {
      const saveHyperLinkRequest: ISaveHyperLinkRequest = action.payload;
      const newState: ISaveHyperLinkWorkflowState = {
        hyperLink: saveHyperLinkRequest.hyperLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.hyperLink_SaveRequested,
      }

      // call internal base function to set the newState into the IStoreState variable
      hyperLinkSaveWorkflowStateChange(newState);
    },
    hyperLinkSaveSuccess: (state, action: PayloadAction<IHyperLink>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.hyperLink_SaveSuccess;

        const newState: ISaveHyperLinkWorkflowState = {
          ...(state as ISaveHyperLinkWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        hyperLinkSaveWorkflowStateChange(newState);
      }
    },
    hyperLinkSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.hyperLink_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveHyperLinkWorkflowState = {
          ...(state as ISaveHyperLinkWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        hyperLinkSaveWorkflowStateChange(newState);
      }
    },
    hyperLinkSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      hyperLinkSaveWorkflowStateChange(null);
    },
  },
})

// Action creators are generated for each function in reducer)
export const {
  hyperLinkSaveWorkflowStateChange,
  hyperLinkSaveRequest,
  hyperLinkSaveSuccess,
  hyperLinkSaveError,
  hyperLinkSaveStatusClear,
} = hyperLinkSaveStatusSlice.actions;

// export the core reducer for the slice
export const hyperLinkSaveStatusReducer = hyperLinkSaveStatusSlice.reducer;

export default hyperLinkSaveStatusSlice.reducer;