// import 'react-native-get-random-values';
// import { default as generateId } from 'nanoid'; 'nanoid/generate';
// import { default as generateIdAsync } from 'nanoid/async/generate';

import { customAlphabet } from 'nanoid';
// *** Async was removed from nanoid with v5.x, so we will remove async operations for nanoid
// import { customAlphabet as customAlphabetAsync } from 'nanoid/async';

/**
 * @class RandomId A class with static methods (singleton and arrays) to generate random Ids synchronously and asynchronously.
 */
export class RandomId {
  static readonly DEFAULT_ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  static readonly DEFAULT_ID_LENGTH = 21;
  static readonly MINIMUM_ID_LENGTH = 4;

  static readonly MINIMUM_COUNT_FOR_IDS_REQUESTED = 1;
  static readonly MAXIMUM_COUNT_FOR_IDS_REQUESTED = 100;

  /*-----------------------------------------------*/
  /**
   * @method alphabetToUse (Private) Determines an Alphabet to use.
   * @param {string} alphabetRequested (Optional) An alphabet (set of characters) to be included in the resulting Id. If not specified, a default 
   * alphabet of alphanumeric characters will be used ('0-9', 'a-z', 'A-Z').
   */
  private static alphabetToUse(alphabetRequested?: string) {
    const alphabetToUse: string = (alphabetRequested !== undefined ? alphabetRequested : RandomId.DEFAULT_ALPHABET);

    return alphabetToUse;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method idLengthToUse (Private) Determines an Id Length to use.
   * @param {number} idLengthRequested (Optional) The length (in characters) of the Id to generate. If not specified, a default length of 21 will be used.
   */
  private static idLengthToUse(idLengthRequested?: number) {
    const idLengthToUse: number = (idLengthRequested !== undefined ? idLengthRequested : RandomId.DEFAULT_ID_LENGTH);

    if (idLengthToUse < RandomId.MINIMUM_ID_LENGTH) {
      throw new RangeError(`A random Id must have a length of at least ${RandomId.MINIMUM_ID_LENGTH} characters. Length specified: ${idLengthToUse}`);
    }

    return idLengthToUse;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method countOfIds (Private) Validates the countRequested parameter for array methods.
   * @param {number} countRequested The number of Ids requested. Must be in the range of 1-100, inclusive.
   */
  private static validatedCountRequested(countRequested: number) {
    if (countRequested < RandomId.MINIMUM_COUNT_FOR_IDS_REQUESTED) {
      throw new RangeError(`To request an array Ids, the count requested must be in the range of ` +
        `${RandomId.MINIMUM_COUNT_FOR_IDS_REQUESTED}-${RandomId.MAXIMUM_COUNT_FOR_IDS_REQUESTED}, inclusive. Count specified: ${countRequested}`);
    }

    return countRequested;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method newId (Synchronous) Generates a random Id.
   * @param {string} alphabet (Optional) An alphabet (set of characters) to be included in the resulting Id. If not specified, a default 
   * alphabet of alphanumeric characters will be used ('0-9', 'a-z', 'A-Z').
   * @param {number} idLength (Optional) The length (in characters) of the Id to generate. If not specified, a default length of 21 will be used.
   * @returns {string} A random ID string.
   */
  static newId(alphabet?: string, idLength?: number): string {
    const alphabetToUse: string = RandomId.alphabetToUse(alphabet);
    const idLengthToUse: number = RandomId.idLengthToUse(idLength);

    // const randomId = generateId(alphabetToUse, idLengthToUse);
    const randomId: string = customAlphabet(alphabetToUse, idLengthToUse)();

    return randomId;
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @method newIdAsync (Asynchronous) Generates a random Id, asynchronously.
  //  * @param {string} alphabet (Optional) An alphabet (set of characters) to be included in the resulting Id. If not specified, a default 
  //  * alphabet of alphanumeric characters will be used ('0-9', 'a-z', 'A-Z').
  //  * @param {number} idLength (Optional) The length (in characters) of the Id to generate. If not specified, a default length of 21 will be used.
  //  * @returns {string} A promise that will deliver a random ID string.
  //  */
  // static newIdAsync(alphabet?: string, idLength?: number): Promise<string> {

  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const alphabetToUse: string = RandomId.alphabetToUse(alphabet);
  //       const idLengthToUse: number = RandomId.idLengthToUse(idLength);

  //       // const randomId = await generateIdAsync(alphabetToUse, idLengthToUse);
  //       const randomId: string = await customAlphabetAsync(alphabetToUse, idLengthToUse)();

  //       resolve(randomId);

  //     } catch (error: any) {
  //       reject(error);
  //     }
  //   });
  // }
  // /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method newIdArray (Synchronous) Generates an array of random Ids.
   * @param {number} countRequested The number of Ids requested. Must be > 0.
   * @param {string} alphabet (Optional) An alphabet (set of characters) to be included in the resulting Id. If not specified, a default 
   * alphabet of alphanumeric characters will be used ('0-9', 'a-z', 'A-Z').
   * @param {number} idLength (Optional) The length (in characters) of the Id to generate. If not specified, a default length of 21 will be used.
   * @returns {string} A random ID string.
   */
  static newIdArray(countRequested: number, alphabet?: string, idLength?: number): Array<string> {
    const idsToGenerate = RandomId.validatedCountRequested(countRequested);
    const alphabetToUse: string = RandomId.alphabetToUse(alphabet);
    const idLengthToUse: number = RandomId.idLengthToUse(idLength);

    const idArray: Array<string> = new Array<string>(idsToGenerate);
    for (let idx = 0; idx < idsToGenerate; idx++) {
      // idArray[idx] = generateId(alphabetToUse, idLengthToUse);
      idArray[idx] = customAlphabet(alphabetToUse, idLengthToUse)();
    }

    return idArray;
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @method newIdArrayAsync (Asynchronous) Generates an array of random Ids, asynchronously.
  //  * @param {number} countRequested The number of Ids requested. Must be > 0.
  //  * @param {string} alphabet (Optional) An alphabet (set of characters) to be included in the resulting Id. If not specified, a default 
  //  * alphabet of alphanumeric characters will be used ('0-9', 'a-z', 'A-Z').
  //  * @param {number} idLength (Optional) The length (in characters) of the Id to generate. If not specified, a default length of 21 will be used.
  //  * @returns {string} A promise that will deliver a random ID string.
  //  */
  // static newIdArrayAsync(countRequested: number, alphabet?: string, idLength?: number): Promise<Array<string>> {

  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const idsToGenerate = RandomId.validatedCountRequested(countRequested);
  //       const alphabetToUse: string = RandomId.alphabetToUse(alphabet);
  //       const idLengthToUse: number = RandomId.idLengthToUse(idLength);

  //       const promiseArray: Array<Promise<string>> = new Array<Promise<string>>(idsToGenerate);
  //       for (let idx = 0; idx < idsToGenerate; idx++) {
  //         // promiseArray[idx] = generateIdAsync(alphabetToUse, idLengthToUse);
  //         promiseArray[idx] = customAlphabetAsync(alphabetToUse, idLengthToUse)();
  //       }

  //       const idArray: Array<string> = await Promise.all(promiseArray);

  //       resolve(idArray);

  //     } catch (error: any) {
  //       reject(error);
  //     }
  //   });
  // }
  // /*-----------------------------------------------*/

}
