import { IDigitalMediaSearchResultItem, IDigitalMediaSearchResultItemAsJson } from ".";
import { enumDigitalMediaType } from "../../../enums";
import { typeUniqueId } from "../../../types";

/** 
 * @class DigitalMediaSearchResultItem Information about an individual item (or digital media object) resulting from a search for digital media objects
 */
export class DigitalMediaSearchResultItem implements IDigitalMediaSearchResultItem {
  /**
   * @method Constructor method
   * @param {typeUniqueId} id The unique Id of the digital media object
   * @param {enumDigitalMediaType} objectType The type of the digital media object
   * @param {typeUniqueId} topicItemId Id of the digital media object's parent topicItem
   * @param {string} titleOrName The title or name of the digital media object (some digital media objects have a name, others a title)
   * @param {string} description Description associated with the digital media object
   */
  constructor(
    id: typeUniqueId,
    objectType: enumDigitalMediaType,
    topicItemId: typeUniqueId,
    topicId: typeUniqueId,
    channelId: typeUniqueId,
    titleOrName: string,
    description: string,
  ) {
    this.id = id;
    this._objectType = objectType;
    this._topicItemId = topicItemId;
    this._topicId = topicId;
    this._channelId = channelId;
    this._titleOrName = titleOrName;
    this._description = description;
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _id The unique Id of the digital media object
   */
  private _id: string = '';

  /**
   * @method id Getter method for _id
   */
  get id(): string {
    return this._id;
  }

  /**
   * @method id Setter method for _id
   * @param {string} value is the input value for setting _id
   */
  set id(value: string) {
    this._id = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumDigitalMediaType} _objectType The type of the digital media object
   */
  private _objectType: enumDigitalMediaType;

  /**
   * @method objectType Getter method for _objectType
   */
  get objectType(): enumDigitalMediaType {
    return this._objectType;
  }

  /**
   * @method objectType Setter method for _objectType
   * @param {enumDigitalMediaType} value is the input value for setting _objectType
   */
  set objectType(value: enumDigitalMediaType) {
    this._objectType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _topicItemId Id of the digital media object's parent topicItem
   */
  private _topicItemId: typeUniqueId;

  /**
   * @method topicItemId Getter method for _topicItemId
   */
  get topicItemId(): typeUniqueId {
    return this._topicItemId;
  }

  /**
   * @method topicItemId Setter method for _topicItemId
   * @param {typeUniqueId} value is the input value for setting _topicItemId
   */
  set topicItemId(value: typeUniqueId) {
    this._topicItemId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _topicId Id of the digital media object's ancestor topic (grandparent of digital media object)
   */
  private _topicId: typeUniqueId;

  /**
   * @method topicId Getter method for _topicId
   */
  get topicId(): typeUniqueId {
    return this._topicId;
  }

  /**
   * @method topicId Setter method for _topicId
   * @param {typeUniqueId} value is the input value for setting _topicId
   */
  set topicId(value: typeUniqueId) {
    this._topicId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _channelId Id of the digital media object's ancestor channel (great-grandparent of digital media object)
   */
  private _channelId: typeUniqueId;

  /**
   * @method channelId Getter method for _channelId
   */
  get channelId(): typeUniqueId {
    return this._channelId;
  }

  /**
   * @method channelId Setter method for _channelId
   * @param {typeUniqueId} value is the input value for setting _channelId
   */
  set channelId(value: typeUniqueId) {
    this._channelId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _titleOrName The title or name of the digital media object (some digital media objects have a name, others a title)
   */
  private _titleOrName: string;

  /**
   * @method titleOrName Getter method for _titleOrName
   */
  get titleOrName(): string {
    return this._titleOrName;
  }

  /**
   * @method titleOrName Setter method for _titleOrName
   * @param {string} value is the input value for setting _titleOrName
   */
  set titleOrName(value: string) {
    this._titleOrName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description A collection of Digital Media Types to be used in the search
   */
  private _description: string;

  /**
   * @method description Getter method for _description
   */
  get description(): string {
    return this._description;
  }

  /**
   * @method description Setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value: string) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IDigitalMediaSearchResultItem} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IDigitalMediaSearchResultItem {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IDigitalMediaSearchResultItem = Object.create(DigitalMediaSearchResultItem.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the class instance to a JSON representation
   */
  toJSON(): IDigitalMediaSearchResultItemAsJson {
    const jsonObject: IDigitalMediaSearchResultItemAsJson = {
      id: this.id,
      objectType: this.objectType,
      topicItemId: this.topicItemId,
      topicId: this.topicId,
      channelId: this.channelId,
      titleOrName: this.titleOrName,
      description: this.description,
    };

    return jsonObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a class instance from a JSON representation
   */
  static fromJSON(jsonObject: IDigitalMediaSearchResultItemAsJson): IDigitalMediaSearchResultItem {
    // create a new instance of this class
    let digitalMediaSearchResults: IDigitalMediaSearchResultItem = Object.create(DigitalMediaSearchResultItem.prototype);

    // assign all properties from the JSON object to the data members
    digitalMediaSearchResults.id = jsonObject.id;
    digitalMediaSearchResults.objectType = jsonObject.objectType;
    digitalMediaSearchResults.topicItemId = jsonObject.topicItemId;
    digitalMediaSearchResults.topicId = jsonObject.topicId;
    digitalMediaSearchResults.channelId = jsonObject.channelId;
    digitalMediaSearchResults.titleOrName = jsonObject.titleOrName;
    digitalMediaSearchResults.description = jsonObject.description;

    return digitalMediaSearchResults;
  }
  /*-----------------------------------------------*/

}
