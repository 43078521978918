import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsATwitterOrXAddress Returns whether the URL is a Twitter or X address
 * @param url
 */
export const urlIsATwitterOrXAddress: (url: string) => boolean = (url: string) => {

  // get the regular expression pattern for the website
  const regEx: RegExp = RegularExpressions.TwitterOrX;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
