import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface IMyTennisBrainLogoParms {
  className?: string;
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  personHead: {
    fill: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.personHeadFill : theme.palette.primary.light,
    stroke: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.personHeadStroke : theme.palette.primary.dark,
    strokeWidth: '9px'
  },
  clipPath: {
    fill: 'none'
  },
  racketHead: {
    fill: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.racketHeadFill : 'black',
  },
  racketThroat: {
    fill: 'none',
    stroke: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.racketThroatStroke : 'black',
    strokeMiterLimit: '10',
    strokeWidth: '54.26px'
  },
  racketThroatBase: {
    fill: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.racketThroatBaseFill : 'black',
    stroke: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.racketThroatBaseStroke : 'black',
  },
  racketStringMain: {
    fill: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.stringMainFill : 'none',
    stroke: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.stringMainStroke : '#3f3f3f',
    strokeMiterLimit: '10',
    strokeWidth: '2.0px'
  },
  racketStringCross: {
    fill: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.stringCrossFill : 'none',
    stroke: theme.myTennisBrainLogo ? theme.myTennisBrainLogo.stringCrossStroke : '#3f3f3f',
    strokeMiterLimit: '10',
    strokeWidth: '2.0px'
  },
  tennisBallLayers: {
    isolation: 'isolate'
  },
  cls25: {
    clipPath: 'url(#clip-path)'
  },
  cls47: {
    clipPath: 'url(#clip-path-3)'
  },
  cls51: {
    clipPath: 'url(#clip-path-5)'
  },

  tennisBallGradient01: {
    fill: '#e3e439'
  },
  tennisBallGradient02: {
    fill: '#dedf37'
  },
  tennisBallGradient03: {
    fill: '#d9da36'
  },
  tennisBallGradient04: {
    fill: '#d4d535'
  },
  tennisBallGradient05: {
    fill: '#d0d134'
  },
  tennisBallGradient06: {
    fill: '#cbcc33'
  },
  tennisBallGradient07: {
    fill: '#c6c732'
  },
  tennisBallGradient08: {
    fill: '#c2c230'
  },
  tennisBallGradient09: {
    fill: '#bdbe2f'
  },
  tennisBallGradient10: {
    fill: '#b8b92e'
  },
  tennisBallGradient11: {
    fill: '#b3b42d'
  },
  tennisBallGradient12: {
    fill: '#afaf2c'
  },
  tennisBallGradient13: {
    fill: '#aaab2a'
  },
  tennisBallGradient14: {
    fill: '#a5a629'
  },
  tennisBallGradient15: {
    fill: '#a0a128'
  },
  tennisBallGradient16: {
    fill: '#9c9c27'
  },
  tennisBallGradient17: {
    fill: '#979826'
  },
  tennisBallGradient18: {
    fill: '#929325'
  },
  tennisBallGradient19: {
    fill: '#8e8e23'
  },
  tennisBallGradient20: {
    fill: '#898922'
  },
  tennisBallGradient21: {
    fill: '#848521'
  },
  tennisBallGradient22: {
    fill: '#7f8020'
  },
  tennisBallGradient23: {
    fill: '#7b7b1f'
  },
  tennisBallSeams: {
    fill: '#e6e6e6'
  },

}));


// const MyTennisBrainLogo: React.FC<IMyTennisBrainLogoParms> = (props: IMyTennisBrainLogoParms) => {

// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const MyTennisBrainLogo = React.forwardRef((props: IMyTennisBrainLogoParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    // <svg ref={ref} className={logoOverallClassName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3257.35 3609.58" >
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3257.35 3609.58" >
      {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> */}
      <defs>
        <clipPath id="clip-path">
          <path id="SVGID" className={classes.clipPath}
            d="M1153.91,1145.72c2.46,0,4.46-114.37,4.46-255.42s-2-255.42-4.46-255.42c6.28.25,14.7.09,20.94.85,12.2,1.47,28.49,3.67,40.43,6.57a184.53,184.53,0,0,1,19.35,5.6c11.17,4.21,25.85,10.42,36.65,15.49h0a256.42,256.42,0,0,1,63.23,46.31c4,4.31,9.5,9.92,13.33,14.38s12.2,9.49,12.2,15.38a255.27,255.27,0,0,1-25.53,331.44,258.7,258.7,0,0,1-29.76,25.53,255.34,255.34,0,0,1-33.47,20.77,253.67,253.67,0,0,1-76,25.17A257.05,257.05,0,0,1,1153.91,1145.72Z" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path id="SVGID-3" data-name="SVGID" className={classes.clipPath}
            d="M898.49,890.3a257.18,257.18,0,0,1,3.34-41.43,254.34,254.34,0,0,1,9.68-39.3c4.22-11.16,10.42-25.84,15.49-36.64a255.81,255.81,0,0,1,20.78-33.47c3.66-4.61,8.2-11.06,12.2-15.38,8-8.62,18.78-20.06,27.71-27.71s21.92-16.62,31.68-23.22a176.92,176.92,0,0,1,17.17-9.76,255.49,255.49,0,0,1,56-21.09c11.94-2.9,28.23-5.1,40.43-6.57,6.25-.76,14.66-.6,21-.85h0c2.46,0,4.46,114.37,4.46,255.42s-2,255.42-4.46,255.42c-12.46-.51-29.05-1.84-41.43-3.35a254.45,254.45,0,0,1-109.41-45.94c-9.22-7.31-21.13-17.54-29.76-25.53A255.13,255.13,0,0,1,911.51,971c-1.68-5.8-4.15-13.48-5.6-19.35s-4.08-13.84-4.08-19.95A257.05,257.05,0,0,1,898.49,890.3Z" />
        </clipPath>
        <clipPath id="clip-path-5">
          <path id="SVGID-5" data-name="SVGID" className={classes.clipPath}
            d="M1153.91,1145.72c-12.46-.51-29.05-1.84-41.43-3.35a254.45,254.45,0,0,1-109.41-45.94h0c-9.22-7.31-21.13-17.54-29.76-25.53A255.13,255.13,0,0,1,911.51,971h0c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3c0-6,4.72-13.17,7-18.69s5.91-12.57,8.44-18a255.81,255.81,0,0,1,20.78-33.47c0-5.89,8.2-11.06,12.2-15.38,8-8.62,18.78-20.06,27.71-27.71s21.92-16.62,31.68-23.22a176.92,176.92,0,0,1,17.17-9.76,255.49,255.49,0,0,1,56-21.09c11.94-2.9,28.23-5.1,40.43-6.57,6.25-.76,14.66-.6,21-.85,6.28.25,14.7.09,20.94.85,12.2,1.47,28.49,3.67,40.43,6.57a184.53,184.53,0,0,1,19.35,5.6c11.17,4.21,25.85,10.42,36.65,15.49a256.42,256.42,0,0,1,63.23,46.31h0c8,8.63,18.22,20.54,25.53,29.76a255.27,255.27,0,0,1-25.53,331.44,258.7,258.7,0,0,1-29.76,25.53,255.34,255.34,0,0,1-33.47,20.77,253.67,253.67,0,0,1-76,25.17A257.05,257.05,0,0,1,1153.91,1145.72Z" />
        </clipPath>
      </defs>

      <g id="Human_Head_Small_Light_Blue" data-name="Human Head Small Light Blue">
        <path className={classes.personHead}
          d="M463.79,2508.09C375.05,2152.93-85.8,1958.41,20.26,1124c181.8-1430.38,2302.1-1384.29,2697.3-457.49,184.16,431.86,61.52,429.32,128,566.54,42.51,87.68,167.28,201.48,341.65,456.61,212.85,311.45-157.82,273.9-184,345.69-37.61,103,65.56,172.76,65.56,221.63,0,64.51-41.05,99.31-84.66,110.84,0,0,111.81,81.73,32,158-104.5,99.91-29.71,241.34-6.21,323,43.72,152-65,283.15-246,298.18-153.17,12.69-361.24,1-464.59,3.23-126.14,2.75-132,168.09-189,454.88H351.44S536.48,2799,463.79,2508.09Z" />
      </g>

      <g id="Racket_with_Strings_Rotated_259_degrees" data-name="Racket with Strings Rotated 259 degrees">
        <g id="RacketTopWithStrings">
          <g id="RacketThroat">
            <g id="RacketThroatGroup">
              <rect id="RacketThroatBase" className={classes.racketThroatBase} x="2648.38" y="1135.68" width="138.55"
                height="123.15" transform="translate(1025.83 3637.92) rotate(-79.05)" />
              <path id="RacketThroatRight" className={classes.racketThroat}
                d="M1812.17,544.9C1950.86,698.29,2151,880.19,2425.32,1029a2277.93,2277.93,0,0,0,249.09,116.2" />
              <path id="RacketThroatLeft" className={classes.racketThroat}
                d="M1634.15,1464.75c185.91-90.57,439.43-184.68,749.5-220.39a2275.81,2275.81,0,0,1,274.45-14.87" />
            </g>
          </g>
          <g id="Strings_-_Main" data-name="Strings - Main">
            <line id="MainString_18" className={classes.racketStringMain} x1="817.83" y1="339.16" x2="1809.29" y2="531.03" />
            <line id="MainString_17" className={classes.racketStringMain} x1="683.22" y1="373.29" x2="1892.33" y2="607.28" />
            <line id="MainString_16" className={classes.racketStringMain} x1="599.35" y1="417.66" x2="1929.36" y2="675.05" />
            <line id="MainString_15" className={classes.racketStringMain} x1="556.66" y1="472.7" x2="1959.22" y2="744.14" />
            <line id="MainString_14" className={classes.racketStringMain} x1="511.5" y1="522.27" x2="1986.61" y2="807.75" />
            <line id="MainString_13" className={classes.racketStringMain} x1="478.44" y1="572.62" x2="2007.95" y2="868.63" />
            <line id="MainString_12" className={classes.racketStringMain} x1="447.2" y1="620.5" x2="1994.85" y2="920.02" />
            <line id="MainString_11" className={classes.racketStringMain} x1="430.78" y1="672.91" x2="1990.53" y2="974.77" />
            <line id="MainString_10" className={classes.racketStringMain} x1="408.84" y1="723.14" x2="1992.14" y2="1029.56" />
            <line id="MainString_09" className={classes.racketStringMain} x1="399.37" y1="776.12" x2="1982.66" y2="1082.54" />
            <line id="MainString_08" className={classes.racketStringMain} x1="400.56" y1="829.09" x2="1960.3" y2="1130.95" />
            <line id="MainString_07" className={classes.racketStringMain} x1="397.77" y1="879.9" x2="1945.42" y2="1179.42" />
            <line id="MainString_06" className={classes.racketStringMain} x1="401.55" y1="933.9" x2="1931.06" y2="1229.91" />
            <line id="MainString_05" className={classes.racketStringMain} x1="417.01" y1="990.82" x2="1892.12" y2="1276.3" />
            <line id="MainString_04" className={classes.racketStringMain} x1="445.1" y1="1050.84" x2="1847.66" y2="1322.28" />
            <line id="MainString_03" className={classes.racketStringMain} x1="472.95" y1="1115.55" x2="1802.97" y2="1372.94" />
            <line id="MainString_02" className={classes.racketStringMain} x1="525.09" y1="1186.62" x2="1734.19" y2="1420.62" />
            <line id="MainString_01" className={classes.racketStringMain} x1="615.89" y1="1279.83" x2="1607.36" y2="1471.71" />
          </g>
          <g id="Strings_-_Cross" data-name="Strings - Cross">
            <line id="CrossString_20" className={classes.racketStringCross} x1="1846.46" y1="587.28" x2="1682.66"
              y2="1433.65" />
            <line id="CrossString_19" className={classes.racketStringCross} x1="1773.69" y1="507.78" x2="1584.15"
              y2="1487.16" />
            <line id="CrossString_18" className={classes.racketStringCross} x1="1710.79" y1="442.94" x2="1502.53"
              y2="1519.04" />
            <line id="CrossString_17" className={classes.racketStringCross} x1="1646.67" y1="429.45" x2="1438.41"
              y2="1505.55" />
            <line id="CrossString_16" className={classes.racketStringCross} x1="1584.02" y1="408.36" x2="1371.08"
              y2="1508.64" />
            <line id="CrossString_15" className={classes.racketStringCross} x1="1522.7" y1="380.37" x2="1305.08"
              y2="1504.83" />
            <line id="CrossString_14" className={classes.racketStringCross} x1="1458.77" y1="352.76" x2="1234.14"
              y2="1513.5" />
            <line id="CrossString_13" className={classes.racketStringCross} x1="1392.67" y1="336.39" x2="1168.03"
              y2="1497.13" />
            <line id="CrossString_12" className={classes.racketStringCross} x1="1331.89" y1="320.14" x2="1104.91"
              y2="1492.97" />
            <line id="CrossString_11" className={classes.racketStringCross} x1="1270.21" y1="308.21" x2="1043.23"
              y2="1481.04" />
            <line id="CrossString_10" className={classes.racketStringCross} x1="1208.54" y1="296.27" x2="981.56" y2="1469.1" />
            <line id="CrossString_09" className={classes.racketStringCross} x1="1145.69" y1="290.38" x2="921.06"
              y2="1451.12" />
            <line id="CrossString_08" className={classes.racketStringCross} x1="1083.91" y1="297.24" x2="866.29" y2="1421.7" />
            <line id="CrossString_07" className={classes.racketStringCross} x1="1021.59" y1="297.72" x2="808.65"
              y2="1398.01" />
            <line id="CrossString_06" className={classes.racketStringCross} x1="959.77" y1="317.12" x2="758.53" y2="1356.94" />
            <line id="CrossString_05" className={classes.racketStringCross} x1="906.36" y1="325.6" x2="712.15" y2="1329.15" />
            <line id="CrossString_04" className={classes.racketStringCross} x1="850.45" y1="346.13" x2="667.93" y2="1289.24" />
            <line id="CrossString_03" className={classes.racketStringCross} x1="785.82" y1="364.47" x2="614.8" y2="1248.11" />
            <line id="CrossString_02" className={classes.racketStringCross} x1="710.55" y1="373.26" x2="548.25" y2="1211.88" />
            <line id="CrossString_01" className={classes.racketStringCross} x1="619.47" y1="438.06" x2="487.92" y2="1117.78" />
          </g>
          <g id="RacketHead">
            <g id="RacketHeadGroup">
              <path id="RacketHeadPath" className={classes.racketHead}
                d="M427.91,754.15c-14.62,75.51-9.12,152.76,16.32,229.61,25.06,75.68,68.27,147.92,128.45,214.73,61.11,67.85,136.48,126.5,224,174.33,91.27,49.87,191.67,85.63,298.43,106.29s213.25,24.94,316.53,12.72c99-11.71,190.86-38,272.88-78.17,80.76-39.53,147.8-90.43,199.28-151.29,52.28-61.81,86.2-131.44,100.82-206.94s9.12-152.76-16.33-229.62c-25.06-75.67-68.27-147.91-128.45-214.72-61.11-67.85-136.48-126.5-224-174.33-91.27-49.87-191.67-85.63-298.43-106.29s-213.25-24.94-316.53-12.73c-99,11.72-190.86,38-272.88,78.17-80.75,39.54-147.8,90.44-199.28,151.3C476.44,609,442.52,678.65,427.91,754.15M375,743.92c67-346.41,493.55-555.2,952.64-466.35s776.91,441.69,709.87,788.09S1544,1620.86,1084.88,1532,308,1090.32,375,743.92Z" />
            </g>
          </g>
        </g>
      </g>

      <g id="Tennis_Ball_-_Small" data-name="Tennis Ball - Small">
        <g className={classes.tennisBallLayers}>
          <g className={classes.tennisBallLayers}>
            <g className={classes.cls25}>
              <g className={classes.tennisBallLayers}>
                <path className={classes.tennisBallGradient04}
                  d="M1153.91,1145.72a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31c-20.57-9.66-33-15.49-56-21.09-24.41-5.92-36-6.39-61.37-7.42,2.46,0,4.46,114.37,4.46,255.42s-2,255.42-4.46,255.42" />
                <path className={classes.tennisBallGradient04}
                  d="M1158.37,890.3c0,16.59,0,32.82-.09,48.53h0c8,17.14,14.39,45.59-.56,83.78-.78,73.8-2.2,123.11-3.81,123.11a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.46,0,4.46,114.37,4.46,255.42" />
                <path className={classes.tennisBallGradient05}
                  d="M1158.37,890.3q0,12,0,23.75h0c28.56,62.05,22.89,76.14-1,135.42-.81,58.65-2.07,96.25-3.48,96.25a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.46,0,4.46,114.37,4.46,255.42" />
                <path className={classes.tennisBallGradient06}
                  d="M1158.37,889.27c24.92,52.8,53.17,112.64-1.17,173.34-.82,51.07-2,83.11-3.29,83.11a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.45,0,4.45,113.82,4.46,254.39" />
                <path className={classes.tennisBallGradient07}
                  d="M1158.36,874.07c31.73,39.09,73.76,117.15-1.37,200.64-.8,44-1.89,71-3.08,71a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.36,0,4.3,105.7,4.45,239.19" />
                <path className={classes.tennisBallGradient08}
                  d="M1158.33,859.1c97.13,117.86,32.93,188.8-1.56,226.91-.77,37.26-1.77,59.71-2.86,59.71a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.34,255.34,0,0,0,20.77-33.47A253.67,253.67,0,0,0,1406,931.72a257.8,257.8,0,0,0,0-82.85A254.45,254.45,0,0,0,1360,739.46h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.27,0,4.15,97.89,4.42,224.22" />
                <path className={classes.tennisBallGradient09}
                  d="M1158.29,844.4c133.35,159.61,12.5,241.65-1.71,250.48-.75,31.93-1.67,50.84-2.67,50.84a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.19,0,4,90.37,4.38,209.52" />
                <path className={classes.tennisBallGradient10}
                  d="M1158.24,830.24c138.49,159,44.84,243.29-1.85,272.14-.71,27.36-1.56,43.34-2.48,43.34a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2.1,0,3.86,83.28,4.33,195.36" />
                <path className={classes.tennisBallGradient11}
                  d="M1158.18,818.16c2,1.65,190,170.44-2,291.18-.67,23.09-1.45,36.38-2.29,36.38a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c2,0,3.73,77.35,4.27,183.28" />
                <path className={classes.tennisBallGradient12}
                  d="M1158.12,806.32c86.15,71,161.76,217.77-2.12,309.44-.62,19.12-1.33,30-2.09,30a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.95,0,3.6,71.64,4.21,171.44" />
                <path className={classes.tennisBallGradient13}
                  d="M1158,794.74h0c180.39,147.56,93.15,293.25-2.24,326.75-.57,15.54-1.21,24.23-1.89,24.23a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.87,0,3.47,66.14,4.13,159.86" />
                <path className={classes.tennisBallGradient14}
                  d="M1158,783.44c42.4,33.45,113.38,89.43,112.73,184.56-.81,118.12-86.82,148.49-115.08,158.47-.53,12.4-1.1,19.25-1.7,19.25a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.79,0,3.34,60.87,4,148.56" />
                <path className={classes.tennisBallGradient15}
                  d="M1157.86,772.07h0c163.77,100.74,162.35,308.66-2.45,358.84-.47,9.58-1,14.81-1.5,14.81a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.71,0,3.21,55.65,3.95,137.19" />
                <path className={classes.tennisBallGradient16}
                  d="M1157.75,760.7c196.92,120.54,152.34,327.16,16.36,369.72-6.19,1.94-12.62,2.87-18.91,4.38-.41,7.1-.84,10.92-1.29,10.92a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.63,0,3.06,50.52,3.84,125.82" />
                <path className={classes.tennisBallGradient17}
                  d="M1157.63,749.7c227.09,138.35,152.17,351.86,12.88,385.82-5.11,1.24-10.35,1.71-15.52,2.6-.35,5-.71,7.6-1.08,7.6a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.55,0,2.92,45.64,3.72,114.82" />
                <path className={classes.tennisBallGradient18}
                  d="M1157.5,739.08c222.44,120.44,182.35,347.53,27.44,396.24-10.28,3.23-16.53,4.09-30.2,6-.27,2.91-.55,4.44-.83,4.44a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.47,0,2.78,41,3.59,104.2" />
                <path className={classes.tennisBallGradient19}
                  d="M1157.35,728.33c228.51,105.08,196.21,352.1,42.52,405.88-14.13,4.94-28.13,7.88-45.4,9.52,13.54,2,27.38.84,40.86-1.36a253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.39,0,2.63,36.4,3.44,93.45" />
                <path className={classes.tennisBallGradient20}
                  d="M1157.16,715.61c229.08,104.77,210,354.69,62.42,414.86a197.08,197.08,0,0,1-65.39,14.74,208.38,208.38,0,0,0,41.14-2.84,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.28,0,2.44,31,3.25,80.73" />
                <path className={classes.tennisBallGradient21}
                  d="M1157,703.66c220.63,83.08,239.85,349.21,77.7,424.14-32.94,15.22-64.65,17.76-80.62,17.92h-.12a257.05,257.05,0,0,0,41.42-3.35,253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.17,0,2.24,26.12,3,68.78" />
                <path className={classes.tennisBallGradient22}
                  d="M1156.73,692.52c205.24,50.84,271.81,330.34,101.75,427.23a199.57,199.57,0,0,1-70.37,23.7q3.63-.5,7.22-1.08a253.67,253.67,0,0,0,76-25.17,255.34,255.34,0,0,0,33.47-20.77,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c1.07,0,2,21.61,2.82,57.64" />
                <path className={classes.tennisBallGradient23}
                  d="M1156.3,674.64h0c191.65,39,302.93,313.59,127.64,433.8a205.17,205.17,0,0,1-41,21.33,255.19,255.19,0,0,0,61.82-33.34,257,257,0,0,0,55.29-55.29,255.09,255.09,0,0,0,0-301.68h0c-7.31-9.22-17.54-21.13-25.53-29.76a256.42,256.42,0,0,0-63.23-46.31h0c-10.8-5.07-25.48-11.27-36.65-15.49l-19.35-5.6-20-4.08-20.48-2.49-20.94-.85c.88,0,1.7,14.59,2.39,39.76" />
              </g>
            </g>
            <g className={classes.cls25}>
              <path className={classes.tennisBallSeams}
                d="M1353,922.65c-.51,49.7-.51,49.7-3.55,123.31a15.51,15.51,0,0,1,2.4,2.73h2.44q3-3.72,5.76-7.55A254.09,254.09,0,0,0,1406,931.73a257.86,257.86,0,0,0,0-82.86A254.45,254.45,0,0,0,1360,739.46l-10.53-13.41h-2.39c-1.35,1.87-1.35,1.87,2.14,64.1,4.19,74.84,3.74,132,3.73,132.5m-17,6.94c1.14-159.72-9.08-205.24-13.44-224.68l1.51-1.75a5.91,5.91,0,0,1,2.49-1.08c2.71,2.48,5.35,5,8,7.62,8,8.63,18.22,20.54,25.53,29.76a255.27,255.27,0,0,1-25.53,331.44l-2.46,2.43h-2.18c-1.92-3.56-1.81-9.71-1.74-11.51,1.89-44.12,7.56-88,7.87-132.23" />
            </g>
          </g>
          <g className={classes.tennisBallLayers}>
            <g className={classes.cls47}>
              <g className={classes.tennisBallLayers}>
                <path className={classes.tennisBallGradient04}
                  d="M898.49,890.3a257.05,257.05,0,0,0,3.34,41.42c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94c12.38,1.51,29,2.84,41.43,3.35,2.46,0,4.46-114.37,4.46-255.42s-2-255.42-4.46-255.42c-25.38,1-37,1.5-61.38,7.42a254.8,254.8,0,0,0-104.84,54.07c-17.88,15.32-25.71,25.19-39.91,43.09A255.81,255.81,0,0,0,927,772.93c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,257.18,257.18,0,0,0-3.34,41.43" />
                <path className={classes.tennisBallGradient04}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35,1.61,0,3-49.31,3.81-123.11h0c-105.82,110.23-194.34-8.5-192.43-73.75,2.17-74.34,95.44-101.16,146.69-58.56,17.31,14.39,30.62,32.59,46.3,48.53.06-15.71.09-31.94.09-48.53,0-141-2-255.42-4.46-255.42h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient05}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35,1.41,0,2.67-37.6,3.48-96.25h0c-185.63,92.08-211.33-96.37-189.82-145.54,14.48-33.08,89.54-94.79,190.78,10.12q0-11.73,0-23.75c0-141-2-255.42-4.46-255.42h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient06}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35,1.3,0,2.47-32,3.29-83.11h0c-181.39,79.66-229.83-99.12-194.63-172.31.23-.48,28.19-48,91.74-48.33,24.65-.13,29.14,2.55,104.06,47.3,0-140.57-2-254.39-4.46-254.39h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient07}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35,1.19,0,2.28-27,3.08-71h0c-169.92,68.5-241.36-102.54-203.78-184.41,48.92-106.6,162.42-40.95,205.15-16.23-.15-133.49-2.09-239.19-4.45-239.19h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient08}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35,1.09,0,2.09-22.45,2.86-59.71h0C1003.54,1147.4,906.6,980.64,944.49,890.3c34.23-81.64,106.18-92.13,213.84-31.2-.27-126.33-2.15-224.22-4.42-224.22h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient09}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35,1,0,1.92-18.91,2.67-50.84h0c-198.19,52.31-252.5-150.54-212.14-219.4,66.48-113.4,177.4-51.44,213.85-31.08-.37-119.15-2.19-209.52-4.38-209.52h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient10}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.92,0,1.77-16,2.48-43.34h0C957,1150.7,891.45,942.35,944.78,859.79c62.21-96.31,156.89-54.52,213.46-29.55-.47-112.08-2.23-195.36-4.33-195.36h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient11}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.84,0,1.62-13.29,2.29-36.38h0C967.6,1151,883.59,955.94,937.51,856.74c58.32-107.27,172.09-59.14,220.67-38.58-.54-105.93-2.25-183.28-4.27-183.28h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient12}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.76,0,1.47-10.84,2.09-30h0c-184.91,37.59-283.68-161.51-216.45-275.44,58.38-98.92,179-50.06,218.57-34-.61-99.8-2.26-171.44-4.21-171.44h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient13}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.68,0,1.32-8.69,1.89-24.23h0C978,1152.71,860.46,958.24,937.59,831.74c60.56-99.34,180.91-52.42,220.45-37-.66-93.72-2.26-159.86-4.13-159.86h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient14}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.6,0,1.17-6.85,1.7-19.25h0c-184.56,30-304.48-184.75-215.1-308.67,67.06-93,176.32-50.39,217.45-34.36-.71-87.69-2.26-148.56-4-148.56h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient15}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.52,0,1-5.23,1.5-14.81h0C968.82,1158.8,846.82,944,934,817.8c70.21-101.63,174-63.87,223.84-45.73-.74-81.54-2.24-137.19-3.95-137.19h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient16}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.45,0,.88-3.82,1.29-10.92h0c-179,24.41-321.22-191.88-217.44-330.62,70.6-94.38,167.78-61.27,220-43.48-.78-75.3-2.21-125.82-3.84-125.82h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient17}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88h0c8.63,8,20.54,18.22,29.76,25.53a254,254,0,0,0,109.41,45.94h0c12.38,1.51,29,2.84,41.43,3.35.37,0,.73-2.64,1.08-7.6h0c-176.52,22.44-321.89-198-222.21-336.93,71.83-100.14,175.18-67.28,224.85-51.49-.8-69.18-2.17-114.82-3.72-114.82h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <g>
                  <path className={classes.tennisBallGradient18}
                    d="M1036.54,1117.2a253.48,253.48,0,0,0,75.94,25.17h0c12.38,1.51,29,2.84,41.43,3.35.28,0,.56-1.53.83-4.44h0c-68.93,7.8-125.68-27.92-144.33-39.66a255.06,255.06,0,0,0,26.13,15.58" />
                  <path className={classes.tennisBallGradient18}
                    d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85h0c2.44,11.9,6.31,27.64,9.68,39.3q1.18,3.54,2.46,7h0c-34.45-107.4,3.35-174.14,41.13-211.21,69.69-68.39,152.76-43,202.4-27.77-.81-63.19-2.12-104.2-3.59-104.2h0c-12.46.5-29.12,1.34-41.43,3.34" />
                </g>
                <g>
                  <path className={classes.tennisBallGradient19}
                    d="M1073.18,1132.69a253.2,253.2,0,0,0,39.3,9.68h0c12.38,1.51,29,2.84,41.43,3.35.19,0,.37-.68.56-2h0c-17.21,1.34-52.8.88-97.88-17.2q8.16,3.36,16.59,6.17" />
                  <path className={classes.tennisBallGradient19}
                    d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.76,258.76,0,0,0-.73,78.1h0c-5.52-55-1.91-125.8,75.7-186,67.18-52.07,152.51-22.42,180.55-12.68-.81-57.05-2-93.45-3.44-93.45h0c-12.46.5-29.12,1.34-41.43,3.34" />
                </g>
                <g>
                  <path className={classes.tennisBallGradient20}
                    d="M1112.48,1142.37c12.38,1.51,29,2.84,41.43,3.35.09,0,.19-.17.28-.51h0c-21.63.9-43.54-3.15-50.74-4.48q4.48.9,9,1.64" />
                  <path className={classes.tennisBallGradient20}
                    d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,257.51,257.51,0,0,0-3.22,33.67h0c3.61-48.3,16.77-109.55,87-154.91,68.33-44.11,138-22.43,171.53-12-.81-49.68-2-80.73-3.25-80.73h0c-12.46.5-29.12,1.34-41.43,3.34" />
                </g>
                <g>
                  <path className={classes.tennisBallGradient21} d="M1153.91,1145.72c-1.56,0-3.11,0-6.25-.08l6.25.08" />
                  <path className={classes.tennisBallGradient21}
                    d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38A255.81,255.81,0,0,0,927,772.93h0c-5.07,10.8-11.27,25.48-15.49,36.64A255.16,255.16,0,0,0,903,842.3h0c4.35-20.14,48.42-195.13,254-138.64-.8-42.66-1.87-68.78-3-68.78h0c-12.46.5-29.12,1.34-41.43,3.34" />
                </g>
                <path className={classes.tennisBallGradient22}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38a254.29,254.29,0,0,0-34.46,64.89h0c7.74-19,31.32-76.8,97.15-107.05,41.35-19,75.07-20.1,87.76-20.51,21.1-.69,39,10.58,58.5,15.73-.77-36-1.75-57.64-2.82-57.64h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient23}
                  d="M1112.48,638.22c-11.89,2.44-27.63,6.31-39.3,9.68L1054.49,655l-18,8.44-17.17,9.76-16.3,11-15.38,12.2L973.31,709.7,960,724.08l-12.2,15.38q-7.29,9.94-13.62,20.58h0c9.78-14.92,39.54-60.32,100.67-83.82,57.46-22.09,102.31-7.72,121.47-1.58-.69-25.17-1.51-39.76-2.39-39.76h0c-12.46.5-29.12,1.34-41.43,3.34" />
                <path className={classes.tennisBallGradient03}
                  d="M1037.29,1050c3.43,2.25,39.47,6.91,39.77,6.91,8,0,18.44-3.81,25.85-6.91,3.35-1.4,8.23-2.9,10.81-5.46l36.83-42-6.26-53.76-40.71-42.3c-.28-.21-33.33-21.19-51-23.26-6.38-.75-41.5,8.42-45.82,12-2.34,1.92-25.1,38.46-31,53.6-2.12,5.46,1.51,13.61,2.65,19.36,2,10.39,1.73,25.8,7.92,34.4,3.73,5.18,44.72,43.3,51,47.42" />
                <path className={classes.tennisBallGradient02}
                  d="M1030.94,1030.79c.71.4,41.19,12.2,41.65,12.14l38.1-21c6.11-4.52,14.11-12.1,16.33-19.36l-6.1-53.76c-4.32-5.78-10.79-13.21-16.78-17.22l-51.42-28.5c-.91-.07-43.82,16.48-45,17.37-7.9,5.87-12,19.6-16.52,28.35-1.1,2.12,8,49.85,10.22,53.76.73,1.27,28.06,27.33,29.54,28.17" />
                <path className={classes.tennisBallGradient01}
                  d="M1023.2,1008c.75.46,43.34,14.22,43.83,14.15l32.93-19.48-8.14-53.76-38.23-21c-.88-.06-37.08,14.17-43.52,17.12-1.21.55-1.68,2.63-2.25,3.83-1,2,8,50.24,10,53.76a17.1,17.1,0,0,0,5.41,5.33" />
              </g>
            </g>
            <g className={classes.cls47}>
              <path className={classes.tennisBallSeams}
                d="M947.78,739.46A255.81,255.81,0,0,0,927,772.93c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85c2.44,11.9,6.31,27.64,9.68,39.3a254.48,254.48,0,0,0,36.27,70.12q2.81,3.83,5.75,7.55h2.64c4.05-5,4.38-5.36.84-57.23-.06-.86-5-99,6.22-262.74a15.25,15.25,0,0,1-2.35-2.67h-2.57l-10.53,13.41m25.53-29.76q3.88-3.9,7.94-7.62h2.27c1.56,2.95,1.61,7.71,1.37,11.19-2.93,43.38-8.67,86.53-10.91,130-5.28,102,5.32,219.85,6.41,227.18l-1.76,1.8a5.93,5.93,0,0,1-2.83,1.11h0c-.83-.81-1.65-1.61-2.46-2.43A255.13,255.13,0,0,1,911.51,971c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3c4.22-11.16,10.42-25.84,15.49-36.64a255.81,255.81,0,0,1,20.78-33.47c7.31-9.22,17.37-21.29,25.53-29.76" />
            </g>
          </g>
          <g className={classes.tennisBallLayers}>
            <g className={classes.cls51}>
              <g className={classes.tennisBallLayers}>
                <path className={classes.tennisBallGradient21}
                  d="M1153.91,1145.72c-12.46-.51-29.05-1.84-41.43-3.35a254.45,254.45,0,0,1-109.41-45.94c-9.22-7.31-21.13-17.54-29.76-25.53A255.13,255.13,0,0,1,911.51,971c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3c4.22-11.16,10.42-25.84,15.49-36.64a255.81,255.81,0,0,1,20.78-33.47c24.39-30.74,36.05-42.45,71.59-66.31,8.71-5.84,38.82-22.77,73.16-30.85,42.81-10.06,73.83-7.57,82.32-6.57,44.64,5.26,59.57,10.34,96.43,27.66a256.42,256.42,0,0,1,63.23,46.31c8,8.63,18.22,20.54,25.53,29.76a255.27,255.27,0,0,1-25.53,331.44,258.7,258.7,0,0,1-29.76,25.53,255.34,255.34,0,0,1-33.47,20.77,253.67,253.67,0,0,1-76,25.17,257.05,257.05,0,0,1-41.42,3.35" />
                <g>
                  <path className={classes.tennisBallGradient21}
                    d="M1396.3,971a254.82,254.82,0,0,1-36.26,70.12,256.36,256.36,0,0,1-88.76,76.06,253.67,253.67,0,0,1-76,25.17,257.05,257.05,0,0,1-41.42,3.35l-21-.85-20.48-2.5a254.45,254.45,0,0,1-109.41-45.94h0c-9.22-7.31-21.13-17.54-29.76-25.53A255.13,255.13,0,0,1,911.51,971h0c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3h0c4.22-11.16,10.42-25.84,15.49-36.64a255.81,255.81,0,0,1,20.78-33.47h0c7.31-9.22,17.53-21.13,25.53-29.76l14.38-13.33,15.38-12.2,16.3-11,17.17-9.76,18-8.44,18.69-7.05,19.35-5.6,20-4.08,20.48-2.49,21-.85c-194,3.86-258.07,235.21-105.49,380.84,131.61,125.61,321.47,84.36,356.4-77.43A252.68,252.68,0,0,1,1396.3,971" />
                  <path className={classes.tennisBallGradient21}
                    d="M1160.15,635c-2.15-.05-3.7-.08-6.24-.08,2.08,0,4.17,0,6.24.08" />
                </g>
                <path className={classes.tennisBallGradient22}
                  d="M1380.81,1007.67a256,256,0,0,1-46.3,63.23,258.7,258.7,0,0,1-29.76,25.53,255.34,255.34,0,0,1-33.47,20.77,253.67,253.67,0,0,1-76,25.17,257.05,257.05,0,0,1-41.42,3.35l-21-.85-20.48-2.5a254.45,254.45,0,0,1-109.41-45.94h0c-9.22-7.31-21.13-17.54-29.76-25.53A255.13,255.13,0,0,1,911.51,971h0c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3h0c4.22-11.16,10.42-25.84,15.49-36.64a255.81,255.81,0,0,1,20.78-33.47h0c7.31-9.22,17.53-21.13,25.53-29.76l14.38-13.33,15.38-12.2,16.3-11,17.17-9.76,18-8.44,18.69-7.05,19.35-5.6,20-4.08,7.22-1.07-4.24.62c-181.07,33-236.69,268.29-47.21,411.71,135.49,102.56,280.68,43.72,326.25-73.23a253.3,253.3,0,0,1-13.69,31.42" />
                <path className={classes.tennisBallGradient23}
                  d="M1360,1041.14a256.36,256.36,0,0,1-88.76,76.06,253.67,253.67,0,0,1-76,25.17,257.05,257.05,0,0,1-41.42,3.35l-21-.85-20.48-2.5a254.45,254.45,0,0,1-109.41-45.94h0c-9.22-7.31-21.13-17.54-29.76-25.53A255.13,255.13,0,0,1,911.51,971h0c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3h0c4.22-11.16,10.42-25.84,15.49-36.64a255.81,255.81,0,0,1,20.78-33.47h0c7.31-9.22,17.53-21.13,25.53-29.76l14.38-13.33,15.38-12.2,16.3-11,17.17-9.76,18-8.44,10.39-4.12-8.33,3.37C869.34,744.54,906.1,1002.58,1120,1099.53c78,35.37,193.44,19.71,253.67-79l-10.7,16.53-2.91,4.06" />
                <path className={classes.tennisBallGradient20}
                  d="M1234.63,647.9c11.17,4.22,25.85,10.42,36.65,15.49a256.42,256.42,0,0,1,63.23,46.31h0c8,8.63,18.22,20.54,25.53,29.76a254.66,254.66,0,0,1,49.29,150.84c0,2.59,0,5.18-.12,7.75h0c-15,199.73-233.5,236.79-355.82,104.57-160.55-173.54-42.24-373.32,113-367.24a245.12,245.12,0,0,1,37.93,4.48l19.69,4.78,10.58,3.26" />
                <path className={classes.tennisBallGradient19}
                  d="M1271.28,663.39a256.42,256.42,0,0,1,63.23,46.31h0c8,8.63,18.22,20.54,25.53,29.76A254,254,0,0,1,1406,848.87c.26,1.58.5,3.17.73,4.75h0c17.88,177.9-159.55,293.44-330.74,154C938.23,895.49,960.24,655,1151.51,637c13.87-1.31,51.36-2.27,99.71,17.13l18,8.28,2,1" />
                <path className={classes.tennisBallGradient18}
                  d="M1304.75,684.17a256.74,256.74,0,0,1,29.76,25.53h0c8,8.63,18.22,20.54,25.53,29.76a254.68,254.68,0,0,1,20.77,33.47,250.93,250.93,0,0,1,13,29.61h0c60,187-94,302.48-243.52,239.72-166-69.69-218.92-319.48-40.87-391.68,71.8-29.12,141.84-.63,188,28.4q3.71,2.53,7.34,5.19" />
                <path className={classes.tennisBallGradient17}
                  d="M1383.24,778c82.92,172.12-63,330.65-233,253.17C979.83,953.53,956.62,713,1113.66,652.68c109-41.89,224.84,32.42,269.58,125.3" />
                <path className={classes.tennisBallGradient16}
                  d="M1382.5,778c75.43,159.35-49,291.79-187.16,257.92-169.07-41.46-252.72-275.63-90.67-373.82,78.49-47.55,219.92-6.46,277.83,115.9" />
                <path className={classes.tennisBallGradient15}
                  d="M1381.15,778c67,143.15-39.89,286.93-184.53,248.13-167.44-44.91-244.35-281.82-77.39-365.27C1207.05,617,1331.89,672.78,1381.15,778" />
                <path className={classes.tennisBallGradient14}
                  d="M1396.31,831.74c24.16,146.81-116.34,240.35-246.46,164.06-150.68-88.33-149.21-302,2.34-341.32,104-27,225,60.84,244.12,177.26" />
                <path className={classes.tennisBallGradient13}
                  d="M1393.54,831.74c19.78,132-105.24,234.75-243.78,152.13C1014.08,903,1015.5,697,1152,659.82c114.84-31.3,226,68.46,241.56,171.92" />
                <path className={classes.tennisBallGradient12}
                  d="M1390.29,831.74c16.26,115.49-96.5,228.19-240.61,139.89-113-69.23-141.94-268.62,22-310.79,82-21.09,202.74,58,218.64,170.9" />
                <path className={classes.tennisBallGradient11}
                  d="M1377.18,794.1c45.07,136-65,228.22-176.4,190.6-152.86-51.61-185-255.63-49.21-311.86,83.81-34.69,193.45,24.24,225.61,121.26" />
                <path className={classes.tennisBallGradient10}
                  d="M1382.23,831.74c14.87,148.65-148.78,187-232.67,114.15-127.07-110.31-73.66-274.13,54.49-277.64,84.46-2.32,169.39,75.6,178.18,163.49" />
                <path className={classes.tennisBallGradient09}
                  d="M1377.47,831.74c10.74,126.87-127.75,186.89-228,98.81-55-48.35-68.79-118.33-61-152.57,21.33-93.79,96.75-103.32,119.35-104,89.21-2.71,163.13,81.06,169.62,157.76" />
                <path className={classes.tennisBallGradient08}
                  d="M1372.26,831.74c7.49,104.57-107.9,185.51-222.79,83.16C1106.67,876.77,1091,808.34,1099,778c21.85-82.63,79.87-96.79,112.51-97.87,85.57-2.83,155.43,77.72,160.72,151.63" />
                <path className={classes.tennisBallGradient07}
                  d="M1366.62,831.74c2.64,89.66-96.22,177.38-217.17,67.23-26.89-24.48-46.49-95.12-38.49-121,6.78-21.91,27.4-88.59,104.15-91.35,66.17-2.37,144.67,56.64,151.51,145.11" />
                <path className={classes.tennisBallGradient06}
                  d="M1359.22,831.74c0,9.26-10.28,52.49-14,58.56-12.25,20.12-45.64,39.77-45.9,39.84C1276,937,1221.2,953,1158.63,890.3c-26.47-26.51-42.83-87.7-35.27-112.32.13-.41,14.22-42.62,27.16-54,7.47-6.55,34.46-28,68.37-29.8,49.28-2.57,140,47.71,140.33,137.53" />
                <path className={classes.tennisBallGradient05}
                  d="M1351.16,831.74c-.63,46.06-30.67,77.6-50.77,84.4-1.27.43-45.55,9-46,8.93-46.4-9.81-57.93-16.23-76.15-34.77-.28-.28-29.83-34.45-38.9-58.56-.27-.74-3.55-53-3.36-53.76,15.28-63.71,66-73.33,87.08-74.51,45.82-2.55,129.27,44.2,128.1,128.27" />
                <path className={classes.tennisBallGradient04}
                  d="M1342.53,831.74c-.61,20.71-26.23,64-41.55,70.18-1,.42-45.33,9.45-46,9.37-.42,0-46.47-9.51-57.55-21l-43.79-58.56c-.65-1.51-5.19-51.75-4.85-53.76.45-2.69,30.4-47.21,30.67-47.43,18.27-15.11,44.61-17.08,47.56-17.25,53.65-3.19,117.49,50.83,115.51,118.44" />
                <path className={classes.tennisBallGradient03}
                  d="M1332.08,831.74c-.81,5-26.87,50.09-31,53.6-5.95,5.07-42.11,12-45.83,12-5.81,0-39.84-14.42-51-23.26l-32-42.3L1166.8,778l27.3-42c.22-.24,25.66-21.93,74.18-6.56,6.62,2.1,53,48.12,53.24,48.52,2.43,3.9,11.32,49.12,10.56,53.76" />
                <path className={classes.tennisBallGradient02}
                  d="M1316.64,831.74c-1.83,9.67-8.63,22.47-16.53,28.35-1.19.89-44,17.5-44.91,17.42L1203.79,849c-5.36-3.79-10.51-11.39-13.38-17.28L1184.94,778c.63-6.83,7.17-14.67,12.19-19.36l38.1-21c.46-.06,40.94,11.74,41.65,12.14,1.42.8,26.81,25,29.54,28.17,2,2.35,11,49.57,10.22,53.76" />
                <path className={classes.tennisBallGradient01}
                  d="M1300,831.74c-1.59,7.85-45.36,21-45.77,20.94L1216,831.74,1207.85,778l32.94-19.49c.51-.06,40.65,12.9,43.82,14.15,2.12.84,4,3.57,5.41,5.34,1.72,2.12,10.67,50.35,10,53.76" />
              </g>
            </g>
            <g className={classes.cls51}>
              <path className={classes.tennisBallSeams}
                d="M1360,739.46a255.09,255.09,0,0,1,0,301.68q-2.8,3.83-5.76,7.55H953.53q-2.94-3.72-5.75-7.55A254.48,254.48,0,0,1,911.51,971c-3.37-11.66-7.24-27.4-9.68-39.3a258.56,258.56,0,0,1,0-82.85,254.34,254.34,0,0,1,9.68-39.3c4.22-11.16,10.42-25.84,15.49-36.64a254.75,254.75,0,0,1,20.78-33.47l10.53-13.41h391.2L1360,739.46m-25.53-29.76c-2.6-2.6-5.24-5.14-8-7.62H981.25q-4.05,3.72-7.94,7.62c-8,8.63-18.22,20.54-25.53,29.76A255.81,255.81,0,0,0,927,772.93c-5.07,10.8-11.27,25.48-15.49,36.64a254.34,254.34,0,0,0-9.68,39.3,258.56,258.56,0,0,0,0,82.85c2.44,11.9,6.31,27.64,9.68,39.3a255.76,255.76,0,0,0,61.8,99.88q1.22,1.23,2.46,2.43h356.28l2.46-2.43a256,256,0,0,0,46.3-63.23A253.67,253.67,0,0,0,1406,931.72a257.8,257.8,0,0,0,0-82.85A254.45,254.45,0,0,0,1360,739.46c-7.31-9.22-17.37-21.29-25.53-29.76" />
            </g>
          </g>
        </g>
      </g>

    </svg>
  )
});

export default MyTennisBrainLogo;