import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsAGoogleDriveLink Returns whether the URL is a Google Drive location
 * @param url
 */
export const urlIsAGoogleDriveLink: (url: string) => boolean = (url: string) => {
  // if the url includes the domain for Google Drive ('drive.google.com'), then it's a Google Drive URL
  // return url.includes('drive.google.com');
  
  // if the url starts with the pattern 'https://drive.google.com/file/d', then it's a Google Drive URL
  return url.includes('https://drive.google.com/file/d');
}
