import { IFirestoreChannelRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IChannel, IChannelAsJson } from "../../../../dataObjects/models/channels/Channel";
import { removeChannelAsChildFromAllCategories } from "../categoryActions";


export function deleteChannel(channel: IChannel): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Channel repository from the repository factory
      const firestoreChannelRepository: IFirestoreChannelRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
        IFirestoreBaseRepository<IChannel, IChannelAsJson>;

      // call a method to remove the Channel from all Category objects
      removeChannelAsChildFromAllCategories(channel.id);

      // call the repository delete() method to delete the object
      await firestoreChannelRepository.delete(channel.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}