import { IEmailRequest, IEmailRequestAsJson } from "../../../../dataObjects/models/emailRequests";
import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";

/**
 * @function submitEmailRequestToServer Submits a given Email Request to an appropriate Firebase Function.
 * @param {IEmailRequest} emailRequest The email request to be submitted.
 * @returns {void} No return value
 */
export function submitEmailRequestToServer(emailRequest: IEmailRequest): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // whether to display console logs (displayConsoleLogs && console.log statements)
      const displayConsoleLogs: boolean = false;

      displayConsoleLogs && console.log(`%c Entered submitEmailRequestToServer. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #055; color: #fff');

      // convert the emailRequest to JSON
      const emailRequestAsJson: IEmailRequestAsJson = emailRequest.toJSON(true);

      displayConsoleLogs && console.log(`%c In submitEmailRequestToServer. emailRequestAsJson: ${JSON.stringify(emailRequestAsJson)}`, 'background: #055; color: #fff');

      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const submitEmailRequest =
        httpsCallable<IEmailRequestAsJson>(functions, 'submitEmailRequest');
      submitEmailRequest(emailRequestAsJson)
        .then(() => {
          displayConsoleLogs && console.log(`%c In submitEmailRequestToServer(). Returned from Http call.`, 'background: #055; color: #fff');

          resolve();
        });
    } catch (error: any) {
      reject(error);
    }
  });
}
