import { put } from "redux-saga/effects";
import { saveDocumentLink } from "../../../firebaseServices/dataServices/dataServiceActions/documentLinkActions";
import { IDocumentLink } from '../../../dataObjects/models/digitalMedia/DocumentLink';
import { ISaveDocumentLinkRequest } from '../../../dataObjects/models/serviceRequests/documentLink/SaveDocumentLinkRequest';
import { ISaveDocumentLinkWorkflowState } from "../../store/SaveDocumentWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";
import { documentLinkSaveError, documentLinkSaveSuccess, documentLinkSaveWorkflowStateChange } from "../../slices/documentLink/documentLinkSaveStatusSlice";

/**
 * @function saveDocumentLinkOnlyWorkerSaga A "worker" saga function that performs the work for a request to
 *   save an DocumentLink only (without a file upload).
 * @param action 
 */
export function* saveDocumentLinkOnlyWorkerSaga(saveDocumentLinkRequest: ISaveDocumentLinkRequest) {
  try {
    // if the ISaveDocumentLinkRequest is defined...
    if (saveDocumentLinkRequest !== undefined) {

      // update store state...      
      const saveDocumentLinkWorkflowState: ISaveDocumentLinkWorkflowState = {
        documentLink: saveDocumentLinkRequest.documentLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.documentLink_SaveRequested,
        fileUploadRequest: undefined
      }
      yield put(documentLinkSaveWorkflowStateChange(saveDocumentLinkWorkflowState));

      // get the DocumentLink from ISaveDocumentLinkRequest
      const documentLink: IDocumentLink | undefined = saveDocumentLinkRequest.documentLink;

      // if the DocumentLink is defined...
      if (documentLink !== undefined) {
        // call services method to save the documentLink
        yield saveDocumentLink(documentLink)

        // dispatch an action to indicate that the save operation was successful
        yield put(documentLinkSaveSuccess(documentLink));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveDocumentLinkWorkflowState: ISaveDocumentLinkWorkflowState = {
          documentLink: saveDocumentLinkRequest.documentLink,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.documentLink_SaveSuccess,
        }

        saveDocumentLinkWorkflowState.workflowState = enumWorkflowState.Success;
        saveDocumentLinkWorkflowState.workflowStateMessage = MessagesStringAssets.documentLink_SaveSuccess;
        yield put(documentLinkSaveWorkflowStateChange(saveDocumentLinkWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(documentLinkSaveError(error));
  }
}