import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const searchMetadataPopulateFailureSlice = createSlice({
  name: 'searchMetadataPopulateFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    searchMetadataPopulateFailure(state, action) {
      return action.payload;
    },

    // action has a payload with a null value
    searchMetadataPopulateFailureClear(state, action) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { searchMetadataPopulateFailure, searchMetadataPopulateFailureClear } = searchMetadataPopulateFailureSlice.actions;

// export the core reducer for the slice
export const searchMetadataPopulateFailureReducer = searchMetadataPopulateFailureSlice.reducer;

export default searchMetadataPopulateFailureSlice.reducer;