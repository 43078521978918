import { enumSharingPermission } from "../../../enums";
import { IChannelSharingUserEditViewModel } from "./";


export class ChannelSharingUserEditViewModel implements IChannelSharingUserEditViewModel {

  /**
   * @method Constructor method
   * @param {string} email The email address of the user
   * @param {string} name The name of the user
   * @param {enumSharingPermission} sharingPermission The permission the user has in his/her sharing of the channel
   */
  constructor(
    email: string,
    name: string,
    sharingPermission: enumSharingPermission
  ) {

    // assign properties from ctor parameters
    this._email = email;
    this._name = name;
    this._sharingPermission = sharingPermission;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _email The email address of the user
   */
  private _email: string;

  /**
   * @method email Getter method for _email
   */
  get email(): string {
    return this._email;
  }

  /**
   * @method userEmail Setter method for _email
   * @param {string} value The input value for setting _email
   */
  set email(value: string) {
    this._email = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _name The name of the user
   */
  private _name: string;

  /**
   * @method name Getter method for _name
   */
  get name(): string {
    return this._name;
  }

  /**
   * @method name Setter method for _name
   * @param {string} value The input value for setting _userName
   */
  set name(value: string) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSharingPermission} __sharingPermission The permission the user has in his/her sharing of the channel
   */
  private _sharingPermission: enumSharingPermission;

  /**
   * @method _sharingPermission Getter method for __sharingPermission
   */
  get sharingPermission(): enumSharingPermission {
    return this._sharingPermission;
  }

  /**
   * @method _sharingPermission Setter method for __sharingPermission
   * @param {string} value The input value for setting __sharingPermission
   */
  set sharingPermission(value: enumSharingPermission) {
    this._sharingPermission = value;
  }
  /*-----------------------------------------------*/
}