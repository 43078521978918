import { typeUniqueId } from "../../../../dataObjects/types"
import { ITopicItem, ITopicItemAsJson } from "../../../../dataObjects/models/topics/TopicItem";
import { IFirestoreTopicItemRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicItemRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";


export function getTopicItemObjectUsingId(topicItemId: typeUniqueId): Promise<ITopicItem | undefined> {
  return new Promise<ITopicItem | undefined>(async (resolve, reject) => {
    try {
      // get a TopicItem repository from the repository factory
      const firestoreTopicItemRepository: IFirestoreTopicItemRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.TopicItem) as
        IFirestoreBaseRepository<ITopicItem, ITopicItemAsJson>;

      // make request of the repository to get the TopicItem object associated with the Id
      const topicItem: ITopicItem | undefined = await firestoreTopicItemRepository.get(topicItemId);

      resolve(topicItem);
    } catch (error: any) {
      reject(error);
    }
  });
}