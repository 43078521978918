import { IFirestoreSocialMediaPostRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreSocialMediaPostRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ISocialMediaPost, ISocialMediaPostAsJson } from "../../../../dataObjects/models/digitalMedia/SocialMediaPost";


export function deleteSocialMediaPost(socialMediaPost: ISocialMediaPost): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a SocialMediaPost repository from the repository factory
      const firestoreSocialMediaPostRepository: IFirestoreSocialMediaPostRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.SocialMediaPost) as
        IFirestoreBaseRepository<ISocialMediaPost, ISocialMediaPostAsJson>;

      // call the repository delete() method to delete the object
      await firestoreSocialMediaPostRepository.delete(socialMediaPost.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}