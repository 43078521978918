import { take, put } from 'redux-saga/effects';
import { deleteCategory } from '../../../firebaseServices/dataServices/dataServiceActions/categoryActions';
import { categoryDeleteRequest, categoryDeleteStatusChange } from '../../slices/category/categoryDeleteStatusSlice';
import { categoryDeleteFailureChange } from '../../slices/category/categoryDeleteFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteCategoryRequestedSaga A saga workflow pertaining to a user having requested to delete a category.
 */
export function* deleteCategoryRequestedSaga() {
  // console.info('(deleteCategoryRequestedSaga) entered deleteCategoryRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the categoryDeleteRequest action to be dispatched and get the payload data...
      const categoryDeleteRequestData = yield take(categoryDeleteRequest);
      const { payload: category } = categoryDeleteRequestData;

      // make a request to delete the category
      yield deleteCategory(category)

      // dispatch an action to indicate that the delete operation was successful
      yield put(categoryDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(categoryDeleteStatusChange(enumWorkflowState.Failure));
      yield put(categoryDeleteFailureChange(error));
    }
  }
}
