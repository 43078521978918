import { put } from "redux-saga/effects";
import { saveHyperLink } from "../../../firebaseServices/dataServices/dataServiceActions/hyperLinkActions";
import { IHyperLink } from '../../../dataObjects/models/digitalMedia/HyperLink';
import { ISaveHyperLinkRequest } from '../../../dataObjects/models/serviceRequests/hyperLink/SaveHyperLinkRequest';
import { hyperLinkSaveError, hyperLinkSaveSuccess, hyperLinkSaveWorkflowStateChange } from "../../slices/hyperLink/hyperLinkSaveStatusSlice";
import { ISaveHyperLinkWorkflowState } from "../../store/SaveHyperLinkWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";

/**
 * @function saveHyperLinkWorkerSaga A "worker" saga function that performs the work for a request to
 *   save a HyperLink .
 * @param action 
 */
export function* saveHyperLinkWorkerSaga(saveHyperLinkRequest: ISaveHyperLinkRequest) {
  try {
    // // extract the ISaveHyperLinkRequest from the action payload
    // const saveHyperLinkRequest: ISaveHyperLinkRequest | undefined = action.saveHyperLinkRequest;

    // if the ISaveHyperLinkRequest is defined...
    if (saveHyperLinkRequest !== undefined) {
      
      // update store state...      
      const saveHyperLinkWorkflowState: ISaveHyperLinkWorkflowState = {
        hyperLink: saveHyperLinkRequest.hyperLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.hyperLink_SaveRequested,
      }
      yield put(hyperLinkSaveWorkflowStateChange(saveHyperLinkWorkflowState));

      // get the HyperLink from ISaveHyperLinkRequest
      const hyperLink: IHyperLink | undefined = saveHyperLinkRequest.hyperLink;

      // if the HyperLink is defined...
      if (hyperLink !== undefined) {
        // call services method to save the hyperLink
        yield saveHyperLink(hyperLink)

        // dispatch an action to indicate that the save operation was successful
        yield put(hyperLinkSaveSuccess(hyperLink));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveHyperLinkWorkflowState: ISaveHyperLinkWorkflowState = {
          hyperLink: saveHyperLinkRequest.hyperLink,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.hyperLink_SaveSuccess,
        }

        saveHyperLinkWorkflowState.workflowState = enumWorkflowState.Success;
        saveHyperLinkWorkflowState.workflowStateMessage = MessagesStringAssets.hyperLink_SaveSuccess;
        yield put(hyperLinkSaveWorkflowStateChange(saveHyperLinkWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(hyperLinkSaveError(error));
  }
}