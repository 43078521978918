import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const userAccountExpungeFailureSlice = createSlice({
  name: 'userAccountExpungeFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userAccountExpungeFailure(state, action) {
      return action.payload;
    },

    // action has a payload with a null value
    userAccountExpungeFailureClear(state, action) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userAccountExpungeFailure, userAccountExpungeFailureClear } = userAccountExpungeFailureSlice.actions;

// export the core reducer for the slice
export const userAccountExpungeFailureReducer = userAccountExpungeFailureSlice.reducer;

export default userAccountExpungeFailureSlice.reducer;