import { put } from 'redux-saga/effects';
import { ISaveAudioLinkRequest } from '../../../dataObjects/models/serviceRequests/audioLink/SaveAudioLinkRequest';
import { IAudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult, uploadFileToCloudStorage } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions';
import { ISaveAudioLinkWorkflowState } from '../../store/SaveAudioWorkflowState';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { audioLinkFileUploadResult, audioLinkSaveError, audioLinkSaveWithoutFileUploadRequest, audioLinkSaveWorkflowStateChange } from '../../slices/audioLink/audioLinkSaveStatusSlice';


export function* saveAudioLinkWithFileUploadWorkerSaga(saveAudioLinkRequest: ISaveAudioLinkRequest) {
  try {
    // console.info('(saveAudioLinkWithFileUploadWorkerSaga) Entered saveAudioLinkWithFileUploadWorkerSaga');

    // // used for debugging timing
    // const startTime: number = Date.now();

    // if the ISaveAudioLinkRequest is defined...
    if (saveAudioLinkRequest !== undefined) {
      // get the IFileUploadRequest from the ISaveAudioLinkRequest object
      const fileUploadRequest: IFileUploadRequest | undefined = saveAudioLinkRequest.fileUploadRequest;

      // get the AudioLink object from the request
      const audioLink: IAudioLink | undefined = saveAudioLinkRequest.audioLink;

      // only proceed if both the IFileUploadRequest and AudioLink objects have been defined
      if (fileUploadRequest !== undefined && audioLink !== undefined) {
        const fileUploadResult: ICloudStorageFileUploadResult = yield uploadFileToCloudStorage(fileUploadRequest.fileToUpload, fileUploadRequest.userId,
          fileUploadRequest.fileClass, fileUploadRequest.fileUniqueId, fileUploadRequest.uploadProgressCallback);

        // update store state...      
        const saveAudioLinkWorkflowState: ISaveAudioLinkWorkflowState = {
          audioLink: saveAudioLinkRequest.audioLink,
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.audioLink_SaveRequested,
          fileUploadRequest: fileUploadRequest
        }
        yield put(audioLinkSaveWorkflowStateChange(saveAudioLinkWorkflowState));

        // console.info(`(saveAudioLinkWithFileUploadWorkerSaga) Elapsed time after uploading file: ${Date.now() - startTime} milliseconds`);
        // dispatch action to indicate that there is a fileUploadResult
        yield put(audioLinkFileUploadResult(fileUploadResult));

        // console.info(`(saveAudioLinkWithFileUploadWorkerSaga) Elapsed time after dispatching fileUploadResult: ${Date.now() - startTime} milliseconds`);

        // set the baseStoragePath and downloadUrl for the AudioLink object
        saveAudioLinkRequest.audioLink.baseStoragePath = fileUploadResult.baseStoragePath;
        saveAudioLinkRequest.audioLink.downloadUrl = fileUploadResult.downloadUrl;
        saveAudioLinkRequest.audioLink.mediaType = fileUploadResult.mediaType;

        // console.info(`(saveAudioLinkWithFileUploadWorkerSaga) Elapsed time after delay, following uploading file: ${Date.now() - startTime} milliseconds`);

        // now, dispatch an action to save the AudioLink object
        yield put(audioLinkSaveWithoutFileUploadRequest(saveAudioLinkRequest));
      }

    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(audioLinkSaveError(error));
  }
}