import { IFirestoreDocumentLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDocumentLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IDocumentLink, IDocumentLinkAsJson } from "../../../../dataObjects/models/digitalMedia/DocumentLink";


export function saveDocumentLink(documentLink: IDocumentLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a DocumentLink repository from the repository factory
      const firestoreDocumentLinkRepository: IFirestoreDocumentLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.DocumentLink) as
        IFirestoreBaseRepository<IDocumentLink, IDocumentLinkAsJson>;

      // determine if the documentLink already exists by calling the repository get() method to try and get the object
      const existingDocumentLink: IDocumentLink | undefined = await firestoreDocumentLinkRepository.get(documentLink.id);

      // If the documentLink wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingDocumentLink === undefined) {
        // call repository method to create the documentLink
        await firestoreDocumentLinkRepository.create(documentLink);
      } else {
        // call repository method to update the documentLink
        await firestoreDocumentLinkRepository.update(documentLink);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}