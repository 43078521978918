import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { IThemeSpecsMetadata, IThemeSpecsMetadataAsJson } from './';
import { ILocaleTranslation, LocaleTranslation } from '../../locale/LocaleTranslation';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';

/** 
 * @class ThemeSpecsMetadata represents ThemeSpecs-specific metadata
 */
export class ThemeSpecsMetadata extends VersionAwarePersistable implements IThemeSpecsMetadata {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {Array<ILocaleTranslation<string>>} nameTranslations A collection of translations of the name in different languages/locale
   * @param {Array<ILocaleTranslation<string>>} descriptionTranslations A collection of translations of the description in different languages/locale
   */
  /**
   * @method Constructor method
   * @param {typeUniqueId} parentId Id of the parent of this object
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userPersistenceData?: IUserPersistenceData,
    nameTranslations?: Array<ILocaleTranslation<string>>,
    descriptionTranslations?: Array<ILocaleTranslation<string>>
  ) {
    super(ownerId, enumPersistableObjectClassName.ThemeSpecsMetadata, enumPersistableObjectType.ThemeSpecsMetadata, id, parentObjectType, parentId, objectState, userPersistenceData);

    if (nameTranslations) {
      this.nameTranslations = nameTranslations;
    }

    if (descriptionTranslations) {
      this.descriptionTranslations = descriptionTranslations;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {Array<ILocaleTranslation<string>>} _nameTranslations property A collection of translations of the name in different languages/locales
   */
  private _nameTranslations: Array<ILocaleTranslation<string>> = [];

  /**
   * @method nameTranslations is an optional getter method for _nameTranslations
   */
  get nameTranslations() {
    return this._nameTranslations;
  }

  /**
   * @method nameTranslations is an optional setter method for _nameTranslations
   * @param {Array<ILocaleTranslation<string>>} value is the input value for setting _nameTranslations
   */
  set nameTranslations(value) {
    this._nameTranslations = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<ILocaleTranslation<string>>} _descriptionTranslations property A collection of translations of the name in different languages/locales
   */
  private _descriptionTranslations: Array<ILocaleTranslation<string>> = [];

  /*-----------------------------------------------*/
  /**
   * @method descriptionTranslations is an optional getter method for _descriptionTranslations
   */
  get descriptionTranslations() {
    return this._descriptionTranslations;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method descriptionTranslations is an optional setter method for _descriptionTranslations
   * @param {Array<ILocaleTranslation<string>>} value is the input value for setting _descriptionTranslations
   */
  set descriptionTranslations(value) {
    this._descriptionTranslations = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IThemeSpecsMetadata} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IThemeSpecsMetadata {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IThemeSpecsMetadata = Object.create(ThemeSpecsMetadata.prototype);
    Object.assign(copyOfObject, this);

    // copy each of the translation arrays
    if (this.nameTranslations !== undefined) {
      copyOfObject.nameTranslations = new Array<ILocaleTranslation<string>>();
      this.nameTranslations.forEach(nameTranslation => {
        copyOfObject.nameTranslations.push(nameTranslation.copy());
      })
    }

    if (this.descriptionTranslations !== undefined && this.descriptionTranslations.length > 0) {
      copyOfObject.descriptionTranslations = new Array<ILocaleTranslation<string>>();
      this.descriptionTranslations.forEach(descriptionTranslation => {
        copyOfObject.descriptionTranslations.push(descriptionTranslation.copy());
      })
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON converts a ThemeSpecsItemMetadata object to JSON -- is automatically used by JSON.stringify
   * This method is provided both to serialize the object to include property names without the leading underscore '_'
   * character AND to convert data types that may not properly serialize/deserialize using their default formats --
   * Date is an example of that.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IThemeSpecsMetadataAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IThemeSpecsMetadataAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.nameTranslations = LocaleTranslation.toJSONArray(this.nameTranslations);
      jsonObject.descriptionTranslations = LocaleTranslation.toJSONArray(this.descriptionTranslations);

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IThemeSpecsMetadataAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IThemeSpecsMetadataAsJson, includeContainedObjects: boolean = true): IThemeSpecsMetadata {
    try {
      // create a new instance of this class
      let channelItemMetadataObject: ThemeSpecsMetadata = Object.create(ThemeSpecsMetadata.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      channelItemMetadataObject = super.fromJSONProtected(channelItemMetadataObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.nameTranslations) {
        channelItemMetadataObject.nameTranslations = JsonConverter.arrayFromJSONArray(LocaleTranslation, jsonObject.nameTranslations);
      }

      if (jsonObject.descriptionTranslations) {
        channelItemMetadataObject.descriptionTranslations = JsonConverter.arrayFromJSONArray(LocaleTranslation, jsonObject.descriptionTranslations);
      }

      return channelItemMetadataObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
