import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { ISocialMediaPost, ISocialMediaPostAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';

/** 
 * @class SocialMediaPost A audio link that can be associated with something
 */
export class SocialMediaPost extends VersionAwarePersistable implements ISocialMediaPost {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} url A full Url for the Social Media Post
   * @param {string} description A description of the Social Media Post
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    url: string,
    description: string,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.SocialMediaPost, enumPersistableObjectType.SocialMediaPost, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.url = url;
    this.description = description;
  }


  /*-----------------------------------------------*/
  /**
   * @property {string} _url The full Url for the Social Media Post
   */
  private _url: string = '';

  /**
   * @method url Getter method for _url
   */
  get url(): string {
    return this._url;
  }

  /**
   * @method url Setter method for _url
   * @param {string} value The input value for setting _url
   */
  set url(value: string) {
    this._url = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description The description for the SocialMediaPost
   */
  private _description: string = '';

  /**
   * @method description Getter method for _description
   */
  get description(): string {
    return this._description;
  }

  /**
   * @method description Setter method for _description
   * @param {string} value The input value for setting _description
   */
  set description(value: string) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ISocialMediaPost} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ISocialMediaPost {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ISocialMediaPost = Object.create(SocialMediaPost.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ISocialMediaPostAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: ISocialMediaPostAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.url = this.url;
      jsonObject.description = this.description;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ISocialMediaPostAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ISocialMediaPostAsJson, includeContainedObjects: boolean = true): ISocialMediaPost {
    try {
      // create a new instance of this class
      let socialMediaPostObject: SocialMediaPost = Object.create(SocialMediaPost.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      socialMediaPostObject = super.fromJSONProtected(socialMediaPostObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.url) {
        socialMediaPostObject.url = jsonObject.url;
      }

      if (jsonObject.description) {
        socialMediaPostObject.description = jsonObject.description;
      }
      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return socialMediaPostObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
