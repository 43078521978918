import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsAnAppleMusicAddress Returns whether the URL is an Apple Music address
 * @param url
 */
export const urlIsAnAppleMusicAddress: (url: string) => boolean = (url: string) => {

  // get the regular expression pattern for the website
  const regEx: RegExp = RegularExpressions.AppleMusic;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
