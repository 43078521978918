import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableObjectType, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState } from "../../../enums";
import { IUserPersistenceData, UserPersistenceData } from '../UserPersistenceData';
import { Persistable } from '../Persistable';
import { IVersionAwarePersistable, IVersionAwarePersistableAsJson } from ".";
import { JsonConverter } from '../../../utilities/JsonConverter';

/** 
 * @class VersionAwarePersistable A Persistable object that also has versioning information
 */
export abstract class VersionAwarePersistable extends Persistable implements IVersionAwarePersistable {
  /**
   * @method Constructor For VersionAwarePersistable
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {string} className Name of the class behind the instance
   * @param {typePersistableObjectType} objectType Type of object behind the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {IVersionAwarePersistable} parentObject A live instance of the object's parent
   */
  constructor(
    ownerId: typeUniqueId,
    className: string,
    objectType: typePersistableObjectType,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userPersistenceData?: IUserPersistenceData,
    parentObject?: IVersionAwarePersistable
  ) {
    super(ownerId, className, objectType, id, parentObjectType, parentId);
    this._objectState = objectState;

    this.userPersistenceData = userPersistenceData;
    this.parentObject = parentObject;
  }

  /*-----------------------------------------------*/
  /**
   * @property {number} _versionToken A token that represents the version of the object that was last persisted.
   * (Note: This token will be generated using Date.now(), which represents the number of milliseconds elapsed 
   * since January 1, 1970 00:00:00 UTC.) This value can be turned into a date via the Data constructor:
   * @example const dateFromVersionToken = new Date(versionToken);
   */
  private _versionToken: number = Date.now();

  /**
   * @method versionToken is an optional getter method for _versionToken
   */
  get versionToken() {
    return this._versionToken;
  }

  /**
   * @method versionToken is an optional setter method for _versionToken
   * @param {number} value is the input value for setting _versionToken
   */
  set versionToken(value: number) {
    this._versionToken = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumObjectPersistenceState} _objectState The state of the object since it was last persisted.
   */
  private _objectState: enumObjectPersistenceState = enumObjectPersistenceState.NotSet;

  /**
   * @method objectState is an optional getter method for _objectState
   */
  get objectState() {
    return this._objectState;
  }

  /**
   * @method objectState is an optional setter method for _objectState
   * @param {enumObjectPersistenceState} value is the input value for setting _objectState
   */
  set objectState(value: enumObjectPersistenceState) {
    this._objectState = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistable | undefined} parentObject The object instance that is a parent of 'this' instance.
   * This is used to enable communications from a child object to a parent object when there is a live object graph.
   * This property should not be serialized to JSON, but only used with a live object graph.
   */
  private _parentObject: IVersionAwarePersistable | undefined = undefined;

  /**
   * @method objectState is an optional getter method for _objectState
   */
  get parentObject() {
    return this._parentObject;
  }

  /**
   * @method objectState is an optional setter method for _objectState
   * @param {IVersionAwarePersistable | undefined} value is the input value for setting _objectState
   */
  set parentObject(value: IVersionAwarePersistable | undefined) {
    this._parentObject = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IUserPersistenceData | undefined} _userPersistenceData Contains user-related data pertaining to persistence, providing
   * information such as the user who requested a persistence operation (creation, update, deletion, etc.), the device being used
   * at the time, and the locale setting of the device at the time. 
   */
  private _userPersistenceData: IUserPersistenceData | undefined = undefined;

  /**
   * @method userPersistenceData Getter method for _userPersistenceData
   */
  get userPersistenceData(): IUserPersistenceData | undefined {
    return this._userPersistenceData;
  }

  /**
   * @method UserPersistenceData Setter method for _userPersistenceData
   * @param {IUserPersistenceData | undefined} value The value to be used in setting _userPersistenceData
   */
  set userPersistenceData(value: IUserPersistenceData | undefined) {
    this._userPersistenceData = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IVersionAwarePersistable} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IVersionAwarePersistable {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IVersionAwarePersistable = Object.create(VersionAwarePersistable.prototype);
    Object.assign(copyOfObject, this);

    // copy contained objects
    if (this.userPersistenceData !== undefined) {
      copyOfObject.userPersistenceData = this.userPersistenceData.copy();
    }

    return copyOfObject;

  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of the derived class instance to JSON, along with all of its descendants. 
   * (Note: This method will be called if JSON.stringify() is executed on an instance.)
   * @returns A JSON object with serialized data from 'this' instance of the derived class.
   */
  // abstract toJSON(includeDescendants: boolean): IVersionAwarePersistableAsJson;
  toJSON(includeContainedObjects: boolean): IVersionAwarePersistableAsJson {
    try {

      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IVersionAwarePersistableAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.versionToken = this.versionToken;
      jsonObject.objectState = this.objectState;
      jsonObject.userPersistenceData = (this.userPersistenceData === undefined ? 'undefined' : this.userPersistenceData.toJSON());

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects to include
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSONProtected A static method that converts the properties of this base class from a JSON object to an instance of a 
   * derived class.
   * @param classType 
   * @param jsonObject 
   * @param includeContainedObjects 
   */
  protected static fromJSONProtected(versionAwarePersistableObject: VersionAwarePersistable, jsonObject: IVersionAwarePersistableAsJson, includeContainedObjects: boolean = true): any {
    try {
      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      versionAwarePersistableObject = super.fromJSONProtected(versionAwarePersistableObject, jsonObject, includeContainedObjects);

      // fill the remaining properties of the object that pertain to this base class
      if (jsonObject.versionToken !== undefined) {
        versionAwarePersistableObject.versionToken = jsonObject.versionToken;
      }

      if (jsonObject.objectState !== undefined) {
        versionAwarePersistableObject.objectState = jsonObject.objectState;
      }

      if (jsonObject.userPersistenceData !== undefined) {
        versionAwarePersistableObject.userPersistenceData = (jsonObject.userPersistenceData === 'undefined' ? undefined : JsonConverter.fromJSON(UserPersistenceData, jsonObject.userPersistenceData));
      }

      // if requested to include contained objects, de-serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects to include
      }

      return versionAwarePersistableObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method markAsModified Marks the object as having been modified, and also calls the parent object to mark as modified.
   * This will cascade 'markAsModified' through the ancestry of objects, unless one of the ancestors has overridden this 
   * method and stops the cascading. With the cascading, it fulfills the need for a parent object to be marked as 'Updated'
   * if any of its children have been modified.
   */
  markObjectStateAsModified(cascadeToAncestors: boolean): void {
    // first, set the state of 'this' object to 'Modified'
    this.objectState = enumObjectPersistenceState.Modified;

    // if requested to cascade the request, ...
    if (cascadeToAncestors) {
      // if this instance has a live parentObject, cascade the request
      if (this.parentObject !== undefined) {
        this.parentObject.markObjectStateAsModified(cascadeToAncestors);
      }
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /** 
   * @method propagateObjectStateChangeToDescendents Requests to change the state of each VersionAwarePersistable-derived descendant object of this class
   * @param {enumObjectPersistenceState} newState The state to which each descdendant object is to be set
   */
  propagateObjectStateChangeToDescendents(newState: enumObjectPersistenceState): void {
    // Do nothing in this base class implementation.
    // Derived classes can optionally implement the method if they contain child VersionAwarePersistable-derived class instances.
  }
  /*-----------------------------------------------*/

}
