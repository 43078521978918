import { enumSharingPermission } from "../../../enums";
import { typeUniqueId } from "../../../types";
import { IChannelSharingUser } from "./";


export class ChannelSharingUser implements IChannelSharingUser {

  /**
   * @method Constructor method
   * @param {typeUniqueId} userId Id of the user
   * @param {string} userEmail The email address of the user
   * @param {string} userFirstName The first name of the user
   * @param {string} userLastName The last name of the user
   * @param {typeUniqueId} channelId Id of the channel
   * @param {string} channelName The name of the channel
   * @param {enumSharingPermission} permission The permission the user has in his/her sharing of the channel
   */
  constructor(
    userId: typeUniqueId,
    userEmail: string,
    userFirstName: string,
    userLastName: string,
    channelId: typeUniqueId,
    channelName: string,
    permission: enumSharingPermission
  ) {

    // assign properties from ctor parameters
    this._userId = userId;
    this._userEmail = userEmail;
    this._userFirstName = userFirstName;
    this._userLastName = userLastName;
    this._channelId = channelId;
    this._channelName = channelName;
    this._permission = permission;
  }


  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _userId The Id of the user
   */
  private _userId: typeUniqueId;

  /**
   * @method userId Getter method for _userId
   */
  get userId(): typeUniqueId {
    return this._userId;
  }

  /**
   * @method userId Setter method for _userId
   * @param {typeUniqueId} value The input value for setting _userId
   */
  set userId(value: typeUniqueId) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _userEmail The email address of the user
   */
  private _userEmail: string;

  /**
   * @method userEmail Getter method for _userEmail
   */
  get userEmail(): string {
    return this._userEmail;
  }

  /**
   * @method userEmail Setter method for _userEmail
   * @param {string} value The input value for setting _userEmail
   */
  set userEmail(value: string) {
    this._userEmail = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _userFirstName The first name of the user
   */
  private _userFirstName: string;

  /**
   * @method userFirstName Getter method for _userFirstName
   */
  get userFirstName(): string {
    return this._userFirstName;
  }

  /**
   * @method userFirstName Setter method for _userFirstName
   * @param {string} value The input value for setting _userFirstName
   */
  set userFirstName(value: string) {
    this._userFirstName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _userLastName The last name of the user
   */
  private _userLastName: string;

  /**
   * @method userLastName Getter method for _userLastName
   */
  get userLastName(): string {
    return this._userLastName;
  }

  /**
   * @method userLastName Setter method for _userLastName
   * @param {string} value The input value for setting _userLastName
   */
  set userLastName(value: string) {
    this._userLastName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method formattedName A formatted version of the full name of the user
   */
  get formattedName(): string {
    return `${this._userLastName}, ${this._userFirstName}`;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _channelId The Id of the channel
   */
  private _channelId: typeUniqueId;

  /**
   * @method channelId Getter method for _channelId
   */
  get channelId(): typeUniqueId {
    return this._channelId;
  }

  /**
   * @method channelId Setter method for _channelId
   * @param {typeUniqueId} value The input value for setting _channelId
   */
  set channelId(value: typeUniqueId) {
    this._channelId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _channelName The name of the channel
   */
  private _channelName: string;

  /**
   * @method channelName Getter method for _channelName
   */
  get channelName(): string {
    return this._channelName;
  }

  /**
   * @method channelName Setter method for _channelName
   * @param {string} value The input value for setting _channelName
   */
  set channelName(value: string) {
    this._channelName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSharingPermission} _permission The permission the user has in his/her sharing of the channel
   */
  private _permission: enumSharingPermission;

  /**
   * @method permission Getter method for _permission
   */
  get permission(): enumSharingPermission {
    return this._permission;
  }

  /**
   * @method permission Setter method for _permission
   * @param {string} value The input value for setting _permission
   */
  set permission(value: enumSharingPermission) {
    this._permission = value;
  }
  /*-----------------------------------------------*/
}