import { take, put } from 'redux-saga/effects';
import { enumSharingRequestStatus, enumWorkflowState } from '../../../dataObjects/enums';
import { saveObjectSharingRequestTracker } from '../../../firebaseServices/dataServices/dataServiceActions/collaborationActions';
import { sharingRequestDeclineRequest, sharingRequestDeclineStatusChange } from '../../slices/collaboration/sharingRequestDeclineStatusSlice';
import { sharingRequestDeclineFailureChange } from '../../slices/collaboration/sharingRequestDeclineFailureSlice';

/**
 * @function declineSharingRequestSaga A saga workflow pertaining to a user having requested to decline a Sharing Request.
 */
export function* declineSharingRequestSaga() {
  // console.info('(declineSharingRequestSaga) entered declineSharingRequestSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the sharingRequestDeclineRequest action to be dispatched
      const sharingRequestDeclineRequestData = yield take(sharingRequestDeclineRequest);
      const { payload: objectSharingRequestTracker } = sharingRequestDeclineRequestData;

      // set the status of the sharingRequest to "Declined"
      objectSharingRequestTracker.status = enumSharingRequestStatus.Declined;

      // make a request to save the objectSharingRequestTracker
      yield saveObjectSharingRequestTracker(objectSharingRequestTracker)

      // dispatch an action to indicate that the save operation was successful
      yield put(sharingRequestDeclineStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(sharingRequestDeclineStatusChange(enumWorkflowState.Failure));
      yield put(sharingRequestDeclineFailureChange(error));
    }
  }
}
