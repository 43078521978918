import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface IPersonalAndTeamUseParms {
  className: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  boundingRect: {
    fill: 'none',
  },

  filledObject: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

  tennisRacketThroatLargeRacket: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '0.75px',
  },

  handleLargeRacket: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '2.46px',
  },

  buttCapLargeRacket: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '2.38px',
    fillRule: 'evenodd',
  },

  tennisRacketThroatSmallRacket: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '0.51px',
  },

  handleSmallRacket: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '1.66px',
  },

  buttCapSmallRacket: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '1.61px',
    fillRule: 'evenodd',
  },

}));


// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const PersonalAndTeamUse = React.forwardRef((props: IPersonalAndTeamUseParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536.5 364.22" >
      <defs>
      </defs>

      <g id="groupIndividualPerson">
        <g>
          <g>
            <rect className={classes.filledObject} x="0.5" y="104.72" width="143" height="143" rx="14.22" />
            <path className={classes.filledObject} d="M328.28,217A13.74,13.74,0,0,1,342,230.72V345.28A13.74,13.74,0,0,1,328.28,359H213.72A13.74,13.74,0,0,1,200,345.28V230.72A13.74,13.74,0,0,1,213.72,217H328.28m0-1H213.72A14.72,14.72,0,0,0,199,230.72V345.28A14.72,14.72,0,0,0,213.72,360H328.28A14.72,14.72,0,0,0,343,345.28V230.72A14.72,14.72,0,0,0,328.28,216Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <rect className={classes.filledObject} x="24.5" y="220.72" width="95" height="143" rx="14.22" />
            <path className={classes.filledObject} d="M304.28,333A13.74,13.74,0,0,1,318,346.72V461.28A13.74,13.74,0,0,1,304.28,475H237.72A13.74,13.74,0,0,1,224,461.28V346.72A13.74,13.74,0,0,1,237.72,333h66.56m0-1H237.72A14.72,14.72,0,0,0,223,346.72V461.28A14.72,14.72,0,0,0,237.72,476h66.56A14.72,14.72,0,0,0,319,461.28V346.72A14.72,14.72,0,0,0,304.28,332Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <circle className={classes.filledObject} cx="73" cy="59.22" r="35.5" />
            <path className={classes.filledObject} d="M272,136a35,35,0,1,1-35,35,35,35,0,0,1,35-35m0-1a36,36,0,1,0,36,36,36,36,0,0,0-36-36Z" transform="translate(-199 -111.78)" />
          </g>
        </g>
        <g id="groupIndividualPersonTennisRacket">
          <g>
            <g>
              <g>
                <rect className={classes.filledObject} x="287.04" y="237.04" width="2.63" height="7.35" transform="translate(-263.29 258.3) rotate(-60)" />
                <path className={classes.filledObject} d="M286,238.26l5.72,3.3-.94,1.62-5.71-3.3.93-1.62m-.27-1L284,240.15l7,4,1.68-2.92-7-4.05Z" transform="translate(-199 -111.78)" />
              </g>
              <rect className={classes.tennisRacketThroatLargeRacket} x="294.11" y="204.99" width="6.75" height="39.82" transform="translate(-46.69 -230.39) rotate(30)" />
              <rect className={classes.tennisRacketThroatLargeRacket} x="285.13" y="237.94" width="8.1" height="2.7" rx="1.35" transform="translate(-40.61 -224.31) rotate(30)" />
            </g>
            <rect className={classes.tennisRacketThroatLargeRacket} x="305.75" y="202.62" width="6.08" height="5.4" transform="translate(-54.97 -238.67) rotate(30)" />
          </g>
          <g>
            <path className={classes.handleLargeRacket} d="M350.52,176.23" transform="translate(-199 -111.78)" />
            <path className={classes.filledObject} d="M349.89,114.15h0a19.72,19.72,0,0,1,10,2.61c5.82,3.36,9.57,9.6,10.55,17.56,1,8.21-1,17.22-5.72,25.37-7,12.08-18.58,19.89-29.56,19.89a19.82,19.82,0,0,1-10-2.61c-12.25-7.07-14.42-26.33-4.83-42.93,7-12.09,18.58-19.89,29.56-19.89m0-2.37c-11.45,0-24,7.88-31.61,21.08C308,150.61,310.58,171.28,324,179a22.17,22.17,0,0,0,11.21,2.93c11.44,0,24-7.88,31.61-21.07,10.25-17.76,7.7-38.42-5.7-46.16a22.17,22.17,0,0,0-11.21-2.93Z" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapLargeRacket} d="M348.79,177.12a100.3,100.3,0,0,0-37.22,27.15" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapLargeRacket} d="M313.21,156.58a101.5,101.5,0,0,1-1.59,34.22,99.86,99.86,0,0,1-3.31,11.59" transform="translate(-199 -111.78)" />
          </g>
        </g>
      </g>
      <g id="groupTeamPerson1">
        <g>
          <g>
            <rect className={classes.filledObject} x="219.98" y="119.75" width="96.52" height="96.52" rx="9.6" />
            <path className={classes.filledObject} d="M505.91,231.87a9.27,9.27,0,0,1,9.26,9.26v77.32a9.28,9.28,0,0,1-9.26,9.27H428.58a9.27,9.27,0,0,1-9.26-9.27V241.13a9.27,9.27,0,0,1,9.26-9.26h77.33m0-.68H428.58a9.94,9.94,0,0,0-9.94,9.94v77.32a9.94,9.94,0,0,0,9.94,9.94h77.33a9.94,9.94,0,0,0,9.93-9.94V241.13a9.94,9.94,0,0,0-9.93-9.94Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <rect className={classes.filledObject} x="236.18" y="198.05" width="64.12" height="96.52" rx="9.6" />
            <path className={classes.filledObject} d="M489.71,310.17a9.27,9.27,0,0,1,9.26,9.26v77.32a9.28,9.28,0,0,1-9.26,9.27H444.78a9.27,9.27,0,0,1-9.26-9.27V319.43a9.27,9.27,0,0,1,9.26-9.26h44.93m0-.68H444.78a9.94,9.94,0,0,0-9.94,9.94v77.32a9.94,9.94,0,0,0,9.94,9.94h44.93a9.94,9.94,0,0,0,9.93-9.94V319.43a9.94,9.94,0,0,0-9.93-9.94Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <path className={classes.filledObject} d="M467.92,224.78a24,24,0,1,1,24-24A24,24,0,0,1,467.92,224.78Z" transform="translate(-199 -111.78)" />
            <path className={classes.filledObject} d="M467.92,177.19a23.63,23.63,0,1,1-23.63,23.63,23.65,23.65,0,0,1,23.63-23.63m0-.67a24.3,24.3,0,1,0,24.3,24.3,24.3,24.3,0,0,0-24.3-24.3Z" transform="translate(-199 -111.78)" />
          </g>
        </g>
        <g id="groupTeamPerson1TennisRacket">
          <g>
            <g>
              <g>
                <rect className={classes.filledObject} x="478.07" y="245.4" width="1.77" height="4.96" transform="translate(-174.32 426.73) rotate(-59.97)" />
                <path className={classes.filledObject} d="M477.34,246.21l3.86,2.23-.63,1.1-3.86-2.23.63-1.1m-.18-.69-1.14,2,4.73,2.73,1.14-2-4.73-2.74Z" transform="translate(-199 -111.78)" />
              </g>
              <rect className={classes.tennisRacketThroatSmallRacket} x="482.84" y="223.76" width="4.56" height="26.88" transform="translate(-15.41 -322.56) rotate(30)" />
              <rect className={classes.tennisRacketThroatSmallRacket} x="476.78" y="246" width="5.47" height="1.82" rx="0.91" transform="matrix(0.87, 0.5, -0.5, 0.87, -11.3, -318.46)" />
            </g>
            <rect className={classes.tennisRacketThroatSmallRacket} x="490.7" y="222.16" width="4.1" height="3.65" transform="translate(-20.99 -328.15) rotate(30)" />
          </g>
          <g>
            <path className={classes.handleSmallRacket} d="M520.92,204.35" transform="translate(-199 -111.78)" />
            <path className={classes.filledObject} d="M520.49,162.44h0a13.39,13.39,0,0,1,6.78,1.76c3.93,2.27,6.46,6.48,7.12,11.86a28,28,0,0,1-3.86,17.12c-4.71,8.16-12.54,13.43-19.95,13.43a13.4,13.4,0,0,1-6.78-1.77c-8.27-4.77-9.73-17.77-3.26-29,4.71-8.16,12.54-13.43,19.95-13.43m0-1.59c-7.72,0-16.19,5.31-21.33,14.22-6.92,12-5.2,25.93,3.84,31.16a15,15,0,0,0,7.58,2c7.72,0,16.19-5.32,21.33-14.22,6.92-12,5.2-25.94-3.85-31.16a15,15,0,0,0-7.57-2Z" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapSmallRacket} d="M519.75,205a68.63,68.63,0,0,0-19.47,12.48,66.42,66.42,0,0,0-5.65,5.85" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapSmallRacket} d="M495.74,191.09a68.6,68.6,0,0,1-1.08,23.09,69.37,69.37,0,0,1-2.23,7.83" transform="translate(-199 -111.78)" />
          </g>
        </g>
      </g>
      <g id="groupTeamPerson2">
        <g>
          <g>
            <rect className={classes.filledObject} x="319.43" y="172.31" width="96.52" height="96.52" rx="9.6" />
            <path className={classes.filledObject} d="M605.35,284.43a9.27,9.27,0,0,1,9.26,9.26V371a9.27,9.27,0,0,1-9.26,9.27H528a9.28,9.28,0,0,1-9.27-9.27V293.69a9.27,9.27,0,0,1,9.27-9.26h77.32m0-.68H528a9.94,9.94,0,0,0-9.94,9.94V371A9.94,9.94,0,0,0,528,381h77.32a9.94,9.94,0,0,0,9.94-9.94V293.69a9.94,9.94,0,0,0-9.94-9.94Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <rect className={classes.filledObject} x="335.63" y="250.61" width="64.12" height="96.52" rx="9.6" />
            <path className={classes.filledObject} d="M589.15,362.73a9.27,9.27,0,0,1,9.26,9.26v77.32a9.27,9.27,0,0,1-9.26,9.27H544.23a9.28,9.28,0,0,1-9.27-9.27V372a9.27,9.27,0,0,1,9.27-9.26h44.92m0-.68H544.23a9.94,9.94,0,0,0-9.94,9.94v77.32a9.94,9.94,0,0,0,9.94,9.94h44.92a9.94,9.94,0,0,0,9.94-9.94V372a9.94,9.94,0,0,0-9.94-9.94Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <path className={classes.filledObject} d="M567.37,277.34a24,24,0,1,1,24-24A24,24,0,0,1,567.37,277.34Z" transform="translate(-199 -111.78)" />
            <path className={classes.filledObject} d="M567.36,229.75a23.63,23.63,0,1,1-23.62,23.63,23.66,23.66,0,0,1,23.62-23.63m0-.67a24.3,24.3,0,1,0,24.3,24.3,24.3,24.3,0,0,0-24.3-24.3Z" transform="translate(-199 -111.78)" />
          </g>
        </g>
        <g id="groupTeamPerson2TennisRacket">
          <g>
            <g>
              <g>
                <rect className={classes.filledObject} x="577.52" y="297.95" width="1.77" height="4.96" transform="translate(-169.98 539.35) rotate(-60)" />
                <path className={classes.filledObject} d="M576.79,298.77l3.86,2.23-.63,1.1-3.86-2.23.63-1.1m-.19-.69-1.13,2,4.73,2.74,1.14-2-4.74-2.74Z" transform="translate(-199 -111.78)" />
              </g>
              <rect className={classes.tennisRacketThroatSmallRacket} x="582.29" y="276.32" width="4.56" height="26.88" transform="translate(24.2 -365.25) rotate(30)" />
              <rect className={classes.tennisRacketThroatSmallRacket} x="576.23" y="298.56" width="5.47" height="1.82" rx="0.91" transform="translate(28.3 -361.14) rotate(30)" />
            </g>
            <rect className={classes.tennisRacketThroatSmallRacket} x="590.15" y="274.72" width="4.1" height="3.65" transform="translate(18.61 -370.83) rotate(30)" />
          </g>
          <g>
            <path className={classes.handleSmallRacket} d="M620.37,256.91" transform="translate(-199 -111.78)" />
            <path className={classes.filledObject} d="M619.94,215h0a13.33,13.33,0,0,1,6.77,1.76c3.93,2.27,6.46,6.48,7.13,11.86A27.89,27.89,0,0,1,630,245.74c-4.71,8.16-12.54,13.43-20,13.43a13.33,13.33,0,0,1-6.77-1.77c-8.27-4.77-9.73-17.77-3.26-29,4.7-8.16,12.54-13.43,20-13.43m0-1.6c-7.72,0-16.19,5.32-21.34,14.23-6.92,12-5.19,25.93,3.85,31.15a14.9,14.9,0,0,0,7.57,2c7.72,0,16.19-5.32,21.33-14.22,6.92-12,5.2-25.94-3.84-31.16a15,15,0,0,0-7.57-2Z" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapSmallRacket} d="M619.2,257.51a67.91,67.91,0,0,0-25.13,18.32" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapSmallRacket} d="M595.18,243.64a68.61,68.61,0,0,1-1.07,23.1,66.55,66.55,0,0,1-2.24,7.82" transform="translate(-199 -111.78)" />
          </g>
        </g>
      </g>
      <g id="groupTeamPerson3">
        <g>
          <g>
            <rect className={classes.filledObject} x="419.33" y="120.11" width="96.52" height="96.52" rx="9.6" />
            <path className={classes.filledObject} d="M705.25,232.23a9.27,9.27,0,0,1,9.26,9.26v77.32a9.27,9.27,0,0,1-9.26,9.27H627.93a9.28,9.28,0,0,1-9.27-9.27V241.49a9.27,9.27,0,0,1,9.27-9.26h77.32m0-.68H627.93a9.94,9.94,0,0,0-9.94,9.94v77.32a9.94,9.94,0,0,0,9.94,9.94h77.32a9.94,9.94,0,0,0,9.94-9.94V241.49a9.94,9.94,0,0,0-9.94-9.94Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <rect className={classes.filledObject} x="435.53" y="198.41" width="64.13" height="96.52" rx="9.6" />
            <path className={classes.filledObject} d="M689.05,310.53a9.27,9.27,0,0,1,9.26,9.26v77.32a9.27,9.27,0,0,1-9.26,9.27H644.13a9.28,9.28,0,0,1-9.27-9.27V319.79a9.27,9.27,0,0,1,9.27-9.26h44.92m0-.68H644.13a9.94,9.94,0,0,0-9.94,9.94v77.32a9.94,9.94,0,0,0,9.94,9.94h44.92a9.94,9.94,0,0,0,9.94-9.94V319.79a9.94,9.94,0,0,0-9.94-9.94Z" transform="translate(-199 -111.78)" />
          </g>
          <g>
            <circle className={classes.filledObject} cx="468.26" cy="89.39" r="23.96" />
            <path className={classes.filledObject} d="M667.26,177.55a23.63,23.63,0,1,1-23.62,23.63,23.66,23.66,0,0,1,23.62-23.63m0-.67a24.3,24.3,0,1,0,24.3,24.3,24.3,24.3,0,0,0-24.3-24.3Z" transform="translate(-199 -111.78)" />
          </g>
        </g>
        <g id="groupTeamPerson3TennisRacket">
          <g>
            <g>
              <g>
                <rect className={classes.filledObject} x="677.42" y="245.75" width="1.77" height="4.96" transform="translate(-74.76 599.84) rotate(-60.01)" />
                <path className={classes.filledObject} d="M676.69,246.57l3.86,2.23-.63,1.1-3.86-2.23.63-1.1m-.19-.69-1.13,2,4.73,2.74,1.14-2-4.74-2.74Z" transform="translate(-199 -111.78)" />
              </g>
              <rect className={classes.tennisRacketThroatSmallRacket} x="682.19" y="224.12" width="4.56" height="26.88" transform="translate(11.48 -422.19) rotate(30)" />
              <rect className={classes.tennisRacketThroatSmallRacket} x="676.13" y="246.36" width="5.47" height="1.82" rx="0.91" transform="translate(15.59 -418.09) rotate(30)" />
            </g>
            <rect className={classes.tennisRacketThroatSmallRacket} x="690.05" y="222.52" width="4.1" height="3.65" transform="translate(5.89 -427.78) rotate(30)" />
          </g>
          <g>
            <path className={classes.handleSmallRacket} d="M720.27,204.71" transform="translate(-199 -111.78)" />
            <path className={classes.filledObject} d="M719.84,162.8h0a13.33,13.33,0,0,1,6.77,1.76c3.93,2.27,6.46,6.48,7.13,11.86a27.89,27.89,0,0,1-3.87,17.12c-4.71,8.16-12.54,13.43-20,13.43a13.33,13.33,0,0,1-6.77-1.77c-3.93-2.27-6.46-6.48-7.13-11.85a27.86,27.86,0,0,1,3.87-17.12c4.7-8.16,12.54-13.43,20-13.43m0-1.6c-7.72,0-16.19,5.32-21.34,14.23-6.92,12-5.19,25.93,3.85,31.15a14.9,14.9,0,0,0,7.57,2c7.72,0,16.19-5.32,21.33-14.22,6.92-12,5.2-25.94-3.84-31.16a15,15,0,0,0-7.57-2Z" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapSmallRacket} d="M719.1,205.31A67.91,67.91,0,0,0,694,223.63" transform="translate(-199 -111.78)" />
            <path className={classes.buttCapSmallRacket} d="M695.08,191.44a68.61,68.61,0,0,1-1.07,23.1,66.55,66.55,0,0,1-2.24,7.82" transform="translate(-199 -111.78)" />
          </g>
        </g>
      </g>

    </svg >
  )
});

export default PersonalAndTeamUse;
