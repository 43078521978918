import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { enumAlertType } from '../../../enums';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import { deleteCategoryRequested, deleteCategoryStatusReset, setAlertInfo, setBeacon } from '../../../../uiMiddleware-redux/actions';
import CategoriesListView from '../../../views/categories/CategoriesListView/CategoriesListView';
import { CategoriesCardGridView } from '../../../views/categories/CategoriesCardGridView/CategoriesCardGridView';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import DataViewControls from '../../../controls/DataViewControls/DataViewControls';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import { styled } from '@mui/system';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../../enums';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { categoryDeleteRequest, categoryDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/category/categoryDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for a Horizontal Divider (a CSS Horizontal Rule <hr/>)
const StyledBoxForHorizontalDivider = styled((props) => (
  <Box
    component='hr'
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderTop: `1px solid ${theme.palette.primary.light}`,
}));

// a styled Container for Data View
const StyledContainerForDataView = styled((props) => (
  <Container
    disableGutters
    maxWidth={false}
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));


export interface ICategoriesPageProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId,
  parentType?: typeUniqueId,
}

const CategoriesPage: React.FC<ICategoriesPageProps> = (props: ICategoriesPageProps) => {

  CategoriesPage.displayName = 'Categories Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const params = useParams();

  const parentId = params.parentId; // from the path '/:parentId/:parentType/categories
  const parentType = params.parentType; // from the path '/:parentId/:parentType/categories

  displayConsoleLogs && console.log(`CategoriesPage. parentId: ${parentId}; parentType: ${parentType}`);

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();

  // the Categories associcated with the current user account
  const [categories, setCategories] = useState<Array<ICategory>>([]);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  // if user is deleting a Category, the Category to delete
  const [categoryToDelete, setCategoryToDelete] = useState<ICategory | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();
  // for using browser router navigate
  const navigate = useNavigate();

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c CategoriesPage. currentUser: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');
  const userId: typeUniqueId | undefined = currentUserContextData.currentUser?.id;

  /**
   * @function resetDeleteCategoryStatus Resets the Delete status in Redux state
   */
  const resetDeleteCategoryStatus: () => void = useCallback(() => {
    dispatch(categoryDeleteStatusChange(null));
  }, [dispatch])

  
  // a useEffect hook to respond to changes in the status of the Categories and Channels data
  useEffect(() => {
    // when the data preparation is complete, set all data variables
    if (userCategoriesAndChannelsContext.state.status === enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
      setCategories(userCategoriesAndChannelsContext.state.categories ? userCategoriesAndChannelsContext.state.categories : []);

      // indicate that we are no longer loading data
      if (dataLoading) {
        setDataLoading(false);
      }
    } else {
      // if the data has changed and the data preparation is not complete, indicate that we're loading data
      if (!dataLoading) {
        setDataLoading(true);
      }
    }
  }, [userCategoriesAndChannelsContext, dataLoading]);

  // use a custom hook to evaluate the redux state for the deleteCategory workflow and any deleteCategory error
  const { workflowState: deleteCategoryStatus, errorState: deleteCategoryFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteCategoryStatus, (state: IStoreState) => state.deleteCategoryFailure);

  /**
   * @method handleCategoryDelete Handles requests to delete a Category
   * @param category The Category to be deleted
   */
  async function handleCategoryDelete(category: ICategory): Promise<void> {
    // set the category to be deleted into local state
    setCategoryToDelete(category);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleCategoryDeleteConfirmed(): void {
    // using the category object to be deleted from the local state, dispatch an action to delete the category object
    if (categoryToDelete !== undefined) {
      dispatch(categoryDeleteRequest(categoryToDelete));
    }

    // call method to reset the category delete local state details
    categoryDeleteReset();
  }

  function handleCategoryDeleteCanceled(): void {
    // call method to reset the category delete local state details
    categoryDeleteReset();
  }

  function categoryDeleteReset() {
    // reset the category to be deleted to 'undefined' in local state, since we're done with the object
    setCategoryToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyDeletedObject state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteCategoryStatus
      resetDeleteCategoryStatus();

      // notify user via a Beacon notification that the category has been deleted
      const beacon: Beacon = new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.category_DeleteConfirmationHeader, MessagesStringAssets.category_DeleteSuccess);
      dispatch(beaconChange(beacon));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteCategoryStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.category_Loading;
  } else {
    // if the workflow is in the midst of performing deletion of a category, prepare an alert message fragment to display progress message(s)
    if (deleteCategoryStatus !== undefined && deleteCategoryStatus !== null) {
      if (deleteCategoryStatus === enumWorkflowState.Requested || deleteCategoryStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.category_DeleteRequested;
      } else if (deleteCategoryStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteCategoryStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.category_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteCategoryFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteCategoryFailure && deleteCategoryFailure instanceof Error) {
          failureMessage = deleteCategoryFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }

  // Establish the page title to be displayed. Once we have retrieved the parent Category, apply the categoryNameAliasPlural; otherwise, show nothing
  const pageTitle: string = PageAndViewTitleStringAssets.pageTitle_Categories;

  // Establish the text to be displayed on the '+ New' button
  const addNewButtonText: string = ControlsStringAssets.categoryNew;

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="xl"
        showBackButton={true}
        pageTitle={pageTitle}
        onCloseAlert={resetDeleteCategoryStatus}
      >

        {/* Present the Data View Controls ("Add new" button & "View Type" toggle button) */}
        <DataViewControls
          addNewItemButtonText={addNewButtonText}
          onClickAddNewItemButton={() => navigate(`/${parentId}/category`)}
        >
        </DataViewControls>

        <StyledBoxForHorizontalDivider/>

        {/* Container for embedded data view */}
        <StyledContainerForDataView>

          {/* If there are Categories to display and the current view selection is CardsGridView, display the CategoriesCardGridView */}
          {categories &&
            (collectionViewType === enumCollectionViewType.CardsGridView) &&
            <CategoriesCardGridView categories={categories} onDelete={handleCategoryDelete} />
          }

          {/* If there are Categories to display and the current view selection is ListView, display the CategoriesListView */}
          {categories &&
            (collectionViewType === enumCollectionViewType.ListView) &&
            <CategoriesListView categories={categories} onDelete={handleCategoryDelete} />
          }
        </StyledContainerForDataView>

        {/* Delete Category Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.category_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.category_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleCategoryDeleteConfirmed}
          onNonAcceptance={handleCategoryDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericPageContainer>
    </>
  );
}

export default CategoriesPage;
