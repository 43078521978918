import { take, put } from 'redux-saga/effects';
import { deleteAudioLink } from '../../../firebaseServices/dataServices/dataServiceActions/audioLinkActions';
import { audioLinkDeleteRequest, audioLinkDeleteStatusChange } from '../../slices/audioLink/audioLinkDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { audioLinkDeleteFailureChange } from '../../slices/audioLink/audioLinkDeleteFailureSlice';

/**
 * @function deleteAudioLinkRequestedSaga A saga workflow pertaining to a user having requested to delete a audioLink.
 */
export function* deleteAudioLinkRequestedSaga() {
  // console.info('(deleteAudioLinkRequestedSaga) entered deleteAudioLinkRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the actionTypeEnums.DELETE_AUDIOLINK_REQUESTED action to be dispatched
      const audioLinkDeleteRequestData = yield take(audioLinkDeleteRequest);
      const { payload: audioLink } = audioLinkDeleteRequestData;

      // make a request to delete the audioLink
      yield deleteAudioLink(audioLink)

      // dispatch an action to indicate that the delete operation was successful
      yield put(audioLinkDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(audioLinkDeleteStatusChange(enumWorkflowState.Failure));
      yield put(audioLinkDeleteFailureChange(error));
    }
  }
}
