/**
 * @enum {enumSharingRequestStatus} Enumerated values for the status of a sharing request.
 * Example use case: Sharing a Channel (for collaboration between users)
 */
export enum enumSharingRequestStatus {
  New = 'New', // a request was initiated
  Requested = 'Requested', // a request was made to share
  Accepted = 'Accepted',   // the share request has been accepted by the recipient
  Declined = 'Declined',   // the share request has been declined by the recipient
  Revoked = 'Revoked'      // the share request has been revoked by someone with suffient privileges
}
