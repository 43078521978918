import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { enumAlertType } from '../../../enums';
import { IVideoLink } from '../../../../dataObjects/models/digitalMedia/VideoLink';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import { deleteVideoLinkRequested, deleteVideoLinkStatusReset, setAlertInfo, setBeacon } from '../../../../uiMiddleware-redux/actions';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import GenericToggleViewContainer from '../../GenericToggleViewContainer/GenericToggleViewContainer';
import { VideoLinksCardGridView } from '../VideoLinksCardGridView/VideoLinksCardGridView';
import VideoLinksListView from '../VideoLinksListView/VideoLinksListView';
import { styled } from '@mui/system';
import { IVideoLinkViewModel } from '../../../../dataObjects/viewModels/videoLinkViewModel';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { videoLinkDeleteRequest, videoLinkDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/videoLink/videoLinkDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area padding at the top of the view
const StyledBoxForPaddingAtopView = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// a styled Container for the View Container
const StyledContainerForViewContainer = styled((props) => (
  <Container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));

export interface IVideoLinksViewProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId // the parent DigitalMedia for the VideoLinks
  videoLinksToDisplay: Array<IVideoLinkViewModel>;
  allowAddingNewItems: boolean;
  videoLinksDataLoading: boolean;
}

export const VideoLinksView: React.FC<IVideoLinksViewProps> = (props: IVideoLinksViewProps) => {

  VideoLinksView.displayName = 'Video Links View';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  // const displayConsoleLogs: boolean = false;

  const { parentId, videoLinksToDisplay, allowAddingNewItems, videoLinksDataLoading } = props;

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the VideoLinks associcated with the current Digital Media
  // const [videoLinks, setVideoLinks] = useState<Array<IVideoLink>>(videoLinksToDisplay);
  const [videoLinkViewModels, setVideoLinkViewModels] = useState<Array<IVideoLinkViewModel>>(videoLinksToDisplay);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(videoLinksDataLoading);
  // if user is deleting a VideoLink, the VideoLink to delete
  const [videoLinkToDelete, setVideoLinkToDelete] = useState<IVideoLink | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  /**
   * @function resetDeleteVideoLinkStatus Resets the Delete status in Redux state
   */
  const resetDeleteVideoLinkStatus: () => void = useCallback(() => {
    dispatch(videoLinkDeleteStatusChange(null));
  }, [dispatch])

  // use a custom hook to evaluate the redux state for the deleteVideoLink workflow and any deleteVideoLink error
  const { workflowState: deleteVideoLinkStatus, errorState: deleteVideoLinkFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteVideoLinkStatus, (state: IStoreState) => state.deleteVideoLinkFailure);

  useEffect(() => {
    if (videoLinkViewModels !== videoLinksToDisplay) {
      setVideoLinkViewModels(videoLinksToDisplay);
    }
  }, [videoLinkViewModels, videoLinksToDisplay]);

  useEffect(() => {
    if (dataLoading !== videoLinksDataLoading) {
      setDataLoading(videoLinksDataLoading);
    }
  }, [dataLoading, videoLinksDataLoading]);

  /**
   * @method handleVideoLinkDelete Handles requests to delete a Channel
   * @param videoLink The VideoLink to be deleted
   */
  async function handleVideoLinkDelete(videoLink: IVideoLink): Promise<void> {
    // set the videoLink to be deleted into local state
    setVideoLinkToDelete(videoLink);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleVideoLinkDeleteConfirmed(): void {
    // using the videoLink object to be deleted from the local state, dispatch an action to delete the videoLink object
    if (videoLinkToDelete !== undefined) {
      dispatch(videoLinkDeleteRequest(videoLinkToDelete));
    }

    // call method to reset the videoLink delete local state details
    videoLinkDeleteReset();
  }

  function handleVideoLinkDeleteCanceled(): void {
    // call method to reset the videoLink delete local state details
    videoLinkDeleteReset();
  }

  function videoLinkDeleteReset() {
    // reset the videoLink to be deleted to 'undefined' in local state, since we're done with the object
    setVideoLinkToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteTopicStatus
      resetDeleteVideoLinkStatus();

      // notify user via a Beacon notification that the videoLink has been deleted
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.videoLink_DeleteConfirmationHeader, MessagesStringAssets.videoLink_DeleteSuccess)));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteVideoLinkStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.videoLinks_FetchingRequested;
  } else {
    // if the workflow is in the midst of performing deletion of a videoLink, prepare an alert message fragment to display progress message(s)
    if (deleteVideoLinkStatus !== undefined && deleteVideoLinkStatus !== null) {
      if (deleteVideoLinkStatus === enumWorkflowState.Requested || deleteVideoLinkStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.videoLink_DeleteRequested;
      } else if (deleteVideoLinkStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteVideoLinkStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.videoLink_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteVideoLinkFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteVideoLinkFailure && deleteVideoLinkFailure instanceof Error) {
          failureMessage = deleteVideoLinkFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }

  // Establish the text to be displayed on the '+ New' button
  let addNewButtonText: string = '';
  if (allowAddingNewItems) {
    addNewButtonText = ControlsStringAssets.videoNew;
  }


  return (
    <>
      <StyledBoxForPaddingAtopView />
      <GenericToggleViewContainer
        viewTitle={PageAndViewTitleStringAssets.viewTitle_Videos}
        addNewButtonText={addNewButtonText}
        urlForAddNewButtonNavigation={`/${parentId}/videoLink`}
      >

        {/* Container for embedded data view */}
        <StyledContainerForViewContainer>

          {/* If there are VideoLinks to display and the current view selection is CardsGridView, display the VideoLinksCardGridView */}
          {videoLinkViewModels &&
            (collectionViewType === enumCollectionViewType.CardsGridView) &&
            <VideoLinksCardGridView videoLinkViewModels={videoLinkViewModels} onDelete={handleVideoLinkDelete} />
          }

          {/* If there are VideoLinks to display and the current view selection is ListView, display the VideoLinksListView */}
          {videoLinkViewModels &&
            (collectionViewType === enumCollectionViewType.ListView) &&
            <VideoLinksListView videoLinkViewModels={videoLinkViewModels} onDelete={handleVideoLinkDelete} />
          }
        </StyledContainerForViewContainer>

        {/* Delete VideoLink Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.videoLink_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.videoLink_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleVideoLinkDeleteConfirmed}
          onNonAcceptance={handleVideoLinkDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericToggleViewContainer>
    </>
  );
}

export default VideoLinksView;
