import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { useNavigate, useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IChannel, Channel, IChannelAsJson, IChannelAndParentCategoryId } from '../../../../dataObjects/models/channels/Channel';
import { IUser, IUserAsJson, User } from '../../../../dataObjects/models/users/User';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumPersistableObjectType, enumSharingPermission } from '../../../../dataObjects/enums';
import { PageAndViewTitleStringAssets, MessagesStringAssets } from '../../../../assets/stringAssets';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import { AlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../../enums';
import ChannelSharingUserForm from '../../../forms/ChannelSharingUserForm/ChannelSharingUserForm';
import { ChannelSharingUserEditViewModel, IChannelSharingUserEditViewModel } from '../../../../dataObjects/viewModels/channelSharing/ChannelSharingUserEditViewModel';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { channelSaveRequest, channelSaveStatusChange } from '../../../../uiMiddleware-redux/slices/channel/channelSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/**
 * @interface IManageChannelSharingUserPageProps Input properties for the ManageChannelSharingUserPage
 */
export interface IManageChannelSharingUserPageProps extends PropsWithChildren<unknown> {
  channelId?: typeUniqueId; // the Id of the Channel for which a user's sharingPermission is to be edited
  userId?: typeUniqueId  // the Id of the User (associated with the Channel) for which the user's sharingPermission is to be edited
}

const ManageChannelSharingUserPage: React.FC<IManageChannelSharingUserPageProps> = (props: IManageChannelSharingUserPageProps) => {

  ManageChannelSharingUserPage.displayName = "Manage Channel Sharing User Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  // for using browser router navigate
  const navigate = useNavigate();

  const params = useParams();

  displayConsoleLogs && console.log(`ManageChannelSharingUserPage. Entering component. channelId: ${params.channelId}; params.userId: ${params.userId}`);


  // will be set to the Channel object associated with the channelId provided in the URL parms. The Channel object will be retrieved
  // during the mounting of this component in a useEffect().
  const [channel, setChannel] = useState<IChannel | undefined>(undefined);

  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  // define an effect based on a change to the params.channelId value. 
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingUserPage. Entered useEffect() for [params.channelId]. channelId value: ${params.channelId}`);

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() Channel request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackForChannelData: () => void = () => { };

    // if an Id was provided, we will be editing an existing channel; therefore, if we haven't already fetched the channel...
    if (params.channelId !== undefined) {
      displayConsoleLogs && console.log(`ChannelSharingPage. In useEffect() for [params.channelId] (${params.channelId}). Ready to call getSingleObjectById_onSnapshot().`);

      // subscribe to onShapshot updates for a Channel that has the given channelId, providing realtime updates to the data, and 
      // capture the 'unsubscribe' callback method provided by firestore
      getSingleObjectById_onSnapshot(params.channelId, enumPersistableObjectType.Channel, onChannelSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallbackForChannelData = unsubscribe;
      });

    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the Channel onSnapshot. 
      displayConsoleLogs && console.info(`ManageChannelSharingUserPage. Cleanup before unmounting. Ready to call unsubscribeCallbackForChannelData().`)
      unsubscribeCallbackForChannelData();
      displayConsoleLogs && console.info(`ManageChannelSharingUserPage. Cleanup before unmounting. After call to unsubscribeCallbackForChannelData().`)
    }

  }, [params.channelId]);


  /**
   * @function onChannelSnapshotCallback A callback method to receive firestore Channel data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onChannelSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);

    // fetch the data from the document, which should be a JSON version of the correct type of object
    const channelAsJson: IChannelAsJson = snapshot.docs[0].data() as IChannelAsJson;

    // convert the JSON object to a Typescript object
    const channelData: IChannel = JsonConverter.fromJSON(Channel, channelAsJson, true);

    setChannel(channelData);
  }

  // will be set to the Channel object associated with the userId provided in the URL parms. The User object will be retrieved
  // during the mounting of this component in a useEffect().
  const [user, setUser] = useState<IUser | undefined>(undefined);

  // define an effect based on a change to the params.userId value. 
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingUserPage. Entered useEffect() for [params.userId]. params.userId value: ${params.userId}`);

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() User request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackForUserData: () => void = () => { };

    if (params.userId !== undefined) {
      displayConsoleLogs && console.log(`ChannelSharingPage. In useEffect() for [params.userId] (${params.userId}). Ready to call getSingleObjectById_onSnapshot().`);

      // subscribe to onShapshot updates for a User that has the given userId, providing realtime updates to the data, and 
      // capture the 'unsubscribe' callback method provided by firestore
      getSingleObjectById_onSnapshot(params.userId, enumPersistableObjectType.User, onUserSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallbackForUserData = unsubscribe;
      });

    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the User onSnapshot. 
      displayConsoleLogs && console.info(`ManageChannelSharingUserPage. Cleanup before unmounting. Ready to call unsubscribeCallbackForUserData().`)
      unsubscribeCallbackForUserData();
      displayConsoleLogs && console.info(`ManageChannelSharingUserPage. Cleanup before unmounting. After call to unsubscribeCallbackForUserData().`)
    }

  }, [params.channelId, params.userId]);


  /**
   * @function onChannelSnapshotCallback A callback method to receive firestore User data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onUserSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // set state variable indicating that dataLoading is no longer taking place
    setDataLoading(false);

    // fetch the data from the document, which should be a JSON version of the correct type of object
    const userAsJson: IUserAsJson = snapshot.docs[0].data() as IUserAsJson;

    // convert the JSON object to a Typescript object
    const userData: IUser = JsonConverter.fromJSON(User, userAsJson, true);

    setUser(userData);
  }

  // will hold the View Model data to be passed to the embedded form
  const [channelSharingUserEditViewModel, setChannelSharingUserEditViewModel] = useState<IChannelSharingUserEditViewModel | undefined>(undefined);

  // define an effect based on changes to the channel and user values.
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingUserPage. Entered useEffect() for [channel, user].`);


    // if both the Channel and User have been defined (data has been fetched for both)...
    if (channel && user) {
      const userSharingPermission: enumSharingPermission = channel.getUserSharingPermission(user.id);

      const userName: string = `${user.userSettings.firstName} ${user.userSettings.lastName}`;

      // set up the View Model object to be passed to the embedded form
      const channelSharingUserEditVM = new ChannelSharingUserEditViewModel(user.userSettings.email, userName, userSharingPermission);
      setChannelSharingUserEditViewModel(channelSharingUserEditVM);
    } else if (channelSharingUserEditViewModel !== undefined) {
      setChannelSharingUserEditViewModel(undefined);
    }
  }, [channel, user, setChannelSharingUserEditViewModel, channelSharingUserEditViewModel]);

  const resetSaveChannelStatus: () => void = useCallback(() => {
    dispatch(channelSaveStatusChange(null))
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveChannelStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveChannelStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveChannelStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // define an effect that will set the currentUser anytime the currentUser changes
  useEffect(() => {
    if (currentUser) {
      // A user will not be allowed to set his/her own sharingPermission for a Channel. Why?
      //  1) A user is always an Admin for his/her own Channels, and there is never a need to downgrade that permission; and
      //  2) A user who is sharing someone else's channel must have his/her sharingPermission modified by an Admin of the Channel.
      // Therefore, if the userId passed in via URL parameter matches the currentUser's Id, set an alert and navigate to previous page.
      if (currentUser.id === params.userId) {
        dispatch(alertInfoChange(new AlertInfo(true, enumAlertType.Error, MessagesStringAssets.channelSharingUser_UpdateUserViolation)));
        navigate(-1); // navigate to previous page
      }
    }
  }, [currentUser, dispatch, navigate, params.userId]);

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveChannelStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveChannelFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.channelSharingUser_UpdateFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveChannelSharingUser,
    notificationSuccessMessage: MessagesStringAssets.channelSharingUser_UpdateSuccess,
  }


  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);


  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(sharingPermission: enumSharingPermission): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`ManageChannelSharingUserPage. Entered handleFormSubmit().`);

        // update the sharingPermission for the user within the channel
        if (channel && user) {
          const sharingPermissionHasBeenChanged: boolean = channel.setUserSharingPermission(user.id, sharingPermission);

          // if the sharingPermission has been changed...
          if (sharingPermissionHasBeenChanged) {
            // dispatch an action to save (update) the Channel 
            const channelAndParentCategoryId: IChannelAndParentCategoryId = { channel: channel, parentCategoryId: '' };
            dispatch(channelSaveRequest(channelAndParentCategoryId));
              }
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={PageAndViewTitleStringAssets.pageTitle_ManageChannelSharingUser_Existing}
      onCloseAlert={resetSaveChannelStatus}
    >
      {channel && user && channelSharingUserEditViewModel &&
        <ChannelSharingUserForm
          channelSharingUser={channelSharingUserEditViewModel}
          saveRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  )

}

export default ManageChannelSharingUserPage;