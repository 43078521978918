
/**
 * @class FirebaseEmulatorSettings Provides settings for Firebase emulators 
 */
export class FirebaseEmulatorSettings {
  /*-----------------------------------------------*/
  /**
   * @property {boolean} useEmulators Whether to use Firebase Emulators
   */
  static useEmulators: boolean = (process.env.REACT_APP_GOOGLE_FIREBASE_USE_EMULATORS ? (process.env.REACT_APP_GOOGLE_FIREBASE_USE_EMULATORS.toLowerCase() === 'true') : false);
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} authEmulatorPort Emulator Port for Firebase Authentication
   */
  static authEmulatorPort: string | undefined = (process.env.REACT_APP_GOOGLE_FIREBASE_EMULATOR_PORT_AUTHENTICATION ?? undefined);
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} cloudStorageEmulatorPort Emulator Port for Firebase Cloud Storage
   */
  static cloudStorageEmulatorPort: string | undefined = (process.env.REACT_APP_GOOGLE_FIREBASE_EMULATOR_PORT_CLOUD_STORAGE ?? undefined);
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} firestoreEmulatorPort Emulator Port for Firebase Firestore Database
   */
  static firestoreEmulatorPort: string | undefined = (process.env.REACT_APP_GOOGLE_FIREBASE_EMULATOR_PORT_FIRESTORE ?? undefined);
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} functionsEmulatorPort Emulator Port for Firebase Functions
   */
  static functionsEmulatorPort: string | undefined = (process.env.REACT_APP_GOOGLE_FIREBASE_EMULATOR_PORT_FUNCTIONS ?? undefined);
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} hostingEmulatorPort Emulator Port for Firebase Hosting
   */
  static hostingEmulatorPort: string | undefined = (process.env.REACT_APP_GOOGLE_FIREBASE_EMULATOR_PORT_HOSTING ?? undefined);
  /*-----------------------------------------------*/

}
