import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const listViewStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  // root: {
  //   flexGrow: 1,
  //   paddingLeft: '0',
  //   paddingRight: '0',
  // },
  // tableHeader: {
  //   background: theme.palette.listViewTable.header.background,
  //   color: theme.palette.listViewTable.header.textColor,
  //   padding: theme.spacing(1, 1),
  // },
}));