import { take, put } from 'redux-saga/effects';
import { saveThemeSpecs } from '../../../firebaseServices/dataServices/dataServiceActions/themeSpecsActions';
import { themeSpecsSaveRequest, themeSpecsSaveStatusChange } from '../../slices/themeSpecs/themeSpecsSaveStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { themeSpecsSaveFailureChange } from '../../slices/themeSpecs/themeSpecsSaveFailureSlice';

/**
 * @function saveThemeSpecsRequestedSaga A saga workflow pertaining to a user having requested to save a themeSpecs.
 */
export function* saveThemeSpecsRequestedSaga() {
  // console.info('(saveThemeSpecsRequestedSaga) entered saveThemeSpecsRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the themeSpecsSaveRequest action to be dispatched
      const themeSpecsSaveRequestData = yield take(themeSpecsSaveRequest);
      const { payload: themeSpecs } = themeSpecsSaveRequestData;

      // make a request to save the themeSpecs
      yield saveThemeSpecs(themeSpecs)

      // dispatch an action to indicate that the save operation was successful
      yield put(themeSpecsSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(themeSpecsSaveStatusChange(enumWorkflowState.Failure));
      yield put(themeSpecsSaveFailureChange(error));
    }
  }
}
