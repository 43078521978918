import { FirebaseApp } from 'firebase/app';
import {
  Auth,
  getAuth,
  createUserWithEmailAndPassword,
  UserCredential,
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  ConfirmationResult,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  PhoneAuthCredential,
  User,
  linkWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  updateEmail,
  AuthCredential,
  updatePhoneNumber,
  updateProfile,
  signOut,
  connectAuthEmulator
} from "firebase/auth";
import { BaseFirebase } from '../../BaseFirebase';
import { IFirebaseAuthentication } from '.';
import { FirebaseEmulatorSettings } from '../../FirebaseConfigurationVariables';

// Provides an interface that addresses all operation related to Firebase Authentication, from
// registering a user account, verifying an account logging in/out, deleting an account, and 
// a few other operations

/** 
 * @class FirebaseAuthentication Provides capabilities that address all operations related to Firebase Authentication, from
 * registering a user account, verifying an account logging in/out, deleting an account, and several other operations
 */
export class FirebaseAuthentication extends BaseFirebase implements IFirebaseAuthentication {
  /**
   * @method Constructor method
   * @param {FirebaseApp} firebaseApp A Firebase Application object that has been initialized, if provided
   */
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    firebaseApp?: FirebaseApp
  ) {
    super(firebaseApp);
  }

  /*-----------------------------------------------*/
  /**
   * @method registerUserAccount Registers a user account as an email account.
   * @return {Promise<UserCredential>} Returns a promise to enable asynchronous behavior.
   */
  registerUserAccount(email: string, password: string): Promise<UserCredential> {
    return new Promise<UserCredential>(async (resolve, reject) => {
      try {
        const auth: Auth = await getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        const userRegistration: UserCredential = await createUserWithEmailAndPassword(auth, email, password);

        // TODO: log that user was registered

        // TODO: send "Verification" email to user
        // this.verifyUserAccount();

        // TODO: send "Welcome" email to user with some helpful tips (perhaps this should follow receipt of verification response)


        resolve(userRegistration);
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method loginWithEmailAndPassword Logs into a user account using Email and Password.
   * Asynchronously logs in (signs in) to the user account.
   * Fails with an error exception if the email address and password do not match a Firebase account for the specified Firebase project.
   * Note: The user's password is NOT the password used to access the user's email account. The email address serves as a unique 
   * identifier for the user, and the password is used to access the user's account in your Firebase project.
   * @return {Promise<UserCredential>} Returns a promise with the logged in user credentials.
   */
  loginWithEmailAndPassword(email: string, password: string): Promise<UserCredential> {
    return new Promise<UserCredential>(async (resolve, reject) => {
      try {
        const auth: Auth = await getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        const userCredential: UserCredential = await signInWithEmailAndPassword(auth, email, password);
        // TODO: log that user logged in
        resolve(userCredential);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method loginWithPhoneNumber_Step1_SendCode As the first of two steps required for logging in with a Phone Number, this method triggers 
   * the sending of a confirmation code to a phone number.
   * @param {string} phoneNumber A phone number that is being used to login.
   * @param {RecaptchaVerifier} appVerifier A Recaptcha Verifier that will be used to verify the user's phone number.
   * @return {Promise<ConfirmationResult>} Returns a promise with a Confirmation Result. This, along with a Verification Code, will
   * be required to complete the login process.
   */
  loginWithPhoneNumber_Step1_SendCode(phoneNumber: string, appVerifier: RecaptchaVerifier): Promise<ConfirmationResult> {
    return new Promise<ConfirmationResult>(async (resolve, reject) => {
      try {
        appVerifier.verify();

        const auth: Auth = await getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        const confirmationResult: ConfirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);

        resolve(confirmationResult);
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method loginWithPhoneNumber_Step2_FinishLogin As the second of two steps required for logging in with a Phone Number, this method uses
   * a confirmation result issued by a verifier (e.g., Recaptcha) and a verification code (provie by a user), and completes the login process.
   * @param {ConfirmationResult} confirmationResult A confirmation result issued by a verifier (e.g., Recaptcha).
   * @param {string} verificationCode A verification code sent to a user's phone via the verifier, and then entered (confirmed) by the user.
   * @return {Promise<UserCredential>} Returns a promise with the logged in user credentials.
   */
  loginWithPhoneNumber_Step2_FinishLogin(confirmationResult: ConfirmationResult, verificationCode: string): Promise<UserCredential> {
    return new Promise<UserCredential>(async (resolve, reject) => {
      try {
        const userCredential: UserCredential = await confirmationResult.confirm(verificationCode);

        // console.info(`User info after logging in with Phone Number: ${JSON.stringify(user)}`);

        // TODO: log that user logged in

        resolve(userCredential);
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method loginWithPhoneNumber_Step1_SendCode As the first of two steps required for logging in with a Phone Number, this method triggers 
   * the sending of a confirmation code to a phone number to verify the phone number.
   * @param {string} phoneNumber A phone number that is being used to login.
   * @param {RecaptchaVerifier} appVerifier A Recaptcha Verifier that will be used to verify the user's phone number.
   * @return {Promise<ConfirmationResult>} Returns a promise with a Confirmation Result. This, along with a Verification Code, will
   * be required to complete the login process.
   */
  linkWithPhoneNumber_Step1_VerifyPhoneNumber(phoneNumber: string, appVerifier: RecaptchaVerifier): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      try {
        appVerifier.verify();

        const auth: Auth = await getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        const provider: PhoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId: string = await provider.verifyPhoneNumber(phoneNumber, appVerifier);

        resolve(verificationId);
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method linkWithPhoneNumber_Step2_FinishLogin As the second of two steps required for logging in with a Phone Number, this method uses
   * a Verification Id issued by the PhoneAuthProvider, along with a verification code (provie by a user), and completes the linking process.
   * @param {ConfirmationResult} verificationId A Verification Id issued by the PhoneAuthProvider.
   * @param {string} verificationCode A verification code sent to a user's phone via the verifier, and then entered (confirmed) by the user.
   * @return {Promise<UserCredential>} Returns a promise with the linked user credentials.
   */
  linkWithPhoneNumber_Step2_FinishLogin(verificationId: string, verificationCode: string): Promise<UserCredential> {
    return new Promise<UserCredential>(async (resolve, reject) => {
      try {
        const phoneCredential: PhoneAuthCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
        // if (phoneCredential === null || phoneCredential === undefined) {
        //   reject('Unable to link phone number to the current user account. PhoneAuthProvider.credential() failed.');
        // }

        const currentUser: User | null = await this.getUserAccountProfile();

        // can only send verification email if there's a current user
        if (currentUser !== null) {
          const userCredential: UserCredential = await linkWithCredential(currentUser, phoneCredential);

          // if (userCredential === null || userCredential === undefined) {
          //   reject('Unable to link phone number to the current user account. currentUser.linkWithCredential() failed.');
          // }

          // console.info(`User info after linking with Phone Number: ${JSON.stringify(user)}`);

          // TODO: log that phone number linked to user account

          resolve(userCredential);
        }
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method verifyUserAccount Triggers request to verify a user account.
   * This will be performed after user registers for an account, triggering
   * an email to the user with a hyperlink to verify the account. 
   * The purpose of this approach is to reduce (eliminate?) the risk of bots 
   * creating and using accounts.
   * @return {Promise<boolean>} Returns a promise to enable asynchronous behavior.
   */
  verifyUserAccount(): Promise<boolean> {
    return new Promise<boolean>(async (resolve: (value: boolean) => void, reject) => {
      try {
        let verifyUserAccountRequestSent = false;

        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only send verification email if there's a current user
        if (user !== null && user.email !== null) {
          await sendEmailVerification(user);
          // TODO: log that verification email has been sent

          verifyUserAccountRequestSent = true;
        }

        resolve(verifyUserAccountRequestSent);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method resetUserAccountPassword Allows the currently authenticated user
   * to reset the account password.
   * @return {Promise<boolean>} Returns a promise to enable asynchronous behavior.
   */
  resetUserAccountPassword(): Promise<boolean> {
    return new Promise<boolean>(async (resolve: (value: boolean) => void, reject) => {
      try {
        let resetUserAccountPasswordRequestSent = false;

        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only send password reset  email if there's a current user with a valud email address
        const auth: Auth = await getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        if (user !== null && user.email !== null) {
          await sendPasswordResetEmail(auth, user.email);

          // TODO: log that a request was issued to reset the user account password

          resetUserAccountPasswordRequestSent = true;
        }

        resolve(resetUserAccountPasswordRequestSent);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getUserProfile Retrieves the Firebase Auth user account profile 
   * for the currently authenticated user.
   * @return {Promise<irebase.User | null>} Returns the user account profile, wrapped in a Promise, enabling
   * asynchronous behavior.
   */
  getUserAccountProfile(): Promise<User | null> {
    return new Promise<User | null>(async (resolve, reject) => {
      try {
        const auth: Auth = await getAuth(this.firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }
        const user: User | null = auth.currentUser;
        // return the 'user' object, whether it's valid (a non-null value) or not
        resolve(user);
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method updateUserAccountEmail Updates the email address for the user account of
   * the currently authenticated user.
   * @return {Promise<any>} Returns a promise to enable asynchronous behavior.
   */
  updateUserAccountEmail(newEmail: string): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let userAccountEmailUpdated = false;

        // if (newEmail !== null && newEmail !== undefined) {
        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only update the email if there's a current user
        if (user !== null) {
          // if there's an existing email address and it's NOT the same as the new one requested, update the email address
          await updateEmail(user, newEmail);

          // TODO: log that the user account email address was updated

          userAccountEmailUpdated = true;
        }

        // }

        resolve(userAccountEmailUpdated);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method updateUserAccountPhoneNumber Updates the phone number for the user account of
   * the currently authenticated user.
   * @param {AuthCredential} newPhoneCredential The new phone number (phone credential).
   * @return {Promise<AuthCredential>} Returns a promise to enable asynchronous behavior.
   */
  updateUserAccountPhoneNumber(newPhoneCredential: AuthCredential): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let userAccountPhoneNumberUpdated = false;

        // if (newPhoneCredential !== null && newPhoneCredential !== undefined) {
        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only update the phone number if there's a current user
        if (user !== null) {
          await updatePhoneNumber(user, newPhoneCredential as unknown as PhoneAuthCredential);

          // TODO: log that the user account phone number was updated

          userAccountPhoneNumberUpdated = true;
        }
        // }

        resolve(userAccountPhoneNumberUpdated);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method updateUserAccountProfile Updates the user profile of
   * the currently authenticated user.
   * @return {Promise<boolean>} Returns a promise to enable asynchronous behavior.
   */
  updateUserAccountProfile(displayName?: string | null, photoUrl?: string | null): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let userProfileUpdated = false;

        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only update profile data if there's a current user
        if (user !== null) {
          await updateProfile(user, { displayName: displayName, photoURL: photoUrl });

          // TODO: log that the user account profile was updated

          userProfileUpdated = true;
        }

        resolve(userProfileUpdated);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method logout Logs out the current user.
   * @return {Promise<boolean>} A promise with boolean value to indicate whether the user was logged out.
   */
  logout(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        let userLoggedOut = false;

        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only logout if there's a current user
        if (user !== null) {
          const auth: Auth = await getAuth(this.firebaseApp);
          // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
          if (FirebaseEmulatorSettings.useEmulators) {
            if (FirebaseEmulatorSettings.authEmulatorPort) {
              connectAuthEmulator(auth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
            }
          }
          await signOut(auth);

          // TODO: log that the user logged out

          userLoggedOut = true;
        }

        resolve(userLoggedOut);

      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method deleteUserAccount Deletes the user account of
   * the currently authenticated user.
   * @return {Promise<User | null>} Returns a promise to enable asynchronous behavior.
   */
  deleteUserAccount(): Promise<User | null> {
    return new Promise<User | null>(async (resolve, reject) => {
      try {

        // get the user account profile for the authenticated user (re-use our internal 'getUserAccountProfile()' method)
        const user: User | null = await this.getUserAccountProfile();

        // can only delete the user account if there's a current user
        if (user !== null) {
          await user.delete();

          // TODO: log that the user account was deleted

        }

        resolve(user);
      } catch (error: any) {
        // TODO: log error
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

}
