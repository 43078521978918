import { PaletteMode, Theme } from "@mui/material";
import { IThemeColorSpectrumsPalette } from "../../dataObjects/models/themes";
import { IThemeBaseColors } from "../../dataObjects/models/themes/ThemeBaseColors";
import { createColorPalettesFromThemeBaseColors } from "../../dataObjects/utilities/colors";
import { createThemeFromModeAndColorPalettes } from "./createThemeFromModeAndColorPalettes";


export function createThemeFromModeAndBaseColors(themeMode: PaletteMode, themeBaseColors: IThemeBaseColors): Promise<Theme> {
  return new Promise<Theme>(async (resolve, reject) => {
    try {
      const colorSpectrums: IThemeColorSpectrumsPalette = await createColorPalettesFromThemeBaseColors(themeBaseColors);
      const theme: Theme = createThemeFromModeAndColorPalettes(themeMode, colorSpectrums);

      resolve(theme);
    } catch (error: any) {
      // alert(`Error. User registration failed: (${error})`);
      reject(error);
    }
  });

}