import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchNotesFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchNotesFailureSlice = createSlice({
  name: 'fetchNotesFailure',
  initialState,
  reducers: {
    fetchNotesFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    fetchNotesFailureClear: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { fetchNotesFailure, fetchNotesFailureClear } = fetchNotesFailureSlice.actions;

// export the core reducer for the slice
export const fetchNotesFailureReducer = fetchNotesFailureSlice.reducer;

export default fetchNotesFailureSlice.reducer;