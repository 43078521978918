import { IFirestoreCategoryRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ICategory, ICategoryAsJson } from "../../../../dataObjects/models/categories/Category";


export function deleteCategory(category: ICategory): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Category repository from the repository factory
      const firestoreCategoryRepository: IFirestoreCategoryRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
        IFirestoreBaseRepository<ICategory, ICategoryAsJson>;

      // call the repository delete() method to delete the object
      await firestoreCategoryRepository.delete(category.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}