import { IMdbError, IMdbErrorAsJson } from ".";
import { enumMdbErrorType } from "../enums";

export class MdbError extends Error implements IMdbError {

  constructor(
    message: string,
    errorType?: enumMdbErrorType,
    contextInfo?: any,
    options?: ErrorOptions
  ) {
    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c (MdbError.ctor()) Constructor parameters. message: ${message}; errorType: ${errorType}; options: ${options}`, 'background: #BB0; color: #fff');

    // pass parameters to the base Error class
    super(message, options);
    super.name = 'MdbError';

    // if the errorType is defined, set the value of the member variable _errorType
    if (errorType !== undefined) {
      this._errorType = errorType;
    }

    // if the contextInfo is defined, set the value of the member variable _contextInfo
    if (contextInfo !== undefined) {
      this._contextInfo = contextInfo;
    }
    
    displayConsoleLogs && console.log(`%c (MdbError.ctor()) Superclass & Class members. super.message: ${super.message}; this.errorType: ${this.errorType}; super.cause: ${super.cause}`, 'background: #BB0; color: #fff');
    displayConsoleLogs && console.log(`%c (MdbError.ctor()) Class members. this.message: ${this.message}; this.errorType: ${this.errorType}; this.cause: ${this.cause}`, 'background: #BB0; color: #fff');

  }

  /*-----------------------------------------------*/
  /**
   * @property {enumMdbErrorType} errorType The type of error
   */
  private _errorType: enumMdbErrorType = enumMdbErrorType.UnknownError;

  /**
   * @method errorType Getter method for _errorType
   */
  get errorType(): enumMdbErrorType {
    return this._errorType;
  }

  /**
   * @method errorType Setter method for _errorType
   * @param {enumMdbErrorType} value is the input value for setting _errorType
   */
  set errorType(value: enumMdbErrorType) {
    this._errorType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {any | undefined} contextInfo Additional context information for a given error.
   */
  private _contextInfo: any | undefined = undefined;

  /**
   * @method contextInfo Getter method for _contextInfo
   */
  get contextInfo(): any | undefined {
    return this._contextInfo;
  }

  /**
   * @method contextInfo Setter method for _contextInfo
   * @param {any | undefined} value is the input value for setting _contextInfo
   */
  set contextInfo(value: any | undefined) {
    this._contextInfo = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IMdbError} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IMdbError {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IMdbError = Object.create(MdbError.prototype);
    Object.assign(copyOfObject, this);

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(): IMdbErrorAsJson {
    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c (MdbError.toJSON()) Entered toJSON()', 'background: #BB0; color: #fff');

    try {
      // prepare JSON object for return, including members from the base (super) class and
      // members of this class
      const jsonObject: IMdbErrorAsJson = {
        name: this.name,
        message: this.message,
        cause: this.cause,
        stack: this.stack,
        errorType: this.errorType,
        contextInfo: this.contextInfo,
      };

      displayConsoleLogs && console.log(`%c (MdbError.toJSON()) Ready to return from toJSON(). Discrete data. this.errorType: ${this.errorType}; this.name: ${this.name}; this.message: ${this.message}; this.cause: ${this.cause}`, 'background: #BB0; color: #fff');

      displayConsoleLogs && console.log(`%c (MdbError.toJSON()) Ready to return from toJSON(). jsonObject elements. errorType: ${jsonObject.errorType}; name: ${jsonObject.name}; message: ${jsonObject.message}; cause: ${jsonObject.cause}`, 'background: #BB0; color: #fff');

      displayConsoleLogs && console.log(`%c (MdbError.toJSON()) Ready to return from toJSON(). jsonObject stringified: ${JSON.stringify(jsonObject)}`, 'background: #BB0; color: #fff');

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IMdbErrorAsJson} jsonObject A JSON version of a class instance.
   * @returns An IMdbError instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IMdbErrorAsJson): IMdbError {
    try {
      // create a new instance of this class
      let mdbErrorObject: MdbError = Object.create(MdbError.prototype);

      // copy field values from the json object 
      mdbErrorObject.message = jsonObject.message;
      mdbErrorObject.name = jsonObject.name;
      mdbErrorObject.cause = jsonObject.cause;
      mdbErrorObject.errorType = jsonObject.errorType;
      mdbErrorObject.contextInfo = jsonObject.contextInfo;

      return mdbErrorObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}