import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface ITennisBallWithMyTennisBrainLogoParms {
  overallClassName: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  clipPaths: {
    fill: 'none',
  },

  racketThroat: {
    fill: 'none',
    stroke: '#000',
    strokeWidth: '0.98px',
    strokeMiterlimit: 10,
  },

  racketStringMain: {
    fill: 'none',
    strokeMiterlimit: 10,
    stroke: '#3f3f3f',
    strokeWidth: '0.03px',
  },

  racketStringCross: {
    fill: 'none',
    strokeMiterlimit: 10,
    stroke: '#3f3f3f',
    strokeWidth: '0.03px',
  },

  groupsForLargeTennisBall: {
    isolation: 'isolate',
  },

  clipsForLargeTennisBall: {
    clipPath: 'url(#clipPathForLargeTennisBallOutline)',
  },

  tennisBallGradient01: {
    fill: '#e3e439',
  },

  tennisBallGradient02: {
    fill: '#dedf37',
  },

  tennisBallGradient03: {
    fill: '#d9da36',
  },

  tennisBallGradient04: {
    fill: '#d4d535',
  },

  tennisBallGradient05: {
    fill: '#d0d134',
  },

  tennisBallGradient06: {
    fill: '#cbcc33',
  },

  tennisBallGradient07: {
    fill: '#c6c732',
  },

  tennisBallGradient08: {
    fill: '#c2c230',
  },

  tennisBallGradient09: {
    fill: '#bdbe2f',
  },

  tennisBallGradient10: {
    fill: '#b8b92e',
  },

  tennisBallGradient11: {
    fill: '#b3b42d',
  },

  tennisBallGradient12: {
    fill: '#afaf2c',
  },

  tennisBallGradient13: {
    fill: '#aaab2a',
  },

  tennisBallGradient14: {
    fill: '#a5a629',
  },

  tennisBallGradient15: {
    fill: '#a0a128',
  },

  tennisBallGradient16: {
    fill: '#9c9c27',
  },

  tennisBallGradient17: {
    fill: '#979826',
  },

  tennisBallGradient18: {
    fill: '#929325',
  },

  tennisBallGradient19: {
    fill: '#8e8e23',
  },

  tennisBallGradient20: {
    fill: '#898922',
  },

  tennisBallGradient21: {
    fill: '#7f8020',
  },

  tennisBallGradient22: {
    fill: '#7b7b1f',
  },

  tennisBallGradient23: {
    fill: '#848521',
  },


  tennisBallSeams: {
    fill: '#e6e6e6',
  },

  clipPathTennisBallSeams: {
    clipPath: 'url(#clipPathForSmallTennisBallOutline)',
  },

  logoBackgroundRect: {
    fill: theme.palette.primary.dark,
  },

  personHead: {
    fill: theme.myTennisBrainLogo?.personHeadFill,
    stroke: theme.myTennisBrainLogo?.personHeadStroke,
    strokeWidth: '001px',
  },

}));


// const TennisBallWithMyTennisBrainLogo: React.FC<ITennisBallWithMyTennisBrainLogoParms> = (props: ITennisBallWithMyTennisBrainLogoParms) => {

// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const TennisBallWithMyTennisBrainLogo = React.forwardRef((props: ITennisBallWithMyTennisBrainLogoParms, ref?: React.Ref<SVGSVGElement>) => {

  const { overallClassName } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={overallClassName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 194.74 194.46" >
      {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3.055 11H5a2 2 0 012 2v1a2 2 0 002 2 2 2 0 012 2v2.945M8 3.935V5.5A2.5 2.5 0 0010.5 8h.5a2 2 0 012 2 2 2 0 104 0 2 2 0 012-2h1.064M15 20.488V18a2 2 0 012-2h3.064M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /> */}
      <defs>
        <clipPath id="clipPathForLargeTennisBallOutline" transform="translate(0.17 0.06)">
          <path id="largeTennisBallOutlineForGradients" className={classes.clipPaths}
            d="M97.2,194.4c-4.74-.19-11-.7-15.76-1.27A96.58,96.58,0,0,1,39.8,175.64h0c-3.51-2.78-8-6.67-11.33-9.71a98.11,98.11,0,0,1-17.62-24.06,95.93,95.93,0,0,1-5.89-14H5c-1.29-4.44-2.76-10.43-3.69-15a98.42,98.42,0,0,1,0-31.52A96.45,96.45,0,0,1,5,66.48c0-2.28,1.79-5,2.68-7.11s2.25-4.79,3.21-6.84A97.31,97.31,0,0,1,18.76,39.8c0-2.24,3.12-4.21,4.64-5.85A141.9,141.9,0,0,1,34,23.4a145,145,0,0,1,12-8.84,67.73,67.73,0,0,1,6.53-3.71,97.12,97.12,0,0,1,21.31-8A145.54,145.54,0,0,1,89.23.32C91.6,0,94.81.1,97.2,0c2.39.1,5.6,0,8,.32a145.54,145.54,0,0,1,15.39,2.51A69.55,69.55,0,0,1,127.92,5c4.25,1.6,9.84,4,14,5.89a98.11,98.11,0,0,1,24.06,17.62h0c3,3.29,6.93,7.82,9.71,11.33a97.31,97.31,0,0,1,7.91,12.73,97,97,0,0,1,9.58,28.91,98.42,98.42,0,0,1,0,31.52,97.54,97.54,0,0,1-51.26,70.59A97,97,0,0,1,113,193.13,98.58,98.58,0,0,1,97.2,194.4Z" />
        </clipPath>
        <clipPath id="clipPathForSmallTennisBallOutline" transform="translate(0.17 0.06)">
          <path id="smallTennisBallOutlineForGradients" className={classes.clipPaths}
            d="M88.65,85.33c-.23,0-.52,0-.75-.06a4.65,4.65,0,0,1-2-.83h0c-.16-.13-.38-.31-.53-.46a4.48,4.48,0,0,1-.84-1.14,3.81,3.81,0,0,1-.27-.66h0c-.07-.21-.13-.49-.18-.7a4.66,4.66,0,0,1,0-1.49,4.35,4.35,0,0,1,.18-.71,1.06,1.06,0,0,1,.12-.34c0-.1.11-.22.15-.32a4.67,4.67,0,0,1,.38-.6c0-.11.15-.2.22-.28a5.9,5.9,0,0,1,.5-.5,6,6,0,0,1,.57-.42,3.07,3.07,0,0,1,.31-.17,4,4,0,0,1,1-.38,6.17,6.17,0,0,1,.73-.12l.38,0,.38,0a5.69,5.69,0,0,1,.72.12,2,2,0,0,1,.35.1c.2.07.47.19.66.28a4.45,4.45,0,0,1,1.14.83h0c.14.16.33.37.46.54a4.6,4.6,0,0,1-1,6.43,6,6,0,0,1-.6.37,4.69,4.69,0,0,1-1.37.45A4.52,4.52,0,0,1,88.65,85.33Z" />
        </clipPath>
      </defs>

      <g id="layerLargeTennisBall">
        <g id="groupLargeTennisBallOuter">
          <g id="groupLargeTennisBallMiddle">
            <g id="groupLargeTennisBallInner" className={classes.groupsForLargeTennisBall}>
              <g id="groupLargeTennisBallSeamsAndGradients" className={classes.groupsForLargeTennisBall}>
                <g id="groupClipLargeTennisBallGradients">
                  <g className={classes.clipsForLargeTennisBall}>
                    <g id="groupLargeTennisBallGradients" className={classes.groupsForLargeTennisBall}>
                      <path id="pathLargeTennisBallGradient24" className={classes.tennisBallGradient23}
                        d="M97.2,194.4c-4.74-.19-11-.7-15.76-1.27A96.58,96.58,0,0,1,39.8,175.64c-3.51-2.78-8-6.67-11.33-9.71a98.11,98.11,0,0,1-17.62-24.06,95.93,95.93,0,0,1-5.89-14c-1.29-4.44-2.76-10.43-3.69-15a98.42,98.42,0,0,1,0-31.52A96.45,96.45,0,0,1,5,66.48c1.6-4.25,4-9.84,5.89-14A97.31,97.31,0,0,1,18.76,39.8C28,28.1,32.47,23.64,46,14.56A96.4,96.4,0,0,1,73.84,2.83C90.13-1,101.94-.06,105.17.32c17,2,22.67,3.94,36.7,10.53a98.11,98.11,0,0,1,24.06,17.62c3,3.29,6.93,7.82,9.71,11.33a97.31,97.31,0,0,1,7.91,12.73,97,97,0,0,1,9.58,28.91,98.48,98.48,0,0,1,0,31.53,97.54,97.54,0,0,1-51.26,70.58A97,97,0,0,1,113,193.13,98.58,98.58,0,0,1,97.2,194.4"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient21" className={classes.tennisBallGradient23}
                        d="M189.44,127.92a95.93,95.93,0,0,1-5.89,14,97.8,97.8,0,0,1-41.68,41.68A97,97,0,0,1,113,193.13,98.58,98.58,0,0,1,97.2,194.4l-8-.32-7.79-1A96.58,96.58,0,0,1,39.8,175.64h0c-3.51-2.78-8-6.67-11.33-9.71a98.11,98.11,0,0,1-17.62-24.06,95.93,95.93,0,0,1-5.89-14H5c-1.29-4.44-2.76-10.43-3.69-15a98.42,98.42,0,0,1,0-31.52A96.45,96.45,0,0,1,5,66.48H5c1.6-4.25,4-9.84,5.89-14A97.31,97.31,0,0,1,18.76,39.8h0c2.78-3.51,6.67-8,9.71-11.33L34,23.4l5.85-4.64,6.2-4.2,6.53-3.71,6.84-3.21L66.48,5l7.36-2.13,7.6-1.56L89.23.32l8-.32C23.38,1.47-1,89.51,57.06,144.93c50.08,47.8,122.33,32.1,135.63-29.47a95.72,95.72,0,0,1-3.25,12.46"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient23" className={classes.tennisBallGradient22}
                        d="M175.64,154.6a97.8,97.8,0,0,1-33.77,29A97,97,0,0,1,113,193.13,98.58,98.58,0,0,1,97.2,194.4l-8-.32-7.79-1A96.58,96.58,0,0,1,39.8,175.64h0c-3.51-2.78-8-6.67-11.33-9.71a98.11,98.11,0,0,1-17.62-24.06,95.93,95.93,0,0,1-5.89-14H5c-1.29-4.44-2.76-10.43-3.69-15a98.42,98.42,0,0,1,0-31.52A96.45,96.45,0,0,1,5,66.48H5c1.6-4.25,4-9.84,5.89-14A97.31,97.31,0,0,1,18.76,39.8h0c2.78-3.51,6.67-8,9.71-11.33L34,23.4l5.85-4.64,6.2-4.2,6.53-3.71,6.84-3.21,4-1.57L60.15,7.35C-11.09,41.73,2.9,139.93,84.29,176.83c29.69,13.45,73.61,7.49,96.54-30.06l-4.08,6.29-1.11,1.54"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient22" className={classes.tennisBallGradient21}
                        d="M183.55,141.87a97.8,97.8,0,0,1-41.68,41.68A97,97,0,0,1,113,193.13,98.58,98.58,0,0,1,97.2,194.4l-8-.32-7.79-1A96.58,96.58,0,0,1,39.8,175.64h0c-3.51-2.78-8-6.67-11.33-9.71a98.11,98.11,0,0,1-17.62-24.06,95.93,95.93,0,0,1-5.89-14H5c-1.29-4.44-2.76-10.43-3.69-15a98.42,98.42,0,0,1,0-31.52A96.45,96.45,0,0,1,5,66.48H5c1.6-4.25,4-9.84,5.89-14A97.31,97.31,0,0,1,18.76,39.8h0c2.78-3.51,6.67-8,9.71-11.33L34,23.4l5.85-4.64,6.2-4.2,6.53-3.71,6.84-3.21L66.48,5l7.36-2.13,7.6-1.56L84.18.86l-1.61.24C13.66,13.64-7.5,103.2,64.6,157.78c51.56,39,106.82,16.64,124.16-27.87a97.15,97.15,0,0,1-5.21,12"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient20" className={classes.tennisBallGradient20}
                        d="M127.92,5c4.25,1.6,9.84,4,14,5.89a98.11,98.11,0,0,1,24.06,17.62h0c3,3.29,6.93,7.82,9.71,11.33a97.31,97.31,0,0,1,7.91,12.73,97,97,0,0,1,9.58,28.91A98.58,98.58,0,0,1,194.4,97.2c0,1,0,2,0,3h0c-5.7,76-88.86,90.11-135.41,39.79C-2.15,73.9,42.87-2.13,102,.19A92.37,92.37,0,0,1,116.4,1.9l7.49,1.81,4,1.25"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient19" className={classes.tennisBallGradient19}
                        d="M141.87,10.85a98.11,98.11,0,0,1,24.06,17.62h0c3,3.29,6.93,7.82,9.71,11.33a97.31,97.31,0,0,1,7.91,12.73,97,97,0,0,1,9.58,28.91q.15.9.27,1.8h0c6.81,67.7-60.71,111.67-125.86,58.62C15.12,99.18,23.5,7.67,96.29.79c5.28-.5,19.54-.86,37.94,6.52l6.87,3.15.77.39"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient18" className={classes.tennisBallGradient18}
                        d="M154.6,18.76a97.06,97.06,0,0,1,11.33,9.71h0c3,3.29,6.93,7.82,9.71,11.33a96.63,96.63,0,0,1,12.87,24h0C211.33,135,152.75,178.91,95.84,155,32.64,128.51,12.52,33.45,80.28,6c27.33-11.08,54-.23,71.53,10.81q1.41,1,2.79,2"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient17" className={classes.tennisBallGradient17}
                        d="M184.47,54.46c31.56,65.5-24,125.83-88.69,96.34S22.12,29.73,81.88,6.77c41.5-15.94,85.57,12.34,102.59,47.69"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient16" className={classes.tennisBallGradient16}
                        d="M184.19,54.46c28.71,60.64-18.65,111-71.22,98.15C48.63,136.83,16.79,47.72,78.46,10.35c29.87-18.09,83.69-2.46,105.73,44.11"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient15" className={classes.tennisBallGradient15}
                        d="M183.68,54.46c25.51,54.47-15.18,109.19-70.22,94.43-63.73-17.1-93-107.25-29.46-139,33.42-16.7,80.93,4.54,99.68,44.58"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient14" className={classes.tennisBallGradient14}
                        d="M189.45,74.91c9.19,55.88-44.28,91.47-93.8,62.44C38.32,103.74,38.87,22.4,96.55,7.46c39.59-10.26,85.61,23.15,92.9,67.45"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient13" className={classes.tennisBallGradient13}
                        d="M188.39,74.91c7.53,50.23-40,89.34-92.77,57.9C44,102,44.53,23.65,96.47,9.49c43.7-11.91,86,26.05,91.92,65.42"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient12" className={classes.tennisBallGradient12}
                        d="M187.16,74.91c6.18,44-36.73,86.84-91.57,53.24-43-26.34-54-102.22,8.36-118.27,31.2-8,77.16,22.07,83.21,65"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient11" className={classes.tennisBallGradient11}
                        d="M182.17,60.59c17.15,51.76-24.73,86.85-67.13,72.53C56.87,113.48,44.61,35.84,96.31,14.44c31.89-13.2,73.62,9.23,85.86,46.15"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient10" className={classes.tennisBallGradient10}
                        d="M184.09,74.91c5.66,56.58-56.62,71.16-88.55,43.44C47.19,76.38,67.51,14,116.28,12.7c32.14-.89,64.46,28.77,67.81,62.21"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient09" className={classes.tennisBallGradient09}
                        d="M182.28,74.91c4.08,48.28-48.62,71.13-86.76,37.61-20.93-18.4-26.17-45-23.21-58.06,8.12-35.7,36.82-39.32,45.42-39.58,34-1,62.08,30.85,64.55,60"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient08" className={classes.tennisBallGradient08}
                        d="M180.3,74.91c2.84,39.8-41.07,70.6-84.79,31.65C79.22,92.05,73.26,66,76.32,54.46,84.63,23,106.71,17.62,119.13,17.21c32.57-1.08,59.15,29.58,61.17,57.7"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient07" className={classes.tennisBallGradient07}
                        d="M178.15,74.91c1,34.13-36.62,67.51-82.65,25.59-10.23-9.32-17.69-36.2-14.64-46,2.58-8.34,10.42-33.72,39.63-34.76,25.18-.91,55.05,21.55,57.66,55.21"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient06" className={classes.tennisBallGradient06}
                        d="M175.33,74.91c0,3.53-3.91,20-5.32,22.29-4.65,7.66-17.36,15.13-17.46,15.16-8.9,2.6-29.74,8.69-53.55-15.16C88.92,87.11,82.7,63.83,85.58,54.46c0-.16,5.41-16.22,10.33-20.54,2.84-2.49,13.11-10.67,26-11.34,18.75-1,53.27,18.15,53.4,52.33"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient05" className={classes.tennisBallGradient05}
                        d="M172.27,74.91C172,92.45,160.59,104.45,152.94,107c-.48.16-17.33,3.43-17.5,3.39-17.66-3.73-22-6.17-29-13.23-.11-.11-11.35-13.11-14.8-22.29-.11-.28-1.35-20.16-1.28-20.45,5.81-24.25,25.11-27.91,33.13-28.36,17.44-1,49.2,16.82,48.76,48.81"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient04" className={classes.tennisBallGradient04}
                        d="M169,74.91c-.23,7.89-10,24.36-15.81,26.71-.4.16-17.25,3.6-17.51,3.57s-17.68-3.62-21.9-8L97.1,74.91c-.25-.57-2-19.69-1.85-20.45.17-1,11.57-18,11.67-18.05,7-5.75,17-6.5,18.1-6.57,20.42-1.21,44.71,19.35,44,45.07"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient03" className={classes.tennisBallGradient03}
                        d="M165,74.91c-.31,1.91-10.22,19.07-11.78,20.4-2.27,1.93-16,4.55-17.44,4.55-2.21,0-15.16-5.48-19.43-8.85l-12.19-16.1-2-20.45,10.39-16c.08-.09,9.76-8.35,28.22-2.5,2.53.8,20.17,18.32,20.26,18.47.93,1.48,4.31,18.69,4,20.45"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient02" className={classes.tennisBallGradient02}
                        d="M159.13,74.91c-.7,3.69-3.29,8.56-6.29,10.79-.46.34-16.75,6.66-17.09,6.63L116.18,81.49a18.85,18.85,0,0,1-5.09-6.58L109,54.46c.24-2.6,2.73-5.59,4.64-7.37l14.5-8c.17,0,15.58,4.46,15.85,4.62.54.3,10.2,9.52,11.24,10.72.77.89,4.19,18.86,3.89,20.45"
                        transform="translate(0.17 0.06)" />
                      <path id="pathLargeTennisBallGradient01" className={classes.tennisBallGradient01}
                        d="M152.8,74.91c-.61,3-17.27,8-17.42,8l-14.55-8-3.1-20.45L130.26,47c.2,0,15.47,4.91,16.68,5.39.81.31,1.51,1.35,2.06,2s4.06,19.16,3.8,20.45"
                        transform="translate(0.17 0.06)" />
                    </g>
                  </g>
                </g>
                <g id="groupClipLargeTennisBallSeams">
                  <g className={classes.clipsForLargeTennisBall}>
                    <g id="groupTennisBallSeamsOuter">
                      <g id="groupLargeTennisBallSeamsMiddle">
                        <g id="groupLargeTennisBallSeamsInner">
                          <path id="largeTennisBallSeams" className={classes.tennisBallSeams}
                            d="M175.78,39.81A96.76,96.76,0,0,1,193.3,81.52a97.72,97.72,0,0,1-3.69,46.57,97.05,97.05,0,0,1-13.83,26.72q-1.07,1.47-2.19,2.88H20.81q-1.12-1.41-2.19-2.88A97.05,97.05,0,0,1,4.79,128.09c-1.28-4.45-2.76-10.45-3.69-15a98.79,98.79,0,0,1,0-31.58,96.69,96.69,0,0,1,3.69-15c1.61-4.26,4-9.86,5.91-14a96,96,0,0,1,7.92-12.76l4-5.12H171.77l4,5.12m-10-11.22c-1-1-2-1.95-3-2.9H31.63c-1,.95-2,1.91-3,2.9-3,3.27-6.92,7.8-9.7,11.3A97.36,97.36,0,0,0,11,52.6c-1.93,4.1-4.28,9.68-5.88,13.92A96.06,96.06,0,0,0,1.46,81.45a98,98,0,0,0,0,31.46c.93,4.52,2.4,10.5,3.68,14.93A95.53,95.53,0,0,0,11,141.76a97.14,97.14,0,0,0,17.59,24l.93.92H164.86l.93-.92a97.14,97.14,0,0,0,17.59-24,96.57,96.57,0,0,0,9.56-28.85,98,98,0,0,0,0-31.46,96.57,96.57,0,0,0-9.56-28.85,97.36,97.36,0,0,0-7.89-12.71c-2.78-3.5-6.6-8.08-9.7-11.3"
                            transform="translate(0.17 0.06)" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g >
      <g id="layerLogoWithBackgroundRect" data-name="layerLogoWithBackgroundRect">
        <g id="layerLogoBackgroundRect">
          <rect id="logoBackgroundRect" className={classes.logoBackgroundRect} x="52.37" y="52.26" width="90" height="90" rx="8.54" />
        </g>
        <g id="layerPersonHead">
          <path id="personHead" className={classes.personHead}
            d="M76.23,109.85c-1.6-6.4-9.9-9.9-8-24.92C71.52,59.18,109.68,60,116.79,76.7c3.32,7.77,1.11,7.72,2.31,10.19.76,1.58,3,3.63,6.15,8.22,3.83,5.61-2.84,4.93-3.31,6.23-.68,1.85,1.18,3.1,1.18,4a2,2,0,0,1-1.53,2s2,1.47.58,2.84c-1.88,1.8-.54,4.35-.11,5.82.78,2.73-1.17,5.09-4.43,5.37-2.76.22-6.5,0-8.36.05-2.27,0-2.38,3-3.41,8.19H74.2S77.53,115.08,76.23,109.85Z"
            transform="translate(0.17 0.06)" />
        </g>
        <g id="layerRacket">
          <g id="groupRacketThroat">
            <rect id="racketThroatBase" x="115.55" y="85.15" width="2.49" height="2.22"
              transform="translate(10.1 184.6) rotate(-79.05)" />
            <path id="racketThroatLeft" className={classes.racketThroat}
              d="M97.29,91.07a41.64,41.64,0,0,1,13.49-4,40.78,40.78,0,0,1,4.94-.27" transform="translate(0.17 0.06)" />
            <path id="racketThroatRight" className={classes.racketThroat} d="M100.5,74.51A40.9,40.9,0,0,0,116,85.32"
              transform="translate(0.17 0.06)" />
          </g>
          <g id="groupRacketStringsMain">
            <line id="racketStringMain18" className={classes.racketStringMain} x1="82.77" y1="70.88" x2="100.62" y2="74.33" />
            <line id="racketStringMain17" className={classes.racketStringMain} x1="80.35" y1="71.49" x2="102.11" y2="75.7" />
            <line id="racketStringMain16" className={classes.racketStringMain} x1="78.84" y1="72.29" x2="102.78" y2="76.92" />
            <line id="racketStringMain15" className={classes.racketStringMain} x1="78.07" y1="73.28" x2="103.32" y2="78.17" />
            <line id="racketStringMain14" className={classes.racketStringMain} x1="77.26" y1="74.17" x2="103.81" y2="79.31" />
            <line id="racketStringMain13" className={classes.racketStringMain} x1="76.66" y1="75.08" x2="104.19" y2="80.41" />
            <line id="racketStringMain12" className={classes.racketStringMain} x1="76.1" y1="75.94" x2="103.96" y2="81.33" />
            <line id="racketStringMain11" className={classes.racketStringMain} x1="75.8" y1="76.88" x2="103.88" y2="82.32" />
            <line id="racketStringMain10" className={classes.racketStringMain} x1="75.41" y1="77.79" x2="103.91" y2="83.3" />
            <line id="racketStringMain09" className={classes.racketStringMain} x1="75.24" y1="78.74" x2="103.74" y2="84.26" />
            <line id="racketStringMain08" className={classes.racketStringMain} x1="75.26" y1="79.69" x2="103.34" y2="85.13" />
            <line id="racketStringMain07" className={classes.racketStringMain} x1="75.21" y1="80.61" x2="103.07" y2="86" />
            <line id="racketStringMain06" className={classes.racketStringMain} x1="75.28" y1="81.58" x2="102.81" y2="86.91" />
            <line id="racketStringMain05" className={classes.racketStringMain} x1="75.56" y1="82.61" x2="102.11" y2="87.74" />
            <line id="racketStringMain04" className={classes.racketStringMain} x1="76.06" y1="83.69" x2="101.31" y2="88.57" />
            <line id="racketStringMain03" className={classes.racketStringMain} x1="76.56" y1="84.85" x2="100.5" y2="89.48" />
            <line id="racketStringMain02" className={classes.racketStringMain} x1="77.5" y1="86.13" x2="99.27" y2="90.34" />
            <line id="racketStringMain01" className={classes.racketStringMain} x1="79.14" y1="87.81" x2="96.98" y2="91.26" />
          </g>
          <g id="groupRacketStringsCross">
            <line id="racketStringCross20" className={classes.racketStringCross} x1="101.29" y1="75.34" x2="98.34" y2="90.58" />
            <line id="racketStringCross19" className={classes.racketStringCross} x1="99.98" y1="73.91" x2="96.57" y2="91.54" />
            <line id="racketStringCross18" className={classes.racketStringCross} x1="98.84" y1="72.74" x2="95.1" y2="92.11" />
            <line id="racketStringCross17" className={classes.racketStringCross} x1="97.69" y1="72.5" x2="93.94" y2="91.87" />
            <line id="racketStringCross16" className={classes.racketStringCross} x1="96.56" y1="72.12" x2="92.73" y2="91.93" />
            <line id="racketStringCross15" className={classes.racketStringCross} x1="95.46" y1="71.62" x2="91.54" y2="91.86" />
            <line id="racketStringCross14" className={classes.racketStringCross} x1="94.31" y1="71.12" x2="90.26" y2="92.01" />
            <line id="racketStringCross13" className={classes.racketStringCross} x1="93.12" y1="70.83" x2="89.08" y2="91.72" />
            <line id="racketStringCross12" className={classes.racketStringCross} x1="92.02" y1="70.53" x2="87.94" y2="91.64" />
            <line id="racketStringCross11" className={classes.racketStringCross} x1="90.91" y1="70.32" x2="86.83" y2="91.43" />
            <line id="racketStringCross10" className={classes.racketStringCross} x1="89.8" y1="70.1" x2="85.72" y2="91.21" />
            <line id="racketStringCross09" className={classes.racketStringCross} x1="88.67" y1="70" x2="84.63" y2="90.89" />
            <line id="racketStringCross08" className={classes.racketStringCross} x1="87.56" y1="70.12" x2="83.64" y2="90.36" />
            <line id="racketStringCross07" className={classes.racketStringCross} x1="86.44" y1="70.13" x2="82.61" y2="89.94" />
            <line id="racketStringCross06" className={classes.racketStringCross} x1="85.33" y1="70.48" x2="81.7" y2="89.2" />
            <line id="racketStringCross05" className={classes.racketStringCross} x1="84.36" y1="70.63" x2="80.87" y2="88.7" />
            <line id="racketStringCross04" className={classes.racketStringCross} x1="83.36" y1="71" x2="80.07" y2="87.98" />
            <line id="racketStringCross03" className={classes.racketStringCross} x1="82.2" y1="71.33" x2="79.12" y2="87.24" />
            <line id="racketStringCross02" className={classes.racketStringCross} x1="80.84" y1="71.49" x2="77.92" y2="86.58" />
            <line id="racketStringCross01" className={classes.racketStringCross} x1="79.2" y1="72.66" x2="76.83" y2="84.89" />
          </g>
          <g id="groupRacketHead">
            <path id="racketHead"
              d="M75.58,78.28a8.21,8.21,0,0,0,.29,4.13,10.77,10.77,0,0,0,2.32,3.87,15.13,15.13,0,0,0,4,3.14,19,19,0,0,0,11.07,2.14,15.28,15.28,0,0,0,4.91-1.41,11.07,11.07,0,0,0,3.59-2.72,8,8,0,0,0,1.52-7.86A10.94,10.94,0,0,0,101,75.71a15,15,0,0,0-4-3.14,18.79,18.79,0,0,0-11.07-2.14A15.27,15.27,0,0,0,81,71.83a10.81,10.81,0,0,0-3.58,2.73,8,8,0,0,0-1.82,3.72m-1-.18c1.2-6.24,8.88-10,17.15-8.4s14,8,12.77,14.19-8.88,10-17.14,8.39-14-8-12.78-14.18Z"
              transform="translate(0.17 0.06)" />
          </g>
        </g>

        <g id="layerSmallTennisBall">
          <g id="groupSmallTennisBallOuter">
            <g id="groupSmallTennisBallMiddle">
              <g id="groupSmallTennisBallInner">
                <g className={classes.clipPathTennisBallSeams}>
                  <g id="groupSmallTennisBallGradients">
                    <g id="groupSmallTennisBallGradients-2" data-name="groupSmallTennisBallGradients">
                      <path id="pathSmallTennisBallGradient25" className={classes.tennisBallGradient23}
                        d="M88.65,85.33c-.23,0-.52,0-.75-.06a4.65,4.65,0,0,1-2-.83c-.16-.13-.38-.31-.53-.46a4.48,4.48,0,0,1-.84-1.14,3.81,3.81,0,0,1-.27-.66c-.07-.21-.13-.49-.18-.7a4.66,4.66,0,0,1,0-1.49,4.35,4.35,0,0,1,.18-.71c.07-.2.18-.47.27-.66a4.67,4.67,0,0,1,.38-.6A4.59,4.59,0,0,1,89,76.15a4.36,4.36,0,0,1,1.73.5,4.7,4.7,0,0,1,1.14.83c.14.16.33.37.46.54a4.59,4.59,0,0,1-1.6,6.8,4.69,4.69,0,0,1-1.37.45,4.52,4.52,0,0,1-.74.06"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient24" className={classes.tennisBallGradient23}
                        d="M93,82.18a4.59,4.59,0,0,1-4.36,3.15l-.38,0-.37,0a4.65,4.65,0,0,1-2-.83h0c-.16-.13-.38-.31-.53-.46a4.48,4.48,0,0,1-.84-1.14,3.81,3.81,0,0,1-.27-.66h0c-.07-.21-.13-.49-.18-.7a4.66,4.66,0,0,1,0-1.49,4.35,4.35,0,0,1,.18-.71h0c.07-.2.18-.47.27-.66a4.67,4.67,0,0,1,.38-.6h0c.13-.17.31-.38.46-.54l.26-.24.27-.22.3-.2.31-.17.32-.15.34-.13.34-.1.36-.08.37,0,.38,0c-3.49.07-4.64,4.24-1.9,6.86,2.37,2.26,5.79,1.52,6.42-1.39a4.31,4.31,0,0,1-.16.59"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient23" className={classes.tennisBallGradient23} d="M88.76,76.14h0"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient21" className={classes.tennisBallGradient22}
                        d="M92.36,83.45a4.57,4.57,0,0,1-3,1.82,4.52,4.52,0,0,1-.74.06l-.38,0-.37,0a4.59,4.59,0,0,1-2-.83h0c-.16-.13-.38-.31-.53-.46a4.48,4.48,0,0,1-.84-1.14,4.84,4.84,0,0,1-.28-.66h0c-.06-.21-.13-.49-.17-.7a4.66,4.66,0,0,1,0-1.49,5.65,5.65,0,0,1,.17-.71h0c.08-.2.19-.47.28-.66a4.67,4.67,0,0,1,.38-.6h0c.13-.17.31-.38.46-.54l.26-.24.27-.22.3-.2.31-.17.32-.15.19-.08-.15.06c-3.37,1.63-2.71,6.27,1.14,8a3.61,3.61,0,0,0,4.56-1.42l-.19.29,0,.08"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient22" className={classes.tennisBallGradient21}
                        d="M92.73,82.84a4.59,4.59,0,0,1-2,2,4.69,4.69,0,0,1-1.37.45,4.52,4.52,0,0,1-.74.06l-.38,0-.37,0a4.59,4.59,0,0,1-2-.83h0c-.16-.13-.38-.31-.53-.46a4.48,4.48,0,0,1-.84-1.14,4.84,4.84,0,0,1-.28-.66h0c-.06-.21-.13-.49-.17-.7a4.66,4.66,0,0,1,0-1.49,5.65,5.65,0,0,1,.17-.71h0c.08-.2.19-.47.28-.66a4.67,4.67,0,0,1,.38-.6h0c.13-.17.31-.38.46-.54l.26-.24.27-.22.3-.2.31-.17.32-.15.34-.13.34-.1.36-.08H88c-3.26.59-4.26,4.83-.85,7.41,2.44,1.84,5,.78,5.87-1.32a4,4,0,0,1-.25.56"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient20" className={classes.tennisBallGradient20}
                        d="M90.1,76.37c.2.07.47.19.66.28a4.7,4.7,0,0,1,1.14.83h0c.14.16.33.37.46.54a4.56,4.56,0,0,1,.89,2.71.61.61,0,0,1,0,.14h0c-.27,3.6-4.2,4.26-6.4,1.88-2.89-3.12-.76-6.72,2-6.61a5.4,5.4,0,0,1,.69.08l.35.09.19.06"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient19" className={classes.tennisBallGradient19}
                        d="M90.76,76.65a4.7,4.7,0,0,1,1.14.83h0c.14.16.33.37.46.54a4.54,4.54,0,0,1,.83,2s0,.05,0,.08h0c.32,3.2-2.87,5.28-6,2.77-2.48-2-2.09-6.34,1.36-6.67a4,4,0,0,1,1.79.31l.32.15,0,0"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient18" className={classes.tennisBallGradient18}
                        d="M91.36,77a4.69,4.69,0,0,1,.54.46h0c.14.16.33.37.46.54A4.74,4.74,0,0,1,93,79.15h0c1.08,3.37-1.69,5.45-4.39,4.32-3-1.26-3.94-5.75-.73-7a3.71,3.71,0,0,1,3.38.51l.13.09"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient17" className={classes.tennisBallGradient17}
                        d="M92.78,78.71c1.49,3.1-1.14,6-4.2,4.56s-3.48-5.73-.66-6.82c2-.75,4,.59,4.86,2.26"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient16" className={classes.tennisBallGradient16}
                        d="M92.76,78.71c1.36,2.87-.88,5.25-3.37,4.64-3-.74-4.54-5-1.63-6.73,1.42-.85,4-.11,5,2.09"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient15" className={classes.tennisBallGradient15}
                        d="M92.74,78.71c1.21,2.58-.72,5.16-3.32,4.47-3-.81-4.4-5.08-1.4-6.58,1.59-.79,3.83.22,4.72,2.11"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient14" className={classes.tennisBallGradient14}
                        d="M93,79.68a2.85,2.85,0,0,1-4.43,2.95c-2.72-1.59-2.69-5.44,0-6.14A3.84,3.84,0,0,1,93,79.68"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient13" className={classes.tennisBallGradient13}
                        d="M93,79.68c.36,2.37-1.89,4.22-4.39,2.74s-2.41-5.17,0-5.83c2.07-.57,4.07,1.23,4.35,3.09"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient12" className={classes.tennisBallGradient12}
                        d="M92.9,79.68c.3,2.08-1.73,4.11-4.33,2.52-2-1.25-2.55-4.84.4-5.6,1.47-.38,3.65,1.05,3.93,3.08"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient11" className={classes.tennisBallGradient11}
                        d="M92.67,79c.81,2.45-1.17,4.11-3.18,3.43-2.75-.93-3.33-4.6-.88-5.61A3.26,3.26,0,0,1,92.67,79"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient10" className={classes.tennisBallGradient10}
                        d="M92.76,79.68c.27,2.67-2.68,3.36-4.19,2-2.29-2-1.33-4.93,1-5a3.38,3.38,0,0,1,3.21,2.94"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient09" className={classes.tennisBallGradient09}
                        d="M92.67,79.68c.2,2.28-2.3,3.36-4.1,1.78a3.16,3.16,0,0,1-1.1-2.75,2.21,2.21,0,0,1,2.15-1.87,3.19,3.19,0,0,1,3,2.84"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient08" className={classes.tennisBallGradient08}
                        d="M92.58,79.68c.13,1.88-1.94,3.34-4,1.5a3,3,0,0,1-.91-2.47,2.09,2.09,0,0,1,2-1.76,3,3,0,0,1,2.89,2.73"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient07" className={classes.tennisBallGradient07}
                        d="M92.48,79.68c0,1.61-1.73,3.19-3.91,1.21a2.92,2.92,0,0,1-.69-2.18,2,2,0,0,1,1.87-1.64,2.83,2.83,0,0,1,2.73,2.61"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient06" className={classes.tennisBallGradient06}
                        d="M92.34,79.68a4.75,4.75,0,0,1-.25,1,3,3,0,0,1-.82.72,2.33,2.33,0,0,1-2.54-.72,2.7,2.7,0,0,1-.63-2,3,3,0,0,1,.49-1,2.13,2.13,0,0,1,1.23-.54,2.63,2.63,0,0,1,2.52,2.48"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient05" className={classes.tennisBallGradient05}
                        d="M92.2,79.68a1.65,1.65,0,0,1-.91,1.52l-.83.16c-.84-.18-1-.29-1.37-.63a4.37,4.37,0,0,1-.7-1s-.07-1-.06-1a1.62,1.62,0,0,1,1.56-1.34,2.39,2.39,0,0,1,2.31,2.31"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient04" className={classes.tennisBallGradient04}
                        d="M92,79.68a2.06,2.06,0,0,1-.74,1.26l-.83.17a3,3,0,0,1-1-.38l-.79-1s-.09-.93-.08-1,.54-.85.55-.85a1.61,1.61,0,0,1,.86-.31A2.13,2.13,0,0,1,92,79.68"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient03" className={classes.tennisBallGradient03}
                        d="M91.86,79.68a7.07,7.07,0,0,1-.56,1,3.21,3.21,0,0,1-.83.22,3.38,3.38,0,0,1-.92-.42L89,79.68l-.1-1,.49-.75a1.39,1.39,0,0,1,1.34-.12,10.81,10.81,0,0,1,1,.87,6.4,6.4,0,0,1,.19,1"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient02" className={classes.tennisBallGradient02}
                        d="M91.58,79.68a1,1,0,0,1-.3.51l-.81.31L89.55,80a.9.9,0,0,1-.24-.31l-.1-1a.7.7,0,0,1,.22-.35l.68-.37s.74.21.75.21.49.45.53.51a5.75,5.75,0,0,1,.19,1"
                        transform="translate(0.17 0.06)" />
                      <path id="pathSmallTennisBallGradient01" className={classes.tennisBallGradient01}
                        d="M91.28,79.68c0,.14-.82.38-.83.38l-.68-.38-.15-1,.59-.35.79.25s.07.07.1.1a7.56,7.56,0,0,1,.18,1"
                        transform="translate(0.17 0.06)" />
                    </g>
                  </g>
                </g>
              </g>
              <g id="clipGroupSmallTennisBallSeams">
                <g className={classes.clipPathTennisBallSeams}>
                  <g id="groupSmallTennisBallSeams">
                    <path id="smallTennisBallSeams" className={classes.tennisBallSeams}
                      d="M92.36,78a4.59,4.59,0,0,1,0,5.44l-.1.13H85l-.11-.13a4.77,4.77,0,0,1-.65-1.27c-.06-.21-.13-.49-.17-.71a4.66,4.66,0,0,1,0-1.49,4.32,4.32,0,0,1,.17-.71c.08-.2.19-.46.28-.66a4.6,4.6,0,0,1,.37-.6l.19-.24h7l.19.24m-.46-.54-.15-.13H85.54l-.14.13c-.14.16-.33.37-.46.54a4.6,4.6,0,0,0-.37.6c-.09.19-.21.46-.28.66a4.35,4.35,0,0,0-.18.71,4.66,4.66,0,0,0,0,1.49c0,.21.12.49.18.7a4.43,4.43,0,0,0,.65,1.26,4.69,4.69,0,0,0,.46.54l0,0h6.41l.05,0A4.66,4.66,0,0,0,93,82.18a4.2,4.2,0,0,0,.17-.7,4.66,4.66,0,0,0,0-1.49,4.53,4.53,0,0,0-.82-2l-.46-.54"
                      transform="translate(0.17 0.06)" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g >
      </g >

    </svg >
  )
});

export default TennisBallWithMyTennisBrainLogo;
