import { IThemeColorSpectrum, IThemeColorSpectrumsPalette } from "../../models/themes";
import { IThemeBaseColors } from "../../models/themes/ThemeBaseColors";
import { ThemeColorSpectrumsPalette } from "../../models/themes/ThemeColorSpectrumsPalette";
import { generateThemeColorSpectrumFromSingleColor } from "./generateThemeColorSpectrumFromSingleColor";

/**
 * @function createColorPalettesFromThemeBaseColors Given a ThemeBaseColors object, generates an IThemeColorSpectrumsPalette object
 */
export function createColorPalettesFromThemeBaseColors(themeBaseColors: IThemeBaseColors): Promise<IThemeColorSpectrumsPalette> {
  return new Promise<IThemeColorSpectrumsPalette>(async (resolve, reject) => {
    try {
      const themeColorSpectrumPrimary: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForPrimary);
      const themeColorSpectrumSecondary: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForSecondary);
      const themeColorSpectrumCancel: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForCancel);
      const themeColorSpectrumError: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForError);
      const themeColorSpectrumHightlight: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForHighlight);
      const themeColorSpectrumInfo: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForInfo);
      const themeColorSpectrumSuccess: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForSuccess);
      const themeColorSpectrumWarning: IThemeColorSpectrum = await generateThemeColorSpectrumFromSingleColor(themeBaseColors.baseColorForWarning);

      let themeColorSpectrumsPalette: IThemeColorSpectrumsPalette = new ThemeColorSpectrumsPalette(
        themeColorSpectrumPrimary, themeColorSpectrumSecondary, themeColorSpectrumCancel, themeColorSpectrumError,
        themeColorSpectrumHightlight, themeColorSpectrumInfo, themeColorSpectrumSuccess, themeColorSpectrumWarning
      );

      resolve(themeColorSpectrumsPalette);
    } catch (error: any) {
      // alert(`Error. User registration failed: (${error})`);
      reject(error);
    }
  });
}

