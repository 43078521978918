import { take, put } from 'redux-saga/effects';
import { deleteThemeSpecs } from '../../../firebaseServices/dataServices/dataServiceActions/themeSpecsActions';
import { themeSpecsDeleteRequest, themeSpecsDeleteStatusChange } from '../../slices/themeSpecs/themeSpecsDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { themeSpecsDeleteFailureChange } from '../../slices/themeSpecs/themeSpecsDeleteFailureSlice';

/**
 * @function deleteThemeSpecsRequestedSaga A saga workflow pertaining to a user having requested to delete a themeSpecs.
 */
export function* deleteThemeSpecsRequestedSaga() {
  // console.info('(deleteThemeSpecsRequestedSaga) entered deleteThemeSpecsRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the themeSpecsDeleteRequest action to be dispatched
      const themeSpecsDeleteRequestData = yield take(themeSpecsDeleteRequest);
      const { payload: themeSpecs } = themeSpecsDeleteRequestData;

      // make a request to delete the themeSpecs
      yield deleteThemeSpecs(themeSpecs)

      // dispatch an action to indicate that the delete operation was successful
      yield put(themeSpecsDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(themeSpecsDeleteStatusChange(enumWorkflowState.Failure));
      yield put(themeSpecsDeleteFailureChange(error));
    }
  }
}
