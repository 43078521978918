import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { ITopicItem } from '../../../../dataObjects/models/topics/TopicItem';
import { TopicItemsCardGridViewItem } from './TopicItemsCardGridViewItem';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';


export interface ITopicItemsCardGridViewProps extends PropsWithChildren<unknown> {
  topicItems: Array<ITopicItem>;
  currentUser: IUser;
  channel: IChannel;
  onDelete: (topicItem: ITopicItem) => void;
}


export const TopicItemsCardGridView: React.FC<ITopicItemsCardGridViewProps> = (props: ITopicItemsCardGridViewProps) => {
  TopicItemsCardGridView.displayName = 'TopicItems Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render TopicItemsList`);

  const { topicItems, currentUser, channel, onDelete } = props;

  const userPermissions : IUserAccessPermissionsForObject = new UserAccessPermissionsForObject(currentUser.id, channel);

  return (
    <>
      <Grid container spacing={2} >
        {
          // only display topicItems if there is a currentUser and a channel
          currentUser && channel && 
          topicItems.map(topicItem =>
            <Grid item key={topicItem.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <TopicItemsCardGridViewItem topicItem={topicItem} userPermissions={userPermissions} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}