import { take } from "redux-saga/effects";
import { saveAudioLinkOnlyWorkerSaga } from "./saveAudioLinkOnlyWorkerSaga";
import { audioLinkSaveWithoutFileUploadRequest } from "../../slices/audioLink/audioLinkSaveStatusSlice";

/**
 * @function saveAudioLinkOnlyWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an AudioLink only (without a file upload).
 */
export function* saveAudioLinkOnlyWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the audioLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveAudioLinkRequestData = yield take(audioLinkSaveWithoutFileUploadRequest);
    const { payload: saveAudioLinkRequest } = saveAudioLinkRequestData;

    yield saveAudioLinkOnlyWorkerSaga(saveAudioLinkRequest);
  }
}