/**
 * @enum {enumDigitalMediaType} The different possible types of Digital Media objects
 */
export enum enumDigitalMediaType {
  Audio = 'Audio',
  Document = 'Document',
  HyperLink = 'HyperLink',
  Image = 'Image',
  Note = 'Note',
  SocialMedia = 'SocialMedia',
  Video = 'Video'
}
