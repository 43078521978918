import { IFirestoreHyperLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreHyperLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IHyperLink, IHyperLinkAsJson } from "../../../../dataObjects/models/digitalMedia/HyperLink";


export function saveHyperLink(hyperLink: IHyperLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a HyperLink repository from the repository factory
      const firestoreHyperLinkRepository: IFirestoreHyperLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.HyperLink) as
        IFirestoreBaseRepository<IHyperLink, IHyperLinkAsJson>;

      // determine if the hyperLink already exists by calling the repository get() method to try and get the object
      const existingHyperLink: IHyperLink | undefined = await firestoreHyperLinkRepository.get(hyperLink.id);

      // If the hyperLink wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingHyperLink === undefined) {
        // call repository method to create the hyperLink
        await firestoreHyperLinkRepository.create(hyperLink);
      } else {
        // call repository method to update the hyperLink
        await firestoreHyperLinkRepository.update(hyperLink);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}