import React, { PropsWithChildren } from 'react';
import { Theme } from '@mui/material/styles';
import { Slide, Snackbar } from '@mui/material';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { IBeacon } from '../../../dataObjects/models/alerts/Beacon';
import { typeBeaconType, typeUniqueId } from '../../../dataObjects/types';
import { TransitionProps } from '@mui/material/transitions';
import { Alert, AlertTitle } from '@mui/material';
import { enumBeaconType } from '../../../dataObjects/enums';
import { useTheme } from '@mui/material/styles';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';

interface IAlertSxProperty {
  border: string;
  backgroundColor: string;
  color: string;
}

export interface ISnackbarWrapperProps extends PropsWithChildren<unknown> {

}

/**
 * @function SnackbarWrapper A wrapper for the Material-UI Snackbar component
 */
const SnackbarWrapper: React.FC<ISnackbarWrapperProps> = (props: ISnackbarWrapperProps) => {

  SnackbarWrapper.displayName = 'Snackbar Wrapper';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log(`Render SnackbarWrapper`);

  const theme: Theme = useTheme();

  const dispatch = useAppDispatch();

  /**
   * @function handleCloseNotification Handler that gets call when either the Snackbar or Alert are being closed, whether
   *                                   by request of the user or by auto-timeout.
   */
  const handleCloseNotification: () => void = () => {
    // dispatch(clearBeacon());
    dispatch(beaconChange(null));
  }

  // Get current beacon from Redux state
  const beacon: IBeacon | null = useAppSelector((state: IStoreState) => state.beacon) as IBeacon | null;

  // set up details for the Snackbar, per the beacon info retrieved from Redux state
  const showSnackbar: boolean = beacon !== null;
  const snackbarKey: typeUniqueId = (beacon != null) ? beacon.id : 'genericKey';
  const snackbarTitle: string = (beacon && beacon.title) ? beacon.title : '';
  const snackbarMessage: string = (beacon && beacon.message) ? beacon.message : '';
  const snackbarAlertsxProperty: {} = (beacon !== null) ? createAlertSxPropertyBeaconType(beacon.beaconType) : {};
  const snackbarAlertSeverity: "error" | "warning" | "info" | "success" | undefined = (beacon !== null) ? alertSeverityStringFromBeaconType(beacon.beaconType) : undefined;

  displayConsoleLogs && console.log(`In SnackbarWrapper after retrieving info from Redux. snackbarTitle: ${snackbarTitle}. snackbarMessage: ${snackbarMessage}`);

  interface ITransitionPropsWithChildren extends TransitionProps {
    children?: React.ReactElement;
  }

  function alertSeverityStringFromBeaconType(beaconType: typeBeaconType): "error" | "warning" | "info" | "success" | undefined {
    // default to 'info'
    let severityString: "error" | "warning" | "info" | "success" | undefined = undefined;

    switch (beaconType) {
      case enumBeaconType.Error:
        severityString = 'error';
        break;

      case enumBeaconType.Info:
        severityString = 'info';
        break;

      case enumBeaconType.Success:
        severityString = 'success';
        break;

      case enumBeaconType.Warning:
        severityString = 'warning';
        break;

      default:
    }

    return severityString;
  }


  function createAlertSxPropertyBeaconType(beaconType: typeBeaconType): IAlertSxProperty {
    // default to 'info'
    let alertSxProperty: IAlertSxProperty = {
      border: theme.alertBar.border,
      backgroundColor: "white",
      color: "black",
    };

    switch (beaconType) {
      case enumBeaconType.Error:
        alertSxProperty = {
          border: theme.beaconNotification.beaconBoard.border,
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        };
        break;

      case enumBeaconType.Info:
        alertSxProperty = {
          border: theme.beaconNotification.beaconBoard.border,
          backgroundColor: theme.palette.info.main,
          color: theme.palette.info.contrastText,
        };
        break;

      case enumBeaconType.Success:
        alertSxProperty = {
          border: theme.beaconNotification.beaconBoard.border,
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        };
        break;

      case enumBeaconType.Warning:
        alertSxProperty = {
          border: theme.beaconNotification.beaconBoard.border,
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        };
        break;

      default:
        alertSxProperty = {
          border: theme.beaconNotification.beaconBoard.border,
          backgroundColor: theme.palette.success.main,
          color: theme.palette.success.contrastText,
        };
    }

    return alertSxProperty;
  }

  return (
    <>
      <Snackbar
        key={snackbarKey}
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: true }}
      >
        <Alert
          sx={snackbarAlertsxProperty}
          onClose={handleCloseNotification}
          severity={snackbarAlertSeverity}
          variant="filled"
          elevation={6}
        >
          <AlertTitle>
            {snackbarTitle}
          </AlertTitle>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default SnackbarWrapper;
