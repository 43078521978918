import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IObjectSharingRequestTracker } from '../../../dataObjects/models/collaboration/ObjectSharingTracker';

// export interface IAcceptSharingRequestStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const sharingRequestAcceptanceStatusSlice = createSlice({
  name: 'sharingRequestAcceptanceStatus',
  initialState,
  reducers: {
    // The sharingRequestAcceptanceRequest triggers the saving of an ObjectSharingRequestTracker. It's not really associated with the Store State
    // for the 'saveObjectSharingRequestTrackerStatus' store variable; however, it is used to both trigger the ObjectSharingRequestTracker Save Request -AND-
    // to call the objectSharingRequestTrackerSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design pattern, the most likely alternative would require creating another Store State
    // variable (eg, 'saveObjectSharingRequestTrackerData') and create another slice with a objectSharingRequestTrackerSaveRequest action. 
    sharingRequestAcceptanceRequest: (state, action: PayloadAction<IObjectSharingRequestTracker>) => {
      sharingRequestAcceptanceStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an IObjectSharingRequestTracker object or null
    sharingRequestAcceptanceStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { sharingRequestAcceptanceRequest, sharingRequestAcceptanceStatusChange } = sharingRequestAcceptanceStatusSlice.actions;

// export the core reducer for the slice
export const sharingRequestAcceptanceStatusReducer = sharingRequestAcceptanceStatusSlice.reducer;

export default sharingRequestAcceptanceStatusSlice.reducer;