import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDigitalMediaSearchCriteria } from '../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchCriteria';

const initialState: IDigitalMediaSearchCriteria | null = null;

export const digitalMediaSearchCriteriaSlice = createSlice({
  name: 'digitalMediaSearchCriteria',
  initialState,
  reducers: {
    // action has a payload with IDigitalMediaSearchCriteria 
    digitalMediaSetSearchCriteria: (state: IDigitalMediaSearchCriteria | null, action) => {
      return action.payload;
    },
    digitalMediaClearSearchCriteria: (state: IDigitalMediaSearchCriteria | null) => {
      return null;
    },
  },
})

// Action creators are generated for each case reducer function
export const { digitalMediaSetSearchCriteria, digitalMediaClearSearchCriteria } = digitalMediaSearchCriteriaSlice.actions;

// export the core reducer for the slice
export const digitalMediaSearchCriteriaReducer = digitalMediaSearchCriteriaSlice.reducer;

export default digitalMediaSearchCriteriaSlice.reducer;
