import { put } from "redux-saga/effects";
import { saveImageLink } from "../../../firebaseServices/dataServices/dataServiceActions/imageLinkActions";
import { IImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import { ISaveImageLinkRequest } from '../../../dataObjects/models/serviceRequests/imageLink/SaveImageLinkRequest';
import { imageLinkSaveError, imageLinkSaveSuccess, imageLinkSaveWorkflowStateChange } from "../../slices/imageLink/imageLinkSaveStatusSlice";
import { ISaveImageLinkWorkflowState } from "../../store/SaveImageWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";

/**
 * @function saveImageLinkOnlyWorkerSaga A "worker" saga function that performs the work for a request to
 *   save an ImageLink only (without a file upload).
 * @param saveImageLinkRequest 
 */
export function* saveImageLinkOnlyWorkerSaga(saveImageLinkRequest: ISaveImageLinkRequest) {
  try {
    // // extract the ISaveImageLinkRequest from the action payload
    // const saveImageLinkRequest: ISaveImageLinkRequest | undefined = saveImageLinkRequest;

    // if the ISaveImageLinkRequest is defined...
    if (saveImageLinkRequest !== undefined) {

      // update store state...      
      const saveImageLinkWorkflowState: ISaveImageLinkWorkflowState = {
        imageLink: saveImageLinkRequest.imageLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.imageLink_SaveRequested,
        fileUploadRequest: undefined
      }
      yield put(imageLinkSaveWorkflowStateChange(saveImageLinkWorkflowState));

      // get the ImageLink from ISaveImageLinkRequest
      const imageLink: IImageLink | undefined = saveImageLinkRequest.imageLink;

      // if the ImageLink is defined...
      if (imageLink !== undefined) {
        // call services method to save the imageLink
        yield saveImageLink(imageLink)

        // dispatch an action to indicate that the save operation was successful
        yield put(imageLinkSaveSuccess(imageLink));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveImageLinkWorkflowState: ISaveImageLinkWorkflowState = {
          imageLink: saveImageLinkRequest.imageLink,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.imageLink_SaveSuccess,
        }

        saveImageLinkWorkflowState.workflowState = enumWorkflowState.Success;
        saveImageLinkWorkflowState.workflowStateMessage = MessagesStringAssets.imageLink_SaveSuccess;
        yield put(imageLinkSaveWorkflowStateChange(saveImageLinkWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(imageLinkSaveError(error));
  }
}