import { take } from "redux-saga/effects";
import { saveDocumentLinkOnlyWorkerSaga } from "./saveDocumentLinkOnlyWorkerSaga";
import { documentLinkSaveWithoutFileUploadRequest } from "../../slices/documentLink/documentLinkSaveStatusSlice";

/**
 * @function saveDocumentLinkOnlyWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an DocumentLink only (without a file upload).
 */
export function* saveDocumentLinkOnlyWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the documentLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveDocumentLinkRequestData = yield take(documentLinkSaveWithoutFileUploadRequest);
    const { payload: saveDocumentLinkRequest } = saveDocumentLinkRequestData;

    yield saveDocumentLinkOnlyWorkerSaga(saveDocumentLinkRequest);
  }
}