import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { ITopicItemMetadata, ITopicItemMetadataAsJson } from '.';
import { ILocaleTranslation, LocaleTranslation } from '../../locale/LocaleTranslation';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';

/** 
 * @class TopicItemMetadata (complete the description)
 */
export class TopicItemMetadata extends VersionAwarePersistable implements ITopicItemMetadata {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {Array<ILocaleTranslation>} titleTranslations Translations for the TopicItem Name
   * @param {Array<ILocaleTranslation>} descriptionTranslations Translations for the TopicItem Name
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userPersistenceData?: IUserPersistenceData,
    titleTranslations?: Array<ILocaleTranslation<string>>,
    descriptionTranslations?: Array<ILocaleTranslation<string>>,
  ) {
    super(ownerId, enumPersistableObjectClassName.TopicItemMetadata, enumPersistableObjectType.TopicItemMetadata, id, parentObjectType, parentId, objectState, userPersistenceData);

    if (titleTranslations) {
      this._titleTranslations = titleTranslations;
    }

    if (descriptionTranslations) {
      this._descriptionTranslations = descriptionTranslations;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {Array<LocaleTranslation>} _titleTranslations Translations for the TopicItem name
   */
  private _titleTranslations: Array<ILocaleTranslation<string>> = [];

  /**
   * @method titleTranslations is an optional getter method for _titleTranslations
   */
  get titleTranslations() {
    return this._titleTranslations;
  }

  /**
   * @method titleTranslations is an optional setter method for _titleTranslations
   * @param {Array<LocaleTranslation>} value is the input value for setting _titleTranslations
   */
  set titleTranslations(value) {
    this._titleTranslations = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<LocaleTranslation>} _descriptionTranslations Translations for the TopicItem description
   */
  private _descriptionTranslations: Array<ILocaleTranslation<string>> = [];

  /**
   * @method descriptionTranslations is an optional getter method for _descriptionTranslations
   */
  get descriptionTranslations() {
    return this._descriptionTranslations;
  }

  /**
   * @method descriptionTranslations is an optional setter method for _descriptionTranslations
   * @param {Array<LocaleTranslation>} value is the input value for setting _descriptionTranslations
   */
  set descriptionTranslations(value) {
    this._descriptionTranslations = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ITopicItemMetadata} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ITopicItemMetadata {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ITopicItemMetadata = Object.create(TopicItemMetadata.prototype);
    Object.assign(copyOfObject, this);

    // copy each of the translation arrays
    if (this.titleTranslations !== undefined) {
      copyOfObject.titleTranslations = new Array<ILocaleTranslation<string>>();
      this.titleTranslations.forEach(titleTranslation => {
        copyOfObject.titleTranslations.push(titleTranslation.copy());
      })
    }

    if (this.descriptionTranslations !== undefined && this.descriptionTranslations.length > 0) {
      copyOfObject.descriptionTranslations = new Array<ILocaleTranslation<string>>();
      this.descriptionTranslations.forEach(descriptionTranslation => {
        copyOfObject.descriptionTranslations.push(descriptionTranslation.copy());
      })
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON converts a TopicItemMetadata object to JSON -- is automatically used by JSON.stringify
   * This method is provided both to serialize the object to include property names without the leading underscore '_'
   * character AND to convert data types that may not properly serialize/deserialize using their default formats --
   * Date is an example of that.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ITopicItemMetadataAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: ITopicItemMetadataAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.titleTranslations = LocaleTranslation.toJSONArray(this._titleTranslations);
      jsonObject.descriptionTranslations = LocaleTranslation.toJSONArray(this._descriptionTranslations);

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ITopicItemMetadataAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ITopicItemMetadataAsJson, includeContainedObjects: boolean = true): ITopicItemMetadata {
    try {
      // create a new instance of this class
      let topicItemMetadataObject: TopicItemMetadata = Object.create(TopicItemMetadata.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      topicItemMetadataObject = super.fromJSONProtected(topicItemMetadataObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.titleTranslations !== undefined) {
        topicItemMetadataObject.titleTranslations = JsonConverter.arrayFromJSONArray(LocaleTranslation, jsonObject.titleTranslations);
      }

      if (jsonObject.descriptionTranslations !== undefined) {
        topicItemMetadataObject.descriptionTranslations = JsonConverter.arrayFromJSONArray(LocaleTranslation, jsonObject.descriptionTranslations);
      }

      return topicItemMetadataObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/
}
