import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const topicDeleteFailureSlice = createSlice({
  name: 'topicDeleteFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    topicDeleteFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    topicDeleteFailureClear(state) {
      return null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { topicDeleteFailureChange, topicDeleteFailureClear } = topicDeleteFailureSlice.actions;

// export the core reducer for the slice
export const topicDeleteFailureReducer = topicDeleteFailureSlice.reducer;

export default topicDeleteFailureSlice.reducer;