import { collection, DocumentData, Firestore, onSnapshot, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames, enumFirestoreDataRepositoryDataType } from '../enums';
import { IFirestoreUserRepository } from '../FirestoreUserRepository';
import { FirestoreDataRepositoryFactory } from '../FirestoreDataRepositoryFactory';
import { IFirestoreBaseRepository } from '../FirestoreBaseRepository';
import { IUser, IUserAsJson } from '../../../../../../dataObjects/models/users/User';
import { enumObjectPersistenceState } from '../../../../../../dataObjects/enums';
import { IFirestoreUserCategoriesRepository_Ext } from '.';
import { MdbError } from '../../../../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../../../../errorObjects/enums';

/** 
 * @class FirestoreUserCategoriesRepository_Ext Provides extended functionality beyond the FirestoreUserCategoriesRepository class
 * *** IMPORTANT NOTE: FirestoreUserCategoriesRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestoreUserCategoriesRepository_Ext is focused on other features.
 *   Therefore, 
 *     1) FirestoreUserCategoriesRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreUserCategoriesRepository_Ext.
 */
export class FirestoreUserCategoriesRepository_Ext implements IFirestoreUserCategoriesRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method getUserCategoriesForUser_onSnapshot Makes an onSnapshot() request to firestore for retrieving all UserCategories from the database associated with 
   *   a given User. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {typeUniqueId} userId The unique Id of the User of the objects to retrieve.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is able to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getUserCategoriesForUser_onSnapshot(userId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };
        // console.log(`FirestoreUserCategoriesRepository:getUserCategoriesForUser_onSnapshot before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // get a User repository from the repository factory
        const firestoreUserRepository: IFirestoreUserRepository =
          FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
          IFirestoreBaseRepository<IUser, IUserAsJson>;

        const user: IUser | undefined = await firestoreUserRepository.get(userId);

        if (user === undefined) {
          throw new MdbError(`Unable to find a User with the given Id: ${userId}`, enumMdbErrorType.RequiredDataNotFound);
        }
        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the UserCategories collection
        const userCategoriessCollection = collection(firestoreDb, enumFirestoreCollectionNames.UserCategoriesCollection);

        // prepare a query that will get all UserCategories documents where the userId appears in the UserCategories's memberUsers array -and- the UserCategories
        // is in either the 'New' or 'Modified' state
        // const query: Query<DocumentData> = userCategoriessCollection
        //   .where("parentId", "==", userId)
        //   .where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified]);

        const documentQuery: Query<DocumentData> =
          query(userCategoriessCollection,
            where("parentId", "==", userId),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        // unsubscribeCallback = await documentQuery.onSnapshot(callback);
        unsubscribeCallback = await onSnapshot(documentQuery, callback);
        // await this.sleep(2000);
        // console.log(`FirestoreUserCategoriesRepository:getUserCategoriesForUser_onSnapshot after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // return the 'unsubscribeCallback' with the Promise, via the 'resolve'
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }

  /*-----------------------------------------------*/
  /**
   * @method getUserCategoriesForChannel_onSnapshot Makes an onSnapshot() request to firestore for retrieving all UserCategories from the database associated with 
   *   a given Channel, per it's Id. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {typeUniqueId} userId The unique Id of the User of the objects to retrieve.
   * @param {typeUniqueId} channelId The unique Id of the Channel to search for as a child of a Category owned by the user.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is able to receive 
   *   a snapshot of data from firestore.
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getUserCategoriesForUserAndChannel_onSnapshot(userId: typeUniqueId, channelId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };
        // console.log(`FirestoreUserCategoriesRepository:getUserCategoriesForUser_onSnapshot before initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // get a User repository from the repository factory
        const firestoreUserRepository: IFirestoreUserRepository =
          FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
          IFirestoreBaseRepository<IUser, IUserAsJson>;

        const user: IUser | undefined = await firestoreUserRepository.get(userId);

        if (user === undefined) {
          throw new MdbError(`Unable to find a User with the given Id: ${userId}`);
        }
        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the UserCategories collection
        const userCategoriessCollection = collection(firestoreDb, enumFirestoreCollectionNames.UserCategoriesCollection);

        // prepare a query that will get all UserCategories documents owned by the user provided (userId) and where the channelId appears in the 
        // UserCategory's children array -and- the UserCategories is in either the 'New' or 'Modified' state
        // const query: firebase.firestore.Query<DocumentData> = userCategoriessCollection
        //   .where("ownerId", "==", userId)
        //   .where("children", "array-contains", channelId)
        //   .where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified]);

        const documentQuery: Query<DocumentData> =
          query(userCategoriessCollection,
            where("ownerId", "==", userId),
            where("children", "array-contains", channelId),
            where("objectState", "in", [enumObjectPersistenceState.New, enumObjectPersistenceState.Modified])
          );

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        // unsubscribeCallback = await query.onSnapshot(callback);
        unsubscribeCallback = await onSnapshot(documentQuery, callback);
        // await this.sleep(2000);
        // console.log(`FirestoreUserCategoriesRepository:getUserCategoriesForUser_onSnapshot after initiating snapshot, unsubscribeCallback: ${unsubscribeCallback}`);

        // return the 'unsubscribeCallback' with the Promise, via the 'resolve'
        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }

}
