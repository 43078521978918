import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { listViewStyles } from '../../../styles';
import SharingRequestsListViewItem from './SharingRequestsListViewItem';
import { ISharingRequestViewModel } from '../../../../dataObjects/viewModels/sharingRequestViewModel';


export interface ISharingRequestsListViewProps extends PropsWithChildren<unknown> {
  sharingRequestViewModels: Array<ISharingRequestViewModel>;
  onAccept: (sharingRequestViewModel: ISharingRequestViewModel) => void;
  onDecline: (sharingRequestViewModel: ISharingRequestViewModel) => void;
}

export const SharingRequestsListView: React.FC<ISharingRequestsListViewProps> = (props: ISharingRequestsListViewProps) => {
  SharingRequestsListView.displayName = 'SharingRequests List View';

  // eslint-disable-next-line no-console
  // console.info(`Render SharingRequestsListView`);

  const classes: Record<string, string> = listViewStyles();

  const { sharingRequestViewModels, onAccept, onDecline } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and three data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
          - Data Row 3: Requestor / Date & Time
      */}

      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='nameDescriptionAndRequestorLabel' item xs={12} md={10} >
            <Typography variant='h6'>
              Name / Description / Requestor
            </Typography>
          </Grid>
          <Grid item container md={2} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='acceptLabel' item container className={classes.actionButtonLabelContainer} md={6} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Accept
                </Typography>
              </Box>
            </Grid>
            <Grid key='declineLabel' item container className={classes.actionButtonLabelContainer} md={6} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Decline
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          sharingRequestViewModels.map((sharingRequestViewModel, idx) => (
            <SharingRequestsListViewItem
              key={sharingRequestViewModel.sharingRequest.id}
              sharingRequestViewModel={sharingRequestViewModel}
              onAccept={onAccept}
              onDecline={onDecline}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default SharingRequestsListView;