import { IFirestoreNoteRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreNoteRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { INote, INoteAsJson } from "../../../../dataObjects/models/digitalMedia/Note";


export function saveNote(note: INote): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Note repository from the repository factory
      const firestoreNoteRepository: IFirestoreNoteRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Note) as
        IFirestoreBaseRepository<INote, INoteAsJson>;

      // determine if the note already exists by calling the repository get() method to try and get the object
      const existingNote: INote | undefined = await firestoreNoteRepository.get(note.id);

      // If the note wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingNote === undefined) {
        // call repository method to create the note
        await firestoreNoteRepository.create(note);
      } else {
        // call repository method to update the note
        await firestoreNoteRepository.update(note);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}