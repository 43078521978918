import { IBaseDbContext } from ".";

/**
 * @class BaseDbContext Serves as the base Database Context
 */
export abstract class BaseDbContext<DbType> implements IBaseDbContext<DbType> {
  /**
   * @method Constructor method
   * @param {string} dbName The name of the database
   * @param {DbType} dbReference A reference to the underlying database
   */
  constructor(dbName: string, dbReference: DbType) {
    this.dbName = dbName;
    this._dbReference = dbReference;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _dbName The name of the database
   */
  private _dbName: string = '';

  /**
   * @method dbName A getter method for the database name
   */
  get dbName(): string {
    return this._dbName;
  }

  /**
   * @method dbName is a setter method for the database name
   * @param {string} value The name change for the database
   */
  set dbName(value: string) {
    this._dbName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {DbType} _dbReference A reference to the underlying database
   */
  private _dbReference: DbType;

  /**
   * @method connectionConfig A getter method for the underlying database
   */
  get dbReference(): DbType {
    return this._dbReference;
  }

  /**
   * @method dbReference is a setter method for the underlying database
   * @param {any} value A new value for the underlying database
   */
  set dbReference(value: DbType) {
    this._dbReference = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method initialize Initializes the database connection, if needed.
   * No initialization is performed in this BaseDbContext class.
   */
  initialize(): void {

  }
  /*-----------------------------------------------*/

  /**
   * @method validateDbType Validates whether the embedded dbReference property holds
   * an instance of a given type.
   * @param {any} type The type to which the dbReference object will be compared
   * @returns {boolean} Whether the dbReference property holds an object of the given type
   */


}
