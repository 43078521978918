import { enumThemeMode } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumThemeModeConvert Provides to/from string conversions for enumThemeMode values.
 */
export class enumThemeModeConvert {
  public static toString(themeModeEnum: enumThemeMode): string {
    return themeModeEnum.toString();
  }

  public static fromString(themeModeString: string): enumThemeMode {
    let themeModeEnum: enumThemeMode = enumThemeMode.Light;

    switch (themeModeString) {
      case enumThemeModeConvert.toString(enumThemeMode.Light):
        themeModeEnum = enumThemeMode.Light;
        break;

      case enumThemeModeConvert.toString(enumThemeMode.Dark):
        themeModeEnum = enumThemeMode.Dark;
        break;

      default:
        throw new MdbError(`enumThemeModeConvert.fromString. Unrecognized/unsupported enumThemeMode string: ${themeModeString}`, enumMdbErrorType.UnsupportedFeature);
    }

    return themeModeEnum;
  }
}