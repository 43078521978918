import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: enumWorkflowState | null = null;

export const searchMetadataPopulateStatusSlice = createSlice({
  name: 'searchMetadataPopulateStatus',
  initialState,
  reducers: {
    // action has a payload with the new enumWorkflowState value or null
    searchMetadataPopulateStatusChange(state, action) {
      // change status of the populate search metadata process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { searchMetadataPopulateStatusChange } = searchMetadataPopulateStatusSlice.actions;

// export the core reducer for the slice
export const searchMetadataPopulateStatusReducer = searchMetadataPopulateStatusSlice.reducer;

export default searchMetadataPopulateStatusSlice.reducer;