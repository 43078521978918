import { Firestore } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { FirestoreCategoryRepository_Ext, IFirestoreCategoryRepository, IFirestoreCategoryRepository_Ext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository";
import { ICategory, ICategoryAsJson } from "../../../../dataObjects/models/categories/Category";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";


/**
 * @function removeChannelAsChildFromAllCategories Removes the Channel as a child from all Category objects. This is an 
 *                                                     operation that will be performed when a Channel is being deleted.
 * @param {typeUniqueId} channelId The Id of the Channel that will be removed as a child from all Category objects for the given user
 */
export function removeChannelAsChildFromAllCategories(channelId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      
      // get a Category repository from the repository factory
      const firestoreCategoryRepository: IFirestoreCategoryRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
        IFirestoreBaseRepository<ICategory, ICategoryAsJson>;

      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      const firestoreCategoryRepository_Ext: IFirestoreCategoryRepository_Ext = new FirestoreCategoryRepository_Ext(firestoreObj);

      // get any Category records that have the channel as a child 
      const categoriesForOwnerIdWithChannelId: Array<ICategory> | undefined = await firestoreCategoryRepository_Ext.getAllCategoriesWithChannelAsChild(channelId);

      // determine if any Category records met the criteria
      if (categoriesForOwnerIdWithChannelId !== undefined && categoriesForOwnerIdWithChannelId.length > 0) {
        // traverse the records and remove the channel from any Category records, except for the Category to that will have the Channel as a child
        categoriesForOwnerIdWithChannelId.forEach(async (category: ICategory, idxCategory: number) => {
          // remove the one element at the index from the array
          category.removeChild(channelId);

          // call repository method to update this Category that has had the Channel child removed from it
          await firestoreCategoryRepository.update(category);
        })
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}