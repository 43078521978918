import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { IVideoLink } from '../../../../dataObjects/models/digitalMedia/VideoLink';
import { listViewStyles } from '../../../styles';
import VideoLinksListViewItem from './VideoLinksListViewItem';
import { IVideoLinkViewModel } from '../../../../dataObjects/viewModels/videoLinkViewModel';


export interface IVideoLinksListViewProps extends PropsWithChildren<unknown> {
  videoLinkViewModels: Array<IVideoLinkViewModel>;
  onDelete: (VideoLink: IVideoLink) => void;
}

export const VideoLinksListView: React.FC<IVideoLinksListViewProps> = (props: IVideoLinksListViewProps) => {
  VideoLinksListView.displayName = 'VideoLinks List View';

  // eslint-disable-next-line no-console
  // console.info(`Render VideoLinksListView`);

  const classes: Record<string, string> = listViewStyles();

  const { videoLinkViewModels, onDelete } = props;

  return (
    <>
      {/* overall grid for list. There will be a header row and either one or two data rows, based on a media query: 
          - Header Row
          - Data Row 1: Video & Action Buttons (also, the Description for larger screen sizes)
          - Data Row 2: Description (this row is only shown for smaller screen sizes)
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='nameAndDescriptionLabel' item xs={12} md={10} >
            <Typography variant='h6'>
              Video / Description
            </Typography>
          </Grid>
          <Grid item container md={2} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='editLabel' item container className={classes.actionButtonLabelContainer} md={6} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' item container className={classes.actionButtonLabelContainer} md={6} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          videoLinkViewModels.map((videoLinkViewModel, idx) => (
            <VideoLinksListViewItem
              key={videoLinkViewModel.videoLink.id}
              videoLinkViewModel={videoLinkViewModel}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default VideoLinksListView;