import { take } from "redux-saga/effects";
import { saveSocialMediaPostWorkerSaga } from "./saveSocialMediaPostWorkerSaga";
import { socialMediaPostSaveRequest } from "../../slices/socialMediaPost/socialMediaPostSaveStatusSlice";

/**
 * @function saveSocialMediaPostWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save a SocialMediaPost.
 */
export function* saveSocialMediaPostWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the socialMediaPostSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveSocialMediaPostRequestData = yield take(socialMediaPostSaveRequest);
    const { payload: saveSocialMediaPostRequest } = saveSocialMediaPostRequestData;

    yield saveSocialMediaPostWorkerSaga(saveSocialMediaPostRequest);
  }
}