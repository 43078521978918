import React, { PropsWithChildren } from 'react';
import Grid from '@mui/material/Grid';
import { ITopic } from '../../../../dataObjects/models/topics/Topic';
import { TopicsCardGridViewItem } from './TopicsCardGridViewItem';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';


export interface ITopicsCardGridViewProps extends PropsWithChildren<unknown> {
  topics: Array<ITopic>;
  currentUser: IUser;
  channel: IChannel;
  onDelete: (topic: ITopic) => void;
}


export const TopicsCardGridView: React.FC<ITopicsCardGridViewProps> = (props: ITopicsCardGridViewProps) => {
  TopicsCardGridView.displayName = 'Topics Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render TopicsList`);

  const { topics, currentUser, channel, onDelete } = props;

  const userPermissions : IUserAccessPermissionsForObject = new UserAccessPermissionsForObject(currentUser.id, channel);

  return (
    <>
      <Grid container spacing={2} >
        {
          // only display topics if there is a currentUser and a channel
          currentUser && channel && 
          topics.map(topic =>
            <Grid item key={topic.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <TopicsCardGridViewItem topic={topic} userPermissions={userPermissions} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}