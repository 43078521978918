import { IFirestoreImageLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreImageLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IImageLink, IImageLinkAsJson } from "../../../../dataObjects/models/digitalMedia/ImageLink";


export function deleteImageLink(imageLink: IImageLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a ImageLink repository from the repository factory
      const firestoreImageLinkRepository: IFirestoreImageLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.ImageLink) as
        IFirestoreBaseRepository<IImageLink, IImageLinkAsJson>;

      // call the repository delete() method to delete the object
      await firestoreImageLinkRepository.delete(imageLink.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}