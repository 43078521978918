import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IUser } from '../../../dataObjects/models/users/User';

const initialState: enumWorkflowState | null = null;

export const channelsFetchStatusSlice = createSlice({
  name: 'channelsFetchStatus',
  initialState,
  reducers: {
    // action has a payload with IUser
    channelsFetchRequest: (state, action: PayloadAction<IUser>) => {
      channelsFetchStatusChange(enumWorkflowState.Requested);
    },
    channelsFetchStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { channelsFetchRequest, channelsFetchStatusChange } = channelsFetchStatusSlice.actions;

// export the core reducer for the slice
export const channelsFetchStatusReducer = channelsFetchStatusSlice.reducer;

export default channelsFetchStatusSlice.reducer;