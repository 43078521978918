import { enumVideoLinkType } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumVideoLinkTypeConvert Provides to/from string conversions for enumVideoLinkType values.
 */
export class enumVideoLinkTypeConvert {
  public static toString(videoLinkTypeEnum: enumVideoLinkType): string {
    return videoLinkTypeEnum.toString();
  }

  public static fromString(videoLinkTypeString: string): enumVideoLinkType {
    let videoLinkTypeEnum: enumVideoLinkType = enumVideoLinkType.GoogleCloudStorage;

    switch (videoLinkTypeString) {
      case enumVideoLinkTypeConvert.toString(enumVideoLinkType.GoogleCloudStorage):
        videoLinkTypeEnum = enumVideoLinkType.GoogleCloudStorage;
        break;

      case enumVideoLinkTypeConvert.toString(enumVideoLinkType.ExternalSource):
        videoLinkTypeEnum = enumVideoLinkType.ExternalSource;
        break;

      default:
        throw new MdbError(`enumVideoLinkTypeConvert.fromString. Unrecognized/unsupported enumVideoLinkType string: ${videoLinkTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return videoLinkTypeEnum;
  }
}