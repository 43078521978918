import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { IFirestoreTopicItemRepository, FirestoreTopicItemRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicItemRepository";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreDbContext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDbContext";


export function getTopicItemsForParentId_onSnapshot(parentId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // instantiate a FirestoreDbContext
      const dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);

      const firestoreTopicItemRepository: IFirestoreTopicItemRepository = new FirestoreTopicItemRepository(dbContext);

      // fetch the topics for the pertinent channelId
      const unsubscribeCallback: () => void = await firestoreTopicItemRepository.getAllForParent_onSnapshot(parentId, callback);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}