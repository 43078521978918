import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { ControlsStringAssets, MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { useProcessPerManageObjectPageStatus } from '../../customHooks';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { clearAlertInfo, setAlertInfo, updateUserEmailAddressRequested } from '../../../uiMiddleware-redux/actions';
import { updateUserEmailAddressStatusReset } from '../../../uiMiddleware-redux/actions/user/updateUserEmailAddressStatusReset';
import TwoButtonAcceptanceDialog from '../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { composeMessageUsingStringAsset } from '../../messages';
import EmailEntryForm from '../../forms/EmailEntryForm/EmailEntryForm';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { IUserAndEmailAddress } from '../../../dataObjects/models/users/UserAndEmailAddress';
import { AlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { userUpdateEmailAddressStatusChange } from '../../../uiMiddleware-redux/slices/user/userUpdateEmailAddressStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { userUpdateEmailAddressDataClear, userUpdateEmailAddressRequest } from '../../../uiMiddleware-redux/slices/user/userUpdateEmailAddressDataSlice';


/**
 * @interface IUpdateEmailAddressPageProps Input properties for the UpdateEmailAddressPage
 */
export interface IUpdateEmailAddressPageProps extends PropsWithChildren<unknown> {
}

const UpdateEmailAddressPage: React.FC<IUpdateEmailAddressPageProps> = (props: IUpdateEmailAddressPageProps) => {

  UpdateEmailAddressPage.displayName = "Update Email Address Page";

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();

  // the new email address to be assigned to the current user
  const [newEmailAddress, setNewEmailAddress] = useState<string | undefined>(undefined);

  // whether to display a confirmation dialog for updating the user's email address
  const [showUpdateEmailAddressConfirmationDlg, setShowUpdateEmailAddressConfirmationDlg] = useState<boolean>(false);

  const resetUpdateUserEmailAddressStatus: () => void = useCallback(() => {
    dispatch(userUpdateEmailAddressStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetUpdateUserEmailAddressStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetUpdateUserEmailAddressStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetUpdateUserEmailAddressStatus]);

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.updateUserEmailAddressStatus,
    failureErrorStateObj: (state: IStoreState) => state.updateUserEmailAddressFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.user_UpdateUserEmailAddressFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_UpdateUserEmailAddress,
    notificationSuccessMessage: MessagesStringAssets.user_UpdateUserEmailAddressSuccess,
  }


  // if there is defined email address, append the email address to the message for success
  if (newEmailAddress !== undefined) {
    console.log(`UpdateEmailAddressPage. 'newEmailAddress' IS defined: ${newEmailAddress}`);
    processPerManageObjectPageStatusInput.notificationSuccessMessage = composeMessageUsingStringAsset(MessagesStringAssets.user_UpdateUserEmailAddressSuccess, newEmailAddress, MessagesStringAssets.substitutionKeyword);
  } else {
    console.log(`UpdateEmailAddressPage. 'newEmailAddress' is NOT yet defined.`);
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  // function that embedded form component is to call when submitting for an email update operation
  function handleFormSubmit(newEmailAddress: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(`UpdateEmailAddressPage. Entered handleFormSubmit(). newEmailAddress: ${newEmailAddress}`);

        setNewEmailAddress(newEmailAddress);

        // set flag to show the Expunge User Account Confirmation dialog
        setShowUpdateEmailAddressConfirmationDlg(true);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // function that embedded form component is to call when canceling an email update operation
  function handleFormCancel(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        console.log(`UpdateEmailAddressPage. Entered handleFormCancel().`);

        // navigate back to page that led to this page
        navigate(-1);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  const handleUpdateUserEmailAddressConfirmed = () => {
    console.log(`UpdateEmailAddressPage. Entered handleUpdateUserEmailAddressConfirmed(). email address of account to be expunged: ${newEmailAddress}`);

    if (currentUserContextData && currentUserContextData.currentUser && newEmailAddress) {
      // dispatch an action to request updating the email address of the user account
      const userAndNewEmailAddress: IUserAndEmailAddress = {
        user: currentUserContextData.currentUser,
        emailAddress: newEmailAddress
      }
      if (newEmailAddress.toLowerCase() !== currentUserContextData.currentUser.userSettings.email.toLowerCase()) {
        dispatch(userUpdateEmailAddressRequest(userAndNewEmailAddress));
      } else {
        // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
        const alertInfo = new AlertInfo(true, enumAlertType.Error, MessagesStringAssets.user_UpdateUserEmailAddressRequiresDifferentEmail);
        dispatch(alertInfoChange(alertInfo));
      }
    }

    // call method to reset the local state details for hiding/showing the Expunge User Account Confirmation dialog
    updateUserEmailAddressConfirmationReset();
  }

  function handleUpdateUserEmailAddressCanceled(): void {
    // call method to reset the local state details for hiding/showing the Expunge User Account Confirmation dialog
    updateUserEmailAddressConfirmationReset();

    // clear User Update Email Address data from redux state
    clearUserUpdateEmailAddressDataFromReduxState();
  }

  function updateUserEmailAddressConfirmationReset() {
    // set flag to hide the delete confirmation dialog
    setShowUpdateEmailAddressConfirmationDlg(false);

    // clear User Update Email Address data from redux state
    clearUserUpdateEmailAddressDataFromReduxState()
  }

  function clearUserUpdateEmailAddressDataFromReduxState() {
    // clear data from redux state, both the user email and the process status
    dispatch(userUpdateEmailAddressDataClear());
    dispatch(userUpdateEmailAddressStatusChange(null));
  }


  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={PageAndViewTitleStringAssets.pageTitle_UpdateUserEmailAddress}
      onCloseAlert={resetUpdateUserEmailAddressStatus}
    >
      <EmailEntryForm
        emailAddress=''
        actionInProgress={operationInProgress}
        actionInProgressLabel={MessagesStringAssets.user_UpdateUserEmailAddressRequested}
        instructions={`Enter your NEW email address in the field below and press the 'Submit' button.`}
        onSubmit={handleFormSubmit}
        onCancel={handleFormCancel}
      />

      {/* Confirm Re-sending Account Verification Email Confirmation Dialog */}
      <TwoButtonAcceptanceDialog
        showDialog={showUpdateEmailAddressConfirmationDlg}
        headerText={MessagesStringAssets.user_UpdateUserEmailAddressConfirmationHeader}
        bodyText={composeMessageUsingStringAsset(MessagesStringAssets.user_UpdateUserEmailAddressConfirmation, newEmailAddress, MessagesStringAssets.substitutionKeyword)}
        acceptanceButtonText={ControlsStringAssets.confirmButtonText}
        nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
        onAcceptance={handleUpdateUserEmailAddressConfirmed}
        onNonAcceptance={handleUpdateUserEmailAddressCanceled}
      />

    </GenericPageContainer>
  )

}

export default UpdateEmailAddressPage;