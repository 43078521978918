import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchDocumentLinksStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchDocumentLinksStatusSlice = createSlice({
  name: 'fetchDocumentLinksStatus',
  initialState,
  reducers: {
    setFetchDocumentLinksStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchDocumentLinksStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchDocumentLinksStatus, clearFetchDocumentLinksStatus } = fetchDocumentLinksStatusSlice.actions;

// export the core reducer for the slice
export const fetchDocumentLinksStatusReducer = fetchDocumentLinksStatusSlice.reducer;

export default fetchDocumentLinksStatusSlice.reducer;