import React, { PropsWithChildren } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Close as DeclineIcon,
  DoneOutline as AcceptanceIcon,
} from '@mui/icons-material';
import { listViewItemStyles } from '../../../styles';
import { enumSharableObjectType } from '../../../../dataObjects/enums';
import { ISharingRequestViewModel } from '../../../../dataObjects/viewModels/sharingRequestViewModel';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';


export interface SharingRequestsListViewItemProps extends PropsWithChildren<unknown> {
  sharingRequestViewModel: ISharingRequestViewModel;
  onAccept: (sharingRequestViewModel: ISharingRequestViewModel) => void;
  onDecline: (sharingRequestViewModel: ISharingRequestViewModel) => void;
}

export const SharingRequestsListViewItem: React.FC<SharingRequestsListViewItemProps> = (props: SharingRequestsListViewItemProps) => {
  SharingRequestsListViewItem.displayName = 'SharingRequests List View Item';

  // eslint-disable-next-line no-console
  // console.info(`Render SharingRequestsListViewItem`);

  const classes: Record<string, string> = listViewItemStyles();

  const { sharingRequestViewModel, onAccept, onDecline } = props;
  const { sharingRequest, sharingRequestObject } = sharingRequestViewModel;
  const { id, sharingObjectType } = sharingRequest;

  // set the name and description to display, based on the sharingObjectType
  let name: string = '';
  let description: string = '';
  switch (sharingObjectType) {
    case enumSharableObjectType.Channel:
      name = sharingRequestObject.name;
      description = sharingRequestObject.description;
      break;

    default:
      throw new MdbError('unknown sharingObjectType', enumMdbErrorType.InvalidOperation);
  }

  return (
    <>
      {/* Table data rows: 
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
          - Data Row 3: Owner
      */}

      <div className={classes.tableRow} key={id}>
        {/* Data Row 1 for Medium & Small Sizes: Image & Action Buttons. */}
        {/* Data Row 1 for Larger Sizes: Image, Description & Action Buttons */}
        <Grid item container xs={12} direction="row" >
          {/* Name & Action Buttons */}
          {/* Name */}
          <Grid item container xs={8} sm={10} >
            <Typography className={classes.title} variant="h6">
              {name}
            </Typography>
          </Grid>

          {/* Action Buttons */}
          <Grid item container xs={4} sm={2} direction="row" justifyContent="center" alignContent="flex-start">
            {/* Accept button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              <Tooltip title="Accept" arrow>
                <IconButton
                  size="small"
                  aria-label="accept"
                  className={`${classes.actionButton} ${classes.editIconButton}`}
                  onClick={() => sharingRequestViewModel && onAccept(sharingRequestViewModel)}
                >
                  <AcceptanceIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* Delete button */}
            <Grid item container className={classes.actionButtonContainer} xs={6} >
              <Tooltip
                title="Decline"
                arrow
              >
                <span>
                  <IconButton
                    size="small"
                    className={`${classes.actionButton} ${classes.deleteIconButton}`}
                    onClick={() => sharingRequestViewModel && onDecline(sharingRequestViewModel)}
                  >
                    <DeclineIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        {/* Data Row 2: Description  */}
        <Grid item container xs={12} >
          <Typography className={classes.description} variant="body1">
            {description}
          </Typography>
        </Grid>


        {/* Data Row 3: Owner  */}
        <Grid item container xs={12} >
          <Typography variant="body2">
            {`Requestor: ${sharingRequest.requestorName}`}
            </Typography>
        </Grid>

        {/* Add a dividing line after each item */}
        <Divider />
      </div>
    </>
  );

}

export default SharingRequestsListViewItem;