import { Auth, getAuth, onAuthStateChanged, Unsubscribe, User as FirebaseUser, connectAuthEmulator } from 'firebase/auth';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { CurrentUserContext, ICurrentUserContextData } from '.';
import { enumPersistableObjectType } from '../../../dataObjects/enums';
import { IUser, IUserAsJson, User } from '../../../dataObjects/models/users/User';
import { JsonConverter } from '../../../dataObjects/utilities/JsonConverter';
import { FirebaseAppSingleton } from '../../../firebaseServices/cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { getSingleObjectById_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumAuthenticationStatus } from '../../enums';
import { FirebaseEmulatorSettings } from '../../../firebaseServices/cloudServices/googleFirebaseServices/FirebaseConfigurationVariables';

/**
 * @interface ICurrentUserProviderProps declares any input properties required for this component.
 */
export interface ICurrentUserProviderProps extends PropsWithChildren<unknown> {
}

/**
 * @provider CurrentUserProvider A React Provider component that is based on the CurrentUserContext and can be used to provide a 
 *   React component tree embedded in the provider with information for the current user. The value served up by the provider can 
 *   either be an object 
 *   based on the ICurrentUserContext interface or an 'undefined' value, defaulting to 'undefined'.
 */
export const CurrentUserProvider: FC<ICurrentUserProviderProps> = (props: ICurrentUserProviderProps) => {

  CurrentUserProvider.displayName = "Current User Provider";

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('Entered/Refreshed CurrentUserProvider component');

  // define default unsubscribe callback function definition (an empty function that returns 'void')
  const defaultUnsubscribeCallback: () => void = () => { };

  // Define another default unsubscribe callback function definition that is a function which points to an empty function. 
  // This is the type of structure that is required to capture a function pointer in a useState variable.
  const defaultUnsubscribeCallbackForStateVariable: () => () => void = () => () => { displayConsoleLogs && console.log(`%c Default Unsubscribe Callback`, 'background: #00d; color: #fff'); };

  // fetch the 'children' property from the input properties
  const { children } = props;

  // State of authentication
  const [authenticationStatus, setAuthenticationStatus] = useState<enumAuthenticationStatus>(enumAuthenticationStatus.None);

  // The Id of a user that was just authenticated by Firebase, or 'unidentified' if authentication changed to no user (anonymous)
  const [newlyAuthenticatedUserId, setNewlyAuthenticatedUserId] = useState<string | undefined>(undefined);

  // When we initialize the newlyAuthenticatedUserId state variable (above) to undefined, that will trigger 
  // a useEffect hook that is dependent on newlyAuthenticatedUserId, with newlyAuthenticatedUserId set to undefined. 
  // We want to ignore the invocation of that useEffect hook for that initialization. Therefore, we will use a state variable 
  // to track when have had an initial call to that useEffect hook.
  const [firstTimeEvaluatingNewlyAuthenticatedUserId, setFirstTimeEvaluatingNewlyAuthenticatedUserId] = useState<boolean>(false);

  // The current user logged in via Firebase. This value will be 'undefined' if no user is logged in.
  // If a user has logged in, the value will remain 'undefined' until its data has been retrieved from the database.
  const [currentUser, setCurrentUser] = useState<IUser | undefined>(undefined);

  // Indicates whether the user is logging out. This additional flag helps us to determine if some activities should be 
  // skipped due to the user being in the midst of being logged out.
  const [userLoggingOut, setUserLoggingOut] = useState<boolean>(false);

  // Define a local state variable that will hold the unsubscribe callback for a snapshot request to get the
  // User record from the DB for the current user. The default case will be an empty (No Op) function.
  // NOTE: In order to store a function reference in a useState variable, we must actually store a function that calls a function. This is 
  //       because, if storing just a function referance, upon calling the 'setXXX' function of the useState variable, it will automatically call the 
  //       function right away, and the processing of this component will stop.
  const [unsubscribeCallbackForUserData, setUnsubscribeCallbackForUserData] = useState<() => () => void>(defaultUnsubscribeCallbackForStateVariable);

  // A useEffect hook that is launched upon the component startup
  useEffect(() => {
    // get an instance of the Firebase Auth object
    const firebaseAuth: Auth = getAuth(FirebaseAppSingleton.getInstance().firebaseApp);
        // if the environment is configured to use emulators and the auth emulator port is configured, connect to the emulator
        if (FirebaseEmulatorSettings.useEmulators) {
          if (FirebaseEmulatorSettings.authEmulatorPort) {
            connectAuthEmulator(firebaseAuth, `http://localhost:${FirebaseEmulatorSettings.authEmulatorPort}`);
          }
        }

    // set up a listener for Firebase user authentication state changes and capture the unsubscribe callback function
    let userAuthStateChangeUnsubscribe: Unsubscribe = onAuthStateChanged(firebaseAuth, onAuthStateChangedCallback);

    // logic to perform when unmounting the component
    return () => {
      // call unsubscribe callback
      userAuthStateChangeUnsubscribe();
    }
  });

  function onAuthStateChangedCallback(firebaseUser: FirebaseUser | null) {

    displayConsoleLogs && console.log(`%c Entered CurrentUserProvider.onAuthStateChangedCallback.`, 'background: #c00; color: #fff');

    let message: string = 'In CurrentUserProvider.onAuthStateChangedCallback. user is ';

    if (firebaseUser) {
      // if there is a non-null firebaseUser object passed into the method, it means that the user is being logged in.
      message += `defined. firebaseUser.uid: ${firebaseUser.uid}`;
      displayConsoleLogs && console.log(`%c ${message}`, 'background: #ddd; color: #030');

      // Since there is an active Firebase user, it tells us that the user is NOT logging out.
      // Thus, if the userLoggingOut local state flag is currently set to 'true', set it to 'false'
      displayConsoleLogs && console.log(`%c In CurrentUserProvider.onAuthStateChangedCallback (fireBaseUser DEFINED). Before setting userLoggingOut to false, userLoggingOut: ${userLoggingOut.toString()}`, 'background: #c00; color: #fff');
      if (userLoggingOut) {
        setUserLoggingOut(false);
      }

      // if the currentUser local state variable isn't yet set to a valid User object (is 'undefined') -OR- 
      // there is a currentUser defined -AND- the newly authenticated user's Id differs from that of the currentUser state variable,
      // then we are going to get the User data from the database.
      // NOTE: If the current user information hasn't changed, then the authentication process has already been completed for the user
      if (!currentUser || (currentUser && firebaseUser.uid !== currentUser.id)) {

        // set the Id of the newly authenticated user -- this will trigger a useEffect that will launch a snapshot query for user data
        if (newlyAuthenticatedUserId !== firebaseUser.uid) {
          displayConsoleLogs && console.log(`%c In CurrentUserProvider.onAuthStateChangedCallback. About to change newlyAuthenticatedUserId (${newlyAuthenticatedUserId}) to firebaseUser.uid (${firebaseUser.uid})`, 'background: #ddd; color: #030');
          setNewlyAuthenticatedUserId(firebaseUser.uid);
        }
      }
    } else {
      // since firebaseUser is not defined, it means that the user is being logged out
      message += 'not defined.';
      displayConsoleLogs && console.log(`%c ${message}`, 'background: #ddd; color: #030');

      // Set the userLoggingOut useState variable to indicate that we want to specify right away that the user is logging out.
      // We do that as the first useState variable setting in this block of code, so that a component refresh will affect this
      // variable first, reducing the number of refreshes before the logout process completes, in turn reducing the number of
      // database connections and queries that remain alive.
      displayConsoleLogs && console.log(`%c In CurrentUserProvider.onAuthStateChangedCallback. About to change userLoggingOut (${userLoggingOut}) to true`, 'background: #ddd; color: #030');
      if (!userLoggingOut) {
        setUserLoggingOut(true);
      }

      // reset set the Id of the newly authenticated user to 'undefined'
      if (newlyAuthenticatedUserId !== undefined) {
        displayConsoleLogs && console.log(`%c In CurrentUserProvider.onAuthStateChangedCallback. About to change newlyAuthenticatedUserId (${newlyAuthenticatedUserId}) to undefined`, 'background: #ddd; color: #030');
        setNewlyAuthenticatedUserId(undefined);
      }
    }
  }

  // a useEffect hook that responds to a change in user authentication from Firebase
  // If a new user has been authenticated (newlyAuthenticatedUserId !== undefined), launch a snapshot query to fetch user info
  // Otherwise, (newlyAuthenticatedUserId === undefined) it means that the existing user was logged off 
  useEffect(() => {

    displayConsoleLogs && console.log(`%c Entered CurrentUserProvider.useEffect for [newlyAuthenticatedUserId].`, 'background: #c00; color: #fff');

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request, and hold
    // it within the context of this useEffect hook.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackUserSnapshotQuery: () => void = defaultUnsubscribeCallback;

    // if this hook hasn't already been called/evaluated...
    if (!firstTimeEvaluatingNewlyAuthenticatedUserId) {
      // merely set the flag to specify that this handler has been called, so that we can handle the logic below
      // the next time this callback method is called
      displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. This useEffect hook is being called for the first time`, 'background: #c00; color: #fff');
      setFirstTimeEvaluatingNewlyAuthenticatedUserId(true);
    } else {

      if (newlyAuthenticatedUserId !== undefined) {

        // Since the 'newlyAuthenticatedUserId' variable has been changed to an actual UserId, we're going to
        // set the Authentication Status to indicate that authentication is in progress, if it's not already set.
        if (authenticationStatus !== enumAuthenticationStatus.AuthenticationInProgress) {
          displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. About to change authentication status (${authenticationStatus}) to AuthenticationInProgress`, 'background: #ddd; color: #030');
          setAuthenticationStatus(enumAuthenticationStatus.AuthenticationInProgress);
        }

        // subscribe to onShapshot updates for a User that has the given userId, providing realtime updates to the data, and 
        // capture the 'unsubscribe' callback method provided by firestore
        displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. About to call getSingleObjectById_onSnapshot()`, 'background: #ddd; color: #030');
        getSingleObjectById_onSnapshot(newlyAuthenticatedUserId, enumPersistableObjectType.User, onUserSnapshotCallback).then((unsubscribe: () => void) => {
          displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. Completed call to getSingleObjectById_onSnapshot()`, 'background: #ddd; color: #030');

          // capture the unsubscribe callback function so that we can unsubscribe from the snapshot query upon unmounting
          unsubscribeCallbackUserSnapshotQuery = unsubscribe;

          // Capture the unsubscribe callback function so that we can unsubscribe in the case that Current User changes without logging
          // out. Not sure that this is a viable use case (changing Current User), but it will be covered if it is viable.
          setUnsubscribeCallbackForUserData(() => unsubscribe);

          displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. Completed call to setUnsubscribeCallbackForUserData()`, 'background: #00d; color: #fff');

        }).catch(error => {
          displayConsoleLogs && console.error(`In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. Error after call to getSingleObjectById_onSnapshot() & setUnsubscribeCallbackForUserData(). \nError: ${error}`)
        });

      } else {
        // reset the currentUser to 'undefined' if it does not already have that value
        if (currentUser !== undefined) {
          setCurrentUser(undefined);

          // since there is no Current User, call the unsubscribe method associated with the snapshot for user data, since we don't
          // want to get updates for the user's data anymore. 
          displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId] with UNDEFINED newlyAuthenticatedUserId. Ready to call newUnsubscribeCallbackForUserData()`, 'background: #00d; color: #fff');
          unsubscribeCallbackForUserData();

          displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId] with UNDEFINED newlyAuthenticatedUserId. Ready to call setNewUnsubscribeCallbackForUserData()`, 'background: #00d; color: #fff');
          setUnsubscribeCallbackForUserData(defaultUnsubscribeCallbackForStateVariable);
        }

        // Since the 'newlyAuthenticatedUserId' variable has been changed to undefined, it indicates that the user is logging out.
        // Thus, set the userLoggingOut useState variable to indicate that we want to specify right away that the user is logging out.
        // We do that as the first useState variable setting in this block of code, so that a component refresh will affect this
        // variable first, reducing the number of refreshes before the logout process completes, in turn reducing the number of
        // database connections and queries that remain alive.
        displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId] with UNDEFINED newlyAuthenticatedUserId (fireBaseUser NOT defined). Before setting userLoggingOut to true, userLoggingOut: ${userLoggingOut.toString()}`, 'background: #c00; color: #fff');
        if (!userLoggingOut) {
          setUserLoggingOut(true);
        }

        // set the Authentication Status to indicate that an anonymous user is using the app
        if (authenticationStatus !== enumAuthenticationStatus.AnonymousUser) {
          displayConsoleLogs && console.log(`%c In CurrentUserProvider.useEffect for [newlyAuthenticatedUserId] with UNDEFINED newlyAuthenticatedUserId. About to change authentication status (${authenticationStatus}) to AnonymousUser`, 'background: #ddd; color: #030');
          setAuthenticationStatus(enumAuthenticationStatus.AnonymousUser);
        }
      }
    }

    // perform cleanup when the component unmounts
    return () => {
      displayConsoleLogs && console.info(`%c CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. Cleanup before unmounting. Ready to call unsubscribeCallbackUserSnapshotQuery().`, 'background: #00d; color: #fff')

      // call method to unsubscribe from the snapshot query
      unsubscribeCallbackUserSnapshotQuery();
      displayConsoleLogs && console.info(`%c CurrentUserProvider.useEffect for [newlyAuthenticatedUserId]. Cleanup before unmounting. After call to unsubscribeCallbackUserSnapshotQuery().`, 'background: #00d; color: #fff')
    }

  }, [newlyAuthenticatedUserId]);


  /**
 * @function onChannelSnapshotCallback A callback method to receive firestore User data snapshots for dynamic data updates
 * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
 */
  function onUserSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    // // if the user is not in the process of logging out...
    // if (!userLoggingOut) {

    // fetch the data from the document provided, which should be a JSON version of the correct type of object
    const userAsJson: IUserAsJson = snapshot.docs[0].data() as IUserAsJson;

    // convert the JSON object to a Typescript object
    const userData: IUser = JsonConverter.fromJSON(User, userAsJson, true);

    displayConsoleLogs && console.log(`%c In CurrentUserProvider.onUserSnapshotCallback Received User record for current user: \n${JSON.stringify(userData)}`, 'background: #c00; color: #fff');
    displayConsoleLogs && userData && console.log(`%c In CurrentUserProvider.onUserSnapshotCallback UserSettings for current user: \n${JSON.stringify(userData.userSettings)}`, 'background: #c00; color: #fff');

    // displayConsoleLogs && console.log(`%c In CurrentUserProvider.onUserSnapshotCallback userLoggingOut: ${userLoggingOut.toString()}`, 'background: #c00; color: #fff');

    // if the currentUser local state variable has not been set, or if it has already been set but the data for currentUser 
    // is different from what was returned to this callback...
    if (!currentUser || (currentUser && !_.isEqual(userData, currentUser))) {
      // if (!userLoggingOut && (!currentUser || (currentUser && !_.isEqual(userData, currentUser)))) {
      setCurrentUser(userData);
    }

    // set the Authentication Status to indicate that authentication has been completed
    if (authenticationStatus !== enumAuthenticationStatus.AuthenticationComplete) {
      displayConsoleLogs && console.log(`%c In CurrentUserProvider.onUserSnapshotCallback. About to change authentication status to AuthenticationComplete`, 'background: #ddd; color: #030');
      setAuthenticationStatus(enumAuthenticationStatus.AuthenticationComplete);
    }
  }

  // Prepare the Context object with available information.
  // If the 'userLoggingOut' flag has been set, we want to return a status of AuthenticationComplete
  // with an undefined CurrentUser; otherwise, we'll return the current Authentication status and CurrentUser values.
  displayConsoleLogs && console.log(`%c In CurrentUserProvider. Preparing context data to return. userLoggingOut: ${userLoggingOut.toString()}`, 'background: #ddd; color: #030');
  const currentUserContextData: ICurrentUserContextData =
    userLoggingOut ?
      {
        authenticationStatus: enumAuthenticationStatus.AnonymousUser,
        currentUser: undefined
      } :
      {
        authenticationStatus: authenticationStatus,
        currentUser: currentUser
      }

  // this provider forwards the value to the CurrentUserContext.Provider
  return (
    <CurrentUserContext.Provider value={currentUserContextData}>
      {children}
    </CurrentUserContext.Provider>
  )
}