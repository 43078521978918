import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { IFirestoreTopicRepository, FirestoreTopicRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicRepository";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreDbContext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDbContext";


export function getTopicsForParentId_onSnapshot(parentId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // get a Topic repository from the repository factory
      // const firestoreTopicRepository: IFirestoreTopicRepository =
      //   FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Topic) as
      //   IFirestoreBaseRepository<ITopic, ITopicAsJson>;

      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // instantiate a FirestoreDbContext
      const dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);

      const firestoreTopicRepository: IFirestoreTopicRepository = new FirestoreTopicRepository(dbContext);

      // fetch the topics for the pertinent channelId
      const unsubscribeCallback: () => void = await firestoreTopicRepository.getAllForParent_onSnapshot(parentId, callback);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}