import React, { PropsWithChildren } from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import SubmitButton from '../../buttons/SubmitButton/SubmitButton';
import CancelButton from '../../buttons/CancelButton/CancelButton';
import { ControlsStringAssets } from '../../../../assets/stringAssets';
import { styled } from '@mui/system';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), for the Progress Bar Area
const StyledBoxForProgressBarArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  width: "100%",
}));

// a styled LinearProgress for the Progress Bar
const StyledLinearProgressForProgressBar = styled((props) => (
  <LinearProgress
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
}));

// a styled Box (equivalent to a <div>), for the Progress Label Area
const StyledBoxForProgressLabelArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  width: "100%",
}));

// a styled Typography for the Progress Label
const StyledTypographyForProgressLabel = styled((props) => (
  <Typography
    variant="caption"
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Box (equivalent to a <div>), for the Buttons Bar Area
const StyledBoxForButtonsBarArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
}));

// a styled Box (equivalent to a <div>), for the Left Side of the Buttons Bar
const StyledBoxForLeftSideOfButtonsBar = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginRight: theme.spacing(4),
}));

// a styled Box (equivalent to a <div>), for the Right Side of the Buttons Bar
const StyledBoxForRightSideOfButtonsBar = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginLeft: theme.spacing(4),
}));

export interface IFormActionButtonsBarWithProgressProps extends PropsWithChildren<unknown> {
  // if provided, the label to display for the 'Submit' button
  submitButtonLabel?: string;
  // if provided, the label to display for the 'Cancel' button
  cancelButtonLabel?: string;
  // if provided, whether to display a progress indicator
  actionInProgress?: boolean;
  // if provided, a label to display beneath the progress indicator
  actionInProgressLabel?: string;
  // if provided, a boolean to indicate whether the form is valid for submission
  formIsValid?: boolean;
  // if provided, the function to be called upon clicking the 'Cancel' button
  onCancel?: () => void;
}

/**
 * @function FormActionButtonsBarWithProgress A React component that provides an "Action Buttons" bar with progress indicator.
 *    The action buttons will include a 'Submit' button and a 'Cancel' button.
 * @param {IFormActionButtonsBarWithProgressProps} props Input properties
 */
const FormActionButtonsBarWithProgress: React.FC<IFormActionButtonsBarWithProgressProps> = (props: IFormActionButtonsBarWithProgressProps) => {

  const { submitButtonLabel, cancelButtonLabel, actionInProgress, actionInProgressLabel, formIsValid, onCancel } = props;
  // console.info(`FormActionButtonsBarWithProgress. actionInProgress=${actionInProgress}`);
  // console.info(`FormActionButtonsBarWithProgress. formIsValid=${formIsValid}`);

  // determine whether to disable the Submit button based on the OR condition of the following:
  // if 'formIsValid' is a defined parameter, then apply !formIsValid value; otherwise, use 'false' to not disable (want to disable if form is NOT valid) -OR-
  // if 'actionInProgress' is a defined parameter, then apply actionInProgress value; otherwise, use 'false' to not disable (want to disable if action is in progress)
  const disableSubmitButton: boolean = ((formIsValid !== undefined) ? !formIsValid : false) || ((actionInProgress !== undefined) ? actionInProgress : false);
  // console.info(`FormActionButtonsBarWithProgress. disableSubmitButton=${disableSubmitButton}`);

  return (
    <>
      {/* if there's an action in progress, display the progress bar area */}
      {actionInProgress &&
        <StyledBoxForProgressBarArea>
          <StyledLinearProgressForProgressBar />
          <StyledBoxForProgressLabelArea>
            {/* if an action progress label has been provided, display it */}
            {actionInProgressLabel &&
              <StyledTypographyForProgressLabel>{actionInProgressLabel}</StyledTypographyForProgressLabel>
            }
          </StyledBoxForProgressLabelArea>
        </StyledBoxForProgressBarArea>
      }
      {/* display the action buttons (Submit & Cancel) */}
      <StyledBoxForButtonsBarArea>
        <StyledBoxForLeftSideOfButtonsBar>
          <SubmitButton disabled={disableSubmitButton}>
            {submitButtonLabel ?? ControlsStringAssets.saveButtonText}
          </SubmitButton>
        </StyledBoxForLeftSideOfButtonsBar>
        <StyledBoxForRightSideOfButtonsBar>
          <CancelButton onCancel={onCancel} disabled={actionInProgress}>
            {cancelButtonLabel ?? ControlsStringAssets.cancelButtonText}
          </CancelButton>
        </StyledBoxForRightSideOfButtonsBar>
      </StyledBoxForButtonsBarArea>
    </>
  );
}

export default FormActionButtonsBarWithProgress;