import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export const listViewItemStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({
  tableRow: {
    paddingLeft: theme.spacing(1),
    '&:nth-child(even)': {
      background: theme.listViewTable.detailRow.evenRowBackground,
      border: theme.listViewTable.detailRow.evenRowBorder,
      '&:hover': {
        background: theme.listViewTable.detailRow.evenRowHoverBackground,
        border: theme.listViewTable.detailRow.evenRowHoverBorder,
      },
    },
    '&:nth-child(odd)': {
      background: theme.listViewTable.detailRow.oddRowBackground,
      border: theme.listViewTable.detailRow.oddRowBorder,
      '&:hover': {
        background: theme.listViewTable.detailRow.oddRowHoverBackground,
        border: theme.listViewTable.detailRow.oddRowHoverBorder,
      },
    },
  },
  title: {
    margin: theme.spacing(0.5, 1, -1, 0),
    color: theme.listViewTable.detailRow.titleColor
  },
  occurrenceTimestamp: {
    color: theme.listViewTable.detailRow.occurrenceTimestampColor,
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
  description: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.listViewTable.detailRow.descriptionColor
  },
  ownerSection: {
    margin: theme.spacing(1.5, 0, 1, 0),
    color: theme.cardViewCard.descriptionColor,
    justifySelf: "start",
  },
  documentIcon: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0.5),
    border: theme.documentIcon.border,
    color: theme.documentIcon.textColor,
    '&:hover': {
      background: theme.documentIcon.hover.background,
      color: theme.documentIcon.hover.textColor,
    }
  },
  hyperLinkIcon: {
    marginLeft: theme.spacing(1),
    border: theme.hyperlinkIcon.border,
    color: theme.hyperlinkIcon.textColor,
    '&:hover': {
      background: theme.hyperlinkIcon.hover.background,
      color: theme.hyperlinkIcon.hover.textColor,
    }
  },
  noteContent: {
    marginTop: theme.spacing(1),
    color: theme.listViewTable.detailRow.descriptionColor
  },
  digitalMediaObjectContainer: {
    // marginBottom: theme.spacing(1),
    margin: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
  },
  digitalMediaObject: {
    // The following combination works in both desktop and mobile browsers, but height is a hardcoded guesstimate and may cause width of image to shrink
    // maxWidth: '100%',
    // maxHeight: '250px',
    objectFit: 'contain',  // 'contain' & 'scale-down' seem to work similarly
    transition: '1s ease-in',

    [theme.breakpoints.down('xs')]: {
      maxWidth: '400px',
      maxHeight: '250px',
    },
    [theme.breakpoints.up('sm')]: {
      maxWidth: '400px',
      maxHeight: '250px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '500px',
      maxHeight: '350px',
    },
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  actionButtonLabelContainer: {
    display: "flex",
    justifyContent: "center",
  },
  actionButtonContainer: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "center",
  },
  actionButton: {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(0.5),
  },
  drillDownIconButton: {
    border: theme.listViewTable.detailRow.drillDownIconButton.border,
    background: theme.listViewTable.detailRow.drillDownIconButton.background,
    color: theme.listViewTable.detailRow.drillDownIconButton.iconColor,
    '&:hover': {
      border: theme.listViewTable.detailRow.drillDownIconButton.hoverBorder,
      background: theme.listViewTable.detailRow.drillDownIconButton.hoverBackground,
      color: theme.listViewTable.detailRow.drillDownIconButton.hoverIconColor,
    },
  },
  // drillDownIconButton: {
  //   background: theme.listViewTable.detailRow.drillDownIconButton.background,
  //   color: theme.listViewTable.detailRow.drillDownIconButton.iconColor,
  //   '&:hover': {
  //     background: theme.listViewTable.detailRow.drillDownIconButton.hoverBackground,
  //   },
  // },
  editIconButton: {
    border: theme.listViewTable.detailRow.editIconButton.border,
    background: theme.listViewTable.detailRow.editIconButton.background,
    color: theme.listViewTable.detailRow.editIconButton.iconColor,
    '&:hover': {
      border: theme.listViewTable.detailRow.editIconButton.hoverBorder,
      background: theme.listViewTable.detailRow.editIconButton.hoverBackground,
      color: theme.listViewTable.detailRow.editIconButton.hoverIconColor,
    },
  },
  deleteIconButton: {
    border: theme.listViewTable.detailRow.deleteIconButton.border,
    background: theme.listViewTable.detailRow.deleteIconButton.background,
    color: theme.listViewTable.detailRow.deleteIconButton.iconColor,
    '&:hover': {
      border: theme.listViewTable.detailRow.deleteIconButton.hoverBorder,
      background: theme.listViewTable.detailRow.deleteIconButton.hoverBackground,
      color: theme.listViewTable.detailRow.deleteIconButton.hoverIconColor,
    },
  },
  shareIconButton: {
    marginLeft: theme.spacing(1),
    border: theme.cardViewCard.editIconButton.border,
    background: theme.cardViewCard.editIconButton.background,
    color: theme.cardViewCard.editIconButton.iconColor,
    '&:hover': {
      border: theme.cardViewCard.editIconButton.hoverBorder,
      background: theme.cardViewCard.editIconButton.hoverBackground,
      color: theme.cardViewCard.editIconButton.hoverIconColor,
    },
  },
  reactPlayerContainer: {
    margin: theme.spacing(1, 0),
    // border: `1px solid ${theme.palette.primary.light}`,
    // backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
    width: 'auto',
    transition: '1s ease-in',

    [theme.breakpoints.down('xs')]: {
      height: '200px',
      maxWidth: '200px',
    },
    [theme.breakpoints.up('sm')]: {
      height: '225px',
      maxWidth: '225px',
    },
    [theme.breakpoints.up('md')]: {
      height: '250px',
      maxWidth: '250px',
    },
  },
  reactPlayerContainerForAudio: {
    margin: theme.spacing(1, 2, 1, 0),
    // border: `1px solid ${theme.palette.primary.light}`,
    // backgroundColor: theme.palette.primary.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '10000px', // to give it completely rounded sides
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    position: 'relative',
    height: '3rem',
    width: 'auto',
    // maxWidth: '250px',
    // transition: '0.5s ease-in',

    [theme.breakpoints.down('xs')]: {
      // height: '50px',
      maxWidth: '300px',
    },
    [theme.breakpoints.up('sm')]: {
      // height: '55px',
      maxWidth: '350px',
    },
    [theme.breakpoints.up('md')]: {
      // height: '60px',
      maxWidth: '500px',
    },
  },
  reactPlayer: {
    position: 'relative'
  },
  reactPlayerForAudio: {
    height: '3rem',
    position: 'relative'
  },
}));