import { useContext } from 'react';
import { CurrentUserContext, ICurrentUserContextData } from '.';

/**
 * @hook useCurrentUserContext  A custom React hook that simplies the process for fetching the current User value from 
 *   within a React component tree that is includes a CurrentUserProvider at a higher level in the tree.
 */
 export const useCurrentUserContext = (): ICurrentUserContextData => {
  return useContext(CurrentUserContext);
}
