import { createContext } from 'react';
import { IAppVersionContextData } from '.';

/**
 * @context VersionContext A React Context that can be used to provide a React component tree with 
 *          Version available to the current user, including the current theme.
 */
export const AppVersionContext: React.Context<IAppVersionContextData> =
  createContext<IAppVersionContextData>(
    {
      state: {
        webAppVersion: '',
        backendVersion: '',
      },
      actions: {
      }
    }
  );
