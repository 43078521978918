import { collection, CollectionReference, DocumentData, Firestore, getDocs, onSnapshot, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames } from '../enums';
import { IFirestoreObjectSharingRequestTrackerRepository_Ext } from '.';
import { JsonConverter } from '../../../../../../dataObjects/utilities/JsonConverter';
import { IObjectSharingRequestTracker, IObjectSharingRequestTrackerAsJson, ObjectSharingRequestTracker } from '../../../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { enumSharableObjectType, enumSharingRequestStatus } from '../../../../../../dataObjects/enums';

/** 
 * @class FirestoreObjectSharingRequestTrackerRepository_Ext Provides extended functionality beyond the FirestoreObjectSharingRequestTrackerRepository class
 * *** IMPORTANT NOTE: FirestoreObjectSharingRequestTrackerRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestoreObjectSharingRequestTrackerRepository_Ext is focused on other features.
 *   Therefore, 
 *     1) FirestoreObjectSharingRequestTrackerRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreObjectSharingRequestTrackerRepository_Ext.
 */
export class FirestoreObjectSharingRequestTrackerRepository_Ext implements IFirestoreObjectSharingRequestTrackerRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method getRequestsForSharingObject Retrieves zero or more ObjectSharingRequestTracker objects from the database, using the Id
   *                                     of the shared object and, optionally, an array status values for the objects.
   * @param {typeUniqueId} sharingObjectId The Id of the shared object for which records are to be retrieved.
   * @param {Array<enumSharingRequestStatus>} statusValues (optional) A collection of status codes for qualifying the search (to be OR'd together, if provided)
   * @returns {Promise<Array<IObjectSharingRequestTracker>>} A Promise (to provide asynchrounous capability) with an array (potentially
   *                                    an empty array) with ObjectSharingRequestTracker objects that meet the search criteria.
   */
  getRequestsForSharingObject(sharingObjectId: typeUniqueId, statusValues?: Array<enumSharingRequestStatus>): Promise<Array<IObjectSharingRequestTracker>> {
    return new Promise<Array<IObjectSharingRequestTracker>>(async (resolve, reject) => {
      try {
        // array of objects to return
        let objectSharingRequestTrackers: Array<IObjectSharingRequestTracker> | undefined = undefined;

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the appropriate collection
        const objectSharingRequestTrackersCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ObjectSharingRequestTrackerCollection);

        // declare a query variable that will hold the query to be applied to the database
        let documentQuery: Query<DocumentData> | undefined = undefined;

        // construct the query based on whether the 'statusValues' parameter has been provided and has values
        if (statusValues === undefined || statusValues.length === 0) {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectId", "==", sharingObjectId)
            );

        } else {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectId", "==", sharingObjectId),
              where("status", "in", [...statusValues])
            );
        }

        // next, get a snapshot of the document references resulting from executing the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(documentQuery);

        // now, from the snapshot, get the data for each doc and add the data into a JSON objects array
        let objectSharingRequestTrackersAsJson: Array<IObjectSharingRequestTrackerAsJson> = [];
        querySnapshot.forEach((doc) => {
          objectSharingRequestTrackersAsJson.push(doc.data() as IObjectSharingRequestTrackerAsJson);
        });

        // next, convert the array of JSON objects for return
        objectSharingRequestTrackers = JsonConverter.arrayFromJSONArray(
          ObjectSharingRequestTracker,
          objectSharingRequestTrackersAsJson
        );

        resolve(objectSharingRequestTrackers);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getRequestsForSharingObject_onSnapshot Makes an onSnapshot() request to firestore for retrieving all ObjectSharingRequestTracker 
   *          objects from the database where the sharingObjectId matches the one provided and, optionally, an array status values for the objects.
   * @param {typeUniqueId} sharingObjectId The Id of the shared object for which records are to be retrieved.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @param {Array<enumSharingRequestStatus>} statusValues (optional) A collection of status codes for qualifying the search (to be OR'd together, if provided)
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getRequestsForSharingObject_onSnapshot(sharingObjectId: typeUniqueId,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void,
    statusValues?: Array<enumSharingRequestStatus>): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the appropriate collection
        const objectSharingRequestTrackersCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ObjectSharingRequestTrackerCollection);

        // declare a query variable that will hold the query to be applied to the database
        let documentQuery: Query<DocumentData> | undefined = undefined;

        // construct the query based on whether the 'statusValues' parameter has been provided and has values
        if (statusValues === undefined || statusValues.length === 0) {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectId", "==", sharingObjectId)
            );

        } else {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectId", "==", sharingObjectId),
              where("status", "in", [...statusValues])
            );
        }

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);

        // return the 'unsubscribeCallback' with the Promise, via the 'resolve'
        resolve(unsubscribeCallback);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getSharingRequestsByTypeForRecipient Retrieves zero or more ObjectSharingRequestTracker objects from the database, using the Type
   *           of shared object, the Id of the sharing request recipient and, optionally, an array status values for the objects.
   * @param {enumSharableObjectType} sharingObjectType The type of object (eg, Channel) for which the sharing requests are to be retrieved.
   * @param {typeUniqueId} recipientUserId The Id of the recipient who is the target of the sharing request.
   * @param {Array<enumSharingRequestStatus>} statusValues (optional) A collection of status codes for qualifying the search (to be OR'd together, if provided)
   * @returns {Promise<Array<IObjectSharingRequestTracker>>} A Promise (to provide asynchrounous capability) with an array (potentially
   *                                    an empty array) with ObjectSharingRequestTracker objects that meet the search criteria.
   */
  getSharingRequestsByTypeForRecipient(sharingObjectType: enumSharableObjectType, recipientUserId: typeUniqueId, statusValues?: Array<enumSharingRequestStatus>): Promise<Array<IObjectSharingRequestTracker>> {
    return new Promise<Array<IObjectSharingRequestTracker>>(async (resolve, reject) => {
      try {
        // array of objects to return
        let objectSharingRequestTrackers: Array<IObjectSharingRequestTracker> | undefined = undefined;

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the appropriate collection
        const objectSharingRequestTrackersCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ObjectSharingRequestTrackerCollection);

        // declare a query variable that will hold the query to be applied to the database
        let documentQuery: Query<DocumentData> | undefined = undefined;

        // construct the query based on whether the 'statusValues' parameter has been provided and has values
        if (statusValues === undefined || statusValues.length === 0) {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectType", "==", sharingObjectType),
              where("recipientUserId", "==", recipientUserId)
            );

        } else {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectType", "==", sharingObjectType),
              where("recipientUserId", "==", recipientUserId),
              where("status", "in", [...statusValues])
            );
        }

        // next, get a snapshot of the document references resulting from executing the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(documentQuery);

        // now, from the snapshot, get the data for each doc and add the data into a JSON objects array
        let objectSharingRequestTrackersAsJson: Array<IObjectSharingRequestTrackerAsJson> = [];
        querySnapshot.forEach((doc) => {
          objectSharingRequestTrackersAsJson.push(doc.data() as IObjectSharingRequestTrackerAsJson);
        });

        // next, convert the array of JSON objects for return
        objectSharingRequestTrackers = JsonConverter.arrayFromJSONArray(
          ObjectSharingRequestTracker,
          objectSharingRequestTrackersAsJson
        );

        resolve(objectSharingRequestTrackers);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getSharingRequestsByTypeForRecipient_onSnapshot  Makes an onSnapshot() request to firestore for retrieving all 
   *           ObjectSharingRequestTracker objects from the database, using the Type
   *           of shared object, the Email of the sharing request recipient and, optionally, an array status values for the objects.
   *           The Recipient Email is used, as opposed to the Recipient UserId, because Sharing Requests could have been submitted
   *           for the recipient before the recipient created a User Account.
   * @param {enumSharableObjectType} sharingObjectType The type of object (eg, Channel) for which the sharing requests are to be retrieved.
   * @param {string} recipientUserEmail The email address of the recipient user who is the target of the sharing request.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
   *   a snapshot of data from firestore.
   * @param {Array<enumSharingRequestStatus>} statusValues (optional) A collection of status codes for qualifying the search (to be OR'd together, if provided)
   * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
   */
  getSharingRequestsByTypeForRecipient_onSnapshot(sharingObjectType: enumSharableObjectType,
    recipientEmail: string,
    callback: (snapshot: QuerySnapshot<DocumentData>) => void,
    statusValues?: Array<enumSharingRequestStatus>): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // Declare an 'unsubscribeCallback' variable that will hold the unsubscribe callback from a firestore onSnapshot() request and will be returned
        // from this method.  
        // We initialize it to a function that does nothing.
        let unsubscribeCallback: () => void = () => { };

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the appropriate collection
        const objectSharingRequestTrackersCollection: CollectionReference<DocumentData> = collection(firestoreDb, enumFirestoreCollectionNames.ObjectSharingRequestTrackerCollection);

        // declare a query variable that will hold the query to be applied to the database
        let documentQuery: Query<DocumentData> | undefined = undefined;

        // construct the query based on whether the 'statusValues' parameter has been provided and has values
        if (statusValues === undefined || statusValues.length === 0) {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectType", "==", sharingObjectType),
              where("recipientEmail", "==", recipientEmail)
            );

        } else {
          documentQuery =
            query(objectSharingRequestTrackersCollection,
              where("sharingObjectType", "==", sharingObjectType),
              where("recipientEmail", "==", recipientEmail),
              where("status", "in", [...statusValues])
            );
        }

        // with the query object, call onSnapshot() to subscribe to dynamic updates, passing the callback function and capturing the firestore
        // 'unsubscribe' callback function
        unsubscribeCallback = await onSnapshot(documentQuery, callback);

        // return the 'unsubscribeCallback' with the Promise, via the 'resolve'
        resolve(unsubscribeCallback);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

}
