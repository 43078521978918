import { take, put } from 'redux-saga/effects';
import { deleteHyperLink } from '../../../firebaseServices/dataServices/dataServiceActions/hyperLinkActions';
import { hyperLinkDeleteRequest, hyperLinkDeleteStatusChange } from '../../slices/hyperLink/hyperLinkDeleteStatusSlice';
import { hyperLinkDeleteFailureChange } from '../../slices/hyperLink/hyperLinkDeleteFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteHyperLinkRequestedSaga A saga workflow pertaining to a user having requested to delete a hyperLink.
 */
export function* deleteHyperLinkRequestedSaga() {
  // console.info('(deleteHyperLinkRequestedSaga) entered deleteHyperLinkRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the hyperLinkDeleteRequest action to be dispatched and get the payload data...
      const hyperLinkDeleteRequestData = yield take(hyperLinkDeleteRequest);
      const { payload: hyperLink } = hyperLinkDeleteRequestData;

      // make a request to delete the hyperLink
      yield deleteHyperLink(hyperLink);

      // dispatch an action to indicate that the delete operation was successful
      yield put(hyperLinkDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(hyperLinkDeleteStatusChange(enumWorkflowState.Failure));
      yield put(hyperLinkDeleteFailureChange(error));
    }
  }
}
