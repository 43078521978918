import React, { PropsWithChildren } from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/system';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Typography for the Page Title
const StyledTypographyForPageTitle = styled((props) => (
  <Typography
    variant="h4"
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
  fontWeight: 'bold',

  [theme.breakpoints.up('xs')]: {
    fontSize: '1.5rem',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '1.7rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1.85rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '2.0rem',
  },
  [theme.breakpoints.up('xl')]: {
    fontSize: '2.125rem',
  },
}));


/**
 * @interface IPageTitleProps declares any input properties required for this component.
 */
export interface IPageTitleProps extends PropsWithChildren<unknown> {
  title: string;
}

/**
 * @function PageTitle is a React functional component, representing a title that will appear on a page.
 * @param {IPageTitleProps} props are the properties passed into the component and, in this case.
 */
export const PageTitle: React.FC<IPageTitleProps> = (props: IPageTitleProps) => {
  PageTitle.displayName = 'Page Title';

  const { title } = props;

  return (
    <>
      <StyledTypographyForPageTitle>
        {title}
      </StyledTypographyForPageTitle>
    </>
  )
}