/**
 * @class HeaderAndFooterStringAssets A collection of string assets that are use in header and footer areas
 */
export class HeaderAndFooterStringAssets {

  // APPLICATION TITLE
  // static appTitle: string = 'MyTennisBrain';
  static appTitle: string = 'MyDigiBrain';

  // MENUS
  static menu_: string = ``;

  // MENU BUTTONS
  static menuButton_Signin: string = 'Sign in';
  static menuButton_About: string = 'About';

  // COPYRIGHT MESSAGE
  static copyrightMessage: string = `Copyright \u00A9 2020, MyTennisBrain`; // Note: \u00A9 is the unicode (hex) code for the copyright symbol

  // VERSION LABEL
  static version_Label: string = `Version: `;


}