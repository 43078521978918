import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function convertGoogleDriveFileUrlForFileLink Given a Google Drive File URL, converts the URL to represent a link to the file,
 *                                                so that the file (an image file, video file, audio file, etc.) can be displayed.
 * @param url
 * 
 * Up until January 2024, the steps to convert a Google Drive link to a Google Drive Direct Download Link, where the image
 * could be embedded in html, were as follows:
 *   1. The url should be similar to this one: https://drive.google.com/file/d/1ikiFSkNkT3f2lcZiDw6USpWh20mX4B-K/view?usp=drive_link 
 *   2. Each Google Drive file url has a unique file ID in the link, this is the text between d/ and /view: "1RG3CAPiwiFlFATUlIIwhk0RrbEU4PgVP" in this case
 *   3. Replace "file/d/" in the url with "uc?export=download&id="
 *   4. Remove the section after the fileId, "/view?...". 
 *   5. The resulting url would look like this: https://drive.google.com/uc?id=1RG3CAPiwiFlFATUlIIwhk0RrbEU4PgVP&export=download
 * 
 * However, as of January 2024, Google no longer directly supports a link to an image in a Google Drive folder.
 * (See https://justin.poehnelt.com/posts/google-drive-embed-images-403/ )
 * 
 * An IFrame approach is possible, but querky and problematic. There is a service (https://lienuc.com/) that provides a solution.
 * We will use the lienuc service for the time being. The process with using the lienuc service:
 *   1. The url should be similar to this one: https://drive.google.com/file/d/1ikiFSkNkT3f2lcZiDw6USpWh20mX4B-K/view?usp=drive_link 
 *   2. Each Google Drive file url has a unique file ID in the link, this is the text between "file/d/"" and "/view: "1ikiFSkNkT3f2lcZiDw6USpWh20mX4B-K" in this case
 *   3. Formulate the <img> tag as follows:
 *      <img
 *        crossorigin="anonymous" (Note: This must be provided)
 *        height="desired height"
 *        width="desired width"
 *        src="https://drive.lienuc.com/uc?id=GOOGLE_DRIVE_FILE_ID"
 *        alt="Some alternate text"
 *      />
 * 
 */
export const convertGoogleDriveFileUrlForFileLink: (url: string) => string = (url: string) => {
	// declare a url to return and default it to the url parameter
	let convertedUrl: string = url;

	if (url) {
		// using a regular expression, extract the Google Drive FileId from the url
		const regEx: RegExp = RegularExpressions.ExtractFileIdFromGoogleDriveShareLink;
		const regExResults: RegExpExecArray | null = regEx.exec(url);

    // If something was extracted (regExResults array) and the array has more than one element, it must have found the FileId.
    //   - The first array element contains the url that matches the regex pattern. 
    //   = The second array alement (index 1) contains the FileId.
		if (regExResults && (regExResults.length > 1)) {
      // create a revised Url that includes the FileId
			convertedUrl = 'https://drive.lienuc.com/uc?id=' + regExResults[1];
		}
	}

	return convertedUrl;
}
