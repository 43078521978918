import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../../dataObjects/models/users/User';
import { ITopicItem, TopicItem } from '../../../../dataObjects/models/topics/TopicItem';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumObjectPersistenceState, enumPersistableObjectType } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets } from '../../../../assets/stringAssets';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage/IProcessPerManageObjectPageStatusInput';
import TopicItemForm from '../../../forms/TopicItemForm/TopicItemForm';
import { ITopic } from '../../../../dataObjects/models/topics/Topic';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { topicItemSaveRequest, topicItemSaveStatusChange } from '../../../../uiMiddleware-redux/slices/topicItem/topicItemSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { useAppDispatch, useAppSelector } from '../../../../uiMiddleware-redux/store/configureStore';

/**
 * @interface IManageTopicItemPageProps Input properties for the ManageTopicPage
 */
export interface IManageTopicItemPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the TopicItem already exists
  parentId?: typeUniqueId  // if the parentId is given, it means that a new TopicItem is to be created
}

/**
 * @function ManageTopicItemPage A React Function Component for manageing (creating or editing) a TopicItem.
 * @param {IManageTopicItemPageProps} props Input properties
 */
const ManageTopicItemPage: React.FC<IManageTopicItemPageProps> = (props: IManageTopicItemPageProps) => {

  ManageTopicItemPage.displayName = "Manage TopicItem Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [topicItemId, setTopicItemId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the topicItemId anytime the params.id changes
  useEffect(() => {
    setTopicItemId(params.id);
  }, [params.id]);

  // define an effect based on a change to the topicItemId. Having a defined topicItemId means that we will 
  // be editing an existing TopicItem
  useEffect(() => {
    if (topicItemId !== undefined) {
      // try to find an TopicItem object with the Id given in the URL
      getSingleObjectById(topicItemId, enumPersistableObjectType.TopicItem, onTopicItemFetched);
    }
  }, [topicItemId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [topicItemParentId, setTopicItemParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the topicItemParentId anytime the params.parentId changes
  useEffect(() => {
    setTopicItemParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the topicItem to be managed, either a new topicItem or an existing one
  const [topicItem, setTopicItem] = useState<ITopicItem | undefined>(undefined);

  // whether we are going to be managing a new topicItem
  const [isNewObject, setIsNewObject] = useState<boolean | undefined>(undefined);

  const resetSaveTopicItemStatus: () => void = useCallback(() => {
    dispatch(topicItemSaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveTopicItemStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveTopicItemStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveTopicItemStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // get the current Topic from Redux state for use in preparing the page title
  const currentTopic: ITopic | null | undefined = useAppSelector((state: IStoreState) => state.currentTopic);

  const [pageTitle, setPageTitle] = useState<string>('');

  const preparePageTitle: () => void = useCallback(
    () => {
      const preparedPageTitle: string = (isNewObject ? 'New ' : 'Modify ') + currentTopic?.topicItemNameAliasSingular;
      setPageTitle(preparedPageTitle);
    },
    [currentTopic, isNewObject],
  );

  useEffect(() => {
    preparePageTitle();
  }, [preparePageTitle]);

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveTopicItemStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveTopicItemFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.topicItem_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveTopicItem,
    notificationSuccessMessage: MessagesStringAssets.topicItem_SaveSuccess,
  }

  if (topicItem !== undefined) {
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.topicItem_SaveSuccess} (${topicItem.name})`;
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewTopic Prepares/creates a new topicItem that will be used if user is requesting to create/add a new topicItem.
   * @param parentChannel 
   */
  const prepareNewTopicItem: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => ITopicItem = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newTopicItem: ITopicItem;

      const newTopicItemId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newTopicItem = new TopicItem(user.id, newTopicItemId, enumPersistableObjectType.Topic, parentId, enumObjectPersistenceState.New,
        '', '', userPersistenceData);

      return newTopicItem;
    }, []
  );

  // define an effect based on a change to the topicItemParentId & currentUser. Having a defined topicItemParentId means that we will
  // be creating a new TopicItem
  useEffect(() => {
    if (topicItemParentId && currentUser) {
      setTopicItem(prepareNewTopicItem(topicItemParentId, currentUser, currentUser.userSettings));

      setIsNewObject(true);
    }
  }, [topicItemParentId, currentUser, prepareNewTopicItem]);

  function onTopicItemFetched(topicItem: ITopicItem | undefined): void {
    if (topicItem !== undefined) {
      // set the returned TopicItem to be the one we are editing
      setTopicItem(topicItem);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param event A Submit event
   */
  function handleFormSubmit(topicItem: ITopicItem): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request saving the topicItem
        dispatch(topicItemSaveRequest(topicItem));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }


  return (
    <>
      {currentTopic && (isNewObject !== undefined) &&
        <>
          <GenericPageContainer
            maxWidth="sm"
            showBackButton={true}
            pageTitle={pageTitle}
            onCloseAlert={resetSaveTopicItemStatus}
          >
            {topicItem &&
              <TopicItemForm
                topicItem={topicItem}
                saveRequestInProgress={operationInProgress}
                onSubmit={handleFormSubmit} />
            }

          </GenericPageContainer>
        </>
      }
    </>
  );


} // const ManageTopicPage...

export default ManageTopicItemPage;
