import { enumAudioLinkType } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumAudioLinkTypeConvert Provides to/from string conversions for enumAudioLinkType values.
 */
export class enumAudioLinkTypeConvert {
  public static toString(audioLinkTypeEnum: enumAudioLinkType): string {
    return audioLinkTypeEnum.toString();
  }

  public static fromString(audioLinkTypeString: string): enumAudioLinkType {
    let audioLinkTypeEnum: enumAudioLinkType = enumAudioLinkType.GoogleCloudStorage;

    switch (audioLinkTypeString) {
      case enumAudioLinkTypeConvert.toString(enumAudioLinkType.GoogleCloudStorage):
        audioLinkTypeEnum = enumAudioLinkType.GoogleCloudStorage;
        break;

      case enumAudioLinkTypeConvert.toString(enumAudioLinkType.ExternalSource):
        audioLinkTypeEnum = enumAudioLinkType.ExternalSource;
        break;

      default:
        throw new MdbError(`enumAudioLinkTypeConvert.fromString. Unrecognized/unsupported enumAudioLinkType string: ${audioLinkTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return audioLinkTypeEnum;
  }
}