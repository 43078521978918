import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ControlsStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import isEmail from 'validator/lib/isEmail';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { RegularExpressions } from '../../../assets/regularExpressionAssets';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display Text for the instructions
const StyledBoxForInstructionalText = styled((props) => (
  <Box
    component='div'
    {...props}
  />
))(({ theme }) => ({
  color: theme.palette.text,
  padding: '0 10px',
}));

interface IPasswordWithConfirmationEntryFormValues {
  password: string;
  confirmPassword: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  password: yup
    .string()
    .min(8, ControlsStringAssets.passwordMinLength)
    .matches(RegularExpressions.Password, ControlsStringAssets.passwordPattern)
    .required(ControlsStringAssets.passwordRequired),
  confirmPassword: yup
    .string()
    .required(ControlsStringAssets.confirmPasswordRequired)
    .min(8, ControlsStringAssets.passwordMinLength)
    .oneOf([yup.ref('password')], ControlsStringAssets.confirmPasswordMustMatch) // validates that the confirmation password matches the original password
    .matches(RegularExpressions.Password, ControlsStringAssets.passwordPattern),
});

export interface IPasswordWithConfirmationEntryFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {string} instructions An HTML fragment that provides instructions to display
   */
  instructions?: string,
  /**
   * @property {(password: string) => Promise<void>} onSubmit Method to call for submitting the password
   */
  onSubmit: (password: string) => Promise<void>,
  /**
   * @property {() => Promise<void>} onCancel Method to call if the user requests to cancel the operation
   */
  onCancel: () => Promise<void>,
}

const PasswordWithConfirmationEntryForm: React.FC<IPasswordWithConfirmationEntryFormProps> = (props: IPasswordWithConfirmationEntryFormProps) => {
  PasswordWithConfirmationEntryForm.displayName = 'PasswordWithConfirmationEntryForm Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed PasswordWithConfirmationEntryForm component', 'background: #055; color: #fff');

  const { instructions, onSubmit, onCancel } = props;

  const navigate = useNavigate();

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<IPasswordWithConfirmationEntryFormValues>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: passwordReg, ...passwordProps } = register("password", { required: true });
  const { ref: confirmPasswordReg, ...confirmPasswordProps } = register("confirmPassword", { required: true });

  // // capture the current email address in the 'email' form field (used in a case where submission had resulted in an "Account Not Verified" error)
  // // const currentEmailAddress: string = getValues("email");
  // const [currentEmailAddress, setCurrentEmailAddress] = useState<string>('');

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);

  useEffect(() => {
    setFormIsValid(isValid);
    // console.info(`PasswordWithConfirmationEntryForm.useEffect: formIsValid being set to ${isValid}`);
  }, [isValid]);

  // handles a submit request from the form
  const handlePasswordSubmit = async (data: IPasswordWithConfirmationEntryFormValues) => {
    // call the onSubmit handler passed in, supplying the password
    await onSubmit(data.password);
  }

  function handleCanceled(): void {
    // call onCancel() method
    onCancel();
  }

  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handlePasswordSubmit)}
        onCancel={handleCanceled}
        formIsValid={formIsValid}
        submitButtonLabel={ControlsStringAssets.submitButtonText}
      >
        {
          instructions &&
          <>
            <StyledBoxForInstructionalText>
              {instructions}
            </StyledBoxForInstructionalText>
          </>
        }

        <TextField
          inputRef={passwordReg}
          {...passwordProps}
          type="password"
          label={ControlsStringAssets.passwordLabel}
          margin='normal'
          fullWidth
          error={!!errors.password}
          helperText={errors?.password?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={confirmPasswordReg}
          {...confirmPasswordProps}
          type="password"
          label={ControlsStringAssets.confirmPasswordLabel}
          margin='normal'
          fullWidth
          error={!!errors.confirmPassword}
          helperText={errors?.confirmPassword?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default PasswordWithConfirmationEntryForm;