import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ITopicItem } from '../../../dataObjects/models/topics/TopicItem';

// export interface ISaveTopicItemStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const topicItemSaveStatusSlice = createSlice({
  name: 'topicItemSaveStatus',
  initialState,
  reducers: {
    // The topicItemSaveRequest triggers the saving of a TopicItem. It's not really associated with the Store State
    // for the 'saveTopicItemStatus' store variable; however, it is used to both trigger the TopicItem Save Request -AND-
    // to call the topicItemSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveTopicItemData') and create another slice with a topicItemSaveRequest action. 
    topicItemSaveRequest: (state, action: PayloadAction<ITopicItem>) => {
      topicItemSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with an ITopicItem object or null
    topicItemSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { topicItemSaveRequest, topicItemSaveStatusChange } = topicItemSaveStatusSlice.actions;

// export the core reducer for the slice
export const topicItemSaveStatusReducer = topicItemSaveStatusSlice.reducer;

export default topicItemSaveStatusSlice.reducer;