import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface ITennisCourtPlayingSurfaceParms {
  overallClassName: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  courtSurface: {
    fill: theme.tennisCourt.surfaceColor,
  },

  lines: {
    fill: '#fff',
  },


  net: {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: '1.3px',
  },

  centerLine: {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: '4.5px',
  },

  centerLineTickAtBaseline: {
    fill: 'none',
    stroke: '#fff',
    strokeMiterlimit: 10,
    strokeWidth: '4.27px',
  },

}));


// const TennisCourtPlayingSurface: React.FC<ITennisCourtPlayingSurfaceParms> = (props: ITennisCourtPlayingSurfaceParms) => {

// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const TennisCourtPlayingSurface = React.forwardRef((props: ITennisCourtPlayingSurfaceParms, ref?: React.Ref<SVGSVGElement>) => {

  const { overallClassName } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={overallClassName} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 561.6 259.2" >
      <defs>
      </defs>

      <g id="layerCourtSurface">
        <g id="courtSurface">
          <rect className={classes.courtSurface} x="2.25" y="2.25" width="557.1" height="254.7" />
          <path className={classes.lines} d="M708.3,90.9V341.1H155.7V90.9H708.3m4.5-4.5H151.2V345.6H712.8V86.4Z" transform="translate(-151.2 -86.4)" />
        </g>
      </g>
      <g id="layerSinglesCourt">
        <g id="singlesCourt">
          <g>
            <rect className={classes.courtSurface} x="2.25" y="34.65" width="557.1" height="189.9" />
            <path className={classes.lines} d="M708.3,123.3V308.7H155.7V123.3H708.3m4.5-4.5H151.2V313.2H712.8V118.8Z" transform="translate(-151.2 -86.4)" />
          </g>
          <g>
            <rect className={classes.courtSurface} x="131.85" y="34.65" width="297.9" height="189.9" />
            <path className={classes.lines} d="M578.7,123.3V308.7H285.3V123.3H578.7m4.5-4.5H280.8V313.2H583.2V118.8Z" transform="translate(-151.2 -86.4)" />
          </g>
          <line className={classes.net} x1="280.3" y1="32.1" x2="280.3" y2="227.1" />
          <line className={classes.centerLine} x1="129.6" y1="129.6" x2="432" y2="129.6" />
          <line className={classes.centerLineTickAtBaseline} x1="558" y1="129.6" x2="550.8" y2="129.6" />
          <line className={classes.centerLineTickAtBaseline} x1="10.8" y1="129.6" x2="3.6" y2="129.6" />
        </g>
      </g>

    </svg >
  )
});

export default TennisCourtPlayingSurface;
