import { typeUniqueId } from "../../../../dataObjects/types"
import { RandomId } from "../../../../dataObjects/utilities/RandomId";
import { enumEmailRequestType } from "../../../../dataObjects/enums";

/**
 * @function generateIdForEmailRequest Generates a unique Id to be used with an EmailRequest to be saved to the DB.
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @returns {typeUniqueId} The unique Id generated by the function
 * @description Given that each EmailRequest in the DB must have a unique Id, and further given that there can be multiple
 *              EmailRequests for the same user, and in fact, there could be multiple EmailRequests of the same type for the 
 *              the same user, the Id to be generated will consist of three parts, concatenated with hyphens:
 *              1) User Id
 *              2) The type of EmailRequest ('EmailAddressVerification', 'PasswordReset', 'SignInWithLink', etc.)
 *              3) A randome unique Id
 */
export function generateIdForEmailRequest(userId: typeUniqueId, emailRequestType: enumEmailRequestType): Promise<typeUniqueId> {
  return new Promise<typeUniqueId>(async (resolve, reject) => {
    try {
      // generate a random Id for the third part of the overall Id
      // const randomId: typeUniqueId = await RandomId.newIdAsync();
      const randomId: typeUniqueId = RandomId.newId();

      // create the overall EmailRequest Id
      const emailRequestId: typeUniqueId = `${userId}-${emailRequestType}-${randomId}`;

      resolve(emailRequestId);
    } catch (error: any) {
      reject(error);
    }
  });
}
