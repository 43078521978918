import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { ControlsStringAssets } from '../../../../assets/stringAssets';
import AcceptanceButton from '../../buttons/AcceptanceButton/AcceptanceButton';
import NonAcceptanceButton from '../../buttons/NonAcceptanceButton/NonAcceptanceButton';
import { styled } from '@mui/system';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), for the Buttons Bar Area
const StyledBoxForButtonsBarArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
}));

// a styled Box (equivalent to a <div>), for the Left Side of the Buttons Bar
const StyledBoxForLeftSideOfButtonsBar = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginRight: theme.spacing(4),
}));

// a styled Box (equivalent to a <div>), for the Right Side of the Buttons Bar
const StyledBoxForRightSideOfButtonsBar = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginLeft: theme.spacing(4),
}));


export interface ITwoButtonDialogActionBarProps extends PropsWithChildren<unknown> {
  // if provided, the label to display for the 'Acceptance' button
  acceptanceButtonLabel?: string;
  // if provided, the label to display for the 'NonAcceptance' button
  nonAcceptanceButtonLabel?: string;
  // the function to be called upon clicking the 'Acceptance' button
  onAcceptance: () => void;
  // the function to be called upon clicking the 'NonAcceptance' button
  onNonAcceptance: () => void;
}

/**
 * @function TwoButtonDialogActionBar A React component that provides an "Action Buttons" bar with action buttons that
 *  will include an 'Acceptance' button and a 'NonAcceptance' button.
 * @param {ITwoButtonDialogActionBarProps} props Input properties
 */
const TwoButtonDialogActionBar: React.FC<ITwoButtonDialogActionBarProps> = (props: ITwoButtonDialogActionBarProps) => {

  const { acceptanceButtonLabel, nonAcceptanceButtonLabel, onAcceptance, onNonAcceptance } = props;

  return (
    <>
      {/* display the action buttons (Acceptance & NonAcceptance) */}
      <StyledBoxForButtonsBarArea>
        <StyledBoxForLeftSideOfButtonsBar>
          <AcceptanceButton onAcceptance={onAcceptance}>
            {acceptanceButtonLabel ?? ControlsStringAssets.confirmButtonText}
          </AcceptanceButton>
        </StyledBoxForLeftSideOfButtonsBar>
        <StyledBoxForRightSideOfButtonsBar>
          <NonAcceptanceButton onNonAcceptance={onNonAcceptance}>
            {nonAcceptanceButtonLabel ?? ControlsStringAssets.cancelButtonText}
          </NonAcceptanceButton>
        </StyledBoxForRightSideOfButtonsBar>
      </StyledBoxForButtonsBarArea>
    </>
  );
}

export default TwoButtonDialogActionBar;