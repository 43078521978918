import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { typeUniqueId } from '../../../dataObjects/types';
import { IChannel, IChannelAndParentCategoryId } from '../../../dataObjects/models/channels/Channel';

// export interface ISaveChannelStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const channelSaveStatusSlice = createSlice({
  name: 'channelSaveStatus',
  initialState,
  reducers: {
    // The channelSaveRequest triggers the saving of a Channel. It's not really associated with the Store State
    // for the 'saveChannelStatus' store variable; however, it is used to both trigger the Channel Save Request -AND-
    // to call the channelSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveChannelData') and create another slice with a channelSaveRequest action. 
    channelSaveRequest: (state, action: PayloadAction<IChannelAndParentCategoryId>) => {
      channelSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    channelSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { channelSaveRequest, channelSaveStatusChange } = channelSaveStatusSlice.actions;

// export the core reducer for the slice
export const channelSaveStatusReducer = channelSaveStatusSlice.reducer;

export default channelSaveStatusSlice.reducer;