import { MdbError } from "../../../../errorObjects/MdbError";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { Auth, getAuth, verifyBeforeUpdateEmail } from "firebase/auth";


/**
 * @function updateUserEmailAddress Updates the current user's email address, given the new email address as a parameter
 * @param {string} newEmailAddress New email address for the current user
 * @returns {Promise<void>} A Promise with no return value, providing for an asynchronous process.
 */
export function updateUserEmailAddress(newEmailAddress: string): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get the current Auth object (associated with the current user)
      const firebaseAuth: Auth = getAuth(FirebaseAppSingleton.getInstance().firebaseApp);

      // ensure that the a user is signed in...
      if (!firebaseAuth.currentUser) {
        throw new MdbError(`The email address can only be changed if a user is currently signed in.`);
      } else if (!firebaseAuth.currentUser.email) {
        throw new MdbError(`The currenet user account must have a valid email address.`);
      } else {
        // ensure that the new email address is not the same as the existing email address
        const currentEmailAddress: string = firebaseAuth.currentUser.email;
        if (currentEmailAddress.toLowerCase() === newEmailAddress.toLowerCase()) {
          throw new MdbError(`The email address to be changed is not different from the current email address. No updates will be made.`);
        } else {
          // // make Firebase Auth request to update the email address
          // await updateEmail(firebaseAuth.currentUser, newEmailAddress);

          // When a user changes the account email address, Firebase then requires the account to be verified again.
          // There's a workflow offered by Firebase which initiates an account verification process combined with changing
          // the email address. We're going to use that process.

          // get the base domain of the running application
          const baseDomain: string = window.location.hostname;

          // prepare action code settings
          const actionCodeSettings = {
            // url: `https://${baseDomain}/?email=${currentEmailAddress}`,
            url: `https://${baseDomain}?email=${newEmailAddress}&userId=${firebaseAuth.currentUser.uid}`,
            handleCodeInApp: true
          };

          // make Firebase Auth request to verify email address update
          await verifyBeforeUpdateEmail(firebaseAuth.currentUser, newEmailAddress, actionCodeSettings);
          // // Obtain code from the user.
          // await applyActionCode(auth, code);
        }
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}
