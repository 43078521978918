import { take, put } from 'redux-saga/effects';
import { enumSharingRequestStatus, enumWorkflowState } from '../../../dataObjects/enums';
import { saveObjectSharingRequestTracker } from '../../../firebaseServices/dataServices/dataServiceActions/collaborationActions';
import { objectSharingRequestTrackerSaveRequest, objectSharingRequestTrackerSaveStatusChange } from '../../slices/collaboration/objectSharingRequestTrackerSaveStatusSlice';
import { objectSharingRequestTrackerSaveFailureChange } from '../../slices/collaboration/objectSharingRequestTrackerSaveFailureSlice';
import { submitEmailRequestForObjectSharingRequest } from '../../../firebaseServices/dataServices/dataServiceActions/emailRequestActions';

/**
 * @function saveObjectSharingRequestTrackerRequestedSaga A saga workflow pertaining to a user having requested to save an ObjectSharingRequestTracker.
 */
export function* saveObjectSharingRequestTrackerRequestedSaga() {
  // console.info('(saveObjectSharingRequestTrackerRequestedSaga) entered saveObjectSharingRequestTrackerRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the objectSharingRequestTrackerSaveRequest action to be dispatched
      const objectSharingRequestTrackerSaveRequestData = yield take(objectSharingRequestTrackerSaveRequest);
      const { payload: objectSharingRequestTracker } = objectSharingRequestTrackerSaveRequestData;

      // capture whether the request is in a "New" state (if it is, we will set the status to "Requested", and then send email to the recipient)
      const requestIsNew: boolean = objectSharingRequestTracker.status === enumSharingRequestStatus.New;

      // the initial state to save for a new request is "Requested"
      if (requestIsNew) {
        objectSharingRequestTracker.status = enumSharingRequestStatus.Requested;
      }

      // make a request to save the objectSharingRequestTracker
      yield saveObjectSharingRequestTracker(objectSharingRequestTracker)

      // call function to generate an Email Request for the sharing request and submit to the server
      yield submitEmailRequestForObjectSharingRequest(objectSharingRequestTracker);

      // dispatch an action to indicate that the save operation was successful
      yield put(objectSharingRequestTrackerSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(objectSharingRequestTrackerSaveStatusChange(enumWorkflowState.Failure));
      yield put(objectSharingRequestTrackerSaveFailureChange(error));
    }
  }
}
