
/** 
 * @enum enumFirestoreCollectionNames provides the names of collections in the Firestore DB
 */
export enum enumFirestoreCollectionNames {

  AudioLinkCollection = 'audioLink',
  CategoryCollection = 'category',
  ChannelCollection = 'channel',
  DigitalMediaCollection = 'digitalMedia',
  DocumentLinkCollection = 'documentLink',
  EmailRequestCollection = 'emailRequest',
  HyperLinkCollection = 'hyperLink',
  ImageLinkCollection = 'imageLink',
  NoteCollection = 'note',
  ObjectSharingRequestTrackerCollection = 'objectSharingRequestTracker',
  PersistenceMetadataCollection = 'persistenceMetadata',
  SocialMediaPostCollection = 'socialMediaPost',
  ThemeSpecsCollection = 'themeSpecs',
  TopicCollection = 'topic',
  TopicItemCollection = 'topicItem',
  UserCollection = 'user',
  UserCategoriesCollection = 'userCategories',
  VideoLinkCollection = 'videoLink'
}
