import React from 'react';
import Grid from '@mui/material/Grid';
import { IVideoLink } from '../../../../dataObjects/models/digitalMedia/VideoLink';
import { VideoLinksCardGridViewItem } from './VideoLinksCardGridViewItem';
import { IVideoLinkViewModel } from '../../../../dataObjects/viewModels/videoLinkViewModel';


// The next line is preserved for update to React 18, once peer dependencies will allow the update
// export interface IVideoLinksCardGridViewProps extends PropsWithChildren<unknown> {
export interface IVideoLinksCardGridViewProps {
  videoLinkViewModels: Array<IVideoLinkViewModel>;
  onDelete: (videoLink: IVideoLink) => void;
}


export const VideoLinksCardGridView: React.FC<IVideoLinksCardGridViewProps> = (props: IVideoLinksCardGridViewProps) => {
  VideoLinksCardGridView.displayName = 'Video Links Card Grid View';

  // eslint-disable-next-line no-console
  // console.info(`Render VideoLinksCardGridView`);

  const { videoLinkViewModels, onDelete } = props;

  return (
    <>
      <Grid container spacing={2} >
        {
          videoLinkViewModels.map(videoLinkViewModel =>
            <Grid item key={videoLinkViewModel.videoLink.id} xs={12} sm={6} md={4} lg={2} xl={2}>
              <VideoLinksCardGridViewItem videoLinkViewModel={videoLinkViewModel} onDelete={onDelete} />
            </Grid>
          )
        }
      </Grid>
    </>
  );

}