import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsATruthSocialAddress Returns whether the URL is a Truth Social address
 * @param url
 */
export const urlIsATruthSocialAddress: (url: string) => boolean = (url: string) => {

  // get the regular expression pattern for the website
  const regEx: RegExp = RegularExpressions.TruthSocial;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
