import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { ControlsStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import FighterJet from '../../images/FighterJet/FighterJet';
import Collaboration from '../../images/Collaboration/Collaboration';
import WorldGlobeWithLandMassShaded from '../../images/WorldGlobe/WorldGlobeWithLandMassShaded';
import FilingCabinetComplexLightWithOpenMiddleDrawer from '../../images/FilingCabinet/FilingCabinetComplexLightWithOpenMiddleDrawer';
import PersonalAndTeamUse from '../../images/PersonalAndTeamUse/PersonalAndTeamUse';
import DigitalMedia from '../../images/DigitalMedia/DigitalMedia';
import { useNavigate } from 'react-router-dom';
import TennisRacketBallAndCourtAnimation from '../../animations/TennisRacketBallAndCourtAnimation/TennisRacketBallAndCourtAnimation';
import { styled } from '@mui/system';
import { useFirebaseAuth } from '../../customHooks';
import { IFirebaseAuthAndUserAuthenticationStatus } from '../../customHooks/hookReturnObjects';

// the following style object represents Css specs for all of the Details Section Graphics objects
const detailsSectionGraphicStyle: {} = {
  position: 'absolute',
  height: '75%',  // 75% of its parent element
  // we'll use left and transform to position the court in the horizontal & vertical center of the parent element
  left: '50%',  // set left side of court in center of the parent element
  top: '12.5%',  // place the top of the court at 12.5% of the parent element height ((100%-75%)/2)
  transform: 'translate(-50%, -0%)',  // move the court left by 50% of its width
  transition: '500ms ease-in',
};


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));


// a styled Box (equivalent to a <section> here), providing an area to display the animation window
const StyledBoxForAnimationWindow = styled((props) => (
  <Box
    component='section'
    {...props}
  />
))(({ theme }) => ({
  border: 'none', // '1px solid red',
  margin: theme.spacing(0, 2, 2, 2),
  width: '100%',
  maxWidth: theme.breakpoints.values.sm,

  [theme.breakpoints.up('xs')]: {
    height: '150px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '160px',
  },
  [theme.breakpoints.up('md')]: {
    height: '170px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '180px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '200px',
  },
}));

// a styled Box (equivalent to a <section> here), providing an area to display the animation window
const StyledBoxForOverviewSection = styled((props) => (
  <Box
    component='section'
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(2, 2, 1, 2),
  background: theme.emphasisSection.background,
}));

// a styled Typography for the opening paragraph
const StyledTypographyForOpeningParagraph = styled((props) => (
  <Typography
    variant='body1'
    {...props}
  />
))(({ theme }) => ({
  fontWeight: 'bolder',
  color: theme.infoText.color,
}));

// a styled Typography for bullet point in overview section
const StyledTypographyForOverViewBulletPoint = styled((props) => (
  <Typography
    variant='body1'
    component='li'
    {...props}
  />
))(({ theme }) => ({
  color: theme.infoText.color,
}));

// a styled Typography for bullet point in overview section
const StyledTypographyForBulletPointEmphasis = styled((props) => (
  <Typography
    variant='body1'
    component='span'
    {...props}
  />
))(({ theme }) => ({
  fontWeight: 'bolder',
  textDecoration: 'underline',
}));

// a styled Box (equivalent to a <section> here), providing an area to display the details area
const StyledBoxForDetailsSection = styled((props) => (
  <Box
    component='section'
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(2),
  },
}));

// a styled Typography for a details section title
const StyledTypographyForDetailsSectionTitle = styled((props) => (
  <Typography
    variant='body1'
    {...props}
  />
))(({ theme }) => ({
  textAlign: 'left',
  fontWeight: 'bold',
  color: theme.infoText.color,
  margin: theme.spacing(2, 0, 1, 0),

  [theme.breakpoints.up('xs')]: {
    marginTop: theme.spacing(0),
    textAlign: 'center',
  },
}));

// a styled Typography for a details section content
const StyledTypographyForDetailsSectionContent = styled((props) => (
  <Typography
    variant='body1'
    component='div'
    {...props}
  />
))(({ theme }) => ({
  color: theme.infoText.color,
}));

// a styled Grid for overall details section
const StyledGridForDetailsSection = styled((props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for a details block
const StyledGridForDetailsBlock = styled((props) => (
  <Grid
    item
    container
    xs={12} lg={6}
    {...props}
  />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),

  [theme.breakpoints.up('xs')]: {
    marginBottom: theme.spacing(2),
  },
}));

// a styled Grid for a details Graphic section
const StyledGridForDetailsGraphicSection = styled((props) => (
  <Grid
    item
    container
    xs={12} sm={3} md={2} lg={4} xl={3}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid Item for spacing around a Details Graphics
// Designed to be hidden when screen is Small size or larger, used as a spacer when screen is Extra Small
const StyledGridItemForDetailsGraphicSpacing = styled((props) => (
  <Grid
    item
    xs={3} sm={0}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid Item & Container for holding the Details Graphics
// Designed to be hidden when screen is Small size or larger, used as a spacer when screen is Extra Small
const StyledGridItemContainerForHoldingDetailsGraphic = styled((props) => (
  <Grid
    item
    container
    xs={6} sm={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Box (equivalent to a <section> here), providing a bounding box for graphics int the details area
const StyledBoxDetailsGraphicsBoundingBox = styled((props) => (
  <Box
    justifyItems='center'
    alignItems='center'
    {...props}
  />
))(({ theme }) => ({
  border: theme.aboutPageInfoGraphics.boundingBoxBorder,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(2, 2, 2, 0),
  background: theme.aboutPageInfoGraphics.boundingBoxBackground,
  width: '100%',
  position: 'relative',
  transition: '500ms ease-in',

  [theme.breakpoints.up('xs')]: {
    height: '100px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '110px',
  },
  [theme.breakpoints.up('md')]: {
    height: '120px',
  },
  [theme.breakpoints.up('lg')]: {
    height: '140px',
  },
  [theme.breakpoints.up('xl')]: {
    height: '150px',
  },
}));

// a styled Grid for a details Text section
const StyledGridForDetailsTextSection = styled((props) => (
  <Grid
    item
    container
    xs={12} sm={9} md={10} lg={8} xl={9}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for a details Text content
const StyledGridForDetailsTextContent = styled((props) => (
  <Grid
    item
    xs={12}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for the "Get Started" section
const StyledGridForGetStartedSection = styled((props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  margin: theme.spacing(2, 1),
}));

// a styled Grid for the "Get Started" section internal spacing (before and after the Get Started content)
const StyledGridForGetStartedSectionInternalSpacing = styled((props) => (
  <Grid
    item
    xs={1} sm={2} md={3}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Grid for the "Get Started" section content
const StyledGridForGetStartedSectionContent = styled((props) => (
  <Grid
    item
    xs={10} sm={8} md={6}
    {...props}
  />
))(({ theme }) => ({
}));

// a styled Button for the "Get Started" content
const StyledButtonForGetStartedContent = styled((props) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

// a styled SVG for Digital Media
const StyledSvgForDigitalMedia = styled((props) => (
  <DigitalMedia
    {...props}
  />
))(({ theme }) => (
  detailsSectionGraphicStyle
));

// a styled SVG for FilingCabinetComplexLightWithOpenMiddleDrawer
const StyledSvgForFilingCabinet = styled((props) => (
  <FilingCabinetComplexLightWithOpenMiddleDrawer
    {...props}
  />
))(({ theme }) => (
  detailsSectionGraphicStyle
));

// a styled SVG for WorldGlobeWithLandMassShaded
const StyledSvgForWorldGlobe = styled((props) => (
  <WorldGlobeWithLandMassShaded
    {...props}
  />
))(({ theme }) => (
  detailsSectionGraphicStyle
));

// a styled SVG for FighterJet
const StyledSvgForFightJet = styled((props) => (
  <FighterJet
    {...props}
  />
))(({ theme }) => (
  detailsSectionGraphicStyle
));

// a styled SVG for Collaboration
const StyledSvgForCollaboration = styled((props) => (
  <Collaboration
    {...props}
  />
))(({ theme }) => (
  detailsSectionGraphicStyle
));

// a styled SVG for PersonalAndTeamUse
const StyledSvgForPersonalAndTeamUse = styled((props) => (
  <PersonalAndTeamUse
    {...props}
  />
))(({ theme }) => (
  detailsSectionGraphicStyle
));

export interface IAboutPageProps extends PropsWithChildren<unknown> {

}

const AboutPage: React.FC<IAboutPageProps> = (props: IAboutPageProps) => {
  console.info('Entering AboutPage...');

  AboutPage.displayName = 'About Page';

  const navigate = useNavigate();

  // whether user is authenticated
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  // get the Firebase auth state using a custom hook
  const {auth, userAuthenticated: userIsAuthenticated}: IFirebaseAuthAndUserAuthenticationStatus = useFirebaseAuth();

  // anytime the auth object changes in state, we'll set whether the user is authenticated
  useEffect(() => {
    // set whether user is authenticated
    // setUserAuthenticated(auth.uid !== undefined);
    setUserAuthenticated(userIsAuthenticated);
  }, [auth]);


  const digitalMedia: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const filingCabinet: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const fighterJet: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const collaboration: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const personalAndTeamUse: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);
  const worldGlobe: React.RefObject<SVGSVGElement> = useRef<SVGSVGElement>(null);


  return (
    <>
      {/* <div className={classes.paddingAtopPage} /> */}
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="xl"
        showBackButton={false}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_About}
      >
        <>

          <StyledBoxForAnimationWindow >
            <TennisRacketBallAndCourtAnimation />
          </StyledBoxForAnimationWindow>

          <StyledBoxForOverviewSection>
            <StyledTypographyForOpeningParagraph>
              MyTennisBrain is the #1 choice for tennis players, coaches, teams, tennis families &amp; friends, and tennis aficionados!
            </StyledTypographyForOpeningParagraph>
            <ul>
              <StyledTypographyForOverViewBulletPoint>
                <StyledTypographyForBulletPointEmphasis>
                  Players
                </StyledTypographyForBulletPointEmphasis>
                : Gain rapid improvements, collaborate with coaches, prepare tips &amp; strategies, and track performance using digital media.
              </StyledTypographyForOverViewBulletPoint>
              <StyledTypographyForOverViewBulletPoint>
                <StyledTypographyForBulletPointEmphasis>
                  Coaches
                </StyledTypographyForBulletPointEmphasis>
                : Accelerate the progress of your players, providing both you and your players with new tools and capabilities.
              </StyledTypographyForOverViewBulletPoint>
              <StyledTypographyForOverViewBulletPoint>
                <StyledTypographyForBulletPointEmphasis>
                  Teams
                </StyledTypographyForBulletPointEmphasis>
                : Collaborate on team activities (league seasons, matches, celebrations, etc.) and share videos, photos, notes, etc.
              </StyledTypographyForOverViewBulletPoint>
              <StyledTypographyForOverViewBulletPoint>
                <StyledTypographyForBulletPointEmphasis>
                  Tennis Families &amp; Friends
                </StyledTypographyForBulletPointEmphasis>
                : Collect and manage information about family members and friends.
              </StyledTypographyForOverViewBulletPoint>
              <StyledTypographyForOverViewBulletPoint>
                <StyledTypographyForBulletPointEmphasis>
                  Tennis Aficionados
                </StyledTypographyForBulletPointEmphasis>
                : Capture, organize, and share information about favorite players, tennis events, destinations.
              </StyledTypographyForOverViewBulletPoint>
            </ul>
          </StyledBoxForOverviewSection>

          <StyledBoxForDetailsSection>
            <StyledGridForDetailsSection>
              <StyledGridForDetailsBlock>
                <StyledGridForDetailsGraphicSection>
                  <StyledGridItemForDetailsGraphicSpacing />
                  <StyledGridItemContainerForHoldingDetailsGraphic >
                    <StyledBoxDetailsGraphicsBoundingBox>
                      <StyledSvgForDigitalMedia ref={digitalMedia} />
                    </StyledBoxDetailsGraphicsBoundingBox>
                  </StyledGridItemContainerForHoldingDetailsGraphic>
                  <StyledGridItemForDetailsGraphicSpacing />
                </StyledGridForDetailsGraphicSection>
                <StyledGridForDetailsTextSection>
                  <StyledGridForDetailsTextContent>
                    <StyledTypographyForDetailsSectionTitle>
                      Consolidate &amp; Manage Digital Media Assets
                    </StyledTypographyForDetailsSectionTitle>
                    <StyledTypographyForDetailsSectionContent>
                      Consolidate and manage your selected videos, photos &amp; images, audio clips, notes, and hyperlinks, focusing on the information most useful to you.
                    </StyledTypographyForDetailsSectionContent>
                  </StyledGridForDetailsTextContent>
                </StyledGridForDetailsTextSection>
              </StyledGridForDetailsBlock>

              <StyledGridForDetailsBlock>
                <StyledGridForDetailsGraphicSection>
                  <StyledGridItemForDetailsGraphicSpacing />
                  <StyledGridItemContainerForHoldingDetailsGraphic >
                    <StyledBoxDetailsGraphicsBoundingBox>
                      <StyledSvgForFilingCabinet ref={filingCabinet} />
                    </StyledBoxDetailsGraphicsBoundingBox>
                  </StyledGridItemContainerForHoldingDetailsGraphic>
                  <StyledGridItemForDetailsGraphicSpacing />
                </StyledGridForDetailsGraphicSection>
                <StyledGridForDetailsTextSection>
                  <StyledGridForDetailsTextContent>
                    <StyledTypographyForDetailsSectionTitle>
                      Organize Information the Way <span style={{ textDecoration: 'underline', fontStyle: 'italic' }}>You</span> Like
                    </StyledTypographyForDetailsSectionTitle>
                    <StyledTypographyForDetailsSectionContent>
                      Define how you want to organize your information, maximizing your efficiency.
                    </StyledTypographyForDetailsSectionContent>
                  </StyledGridForDetailsTextContent>
                </StyledGridForDetailsTextSection>
              </StyledGridForDetailsBlock>

              <StyledGridForDetailsBlock>
                <StyledGridForDetailsGraphicSection>
                  <StyledGridItemForDetailsGraphicSpacing />
                  <StyledGridItemContainerForHoldingDetailsGraphic >
                    <StyledBoxDetailsGraphicsBoundingBox>
                      <StyledSvgForWorldGlobe ref={worldGlobe} />
                    </StyledBoxDetailsGraphicsBoundingBox>
                  </StyledGridItemContainerForHoldingDetailsGraphic>
                  <StyledGridItemForDetailsGraphicSpacing />
                </StyledGridForDetailsGraphicSection>
                <StyledGridForDetailsTextSection>
                  <StyledGridForDetailsTextContent>
                    <StyledTypographyForDetailsSectionTitle>
                      Access from Anywhere
                    </StyledTypographyForDetailsSectionTitle>
                    <StyledTypographyForDetailsSectionContent>
                      Access your information from anywhere in the world at any time.
                    </StyledTypographyForDetailsSectionContent>
                  </StyledGridForDetailsTextContent>
                </StyledGridForDetailsTextSection>
              </StyledGridForDetailsBlock>

              <StyledGridForDetailsBlock>
                <StyledGridForDetailsGraphicSection>
                  <StyledGridItemForDetailsGraphicSpacing />
                  <StyledGridItemContainerForHoldingDetailsGraphic >
                    <StyledBoxDetailsGraphicsBoundingBox>
                      <StyledSvgForFightJet ref={fighterJet} />
                    </StyledBoxDetailsGraphicsBoundingBox>
                  </StyledGridItemContainerForHoldingDetailsGraphic>
                  <StyledGridItemForDetailsGraphicSpacing />
                </StyledGridForDetailsGraphicSection>
                <StyledGridForDetailsTextSection>
                  <StyledGridForDetailsTextContent>
                    <StyledTypographyForDetailsSectionTitle>
                      Exceptionally Fast Performance
                    </StyledTypographyForDetailsSectionTitle>
                    <StyledTypographyForDetailsSectionContent>
                      Experience exceptionally fast application performance (limited only by your internet connection).
                    </StyledTypographyForDetailsSectionContent>
                  </StyledGridForDetailsTextContent>
                </StyledGridForDetailsTextSection>
              </StyledGridForDetailsBlock>

              <StyledGridForDetailsBlock>
                <StyledGridForDetailsGraphicSection>
                  <StyledGridItemForDetailsGraphicSpacing />
                  <StyledGridItemContainerForHoldingDetailsGraphic >
                    <StyledBoxDetailsGraphicsBoundingBox>
                      <StyledSvgForCollaboration ref={collaboration} />
                    </StyledBoxDetailsGraphicsBoundingBox>
                  </StyledGridItemContainerForHoldingDetailsGraphic>
                  <StyledGridItemForDetailsGraphicSpacing />
                </StyledGridForDetailsGraphicSection>
                <StyledGridForDetailsTextSection>
                  <StyledGridForDetailsTextContent>
                    <StyledTypographyForDetailsSectionTitle>
                      Share &amp; Collaborate
                    </StyledTypographyForDetailsSectionTitle>
                    <StyledTypographyForDetailsSectionContent>
                      Share information between players, coaches, &amp; family members, and collaborate within designated channels.
                    </StyledTypographyForDetailsSectionContent>
                  </StyledGridForDetailsTextContent>
                </StyledGridForDetailsTextSection>
              </StyledGridForDetailsBlock>

              <StyledGridForDetailsBlock>
                <StyledGridForDetailsGraphicSection>
                  <StyledGridItemForDetailsGraphicSpacing />
                  <StyledGridItemContainerForHoldingDetailsGraphic >
                    <StyledBoxDetailsGraphicsBoundingBox>
                      <StyledSvgForPersonalAndTeamUse ref={personalAndTeamUse} />
                    </StyledBoxDetailsGraphicsBoundingBox>
                  </StyledGridItemContainerForHoldingDetailsGraphic>
                  <StyledGridItemForDetailsGraphicSpacing />
                </StyledGridForDetailsGraphicSection>
                <StyledGridForDetailsTextSection>
                  <StyledGridForDetailsTextContent>
                    <StyledTypographyForDetailsSectionTitle>
                      Personal or Team Use
                    </StyledTypographyForDetailsSectionTitle>
                    <StyledTypographyForDetailsSectionContent>
                      Use for your own needs, or work with teams of people.
                    </StyledTypographyForDetailsSectionContent>
                  </StyledGridForDetailsTextContent>
                </StyledGridForDetailsTextSection>
              </StyledGridForDetailsBlock>
            </StyledGridForDetailsSection>
          </StyledBoxForDetailsSection>

          {/* if user isn't authenticated (hasn't logged in), display the "Get Started Today" button that will redirect to the Registration page */}
          {!userAuthenticated &&
            <StyledGridForGetStartedSection>
              <StyledGridForGetStartedSectionInternalSpacing />
              <StyledGridForGetStartedSectionContent>
                <StyledButtonForGetStartedContent onClick={() => navigate('/createNewAccount')} >
                  {ControlsStringAssets.getStartedTodayButtonText}
                </StyledButtonForGetStartedContent>
              </StyledGridForGetStartedSectionContent>
              <StyledGridForGetStartedSectionInternalSpacing />
            </StyledGridForGetStartedSection>
          }
        </>
      </GenericPageContainer>
    </>
  );

}

export default AboutPage;
