import React, { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Preview Container
const StyledBoxForFormBody = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(1),
  border: theme.form.border,
  borderRadius: theme.form.borderRadius,
  padding: theme.spacing(2)
}));

export interface IFormProps extends PropsWithChildren<unknown> {
  // method to call upon form submission
  onSubmit: () => void;
  // children?: React.ReactNode;
  props?: Record<string, string>
}

const Form: React.FC<IFormProps> = ({ children, onSubmit, ...props }) => {

  return (
    <StyledBoxForFormBody>
      <form noValidate onSubmit={onSubmit} {...props}>
        {/* if there are any children to pass on (ie, children !== undefined), pass them on; otherwise, pass an empty React fragment */}
        <>
          {children && children}
        </>
      </form>
    </StyledBoxForFormBody>
  );

}

export default Form;