import React, { useState, useEffect, useCallback, PropsWithChildren } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { enumWorkflowState, enumObjectPersistenceState, enumPersistableObjectType, enumAudioLinkType, enumBeaconType } from '../../../dataObjects/enums';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { typeUniqueId } from '../../../dataObjects/types';
import { IAudioLink, AudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { IUser } from '../../../dataObjects/models/users/User';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import AudioLinkForm from '../../forms/AudioLinkForm/AudioLinkForm';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { ISaveAudioLinkRequest } from '../../../dataObjects/models/serviceRequests/audioLink/SaveAudioLinkRequest';
import { ISaveAudioLinkWorkflowState } from '../../../uiMiddleware-redux/store/SaveAudioWorkflowState';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { audioLinkSaveStatusClear, audioLinkSaveWithFileUploadRequest, audioLinkSaveWithoutFileUploadRequest, audioLinkSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/audioLink/audioLinkSaveStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';

/**
 * @interface IManageAudioLinkPage Input properties for the ManageAudioLinkPage
 */
export interface IManageAudioLinkPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the AudioLink already exists
  parentId?: typeUniqueId  // if the parentId is given (parentId of the AudioLink parent object), it means that a new AudioLink is to be created
}

/**
 * @function ManageAudioLinkPage A React Function Component for manageing (creating or editing) a AudioLink.
 * @param {IManageAudioLinkPageProps} props Input properties
 */
const ManageAudioLinkPage: React.FC<IManageAudioLinkPageProps> = (props: IManageAudioLinkPageProps) => {

  ManageAudioLinkPage.displayName = "Manage Audio Link Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [audioLinkId, setAudioLinkId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the audioLinkId anytime the params.id changes
  useEffect(() => {
    setAudioLinkId(params.id);
  }, [params.id]);

  // define an effect based on a change to the audioLinkId. Having a defined audioLinkId means that we will 
  // be editing an existing AudioLink
  useEffect(() => {
    if (audioLinkId !== undefined) {
      // try to find an AudioLink object with the Id given in the URL
      getSingleObjectById(audioLinkId, enumPersistableObjectType.AudioLink, onAudioLinkFetched);
    }
  }, [audioLinkId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [audioLinkParentId, setAudioLinkParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the audioLinkParentId anytime the params.parentId changes
  useEffect(() => {
    setAudioLinkParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the audioLink to be managed, either a new audioLink or an existing one
  const [audioLink, setAudioLink] = useState<IAudioLink | undefined>(undefined);

  // whether we are going to be managing a new audioLink
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveAudioLinkStatus: () => void = useCallback(() => {
    dispatch(audioLinkSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveAudioLinkStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveAudioLinkStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveAudioLinkStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving an AudioLink
  const [saveAudioWorkflowState, setSaveAudioWorkflowState] = useState<ISaveAudioLinkWorkflowState | null | undefined>(undefined);

  // indicates whether the page has been successfully completed (for saving new object or updating existing)
  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the audioLink save status store state variable
      dispatch(audioLinkSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // the success message for AudioLink will merely be the defined constant
      const successMessage: string = MessagesStringAssets.audioLink_SaveSuccess;
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveAudioLink, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, navigate]);

  // get the Redux workflow state for saving an AudioLink
  const currentSaveAudioLinkWorkflowState: ISaveAudioLinkWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveAudioLinkStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveAudioWorkflowState(currentSaveAudioLinkWorkflowState);
  }, [currentSaveAudioLinkWorkflowState]);

  /**
   * @function prepareNewAudioLink Prepares/creates a new audioLink that will be used if user is requesting to create/add a new audioLink.
   * @param parentChannel 
   */
  const prepareNewAudioLink: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => IAudioLink = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newAudioLink: IAudioLink;

      const newAudioLinkId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newAudioLink = new AudioLink(user.id, newAudioLinkId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        enumAudioLinkType.GoogleCloudStorage, '', '', '', '', undefined, undefined, userPersistenceData);

      return newAudioLink;
    }, []
  );

  // define an effect based on a change to the audioLinkParentId & currentUser. Having a defined audioLinkParentId means that we will
  // be creating a new AudioLink
  useEffect(() => {
    if (audioLinkParentId !== undefined && currentUser) {
      setAudioLink(prepareNewAudioLink(audioLinkParentId, currentUser, currentUser.userSettings));
    }
  }, [audioLinkParentId, currentUser, prepareNewAudioLink]);


  function onAudioLinkFetched(audioLink: IAudioLink | undefined): void {
    if (audioLink !== undefined) {
      // set the returned AudioLink to be the one we are editing
      setAudioLink(audioLink);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
    * @method handleFormSubmit Handles a submit request from the form in this page
    * @param {ISaveAudioLinkRequest} saveAudioLinkRequest A Submit event
    */
  function handleFormSubmit(saveAudioLinkRequest: ISaveAudioLinkRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {

        // dispatch an appropriate action to request saving the audioLink
        // if there is a file to be uploaded, dispatch an action that includes a file upload; 
        // otherwise, merely dispatch an action to save the audioLink only
        if (saveAudioLinkRequest.fileUploadRequest !== undefined) {
          dispatch(audioLinkSaveWithFileUploadRequest(saveAudioLinkRequest));
        } else {
          dispatch(audioLinkSaveWithoutFileUploadRequest(saveAudioLinkRequest));
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold a progress message
  let progressMessage: string | undefined = undefined;

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;
  if (saveAudioWorkflowState !== null && saveAudioWorkflowState !== undefined) {

    if (saveAudioWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveAudioWorkflowState.workflowState === enumWorkflowState.InProgress || saveAudioWorkflowState.workflowState === enumWorkflowState.Requested) {
      // get message from the workflow state to display as a progress message
      progressMessage = saveAudioWorkflowState.workflowStateMessage ?? undefined;

      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveAudioWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveAudioWorkflowState.workflowError && saveAudioWorkflowState.workflowError instanceof Error) {
        failureMessage = saveAudioWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }

  return (
    <>
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageAudioLink_New : PageAndViewTitleStringAssets.pageTitle_ManageAudioLink_Existing}
        onCloseAlert={resetSaveAudioLinkStatus}
      >
        {audioLink && currentUser &&
          <AudioLinkForm
            userId={currentUser.id}
            audioLink={audioLink}
            saveRequestInProgress={saveInProgress}
            progressMessage={progressMessage}
            onSubmit={handleFormSubmit}
          />
        }

      </GenericPageContainer>
    </>
  );

} // const ManageAudioLinkUploadPage...

export default ManageAudioLinkPage;
