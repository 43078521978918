import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';
import { typeUniqueId, typePersistableObjectType, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../types';

/** 
 * @class FieldValueValidation provides static validation methods for field values
 */
export class FieldValueValidation {

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateDefinedNumberIsNonNegative Ensures that the value provided, if defined,
   * is not a negative number.
   * @param {number} value A number value
   * @param {string} errorMessage The error message to emit
   */
  static validateNumberIsNonNegative(value: number, errorMessage: string): void {
    if (value < 0) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateDefinedNumberIsNonNegative Ensures that the value provided, if defined,
   * is not a negative number.
   * @param {number | undefined} value A number value, or undefined
   * @param {string} errorMessage The error message to emit
   */
  static validateDefinedNumberIsNonNegative(value: number | undefined, errorMessage: string): void {
    if (value !== undefined && value < 0) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateOneNumberIsLessThanAnotherNumber Ensures that one number value is less than
   * another number value.
   * @param {number} value1 A number value
   * @param {number} value2 Another number value
   * @param {string} errorMessage The error message to emit
   */
  static validateOneNumberIsLessThanAnotherNumber(value1: number, value2: number, errorMessage: string): void {
    if (value1 >= value2) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateOneNumberIsLessThanAnotherNumber Ensures that one number value is not greater than
   * another number value.
   * @param {number} value1 A number value
   * @param {number} value2 Another number value
   * @param {string} errorMessage The error message to emit
   */
  static validateOneNumberIsNotGreaterThanAnotherNumber(value1: number, value2: number, errorMessage: string): void {
    if (value1 > value2) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateUniqueIdHasValue Ensures that the 'id' provided has a value
   * @param {typeUniqueId} id A typeUnique id variable
   * @param {string} errorMessage The error message to emit
   */
  static validateUniqueIdHasValue(id: typeUniqueId, errorMessage: string): void {
    if (id === undefined || id.length === 0) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateUniqueIdHasValueIfDefined Ensures that if the 'id' is define (not 'undefined'),
   * it must have a value (a non-empty string)
   * @param {typeUniqueIdWithUndefinedOption} id A typeUniqueIdWithUndefinedOption id variable
   * @param {string} errorMessage The error message to emit
   */
  static validateUniqueIdHasValueIfDefined(id: typeUniqueIdWithUndefinedOption, errorMessage: string): void {
    if (id !== undefined) {
      if (id.length === 0) {
        throw new RangeError(errorMessage);
      }
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateObjectTypeIsValid Ensures that the object type is valid
   * @param {typePersistableObjectType} objectType A typePersistableObjectType variable
   * @param {string} errorMessage The error message to emit
   */
  static validateObjectTypeIsValid(objectType: typePersistableObjectType, errorMessage: string): void {
    if (objectType === undefined) {
      throw new MdbError(errorMessage, enumMdbErrorType.ObjectTypeIsInvalid);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateParentObjectTypeIsValid Ensures that the object type is valid
   * @param {typePersistableParentObjectType} objectType A typePersistableParentObjectType variable
   * @param {string} errorMessage The error message to emit
   */
  static validateParentObjectTypeIsValid(objectType: typePersistableParentObjectType, errorMessage: string): void {
    if (objectType === undefined) {
      throw new MdbError(errorMessage, enumMdbErrorType.ObjectTypeIsInvalid);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method validateStringValueIsPresent Ensures that a string variable has a value
   * @param {string} stringVariable A string variable
   * @param {string} errorMessage The error message to emit
   */
  static validateStringValueIsPresent(stringVariable: string, errorMessage: string): void {
    if (stringVariable === undefined || stringVariable.length === 0) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method validateUrlIsValid Ensures that a Url value is legit
   * @param {string} urlValue A Url 
   * @param {string} errorMessage The error message to emit
   */
  static validateUrlValue(urlValue: string, errorMessage: string): void {
    // eslint-disable-next-line no-useless-escape
    const urlRegEx = new RegExp('@^(https?|ftp)://[^\s/$.?#].[^\s]*$@iS');
    if (!urlRegEx.test(urlValue)) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method validateImageUrlIsValid Ensures that an Image Url value is legit
   * @param {string} imageUrlValue A Url 
   * @param {string} errorMessage The error message to emit
   */
  static validateImageUrlValue(imageUrlValue: string, errorMessage: string): void {
    // eslint-disable-next-line no-useless-escape
    const imageUrlRegEx = new RegExp('(?:([^:/?#]+):)?(?://([^/?#]*))?([^?#]*\.(?:jpg|jpeg|bmp|gif|png))(?:\\?([^#]*))?(?:#(.*))?');
    if (!imageUrlRegEx.test(imageUrlValue)) {
      throw new RangeError(errorMessage);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method FieldValueValidation.validateValueIsExpectedValue Ensures that the value is what is expected
   * @param value The value being evaluated
   * @param expectedValue The expected value
   * @param {string} errorMessage The error message to emit
   */
  static validateValueIsExpectedValue<T>(value: T, expectedValue: T, errorMessage: string): void {
    if (value !== expectedValue) {
      throw new EvalError(`${errorMessage} (value: "${value}"; expectedValue: "${expectedValue}")`);
    }
  }
  /*-----------------------------------------------*/

}
