import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreUserCategoriesRepository_Ext, IFirestoreUserCategoriesRepository_Ext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserCategoriesRepository";


  /**
   * @method getUserCategoriesForUserAndChannel_onSnapshot Makes an onSnapshot() request to firestore for retrieving all UserCategories from the database associated with 
   *   a given Channel, per it's Id. A firebase onSnapshot() request sets up a subscription to firebase to dynamically update the results.
   * @param {typeUniqueId} userId The unique Id of the User of the objects to retrieve.
   * @param {typeUniqueId} channelId The unique Id of the Channel to search for as a child of a Category owned by the user.
   * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback method that receives document data as a parm and has a void return value 
   * @returns {() => void} An 'unsubscribe callback' method that is to be called when the snapshot is no longer needed.
   */
  export function getUserCategoriesForUserAndChannel_onSnapshot(userId: typeUniqueId, channelId: typeUniqueId, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<() => void> {
    return new Promise<() => void>(async (resolve, reject) => {
      try {
        // initialize a Firestore object instance
        const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

        const firestoreUserCategoryRepository_Ext: IFirestoreUserCategoriesRepository_Ext = new FirestoreUserCategoriesRepository_Ext(firestoreObj);

        const unsubscribeCallback: () => void = await firestoreUserCategoryRepository_Ext.getUserCategoriesForUserAndChannel_onSnapshot(userId, channelId, callback);
        // console.log(`getUserCategoriesForUser_onSnapshot (UI middleware service method) before returning, unsubscribeCallback: ${unsubscribeCallback}`);

        resolve(unsubscribeCallback);
      }
      catch (error: any) {
        reject(error);
      }
    });
  }
