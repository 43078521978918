import { typeUniqueId } from '../../../types';
import { enumSharingPermission } from '../../../enums';
import { IUserSharingPermissions, IUserSharingPermissionsAsJson } from ".";

/** 
 * @class UserSharingPermissions Represents user settings and preferences
 */
export class UserSharingPermissions implements IUserSharingPermissions {
  /**
   * @method Constructor method
   * @param {typeUniqueId} userId The Id of the user to which sharing permissions apply
   * @param {enumSharingPermission} sharingPermission Sharing permission granted to the user
   */
  constructor(
    userId: typeUniqueId,
    sharingPermission: enumSharingPermission
  ) {
    this.userId = userId;
    this.sharingPermission = sharingPermission
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _userId Id of the user to which sharing permission applies
   */
  private _userId: typeUniqueId = '';

  /**
   * @method userId Getter method for the user's Id
   */
  get userId(): typeUniqueId {
    return this._userId;
  }

  /**
   * @method userId Setter method for the user's Id
   * @param {typeUniqueId} value The value to be used to set the user's Id
   */
  set userId(value: typeUniqueId) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSharingPermission} _sharingPermission The sharing permssion granted to the user
   */
  private _sharingPermission: enumSharingPermission = enumSharingPermission.ViewContent;

  /**
   * @method sharingPermission Getter method for the user's sharing permission
   */
  get sharingPermission(): enumSharingPermission {
    return this._sharingPermission;
  }

  /**
   * @method sharingPermission Setter method for the user's sharing permission
   * @param {enumSharingPermission} value The value to be used to set the user's sharing permission
   */
  set sharingPermission(value: enumSharingPermission) {
    this._sharingPermission = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IUserSharingPermissions} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserSharingPermissions {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserSharingPermissions = Object.create(UserSharingPermissions.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    
    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IUserSharingPermissionsAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IUserSharingPermissionsAsJson = Object.create(UserSharingPermissions.prototype);

      // copy any additional field values to the json object 
      jsonObject.userId = this.userId;
      jsonObject.sharingPermission = this.sharingPermission;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects for this class
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IUserSharingPermissionsAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A UserSharingPermissions instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IUserSharingPermissionsAsJson, includeContainedObjects: boolean = true): IUserSharingPermissions {
    try {
      // create a new instance of this class
      let userSharingPermissionsObject: UserSharingPermissions = Object.create(UserSharingPermissions.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      
      // copy any additional field values from the json object 
      if (jsonObject.userId !== undefined) {
        userSharingPermissionsObject.userId = jsonObject.userId;
      }

      if (jsonObject.sharingPermission !== undefined) {
        userSharingPermissionsObject.sharingPermission = jsonObject.sharingPermission;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // there are no contained objects to include
      }

      return userSharingPermissionsObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
