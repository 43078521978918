import { enumImageLinkType } from '../';
import { MdbError } from '../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../errorObjects/enums';

/**
 * @class enumImageLinkTypeConvert Provides to/from string conversions for enumImageLinkType values.
 */
export class enumImageLinkTypeConvert {
  public static toString(imageLinkTypeEnum: enumImageLinkType): string {
    return imageLinkTypeEnum.toString();
  }

  public static fromString(imageLinkTypeString: string): enumImageLinkType {
    let imageLinkTypeEnum: enumImageLinkType = enumImageLinkType.GoogleCloudStorage;

    switch (imageLinkTypeString) {
      case enumImageLinkTypeConvert.toString(enumImageLinkType.GoogleCloudStorage):
        imageLinkTypeEnum = enumImageLinkType.GoogleCloudStorage;
        break;

      case enumImageLinkTypeConvert.toString(enumImageLinkType.ExternalSource):
        imageLinkTypeEnum = enumImageLinkType.ExternalSource;
        break;

      default:
        throw new MdbError(`enumImageLinkTypeConvert.fromString. Unrecognized/unsupported enumImageLinkType string: ${imageLinkTypeString}`, enumMdbErrorType.InvalidOperation);
    }

    return imageLinkTypeEnum;
  }
}