import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface IFighterJetParms {
  className?: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  boundingRect: {
    fill: 'none',
  },

  groupFighterJet: {
    clipPath: 'url(#clipPathBoundingRect)',
  },

  fighterJet: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },


}));


// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const FighterJet = React.forwardRef((props: IFighterJetParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1675.37 1675.37" >
      <defs>
        <clipPath id="clipPathBoundingRect" transform="translate(-389.32 -397.49)">
          <rect className={classes.boundingRect} width="2454" height="2454" />
        </clipPath>
      </defs>

      <g className={classes.groupFighterJet}>
        <path className={classes.fighterJet} d="M2064.64,397.54c-49.33-2.46-226.32,99.4-391.07,225.8C1655.84,637,1472.73,816.4,1455.43,830.47l134.15-175.85-31.25-10.33L1400.52,761.44,1259.13,822.7l-229,198.68L1174,845.05,632.81,830.48,557,892.19c4.86-5.79,61.6-73.7,65.79-79,24.07-30.59,36.61-52.95,31.13-58.54-9.84-9.78-72.86,37.44-141.06,105.51s-132.34,148.15-122.53,158c4.05,4,16.9-1.46,35-13.7l438.77,241.06,170.86-132.09L795.38,1350.26,575.15,1423l-33.24,107.45L683,1552.16l-37.9,63.5,44.73,23.87c-15.22,23.18-21.11,43.07-12.93,51.25,9.28,9.35,33.84.67,60.79-19.38l35.88,17.21,17.23,35.89c-20,27-28.74,51.5-19.4,60.78,8.18,8.19,28.07,2.31,51.26-12.91l23.87,44.72L910,1779.18l21.7,141.08L1039.14,1887l72.78-220.23,236.91-239.52-132.09,170.85,241,438.76c-12.22,18.09-17.73,30.94-13.7,35,9.81,9.8,89.9-54.47,158-122.54s115.29-131.21,105.51-141c-5.58-5.48-28,7.06-58.54,31.13-5.32,4.19-73.25,60.94-79,65.79l61.71-75.84-14.58-541.13L1440.8,1432.09l198.69-229,61.26-141.39,117.15-157.8-10.34-31.24-175.85,134.14c14.07-17.32,193.5-200.41,207.13-218.16C1965.23,623.87,2067.1,446.87,2064.64,397.54Z" transform="translate(-389.32 -397.49)" />
      </g>

    </svg >
  )
});

export default FighterJet;
