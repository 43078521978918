import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName, enumSharingRequestStatus, enumSharableObjectType, enumSharingPermission } from '../../../enums';
import { VersionAwarePersistable } from "../../persistence/VersionAwarePersistable";
import { IObjectSharingRequestTracker, IObjectSharingRequestTrackerAsJson } from ".";
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';

/** 
 * @class ObjectSharingRequestTracker Tracks the collaboration sharing information for an object
 */
export class ObjectSharingRequestTracker extends VersionAwarePersistable implements IObjectSharingRequestTracker {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {typeUniqueId} sharingObjectId The unique Id of the object being shared via the sharing request
   * @param {enumSharableObjectType} sharingObjectType The type of object being shared via the sharing request
   * @param {string} sharingObjectName The name of the object, at the time of the initial request, being shared via the sharing request
   * @param {typeUniqueId} requestorUserId The User Id of the user who initiated the sharing request
   * @param {string} requestorName The Name of the user who initiated the sharing request (at the time the request was initiated)
   * @param {string} requestorEmail The Email Address of the user who initiated the sharing request
   * @param {string} recipientName The Name of the user who is the recipient of the sharing request
   * @param {string} recipientEmail The Email Address of the user who is the recipient of the sharing request
   * @param {enumSharingPermission} sharingPermission The sharing permission being granted to the recipient of the sharing request
   * @param {typeUniqueId | undefined} recipientUserId (optional) UserId of the user who is the recipient of the sharing request
   * @param {string | undefined} revokerEmail (optional) The Email Address of the user who revoked the sharing
   * @param {typeUniqueId | undefined} revokerUserId (optional) The User Id of the user who revoked the sharing
   * @param {Date} requestTimestamp (optional) A timestamp (in UTC) representing the Date/Time when the sharing request was initiated.
   * @param {Date | undefined} acceptanceTimestamp (optional) A timestamp (in UTC) representing the Date/Time when the sharing request was accepted by the recipient
   * @param {Date | undefined} declinedTimestamp (optional) A timestamp (in UTC) representing the Date/Time when the sharing request was declined by the recipient
   * @param {Date | undefined} revokedTimestamp (optional) A timestamp (in UTC) representing the Date/Time when the sharing request was revoked.
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   */
  /**
   * @method Constructor method
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    originatingDomain: string,
    sharingObjectId: typeUniqueId,
    sharingObjectType: enumSharableObjectType,
    sharingObjectName: string,
    requestorUserId: typeUniqueId,
    requestorName: string,
    requestorEmail: string,
    recipientName: string,
    recipientEmail: string,
    sharingPermission: enumSharingPermission,
    userPersistenceData?: IUserPersistenceData,
    recipientUserId?: typeUniqueId | undefined,
    revokerEmail?: string | undefined,
    revokerUserId?: typeUniqueId | undefined,
    requestTimestamp?: Date,
    acceptanceTimestamp?: Date | undefined,
    declinedTimestamp?: Date | undefined,
    revokedTimestamp?: Date | undefined,
  ) {
    super(ownerId, enumPersistableObjectClassName.ObjectSharingRequestTracker, enumPersistableObjectType.ObjectSharingRequestTracker, id, parentObjectType, parentId, objectState, userPersistenceData);

    // assign required constructor parameters
    this._originatingDomain = originatingDomain;
    this._sharingObjectId = sharingObjectId;
    this._sharingObjectType = sharingObjectType;
    this._sharingObjectName = sharingObjectName;
    this._requestorUserId = requestorUserId;
    this._requestorName = requestorName;
    this._requestorEmail = requestorEmail;
    this._recipientName = recipientName;
    this._recipientEmail = recipientEmail;
    this._sharingPermission = sharingPermission;

    // assign optional constructor parameters
    if (recipientUserId !== undefined) {
      this._recipientUserId = recipientUserId;
    }
    if (revokerUserId !== undefined) {
      this._revokerUserId = revokerUserId;
    }
    if (revokerEmail !== undefined) {
      this._revokerEmail = revokerEmail;
    }
    if (requestTimestamp !== undefined) {
      this._requestTimestamp = requestTimestamp;
    }
    if (acceptanceTimestamp !== undefined) {
      this._acceptanceTimestamp = acceptanceTimestamp;
    }
    if (declinedTimestamp !== undefined) {
      this._declinedTimestamp = declinedTimestamp;
    }
    if (revokedTimestamp !== undefined) {
      this._revokedTimestamp = revokedTimestamp;
    }
  }


  /*-----------------------------------------------*/
  /**
   * @property {enumSharingRequestStatus} status The status of sharing request
   */
  private _status: enumSharingRequestStatus = enumSharingRequestStatus.New;

  /**
   * @method status Getter method for _status
   * @returns {enumSharingRequestStatus} The value for the status
   */
  get status(): enumSharingRequestStatus {
    return this._status;
  }

  /**
   * @method status Setter method for _status
   * @param {enumSharingRequestStatus} value The value for setting _status
   */
  set status(value: enumSharingRequestStatus) {
    this._status = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} originatingDomain The originating domain from which the sharing request was submitted
   */
   private _originatingDomain: string;

   /**
    * @method originatingDomain A getter method for _originatingDomain
    * @returns {string} The originating domain from which the sharing request was submitted
    */
   get originatingDomain(): string {
     return this._originatingDomain;
   }
 
   /**
    * @method originatingDomain A setter method for _originatingDomain
    * @param {string} value The value for setting _originatingDomain
    */
   set originatingDomain(value: string) {
     this._originatingDomain = value;
   }
   /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} sharingObjectId The unique Id of the object being shared via the sharing request
   */
  private _sharingObjectId: typeUniqueId;

  /**
   * @method status Getter method for _sharingObjectId
   * @returns {typeUniqueId} The value for the sharingObjectId
   */
  get sharingObjectId(): typeUniqueId {
    return this._sharingObjectId;
  }

  /**
   * @method sharingObjectId Setter method for _sharingObjectId
   * @param {typeUniqueId} value The value for setting _sharingObjectId
   */
  set sharingObjectId(value: typeUniqueId) {
    this._sharingObjectId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSharableObjectType} sharingObjectType The type of object being shared via the sharing request
   */
  private _sharingObjectType: enumSharableObjectType;

  /**
   * @method status Getter method for _sharingObjectType
   * @returns {enumSharableObjectType} The value for the sharingObjectType
   */
  get sharingObjectType(): enumSharableObjectType {
    return this._sharingObjectType;
  }

  /**
   * @method sharingObjectType Setter method for _sharingObjectType
   * @param {enumSharableObjectType} value The value for setting _sharingObjectType
   */
  set sharingObjectType(value: enumSharableObjectType) {
    this._sharingObjectType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} sharingObjectName The name of the object, at the time of the initial request, being shared via the sharing request
   */
  private _sharingObjectName: string;

  /**
   * @method sharingObjectName A getter method for _sharingObjectName
   * @returns {string} The name of the object to be shared
   */
  get sharingObjectName(): string {
    return this._sharingObjectName;
  }

  /**
   * @method sharingObjectName A setter method for _sharingObjectName
   * @param {string} value The value for setting _sharingObjectName
   */
  set sharingObjectName(value: string) {
    this._sharingObjectName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} requestorUserId The User Id of the user who initiated the sharing request
   */
  private _requestorUserId: typeUniqueId;

  /**
   * @method requestorUserId A getter method for _requestorUserId
   * @returns {typeUniqueId} The Id of the user who initiated the sharing request
   */
  get requestorUserId(): typeUniqueId {
    return this._requestorUserId;
  }

  /**
   * @method requestorUserId A setter method for _requestorUserId
   * @param {typeUniqueId} value The value for setting _requestorUserId
   */
  set requestorUserId(value: typeUniqueId) {
    this._requestorUserId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} requestorName The Name of the user who initiated the sharing request (at the time the request was initiated)
   */
  private _requestorName: string;

  /**
   * @method requestorName A getter method for _requestorName
   * @returns {string} The name of the user who initiated the sharing request
   */
  get requestorName(): string {
    return this._requestorName;
  }

  /**
   * @method requestorName A setter method for _requestorName
   * @param {string} value The value for setting _requestorName
   */
  set requestorName(value: string) {
    this._requestorName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} requestorEmail The Email Address of the user who initiated the sharing request
   */
  private _requestorEmail: string;

  /**
   * @method requestorEmail A getter method for _requestorEmail
   * @returns {string} The email address of the user who initiated the sharing request
   */
  get requestorEmail(): string {
    return this._requestorEmail;
  }

  /**
   * @method requestorEmail A setter method for _requestorEmail
   * @param {string} value The value for setting _requestorEmail
   */
  set requestorEmail(value: string) {
    this._requestorEmail = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId | undefined} recipientUserId If the recipient of the sharing request currently has a user account, will be the User Id 
   *                                          of that user. If the recipient does NOT yet have a user account, will be undefined.
   */
  private _recipientUserId: typeUniqueId | undefined = undefined;

  /**
   * @method recipientUserId A getter method for _recipientUserId
   * @returns {typeUniqueId | undefined} The Id of the user who is the recipient of the sharing request
   */
  get recipientUserId(): typeUniqueId | undefined {
    return this._recipientUserId;
  }

  /**
   * @method recipientUserId A setter method for _recipientUserId
   * @param {typeUniqueId | undefined} value The value for setting _recipientUserId
   */
  set recipientUserId(value: typeUniqueId | undefined) {
    this._recipientUserId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} recipientName The Name Address of the user who is the recipient of the sharing request
   */
  private _recipientName: string;

  /**
   * @method recipientEmail A getter method for _recipientName
   * @returns {string} The Name of the user who is the recipient of the sharing request
   */
  get recipientName(): string {
    return this._recipientName;
  }

  /**
   * @method recipientEmail A setter method for _recipientName
   * @param {string} value The value for setting _recipientName
   */
  set recipientName(value: string) {
    this._recipientName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} recipientEmail The Email Address of the user who is the recipient of the sharing request
   */
  private _recipientEmail: string;

  /**
   * @method recipientEmail A getter method for _recipientEmail
   * @returns {string} The email address of the user who is the recipient of the sharing request
   */
  get recipientEmail(): string {
    return this._recipientEmail;
  }

  /**
   * @method recipientEmail A setter method for _recipientEmail
   * @param {string} value The value for setting _recipientEmail
   */
  set recipientEmail(value: string) {
    this._recipientEmail = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumSharingPermission} sharingPermission The sharing permission being granted to the recipient of the sharing request
   */
   private _sharingPermission: enumSharingPermission;

   /**
    * @method status Getter method for _sharingPermission
    * @returns {enumSharingPermission} The value for the sharingPermission
    */
   get sharingPermission(): enumSharingPermission {
     return this._sharingPermission;
   }
 
   /**
    * @method sharingPermission Setter method for _sharingPermission
    * @param {enumSharingPermission} value The value for setting _sharingPermission
    */
   set sharingPermission(value: enumSharingPermission) {
     this._sharingPermission = value;
   }
   /*-----------------------------------------------*/
 
  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId | undefined} revokerUserId Undefined until a user revokes the sharing of object for the user. If the sharing of the object
   *                                        is revoked, will be the User Id of the user who revoked.
   */
  private _revokerUserId: typeUniqueId | undefined = undefined;

  /**
   * @method revokerUserId A getter method for _revokerUserId
   * @returns {typeUniqueId | undefined} The Id of the user who is the revoker of the sharing request
   */
  get revokerUserId(): typeUniqueId | undefined {
    return this._revokerUserId;
  }

  /**
   * @method revokerUserId A setter method for _revokerUserId
   * @param {typeUniqueId | undefined} value The value for setting _revokerUserId
   */
  set revokerUserId(value: typeUniqueId | undefined) {
    this._revokerUserId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} revokerEmail The Email Address of the user who revoked the sharing request
   */
  private _revokerEmail: string | undefined = undefined;

  /**
   * @method revokerEmail A getter method for _revokerEmail
   * @returns {string | undefined} The email address of the user who revoked the sharing request
   */
  get revokerEmail(): string | undefined {
    return this._revokerEmail;
  }

  /**
   * @method revokerEmail A setter method for _revokerEmail
   * @param {string} value The value for setting _revokerEmail
   */
  set revokerEmail(value: string | undefined) {
    this._revokerEmail = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date} requestTimestamp A timestamp (in UTC) representing the Date/Time when the sharing request was initiated.
   */
  private _requestTimestamp: Date = new Date();

  /**
   * @method requestTimestamp is an optional getter method for _requestTimestamp
   */
  get requestTimestamp(): Date {
    return this._requestTimestamp;
  }

  /**
   * @method requestTimestamp is an optional setter method for _requestTimestamp
   * @param {Date} value is the input value for setting _requestTimestamp
   */
  set requestTimestamp(value: Date) {
    this._requestTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date | undefined} acceptanceTimestamp A timestamp (in UTC) representing the Date/Time when the sharing request was accepted by the 
   *                                               recipient. Will be 'undefined' until the request has been accepted.
   */
  private _acceptanceTimestamp: Date | undefined = undefined;

  /**
   * @method acceptanceTimestamp is an optional getter method for _acceptanceTimestamp
   */
  get acceptanceTimestamp(): Date | undefined {
    return this._acceptanceTimestamp;
  }

  /**
   * @method acceptanceTimestamp is an optional setter method for _acceptanceTimestamp
   * @param {Date} value is the input value for setting _acceptanceTimestamp
   */
  set acceptanceTimestamp(value: Date | undefined) {
    this._acceptanceTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date | undefined} declinedTimestamp A timestamp (in UTC) representing the Date/Time when the sharing request was declined by the 
   *                                                recipient. Will be 'undefined' until the request has been declined.
   */
  private _declinedTimestamp: Date | undefined = undefined;

  /**
   * @method declinedTimestamp is an optional getter method for _declinedTimestamp
   */
  get declinedTimestamp(): Date | undefined {
    return this._declinedTimestamp;
  }

  /**
   * @method declinedTimestamp is an optional setter method for _declinedTimestamp
   * @param {Date} value is the input value for setting _declinedTimestamp
   */
  set declinedTimestamp(value: Date | undefined) {
    this._declinedTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date | undefined} revokedTimestamp A timestamp (in UTC) representing the Date/Time when the sharing request was revoked. 
   *                                               Will be 'undefined' until the request has been revoked.
   */
  private _revokedTimestamp: Date | undefined = undefined;

  /**
   * @method revokedTimestamp is an optional getter method for _revokedTimestamp
   */
  get revokedTimestamp(): Date | undefined {
    return this._revokedTimestamp;
  }

  /**
   * @method revokedTimestamp is an optional setter method for _revokedTimestamp
   * @param {Date} value is the input value for setting _revokedTimestamp
   */
  set revokedTimestamp(value: Date | undefined) {
    this._revokedTimestamp = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method acceptSharing Sets the request to indicate that sharing has been accepted by the recipient. 
   *                       The status will be set to 'Accepted' and the acceptanceTimestamp will be set to the Date/Time when this method is called.
   *                       In addition, if the recipientUserId has not yet been set (for a request that was made to recipient before the user
   *                       had a user account), the recipientUserId will be set to the value provided.
   * @param {typeUniqueId} recipientUserId The User Id of the recipient of the request.
   */
  acceptSharing(recipientUserId: typeUniqueId): void {
    try {
      // if not already set, or if different from the parameter passed, set the User Id for the recipient
      if (this.recipientUserId === undefined || this.recipientUserId !== recipientUserId) {
        this.recipientUserId = recipientUserId;
      }

      // set the acceptance timestamp
      this.acceptanceTimestamp = new Date();

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method declineSharing Sets the request to indicate that sharing has been declined by the recipient. 
   *                       The status will be set to 'Declined' and the declineTimestamp will be set to the Date/Time when this method is called.
   *                       In addition, if the recipientUserId has not yet been set (for a request that was made to recipient before the user
   *                       had a user account -AND- the recipient never accepted the sharing request), the recipientUserId will be set to the value provided.
   * @param {typeUniqueId} recipientUserId The User Id of the recipient of the request.
   */
  declineSharing(recipientUserId: typeUniqueId): void {
    try {
      // if not already set, or if different from the parameter passed, set the User Id for the recipient
      if (this.recipientUserId === undefined || this.recipientUserId !== recipientUserId) {
        this.recipientUserId = recipientUserId;
      }

      // set the declined timestamp
      this.declinedTimestamp = new Date();

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method revokeSharing Sets the request to indicate that sharing has been revoked by a user with sufficient authority/permissions. 
   *                       The status will be set to 'Revoked' and the revokedTimestamp will be set to the Date/Time when this method is called.
   *                       In addition, the revokerUserId and revokerEmail will be set to the values provided.
   * @param {typeUniqueId} revokerUserId The User Id of the revoker of the request.
   * @param {string} revokerEmail The Email Address of the revoker of the request.
   */
  revokeSharing(revokerUserId: typeUniqueId, revokerEmail: string): void {
    try {
      // set the User Id & Email Address for the revoker
      this.revokerUserId = revokerUserId;
      this.revokerEmail = revokerEmail;

      // set the status to 'Revoked'
      this.status = enumSharingRequestStatus.Revoked;

      // set the revoked timestamp
      this.revokedTimestamp = new Date();

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IObjectSharingRequestTracker} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IObjectSharingRequestTracker {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IObjectSharingRequestTracker = Object.create(ObjectSharingRequestTracker.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IObjectSharingRequestTrackerAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IObjectSharingRequestTrackerAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.status = this.status;
      jsonObject.sharingObjectType = this.sharingObjectType;
      jsonObject.sharingObjectId = this.sharingObjectId;
      jsonObject.originatingDomain = this.originatingDomain;
      jsonObject.sharingObjectName = this.sharingObjectName;
      jsonObject.sharingPermission = this.sharingPermission;
      jsonObject.requestorUserId = this.requestorUserId;
      jsonObject.requestorName = this.requestorName;
      jsonObject.requestorEmail = this.requestorEmail;
      jsonObject.recipientEmail = this.recipientEmail;
      jsonObject.recipientName = this.recipientName;
      jsonObject.recipientUserId = (this.recipientUserId === undefined ? 'undefined' : this.recipientUserId);
      jsonObject.revokerEmail = (this.revokerEmail === undefined ? 'undefined' : this.revokerEmail);
      jsonObject.revokerUserId = (this.revokerUserId === undefined ? 'undefined' : this.revokerUserId);

      jsonObject.requestTimestamp = this.requestTimestamp.toISOString();
      jsonObject.acceptanceTimestamp = (this.acceptanceTimestamp === undefined ? 'undefined' : this.acceptanceTimestamp.toISOString());
      jsonObject.declinedTimestamp = (this.declinedTimestamp === undefined ? 'undefined' : this.declinedTimestamp.toISOString());
      jsonObject.revokedTimestamp = (this.revokedTimestamp === undefined ? 'undefined' : this.revokedTimestamp.toISOString());

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IObjectSharingRequestTrackerAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An ObjectSharingRequestTrackerObject instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IObjectSharingRequestTrackerAsJson, includeContainedObjects: boolean = true): IObjectSharingRequestTracker {
    try {
      // create a new instance of this class
      let objectSharingRequestTrackerObject: ObjectSharingRequestTracker = Object.create(ObjectSharingRequestTracker.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      objectSharingRequestTrackerObject = super.fromJSONProtected(objectSharingRequestTrackerObject, jsonObject, includeContainedObjects);

      if (jsonObject.status !== undefined) {
        objectSharingRequestTrackerObject.status = jsonObject.status;
      }

      if (jsonObject.sharingObjectType !== undefined) {
        objectSharingRequestTrackerObject.sharingObjectType = jsonObject.sharingObjectType;
      }

      if (jsonObject.originatingDomain !== undefined) {
         objectSharingRequestTrackerObject.originatingDomain = jsonObject.originatingDomain;
      }

      if (jsonObject.sharingObjectId !== undefined) {
         objectSharingRequestTrackerObject.sharingObjectId = jsonObject.sharingObjectId;
      }

      if (jsonObject.sharingObjectName !== undefined) {
        objectSharingRequestTrackerObject.sharingObjectName = jsonObject.sharingObjectName;
      }

      if (jsonObject.sharingPermission !== undefined) {
        objectSharingRequestTrackerObject.sharingPermission = jsonObject.sharingPermission;
      }

      if (jsonObject.requestorUserId !== undefined) {
        objectSharingRequestTrackerObject.requestorUserId = jsonObject.requestorUserId;
      }

      if (jsonObject.requestorName !== undefined) {
        objectSharingRequestTrackerObject.requestorName = jsonObject.requestorName;
      }

      if (jsonObject.requestorEmail !== undefined) {
        objectSharingRequestTrackerObject.requestorEmail = jsonObject.requestorEmail;
      }

      if (jsonObject.recipientEmail !== undefined) {
        objectSharingRequestTrackerObject.recipientEmail = jsonObject.recipientEmail;
      }

      if (jsonObject.recipientName !== undefined) {
        objectSharingRequestTrackerObject.recipientName = jsonObject.recipientName;
      }

      objectSharingRequestTrackerObject.recipientUserId = (jsonObject.recipientUserId === 'undefined' ? undefined : jsonObject.recipientUserId)
      objectSharingRequestTrackerObject.revokerEmail = (jsonObject.revokerEmail === 'undefined' ? undefined : jsonObject.revokerEmail)
      objectSharingRequestTrackerObject.revokerUserId = (jsonObject.revokerUserId === 'undefined' ? undefined : jsonObject.revokerUserId)

      if (jsonObject.requestTimestamp) {
        objectSharingRequestTrackerObject.requestTimestamp = new Date(jsonObject.requestTimestamp);
      }

      if (jsonObject.acceptanceTimestamp === 'undefined') {
        objectSharingRequestTrackerObject.acceptanceTimestamp = undefined;
      } else if (jsonObject.acceptanceTimestamp !== undefined) {
        objectSharingRequestTrackerObject.acceptanceTimestamp = new Date(jsonObject.acceptanceTimestamp);
      }

      if (jsonObject.declinedTimestamp === 'undefined') {
        objectSharingRequestTrackerObject.declinedTimestamp = undefined;
      } else if (jsonObject.declinedTimestamp !== undefined) {
        objectSharingRequestTrackerObject.declinedTimestamp = new Date(jsonObject.declinedTimestamp);
      }

      if (jsonObject.revokedTimestamp === 'undefined') {
        objectSharingRequestTrackerObject.revokedTimestamp = undefined;
      } else if (jsonObject.revokedTimestamp !== undefined) {
        objectSharingRequestTrackerObject.revokedTimestamp = new Date(jsonObject.revokedTimestamp);
      }

      return objectSharingRequestTrackerObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
