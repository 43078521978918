import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { ICategory, Category } from '../../../../dataObjects/models/categories/Category';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumCategoryType, enumObjectPersistenceState, enumPersistableObjectType } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { PageAndViewTitleStringAssets, MessagesStringAssets } from '../../../../assets/stringAssets';
import CategoryForm from '../../../forms/CategoryForm/CategoryForm';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { categorySaveRequest, categorySaveStatusChange } from '../../../../uiMiddleware-redux/slices/category/categorySaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/**
 * @interface IManageCategoryPageProps Input properties for the ManageCategoryPage
 */
export interface IManageCategoryPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId  // if the Id is given, it means that the Category already exists
}

// const ManageCategoryPageNew: React.FC<IManageCategoryPageProps> = (props: IManageCategoryPageProps) => {
const ManageCategoryPage: React.FC<IManageCategoryPageProps> = (props: IManageCategoryPageProps) => {

  ManageCategoryPage.displayName = "Manage Category Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();
  
  const params = useParams();

  displayConsoleLogs && console.log(`ManageCategoryPage. Entering component. params.id. params.id value: ${params.id}`);

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [categoryId, setCategoryId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the categoryId anytime the params.id changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageCategoryPage. Entered useEffect() for [categoryId, params.id]. params.id value: ${params.id}`);
    if (categoryId !== params.id) {
      displayConsoleLogs && console.log(`ManageCategoryPage. In useEffect() for [categoryId, params.id]. params.id value: ${params.id}. Ready to call setCategoryId()`);
      setCategoryId(params.id);
    }

    return () => {
      // process for 'unmounting'
      displayConsoleLogs && console.log(`ManageCategoryPage. In 'unmounting' area of useEffect() for [categoryId, params.id]`);
    }

  }, [categoryId, params.id]);

  // will be set to the Category to be managed, either a new Category or an existing one
  const [category, setCategory] = useState<ICategory | undefined>(undefined);

  // whether we are going to be managing (creating) a new Category
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveCategoryStatus: () => void = useCallback(() => {
    dispatch(categorySaveStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveCategoryStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveCategoryStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveCategoryStatus]);

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  const currentUser: IUser | undefined = currentUserContextData.currentUser;

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveCategoryStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveCategoryFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.category_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveCategory,
    notificationSuccessMessage: MessagesStringAssets.category_SaveSuccess,
  }


  // if there is defined category, append the category name to the message for success
  if (category !== undefined) {
    displayConsoleLogs && console.log(`ManageCategoryPage. 'category' IS defined. name: ${category.name}. params.id: ${params.id}`);
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.category_SaveSuccess} (${category.name})`;
  } else {
    displayConsoleLogs && console.log(`ManageCategoryPage. 'category' is NOT yet defined. params.id: ${params.id}`);
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  /**
   * @function prepareNewCategory Prepares/creates a new Category that will be used if user is requesting to create/add a new Category.
   * @param  
   */
  const prepareNewCategory: (user: IUser, userSettings: IUserSettings) => ICategory = useCallback(
    (user: IUser, userSettings: IUserSettings) => {
      let newCategory: ICategory;

      const newCategoryId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newCategory = new Category(user.id, newCategoryId, enumPersistableObjectType.User, user.id, enumObjectPersistenceState.New,
        enumCategoryType.Channel, '', '', '#ccc', '#000', '#000', [], 2, userPersistenceData);

      return newCategory;
    }, []
  );

  // define an effect based on a change to the currentUser, categoryId, or prepareNewCategory. 
  // Having a defined categoryId means that we will be editing an existing Category
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageCategoryPage. Entered useEffect() for [currentUser, categoryId, prepareNewCategory]. categoryId value: ${categoryId}`);

    // if currentUser information has been established...
    if (currentUser) {
      // if an Id was provided, we will be editing an existing category; therefore, if we haven't already fetched the category...
      if ((category === undefined) && (categoryId !== undefined)) {
        displayConsoleLogs && console.log(`ManageCategoryPage. In useEffect() for [currentUser, categoryId, prepareNewCategory] (${categoryId}). Ready to call getSingleObjectById().`);
        // try to find a Category object with the Id given in the URL
        getSingleObjectById(categoryId, enumPersistableObjectType.Category, onCategoryFetched);
      } else {
        // if we haven't already created a Category object...
        if (category === undefined) {
          displayConsoleLogs && console.log(`ManageCategoryPage. In useEffect() for [currentUser, categoryId, prepareNewCategory] & categoryId is undefined). Ready to call prepareNewCategory().`);
          // otherwise, we will be creating a new Category, so prepare a new one
          setCategory(prepareNewCategory(currentUser, currentUser.userSettings));
        }
      }
    }
  }, [currentUser, category, categoryId, prepareNewCategory]);

  // callback function for fetching the current Category for editing
  function onCategoryFetched(categoryFetched: ICategory | undefined): void {
    // displayConsoleLogs && console.log(`ManageCategoryPage. Entered onCategoryFetched()`);
    if (categoryFetched !== undefined) {
      // displayConsoleLogs && console.log(`ManageCategoryPage. In onCategoryFetched(). categoryFetched.name: ${categoryFetched.name}`);
      // set the returned Category to be the one we are editing
      setCategory(categoryFetched);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing category (default was set to 'true')
      setIsNewObject(false);
    }
  }

  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(categoryToSubmit: ICategory): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`ManageCategoryPage. Entered handleFormSubmit(). categoryToSubmit.name: ${categoryToSubmit.name}. params.id: ${params.id}`);
        // dispatch an action to request saving the category
        dispatch(categorySaveRequest(categoryToSubmit));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  displayConsoleLogs && console.log(`ManageCategoryPage. Before 'return' call, category: ${category === undefined ? 'undefined' : 'id: ' + category.id + '; name: ' + category.name}. params.id: ${params.id}`);

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageCategory_New : PageAndViewTitleStringAssets.pageTitle_ManageCategory_Existing}
      onCloseAlert={resetSaveCategoryStatus}
    >
      {category &&
        <CategoryForm
          category={category}
          saveRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  )

}

export default ManageCategoryPage;