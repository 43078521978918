import { take, put } from 'redux-saga/effects';
import { deleteVideoLink } from '../../../firebaseServices/dataServices/dataServiceActions/videoLinkActions';
import { videoLinkDeleteRequest, videoLinkDeleteStatusChange } from '../../slices/videoLink/videoLinkDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { videoLinkDeleteFailureChange } from '../../slices/videoLink/videoLinkDeleteFailureSlice';

/**
 * @function deleteVideoLinkRequestedSaga A saga workflow pertaining to a user having requested to delete a videoLink.
 */
export function* deleteVideoLinkRequestedSaga() {
  // console.info('(deleteVideoLinkRequestedSaga) entered deleteVideoLinkRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the videoLinkDeleteRequest action to be dispatched and get the payload data...
      const videoLinkDeleteRequestData = yield take(videoLinkDeleteRequest);
      const { payload: videoLink } = videoLinkDeleteRequestData;

      // make a request to delete the videoLink
      yield deleteVideoLink(videoLink)

      // dispatch an action to indicate that the delete operation was successful
      yield put(videoLinkDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(videoLinkDeleteStatusChange(enumWorkflowState.Failure));
      yield put(videoLinkDeleteFailureChange(error));
    }
  }
}
