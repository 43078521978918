import { typeUniqueId } from '../../types';
import { ICategorizedThemeSpecsViewModel } from '.';
import { IThemeSpecsViewModel, ThemeSpecsViewModel } from '.';
import { ICategorizedThemeSpecsViewModelAsJson } from './ICategorizedThemeSpecsViewModelAsJson';
import { JsonConverter } from '../../utilities/JsonConverter';

/**
 * @class CategorizedThemeSpecsViewModel Represents a set of specifications for a UI theme
 * {@linkcode ICategorizedThemeSpecsViewModel}
 */
export class CategorizedThemeSpecsViewModel implements ICategorizedThemeSpecsViewModel {

  /**
   * @method Constructor method
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {string} name The name of the category
   * @param {string} description (optional) A description for the category
   * @param {Array<IThemeSpecsViewModel>} themes ThemeSpecsViewModels associated with the category
   */
  constructor(
    id: typeUniqueId,
    name: string,
    description: string,
    themes?: Array<IThemeSpecsViewModel>,
  ) {
    this._id = id;
    this._name = name;
    this._description = description;

    if (themes) {
      this._themes = [...themes];
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} id Unique Id for the category
   */
  private _id: typeUniqueId;

  // getter for _id
  get id(): typeUniqueId {
    return this._id;
  }

  // setter for _id
  set id(value: typeUniqueId) {
    this._id = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} name represents the name of the category
   */
  private _name: string = '';

  // getter for _propName
  get name() {
    return this._name;
  }

  // setter for _propName
  set name(value) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description property is the description for the ThemeSpecsItem
   */
  private _description: string = '';

  /**
   * @method description is an optional getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description is an optional setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<IThemeSpecsViewModel>} _themes Array of IThemeSpecsViewModel objects
   */
  private _themes: Array<IThemeSpecsViewModel> = [];

  /**
   * @method themes Getter method for _themes
   */
  get themes(): Array<IThemeSpecsViewModel> {
    return this._themes;
  }

  /**
   * @method themes Setter method for _themes
   * @param {string} value Input value for setting _themes
   */
  set themes(value: Array<IThemeSpecsViewModel>) {
    this._themes = [...value];
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(): ICategorizedThemeSpecsViewModelAsJson {
    try {
      // prepare JSON object for return 
      const jsonObject: ICategorizedThemeSpecsViewModelAsJson = {
        id: this.id,
        name: this.name,
        description: this.description,
        themes: JsonConverter.toJSONArray(this.themes),
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ICategorizedThemeSpecsViewModelAsJson} jsonObject A JSON version of a class instance.
   * @returns An ICategorizedThemeSpecsViewModel instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ICategorizedThemeSpecsViewModelAsJson): ICategorizedThemeSpecsViewModel {
    try {
      // create a new instance of this class
      let categorizedThemeSpecsViewModelObject: CategorizedThemeSpecsViewModel = Object.create(CategorizedThemeSpecsViewModel.prototype);

      // copy any additional field values from the json object 
      if (jsonObject.id) {
        categorizedThemeSpecsViewModelObject.id = jsonObject.id;
      }

      // copy any additional field values from the json object 
      if (jsonObject.name) {
        categorizedThemeSpecsViewModelObject.name = jsonObject.name;
      }

      if (jsonObject.description) {
        categorizedThemeSpecsViewModelObject.description = jsonObject.description;
      }

      if (jsonObject.themes) {
        categorizedThemeSpecsViewModelObject.themes = JsonConverter.arrayFromJSONArray(ThemeSpecsViewModel, jsonObject.themes);
      }

      return categorizedThemeSpecsViewModelObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
