import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const channelDeleteFailureSlice = createSlice({
  name: 'channelDeleteFailure',
  initialState,
  reducers: {
    channelDeleteFailureChange: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    channelDeleteFailureClear: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { channelDeleteFailureChange, channelDeleteFailureClear } = channelDeleteFailureSlice.actions;

// export the core reducer for the slice
export const channelDeleteFailureReducer = channelDeleteFailureSlice.reducer;

export default channelDeleteFailureSlice.reducer;