// import { fromJS } from 'immutable'
// import { IStoreState } from './IStoreState';

/**
 * 
 */
// export const defaultStoreState: IStoreState = fromJS({
//   appVersion: "1.0.1",
//   currentUser: undefined,
//   channels: undefined,
//   currentChannel: undefined,
//   currentTopics: undefined,
//   currentTopic: undefined,
//   currentTopicItmes: undefined,
//   currentAssociatedResourcesSummary: undefined,
//   currentAssociatedResourcesDetails: undefined
// });

export const defaultStoreState = {
  appVersion: undefined,
  // currentUser: undefined,
  // channels: undefined,
  // currentChannel: undefined,
  // currentTopics: undefined,
  // currentTopic: undefined,
  // currentTopicItmes: undefined,
  // currentAssociatedResourcesSummary: undefined,
  // currentAssociatedResourcesDetails: undefined
};