import { combineReducers } from 'redux';
import { EnhancedStore, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware, { SagaMiddleware } from 'redux-saga';
import { logger } from 'redux-logger';
import {
  persistStore,
  persistReducer,
  Persistor,
  WebStorage,
} from 'redux-persist';
import { encryptTransform } from 'redux-persist-transform-encrypt'; //  'redux-persist-transform-encrypt';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { rootSaga } from '../../utilities/sagaUtilities';
import { combinedReducers } from '../../utilities/reducerUtilities/combinedReducers';
import { enumDeploymentPlatformType } from '../../../dataObjects/enums';
import { MdbError } from '../../../errorObjects/MdbError';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { composeMessageUsingStringAsset } from '../../../components/messages';
import { IStoreState } from '../IStoreState';

// Establish an encryption key to be used when persisting store state in a Production environment. 
// Get the key from an environment file. However, if the key isn't found in the env file, default to a constant.
const persistenceEncryptionKey: string = process.env.REACT_APP_REDUX_PERSIST_SECRET_KEY ?? 'default-secret-key';

// define an interface for persistence configurations
interface IPersistenceConfig {
  key: string;
  storage: WebStorage;
  transforms: any[];
}

// Prepare a persistence configuration for a Production environment.
const persistConfigForProduction: IPersistenceConfig = {
  key: 'root',
  storage,
  transforms: [
    encryptTransform({
      secretKey: persistenceEncryptionKey,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
};

// Prepare a persistence configuration for a Development environment.
const persistConfigForDevelopment: IPersistenceConfig = {
  key: 'root',
  storage,
  transforms: [] // there a no persistence transforms at this time
};

/** 
 * @class StoreConfiguratorSingleton A singleton class that serves as a configurator for a Redux Store, including a persistor
 *                                   for the store.
 */
export class StoreConfiguratorSingleton {
  /**
   * @method A Private Constructor method
   */
  /*-----------------------------------------------*/
  private constructor(deploymentEnvironment: enumDeploymentPlatformType) {
    // configure and create the store and persistor objects
    this.configureStoreAndPersistor(deploymentEnvironment);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {_instance} A private static instance
   */
  private static _instance: StoreConfiguratorSingleton | null;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {_initialized} Whether the instance has been initialized
   */
  private static _initialized: boolean = false;
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {EnhancedStore<IStoreState> | undefined} _store The 'store' object
   */
  private _store: EnhancedStore<IStoreState> | undefined = undefined;

  /**
   * @method store is a getter method for _store
   */
  get store(): EnhancedStore<IStoreState> | undefined {
    return this._store;
  }

  /**
   * @method store is a setter method for _store
   * @param {EnhancedStore<IStoreState> | undefined} value is the input value for setting _store
   */
  set store(value: EnhancedStore<IStoreState> | undefined) {
    this._store = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Persistor | undefined} _persistor The 'persistor' object
   */
  private _persistor: Persistor | undefined = undefined;

  /**
   * @method persistor is a getter method for _persistor
   */
  get persistor(): Persistor | undefined {
    return this._persistor;
  }

  /**
   * @method persistor is a setter method for _persistor
   * @param {Persistor | undefined} value is the input value for setting _store
   */
  set persistor(value: Persistor | undefined) {
    this._persistor = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method storeAppDispatch A method to return the typeof AppDispatch for the current 'store' object
   */
  get storeAppDispatch() {
    return (this.store !== undefined) ? typeof this.store.dispatch : undefined;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  static initialize(deploymentEnvironment: enumDeploymentPlatformType): StoreConfiguratorSingleton {
    if (!StoreConfiguratorSingleton._instance) {
      StoreConfiguratorSingleton._instance = new StoreConfiguratorSingleton(deploymentEnvironment);
    }

    // indicate that the object has been initialized
    this._initialized = true;

    return StoreConfiguratorSingleton._instance;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  static isInitialized(): boolean {
    return this._initialized;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  static getInstance(): StoreConfiguratorSingleton {
    if (!StoreConfiguratorSingleton._instance) {
      throw new MdbError(MessagesStringAssets.storeConfigurator_NotInitialized);
    }

    return StoreConfiguratorSingleton._instance;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  private configureStoreAndPersistor(deploymentEnvironment: enumDeploymentPlatformType): void {

    // based on the deployment type, establish the appropriate persistence configuration object
    let persistConfig: IPersistenceConfig;
    switch (deploymentEnvironment) {
      case enumDeploymentPlatformType.Development:
        persistConfig = persistConfigForDevelopment;
        break;

      case enumDeploymentPlatformType.Production:
        persistConfig = persistConfigForProduction;
        break;

      default:
        const deploymentPlatformTypeErrorMessage: string = composeMessageUsingStringAsset(MessagesStringAssets.deploymentPlatformEnvironment_Unknown, deploymentEnvironment, MessagesStringAssets.substitutionKeyword);
        throw new MdbError(deploymentPlatformTypeErrorMessage);
    }

    // create a redux-persist persistingReducer object that uses the established persist configuration object and the set of reducers
    const persistingReducer = persistReducer(persistConfig, combineReducers(combinedReducers));

    // create a sagaMiddleware object
    const sagaMiddleware: SagaMiddleware<object> = createSagaMiddleware();

    // configure the store 
    const store = configureStore({
      reducer: persistingReducer,
      middleware: (getDefaultMiddleware) =>
        // See the following stackoverflow.com posting for further insight about the getDefaultMiddleware() call:
        // https://stackoverflow.com/questions/70852386/a-non-serializable-value-was-detected-in-an-action-in-the-path-register-val
        getDefaultMiddleware({
          // serializableCheck: {
          //   ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
          // },
          immutableCheck: false,
          serializableCheck: false,
          thunk: false,
        })
          .prepend(sagaMiddleware)
          .concat(logger)
    });

    // set the 'store' data member
    this.store = store;

    // create a Persistor object that will be use to persist the store
    const persistor: Persistor = persistStore(store);

    // set the 'persistor' data member
    this.persistor = persistor;

    // type RootState = ReturnType<typeof store.getState>;
    // type AppDispatch = typeof store.dispatch;

    // console.info('Saga middleware configured');
    // run all of the sagas in the sagaMiddleware (they are bundled into rootSaga)
    sagaMiddleware.run(rootSaga);
    // console.info('Saga middleware initialized for all configured sagas');

  }
  /*-----------------------------------------------*/

}