import { createSlice } from '@reduxjs/toolkit';
import { enumCollectionViewType } from '../../../dataObjects/enums';

// export interface ICollectionViewTypeState {
//   value: enumCollectionViewType | null,
// }

const initialState: enumCollectionViewType | null = enumCollectionViewType.CardsGridView;

export const collectionViewTypeSlice = createSlice({
  name: 'collectionViewType',
  initialState,
  reducers: {
    setCollectionViewType(state, action) {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setCollectionViewType } = collectionViewTypeSlice.actions;

// export the core reducer for the slice
export const collectionViewTypeReducer = collectionViewTypeSlice.reducer;

export default collectionViewTypeSlice.reducer;
