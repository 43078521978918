import { typeUniqueId } from '../../../types';
import { enumSharingPermission } from '../../../enums';
import { IUserAccessPermissionsForObject } from ".";
import { Channel } from '../../channels/Channel';

/** 
 * @class UserAccessPermissionsForObject A class that provides details about a User's permissions for accessing a specific object
 */
export class UserAccessPermissionsForObject implements IUserAccessPermissionsForObject {
  /**
   * @method Constructor method
   * 
   * @param {typeUniqueId} userId The Id of the user for which permissions to the object apply
   * @param {any} objectInstance The object instance for which user perimissions apply
   */
  /**
   * @method Constructor method
   */
  constructor(
    userId: typeUniqueId,
    objectInstance: any,
  ) {

    // In this ctor, create a 'switch' statement on the object type. For each 'case', call a local function that will:
    //   - Verify that the Javascript object is consistent with the type (eg, channelObj instanceOf Channel)
    //   - Based on the UserId and the object instance, determine the user's permissions on the object and set the Permisisons flags

    // assign required constructor parameters
    this._userId = userId;
    this._objectInstance = objectInstance;

    // call method that will set permissions that guide access for the user to  the specific object instance
    this.setUserPermissionsForObject(userId, objectInstance);
  }

  private setUserPermissionsForObject(userId: typeUniqueId, objectInstance: any) {
    // based on the type of object passed in, perform appropriate steps to obtain user permission for the object

    // if the object is a Channel...
    if (objectInstance instanceof Channel) {
      if (objectInstance.getUserSharingPermission(userId) === enumSharingPermission.ViewContent) {

      }

      switch (objectInstance.getUserSharingPermission(userId)) {
        case enumSharingPermission.ViewContent:
          this._hasViewPermission = true;
          break;

        case enumSharingPermission.EditContent:
          this._hasViewPermission = true;
          this._hasEditPermission = true;
          break;

        case enumSharingPermission.Admin:
          this._hasViewPermission = true;
          this._hasEditPermission = true;
          this._hasAdminPermission = true;
          break;

        default:
          break;
      }
    } else {

    }
  }


  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} userId The Id of the user for which permissions to the object apply
   */
  private _userId: typeUniqueId;;

  /**
   * @method userId Getter method for _userId
   * @returns {typeUniqueId} The value for the userId
   */
  get userId(): typeUniqueId {
    return this._userId;
  }

  // Note: There will be no setter method for this property

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {any} objectInstance The object instance for which user perimissions apply
   */
  private _objectInstance: any;

  /**
   * @method objectInstance A getter method for _objectInstance
   * @returns {any} The object instance for which user perimissions apply
   */
  get objectInstance(): any {
    return this._objectInstance;
  }

  // Note: There will be no setter method for this property

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} hasAdminPermission Whether the user has sufficient permission to perform Administrative functions on the object.
   */
  private _hasAdminPermission: any = false;

  /**
   * @method hasAdminPermission A getter method for _hasAdminPermission
   * @returns {any} Whether the user has sufficient permission to perform Administrative functions on the object.
   */
  get hasAdminPermission(): any {
    return this._hasAdminPermission;
  }

  // Note: There will be no setter method for this property

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} hasEditPermission Whether the user has sufficient permission to Edit the object and its children.
   */
  private _hasEditPermission: any = false;

  /**
   * @method hasEditPermission A getter method for _hasEditPermission
   * @returns {any} Whether the user has sufficient permission to Edit the object and its children.
   */
  get hasEditPermission(): any {
    return this._hasEditPermission;
  }

  // Note: There will be no setter method for this property

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} hasViewPermission Whether the user has the permission to View the object and its children.
   */
  private _hasViewPermission: any = false;

  /**
   * @method hasViewPermission A getter method for _hasAdminPermission
   * @returns {any} Whether the user has the permission to View the object and its children.
   */
  get hasViewPermission(): any {
    return this._hasViewPermission;
  }

  // Note: There will be no setter method for this property

  /*-----------------------------------------------*/

}
