import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ISaveVideoLinkRequest } from '../../../dataObjects/models/serviceRequests/videoLink/SaveVideoLinkRequest';
import { ISaveVideoLinkWorkflowState } from '../../store/SaveVideoWorkflowState';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions';
import { IVideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: enumWorkflowState | null = null;

export const videoLinkSaveStatusSlice = createSlice({
  name: 'videoLinkSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    videoLinkSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    videoLinkSaveWithoutFileUploadRequest: (state, action: PayloadAction<ISaveVideoLinkRequest>) => {
      const saveVideoLinkRequest: ISaveVideoLinkRequest = action.payload;
      const newState: ISaveVideoLinkWorkflowState = {
        videoLink: saveVideoLinkRequest.videoLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.videoLink_SaveRequested,
        fileUploadRequest: undefined
      }

      // call internal base function to set the newState into the IStoreState variable
      videoLinkSaveWorkflowStateChange(newState);
    },
    videoLinkSaveWithFileUploadRequest: (state, action: PayloadAction<ISaveVideoLinkRequest>) => {
      const saveVideoLinkRequest: ISaveVideoLinkRequest = action.payload;
      const newState: ISaveVideoLinkWorkflowState = {
        videoLink: saveVideoLinkRequest.videoLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.videoLink_SaveRequested,
        fileUploadRequest: saveVideoLinkRequest.fileUploadRequest
      }

      // call internal base function to set the newState into the IStoreState variable
      videoLinkSaveWorkflowStateChange(newState);
    },
    videoLinkFileUploadRequest: (state, action: PayloadAction<IFileUploadRequest>) => {
      // we can only add a file upload request if the state variable in IStoreState has been set to an object value

      if (state) {
        const newState: ISaveVideoLinkWorkflowState = {
          ...(state as ISaveVideoLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.videoLink_UploadingVideo,
          fileUploadRequest: action.payload
        }

        // call internal base function to set the newState into the IStoreState variable
        videoLinkSaveWorkflowStateChange(newState);
      }
    },
    videoLinkFileUploadProgressUpdate: (state, action: PayloadAction<IFileUploadProgress>) => {
      // we can only add a file upload progress update if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadProgress: IFileUploadProgress = action.payload;
        const workflowStateMessage = `File upload is ${fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% complete`;

        const newState: ISaveVideoLinkWorkflowState = {
          ...(state as ISaveVideoLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadProgress: fileUploadProgress
        }

        // call internal base function to set the newState into the IStoreState variable
        videoLinkSaveWorkflowStateChange(newState);
      }
    },
    videoLinkFileUploadResult: (state, action: PayloadAction<ICloudStorageFileUploadResult>) => {
      // we can only add a file upload result if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadResult: ICloudStorageFileUploadResult = action.payload;
        const fileUploadTimeInSeconds: number = fileUploadResult.totalMilliseconds / 1000;
        const workflowStateMessage = `File (${fileUploadResult.file.name}) was successfully uploaded in ${fileUploadTimeInSeconds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} seconds`;

        const newState: ISaveVideoLinkWorkflowState = {
          ...(state as ISaveVideoLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadResult: fileUploadResult,
        }

        // call internal base function to set the newState into the IStoreState variable
        videoLinkSaveWorkflowStateChange(newState);
      }
    },
    videoLinkFileUploadVerify: (state) => {
      // we can only add a file upload verification if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.videoLink_FileUploadVerifying;

        const newState: ISaveVideoLinkWorkflowState = {
          ...(state as ISaveVideoLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        videoLinkSaveWorkflowStateChange(newState);
      }
    },
    videoLinkSaveSuccess: (state, action: PayloadAction<IVideoLink>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.videoLink_SaveSuccess;

        const newState: ISaveVideoLinkWorkflowState = {
          ...(state as ISaveVideoLinkWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        videoLinkSaveWorkflowStateChange(newState);
      }
    },
    videoLinkSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.videoLink_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveVideoLinkWorkflowState = {
          ...(state as ISaveVideoLinkWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        videoLinkSaveWorkflowStateChange(newState);
      }
    },
    videoLinkSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      videoLinkSaveWorkflowStateChange(null);
    },

  },
})

// Action creators are generated for each function in reducer)
export const {
  videoLinkSaveWorkflowStateChange,
  videoLinkSaveWithoutFileUploadRequest,
  videoLinkSaveWithFileUploadRequest,
  videoLinkFileUploadRequest,
  videoLinkFileUploadProgressUpdate,
  videoLinkFileUploadResult,
  videoLinkFileUploadVerify,
  videoLinkSaveSuccess,
  videoLinkSaveError,
  videoLinkSaveStatusClear,
} = videoLinkSaveStatusSlice.actions;

// export the core reducer for the slice
export const videoLinkSaveStatusReducer = videoLinkSaveStatusSlice.reducer;

export default videoLinkSaveStatusSlice.reducer;