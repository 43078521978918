import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { Box, Container } from '@mui/material';
import { ControlsStringAssets, MessagesStringAssets, PageAndViewTitleStringAssets } from '../../../../assets/stringAssets';
import { enumAlertType } from '../../../enums';
import { IImageLink } from '../../../../dataObjects/models/digitalMedia/ImageLink';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { enumBeaconType, enumCollectionViewType, enumWorkflowState } from '../../../../dataObjects/enums';
import TwoButtonAcceptanceDialog from '../../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { Beacon } from '../../../../dataObjects/models/alerts/Beacon';
import { useReduxWorkflowState } from '../../../customHooks';
import ActivityIndicatorDialog from '../../../dialogs/ActivityIndicatorDialog/ActivityIndicatorDialog';
import { IActivityIndicatorData } from '../../../dialogs/ActivityIndicatorData';
import { AlertInfo, IAlertInfo } from '../../../../dataObjects/models/alerts/AlertInfo';
import { useGetCollectionViewType } from '../../../customHooks/useGetCollectionViewType';
import GenericToggleViewContainer from '../../GenericToggleViewContainer/GenericToggleViewContainer';
import { ImageLinksCardGridView } from '../ImageLinksCardGridView/ImageLinksCardGridView';
import ImageLinksListView from '../ImageLinksListView/ImageLinksListView';
import { styled } from '@mui/system';
import { IImageLinkViewModel } from '../../../../dataObjects/viewModels/imageLinkViewModel';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { imageLinkDeleteRequest, imageLinkDeleteStatusChange } from '../../../../uiMiddleware-redux/slices/imageLink/imageLinkDeleteStatusSlice';
import { beaconChange } from '../../../../uiMiddleware-redux/slices/beacon/beaconSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area padding at the top of the view
const StyledBoxForPaddingAtopView = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

// a styled Container for the View Container
const StyledContainerForViewContainer = styled((props) => (
  <Container
    {...props}
  />
))(({ theme }) => ({
  flexGrow: 1,
  paddingLeft: 0,
  paddingRight: 0,
}));


export interface IImageLinksViewProps extends PropsWithChildren<unknown> {
  parentId?: typeUniqueId; // the parent DigitalMedia for the ImageLinks
  imageLinksToDisplay: Array<IImageLinkViewModel>;
  allowAddingNewItems: boolean;
  imageLinksDataLoading: boolean;
}

const ImageLinksView: React.FC<IImageLinksViewProps> = (props: IImageLinksViewProps) => {

  ImageLinksView.displayName = 'Image Links View';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const { parentId, imageLinksToDisplay, allowAddingNewItems, imageLinksDataLoading } = props;

  // call custom hook to get the current view type, so the value can be used to initialize the View Type state variable
  const collectionViewType: enumCollectionViewType = useGetCollectionViewType();

  // the ImageLinks associcated with the current Digital Media
  // const [imageLinks, setImageLinks] = useState<Array<IImageLink>>(imageLinksToDisplay);
  const [imageLinkViewModels, setImageLinkViewModels] = useState<Array<IImageLinkViewModel>>(imageLinksToDisplay);
  // whether data is currently loading
  const [dataLoading, setDataLoading] = useState<boolean>(imageLinksDataLoading);
  // if user is deleting a ImageLink, the ImageLink to delete
  const [imageLinkToDelete, setImageLinkToDelete] = useState<IImageLink | undefined>(undefined);
  // whether to show the Delete Confirmation dialog
  const [showDeleteConfirmationDlg, setShowDeleteConfirmationDlg] = useState<boolean>(false);

  // for dispatching redux actions
  const dispatch = useAppDispatch();

  /**
   * @function resetDeleteImageLinkStatus Resets the Delete status in Redux state
   */
  const resetDeleteImageLinkStatus: () => void = useCallback(() => {
    dispatch(imageLinkDeleteStatusChange(null));
  }, [dispatch])

  // use a custom hook to evaluate the redux state for the deleteImageLink workflow and any deleteImageLink error
  const { workflowState: deleteImageLinkStatus, errorState: deleteImageLinkFailure } = useReduxWorkflowState((state: IStoreState) => state.deleteImageLinkStatus, (state: IStoreState) => state.deleteImageLinkFailure);

  useEffect(() => {
    if (imageLinkViewModels !== imageLinksToDisplay) {
      setImageLinkViewModels(imageLinksToDisplay);
    }
  }, [imageLinksToDisplay]);

  useEffect(() => {
    if (dataLoading !== imageLinksDataLoading) {
      setDataLoading(imageLinksDataLoading);
    }
  }, [imageLinksDataLoading]);

  /**
   * @method handleImageLinkDelete Handles requests to delete an Image
   * @param imageLink The ImageLink to be deleted
   */
  async function handleImageLinkDelete(imageLink: IImageLink): Promise<void> {
    // set the imageLink to be deleted into local state
    setImageLinkToDelete(imageLink);

    // set flag to show the delete confirmation dialog
    setShowDeleteConfirmationDlg(true);
  }

  function handleImageLinkDeleteConfirmed(): void {
    // using the imageLink object to be deleted from the local state, dispatch an action to delete the imageLink object
    if (imageLinkToDelete !== undefined) {
      dispatch(imageLinkDeleteRequest(imageLinkToDelete));
    }

    // call method to reset the imageLink delete local state details
    imageLinkDeleteReset();
  }

  function handleImageLinkDeleteCanceled(): void {
    // call method to reset the imageLink delete local state details
    imageLinkDeleteReset();
  }

  function imageLinkDeleteReset() {
    // reset the imageLink to be deleted to 'undefined' in local state, since we're done with the object
    setImageLinkToDelete(undefined);

    // set flag to hide the delete confirmation dialog
    setShowDeleteConfirmationDlg(false);
  }


  // data to indicate whether an activity indicator should be displayed and, if so, the message
  let activityIndicatorData: IActivityIndicatorData = {
    showActivityIndicator: false,
    activityIndicatorMessage: ''
  }

  // data to indicate whether an alert should be displayed
  let alertInfo: IAlertInfo | undefined = undefined;

  // indicates whether the object has been successfully deleted
  const [successfullyDeletedObject, setSuccessfullyDeletedObject] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyDeletedObject) {
      // dispatch an action to reset the deleteTopicStatus
      resetDeleteImageLinkStatus();

      // notify user via a Beacon notification that the audioLink has been deleted
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, MessagesStringAssets.imageLink_DeleteConfirmationHeader, MessagesStringAssets.imageLink_DeleteSuccess)));

      // reset the successfullyDeletedObject flag
      setSuccessfullyDeletedObject(false);
    }
  }, [successfullyDeletedObject, dispatch, resetDeleteImageLinkStatus]);

  if (dataLoading) {
    // display an activity indicator
    activityIndicatorData.showActivityIndicator = true;
    activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.imageLinks_FetchingRequested;
  } else {
    // if the workflow is in the midst of performing deletion of a imageLink, prepare an alert message fragment to display progress message(s)
    if (deleteImageLinkStatus !== undefined && deleteImageLinkStatus !== null) {
      if (deleteImageLinkStatus === enumWorkflowState.Requested || deleteImageLinkStatus === enumWorkflowState.InProgress) {
        // display an activity indicator
        activityIndicatorData.showActivityIndicator = true;
        activityIndicatorData.activityIndicatorMessage = MessagesStringAssets.imageLink_DeleteRequested;
      } else if (deleteImageLinkStatus === enumWorkflowState.Success) {
        // if the successfullyDeletedObject is curently in a 'false' state, we'll want the flag to indicate that the deletion was a success
        if (!successfullyDeletedObject) {
          // set flag to indicate that the object has been successfully deleted, which will force a re-render to allow for cleanup and user notification
          setSuccessfullyDeletedObject(true);
        }
      } else if (deleteImageLinkStatus === enumWorkflowState.Failure) {
        // display an alert message
        // alertFragment = generateJsxAlertMessageFragment(MessagesStringAssets.imageLink_DeleteFailure, enumJsxAlertMessageDisplayVariants.Failure, false, deleteImageLinkFailureMessage, MessagesStringAssets.substitutionKeyword);
        let failureMessage = 'Unknown error';
        if (deleteImageLinkFailure && deleteImageLinkFailure instanceof Error) {
          failureMessage = deleteImageLinkFailure.message;
        }
        alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
        dispatch(alertInfoChange(alertInfo));
      }
    }
  }


  // Establish the text to be displayed on the '+ New' button
  let addNewButtonText: string = '';
  if (allowAddingNewItems) {
    addNewButtonText = ControlsStringAssets.imageNew;
  }


  return (
    <>
      <StyledBoxForPaddingAtopView />
      <GenericToggleViewContainer
        // maxWidth="xl"
        viewTitle={PageAndViewTitleStringAssets.viewTitle_Images}
        addNewButtonText={addNewButtonText}
        urlForAddNewButtonNavigation={`/${parentId}/imageLink`}
      >

        {/* Container for embedded data view */}
        <StyledContainerForViewContainer >

          {/* If there are ImageLinks to display and the current view selection is CardsGridView, display the ImageLinksCardGridView */}
          {imageLinkViewModels &&
            (collectionViewType === enumCollectionViewType.CardsGridView) &&
            <ImageLinksCardGridView imageLinkViewModels={imageLinkViewModels} onDelete={handleImageLinkDelete} />
          }

          {/* If there are ImageLinks to display and the current view selection is ListView, display the ImageLinksListView */}
          {imageLinkViewModels &&
            (collectionViewType === enumCollectionViewType.ListView) &&
            <ImageLinksListView imageLinkViewModels={imageLinkViewModels} onDelete={handleImageLinkDelete} />
          }
        </StyledContainerForViewContainer>

        {/* Delete ImageLink Confirmation Dialog */}
        <TwoButtonAcceptanceDialog
          showDialog={showDeleteConfirmationDlg}
          headerText={MessagesStringAssets.imageLink_DeleteConfirmationHeader}
          bodyText={MessagesStringAssets.imageLink_DeleteConfirmation}
          acceptanceButtonText={ControlsStringAssets.confirmButtonText}
          nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
          onAcceptance={handleImageLinkDeleteConfirmed}
          onNonAcceptance={handleImageLinkDeleteCanceled}
        />

        {activityIndicatorData.showActivityIndicator &&
          <ActivityIndicatorDialog activityLabel={activityIndicatorData.activityIndicatorMessage} />
        }

      </GenericToggleViewContainer>
    </>
  );
}

export default ImageLinksView;
