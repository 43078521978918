import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginData } from '../../../dataObjects/models/registerAndLogin/LoginData';
import { loginStatusChange } from './loginStatusSlice';
import { userRegistrationStatusChange } from '../registration/userRegistrationStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface ILoginDataState {
//   value: ILoginData | null,
// }

// const initialState: string | ILoginData | ILoginDataAsJson | null = null;
const initialState: ILoginData | null = null;

export const loginDataSlice = createSlice({
  name: 'loginData',
  initialState,
  reducers: {
    // loginRequest: (state: ILoginData | null, action: PayloadAction<ILoginData>) => {
    //   // set login data into state for use during the login process
    //   state = action.payload;
    // },

    // action has a payload with ILoginData
    loginRequest(state, action) {
      // set login data into state for use during the login process
      return action.payload;
    },
    // loginSuccess: (state: ILoginData | null, action: PayloadAction<string>) => {
    //   // keep same login data in state, but mask the password, since the login data will be persisted to LocalStorage
    //   if (state) {
    //     state = { ...state, password: '********' };
    //   }
    // },

    // action has a payload with ILoginData that has a masked password
    loginSuccess(state, action) {
      // set login data with masked password into state
      return action.payload;
    },

    // loginSuccess(state, action: PayloadAction<string>) {
    //   // loginSuccess: (state: ILoginData | null, action: PayloadAction<string>) => {
    //   // keep same login data in state, but mask the password, since the login data will be persisted to LocalStorage
    //   let newState: ILoginData; // = state as ILoginData;
    //   if (state) {
    //     state = { ...state, password: '********' };
    //   }
    //   return state;
    // },

    loginDataClear(state, action) {
      return null; // action.payload;
    },
  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(loginStatusChange, (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
          case enumWorkflowState.Success:
            // do nothing
            break;

          case enumWorkflowState.Failure:
            // clear the login data from state
            return null;
            break;
        }
      })
      .addCase(userRegistrationStatusChange, (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
          case enumWorkflowState.Failure:
            // do nothing
            break;

          case enumWorkflowState.Success:
            // clear the login data from state
            return null;
            break;
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { loginRequest, loginSuccess, loginDataClear } = loginDataSlice.actions;

// export the core reducer for the slice
export const loginDataReducer = loginDataSlice.reducer;

export default loginDataSlice.reducer;
