import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { ControlsStringAssets, MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { useProcessPerManageObjectPageStatus } from '../../customHooks';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import ExpungeUserForm from '../../forms/ExpungeUserForm/ExpungeUserForm';
import TwoButtonAcceptanceDialog from '../../dialogs/TwoButtonAcceptanceDialog/TwoButtonAcceptanceDialog';
import { composeMessageUsingStringAsset } from '../../messages';
import { useAppDispatch } from '../../../uiMiddleware-redux/store/configureStore';
import { userAccountExpungeStatusChange } from '../../../uiMiddleware-redux/slices/user/userAccountExpungeStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { userAccountExpungeDataClear, userAccountExpungeRequest } from '../../../uiMiddleware-redux/slices/user/userAccountExpungeDataSlice';


/**
 * @interface IExpungeUserPageProps Input properties for the ExpungeUserPage
 */
export interface IExpungeUserPageProps extends PropsWithChildren<unknown> {
}

const ExpungeUserPage: React.FC<IExpungeUserPageProps> = (props: IExpungeUserPageProps) => {

  ExpungeUserPage.displayName = "Expunge User Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  // the email address for the user account to be expunged
  const [emailForUserAccountToExpunge, setEmailForUserAccountToExpunge] = useState<string | undefined>(undefined);

  // whether to display a confirmation dialog for expunging a user account
  const [showExpungeUserAccountConfirmationDlg, setShowExpungeUserAccountConfirmationDlg] = useState<boolean>(false);

  const resetExpungeUserStatus: () => void = useCallback(() => {
    dispatch(userAccountExpungeStatusChange(null));
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetExpungeUserStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetExpungeUserStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetExpungeUserStatus]);

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.expungeUserAccountStatus,
    failureErrorStateObj: (state: IStoreState) => state.expungeUserAccountFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.user_ExpungeUserAccountFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_ExpungeUserAccount,
    notificationSuccessMessage: MessagesStringAssets.user_ExpungeUserAccountSuccess,
  }

  // if there is defined email address, append the email address to the message for success
  if (emailForUserAccountToExpunge !== undefined) {
    displayConsoleLogs && console.log(`ExpungeUserPage. 'emailForUserAccountToExpunge' IS defined: ${emailForUserAccountToExpunge}`);
    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.user_ExpungeUserAccountSuccess} (${emailForUserAccountToExpunge})`;
  } else {
    displayConsoleLogs && console.log(`ExpungeUserPage. 'emailForUserAccountToExpunge' is NOT yet defined.`);
  }

  // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);

  // function that embedded form component is to call when submitting for an expunge operation
  function handleFormSubmit(email: string): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`ExpungeUserPage. Entered handleFormSubmit(). email address: ${email}`);
        // // dispatch an action to request expunging the user account
        // dispatch(expungeUserAccountInitiateProcess(email));

        setEmailForUserAccountToExpunge(email);

        // set flag to show the Expunge User Account Confirmation dialog
        setShowExpungeUserAccountConfirmationDlg(true);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  const handleExpungeUserAccountConfirmed = () => {
    displayConsoleLogs && console.log(`ExpungeUserPage. Entered handleExpungeUserAccountConfirmed(). email address of account to be expunged: ${emailForUserAccountToExpunge}`);

    // dispatch an action to request expunging the user account
    dispatch(userAccountExpungeRequest(emailForUserAccountToExpunge));

    // call method to reset the local state details for hiding/showing the Expunge User Account Confirmation dialog
    expungeUserAccountConfirmationReset();
  }

  function handleExpungeUserAccountCanceled(): void {
    // call method to reset the local state details for hiding/showing the Expunge User Account Confirmation dialog
    expungeUserAccountConfirmationReset();
  }

  function expungeUserAccountConfirmationReset() {
    // set flag to hide the delete confirmation dialog
    setShowExpungeUserAccountConfirmationDlg(false);

    // clear Expunge User data from redux state
    clearExpungeUserDataFromReduxState();
  }

  function clearExpungeUserDataFromReduxState() {
    // clear data from redux state, both the user email and the process status
    dispatch(userAccountExpungeDataClear());
    dispatch(userAccountExpungeStatusChange(null));
  }

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={PageAndViewTitleStringAssets.pageTitle_ExpungeUserAccount}
      onCloseAlert={resetExpungeUserStatus}
    >
      { /* Only show the form if we're not displaying the confirmation dialog. This is to cause a refresh of the form after release of the confirmation dialog */
        !showExpungeUserAccountConfirmationDlg &&
        <ExpungeUserForm
          email={emailForUserAccountToExpunge ?? ''}
          expungeRequestInProgress={operationInProgress}
          onSubmit={handleFormSubmit}
        />
      }

      {/* Confirm Re-sending Account Verification Email Confirmation Dialog */}
      <TwoButtonAcceptanceDialog
        showDialog={showExpungeUserAccountConfirmationDlg}
        headerText={MessagesStringAssets.user_ExpungeUserAccountConfirmationHeader}
        bodyText={composeMessageUsingStringAsset(MessagesStringAssets.user_ExpungeUserAccountConfirmation, emailForUserAccountToExpunge, MessagesStringAssets.substitutionKeyword)}
        acceptanceButtonText={ControlsStringAssets.confirmButtonText}
        nonAcceptanceButtonText={ControlsStringAssets.cancelButtonText}
        onAcceptance={handleExpungeUserAccountConfirmed}
        onNonAcceptance={handleExpungeUserAccountCanceled}
      />

    </GenericPageContainer>
  )

}

export default ExpungeUserPage;