import React, { PropsWithChildren } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import TwoButtonDialogActionBar from '../../controls/actionBars/DialogActionButtonBars/TwoButtonDialogActionBar';

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children?: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>,
// ) {
//   return <Slide direction="down" ref={ref} {...props} />;
// });


export interface ITwoButtonAcceptanceDialogProps extends PropsWithChildren<unknown> {
  showDialog: boolean,
  headerText: string,
  bodyText: string,
  acceptanceButtonText: string,
  nonAcceptanceButtonText: string,
  onAcceptance: () => void,
  onNonAcceptance: () => void
}

const TwoButtonAcceptanceDialog: React.FC<ITwoButtonAcceptanceDialogProps> = (props) => {

  TwoButtonAcceptanceDialog.displayName = 'TwoButtonAcceptanceDialog';

  const { showDialog, headerText, bodyText, acceptanceButtonText, nonAcceptanceButtonText, onAcceptance, onNonAcceptance } = props;

  return (
    <>
      <Dialog
        open={showDialog}
        // TransitionComponent={Transition}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: true}}
        keepMounted
        onClose={onNonAcceptance}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{headerText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <TwoButtonDialogActionBar
          acceptanceButtonLabel={acceptanceButtonText}
          nonAcceptanceButtonLabel={nonAcceptanceButtonText}
          onAcceptance={onAcceptance}
          onNonAcceptance={onNonAcceptance}
        />
      </Dialog>
    </>

  );
}

export default TwoButtonAcceptanceDialog;
