import { typeUniqueId } from "../../../../dataObjects/types";
import { IFirestoreUserRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IUser, IUserAsJson } from "../../../../dataObjects/models/users/User";


/**
 * @function addChannelToUser Adds the Id of a Channel to a User's list of Channels
 * @param {typeUniqueId} userId The Id of the User to which the ChannelId will be added
 * @param {typeUniqueId} channelId The Id of the Channel to add to the User's list of Channels
 */
export function addChannelToUser(userId: typeUniqueId, channelId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a User repository from the repository factory
      const firestoreUserRepository: IFirestoreUserRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
        IFirestoreBaseRepository<IUser, IUserAsJson>;

      // determine if the User already exists by calling the repository get() method to try and get the object
      const existingUser: IUser | undefined = await firestoreUserRepository.get(userId);

      // If the User was found, and if the channelId isn't already in the user's list of Channels,
      // add the channelId to the user's list of Channels.
      if (existingUser !== undefined) {
        // search for the index of the channelId in the list of the user's Channels
        const idx: number = existingUser.channels.findIndex(memberChannelId => memberChannelId === channelId);
        // if the returned index is -1, it means that the channelId wasn't found, so...
        if (idx === -1) {
          // add the channelId to the list
          existingUser.channels.push(channelId);

          // call repository method to update the User
          await firestoreUserRepository.update(existingUser);
        }
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}