import React, { PropsWithChildren } from 'react';
import { Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import {
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { IFileForUpload } from '../../../dataObjects/models/fileUpload/FileForUpload';
import { mediaFileUploadListItemStyles } from '../../styles';
import { formatBytesToString } from '../../../dataObjects/utilities/numberFormatting/formatBytes';
import { TooltipStringAssets } from '../../../assets/stringAssets';

const MAX_FILENAME_DISPLAY_LENGTH: number = 40;

export interface IMediaFileUploadListItemProps extends PropsWithChildren<unknown> {
  fileForUpload: IFileForUpload;
  mediaIcon: JSX.Element;
  onDelete: (fileForUpload: IFileForUpload) => void;
}

export const MediaFileUploadListItem: React.FC<IMediaFileUploadListItemProps> = (props: IMediaFileUploadListItemProps) => {
  MediaFileUploadListItem.displayName = 'ImageLink FileUpload List Item';

  // eslint-disable-next-line no-console
  // console.info(`Render ImageLinkFileUploadListItem`);

  const classes: Record<string, string> = mediaFileUploadListItemStyles();

  const { fileForUpload, mediaIcon, onDelete } = props;
  const { id, file } = fileForUpload;

  // Prepare filename to be displayed. 
  let filenameForDisplay: string = file.name;
  if (file.name.length > MAX_FILENAME_DISPLAY_LENGTH) {
    const filenameExtension: string = file.name.substring(file.name.lastIndexOf('.') + 1);
    const unalteredFilenamePortion: string = file.name.substring(0, file.name.lastIndexOf('.'));
    const maxAllowableLengthOfFilenamePortion: number = MAX_FILENAME_DISPLAY_LENGTH - filenameExtension.length;
    if (unalteredFilenamePortion.length > maxAllowableLengthOfFilenamePortion) {
      const alteredFilenamePortion: string = unalteredFilenamePortion.substring(0, (maxAllowableLengthOfFilenamePortion - 4)) + '... ';
      filenameForDisplay = alteredFilenamePortion + '.' + filenameExtension;
    }
  }

  return (
    <>
      <div className={classes.tableRow} key={id}>
        <Grid item container xs={12} direction="row" >
          {/* Icon */}
          <Grid item container className={classes.mediaIcon} xs={1} alignContent='center' >
            {mediaIcon}
          </Grid>
          {/* Name */}
          <Grid item container xs={7} sm={8} alignContent='center' >
            <Typography className={classes.name} variant="body1">
              {filenameForDisplay}
            </Typography>
          </Grid>
          {/* Size */}
          <Grid item container xs={3} sm={2} justifyContent='center' alignContent='center' >
            <Typography className={classes.size} variant="body1">
              {formatBytesToString(file.size)}
            </Typography>
          </Grid>
          {/* Delete Action Button */}
          <Grid item container xs={1} alignContent='center' >
            <Tooltip
              title={TooltipStringAssets.delete}
              arrow
            >
              <span>
                <IconButton
                  size="small"
                  className={`${classes.actionButton} ${classes.deleteIconButton}`}
                  onClick={() => fileForUpload && onDelete(fileForUpload)}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>

      </div>
      {/* Add a dividing line after each item */}
      <Divider />
    </>
  );

}

export default MediaFileUploadListItem;