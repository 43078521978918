import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: enumWorkflowState | null = null;

export const userAccountExpungeStatusSlice = createSlice({
  name: 'userAccountExpungeStatus',
  initialState,
  reducers: {
    // action has a payload with the new enumWorkflowState value or null
    userAccountExpungeStatusChange(state, action) {
      // change status of expunge process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userAccountExpungeStatusChange } = userAccountExpungeStatusSlice.actions;

// export the core reducer for the slice
export const userAccountExpungeStatusReducer = userAccountExpungeStatusSlice.reducer;

export default userAccountExpungeStatusSlice.reducer;