import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IChannel, IChannelAsJson } from "../../../../dataObjects/models/channels/Channel";
import { IFirestoreChannelRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository";
import { typeUniqueId } from "../../../../dataObjects/types";
import { addChannelToUser } from "../userActions";
import { setChannelAsCategoryChild } from "../categoryActions";


export function saveChannel(channel: IChannel, parentCategoryId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // // *** FOR TESTING PURPOSES: Induce an artificial error ***
      // await setTimeout(() => {
      //   // do nothing
      // }, 2000);
      // // throw an exception
      // throw new MdbError('(saveChannel) This is an artificial error induced for testing purposes.')
      // // *** END OF TESTING BLOCK

      // get a Channel repository from the repository factory
      const firestoreChannelRepository: IFirestoreChannelRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
        IFirestoreBaseRepository<IChannel, IChannelAsJson>;

      // determine if the Channel already exists by calling the repository get() method to try and get the object
      const existingChannel: IChannel | undefined = await firestoreChannelRepository.get(channel.id);

      // If the Channel wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingChannel === undefined) {
        // call repository method to create the Channel
        await firestoreChannelRepository.create(channel);

        // in creating a new Channel, we need to add the channelId to the list for the user creating it, which will be 
        // the first memberUser in the Channel
        const userId: typeUniqueId = channel.userSharingPermissions[0].userId;
        await addChannelToUser(userId, channel.id);
      } else {
        // call repository method to update the Channel
        await firestoreChannelRepository.update(channel);
      }

      // lastly, if the parentCategoryId has been provided, set the Channel's Id as a child of the Category indicated by the parentCategoryId
      if (parentCategoryId.length > 0) {
        await setChannelAsCategoryChild(parentCategoryId, channel.id);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}