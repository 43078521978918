import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { loginSuccess } from './loginDataSlice';
import { ILoginData } from '../../../dataObjects/models/registerAndLogin/LoginData';

// export interface ILoginStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const loginStatusSlice = createSlice({
  name: 'loginStatus',
  initialState,
  reducers: {
        
    // action has a payload with the new enumWorkflowState value or null
    loginStatusChange(state, action) {
      // change status of login process
      return action.payload;
    },

  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(loginSuccess, (state: ILoginData | null, action: PayloadAction<ILoginData>) => {
        // clear the Status from state
        return null;
      })
  },
})

// Action creators are generated for each function in reducer)
export const { loginStatusChange } = loginStatusSlice.actions;

// export the core reducer for the slice
export const loginStatusReducer = loginStatusSlice.reducer;

export default loginStatusSlice.reducer;