import { take, put } from 'redux-saga/effects';
import { deleteImageLink } from '../../../firebaseServices/dataServices/dataServiceActions/imageLinkActions';
import { imageLinkDeleteRequest, imageLinkDeleteStatusChange } from '../../slices/imageLink/imageLinkDeleteStatusSlice';
import { imageLinkDeleteFailureChange } from '../../slices/imageLink/imageLinkDeleteFailureSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

/**
 * @function deleteImageLinkRequestedSaga A saga workflow pertaining to a user having requested to delete a imageLink.
 */
export function* deleteImageLinkRequestedSaga() {
  // console.info('(deleteImageLinkRequestedSaga) entered deleteImageLinkRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the imageLinkDeleteRequest action to be dispatched and get the payload data...
      const imageLinkDeleteRequestData = yield take(imageLinkDeleteRequest);
      const { payload: imageLink } = imageLinkDeleteRequestData;

      // make a request to delete the imageLink
      yield deleteImageLink(imageLink)

      // dispatch an action to indicate that the delete operation was successful
      yield put(imageLinkDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(imageLinkDeleteStatusChange(enumWorkflowState.Failure));
      yield put(imageLinkDeleteFailureChange(error));
    }
  }
}
