import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchObjectSharingRequestTrackersFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchObjectSharingRequestTrackersFailureSlice = createSlice({
  name: 'fetchObjectSharingRequestTrackersFailure',
  initialState,
  reducers: {
    setFetchObjectSharingRequestTrackersFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchObjectSharingRequestTrackersFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchObjectSharingRequestTrackersFailure, clearFetchObjectSharingRequestTrackersFailure } = fetchObjectSharingRequestTrackersFailureSlice.actions;

// export the core reducer for the slice
export const fetchObjectSharingRequestTrackersFailureReducer = fetchObjectSharingRequestTrackersFailureSlice.reducer;

export default fetchObjectSharingRequestTrackersFailureSlice.reducer;