import { typeUniqueId } from '../../../types';
import { typePersistableParentObjectType } from '../../../types';
import { enumObjectPersistenceState } from '../../../enums';
import { PersistableArray } from '../PersistableArray';
import { IVersionAwarePersistable } from '../VersionAwarePersistable';
import { IVersionAwarePersistableArray } from '.';

export class VersionAwarePersistableArray<T extends IVersionAwarePersistable> extends PersistableArray<T> implements IVersionAwarePersistableArray<T> {

  constructor(
    parentObject: IVersionAwarePersistable,
    classType: any,
    parentObjectType?: typePersistableParentObjectType,
    parentId?: typeUniqueId,
    persistableObjects?: Array<T>
  ) {
    super(classType, parentObjectType, parentId, persistableObjects);

    this._parentObject = parentObject;
  };

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistable} parentObject The object instance that is a parent of 'this' instance.
   * This is used to enable communications from a child object to a parent object when there is a live object graph.
   * This property should not be serialized to JSON, but only used with a live object graph.
   */
  private _parentObject: IVersionAwarePersistable;

  /**
   * @method parentObject is an optional getter method for _parentObject
   */
  get parentObject() {
    return this._parentObject;
  }

  /**
   * @method parentObjectProtected is an optional getter method for _parentObject
   */
  protected get parentObjectProtected() {
    return this._parentObject;
  }

  /**
   * @method parentObjectProtected is an optional setter method for _parentObject
   * @param {IVersionAwarePersistable} value is the input value for setting _parentObject
   */
  protected set parentObjectProtected(value: IVersionAwarePersistable) {
    this._parentObject = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IVersionAwarePersistableArray<T>} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IVersionAwarePersistableArray<T> {
    // construct a new instance of an array object, using core property values from this instance
    const copyOfObject: IVersionAwarePersistableArray<T> = new VersionAwarePersistableArray<T>(this.parentObject, this.classType, this.parentObjectType, this.parentId);

    // copy the objects in the array
    if (this.persistableObjects !== undefined && this.persistableObjects.length > 0) {
      this.persistableObjects.forEach(persistableObject => {
        copyOfObject.addObject(persistableObject.copy() as T);
      });

    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /** 
   * @method propagateObjectStateChangeToDescendents Requests to change the state of each VersionAwarePersistable-derived descendant object in 
   * the instance's array, and propagates the request to each of those objects
   * @param {enumObjectPersistenceState} newState The state to which each descdendant object is to be set
  */
  propagateObjectStateChangeToDescendents(newState: enumObjectPersistenceState): void {

    // for each child in the array, set its state and request that it propagate the state to its children
    this.persistableObjects.forEach(po => {
      po.objectState = newState;
      po.propagateObjectStateChangeToDescendents(newState);
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method alignChildrenWithParentSpecs Aligns all child nodes in the array with the specs of the parent ('parentId' and
   * 'parentObjectType').
   * This is to ensure data integrity.  All objects in the current instance must belong properly to the same parent object.
   */
  protected alignChildrenWithParentSpecs(): void {

    // set the 'parentObject' for each of child in the array
    this.persistableObjects.forEach(po => po.parentObject = this.parentObject);

    // call the immediate base class 'alignChildrenWithParentSpecs'
    super.alignChildrenWithParentSpecs();
  }
  /*-----------------------------------------------*/

}