import { put } from "redux-saga/effects";
import { ISaveDocumentLinkRequest } from '../../../dataObjects/models/serviceRequests/documentLink/SaveDocumentLinkRequest';
import { IDocumentLink } from '../../../dataObjects/models/digitalMedia/DocumentLink';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult, uploadFileToCloudStorage } from "../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions";
import { ISaveDocumentLinkWorkflowState } from "../../store/SaveDocumentWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { MessagesStringAssets } from "../../../assets/stringAssets";
import { documentLinkFileUploadResult, documentLinkSaveError, documentLinkSaveWithoutFileUploadRequest, documentLinkSaveWorkflowStateChange } from "../../slices/documentLink/documentLinkSaveStatusSlice";


export function* saveDocumentLinkWithFileUploadWorkerSaga(saveDocumentLinkRequest: ISaveDocumentLinkRequest) {
  try {
    // console.info('(saveDocumentLinkWithFileUploadWorkerSaga) Entered saveDocumentLinkWithFileUploadWorkerSaga');

    // // used for debugging timing
    // const startTime: number = Date.now();

    // if the ISaveDocumentLinkRequest is defined...
    if (saveDocumentLinkRequest !== undefined) {
      // get the IFileUploadRequest from the ISaveDocumentLinkRequest object
      const fileUploadRequest: IFileUploadRequest | undefined = saveDocumentLinkRequest.fileUploadRequest;

      // get the DocumentLink object from the request
      const documentLink: IDocumentLink | undefined = saveDocumentLinkRequest.documentLink;

      // only proceed if both the IFileUploadRequest and DocumentLink objects have been defined
      if (fileUploadRequest !== undefined && documentLink !== undefined) {
        const fileUploadResult: ICloudStorageFileUploadResult = yield uploadFileToCloudStorage(fileUploadRequest.fileToUpload, fileUploadRequest.userId,
          fileUploadRequest.fileClass, fileUploadRequest.fileUniqueId, fileUploadRequest.uploadProgressCallback);

        // update store state...      
        const saveDocumentLinkWorkflowState: ISaveDocumentLinkWorkflowState = {
          documentLink: saveDocumentLinkRequest.documentLink,
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.documentLink_SaveRequested,
          fileUploadRequest: fileUploadRequest
        }
        yield put(documentLinkSaveWorkflowStateChange(saveDocumentLinkWorkflowState));

        // console.info(`(saveDocumentLinkWithFileUploadWorkerSaga) Elapsed time after uploading file: ${Date.now() - startTime} milliseconds`);
        // dispatch action to indicate that there is a fileUploadResult
        yield put(documentLinkFileUploadResult(fileUploadResult));

        // console.info(`(saveDocumentLinkWithFileUploadWorkerSaga) Elapsed time after dispatching fileUploadResult: ${Date.now() - startTime} milliseconds`);

        // set the baseStoragePath and downloadUrl for the DocumentLink object
        saveDocumentLinkRequest.documentLink.baseStoragePath = fileUploadResult.baseStoragePath;
        saveDocumentLinkRequest.documentLink.downloadUrl = fileUploadResult.downloadUrl;
        saveDocumentLinkRequest.documentLink.mediaType = fileUploadResult.mediaType;

        // console.info(`(saveDocumentLinkWithFileUploadWorkerSaga) Elapsed time after delay, following uploading file: ${Date.now() - startTime} milliseconds`);

        // now, dispatch an action to save the DocumentLink object
        yield put(documentLinkSaveWithoutFileUploadRequest(saveDocumentLinkRequest));
      }

    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(documentLinkSaveError(error));
  }
}