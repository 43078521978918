import { Firestore } from 'firebase/firestore';
import { BaseDbContext } from '../../../../../dataServices/dbContexts/BaseDbContext';
import { IFirestoreDbContext } from '.';

/**
 * @class BaseFirestore Base class for classes that interact with the Firebase Firestore NoSQL database
 */
export class FirestoreDbContext extends BaseDbContext<Firestore> implements IFirestoreDbContext {
  /**
   * @method Constructor method
   * @param {string} dbName Name of the Firebase DB
   * @param {Firestore} firestoreObj A Firebase Application object that has been initialized, if provided
   */
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    dbName: string,
    firestoreObj: Firestore
  ) {
    super(dbName, firestoreObj);
  }

  /**
   * @method firestoreObj An alias for the configured database instance, typed as a Firestore database object
   * @returns {Firestore} A Firestore NoSQL database instance
   */
  get firestoreObj(): Firestore {
    return this.dbReference as Firestore;
  }

}
