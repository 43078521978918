import { Firestore } from 'firebase/firestore';
import { IUser } from '../../../../dataObjects/models/users/User';
import { FirestoreUserRepository_Ext, IFirestoreUserRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository';
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';


/**
 * @function getUserAccountInfoFromEmailAddress Retrieves user account info from the DB using an email address and, if found, returns an IUser object
 * @param userAccountEmailAddress The user account email address to be used in the search operation
 * @returns An IUser object or undefined
 */
export function getUserAccountInfoFromEmailAddress(userAccountEmailAddress: string): Promise<IUser | undefined> {
  return new Promise<IUser | undefined>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      // instantiate a FirestoreUserRepository_Ext object using the Firestore object instance
      const firestoreUserRepository_Ext: IFirestoreUserRepository_Ext = new FirestoreUserRepository_Ext(firestoreObj);

      // call method to get the User Account from the email address
      const userFromDb: IUser | undefined = await firestoreUserRepository_Ext.getUserByEmailAddress(userAccountEmailAddress);

      resolve(userFromDb);

    } catch (error: any) {
      alert(`Error. userAccountActivities.getUserAccountInfoFromEmailAddress failed: (${error})`);
      reject(error);
    }
  });
}
