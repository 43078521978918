import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ITopic } from '../../../dataObjects/models/topics/Topic';

const initialState: enumWorkflowState | null = null;

export const topicDeleteStatusSlice = createSlice({
  name: 'topicDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with ITopic
    topicDeleteRequest: (state, action: PayloadAction<ITopic>) => {
      topicDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    topicDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { topicDeleteRequest, topicDeleteStatusChange } = topicDeleteStatusSlice.actions;

// export the core reducer for the slice
export const topicDeleteStatusReducer = topicDeleteStatusSlice.reducer;

export default topicDeleteStatusSlice.reducer;