import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ICategory, ICategoryAsJson } from "../../../../dataObjects/models/categories/Category";
import { IFirestoreCategoryRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository";


export function saveCategory(category: ICategory): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // // *** FOR TESTING PURPOSES: Induce an artificial error ***
      // await setTimeout(() => {
      //   // do nothing
      // }, 2000);
      // // throw an exception
      // throw new MdbError('(saveCategory) This is an artificial error induced for testing purposes.')
      // // *** END OF TESTING BLOCK

      // get a Category repository from the repository factory
      const firestoreCategoryRepository: IFirestoreCategoryRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
        IFirestoreBaseRepository<ICategory, ICategoryAsJson>;

      // determine if the Category already exists by calling the repository get() method to try and get the object
      const existingCategory: ICategory | undefined = await firestoreCategoryRepository.get(category.id);

      // If the Category wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingCategory === undefined) {
        // call repository method to create the Category
        await firestoreCategoryRepository.create(category);

        // // in creating a new Category, we need to add the categoryId to the list for the user creating it, which will be 
        // // the first memberUser in the Category
        // const userId: typeUniqueId = category.memberUsers[0];
        // await addCategoryToUser(userId, category.id);
      } else {
        // call repository method to update the Category
        await firestoreCategoryRepository.update(category);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}