import { createContext } from 'react';
import { IUserCategoriesAndChannelsContextData } from '.';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../enums';

/**
 * @context UserCategoriesAndChannelsContext A React Context that can be used to provide a React component tree with 
 *          Categories and Channels associated with the current user, along with the current Channel for the user.
 */
export const UserCategoriesAndChannelsContext: React.Context<IUserCategoriesAndChannelsContextData> =
  createContext<IUserCategoriesAndChannelsContextData>(
    {
      state: {
        currentChannel: undefined,
        channelViewModels: undefined,
        categories: undefined,
        channelsByCategories: undefined,
        openSharingRequestViewModels: undefined,
        status: enumCategoriesAndChannelsDataPreparationStatus.None
      },
      actions: {
        setCurrentChannel: undefined,
        resolveCurrentChannel: undefined
      }

    }
  );
