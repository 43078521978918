import { typeUniqueId } from "../../../../dataObjects/types"
import { IDigitalMedia } from "../../../../dataObjects/models/digitalMedia/DigitalMedia";
import { FirestoreDigitalMediaRepository, IFirestoreDigitalMediaRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDigitalMediaRepository";
import { Firestore } from "firebase/firestore";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreDbContext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDbContext";


export function getDigitalMediaObjectUsingId(digitalMediaId: typeUniqueId): Promise<IDigitalMedia | undefined> {
  return new Promise<IDigitalMedia | undefined>(async (resolve, reject) => {
    try {
      // Note: We cannot use the FirestoreDataRepositoryFactory to create the FirestoreDigitalMediaRepository.
      
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;
      // instantiate a FirestoreDbContext
      const dbContext = new FirestoreDbContext('firestoreDb', firestoreObj);

      const firestoreDigitalMediaRepository: IFirestoreDigitalMediaRepository = new FirestoreDigitalMediaRepository(dbContext);

      // make request of the repository to get the DigitalMedia object associated with the Id
      const digitalMedia: IDigitalMedia | undefined = await firestoreDigitalMediaRepository.get(digitalMediaId);

      resolve(digitalMedia);
    } catch (error: any) {
      reject(error);
    }
  });
}