import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface ICollaborationParms {
  className?: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  boundingRect: {
    fill: 'none',
  },

  person: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

  doubleArrow: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsSecondaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '10px',
  },

}));


// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const Collaboration = React.forwardRef((props: ICollaborationParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 388 341" >
      <defs>
      </defs>

      <g id="groupCollaboration">
        <g id="groupPerson1">
          <g id="headPerson1">
            <circle className={classes.person} cx="73" cy="36" r="35.5" />
            <path className={classes.person} d="M1099,145a35,35,0,1,1-35,35,35,35,0,0,1,35-35m0-1a36,36,0,1,0,36,36,36,36,0,0,0-36-36Z" transform="translate(-1026 -144)" />
          </g>
          <g id="bodyPerson1">
            <path className={classes.person} d="M1064.72,484.5a14.24,14.24,0,0,1-14.22-14.22V368.5h-9.78a14.24,14.24,0,0,1-14.22-14.22V239.72a14.24,14.24,0,0,1,14.22-14.22h114.56a14.24,14.24,0,0,1,14.22,14.22V354.28a14.24,14.24,0,0,1-14.22,14.22h-9.78V470.28a14.24,14.24,0,0,1-14.22,14.22Z" transform="translate(-1026 -144)" />
            <path className={classes.person} d="M1155.28,226A13.73,13.73,0,0,1,1169,239.72V354.28A13.73,13.73,0,0,1,1155.28,368H1145V470.28A13.73,13.73,0,0,1,1131.28,484h-66.56A13.73,13.73,0,0,1,1051,470.28V368h-10.28A13.73,13.73,0,0,1,1027,354.28V239.72A13.73,13.73,0,0,1,1040.72,226h114.56m0-1H1040.72A14.72,14.72,0,0,0,1026,239.72V354.28A14.72,14.72,0,0,0,1040.72,369H1050V470.28A14.72,14.72,0,0,0,1064.72,485h66.56A14.72,14.72,0,0,0,1146,470.28V369h9.28A14.72,14.72,0,0,0,1170,354.28V239.72A14.72,14.72,0,0,0,1155.28,225Z" transform="translate(-1026 -144)" />
          </g>
        </g>
        <g id="groupPerson2">
          <g id="headPerson2">
            <circle className={classes.person} cx="317" cy="36" r="35.5" />
            <path className={classes.person} d="M1343,145a35,35,0,1,1-35,35,35,35,0,0,1,35-35m0-1a36,36,0,1,0,36,36,36,36,0,0,0-36-36Z" transform="translate(-1026 -144)" />
          </g>
          <g id="bodyPerson2">
            <path className={classes.person} d="M1308.72,484.5a14.24,14.24,0,0,1-14.22-14.22V368.5h-9.78a14.24,14.24,0,0,1-14.22-14.22V239.72a14.24,14.24,0,0,1,14.22-14.22h114.56a14.24,14.24,0,0,1,14.22,14.22V354.28a14.24,14.24,0,0,1-14.22,14.22h-9.78V470.28a14.24,14.24,0,0,1-14.22,14.22Z" transform="translate(-1026 -144)" />
            <path className={classes.person} d="M1399.28,226A13.73,13.73,0,0,1,1413,239.72V354.28A13.73,13.73,0,0,1,1399.28,368H1389V470.28A13.73,13.73,0,0,1,1375.28,484h-66.56A13.73,13.73,0,0,1,1295,470.28V368h-10.28A13.73,13.73,0,0,1,1271,354.28V239.72A13.73,13.73,0,0,1,1284.72,226h114.56m0-1H1284.72A14.72,14.72,0,0,0,1270,239.72V354.28A14.72,14.72,0,0,0,1284.72,369H1294V470.28A14.72,14.72,0,0,0,1308.72,485h66.56A14.72,14.72,0,0,0,1390,470.28V369h9.28A14.72,14.72,0,0,0,1414,354.28V239.72A14.72,14.72,0,0,0,1399.28,225Z" transform="translate(-1026 -144)" />
          </g>
        </g>
        <g id="groupDoubleArrow">
          <path id="doubleArrow" className={classes.doubleArrow} d="M1338.29,321.44,1276.56,377a11.36,11.36,0,0,1-19-8.45V338.57h-73.56v29.94a11.36,11.36,0,0,1-19,8.45l-61.72-55.52a11.34,11.34,0,0,1,0-16.88L1165.1,249a11.36,11.36,0,0,1,19,8.45V287h73.56V257.49a11.36,11.36,0,0,1,19-8.45l61.73,55.52A11.34,11.34,0,0,1,1338.29,321.44Z" transform="translate(-1026 -144)" />
        </g>
      </g>

    </svg >
  )
});

export default Collaboration;
