import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableObjectType, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { FieldValueValidation } from '../../../validations/FieldValueValidation';
import { IPersistable, IPersistableAsJson } from '.';

/** 
 * @class Persistable A base class for any class that will have persistable objects
 */
export abstract class Persistable implements IPersistable {

  /**
   * @method Constructor For Persistable
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {string} className Name of the class behind the instance
   * @param {typePersistableObjectType} objectType Type of object behind the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   */
  protected constructor(
    ownerId: typeUniqueId,
    className: string,
    objectType: typePersistableObjectType,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption
  ) {
    this._ownerId = ownerId;
    this._className = className;
    this.objectTypeProtected = objectType;
    this.idProtected = id;
    this.parentObjectType = parentObjectType;
    this.parentId = parentId;
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} ownerId The ultimate owner (a User or a Channel) of the object. Will be either 
   * a user's UserId or a channel's ChannelId.  If a new owner type is introduced in the future, then its
   * 'Id' type can also be represented.
   */
  private _ownerId: typeUniqueId;

  /**
   * @method ownerId (public) Returns the value of the instance's '_ownerId'.
   */
  get ownerId() {
    return this._ownerId;
  }

  /**
   * @method typeUniqueId (protected) Returns the value of the instance's 'objectType',
   * but is only accessible by derived classes.
   */
  protected get ownerIdProtected() {
    return this._ownerId;
  }

  /**
   * @method ownerId (protected) Returns the value of the instance's 'ownerId',
   * but is only accessible by derived classes. Therefore, only classes within the 
   * ancestry can modify the 'ownerId'.
   * @param {typeUniqueId} value The value to be used to set the 'ownerId'.
   */
  protected set ownerIdProtected(value: typeUniqueId) {
    this._ownerId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} className The name of the class (leaf-node class) behind the object.
   * (Provided for future use. At some point, reflection will be used to "self-hydrate" class instances
   * from serialized JSON objects.)
   */
  private _className: string;

  /**
   * @method className (public) Returns the value of the instance's '_className'.
   */
  get className() {
    return this._className;
  }

  /**
   * @method classNameProtected (protected) Returns the value of the instance's 'className',
   * but is only accessible by derived classes.
   */
  protected get classNameProtected() {
    return this._className;
  }

  /**
   * @method className (protected) Returns the value of the instance's 'className',
   * but is only accessible by derived classes. Therefore, only classes within the 
   * ancestry can modify the 'className'.
   * @param {string} value The value to be used to set the 'className'.
   */
  protected set classNameProtected(value: string) {
    this._className = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumPersistableObjectType} _objectType Persistable object type
   */
  private _objectType: typePersistableObjectType = undefined;

  /**
   * @method objectType (public) Returns the value of the instance's 'objectType'.
   */
  get objectType() {
    return this.objectTypeProtected;
  }

  /**
   * @method objectTypeProtected (protected) Returns the value of the instance's 'objectType',
   * but is only accessible by derived classes.
   */
  protected get objectTypeProtected() {
    return this._objectType;
  }

  /**
   * @method objectType (protected) Returns the value of the instance's 'objectType',
   * but is only accessible by derived classes. Therefore, only classes within the 
   * ancestry can modify the 'objectType'.
   * @param {typePersistableObjectType} value The value to be used to set the 'objectType'.
   */
  protected set objectTypeProtected(value: typePersistableObjectType) {
    // Validations: Not sure if there are any suitable validations
    // validate that the object type is a valid value
    FieldValueValidation.validateObjectTypeIsValid(value, `The object type must be set to a valid value (objectType provided: ${this.objectType})`);
    this._objectType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} _id A unique Id for the instance
   */
  private _id: typeUniqueId = RandomId.newId();

  /**
   * @method id (public) Returns the value of the instance's 'id'.
   */
  get id() {
    return this.idProtected;
  }

  /**
   * @method id (protected) Returns the value of the instance's 'id',
   * but is only accessible by derived classes.
   */
  protected get idProtected() {
    return this._id;
  }

  /**
   * @method id (protected) Returns the value of the instance's 'id',
   * but is only accessible by derived classes. Therefore, only classes within the 
   * ancestry can modify the 'id'.
   * @param {typeUniqueId} value The value to be used to set the 'id'.
   */
  protected set idProtected(value: typeUniqueId) {
    // validate that the id is a non-empty string
    FieldValueValidation.validateStringValueIsPresent(value, `The object id must be set to a valid value (id provided: '${this.id}')`);
    this._id = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumPersistableObjectType | undefined} _parentObjectType The object type of this object's parent
   */
  private _parentObjectType: typePersistableParentObjectType | undefined = undefined;

  /**
   * @method parentObjectType is an optional getter method for _objectType
   */
  get parentObjectType() {
    return this._parentObjectType;
  }

  /**
   * @method parentObjectType is an optional setter method for _parentId
   * @param {typePersistableParentObjectType} value is the input value for setting _parentId
   */
  set parentObjectType(value: typePersistableParentObjectType | undefined) {
    this._parentObjectType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueIdWithUndefinedOption} _parentId Id of the parent of this object
   */
  private _parentId: typeUniqueIdWithUndefinedOption = undefined;

  /**
   * @method parentId is an optional getter method for _parentId
   */
  get parentId() {
    return this._parentId;
  }

  /**
   * @method parentId is an optional setter method for _parentId
   * @param {typeUniqueIdWithUndefinedOption} value is the input value for setting _parentId
   */
  set parentId(value: typeUniqueIdWithUndefinedOption) {
    // validate that the value is not an empty string
    FieldValueValidation.validateUniqueIdHasValueIfDefined(value, 'A parentId cannot be an empty string.');
    this._parentId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IPersistable} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  abstract copy(): IPersistable;  // marked as 'abstract' to force derived classes to implement
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of the derived class instance to JSON, along with all of its descendants. 
   * (Note: This method will be called if JSON.stringify() is executed on an instance.)
   * @returns A JSON object with serialized data from 'this' instance of the derived class.
   */
  // abstract toJSON(includeDescendants: boolean): IVersionAwarePersistableAsJson;
  toJSON(includeContainedObjects: boolean = true): IPersistableAsJson {
    try {
      // declare a JSON object to be returned
      const jsonObject: IPersistableAsJson = {
        ownerId: this.ownerId,
        className: this.className,
        objectType: this.objectType,
        id: this.id,
        parentObjectType: (this.parentObjectType === undefined ? 'undefined' : this.parentObjectType),
        // parentId: this.parentId,
        parentId: (this.parentId === undefined ? 'undefined' : this.parentId)
      };

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects in this base class
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSONProtected A static method that performs conversion from JSON to an instance of this base class.
   * This method will be called via 'super.fromJSONProtected()' within the fromJSON() method of a directly derived 
   * class.
   * @param persistableObject An instance of the derived-class. 
   * @param jsonObject The JSON object that contains data to be converted.
   * @param includeContainedObjects Whether to include contained object. (This class has no contained objects to include.)
   */
  protected static fromJSONProtected(persistableObject: Persistable, jsonObject: IPersistableAsJson, includeContainedObjects: boolean = true): any {
    try {
      // fill the properties of the object that pertain to this base class
      persistableObject.ownerIdProtected = jsonObject.ownerId;
      persistableObject.classNameProtected = jsonObject.className;
      persistableObject.objectTypeProtected = jsonObject.objectType;
      persistableObject.idProtected = jsonObject.id;
      persistableObject.parentObjectType = (jsonObject.parentObjectType === 'undefined' ? undefined : jsonObject.parentObjectType);
      persistableObject.parentId = (jsonObject.parentId === 'undefined' ? undefined : jsonObject.parentId);

      return persistableObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
