import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsASoundCloudAddress Returns whether the URL is a SoundCloud address
 * @param url
 */
export const urlIsASoundCloudAddress: (url: string) => boolean = (url: string) => {

  // get the regular expression pattern for the website
  const regEx: RegExp = RegularExpressions.SoundCloud;

  // return the result of testing the given url against the regex pattern
  return regEx.test(url);
}
