import { ICategory } from "../../models/categories/Category";
import { IChannel } from "../../models/channels/Channel";
import { IUser } from "../../models/users/User";
import { IChannelViewModel } from "./";


export class ChannelViewModel implements IChannelViewModel {

  /**
   * @method Constructor method
   * @param {IChannel} channel A reference to the underlying Channel object
   * @param {ICategory | undefined} category A reference to the associated Category object
   * @param {IUser | undefined} ownerUser A reference to the associated Owner User object
   */
  constructor(
    channel: IChannel,
    category?: ICategory,
    ownerUser?: IUser
  ) {

    // assign properties from ctor parameters
    this._channel = channel;
    this._category = category;
    this._ownerUser = ownerUser;
  }


  /*-----------------------------------------------*/
  /**
   * @property {IChannel} _channel A reference to the underlying Channel object
   */
  private _channel: IChannel;

  /**
   * @method channel Getter method for _channel
   */
  get channel(): IChannel {
    return this._channel;
  }

  /**
   * @method channel Setter method for _channel
   * @param {IChannel} value The input value for setting _channel
   */
  set channel(value: IChannel) {
    this._channel = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {ICategory | undefined} _category A reference to the associated Category object
   */
  private _category: ICategory | undefined = undefined;

  /**
   * @method category Getter method for _category
   */
  get category(): ICategory | undefined {
    return this._category;
  }

  /**
   * @method category Setter method for _category
   * @param {ICategory | undefined} value The input value for setting _category
   */
  set category(value: ICategory | undefined) {
    this._category = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IUser | undefined} _ownerUser A reference to the associated Owner User object
   */
  private _ownerUser: IUser | undefined = undefined;

  /**
   * @method ownerUser Getter method for _ownerUser
   */
  get ownerUser(): IUser | undefined {
    return this._ownerUser;
  }

  /**
   * @method ownerUser Setter method for _ownerUser
   * @param {IUser | undefined} value The input value for setting _ownerUser
   */
  set ownerUser(value: IUser | undefined) {
    this._ownerUser = value;
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {typeUniqueId} _channelId The Id of the channel
  //  */
  // private _channelId: typeUniqueId;

  // /**
  //  * @method channelId Getter method for _channelId
  //  */
  // get channelId(): typeUniqueId {
  //     return this._channelId;
  // }

  // /**
  //  * @method channelId Setter method for _channelId
  //  * @param {typeUniqueId} value The input value for setting _channelId
  //  */
  // set channelId(value: typeUniqueId) {
  //     this._channelId = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {string} _channelName The name of the channel
  //  */
  // private _channelName: string;

  // /**
  //  * @method channelName Getter method for _channelName
  //  */
  // get channelName(): string {
  //     return this._channelName;
  // }

  // /**
  //  * @method channelName Setter method for _channelName
  //  * @param {string} value The input value for setting _channelName
  //  */
  // set channelName(value: string) {
  //     this._channelName = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {string} _channelDescription The description of the channel
  //  */
  // private _channelDescription: string;

  // /**
  //  * @method channelDescription Getter method for _channelDescription
  //  */
  // get channelDescription(): string {
  //     return this._channelDescription;
  // }

  // /**
  //  * @method channelDescription Setter method for _channelDescription
  //  * @param {string} value The input value for setting _channelDescription
  //  */
  // set channelDescription(value: string) {
  //     this._channelDescription = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {typeUniqueId} _channelOwnerId The Id of the channel owner
  //  */
  // private _channelOwnerId: typeUniqueId;

  // /**
  //  * @method channelOwnerId Getter method for _channelOwnerId
  //  */
  // get channelOwnerId(): typeUniqueId {
  //     return this._channelOwnerId;
  // }

  // /**
  //  * @method channelOwnerId Setter method for _channelOwnerId
  //  * @param {typeUniqueId} value The input value for setting _channelOwnerId
  //  */
  // set channelOwnerId(value: typeUniqueId) {
  //     this._channelOwnerId = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {string | undefined} _channelOwnerName The full name of the channel owner
  //  */
  // private _channelOwnerName: string | undefined = undefined;

  // /**
  //  * @method channelOwnerName Getter method for _channelOwnerName
  //  */
  // get channelOwnerName(): string | undefined {
  //     return this._channelOwnerName;
  // }

  // /**
  //  * @method channelOwnerName Setter method for _channelOwnerName
  //  * @param {string | undefined} value The input value for setting _channelOwnerName
  //  */
  // set channelOwnerName(value: string | undefined) {
  //     this._channelOwnerName = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {typeUniqueId | undefined} _categoryId The Id of the associated category
  //  */
  // private _categoryId: typeUniqueId | undefined = undefined;

  // /**
  //  * @method categoryId Getter method for _categoryId
  //  */
  // get categoryId(): typeUniqueId | undefined {
  //     return this._categoryId;
  // }

  // /**
  //  * @method categoryId Setter method for _categoryId
  //  * @param {typeUniqueId | undefined} value The input value for setting _categoryId
  //  */
  // set categoryId(value: typeUniqueId | undefined) {
  //     this._categoryId = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {string | undefined} _categoryName The name of the associated category
  //  */
  // private _categoryName: string | undefined = undefined;

  // /**
  //  * @method categoryName Getter method for _categoryName
  //  */
  // get categoryName(): string | undefined {
  //     return this._categoryName;
  // }

  // /**
  //  * @method categoryName Setter method for _categoryName
  //  * @param {string | undefined} value The input value for setting _categoryName
  //  */
  // set categoryName(value: string | undefined) {
  //     this._categoryName = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {IChannel | undefined} _category A reference to the underlying channel object
  //  */
  // private _category: IChannel | undefined = undefined;

  // /**
  //  * @method category Getter method for _category
  //  */
  // get category(): IChannel | undefined {
  //     return this._category;
  // }

  // /**
  //  * @method category Setter method for _category
  //  * @param {IChannel | undefined} value The input value for setting _category
  //  */
  // set category(value: IChannel | undefined) {
  //     this._category = value;
  // }
  // /*-----------------------------------------------*/

}