import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import {
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Clear as ClearIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { IChannelSharingUser } from '../../../../dataObjects/viewModels/channelSharing/ChannelSharingUser';
import { KeyValuePairsStringAssets, TooltipStringAssets } from '../../../../assets/stringAssets';
import { enumSharingPermission } from '../../../../dataObjects/enums';
import { ICurrentUserChannelSharingViewModel } from '../../../../dataObjects/viewModels/channelSharing/CurrentUserChannelSharingViewModel';


export interface IChannelSharingUserCardGridViewItemProps extends PropsWithChildren<unknown> {
  channelSharingUser: IChannelSharingUser;
  currentUserChannelSharingViewModel: ICurrentUserChannelSharingViewModel;
  onRevoke: (user: IChannelSharingUser) => void;
}


export const ChannelSharingUserCardGridViewItem: React.FC<IChannelSharingUserCardGridViewItemProps> = (props: IChannelSharingUserCardGridViewItemProps) => {

  const { channelSharingUser, currentUserChannelSharingViewModel, onRevoke } = props;
  const { userId } = channelSharingUser;

  const classes = cardGridViewItemStyles();

  // will hold setting indicating whether the currentUser has Admin privilege to the channel
  const [currentUserHasAdminAccessToChannel, setCurrentUserHasAdminAccessToChannel] = useState<boolean>(false);

  // define an effect that will set whether the currentUser has Admin privilege to the channel
  useEffect(() => {
    setCurrentUserHasAdminAccessToChannel(currentUserChannelSharingViewModel.currentUserChannelSharingPermission === enumSharingPermission.Admin);
  }, [currentUserChannelSharingViewModel]);

  // will indicate whether to enable or disable the Edit and Revoke buttons (start with least privileges)
  const [enableEditButton, setEnableEditButton] = useState<boolean>(false);
  const [enableRevokeButton, setEnableRevokeButton] = useState<boolean>(false);

  // will hold the tooltips for the Edit and Revoke buttons
  const [editButtonTooltipToDisplay, setEditButtonTooltipToDisplay] = useState<string>(TooltipStringAssets.channelSharingUser_EditSharing);
  const [revokeButtonTooltipToDisplay, setRevokeButtonTooltipToDisplay] = useState<string>(TooltipStringAssets.channelSharingUser_RevokeSharing);

  // define an effect that will respond to changes for whether the user has Admin access to the channel, allowing
  // use to set up tooltip messages for Edit and Revoke buttons
  useEffect(() => {
    // // we need a defined currentUser
    // assume buttons will be disabled
    let enableButtons: boolean = false;

    // start with default messages that indicate the user has permission to Edit and Revoke
    let editButtonTooltip: string = TooltipStringAssets.channelSharingUser_EditSharing;
    let revokeButtonTooltip: string = TooltipStringAssets.channelSharingUser_RevokeSharing;

    // if the ChannelSharingUser is the same as the currentUser, change the tooltips
    if (currentUserChannelSharingViewModel.currentUser.id === channelSharingUser.userId) {
      editButtonTooltip = TooltipStringAssets.channelSharingUser_EditDisallowedUserOwnsChannel;
      revokeButtonTooltip = TooltipStringAssets.channelSharingUser_RevokeDisallowedUserOwnsChannel;
    } else if (!currentUserHasAdminAccessToChannel) {
      // otherwise, if the user doesn't have Admin permission, change the tooltips
      editButtonTooltip = TooltipStringAssets.channelSharingUser_EditDisallowedForInsufficientPermissions;
      revokeButtonTooltip = TooltipStringAssets.channelSharingRequest_RevokeDisallowedForInsufficientPermissions;
    } else {
      enableButtons = true;
    }

    setEditButtonTooltipToDisplay(editButtonTooltip);
    setRevokeButtonTooltipToDisplay(revokeButtonTooltip);

    setEnableEditButton(enableButtons);
    setEnableRevokeButton(enableButtons);

  }, [currentUserHasAdminAccessToChannel]);

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      <Card elevation={6} className={classes.card}>

        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {`${channelSharingUser.userLastName}, ${channelSharingUser.userFirstName}`}
        </Typography>

        <CardContent>
          <Typography className={classes.cardDescription} variant="body1">
            {channelSharingUser.userEmail}
          </Typography>
          <Typography className={classes.cardDescription} variant="body1">
            {`${KeyValuePairsStringAssets.getValueFromKey(KeyValuePairsStringAssets.sharingPermissionKeyValuePairs, channelSharingUser.permission)}`}
          </Typography>
        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit sharing" (if it's not the owner of the channel) or "Edit disallowed" 
            // (if it IS the owner of the channel)
            title={editButtonTooltipToDisplay}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                disabled={!enableEditButton}
                onClick={() => navigate(`/channelSharingUser/${channelSharingUser.channelId}/${channelSharingUser.userId}`)}
              >
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            // the tooltip for the revoke button will either be "Revoke sharing" (if it's not the owner of the channel) or "Revoke disallowed" 
            // (if it IS the owner of the channel)
            title={revokeButtonTooltipToDisplay}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="revoke"
                disabled={!enableRevokeButton}
                onClick={() => channelSharingUser && onRevoke(channelSharingUser)} >
                <ClearIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>

      </Card>
    </>
  );

}