
export enum enumEmailRequestType {
  EmailAddressChangedFrom = 'EmailAddressChangedFrom', // an email indicating that the current user's email address changed from existing to a new email address
  EmailAddressChangedTo = 'EmailAddressChangedTo', // an email indicating that the current user's email address changed to an email address from a previous one
  AccountVerification = 'EmailAddressVerification', // an email to a new user for verifying the email address of the user
  ObjectSharingRequest = 'ObjectSharingRequest', // a request by a user to share an object (eg, Channel) with another user
  PasswordReset = 'PasswordReset', // an email to an existing user for resetting the user's password
  PasswordResetCompleted = 'PasswordResetCompleted', // an email to an existing user indicating that the user's password has been reset
  RecoverEmailAddress = 'RecoverEmailAddress', // an email to an email address being recovered (reverted back after email address change)
  SigninWithEmailLink = 'SigninWithEmailLink', // an email to an existing user allowing a login with the link provided in the email (instead of email/password)
  Unknown = 'Unknown', // an unknown value
}
