import { IAlertInfo } from ".";
import { typeAlertType } from "../../../../components/types/typeAlertType";

export class AlertInfo implements IAlertInfo {

  constructor(
    showAlert?: boolean,
    alertType?: typeAlertType,
    message?: string,
    // onClearAlert?: (() => void) | undefined
  ) {
    this.showAlert = showAlert ?? false;
    this.alertType = alertType ?? undefined;
    this.message = message ?? '';
    // this.onClearAlert = onClearAlert ?? undefined;
  }

  /*-----------------------------------------------*/
  /**
   * @property {boolean} showAlert Whether to show an alert
   */
  private _showAlert: boolean = false;

  // getter for _showAlert
  get showAlert(): boolean {
    return this._showAlert;
  }

  // setter for _showAlert
  set showAlert(value: boolean) {
    this._showAlert = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeAlertType} alertType The type of alert
   */
  private _alertType: typeAlertType = undefined;

  // getter for _alertType
  get alertType(): typeAlertType {
    return this._alertType;
  }

  // setter for _alertType
  set alertType(value: typeAlertType) {
    this._alertType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} message The alert message
   */
  private _message: string = '';

  // getter for _message
  get message(): string {
    return this._message;
  }

  // setter for _message
  set message(value: string) {
    this._message = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of the derived class instance to JSON, along with all of its descendants. 
   * (Note: This method will be called if JSON.stringify() is executed on an instance.)
   * @returns A JSON object with serialized data from 'this' instance of the derived class.
   */
  toJSON(includeContainedObjects: boolean = true): IAlertInfoAsJson {
    try {
      // declare a JSON object to be returned
      const jsonObject: IAlertInfoAsJson = {
        showAlert: this.showAlert,
        alertType: this.alertType,
        message: this.message,
      };

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects in this base class
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IAlertInfoAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A Beacon instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IAlertInfoAsJson, includeContainedObjects: boolean = true): IBeacon {
    try {
      // create a new instance of this class
      let alertInfoObject: AlertInfo = Object.create(Beacon.prototype);

      // copy any additional field values from the json object 
      alertInfoObject.showAlert = jsonObject.showAlert;
      alertInfoObject.alertType = jsonObject.alertType;
      alertInfoObject.message = jsonObject.message;

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // there are no contained objects
      }

      return alertInfoObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/


}