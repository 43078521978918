import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { typeUniqueId } from '../../../dataObjects/types';
import { ITopic } from '../../../dataObjects/models/topics/Topic';

// export interface ISaveTopicStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const topicSaveStatusSlice = createSlice({
  name: 'topicSaveStatus',
  initialState,
  reducers: {
    // The topicSaveRequest triggers the saving of a Topic. It's not really associated with the Store State
    // for the 'saveTopicStatus' store variable; however, it is used to both trigger the Topic Save Request -AND-
    // to call the topicSaveStatusChange() action to set the state to 'Requested'.
    // If we didn't use this design patter, the most likely alternative would require creating another Store State
    // variable (eg, 'saveTopicData') and create another slice with a topicSaveRequest action. 
    topicSaveRequest: (state, action: PayloadAction<ITopic>) => {
      topicSaveStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    topicSaveStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { topicSaveRequest, topicSaveStatusChange } = topicSaveStatusSlice.actions;

// export the core reducer for the slice
export const topicSaveStatusReducer = topicSaveStatusSlice.reducer;

export default topicSaveStatusSlice.reducer;