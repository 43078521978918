import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ISaveImageLinkRequest } from '../../../dataObjects/models/serviceRequests/imageLink/SaveImageLinkRequest';
import { ISaveImageLinkWorkflowState } from '../../store/SaveImageWorkflowState';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions';
import { IImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: enumWorkflowState | null = null;

export const imageLinkSaveStatusSlice = createSlice({
  name: 'imageLinkSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    imageLinkSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    imageLinkSaveWithoutFileUploadRequest: (state, action: PayloadAction<ISaveImageLinkRequest>) => {
      const saveImageLinkRequest: ISaveImageLinkRequest = action.payload;
      const newState: ISaveImageLinkWorkflowState = {
        imageLink: saveImageLinkRequest.imageLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.imageLink_SaveRequested,
        fileUploadRequest: undefined
      }

      // call internal base function to set the newState into the IStoreState variable
      imageLinkSaveWorkflowStateChange(newState);
    },
    imageLinkSaveWithFileUploadRequest: (state, action: PayloadAction<ISaveImageLinkRequest>) => {
      const saveImageLinkRequest: ISaveImageLinkRequest = action.payload;
      const newState: ISaveImageLinkWorkflowState = {
        imageLink: saveImageLinkRequest.imageLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.imageLink_SaveRequested,
        fileUploadRequest: saveImageLinkRequest.fileUploadRequest
      }

      // call internal base function to set the newState into the IStoreState variable
      imageLinkSaveWorkflowStateChange(newState);
    },
    imageLinkFileUploadRequest: (state, action: PayloadAction<IFileUploadRequest>) => {
      // we can only add a file upload request if the state variable in IStoreState has been set to an object value

      if (state) {
        const newState: ISaveImageLinkWorkflowState = {
          ...(state as ISaveImageLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.imageLink_UploadingImage,
          fileUploadRequest: action.payload
        }

        // call internal base function to set the newState into the IStoreState variable
        imageLinkSaveWorkflowStateChange(newState);
      }
    },
    imageLinkFileUploadProgressUpdate: (state, action: PayloadAction<IFileUploadProgress>) => {
      // we can only add a file upload progress update if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadProgress: IFileUploadProgress = action.payload;
        const workflowStateMessage = `File upload is ${fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% complete`;

        const newState: ISaveImageLinkWorkflowState = {
          ...(state as ISaveImageLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadProgress: fileUploadProgress
        }

        // call internal base function to set the newState into the IStoreState variable
        imageLinkSaveWorkflowStateChange(newState);
      }
    },
    imageLinkFileUploadResult: (state, action: PayloadAction<ICloudStorageFileUploadResult>) => {
      // we can only add a file upload result if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadResult: ICloudStorageFileUploadResult = action.payload;
        const fileUploadTimeInSeconds: number = fileUploadResult.totalMilliseconds / 1000;
        const workflowStateMessage = `File (${fileUploadResult.file.name}) was successfully uploaded in ${fileUploadTimeInSeconds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} seconds`;

        const newState: ISaveImageLinkWorkflowState = {
          ...(state as ISaveImageLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadResult: fileUploadResult,
        }

        // call internal base function to set the newState into the IStoreState variable
        imageLinkSaveWorkflowStateChange(newState);
      }
    },
    imageLinkFileUploadVerify: (state) => {
      // we can only add a file upload verification if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.imageLink_FileUploadVerifying;

        const newState: ISaveImageLinkWorkflowState = {
          ...(state as ISaveImageLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        imageLinkSaveWorkflowStateChange(newState);
      }
    },
    imageLinkSaveSuccess: (state, action: PayloadAction<IImageLink>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.imageLink_SaveSuccess;

        const newState: ISaveImageLinkWorkflowState = {
          ...(state as ISaveImageLinkWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        imageLinkSaveWorkflowStateChange(newState);
      }
    },
    imageLinkSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.imageLink_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveImageLinkWorkflowState = {
          ...(state as ISaveImageLinkWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        imageLinkSaveWorkflowStateChange(newState);
      }
    },
    imageLinkSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      imageLinkSaveWorkflowStateChange(null);
    },

  },
})

// Action creators are generated for each function in reducer)
export const {
  imageLinkSaveWorkflowStateChange,
  imageLinkSaveWithoutFileUploadRequest,
  imageLinkSaveWithFileUploadRequest,
  imageLinkFileUploadRequest,
  imageLinkFileUploadProgressUpdate,
  imageLinkFileUploadResult,
  imageLinkFileUploadVerify,
  imageLinkSaveSuccess,
  imageLinkSaveError,
  imageLinkSaveStatusClear,
} = imageLinkSaveStatusSlice.actions;

// export the core reducer for the slice
export const imageLinkSaveStatusReducer = imageLinkSaveStatusSlice.reducer;

export default imageLinkSaveStatusSlice.reducer;