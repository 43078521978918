import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IImageLink } from '../../../dataObjects/models/digitalMedia/ImageLink';

const initialState: enumWorkflowState | null = null;

export const imageLinkDeleteStatusSlice = createSlice({
  name: 'imageLinkDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IImageLink
    imageLinkDeleteRequest: (state, action: PayloadAction<IImageLink>) => {
      imageLinkDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    imageLinkDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { imageLinkDeleteRequest, imageLinkDeleteStatusChange } = imageLinkDeleteStatusSlice.actions;

// export the core reducer for the slice
export const imageLinkDeleteStatusReducer = imageLinkDeleteStatusSlice.reducer;

export default imageLinkDeleteStatusSlice.reducer;