import { createSlice } from '@reduxjs/toolkit';

// export interface IDisplayCategorizedState {
//   value: boolean,
// }

const initialState: boolean = false;

export const displayCategorizedSlice = createSlice({
  name: 'displayCategorized',
  initialState,
  reducers: {
    setDisplayCategorized(state, action) {
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { setDisplayCategorized } = displayCategorizedSlice.actions;

// export the core reducer for the slice
export const displayCategorizedReducer = displayCategorizedSlice.reducer;

export default displayCategorizedSlice.reducer;
