import React, { PropsWithChildren } from 'react';
import { Grid, Box, Typography } from '@mui/material';
import { listViewStyles } from '../../../styles';
import { ITopicItem } from '../../../../dataObjects/models/topics/TopicItem';
import TopicItemsListViewItem from './TopicItemsListViewItem';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IChannel } from '../../../../dataObjects/models/channels/Channel';


export interface ITopicItemsListViewProps extends PropsWithChildren<unknown> {
  topicItems: Array<ITopicItem>;
  currentUser: IUser;
  channel: IChannel;
  onDelete: (topicItem: ITopicItem) => void;
}

export const TopicItemsListView: React.FC<ITopicItemsListViewProps> = (props: ITopicItemsListViewProps) => {
  TopicItemsListView.displayName = 'TopicItems List View';

  // eslint-disable-next-line no-console
  // console.info(`Render TopicItemsListView`);

  const classes: Record<string, string> = listViewStyles();

  const { topicItems, currentUser, channel, onDelete } = props;

  const userPermissions : IUserAccessPermissionsForObject = new UserAccessPermissionsForObject(currentUser.id, channel);

  return (
    <>
      {/* overall grid for list. There will be a header row and two data rows: 
          - Header Row
          - Data Row 1: Name & Action Buttons
          - Data Row 2: Description
      */}
      <Grid container direction="column" >
        {/* Header Row */}
        <Grid key='tableHeader' item container className={classes.tableHeader} xs={12} direction="row">
          <Grid key='nameAndDescriptionLabel' item xs={12} md={9} >
            <Typography variant='h6'>
              Name / Description
            </Typography>
          </Grid>
          <Grid item container md={3} direction="row" justifyContent="center" alignContent="flex-start">
            <Grid key='topicItemItemsLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Digital Media
                </Typography>
              </Box>
            </Grid>
            <Grid key='editLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Edit
                </Typography>
              </Box>
            </Grid>
            <Grid key='deleteLabel' item container className={classes.actionButtonLabelContainer} md={4} justifyContent='center' >
              {/* Only display the next label if the screen size is Medium or larger */}
              <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                <Typography variant='h6'>
                  Delete
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {
          currentUser && channel &&
          topicItems.map((topicItem, idx) => (
            <TopicItemsListViewItem
              key={topicItem.id}
              topicItem={topicItem}
              userPermissions={userPermissions}
              onDelete={onDelete}
            />
          ))
        }
      </Grid>
    </>
  );
};

export default TopicItemsListView;
