import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchCategoriesStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const categoriesFetchStatusSlice = createSlice({
  name: 'categoriesFetchStatus',
  initialState,
  reducers: {
    setFetchCategoriesStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchCategoriesStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchCategoriesStatus, clearFetchCategoriesStatus } = categoriesFetchStatusSlice.actions;

// export the core reducer for the slice
export const categoriesFetchStatusReducer = categoriesFetchStatusSlice.reducer;

export default categoriesFetchStatusSlice.reducer;