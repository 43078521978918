import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ISaveAudioLinkRequest } from '../../../dataObjects/models/serviceRequests/audioLink/SaveAudioLinkRequest';
import { ISaveAudioLinkWorkflowState } from '../../store/SaveAudioWorkflowState';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { IFileUploadRequest } from '../../../dataObjects/models/fileUpload/FileUploadRequest';
import { ICloudStorageFileUploadResult } from '../../../firebaseServices/cloudStorageServices/cloudStorageFileUploadActions';
import { IAudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: enumWorkflowState | null = null;

export const audioLinkSaveStatusSlice = createSlice({
  name: 'audioLinkSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    audioLinkSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    audioLinkSaveWithoutFileUploadRequest: (state, action: PayloadAction<ISaveAudioLinkRequest>) => {
      const saveAudioLinkRequest: ISaveAudioLinkRequest = action.payload;
      const newState: ISaveAudioLinkWorkflowState = {
        audioLink: saveAudioLinkRequest.audioLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.audioLink_SaveRequested,
        fileUploadRequest: undefined
      }

      // call internal base function to set the newState into the IStoreState variable
      audioLinkSaveWorkflowStateChange(newState);
    },
    audioLinkSaveWithFileUploadRequest: (state, action: PayloadAction<ISaveAudioLinkRequest>) => {
      const saveAudioLinkRequest: ISaveAudioLinkRequest = action.payload;
      const newState: ISaveAudioLinkWorkflowState = {
        audioLink: saveAudioLinkRequest.audioLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.audioLink_SaveRequested,
        fileUploadRequest: saveAudioLinkRequest.fileUploadRequest
      }

      // call internal base function to set the newState into the IStoreState variable
      audioLinkSaveWorkflowStateChange(newState);
    },
    audioLinkFileUploadRequest: (state, action: PayloadAction<IFileUploadRequest>) => {
      // we can only add a file upload request if the state variable in IStoreState has been set to an object value

      if (state) {
        const newState: ISaveAudioLinkWorkflowState = {
          ...(state as ISaveAudioLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: MessagesStringAssets.audioLink_UploadingAudio,
          fileUploadRequest: action.payload
        }

        // call internal base function to set the newState into the IStoreState variable
        audioLinkSaveWorkflowStateChange(newState);
      }
    },
    audioLinkFileUploadProgressUpdate: (state, action: PayloadAction<IFileUploadProgress>) => {
      // we can only add a file upload progress update if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadProgress: IFileUploadProgress = action.payload;
        const workflowStateMessage = `File upload is ${fileUploadProgress.percentComplete.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}% complete`;

        const newState: ISaveAudioLinkWorkflowState = {
          ...(state as ISaveAudioLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadProgress: fileUploadProgress
        }

        // call internal base function to set the newState into the IStoreState variable
        audioLinkSaveWorkflowStateChange(newState);
      }
    },
    audioLinkFileUploadResult: (state, action: PayloadAction<ICloudStorageFileUploadResult>) => {
      // we can only add a file upload result if the state variable in IStoreState has been set to an object value
      if (state) {
        const fileUploadResult: ICloudStorageFileUploadResult = action.payload;
        const fileUploadTimeInSeconds: number = fileUploadResult.totalMilliseconds / 1000;
        const workflowStateMessage = `File (${fileUploadResult.file.name}) was successfully uploaded in ${fileUploadTimeInSeconds.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} seconds`;

        const newState: ISaveAudioLinkWorkflowState = {
          ...(state as ISaveAudioLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
          fileUploadResult: fileUploadResult,
        }

        // call internal base function to set the newState into the IStoreState variable
        audioLinkSaveWorkflowStateChange(newState);
      }
    },
    audioLinkFileUploadVerify: (state) => {
      // we can only add a file upload verification if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.audioLink_FileUploadVerifying;

        const newState: ISaveAudioLinkWorkflowState = {
          ...(state as ISaveAudioLinkWorkflowState),
          workflowState: enumWorkflowState.InProgress,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        audioLinkSaveWorkflowStateChange(newState);
      }
    },
    audioLinkSaveSuccess: (state, action: PayloadAction<IAudioLink>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.audioLink_SaveSuccess;

        const newState: ISaveAudioLinkWorkflowState = {
          ...(state as ISaveAudioLinkWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        audioLinkSaveWorkflowStateChange(newState);
      }
    },
    audioLinkSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.audioLink_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveAudioLinkWorkflowState = {
          ...(state as ISaveAudioLinkWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        audioLinkSaveWorkflowStateChange(newState);
      }
    },
    audioLinkSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      audioLinkSaveWorkflowStateChange(null);
    },

  },
})

// Action creators are generated for each function in reducer)
export const {
  audioLinkSaveWorkflowStateChange,
  audioLinkSaveWithoutFileUploadRequest,
  audioLinkSaveWithFileUploadRequest,
  audioLinkFileUploadRequest,
  audioLinkFileUploadProgressUpdate,
  audioLinkFileUploadResult,
  audioLinkFileUploadVerify,
  audioLinkSaveSuccess,
  audioLinkSaveError,
  audioLinkSaveStatusClear,
} = audioLinkSaveStatusSlice.actions;

// export the core reducer for the slice
export const audioLinkSaveStatusReducer = audioLinkSaveStatusSlice.reducer;

export default audioLinkSaveStatusSlice.reducer;