import { typeUniqueId } from '../../types';
import { IThemeSpecsViewModel } from '.';
import { IThemeBaseColors, ThemeBaseColors } from '../../models/themes/ThemeBaseColors';
import { IThemeSpecsViewModelAsJson } from './IThemeSpecsViewModelAsJson';
import { JsonConverter } from '../../utilities/JsonConverter';
import { IThemeSpecs } from '../../models/themes/ThemeSpecs';

// const UNCATEGORIZED_CATEGORY_SUFFIX: string = '-Uncategorized';

/**
 * @class ThemeSpecs Represents a set of specifications for a UI theme
 * {@linkcode IThemeSpecs}
 */
export class ThemeSpecsViewModel implements IThemeSpecsViewModel {

  /**
   * @method Constructor method
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {string} name The name of the themeSpecs
   * @param {IThemeBaseColors} themeBaseColors Base colors that will be used to derive the theme's UI color scheme
   * @param {string} description (optional) A description for the themeSpecs
   */
  constructor(
    id: typeUniqueId,
    name: string,
    themeBaseColors: IThemeBaseColors,
    description: string,
  ) {
    this._id = id;
    this._name = name;
    this._description = description;
    this._themeBaseColors = themeBaseColors.copy();
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} id Unique Id for the themeSpecs
   */
  private _id: typeUniqueId;

  // getter for _id
  get id(): typeUniqueId {
    return this._id;
  }

  // setter for _id
  set id(value: typeUniqueId) {
    this._id = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} name represents the name of the themeSpecs
   */
  private _name: string = '';

  // getter for _propName
  get name() {
    return this._name;
  }

  // setter for _propName
  set name(value) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description property is the description for the ThemeSpecsItem
   */
  private _description: string = '';

  /**
   * @method description is an optional getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description is an optional setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeBaseColors} _themeBaseColors Bbase colors that will drive a theme's color presentation
   */
  private _themeBaseColors: IThemeBaseColors;

  /**
   * @method themeBaseColors Getter method for _themeBaseColors
   */
  get themeBaseColors() {
    return this._themeBaseColors;
  }

  /**
   * @method themeBaseColors Setter method for _themeBaseColors
   * @param {string} value Input value for setting _themeBaseColors
   */
  set themeBaseColors(value) {
    this._themeBaseColors = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(): IThemeSpecsViewModelAsJson {
    try {
      // prepare JSON object for return 
      const jsonObject: IThemeSpecsViewModelAsJson = {
        id: this.id,
        name: this.name,
        description: this.description,
        themeBaseColors: this.themeBaseColors.toJSON(),
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IThemeSpecsViewModelAsJson} jsonObject A JSON version of a class instance.
   * @returns An IThemeSpecsViewModel instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IThemeSpecsViewModelAsJson): IThemeSpecsViewModel {
    try {
      // create a new instance of this class
      let themeSpecsViewModelObject: ThemeSpecsViewModel = Object.create(ThemeSpecsViewModel.prototype);

      // copy any additional field values from the json object 
      if (jsonObject.id) {
        themeSpecsViewModelObject.id = jsonObject.id;
      }

      // copy any additional field values from the json object 
      if (jsonObject.name) {
        themeSpecsViewModelObject.name = jsonObject.name;
      }

      if (jsonObject.description) {
        themeSpecsViewModelObject.description = jsonObject.description;
      }

      if (jsonObject.themeBaseColors) {
        themeSpecsViewModelObject.themeBaseColors = JsonConverter.fromJSON(ThemeBaseColors, jsonObject.themeBaseColors);
      }

      return themeSpecsViewModelObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromThemeSpecs Converts an IThemeSpecs object to an IThemeSpecsViewModel object.
   * @param {IThemeSpecs} themeSpecsObject An IThemeSpecs object.
   * @returns An IThemeSpecsViewModel instance with values copied from the themeSpecsObject
   */
  static fromThemeSpecs(themeSpecsObject: IThemeSpecs): IThemeSpecsViewModel {
    try {
      const themeSpecsViewModel: IThemeSpecsViewModel =
        new ThemeSpecsViewModel(
          themeSpecsObject.id,
          themeSpecsObject.name,
          themeSpecsObject.themeBaseColors.copy(),
          themeSpecsObject.description,
        );

      return themeSpecsViewModel;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method arrayFromThemeSpecsArray Converts an array of IThemeSpecs objects to an array of IThemeSpecsViewModel objects.
   * @param {Array<IThemeSpecs>} themeSpecsArray An array of IThemeSpecs objects.
   * @returns An array of IThemeSpecsViewModel objects with values copied from the themeSpecsArray
   */
  static arrayFromThemeSpecsArray(themeSpecsArray: Array<IThemeSpecs>): Array<IThemeSpecsViewModel> {
    try {
      let themeSpecsViewModelArray: Array<IThemeSpecsViewModel> = [];

      themeSpecsArray.forEach((themeSpecs) => {
        themeSpecsViewModelArray.push(ThemeSpecsViewModel.fromThemeSpecs(themeSpecs));
      });

      return themeSpecsViewModelArray;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
