import { put } from "redux-saga/effects";
import { saveNote } from "../../../firebaseServices/dataServices/dataServiceActions/noteActions";
import { INote } from '../../../dataObjects/models/digitalMedia/Note';
import { ISaveNoteRequest } from '../../../dataObjects/models/serviceRequests/note/SaveNoteRequest';
import { ISaveNoteWorkflowState } from "../../store/SaveNoteWorkflowState";
import { enumWorkflowState } from "../../../dataObjects/enums";
import { noteSaveError, noteSaveSuccess, noteSaveWorkflowStateChange } from "../../slices/note/noteSaveStatusSlice";
import { MessagesStringAssets } from "../../../assets/stringAssets";

/**
 * @function saveNoteWorkerSaga A "worker" saga function that performs the work for a request to
 *   save a Note .
 * @param action 
 */
export function* saveNoteWorkerSaga(saveNoteRequest: ISaveNoteRequest) {
  try {
    // // extract the ISaveNoteRequest from the action payload
    // const saveNoteRequest: ISaveNoteRequest | undefined = action.saveNoteRequest;

    // if the ISaveNoteRequest is defined...
    if (saveNoteRequest !== undefined) {
      
      // update store state...      
      const saveNoteWorkflowState: ISaveNoteWorkflowState = {
        note: saveNoteRequest.note,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.note_SaveRequested,
      }
      yield put(noteSaveWorkflowStateChange(saveNoteWorkflowState));

      // get the Note from ISaveNoteRequest
      const note: INote | undefined = saveNoteRequest.note;

      // if the Note is defined...
      if (note !== undefined) {
        // call services method to save the note
        yield saveNote(note)

        // dispatch an action to indicate that the save operation was successful
        yield put(noteSaveSuccess(note));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveNoteWorkflowState: ISaveNoteWorkflowState = {
          note: saveNoteRequest.note,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.note_SaveSuccess,
        }

        saveNoteWorkflowState.workflowState = enumWorkflowState.Success;
        saveNoteWorkflowState.workflowStateMessage = MessagesStringAssets.note_SaveSuccess;
        yield put(noteSaveWorkflowStateChange(saveNoteWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(noteSaveError(error));
  }
}