import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchSocialMediaPostsStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchSocialMediaPostsStatusSlice = createSlice({
  name: 'fetchSocialMediaPostsStatus',
  initialState,
  reducers: {
    setFetchSocialMediaPostsStatus: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    clearFetchSocialMediaPostsStatus: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchSocialMediaPostsStatus, clearFetchSocialMediaPostsStatus } = fetchSocialMediaPostsStatusSlice.actions;

// export the core reducer for the slice
export const fetchSocialMediaPostsStatusReducer = fetchSocialMediaPostsStatusSlice.reducer;

export default fetchSocialMediaPostsStatusSlice.reducer;