import { typeUniqueId } from "../../../../dataObjects/types";
import { generateIdForEmailRequest } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../dataObjects/models/persistence/UserPersistenceData";

/**
 * @function generateEmailRequestForPasswordResetCompleted Generates an email request to reset the user's password
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} emailAddress The email address of the target of the email.
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForPasswordResetCompleted(userId: typeUniqueId, emailAddress: string, firstName: string, lastName: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForPasswordResetCompleted`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      // request to generate an emailRequestId
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userId, enumEmailRequestType.PasswordResetCompleted);

      displayConsoleLogs && console.log(`%c generateEmailRequestForPasswordResetCompleted. emailRequestId: ${emailRequestId}`, 'background: #055; color: #fff');

      const recipientUserName: string = `${firstName} ${lastName}`;
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userId, recipientUserName, 'web browser', enumLocale.English);

      // compose an EmailRequest object
      const emailRequest: IEmailRequest = new EmailRequest(userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.PasswordResetCompleted, userId, undefined, undefined, emailAddress, recipientUserName,
        enumAppPlatformType.Web, `https://${baseDomain}`, emailRequestSubmittedTimestamp, undefined, undefined, undefined, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForPasswordResetCompleted. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #055; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForPasswordResetCompleted. Error encountered: ${error}`, 'background: #550; color: #fff');
      reject(error);
    }
  });
}