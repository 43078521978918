import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import _ from 'lodash';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { UserCategoriesAndChannelsContext, IUserCategoriesAndChannelsContextData } from '.';
import { enumPersistableObjectType, enumSharableObjectType, enumSharingRequestStatus } from '../../../dataObjects/enums';
import { Category, ICategory, ICategoryAsJson } from '../../../dataObjects/models/categories/Category';
import { Channel, IChannel, IChannelAsJson } from '../../../dataObjects/models/channels/Channel';
import { IObjectSharingRequestTracker, IObjectSharingRequestTrackerAsJson, ObjectSharingRequestTracker } from '../../../dataObjects/models/collaboration/ObjectSharingTracker';
import { IUser, IUserAsJson, User } from '../../../dataObjects/models/users/User';
import { typeUniqueId, typeUniqueIdWithUndefinedOption } from '../../../dataObjects/types';
import { JsonConverter } from '../../../dataObjects/utilities/JsonConverter';
import { ChannelViewModel, IChannelViewModel } from '../../../dataObjects/viewModels/channelViewModel';
import { IChannelViewModelsByCategory } from '../../../dataObjects/viewModels/channelViewModelsByCategory';
import { ISharingRequestViewModel } from '../../../dataObjects/viewModels/sharingRequestViewModel/ISharingRequestViewModel';
import { getChannelFromDescendantObjectId, getChannelObjectUsingId, getChannelsForUser_onSnapshot, getSharingRequestsByTypeForRecipient_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/channelActions';
import { getMultipleObjectsByIds_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { getUserCategoriesForUser_onSnapshot } from '../../../firebaseServices/dataServices/dataServiceActions/userCategoryActions';
import { enumAuthenticationStatus } from '../../enums';
import { enumCategoriesAndChannelsDataPreparationStatus } from '../../enums';
import { ICurrentUserContextData, useCurrentUserContext } from '../currentUser';

/**
 * @interface IUserCategoriesAndChannelsProviderProps declares any input properties required for this component.
 */
export interface IUserCategoriesAndChannelsProviderProps extends PropsWithChildren<unknown> {
}


/**
 * @provider UserCategoriesAndChannelsProvider A React Provider component that is based on the UserCategoriesAndChannelsContext and can be used to provide a 
 *   React component tree embedded in the provider with information for the current user's Categories and Channels. 
 */
export const UserCategoriesAndChannelsProvider: FC<IUserCategoriesAndChannelsProviderProps> = (props: IUserCategoriesAndChannelsProviderProps) => {

  UserCategoriesAndChannelsProvider.displayName = "UserCategoriesAndChannelsProvider";

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = true;

  displayConsoleLogs && console.log('Entered/Refreshed UserCategoriesAndChannelsProvider component');

  // define default unsubscribe callback function definition (an empty function that returns 'void')
  const defaultUnsubscribeCallback: () => void = () => { };

  // define default array of unsubscribe callback functions definition (an array of empty functions where each returns 'void')
  const defaultArrayOfUnsubscribeCallbacks: Array<() => void> = [];

  // Define another default unsubscribe callback function definition that is a function which points to an empty function. 
  // This is the type of structure that is required to capture a function pointer in a useState variable.
  const defaultUnsubscribeCallbackForStateVariable: () => () => void = () => () => { displayConsoleLogs && console.log(`%c Default Unsubscribe Callback`, 'background: #00d; color: #fff'); };

  // Define another default unsubscribe callback function definition that is a function which points to an empty function. 
  // This is the type of structure that is required to capture a function pointer in a useState variable.
  const defaultArrayOfUnsubscribeCallbacksForStateVariable: Array<() => () => void> = [];

  // fetch the 'children' property from the input properties
  const { children } = props;

  // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  displayConsoleLogs && console.log(`%c UserCategoriesAndChannelsProvider.currentUserContextData: \n${JSON.stringify(currentUserContextData)}`, 'background: #600; color: #fff');

  // The Id of current user logged in via Firebase. This value will be 'undefined' if no user is logged in.
  // const [currentUser, setCurrentUser] = useState<IUser | undefined>(currentUserContextData.currentUser);
  const [currentUserId, setCurrentUserId] =
    useState<typeUniqueId | undefined>(currentUserContextData.currentUser ? currentUserContextData.currentUser.id : undefined);

  // The Email Address of current user logged in via Firebase. This value will be 'undefined' if no user is logged in.
  const [currentUserEmail, setCurrentUserEmail] =
    useState<string | undefined>(currentUserContextData.currentUser ? currentUserContextData.currentUser.userSettings.email : undefined);

  // The authentication status. We won't want to take action until we know whether user authentication has been completed, or 
  // we know that it's an Anonymous User.
  const [authenticationStatus, setAuthenticationStatus] = useState<enumAuthenticationStatus>(currentUserContextData.authenticationStatus);

  // State of fetching and preparing Categories and Channels data for the current user
  const [dataPreparationStatus, setDataPreparationStatus] = useState<enumCategoriesAndChannelsDataPreparationStatus>(enumCategoriesAndChannelsDataPreparationStatus.None);

  // Boolean flags to indicate whether data has been retrieved for Categories and Channels
  const [categoriesDataRetrieved, setCategoriesDataRetrieved] = useState<boolean>(false);
  const [channelsDataRetrieved, setChannelsDataRetrieved] = useState<boolean>(false);

  // Categories associated with the current user
  const [categories, setCategories] = useState<Array<ICategory> | undefined>(undefined);

  // Channels associcated with the current user account
  const [channels, setChannels] = useState<Array<IChannel>>([]);

  // The current channel for the current user (for use when drilling down into Topics & Topic Items for a Channel)
  const [currentChannel, setCurrentChannel] = useState<IChannel | undefined>(undefined);

  // The current channelId for the current user (for use when drilling down into Topics & Topic Items for a Channel).
  // This is intended to allow a component lower in the hierarchy to request setting the currentChannel from an Id
  const [currentChannelId, setCurrentChannelId] = useState<typeUniqueIdWithUndefinedOption>(undefined);

  // Channel View Models represent Channels associated with the current user (view models are Channel objects that provide
  // pertinent data for views to use in rendering Channel information)
  const [channelViewModels, setChannelViewModels] = useState<Array<IChannelViewModel> | undefined>(undefined);

  // Will be set to an array of Users that are associated with the unique OwnerIds. After the ChannelViewModels information has been set up,
  // objects will be retrieved that represent Users configured to share the Channel.
  const [usersForUniqueOwnerIds, setUsersForUniqueOwnerIds] = useState<Array<IUser>>([]);

  // Will be set to an array of Channels that are associated with the unique ChannelIds for Open Sharing Requests. 
  const [channelsForUniqueChannelIdsForOpenSharingRequests, setChannelsForUniqueChannelIdsForOpenSharingRequests] = useState<Array<IChannel>>([]);

  // all ChannelViewModels for the current user account, organized by Category
  const [allChannelViewModelsByCategory, setAllChannelViewModelsByCategory] = useState<IChannelViewModelsByCategory[]>([]);

  // will hold the set of unique OwnerIds (UserIds) across the set of ChannelViewModels
  const [uniqueOwnerIds, setUniqueOwnerIds] = useState<Array<typeUniqueId>>([]);

  // whether Categories data has been prepared
  const [categoriesDataPrepared, setCategoriesDataPrepared] = useState<boolean>(false);

  // whether Channels data has been prepared
  const [channelsDataPrepared, setChannelsDataPrepared] = useState<boolean>(false);

  // whether UsersForUniqueOwnerIds data has been prepared
  const [usersForUniqueOwnerIdsDataPrepared, setUsersForUniqueOwnerIdsDataPrepared] = useState<boolean>(false);

  // Open Sharing Requests for Channels for the user, in the form of an array of IObjectSharingRequestTracker objects
  const [openSharingRequestsForChannels, setOpenSharingRequestsForChannels] = useState<Array<IObjectSharingRequestTracker>>([]);

  // Collection of unique Channel Ids across the Open Sharing Requests for Channels for the user
  const [uniqueChannelIdsForOpenSharingRequests, setUniqueChannelIdsForOpenSharingRequests] = useState<Array<typeUniqueId> | undefined>(undefined);

  // View Models for Open Sharing Requests for Channels for the user, in the form of an array of ISharingRequestViewModel objects
  const [openSharingRequestsForChannelsViewModels, setOpenSharingRequestsForChannelsViewModels] = useState<Array<ISharingRequestViewModel> | undefined>(undefined);

  // whether OpenSharingRequestsForChannelsViewModels data has been prepared
  const [openSharingRequestsDataPrepared, setOpenSharingRequestsDataPrepared] = useState<boolean>(false);

  // if Current User Id information has changed, set it into local useState variables
  if (currentUserId !== (currentUserContextData.currentUser ? currentUserContextData.currentUser.id : undefined)) {
    setCurrentUserId(currentUserContextData.currentUser ? currentUserContextData.currentUser.id : undefined);
  }

  // if Current User Email information has changed, set it into local useState variables
  if (currentUserEmail !== (currentUserContextData.currentUser ? currentUserContextData.currentUser.userSettings.email : undefined)) {
    setCurrentUserEmail(currentUserContextData.currentUser ? currentUserContextData.currentUser.userSettings.email : undefined);
  }

  if (authenticationStatus !== currentUserContextData.authenticationStatus) {
    setAuthenticationStatus(currentUserContextData.authenticationStatus);
  }

  // Define a local state variable that will hold the unsubscribe callback for a snapshot request to get the
  // User record from the DB for the current user. The default case will be an empty (No Op) function.
  // NOTE: In order to store a function reference in a useState variable, we must actually store a function that calls a function. This is 
  //       because, if storing just a function referance, upon calling the 'setXXX' function of the useState variable, it will automatically call the 
  //       function right away, and the processing of this component will stop.
  const [unsubscribeCallbackForCategoriesData, setUnsubscribeCallbackForCategoriesData] = useState<() => () => void>(defaultUnsubscribeCallbackForStateVariable);
  const [unsubscribeCallbackForChannelsData, setUnsubscribeCallbackForChannelsData] = useState<() => () => void>(defaultUnsubscribeCallbackForStateVariable);
  const [unsubscribeCallbacksForUsersData, setUnsubscribeCallbacksForUsersData] = useState<(() => void)[]>([]);
  const [unsubscribeCallbackForChannelSharingRequestsData, setUnsubscribeCallbackForChannelSharingRequestsData] = useState<() => () => void>(defaultUnsubscribeCallbackForStateVariable);
  const [unsubscribeCallbacksForChannelsPerOpenSharingRequestsData, setUnsubscribeCallbacksForChannelsPerOpenSharingRequestsData] = useState<Array<() => void>>(defaultArrayOfUnsubscribeCallbacksForStateVariable);
  // const [unsubscribeCallbackForOpenSharingRequestsForChannelsViewModelsData, setUnsubscribeCallbackForOpenSharingRequestsForChannelsViewModelsData] = useState<() => () => void>(defaultUnsubscribeCallbackForStateVariable);


  function resolveCurrentChannel(id: typeUniqueId, fromObjectType: enumPersistableObjectType): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const channelObject: IChannel | undefined = await getChannelFromDescendantObjectId(id, fromObjectType);
        setCurrentChannel(channelObject);
      } catch (error: any) {
        reject(error);
      }
    });
  }


  // a useEffect hook that responds to a change in user authentication from Firebase
  // If a new user has been authenticated (newlyAuthenticatedUserId !== undefined), launch a snapshot query to fetch user info
  // Otherwise, (newlyAuthenticatedUserId === undefined) it means that the existing user was logged off 
  useEffect(() => {
    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() request, and hold
    // it within the context of this useEffect hook.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackCategoriesSnapshotQuery: () => void = defaultUnsubscribeCallback;
    let unsubscribeCallbackChannelsSnapshotQuery: () => void = defaultUnsubscribeCallback;
    let unsubscribeCallbackForChannelSharingRequestsSnapshotQuery: () => void = defaultUnsubscribeCallback;

    // if the currentUserId is a valid value (not undefined), it means that the current user has changed, either by a user
    // logging in or the user being switched from one to another (not sure if that's actually possible, but we'll cover that 
    // base in case it is possible).
    if ((currentUserId !== undefined) && (authenticationStatus === enumAuthenticationStatus.AuthenticationComplete)) {
      // reset flag to indicate that Categories data has not yet been prepared
      setCategoriesDataPrepared(false);

      // subscribe to onShapshot updates for Categories, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider.useEffect for [currentUserId]. About to call getUserCategoriesForUser_onSnapshot()`, 'background: #ddd; color: #030');
      getUserCategoriesForUser_onSnapshot(currentUserId, onCategoriesSnapshotCallback).then((unsubscribe: () => void) => {
        displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider.useEffect for [currentUserId]. Completed call to getUserCategoriesForUser_onSnapshot()`, 'background: #ddd; color: #030');
        // set flag to indicate that Categories data has NOT been retrieved (as we are just initiating the data fetch)
        if (categoriesDataRetrieved) {
          setCategoriesDataRetrieved(false);
        }
        // capture the unsubscribe callback function so that we can unsubscribe from the snapshot query upon unmounting
        unsubscribeCallbackCategoriesSnapshotQuery = unsubscribe;
        // Capture the unsubscribe callback function so that we can unsubscribe in the case that Current User changes without logging
        // out. Not sure that this is a viable use case (changing Current User), but we'll have it covered if it is viable.
        setUnsubscribeCallbackForCategoriesData(() => unsubscribe);
        displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider.useEffect for [currentUserId]. Completed call to setUnsubscribeCallbackForCategoriesData()`, 'background: #00d; color: #fff');
      }).catch(error => {
        displayConsoleLogs && console.error(`In UserCategoriesAndChannelsProvider.useEffect for [currentUserId]. Error after call to getUserCategoriesForUser_onSnapshot() & setUnsubscribeCallbackForUserData(). \nError: ${error}`)
      });

      // // reset flag to indicate that Channels data has not yet been prepared
      // setChannelsDataPrepared(false);

      // subscribe to onShapshot updates for Channels, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. About to call getUserChannelsForUser_onSnapshot()`, 'background: #ddd; color: #030');
      getChannelsForUser_onSnapshot(currentUserId, onChannelsSnapshotCallback).then((unsubscribe: () => void) => {
        displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. Completed call to getUserChannelsForUser_onSnapshot()`, 'background: #ddd; color: #030');
        // set flag to indicate that Channels data has NOT been retrieved (as we are just initiating the data fetch)
        if (channelsDataRetrieved) {
          setChannelsDataRetrieved(false);
        }
        // capture the unsubscribe callback function so that we can unsubscribe from the snapshot query upon unmounting
        unsubscribeCallbackChannelsSnapshotQuery = unsubscribe;
        // Capture the unsubscribe callback function so that we can unsubscribe in the case that Current User changes without logging
        // out. Not sure that this is a viable use case (changing Current User), but we'll have it covered if it is viable.
        setUnsubscribeCallbackForChannelsData(() => unsubscribe);
        displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. Completed call to setUnsubscribeCallbackForChannelsData()`, 'background: #00d; color: #fff');
      }).catch(error => {
        displayConsoleLogs && console.error(`In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. Error after call to getUserChannelsForUser_onSnapshot() & setUnsubscribeCallbackForUserData(). \nError: ${error}`)
      });

      // // reset flag to indicate that OpenSharingRequests data has not yet been prepared
      // setOpenSharingRequestsDataPrepared(false);

      // subscribe to onShapshot updates for Open Sharing Requests for Channels, providing realtime updates to the data, and capture the 'unsubscribe' callback method provided by firestore
      displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [currentUserId, currentUserEmail, authenticationStatus]. About to call getSharingRequestsByTypeForRecipient_onSnapshot()`, 'background: #ddd; color: #030');
      if (currentUserEmail !== undefined) {
        getSharingRequestsByTypeForRecipient_onSnapshot(enumSharableObjectType.Channel, currentUserEmail, onOpenSharingRequestsForChannelsSnapshotCallback, [enumSharingRequestStatus.New, enumSharingRequestStatus.Requested]).then((unsubscribe: () => void) => {
          displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. Completed call to getSharingRequestsByTypeForRecipient_onSnapshot()`, 'background: #ddd; color: #030');
          // capture the unsubscribe callback function so that we can unsubscribe from the snapshot query upon unmounting
          unsubscribeCallbackForChannelSharingRequestsSnapshotQuery = unsubscribe;
          // Capture the unsubscribe callback function so that we can unsubscribe in the case that Current User changes without logging
          // out. Not sure that this is a viable use case (changing Current User), but we'll have it covered if it is viable.
          setUnsubscribeCallbackForChannelSharingRequestsData(() => unsubscribe);
          displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. Completed call to setUnsubscribeCallbackForChannelSharingRequests()`, 'background: #00d; color: #fff');
        }).catch(error => {
          displayConsoleLogs && console.error(`In UserChannelsAndChannelsProvider.useEffect for [currentUserId]. Error after call to getSharingRequestsByTypeForRecipient_onSnapshot() & setUnsubscribeCallbackForChannelSharingRequests(). \nError: ${error}`)
        });
      }

    } else {
      // since there is no Current User and Authentication Status is NOT Complete, we want to reset everything
      displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider.useEffect with UNDEFINED currentUserId. Ready to call unsubscribeCallback for snapshot queries`, 'background: #00d; color: #fff');

      // set Categories, Channels, etc. to be empty values
      if (categories !== undefined && categories.length > 0) {
        setCategories(undefined);
      }

      if (channels.length > 0) {
        setChannels([]);
      }

      if (channelViewModels !== undefined && channelViewModels.length > 0) {
        setChannelViewModels(undefined);
      }

      if (allChannelViewModelsByCategory.length > 0) {
        setAllChannelViewModelsByCategory([]);
      }

      if (uniqueOwnerIds.length > 0) {
        setUniqueOwnerIds([]);
      }

      if (usersForUniqueOwnerIds.length > 0) {
        setUsersForUniqueOwnerIds([]);
      }

      if (openSharingRequestsForChannels !== undefined && openSharingRequestsForChannels.length > 0) {
        setOpenSharingRequestsForChannels([]);
      }

      if (openSharingRequestsForChannelsViewModels !== undefined && openSharingRequestsForChannelsViewModels.length > 0) {
        setOpenSharingRequestsForChannelsViewModels(undefined);
      }

      // reset flags to indicate that data hasn't been prepared
      setCategoriesDataPrepared(false);
      setChannelsDataPrepared(false);
      setOpenSharingRequestsDataPrepared(false);
      setUsersForUniqueOwnerIdsDataPrepared(false);

      // reset flags to indicate that data has not been retrieved for Catagories and Channels
      setCategoriesDataRetrieved(false);
      setChannelsDataRetrieved(false);

      // make calls to unsubscribe callback functions for the respective snapshot queries used to retrieve Categories and Channels data
      unsubscribeCallbackForCategoriesData();
      unsubscribeCallbackForChannelsData();
      unsubscribeCallbackForChannelSharingRequestsData();

      // if there any active snapshot listeners for the Users data, unsubscribe to those listeners
      if (unsubscribeCallbacksForUsersData && unsubscribeCallbacksForUsersData.length > 0) {
        unsubscribeCallbacksForUsersData.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }

      displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider.useEffect with UNDEFINED currentUserId. Ready to call setNewUnsubscribeCallback methods for useState variables`, 'background: #00d; color: #fff');
      setUnsubscribeCallbackForCategoriesData(defaultUnsubscribeCallbackForStateVariable);
      setUnsubscribeCallbackForChannelsData(defaultUnsubscribeCallbackForStateVariable);
      setUnsubscribeCallbackForChannelSharingRequestsData(defaultUnsubscribeCallbackForStateVariable);
      setUnsubscribeCallbacksForUsersData(defaultArrayOfUnsubscribeCallbacks);
    }

    // perform cleanup when the component unmounts
    return () => {
      displayConsoleLogs && console.info(`%c UserCategoriesAndChannelsProvider.useEffect for [currentUserId]. Cleanup before unmounting. Ready to call unsubscribeCallback for snapshot queries.`, 'background: #00d; color: #fff')

      // call methods to unsubscribe from the snapshot queries
      unsubscribeCallbackCategoriesSnapshotQuery();
      unsubscribeCallbackChannelsSnapshotQuery();
      unsubscribeCallbackForChannelSharingRequestsSnapshotQuery();
      displayConsoleLogs && console.info(`%c UserCategoriesAndChannelsProvider.useEffect for [currentUserId]. Cleanup before unmounting. After call to unsubscribeCallback for snapshot queries.`, 'background: #00d; color: #fff')
    }

  }, [currentUserId, currentUserEmail, authenticationStatus]);

  // a useEffect() hook that responds to a change in the currentChannelId
  // This useEffect() will ...
  useEffect(() => {
    if (currentChannelId === undefined) {
      setCurrentChannel(undefined);
    } else {
      // fetch the Channel object indicated by the currentChannelId using an IIFE (Immediately Invoked Function Expression) 
      // to perform inline operation using async/await
      (async () => {
        try {
          const localChannel: IChannel | undefined = await getChannelObjectUsingId(currentChannelId);
          setCurrentChannel(localChannel);
        } catch (error: any) {
          displayConsoleLogs && console.error(`ChannelSharingRequestForm. Error attempting to get user account for a given email address: ${error.toString()}`);
        }
      })();
    }
  }, [currentChannelId]);

  // // set up a useEffect() hook to respond to changes in categoriesDataRetrieved and/or channelsDataRetrieved
  // // This useEffect() will respond to changes in flags that indicate whether data has been retrieved from Catagories and/or Channels repositories
  // useEffect(() => {
  //   // if data has been retrieved for BOTH Categories and Channels, trigger operations to process the data
  //   if (categoriesDataRetrieved && channelsDataRetrieved) {

  //     // set dataPreparationStatus to 'DataBeingProcessed' if not already set to that value
  //     if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed) {
  //       setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed)
  //     }

  //     // // reset flags to indicate that data is not being retrieved for either Catagories or Channels
  //     // setCategoriesDataRetrieved(false);
  //     // setChannelsDataRetrieved(false);
  //   } else {
  //     // is there anything to do here???
  //   }
  // }, [categoriesDataRetrieved, channelsDataRetrieved])

  // set up a useEffect() hook to respond to changes in Categories
  // This useEffect() will ...
  useEffect(() => {

  }, [categories]);

  // set up a useEffect() hook to respond to changes in Channels
  // This useEffect() will generate an array of unique OwnerIds from the array of Channels, and then, if the new array differs from
  // the existing uniqueOwnerIds array, set the array of new unique OwnerIds into the uniqueOwnerIds state variable
  useEffect(() => {
    if (channels && channels.length > 0) {
      // get the collection of unique OwnerIds across all Channels
      const uniqueOwnerIdsFromChannels: Array<typeUniqueId> = [...new Set(channels.map(channel => channel.ownerId))];

      setUniqueOwnerIds(uniqueOwnerIdsFromChannels);
    } else {
      // reset the uniqueOwnerIds array to an empty array
      setUniqueOwnerIds([]);
    }

  }, [channels]);

  // set up a useEffect() hook to respond to changes in uniqueOwnerIds
  // If there are values in the uniqueOwnerIds state variable array, we will launch snapshot queries to fetch the User record
  // corresponding to each unique OwnerId.
  useEffect(() => {

    // initialize a new empty array of unsubscribe callbacks
    let unsubscribeCallbacksForUsers: Array<() => void> = [];

    if (uniqueOwnerIds && uniqueOwnerIds.length > 0) {

      // if there are any active snapshot listeners for the Users data, unsubscribe from those listeners
      if (unsubscribeCallbacksForUsersData && unsubscribeCallbacksForUsersData.length > 0) {
        unsubscribeCallbacksForUsersData.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }

      // reset flag to indicate that UsersForUniqueOwnerIds data has not yet been prepared
      setUsersForUniqueOwnerIdsDataPrepared(false);

      displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [uniqueOwnerIds]. About to call getMultipleObjectsByIds_onSnapshot()`, 'background: #ddd; color: #030');
      getMultipleObjectsByIds_onSnapshot(uniqueOwnerIds, enumPersistableObjectType.User, onUserSnapshotCallback).then((unsubscribes: (() => void)[]) => {
        // capture the array of unsubscribe callback functions, both to a local array variable and a useState array variable
        unsubscribeCallbacksForUsers = unsubscribes;
        setUnsubscribeCallbacksForUsersData(unsubscribes);
      }).catch(error => {
        displayConsoleLogs && console.error(`UserCategoriesAndChannelsProvider. Error getting multiple User/Owner records (call to getMultipleObjectsByIds_onSnapshot): ${error.toString()}`);
      });

    } else {
      // if there are any active snapshot listeners for the Users data, unsubscribe from those listeners
      if (unsubscribeCallbacksForUsersData && unsubscribeCallbacksForUsersData.length > 0) {
        unsubscribeCallbacksForUsersData.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }

      setUnsubscribeCallbacksForUsersData(defaultArrayOfUnsubscribeCallbacksForStateVariable);
    }

    // perform cleanup when the component unmounts
    return () => {
      // unsubscribe from all snapshot queries pertaining to fetching User records
      displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider. In useEffect() for [uniqueOwnerIds]. Cleanup before unmounting. Ready to call array of unsubscribeCallbacksForUsers.`)
      if (unsubscribeCallbacksForUsers && unsubscribeCallbacksForUsers.length > 0) {
        unsubscribeCallbacksForUsers.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }
      displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider. In useEffect() for [uniqueOwnerIds]. Cleanup before unmounting. After call to array of unsubscribeCallbacksForUsers.`)
    }
  }, [uniqueOwnerIds]);


  // An effect that will handle changes to channels, categories, uniqueOwnerIds, & usersForUniqueOwnerIds
  // The key purpose of this effect is to build an array of ChannelViewModel objects that will be used to populate areas
  // of the page and its internal views.
  // We will only build an array of ChannelViewModel objects if there are Channels, Categories, and  
  useEffect(() => {
    let channelViewModelsData: Array<IChannelViewModel> = new Array<IChannelViewModel>();

    if ((channels && channels.length > 0) &&
      (categories && categories.length > 0) &&
      (uniqueOwnerIds && uniqueOwnerIds.length > 0) &&
      (usersForUniqueOwnerIds && usersForUniqueOwnerIds.length > 0)) {
      if (usersForUniqueOwnerIds.length === uniqueOwnerIds.length) {

        // *** Create instances of ChannelViewModel objects ***
        // traverse the collection of Channels and, for each, create a ChannelViewModel object, filling in as much data
        // as is provided by the Channels. 
        // At this stage, we only have the underlying Channel object, not the Category nor the OwnerUser
        channels.forEach((channel: IChannel) => {
          const channelViewModel: IChannelViewModel =
            new ChannelViewModel(channel);

          channelViewModelsData.push(channelViewModel);
        });

        // declare an empty array to hold the Category objects and their associated ChannelViewModel objects
        let channelViewModelsByCategory: Array<IChannelViewModelsByCategory> = new Array<IChannelViewModelsByCategory>();

        // *** Fill in Category data for each ChannelViewModel -AND- categorize the ChannelViewModel objects by Category **
        // traverse the collection of categories and, for each category, use the Ids of children to associate channels with the category, 
        // filling in the Category information in the array of ChannelViewModel objects.
        // Then, associate a collection of ChannelViewModel objects with each Category traverse (0-n Channels per Category)
        categories.forEach((category: ICategory, index: number) => {
          // declare an array that will hold the ChannelViewModel objects associated with the current Category
          let channelViewModelsForCurrentCategory: Array<IChannelViewModel> = new Array<IChannelViewModel>();

          // for each channel Id (childId) in the array of channels (children) for the category, get the channel object from the channelsCopy
          category.children.forEach((channelId: typeUniqueId, index: number) => {

            // try to find a non-private channel in the channelsCopy array that matches the channelId of the iterated item
            let idxChannelViewModel: number = channelViewModelsData.findIndex(channelViewModel => channelViewModel.channel.id === channelId);

            // if a non-private channel wasn't found, form the channelId as a private channel id
            if (idxChannelViewModel === -1) {
              const privateChannelId: string = Channel.userPrivateChannelIdFromNonPrivateId(channelId);

              // try to find a channel in the channelViewModelsData array that matches the formulated privateChannelId
              idxChannelViewModel = channelViewModelsData.findIndex(channelViewModel => channelViewModel.channel.id === privateChannelId);
            }

            // if a ChannelViewModel was found in the channelViewModel array, ...
            if (idxChannelViewModel !== -1) {
              // ... fill in the category information for that ChannelViewModel, and ...
              channelViewModelsData[idxChannelViewModel].category = category;

              // ... add the ChannelViewModel object to the array of ChannelViewModel objects for the current Category
              channelViewModelsForCurrentCategory.push(channelViewModelsData[idxChannelViewModel]);
            }
          });

          // We have completed accumulating an array of channels for the current category. 
          // Now, create an object with the category's Id and array of associated channels, and push it onto the array.
          const channelViewModelsByCategoryItem: IChannelViewModelsByCategory = {
            category: category,
            channelViewModels: channelViewModelsForCurrentCategory,
            channelCount: channelViewModelsForCurrentCategory.length // this is just for convenience and a slight performance benefit when needing the count of channels
          };

          // add the object to the array of channelsByCategory
          channelViewModelsByCategory.push(channelViewModelsByCategoryItem);
        });

        // set the channelViewModels by category into the state variable
        setAllChannelViewModelsByCategory(channelViewModelsByCategory);

        // *** Assign the Owner Name to each ChannelViewModel object ***
        // traverse the channelViewModelsData collection, find the Owner User record associated with each (matching the ownerId key),
        // and then assign the user's name to the ownerName value
        channelViewModelsData.forEach((channelViewModel: IChannelViewModel, index: number) => {
          // find the User record with an Id that matches the channelOwnerId of the channelViewModel
          let idxUser: number = usersForUniqueOwnerIds.findIndex((user: IUser) => user.id === channelViewModel.channel.ownerId);

          if (idxUser !== -1) {
            channelViewModel.ownerUser = usersForUniqueOwnerIds[idxUser];
          }
        });
      }
    }

    // set the ChannelViewModels data into local state
    setChannelViewModels(channelViewModelsData);

    setChannelsDataPrepared(true);

    // if (categoriesDataPrepared && channelsDataPrepared && usersForUniqueOwnerIdsDataPrepared) {
    //   // set dataPreparationStatus to 'DataPreparationComplete' if not already set to that value
    //   if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
    //     setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete)
    //   }
    // }
    // }, [dataPreparationStatus, channels, categories, uniqueOwnerIds, usersForUniqueOwnerIds, categoriesDataPrepared, channelsDataPrepared, usersForUniqueOwnerIdsDataPrepared]);
  }, [dataPreparationStatus, channels, categories, uniqueOwnerIds, usersForUniqueOwnerIds]);

  useEffect(() => {

    // if (categoriesDataPrepared && channelsDataPrepared && openSharingRequestsDataPrepared && usersForUniqueOwnerIdsDataPrepared) {
    if (categoriesDataPrepared && channelsDataPrepared && openSharingRequestsDataPrepared) {
      // set dataPreparationStatus to 'DataPreparationComplete' if not already set to that value
      if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) {
        setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete)
      }
    }
  // }, [dataPreparationStatus, categoriesDataPrepared, channelsDataPrepared, openSharingRequestsDataPrepared, usersForUniqueOwnerIdsDataPrepared]);
  }, [dataPreparationStatus, categoriesDataPrepared, channelsDataPrepared, openSharingRequestsDataPrepared]);

  // An effect that will respond to changes to Open Sharing Requests for Channels
  // The key purpose of this effect is to capture the collection of unique ChannelIds across all Open Sharing Requests for Channels
  useEffect(() => {
    // get the collection of unique ChannelIds across all Open Sharing Requests which are for Channels (filtering in 
    // the Sharing Requests that have a sharingObjectType of enumSharableObjectType.Channel)
    let uniqueChannelIds: Array<typeUniqueId> = [...new Set(openSharingRequestsForChannels.filter(sharingRequest => sharingRequest.sharingObjectType === enumSharableObjectType.Channel).map(sharingRequest => sharingRequest.sharingObjectId))];

    // determine if the calculated set of ChannelIds (uniqueChannelIds) has the same set of ChannelIds as the uniqueChannelIdsForOpenSharingRequests
    const uniqueChannelsIdsRemainUnchanged: boolean = (uniqueChannelIds.length === uniqueChannelIdsForOpenSharingRequests?.length) &&
      uniqueChannelIds.every(function (channelId) {
        return uniqueChannelIdsForOpenSharingRequests.includes(channelId);
      })

    // if the collection of Unique ChannelIds has changed...
    if (!uniqueChannelsIdsRemainUnchanged) {
      // set the state variable for the Unique Channel Ids for Open Sharing Requests
      setUniqueChannelIdsForOpenSharingRequests(uniqueChannelIds);
    }
  }, [openSharingRequestsForChannels]);

  // An effect that will respond to changes in the uniqueChannelIdsForOpenSharingRequests
  // The key purpose of this effect is to retrieve the Channel objects from the database for each unique ChannelId
  // represented across the Open Sharing Requests
  useEffect(() => {

    // initialize a new empty array of unsubscribe callbacks
    let unsubscribeCallbacksForChannelsPerOpenSharingRequests: Array<() => void> = [];

    if (uniqueChannelIdsForOpenSharingRequests && uniqueChannelIdsForOpenSharingRequests.length > 0) {

      // if there any active snapshot listeners for the Users data, unsubscribe to those listeners
      if (unsubscribeCallbacksForChannelsPerOpenSharingRequestsData && unsubscribeCallbacksForChannelsPerOpenSharingRequestsData.length > 0) {
        unsubscribeCallbacksForChannelsPerOpenSharingRequestsData.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }

      // reset flag to indicate that UsersForUniqueOwnerIds data has not yet been prepared
      setUsersForUniqueOwnerIdsDataPrepared(false);

      displayConsoleLogs && console.log(`%c In UserChannelsAndChannelsProvider.useEffect for [uniqueOwnerIds]. About to call getMultipleObjectsByIds_onSnapshot()`, 'background: #ddd; color: #030');
      getMultipleObjectsByIds_onSnapshot(uniqueChannelIdsForOpenSharingRequests, enumPersistableObjectType.Channel, onChannelsForOpenSharingRequestsSnapshotCallback).then((unsubscribes: (() => void)[]) => {
        // capture the array of unsubscribe callback functions, both to a local array variable and a useState array variable
        unsubscribeCallbacksForChannelsPerOpenSharingRequests = unsubscribes;
        setUnsubscribeCallbacksForChannelsPerOpenSharingRequestsData(unsubscribes);
      }).catch(error => {
        displayConsoleLogs && console.error(`UserCategoriesAndChannelsProvider. Error getting multiple Channel records (call to getMultipleObjectsByIds_onSnapshot): ${error.toString()}`);
      });

    } else {
      // if there are any active snapshot listeners for the Channels for Open Sharing Requests data, unsubscribe from those listeners
      if (unsubscribeCallbacksForChannelsPerOpenSharingRequestsData && unsubscribeCallbacksForChannelsPerOpenSharingRequestsData.length > 0) {
        unsubscribeCallbacksForChannelsPerOpenSharingRequestsData.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }

      setUnsubscribeCallbacksForChannelsPerOpenSharingRequestsData(defaultArrayOfUnsubscribeCallbacksForStateVariable);
    }

    // perform cleanup when the component unmounts
    return () => {
      // unsubscribe from all snapshot queries pertaining to fetching Channels for Open Sharing Requests records
      displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider. In useEffect() for [uniqueChannelIdsForOpenSharingRequests]. Cleanup before unmounting. Ready to call array of unsubscribeCallbacksForChannelsPerOpenSharingRequests.`)
      if (unsubscribeCallbacksForChannelsPerOpenSharingRequests && unsubscribeCallbacksForChannelsPerOpenSharingRequests.length > 0) {
        unsubscribeCallbacksForChannelsPerOpenSharingRequests.forEach((unsubscribeCallback: () => void, index: number) => {
          unsubscribeCallback();
        });
      }
      displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider. In useEffect() for [uniqueChannelIdsForOpenSharingRequests]. Cleanup before unmounting. After call to array of unsubscribeCallbacksForChannelsPerOpenSharingRequests.`)

    }
  }, [uniqueChannelIdsForOpenSharingRequests]);

  // An effect that will respond to changes to Open Sharing Requests for Channels -AND- Channels representing unique ChannelIds
  // for Open Sharing Requests.
  // The key purpose of this effect is to create the Open Sharing Request View Models, which combines requests and channels
  useEffect(() => {
    // Prepare an empty array to hold Open Sharing Request View Models. If there are no requests and/or no channels, it will remain empty.
    let openSharingRequestViewModelsData: Array<ISharingRequestViewModel> = [];

    // if there are Open Sharing Requests -AND- Channels...
    if ((openSharingRequestsForChannels.length > 0) && (channelsForUniqueChannelIdsForOpenSharingRequests.length)) {
      // loop through the Open Sharing Requests...
      openSharingRequestsForChannels.map((sharingRequest: IObjectSharingRequestTracker, idxSharingRequest: number) => {
        // find a channel where its Id that matches the sharingObjectId in the sharingRequest
        const sharingRequestChannel: IChannel | undefined = channelsForUniqueChannelIdsForOpenSharingRequests.find((channel) => channel.id === sharingRequest.sharingObjectId);
        if (sharingRequestChannel !== undefined) {
          openSharingRequestViewModelsData.push({ sharingRequest: sharingRequest, sharingRequestObject: sharingRequestChannel, sharingRequestObjectType: enumSharableObjectType.Channel });
        }
      });
    }

    // set the array into local state
    displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider. In useEffect() for [openSharingRequestsForChannels, channelsForUniqueChannelIdsForOpenSharingRequests].`);
    displayConsoleLogs && console.info(`openSharingRequestViewModelsData: ${JSON.stringify(openSharingRequestViewModelsData)}`);
    setOpenSharingRequestsForChannelsViewModels(openSharingRequestViewModelsData);

    // set flag to indicate that the OpenSharingRequests data has been prepared
    setOpenSharingRequestsDataPrepared(true);
  }, [openSharingRequestsForChannels, channelsForUniqueChannelIdsForOpenSharingRequests]);

  /**
   * @function onCategoriesSnapshotCallback A callback method to receive firestore Categories data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onCategoriesSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider - in onCategoriesSnapshotCallback(). Entering onCategoriesSnapshotCallback.`)

    // reset flag to indicate that Categories data has not yet been prepared
    setCategoriesDataPrepared(false);

    // set dataPreparationStatus to 'DataBeingProcessed' if not already set to that value
    if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed) {
      setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed)
    }

    const categoryDataAsJson: Array<ICategoryAsJson> = [];
    snapshot.forEach(doc => categoryDataAsJson.push({ ...doc.data(), id: doc.id } as ICategoryAsJson));

    // create an array of Category objects from the JSON data
    let categoriesData: Array<ICategory> = JsonConverter.arrayFromJSONArray(Category, categoryDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    categoriesData = _.orderBy(categoriesData, [category => category.name.toUpperCase()], ['asc']);

    // set the Categories data into state
    setCategories(categoriesData);

    // set flag to indicate that Categories data has been prepared
    displayConsoleLogs && console.info(`In UserCategoriesAndChannelsProvider.onCategoriesSnapshotCallback(). Ready to call setCategoriesDataPrepared(true).`)
    setCategoriesDataPrepared(true);

    const abbreviatedCategoriesData = categoriesData.map(category => [category.name, category.description]);

    // displayConsoleLogs && console.info('Categories: ' + JSON.stringify(categoriesData));
    displayConsoleLogs && console.info('Categories: ' + JSON.stringify(abbreviatedCategoriesData));

    // set flag to indicate that Categories data HAS been retrieved, so that data is ready for processing
    if (!categoriesDataRetrieved) {
      setCategoriesDataRetrieved(true);
    }

  }

  /**
   * @function onChannelsSnapshotCallback A callback method to receive firestore Channels data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onChannelsSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider - in onChannelsSnapshotCallback(). Entering onChannelsSnapshotCallback.`)

    // reset flag to indicate that Channels data has not yet been prepared
    setChannelsDataPrepared(false);

    // set dataPreparationStatus to 'DataBeingProcessed' if not already set to that value
    if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed) {
      setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed)
    }

    const channelDataAsJson: Array<IChannelAsJson> = [];
    snapshot.forEach(doc => channelDataAsJson.push({ ...doc.data(), id: doc.id } as IChannelAsJson));

    // create an array of Channel objects from the JSON data
    let channelsData = JsonConverter.arrayFromJSONArray(Channel, channelDataAsJson);

    // use lodash to sort the array by 'name' in ascending order
    // channelsData = _.orderBy(channelsData, ['name'], ['asc']);
    channelsData = _.orderBy(channelsData, [channel => channel.name.toUpperCase()], ['asc']);

    // set the Channels data into state
    setChannels(channelsData);

    // // set flag to indicate that Channels data has been prepared
    // displayConsoleLogs && console.info(`In UserCategoriesAndChannelsProvider.onChannelsSnapshotCallback(). Ready to call setChannelsDataPrepared(true).`)
    // setChannelsDataPrepared(true);

    // set flag to indicate that Channels data HAS been retrieved, so that data is ready for processing
    if (!channelsDataRetrieved) {
      setChannelsDataRetrieved(true);
    }
  }

  /**
   * @function onLiveChannelSharingRequestsSnapshotCallback A callback method to receive firestore ObjectSharingRequestTracker data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onOpenSharingRequestsForChannelsSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.info(`UserCategoriesAndChannelsProvider - in onLiveChannelSharingRequestsSnapshotCallback(). Entering onLiveChannelSharingRequestsSnapshotCallback.`)

    // reset flag to indicate that the OpenSharingRequests data hasn't been prepared
    setOpenSharingRequestsDataPrepared(false);

    // set dataPreparationStatus to 'DataBeingProcessed' if not already set to that value
    if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed) {
      setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed)
    }

    const openSharingRequestsForChannelsDataAsJson: Array<IObjectSharingRequestTrackerAsJson> = [];
    snapshot.forEach(doc => openSharingRequestsForChannelsDataAsJson.push({ ...doc.data(), id: doc.id } as IObjectSharingRequestTrackerAsJson));

    // create an array of IObjectSharingRequestTracker objects from the JSON data
    let openSharingRequestsForChannelsData = JsonConverter.arrayFromJSONArray(ObjectSharingRequestTracker, openSharingRequestsForChannelsDataAsJson);

    // // use lodash to sort the array by 'name' in ascending order
    // liveChannelSharingRequestsData = _.orderBy(liveChannelSharingRequestsData, [request => request..name.toUpperCase()], ['asc']);

    // set the Open Sharing Requests data into state
    setOpenSharingRequestsForChannels(openSharingRequestsForChannelsData);
  }

  /**
   * @function onUserSnapshotCallback A callback method to receive firestore User data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onUserSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {

    // reset flag to indicate that UsersForUniqueOwnerIds data has not yet been prepared
    setUsersForUniqueOwnerIdsDataPrepared(false);

    // // set dataPreparationStatus to 'DataBeingProcessed' if not already set to that value
    // if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed) {
    //   setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed)
    // }

    // fetch the data from the document, which should be a JSON version of the correct type of object
    const userAsJson: IUserAsJson = snapshot.docs[0].data() as IUserAsJson;

    // convert the JSON object to a Typescript object
    const userData: IUser = JsonConverter.fromJSON(User, userAsJson, true);

    // We're going to set the state of the Users (array) associated with uniqueOwnerIds into local state, and we need to add the latest
    // userData object to the array. However, we need to ensure that we don't have a duplicate of this particular user's data (identified by
    // the userData.id field). So, in calling the setUsersForUniqueOwnerIds() method, we will need to access the existing (previous) state
    // of the array, filtering out any array elements where the user's Id matches the userData.id, and than append the userData object to
    // the array
    setUsersForUniqueOwnerIds((prevStateOfUserArray: Array<IUser>) =>
      [...prevStateOfUserArray.filter(user => { return (user.id !== userData.id) }),
        userData]
    );

    // set flag to indicate that UsersForUniqueOwnerIds data has been prepared
    displayConsoleLogs && console.info(`In UserCategoriesAndChannelsProvider.onUserSnapshotCallback(). Ready to call setUsersForUniqueOwnerIdsDataPrepared(true).`)
    setUsersForUniqueOwnerIdsDataPrepared(true);
  }

  /**
   * @function onChannelsForOpenSharingRequestsSnapshotCallback A callback method to receive firestore Channels data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onChannelsForOpenSharingRequestsSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {

    // // reset flag to indicate that OpenSharingRequests data has not yet been prepared
    // setOpenSharingRequestsDataPrepared(false);

    // // set dataPreparationStatus to 'DataBeingProcessed' if not already set to that value
    // if (dataPreparationStatus !== enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed) {
    //   setDataPreparationStatus(enumCategoriesAndChannelsDataPreparationStatus.DataBeingProcessed)
    // }

    // fetch the data from the document, which should be a JSON version of the correct type of object
    const channelAsJson: IChannelAsJson = snapshot.docs[0].data() as IChannelAsJson;

    // convert the JSON object to a Typescript object
    const channelData: IChannel = JsonConverter.fromJSON(Channel, channelAsJson, true);

    // We're going to set the state of the Channels (array) associated with uniqueChannelIdsForOpenSharingRequests into local state, and we need to add the latest
    // channelData object to the array. However, we need to ensure that we don't have a duplicate of this particular channel's data (identified by
    // the channelData.id field). So, in calling the setChannelsForUniqueChannelIdsForOpenSharingRequests() method, we will need to access the existing (previous) state
    // of the array, filtering out any array elements where the channel's Id matches the channelData.id, and than append the channelData object to
    // the array
    displayConsoleLogs && console.info(`In UserCategoriesAndChannelsProvider.onChannelsForOpenSharingRequestsSnapshotCallback(). Ready to call setChannelsForUniqueChannelIdsForOpenSharingRequests().`)
    displayConsoleLogs && console.info(`In UserCategoriesAndChannelsProvider.onChannelsForOpenSharingRequestsSnapshotCallback(). channelData: \n${JSON.stringify(channelData)}`);
    setChannelsForUniqueChannelIdsForOpenSharingRequests((prevStateOfChannelArray: Array<IChannel>) =>
      [...prevStateOfChannelArray.filter(channel => { return (channel.id !== channelData.id) }),
        channelData]
    );
  }

  // NEXT: NEED TO MODIFY CONTEXT STATE TO INCLUDE CHANNELS FOR OPEN SHARING REQUESTS

  // Prepare the Context object with available information.
  // If the 'userLoggingOut' flag has been set, we want to return a status of AuthenticationComplete
  // with an undefined CurrentUser; otherwise, we'll return the current Authentication status and CurrentUser values.
  displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider before preparing context data to return. dataPreparationStatus: ${dataPreparationStatus.toString()}`, 'background: #ddd; color: #030');
  displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider. Preparing context data to return.`, 'background: #ddd; color: #030');
  const userCategoriesAndChannelsContextData: IUserCategoriesAndChannelsContextData =
    (dataPreparationStatus === enumCategoriesAndChannelsDataPreparationStatus.DataPreparationComplete) ?
      {
        state: {
          currentChannel: currentChannel,
          categories: categories,
          channelViewModels: channelViewModels,
          channelsByCategories: allChannelViewModelsByCategory,
          openSharingRequestViewModels: openSharingRequestsForChannelsViewModels,
          status: dataPreparationStatus
        },
        actions: {
          setCurrentChannel: setCurrentChannel,
          resolveCurrentChannel: resolveCurrentChannel
        }
      }
      :
      {
        state: {
          currentChannel: undefined,
          channelViewModels: undefined,
          categories: undefined,
          channelsByCategories: undefined,
          openSharingRequestViewModels: undefined,
          status: dataPreparationStatus
        },
        actions: {
          setCurrentChannel: undefined,
          resolveCurrentChannel: undefined
        }
      }

  displayConsoleLogs && console.log(`%c In UserCategoriesAndChannelsProvider. userCategoriesAndChannelsContextData: ${JSON.stringify(userCategoriesAndChannelsContextData)}`, 'background: #00a; color: #fff');

  // this provider forwards the value to the UserCategoriesAndChannelsContext.Provider
  return (
    <UserCategoriesAndChannelsContext.Provider value={userCategoriesAndChannelsContextData}>
      {children}
    </UserCategoriesAndChannelsContext.Provider>
  )
}
