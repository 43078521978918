import { RegularExpressions } from "../../../../../assets/regularExpressionAssets";

/**
 * @function urlIsARumbleAddress Returns whether the URL is a Rumble address
 * @param url
 */
export const urlIsARumbleAddress: (url: string) => boolean = (url: string) => {

  // // get the regular expression pattern for the website
  // const regEx: RegExp = RegularExpressions.Rumble;

  // // return the result of testing the given url against the regex pattern
  // return regEx.test(url);
  return url.includes('https://rumble.com');
}
