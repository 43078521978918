// import { RandomId } from '../../../utilities/RandomId';
// import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
// import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
// import { VersionAwarePersistable } from "../../persistence/VersionAwarePersistable";
// import { JsonConverter } from "../../../utilities/JsonConverter";
// import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { IThemeColorSpectrumsPalette, IThemeColorSpectrumsPaletteAsJson } from '.';
import { IThemeColorSpectrum } from '../ThemeColorSpectrum';


export class ThemeColorSpectrumsPalette implements IThemeColorSpectrumsPalette {
  /**
   * @method Constructor method
   * @param {IThemeColorSpectrum} colorSpectrumPrimary The base (main) color spectrum spectrum for the Primary color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumSecondary The base (main) color spectrum for the Secondary color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumCancel The base (main) color spectrum for the Cancel color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumError The base (main) color spectrum for the Error color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumHighlight The base (main) color spectrum for the Highlight color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumInfo The base (main) color spectrum for the Info color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumSuccess The base (main) color spectrum for the Success color of the theme
   * @param {IThemeColorSpectrum} colorSpectrumWarning The base (main) color spectrum for the Warning color of the theme
   */
  /**
   * @method Constructor method
   */
  constructor(
    colorSpectrumPrimary: IThemeColorSpectrum,
    colorSpectrumSecondary: IThemeColorSpectrum,
    colorSpectrumCancel: IThemeColorSpectrum,
    colorSpectrumError: IThemeColorSpectrum,
    colorSpectrumHighlight: IThemeColorSpectrum,
    colorSpectrumInfo: IThemeColorSpectrum,
    colorSpectrumSuccess: IThemeColorSpectrum,
    colorSpectrumWarning: IThemeColorSpectrum,
  ) {

    this._colorSpectrumPrimary = colorSpectrumPrimary;
    this._colorSpectrumSecondary = colorSpectrumSecondary;
    this._colorSpectrumCancel = colorSpectrumCancel;
    this._colorSpectrumError = colorSpectrumError;
    this._colorSpectrumHighlight = colorSpectrumHighlight;
    this._colorSpectrumInfo = colorSpectrumInfo;
    this._colorSpectrumSuccess = colorSpectrumSuccess;
    this._colorSpectrumWarning = colorSpectrumWarning;
  }

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumPrimary The base (main) color spectrum for the Primary color of the theme
   */
  private _colorSpectrumPrimary: IThemeColorSpectrum;

  /**
   * @method colorSpectrumPrimary is an optional getter method for _colorSpectrumPrimary
   */
  get colorSpectrumPrimary(): IThemeColorSpectrum {
    return this._colorSpectrumPrimary;
  }

  /**
   * @method colorSpectrumPrimary is an optional setter method for _colorSpectrumPrimary
   * @param {IThemeColorSpectrum} value The colorSpectrumPrimary value to be used to set _colorSpectrumPrimary
   */
  set colorSpectrumPrimary(value: IThemeColorSpectrum) {
    this._colorSpectrumPrimary = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumSecondary The base (main) color spectrum for the Secondary color of the theme
   */
  private _colorSpectrumSecondary: IThemeColorSpectrum;

  /**
   * @method colorSpectrumSecondary is an optional getter method for _colorSpectrumSecondary
   */
  get colorSpectrumSecondary(): IThemeColorSpectrum {
    return this._colorSpectrumSecondary;
  }

  /**
   * @method colorSpectrumSecondary is an optional setter method for _colorSpectrumSecondary
   * @param {IThemeColorSpectrum} value The colorSpectrumSecondary value to be used to set _colorSpectrumSecondary
   */
  set colorSpectrumSecondary(value: IThemeColorSpectrum) {
    this._colorSpectrumSecondary = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumCancel The base (main) color spectrum for the Cancel color of the theme
   */
  private _colorSpectrumCancel: IThemeColorSpectrum;

  /**
   * @method colorSpectrumCancel is an optional getter method for _colorSpectrumCancel
   */
  get colorSpectrumCancel(): IThemeColorSpectrum {
    return this._colorSpectrumCancel;
  }

  /**
   * @method colorSpectrumCancel is an optional setter method for _colorSpectrumCancel
   * @param {IThemeColorSpectrum} value The colorSpectrumCancel value to be used to set _colorSpectrumCancel
   */
  set colorSpectrumCancel(value: IThemeColorSpectrum) {
    this._colorSpectrumCancel = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumError The base (main) color spectrum for the Error color of the theme
   */
  private _colorSpectrumError: IThemeColorSpectrum;

  /**
   * @method colorSpectrumError is an optional getter method for _colorSpectrumError
   */
  get colorSpectrumError(): IThemeColorSpectrum {
    return this._colorSpectrumError;
  }

  /**
   * @method colorSpectrumError is an optional setter method for _colorSpectrumError
   * @param {IThemeColorSpectrum} value The colorSpectrumError value to be used to set _colorSpectrumError
   */
  set colorSpectrumError(value: IThemeColorSpectrum) {
    this._colorSpectrumError = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumHighlight The base (main) color spectrum for the Highlight color of the theme
   */
  private _colorSpectrumHighlight: IThemeColorSpectrum;

  /**
   * @method colorSpectrumHighlight is an optional getter method for _colorSpectrumHighlight
   */
  get colorSpectrumHighlight(): IThemeColorSpectrum {
    return this._colorSpectrumHighlight;
  }

  /**
   * @method colorSpectrumHighlight is an optional setter method for _colorSpectrumHighlight
   * @param {IThemeColorSpectrum} value The colorSpectrumHighlight value to be used to set _colorSpectrumHighlight
   */
  set colorSpectrumHighlight(value: IThemeColorSpectrum) {
    this._colorSpectrumHighlight = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumInfo The base (main) color spectrum for the Info color of the theme
   */
  private _colorSpectrumInfo: IThemeColorSpectrum;

  /**
   * @method colorSpectrumInfo is an optional getter method for _colorSpectrumInfo
   */
  get colorSpectrumInfo(): IThemeColorSpectrum {
    return this._colorSpectrumInfo;
  }

  /**
   * @method colorSpectrumInfo is an optional setter method for _colorSpectrumInfo
   * @param {IThemeColorSpectrum} value The colorSpectrumInfo value to be used to set _colorSpectrumInfo
   */
  set colorSpectrumInfo(value: IThemeColorSpectrum) {
    this._colorSpectrumInfo = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumSuccess The base (main) color spectrum for the Success color of the theme
   */
  private _colorSpectrumSuccess: IThemeColorSpectrum;

  /**
   * @method colorSpectrumSuccess is an optional getter method for _colorSpectrumSuccess
   */
  get colorSpectrumSuccess(): IThemeColorSpectrum {
    return this._colorSpectrumSuccess;
  }

  /**
   * @method colorSpectrumSuccess is an optional setter method for _colorSpectrumSuccess
   * @param {IThemeColorSpectrum} value The colorSpectrumSuccess value to be used to set _colorSpectrumSuccess
   */
  set colorSpectrumSuccess(value: IThemeColorSpectrum) {
    this._colorSpectrumSuccess = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IThemeColorSpectrum} _colorSpectrumWarning The base (main) color spectrum for the Warning color of the theme
   */
  private _colorSpectrumWarning: IThemeColorSpectrum;

  /**
   * @method colorSpectrumWarning is an optional getter method for _colorSpectrumWarning
   */
  get colorSpectrumWarning(): IThemeColorSpectrum {
    return this._colorSpectrumWarning;
  }

  /**
   * @method colorSpectrumWarning is an optional setter method for _colorSpectrumWarning
   * @param {IThemeColorSpectrum} value The colorSpectrumWarning value to be used to set _colorSpectrumWarning
   */
  set colorSpectrumWarning(value: IThemeColorSpectrum) {
    this._colorSpectrumWarning = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IThemeColorSpectrumsPalette} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IThemeColorSpectrumsPalette {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IThemeColorSpectrumsPalette = Object.create(ThemeColorSpectrumsPalette.prototype);
    Object.assign(copyOfObject, this);

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IThemeColorSpectrumsPaletteAsJson {
    try {
      const jsonObject: IThemeColorSpectrumsPaletteAsJson = {
        colorSpectrumPrimary: this.colorSpectrumPrimary,
        colorSpectrumSecondary: this.colorSpectrumSecondary,
        colorSpectrumCancel: this.colorSpectrumCancel,
        colorSpectrumError: this.colorSpectrumError,
        colorSpectrumInfo: this.colorSpectrumInfo,
        colorSpectrumSuccess: this.colorSpectrumSuccess,
        colorSpectrumWarning: this.colorSpectrumWarning,
      };

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
  //  * objects (if requested).
  //  * @param {IThemeColorSpectrumsPaletteAsJson} jsonObject A JSON version of a class instance.
  //  * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
  //  * @returns A ImageLink instance with values copied from the jsonObject
  //  */
  // static fromJSON(jsonObject: IThemeColorSpectrumsPaletteAsJson, includeContainedObjects: boolean = true): IThemeColorSpectrumsPalette {
  //   try {
  //     // create a new instance of this class
  //     let themeBaseColorsObject: IThemeColorSpectrumsPalette = Object.create(ThemeColorSpectrumsPalette.prototype);

  //     // assign all properties from the JSON object to the data members
  //     themeBaseColorsObject.colorSpectrumPrimary = jsonObject.colorSpectrumPrimary;
  //     themeBaseColorsObject.colorSpectrumSecondary = jsonObject.colorSpectrumSecondary;
  //     themeBaseColorsObject.colorSpectrumCancel = jsonObject.colorSpectrumCancel;
  //     themeBaseColorsObject.colorSpectrumError = jsonObject.colorSpectrumError;
  //     themeBaseColorsObject.colorSpectrumInfo = jsonObject.colorSpectrumInfo;
  //     themeBaseColorsObject.colorSpectrumSuccess = jsonObject.colorSpectrumSuccess;
  //     themeBaseColorsObject.colorSpectrumWarning = jsonObject.colorSpectrumWarning;

  //     return themeBaseColorsObject;

  //   } catch (error: any) {
  //     // TODO: log error
  //     // re-throw error
  //     throw error;
  //   }
  // }
  // /*-----------------------------------------------*/

}
