import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const userRegistrationFailureSlice = createSlice({
  name: 'userRegistrationFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userRegistrationFailure(state, action) {
      return action.payload;
    },

    // action has a payload with a null value
    userRegistrationFailureClear(state, action) {
      return null; // action.payload;
    },

  },
})

// Action creators are generated for each function in reducer)
export const { userRegistrationFailure, userRegistrationFailureClear } = userRegistrationFailureSlice.actions;

// export the core reducer for the slice
export const userRegistrationFailureReducer = userRegistrationFailureSlice.reducer;

export default userRegistrationFailureSlice.reducer;