import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { IUserEmailIdAndNameViewModel, UserEmailIdAndNameViewModel } from '../../../../dataObjects/viewModels/userEmailIdAndName';
import { IUserIdAndName } from "../../../../dataObjects/models/users/UserIdAndName";

/**
 * @function getUserAccountInfoFromEmailAddressViaHttp Retrieves user account info via an Http request using an email address and, if found, returns an IUser object
 * @param emailAddress The user account email address to be used in the search operation
 * @returns An IUser object or undefined
 */
export function getUserAccountInfoFromEmailAddressViaHttp(emailAddress: string): Promise<IUserEmailIdAndNameViewModel> {
  return new Promise<IUserEmailIdAndNameViewModel>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c Entered getUserAccountInfoFromEmailAddressViaHttp()', 'background: #055; color: #fff');

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const getUserIdAndNameFromEmailAddress =
        httpsCallable<string, IUserIdAndName>(functions, 'getUserIdAndNameFromEmailAddress');
      getUserIdAndNameFromEmailAddress(emailAddress)
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {IUserIdAndName} */
          const data: IUserIdAndName = result.data;

          displayConsoleLogs && console.log(`%c getUserAccountInfoFromEmailAddressViaHttp(). Returned from Http call. data: ${JSON.stringify(data)}`, 'background: #055; color: #fff');

          const userEmailIdAndName: IUserEmailIdAndNameViewModel =
            new UserEmailIdAndNameViewModel(data.userId, emailAddress, data.lastName, data.firstName);

          resolve(userEmailIdAndName);
        });
    } catch (error: any) {
      alert(`Error. userAccountActivities.getUserAccountInfoFromEmailAddressViaHttp failed: (${error})`);
      reject(error);
    }
  });
}
