/**
 * @class TooltipStringAssets A collection of string assets that pertain to tooltips
 */
export class TooltipStringAssets {
   static channelSharingRequest_EditRequest: string = 'Edit sharing request';
   static channelSharingRequest_EditDisallowedForInsufficientPermissions: string = 'Edit disallowed (insufficient permissions)';
   static channelSharingRequest_RevokeSharing: string = 'Revoke sharing request';
   static channelSharingRequest_RevokeDisallowedForInsufficientPermissions: string = 'Revioke disallowed (insufficient permissions)';

   static channelSharingUser_EditSharing: string = 'Edit sharing for user';
   static channelSharingUser_EditDisallowedForInsufficientPermissions: string = 'Edit disallowed (insufficient permissions)';
   static channelSharingUser_EditDisallowedUserOwnsChannel: string = 'Edit disallowed (user owns channel)';
   static channelSharingUser_RevokeSharing: string = 'Revoke sharing for user';
   static channelSharingUser_RevokeDisallowedForInsufficientPermissions: string = 'Revioke disallowed (insufficient permissions)';
   static channelSharingUser_RevokeDisallowedUserOwnsChannel: string = 'Revoke disallowed (user owns channel)';

   static changeEmailAddress = "Change email address";

   static customThemes_Manage: string = 'Add/Edit/Delete Custom Themes';

   static delete = "Delete";
   static delete_Disallowed = "Delete disallowed";

   static digitalMedia_View = "View Digital Media";

   static drillDown = "Drill down";
   static drillDown_Disallowed = "Drill down disallowed";

   static edit = "Edit";
   static edit_Disallowed = "Edit disallowed";

   static viewTrainingVideos = "View training videos";
}