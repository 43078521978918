import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { INote } from '../../../dataObjects/models/digitalMedia/Note';
import { MessagesStringAssets } from '../../../assets/stringAssets';
import { ISaveNoteRequest } from '../../../dataObjects/models/serviceRequests/note/SaveNoteRequest';
import { ISaveNoteWorkflowState } from '../../store/SaveNoteWorkflowState';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface ISaveNoteStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const noteSaveStatusSlice = createSlice({
  name: 'noteSaveStatus',
  initialState,
  reducers: {
    // base method to change the workflow state, will be called by other reducer functions in this slice
    noteSaveWorkflowStateChange(state, action) {
      return action.payload;
    },
    noteSaveRequest: (state, action: PayloadAction<ISaveNoteRequest>) => {
      const saveNoteRequest: ISaveNoteRequest = action.payload;
      const newState: ISaveNoteWorkflowState = {
        note: saveNoteRequest.note,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.note_SaveRequested,
      }

      // call internal base function to set the newState into the IStoreState variable
      noteSaveWorkflowStateChange(newState);
    },
    noteSaveSuccess: (state, action: PayloadAction<INote>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.note_SaveSuccess;

        const newState: ISaveNoteWorkflowState = {
          ...(state as ISaveNoteWorkflowState),
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        noteSaveWorkflowStateChange(newState);
      }
    },
    noteSaveError: (state, action: PayloadAction<IMdbError>) => {
      // we can only a save success if the state variable in IStoreState has been set to an object value
      if (state) {
        const workflowStateMessage = MessagesStringAssets.note_SaveFailure;
        const error: IMdbError = action.payload;

        const newState: ISaveNoteWorkflowState = {
          ...(state as ISaveNoteWorkflowState),
          workflowState: enumWorkflowState.Failure,
          workflowError: error,
          workflowStateMessage: workflowStateMessage,
        }

        // call internal base function to set the newState into the IStoreState variable
        noteSaveWorkflowStateChange(newState);
      }
    },
    noteSaveStatusClear: (state) => {
      // call internal base function to set the IStoreState variable to null
      noteSaveWorkflowStateChange(null);
    },
  },
})

// Action creators are generated for each function in reducer)
export const {
  noteSaveWorkflowStateChange,
  noteSaveRequest,
  noteSaveSuccess,
  noteSaveError,
  noteSaveStatusClear,
} = noteSaveStatusSlice.actions;

// export the core reducer for the slice
export const noteSaveStatusReducer = noteSaveStatusSlice.reducer;

export default noteSaveStatusSlice.reducer;