import { typeUniqueId } from "../../../../dataObjects/types";
import { generateIdForEmailRequest } from ".";
import { enumAppPlatformType, enumEmailRequestType, enumLocale, enumObjectPersistenceState } from "../../../../dataObjects/enums";
import { EmailRequest, IEmailRequest } from "../../../../dataObjects/models/emailRequests";
import { IUserPersistenceData, UserPersistenceData } from "../../../../dataObjects/models/persistence/UserPersistenceData";

/**
 * @function generateEmailRequestForUserEmailAddressChangedFrom Generates an email request to notify that the current
 *                            user's email address changed to a different email address
 * @param {typeUniqueId} userId The Id of the user who is the target of the email.
 * @param {string} oldEmailAddress The old (prior) email address of the user (and the target of the email message).
 * @param {string} firstName The first name of the target of the email.
 * @param {string} lastName The last name of the target of the email.
 * @param {string} newEmailAddress The new email address of the user.
 * @returns {Promise<IEmailRequest>} A Promise with the generated Email Request, providing for an asynchronous process.
 */
export function generateEmailRequestForUserEmailAddressChangedFrom(userId: typeUniqueId, oldEmailAddress: string, firstName: string, lastName: string, newEmailAddress: string): Promise<IEmailRequest> {
  return new Promise<IEmailRequest>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered generateEmailRequestForUserEmailAddressChangedFrom`, 'background: #550; color: #fff');

    try {
      // get the base domain of the running application
      const baseDomain: string = window.location.hostname;

      // request to generate an emailRequestId
      const emailRequestId: typeUniqueId = await generateIdForEmailRequest(userId, enumEmailRequestType.EmailAddressChangedFrom);

      displayConsoleLogs && console.log(`%c generateEmailRequestForUserEmailAddressChangedFrom. emailRequestId: ${emailRequestId}`, 'background: #055; color: #fff');

      const recipientUserName: string = `${firstName} ${lastName}`;
      const emailRequestSubmittedTimestamp: string = new Date().toUTCString();

      // create User Persistence data
      const userPersistenceData: IUserPersistenceData =
        new UserPersistenceData(userId, recipientUserName, 'web browser', enumLocale.English);

      // compose an EmailRequest object to send to the old email address for the user prior to updating the email address
      const emailRequest: IEmailRequest = new EmailRequest(userId, emailRequestId, undefined, undefined, enumObjectPersistenceState.New,
        enumEmailRequestType.EmailAddressChangedFrom, userId, undefined, undefined, oldEmailAddress, recipientUserName,
        enumAppPlatformType.Web, `https://${baseDomain}`, emailRequestSubmittedTimestamp, undefined, undefined, newEmailAddress, userPersistenceData);

      displayConsoleLogs && console.log(`%c generateEmailRequestForUserEmailAddressChangedFrom. emailRequest: ${JSON.stringify(emailRequest)}`, 'background: #055; color: #fff');

      resolve(emailRequest);
    } catch (error: any) {
      displayConsoleLogs && console.log(`%c generateEmailRequestForUserEmailAddressChangedFrom. Error encountered: ${error}`, 'background: #550; color: #fff');
      reject(error);
    }
  });
}