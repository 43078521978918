import React, { PropsWithChildren } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Divider, Slide } from '@mui/material';
import TwoButtonDialogActionBar from '../../controls/actionBars/DialogActionButtonBars/TwoButtonDialogActionBar';
import OneButtonDialogActionBar from '../../controls/actionBars/DialogActionButtonBars/OneButtonDialogActionBar';

export interface IOneButtonDialogProps extends PropsWithChildren<unknown> {
  showDialog: boolean,
  headerText: string,
  bodyText: string,
  buttonText: string,
  onButtonClick: () => void,
}

const OneButtonDialog: React.FC<IOneButtonDialogProps> = (props) => {

  OneButtonDialog.displayName = 'OneButtonDialog';

  const { showDialog, headerText, bodyText, buttonText, onButtonClick } = props;

  return (
    <>
      <Dialog
        open={showDialog}
        // TransitionComponent={Transition}
        TransitionComponent={Slide}
        TransitionProps={{ enter: true, exit: true}}
        keepMounted
        onClose={onButtonClick}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">{headerText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <Divider />
        <OneButtonDialogActionBar
          buttonLabel={buttonText}
          onButtonClick={onButtonClick}
        />
      </Dialog>
    </>

  );
}

export default OneButtonDialog;
