import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import isEmail from 'validator/lib/isEmail';
import { useForm } from 'react-hook-form';
import { MenuItem, TextField } from '@mui/material';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { enumSharingPermission, enumSharingPermissionConvert } from '../../../dataObjects/enums';
import { IChannelSharingUserEditViewModel } from '../../../dataObjects/viewModels/channelSharing/ChannelSharingUserEditViewModel';
import { styled } from '@mui/system';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

interface IChannelSharingUserFormValues {
  userEmail: string;
  userName: string;
  sharingPermission: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  userEmail: yup
    .string()
    .email(ControlsStringAssets.emailInvalid)
    .required(ControlsStringAssets.channelSharingUserEmailRequired)
    .test("is-valid", (message) => `${message.path} is invalid`, (value) => value ? isEmail(value) : new yup.ValidationError(ControlsStringAssets.channelSharingUserEmailRequired)),
  userName: yup
    .string()
    .required(ControlsStringAssets.channelSharingUserNameRequired),
  sharingPermission: yup
    .string()
    .required(),
});


/**
 * @interface IChannelSharingUserFormProps Properties for the ChannelSharingUserForm component
 */
export interface IChannelSharingUserFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IChannelSharingUserEditViewModel} channelSharingUser The Channel Sharing User details (an IChannelSharingUserEditViewModel object) for the form
   */
  channelSharingUser: IChannelSharingUserEditViewModel,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(channelSharingUser: IChannelSharingUserEditViewModel) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (sharingPermission: enumSharingPermission) => Promise<void>,
}

const ChannelSharingUserForm: React.FC<IChannelSharingUserFormProps> = (props: IChannelSharingUserFormProps) => {
  ChannelSharingUserForm.displayName = 'Channel Sharing User Form';

  // get required arguments from props
  const { channelSharingUser, onSubmit } = props;

  console.log(`ChannelSharingUserForm. After assignment from props. channelSharingUser.email: ${channelSharingUser.email}`);

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit, reset, setError } = useForm<IChannelSharingUserFormValues>({
    defaultValues: {
      userEmail: channelSharingUser.email,
      userName: channelSharingUser.name,
      sharingPermission: channelSharingUser.sharingPermission,
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: userEmailReg, ...userEmailProps } = register("userEmail", { required: true });
  const { ref: userNameReg, ...userNameProps } = register("userName", { required: true });
  const { ref: sharingPermissionReg, ...sharingPermissionProps } = register("sharingPermission", { required: true });


  const [sharingPermissionsForDropdown, setSharingPermissionsForDropdown] = useState<Array<JSX.Element>>([]);

  // const [sharingPermissionsKey, setSharingPermissionsKey] = useState<string>("");
  const [sharingPermissionsKey, setSharingPermissionsKey] = useState<string>(channelSharingUser.sharingPermission);


  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of SharingPermissions values from the enumSharingPermission enumerator that can be used to 
    // populate MenuItem components for the SharingPermissions <Select> component
    let sharingPermissionsMenuItems: Array<JSX.Element> = [];
    KeyValuePairsStringAssets.sharingPermissionKeyValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      // don't add 'None' to the list of options
      if (keyValuePair.key !== enumSharingPermission.None) {
        sharingPermissionsMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
      }
    });

    setSharingPermissionsForDropdown(sharingPermissionsMenuItems);
    setSharingPermissionsKey(channelSharingUser.sharingPermission);
  }, []);

  // for testing whether the form is in a valid state (cast 'isValid' to 'formIsValid')
  const { isValid: formIsValid } = formState;

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: IChannelSharingUserFormValues) => {

    setSaveInProgress(true);

    // call the onSubmit handler passed in, supplying the sharingPermission that has been set for the user
    await onSubmit(enumSharingPermissionConvert.fromString(data.sharingPermission));
  }


  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.channelSharingUser_UpdateRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={userEmailReg}
          {...userEmailProps}
          type="text"
          label={ControlsStringAssets.channelSharingUserEmailLabel}
          disabled={true}
          margin='normal'
          fullWidth
          error={!!errors.userEmail}
          helperText={errors?.userEmail?.message}
          InputLabelProps={{
            required: true  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        <TextField
          inputRef={userNameReg}
          {...userNameProps}
          type="text"
          label={ControlsStringAssets.channelSharingUserNameLabel}
          disabled={true}
          margin='normal'
          fullWidth
          error={!!errors.userName}
          helperText={errors?.userName?.message}
          InputLabelProps={{
            required: true,  // this will cause an asterisk ('*') to appear at the end of the label text
          }}
        />

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* Only display the field if the dropdown options have been created */}
        {(sharingPermissionsForDropdown.length > 0) &&
          <StyledTextFieldForSelectControl
            inputRef={sharingPermissionReg}
            {...sharingPermissionProps}
            autoFocus
            label="Permission"
            margin='normal'
            value={sharingPermissionsKey}
            onChange={e => setSharingPermissionsKey(e.target.value)}
          >
            {sharingPermissionsForDropdown}
          </StyledTextFieldForSelectControl>
        }

      </FormWithActionBar>
    </>

  );
}

export default ChannelSharingUserForm;
