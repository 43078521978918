// import { RandomId } from '../../../utilities/RandomId';
// import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
// import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
// import { VersionAwarePersistable } from "../../persistence/VersionAwarePersistable";
// import { JsonConverter } from "../../../utilities/JsonConverter";
// import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { IThemeBaseColors, IThemeBaseColorsAsJson } from '.';


export class ThemeBaseColors implements IThemeBaseColors {
  /**
   * @method Constructor method
   * @param {string} baseColorForPrimary The base (main) color for the Primary color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForSecondary The base (main) color for the Secondary color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForCancel The base (main) color for the Cancel color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForError The base (main) color for the Error color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForHightlight The base (main) color for the Highlight color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForInfo The base (main) color for the Info color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForSuccess The base (main) color for the Success color of the theme, represented as a Hex string (#RRGGBB)
   * @param {string} baseColorForWarning The base (main) color for the Warning color of the theme, represented as a Hex string (#RRGGBB)
   */
  /**
   * @method Constructor method
   */
  constructor(
    baseColorForPrimary: string,
    baseColorForSecondary: string,
    baseColorForCancel: string,
    baseColorForError: string,
    baseColorForHighlight: string,
    baseColorForInfo: string,
    baseColorForSuccess: string,
    baseColorForWarning: string,
  ) {

    this._baseColorForPrimary = baseColorForPrimary;
    this._baseColorForSecondary = baseColorForSecondary;
    this._baseColorForCancel = baseColorForCancel;
    this._baseColorForError = baseColorForError;
    this._baseColorForHighlight = baseColorForHighlight;
    this._baseColorForInfo = baseColorForInfo;
    this._baseColorForSuccess = baseColorForSuccess;
    this._baseColorForWarning = baseColorForWarning;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForPrimary The base (main) color for the Primary color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForPrimary: string;

  /**
   * @method baseColorForPrimary is an optional getter method for _baseColorForPrimary
   */
  get baseColorForPrimary(): string {
    return this._baseColorForPrimary;
  }

  /**
   * @method baseColorForPrimary is an optional setter method for _baseColorForPrimary
   * @param {string} value The baseColorForPrimary value to be used to set _baseColorForPrimary
   */
  set baseColorForPrimary(value: string) {
    this._baseColorForPrimary = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForSecondary The base (main) color for the Secondary color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForSecondary: string;

  /**
   * @method baseColorForSecondary is an optional getter method for _baseColorForSecondary
   */
  get baseColorForSecondary(): string {
    return this._baseColorForSecondary;
  }

  /**
   * @method baseColorForSecondary is an optional setter method for _baseColorForSecondary
   * @param {string} value The baseColorForSecondary value to be used to set _baseColorForSecondary
   */
  set baseColorForSecondary(value: string) {
    this._baseColorForSecondary = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForCancel The base (main) color for the Cancel color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForCancel: string;

  /**
   * @method baseColorForCancel is an optional getter method for _baseColorForCancel
   */
  get baseColorForCancel(): string {
    return this._baseColorForCancel;
  }

  /**
   * @method baseColorForCancel is an optional setter method for _baseColorForCancel
   * @param {string} value The baseColorForCancel value to be used to set _baseColorForCancel
   */
  set baseColorForCancel(value: string) {
    this._baseColorForCancel = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForError The base (main) color for the Error color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForError: string;

  /**
   * @method baseColorForError is an optional getter method for _baseColorForError
   */
  get baseColorForError(): string {
    return this._baseColorForError;
  }

  /**
   * @method baseColorForError is an optional setter method for _baseColorForError
   * @param {string} value The baseColorForError value to be used to set _baseColorForError
   */
  set baseColorForError(value: string) {
    this._baseColorForError = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForHighlight The base (main) color for the Highlight color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForHighlight: string;

  /**
   * @method baseColorForHighlight is an optional getter method for _baseColorForHighlight
   */
  get baseColorForHighlight(): string {
    return this._baseColorForHighlight;
  }

  /**
   * @method baseColorForHighlight is an optional setter method for _baseColorForHighlight
   * @param {string} value The baseColorForHighlight value to be used to set _baseColorForHighlight
   */
  set baseColorForHighlight(value: string) {
    this._baseColorForHighlight = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForInfo The base (main) color for the Info color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForInfo: string;

  /**
   * @method baseColorForInfo is an optional getter method for _baseColorForInfo
   */
  get baseColorForInfo(): string {
    return this._baseColorForInfo;
  }

  /**
   * @method baseColorForInfo is an optional setter method for _baseColorForInfo
   * @param {string} value The baseColorForInfo value to be used to set _baseColorForInfo
   */
  set baseColorForInfo(value: string) {
    this._baseColorForInfo = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForSuccess The base (main) color for the Success color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForSuccess: string;

  /**
   * @method baseColorForSuccess is an optional getter method for _baseColorForSuccess
   */
  get baseColorForSuccess(): string {
    return this._baseColorForSuccess;
  }

  /**
   * @method baseColorForSuccess is an optional setter method for _baseColorForSuccess
   * @param {string} value The baseColorForSuccess value to be used to set _baseColorForSuccess
   */
  set baseColorForSuccess(value: string) {
    this._baseColorForSuccess = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _baseColorForWarning The base (main) color for the Warning color of the theme, represented as a Hex string (#RRGGBB)
   */
  private _baseColorForWarning: string;

  /**
   * @method baseColorForWarning is an optional getter method for _baseColorForWarning
   */
  get baseColorForWarning(): string {
    return this._baseColorForWarning;
  }

  /**
   * @method baseColorForWarning is an optional setter method for _baseColorForWarning
   * @param {string} value The baseColorForWarning value to be used to set _baseColorForWarning
   */
  set baseColorForWarning(value: string) {
    this._baseColorForWarning = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IThemeBaseColors} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IThemeBaseColors {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IThemeBaseColors = Object.create(ThemeBaseColors.prototype);
    Object.assign(copyOfObject, this);

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IThemeBaseColorsAsJson {
    try {
      const jsonObject: IThemeBaseColorsAsJson = {
        baseColorForPrimary: this.baseColorForPrimary,
        baseColorForSecondary: this.baseColorForSecondary,
        baseColorForCancel: this.baseColorForCancel,
        baseColorForError: this.baseColorForError,
        baseColorForHighlight: this.baseColorForHighlight,
        baseColorForInfo: this.baseColorForInfo,
        baseColorForSuccess: this.baseColorForSuccess,
        baseColorForWarning: this.baseColorForWarning,
      };

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IThemeBaseColorsAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A ImageLink instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IThemeBaseColorsAsJson, includeContainedObjects: boolean = true): IThemeBaseColors {
    try {
      // create a new instance of this class
      let themeBaseColorsObject: IThemeBaseColors = Object.create(ThemeBaseColors.prototype);

      // assign all properties from the JSON object to the data members
      themeBaseColorsObject.baseColorForPrimary = jsonObject.baseColorForPrimary;
      themeBaseColorsObject.baseColorForSecondary = jsonObject.baseColorForSecondary;
      themeBaseColorsObject.baseColorForCancel = jsonObject.baseColorForCancel;
      themeBaseColorsObject.baseColorForError = jsonObject.baseColorForError;
      themeBaseColorsObject.baseColorForHighlight = jsonObject.baseColorForHighlight;
      themeBaseColorsObject.baseColorForInfo = jsonObject.baseColorForInfo;
      themeBaseColorsObject.baseColorForSuccess = jsonObject.baseColorForSuccess;
      themeBaseColorsObject.baseColorForWarning = jsonObject.baseColorForWarning;

      return themeBaseColorsObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
