import { IFirestoreTopicItemRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicItemRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ITopicItem, ITopicItemAsJson } from "../../../../dataObjects/models/topics/TopicItem";


export function deleteTopicItem(topicItem: ITopicItem): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a TopicItem repository from the repository factory
      const firestoreTopicItemRepository: IFirestoreTopicItemRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.TopicItem) as
        IFirestoreBaseRepository<ITopicItem, ITopicItemAsJson>;

      // call the repository delete() method to delete the object
      await firestoreTopicItemRepository.delete(topicItem.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}