import { createSlice } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: enumWorkflowState | null = null;

export const userRegistrationStatusSlice = createSlice({
  name: 'userRegistrationStatus',
  initialState,
  reducers: {
    // action has a payload with the new enumWorkflowState value or null
    userRegistrationStatusChange(state, action) {
      // change status of registration process
      return action.payload;
    },

  },
})

// Action creators are generated for each function in reducer)
export const { userRegistrationStatusChange } = userRegistrationStatusSlice.actions;

// export the core reducer for the slice
export const userRegistrationStatusReducer = userRegistrationStatusSlice.reducer;

export default userRegistrationStatusSlice.reducer;