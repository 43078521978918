import { IFirestoreDocumentLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDocumentLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IDocumentLink, IDocumentLinkAsJson } from "../../../../dataObjects/models/digitalMedia/DocumentLink";


export function deleteDocumentLink(documentLink: IDocumentLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a DocumentLink repository from the repository factory
      const firestoreDocumentLinkRepository: IFirestoreDocumentLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.DocumentLink) as
        IFirestoreBaseRepository<IDocumentLink, IDocumentLinkAsJson>;

      // call the repository delete() method to delete the object
      await firestoreDocumentLinkRepository.delete(documentLink.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}