import { IFirestoreSocialMediaPostRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreSocialMediaPostRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ISocialMediaPost, ISocialMediaPostAsJson } from "../../../../dataObjects/models/digitalMedia/SocialMediaPost";


export function saveSocialMediaPost(socialMediaPost: ISocialMediaPost): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a SocialMediaPost repository from the repository factory
      const firestoreSocialMediaPostRepository: IFirestoreSocialMediaPostRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.SocialMediaPost) as
        IFirestoreBaseRepository<ISocialMediaPost, ISocialMediaPostAsJson>;

      // determine if the socialMediaPost already exists by calling the repository get() method to try and get the object
      const existingSocialMediaPost: ISocialMediaPost | undefined = await firestoreSocialMediaPostRepository.get(socialMediaPost.id);

      // If the socialMediaPost wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingSocialMediaPost === undefined) {
        // call repository method to create the socialMediaPost
        await firestoreSocialMediaPostRepository.create(socialMediaPost);
      } else {
        // call repository method to update the socialMediaPost
        await firestoreSocialMediaPostRepository.update(socialMediaPost);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}