import { typeUniqueId } from "../../../../dataObjects/types"
import { Channel, IChannel, IChannelAsJson } from "../../../../dataObjects/models/channels/Channel";
import { IFirestoreChannelRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";


export function getChannelObjectUsingId(channelId: typeUniqueId): Promise<IChannel | undefined> {
  return new Promise<IChannel | undefined>(async (resolve, reject) => {
    try {
      // get a Channel repository from the repository factory
      const firestoreChannelRepository: IFirestoreChannelRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
        IFirestoreBaseRepository<IChannel, IChannelAsJson>;

      // make request of the repository to get the Channel object associated with the Id
      let channel: IChannel | undefined = await firestoreChannelRepository.get(channelId);

      // if a channel object wasn't found, see if the channelId is for a private channel
      if (channel === undefined) {
        const privateChannelId: string = Channel.userPrivateChannelIdFromNonPrivateId(channelId);
        channel = await firestoreChannelRepository.get(privateChannelId);
      }

      resolve(channel);
    } catch (error: any) {
      reject(error);
    }
  });
}