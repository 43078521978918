import React, { PropsWithChildren } from 'react';
import { Box, FormControlLabel, Grid, Switch, Tooltip } from '@mui/material';
import { PageBackButton } from '../../controls/buttons/PageBackButton/PageBackButton';
import { PageTitle } from '../../controls/PageTitle/PageTitle';
import { EditCategoriesButton } from '../../controls/buttons/EditCategoriesButton/EditCategoriesButton';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { PageSubtitle } from '../../controls/PageTitle/PageSubtitle';
import { styled } from '@mui/system';
import { ObjectSpecificPageHeader } from '../../controls/PageTitle/ObjectSpecificPageHeader';
import { TrainingVideosButton } from '../../controls/buttons/TrainingVideosButton/TrainingVideosButton';
import { TooltipStringAssets } from '../../../assets/stringAssets';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { setDisplayCategorized } from '../../../uiMiddleware-redux/slices/displayCategorized/displayCategorizedSlice';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Grid for the overall container of the Page Header
const StyledGridForPageHeaderContainer = styled((props) => (
  <Grid
    container
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(1),
}));

// a styled Box for the Page Back Button Area
const StyledBoxForPageBackButtonArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginLeft: theme.spacing(2),

  [theme.breakpoints.up('xs')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.2),
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.4),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.6),
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.8),
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
}));

// a styled Box for the Training Videos Button Area
const StyledBoxForTrainingVideosButtonArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginLeft: theme.spacing(2),

  [theme.breakpoints.up('xs')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.2),
  },
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.4),
  },
  [theme.breakpoints.up('md')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.6),
  },
  [theme.breakpoints.up('lg')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0.8),
  },
  [theme.breakpoints.up('xl')]: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1),
  },
}));

// a styled Grid Item for the Page Header
const StyledGridItemForObjectSpecificPageHeaderContainer = styled((props) => (
  <Grid
    item
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

// a styled Grid Item for the Object Specific Header
const StyledGridItemForObjectSpecificHeaderContainer = styled((props) => (
  <Grid
    item
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

// a styled Grid Item for the Page Header
const StyledGridItemForPageTitleContainer = styled((props) => (
  <Grid
    item
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

// a styled Grid Item for the Page Subtitle
const StyledGridItemForPageSubtitleContainer = styled((props) => (
  <Grid
    item
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

// a styled Grid Item for the CategorizedSwitchArea
const StyledGridItemForCategorizedSwitchArea = styled((props) => (
  <Grid
    item
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}));

// a styled FormControlLabel for the Categorized Switch
const StyledFormControlLabelForCategorizedSwitchLabel = styled((props) => (
  <FormControlLabel
    {...props}
  />
))(({ theme }) => ({
  color: theme.page.titleFontColor,
}));


/**
 * @interface IPageHeaderProps declares any input properties required for this component.
 */
export interface IPageHeaderProps extends PropsWithChildren<unknown> {
  /**
   * @property {boolean} showBackButton Whether to show a "Back" button on the page
   */
  showBackButton: boolean;
  /**
   * @property {string} objectSpecificPageHeaderCategory If provided, represents an object-specific page header category to be 
   *                                                     displayed above the page title.
   * @example "Channel" in "Channel: Private Channel"
   */
  objectSpecificPageHeaderCategory?: string;
  /**
   * @property {string} objectSpecificPageHeaderText If provided, represents text for an object-specific page header to be 
   *                                                 displayed above the page title.
   * @example "Private Channel" in "Channel: Private Channel"
   */
  objectSpecificPageHeaderText?: string;
  /**
   * @property {string} pageTitle Title to be displayed for the page
   */
  pageTitle: string;
  /**
   * @property {string} pageSubtitle (optional) Subtitle to be displayed for the page
   */
  pageSubtitle?: string;
  /**
   * @property {boolean} showCategoriesOption (optional) Whether to show the Categories area in the PageHeader
   */
  showCategoriesOption?: boolean;
  /**
   * @property {() => void} onClickEditCategories (optional) Callback function to call when the Edit Categories button is clicked
   */
  onClickEditCategories?: () => void;
  /**
   * @property {boolean} showTrainingVideosButton (optional) Whether to show a "Training Videos" button on the page
   */
  showTrainingVideosButton?: boolean;
  /**
   * @property {string} trainingVideosUrl (optional) The navigation Url to use if "Training Videos" button is clicked
   */
  trainingVideosUrl?: string;

}

/**
 * @function PageHeader is a React functional component representing a generic header for a page.
 * @param {IPageHeaderProps} props are the properties passed into the component.
 */
export const PageHeader: React.FC<IPageHeaderProps> = (props: IPageHeaderProps) => {
  PageHeader.displayName = 'Page Header';

  const dispatch = useAppDispatch();

  const {
    showBackButton,
    objectSpecificPageHeaderCategory,
    objectSpecificPageHeaderText,
    pageTitle,
    pageSubtitle,
    showCategoriesOption,
    onClickEditCategories,
    showTrainingVideosButton,
    trainingVideosUrl
  } = props;

  const displayCategorized: boolean = useAppSelector((state: IStoreState) => state.displayCategorized) ?? false;

  // const [displayWithCategories, setDisplayWithCategories] = useState<boolean>(false);


  function handleChangeCategoriesSwitch() {
    dispatch(setDisplayCategorized(!displayCategorized));
  }


  return (
    <>
      <StyledGridForPageHeaderContainer>
        {/* The Page Back Button area */}
        <Grid item key={'backButton'} xs={2} sm={1}>
          <StyledBoxForPageBackButtonArea>
            {showBackButton && <PageBackButton />}
          </StyledBoxForPageBackButtonArea>
        </Grid>
        {/* The Page Title area */}
        <Grid item container key={'pageTitleAndCategorizedSwitch'} xs={8} sm={10} direction='column' >
          {objectSpecificPageHeaderCategory && objectSpecificPageHeaderText &&
            <StyledGridItemForObjectSpecificHeaderContainer>
              <ObjectSpecificPageHeader
                objectSpecificPageHeaderCategory={objectSpecificPageHeaderCategory}
                objectSpecificPageHeaderText={objectSpecificPageHeaderText}
              />
            </StyledGridItemForObjectSpecificHeaderContainer>
          }
          {/* Page Title */}
          <StyledGridItemForPageTitleContainer key={'pageTitle'} >
            <PageTitle
              title={pageTitle}
            />
          </StyledGridItemForPageTitleContainer>
          {/* Page Subtitle */}
          {pageSubtitle &&
            <StyledGridItemForPageSubtitleContainer key={'pageSubtitle'} >
              <PageSubtitle
                subTitle={pageSubtitle}
              />
            </StyledGridItemForPageSubtitleContainer>
          }
          {/* Categorized Switch area */}
          {showCategoriesOption && onClickEditCategories &&
            <StyledGridItemForCategorizedSwitchArea key={'categorizedSwitchArea'} >
              <StyledFormControlLabelForCategorizedSwitchLabel
                control={
                  <Tooltip title={displayCategorized ? 'Switch off to display without categories' : 'Switch on to display with categories'} arrow >
                    <Switch
                      name="categorized"
                      checked={displayCategorized}
                      onChange={handleChangeCategoriesSwitch}
                    />
                  </Tooltip>
                }
                label="Categorized"
              />
              {/* if the state indicates to display categorized, show the Edit Catagories Floating Action Button; otherwise, hide it */}
              <Tooltip title={'Edit categories'} arrow >
                <span style={displayCategorized ? { visibility: 'visible' } : { visibility: 'hidden' }}>
                  <EditCategoriesButton onClick={onClickEditCategories} />
                </span>
              </Tooltip>
            </StyledGridItemForCategorizedSwitchArea>
          }
        </Grid>
        {/* A blank area to balance/center the Page Title area */}
        <Grid item key={'balancingArea'} xs={2} sm={1}>
          <Tooltip
            title={TooltipStringAssets.viewTrainingVideos}
            arrow
            placement="bottom"
          >
            <span>
              <StyledBoxForTrainingVideosButtonArea>
                {showTrainingVideosButton && <TrainingVideosButton navigationUrl={trainingVideosUrl ?? ''} />}
              </StyledBoxForTrainingVideosButtonArea>
            </span>
          </Tooltip>
        </Grid>
      </StyledGridForPageHeaderContainer>
    </>
  )
}