import { put } from "redux-saga/effects";
import { saveAudioLink } from "../../../firebaseServices/dataServices/dataServiceActions/audioLinkActions";
import { IAudioLink } from '../../../dataObjects/models/digitalMedia/AudioLink';
import { ISaveAudioLinkRequest } from '../../../dataObjects/models/serviceRequests/audioLink/SaveAudioLinkRequest';
import { enumWorkflowState } from "../../../dataObjects/enums";
import { ISaveAudioLinkWorkflowState } from "../../store/SaveAudioWorkflowState";
import { MessagesStringAssets } from "../../../assets/stringAssets";
import { audioLinkSaveError, audioLinkSaveSuccess, audioLinkSaveWorkflowStateChange } from "../../slices/audioLink/audioLinkSaveStatusSlice";

/**
 * @function saveAudioLinkOnlyWorkerSaga A "worker" saga function that performs the work for a request to
 *   save an AudioLink only (without a file upload).
 * @param action 
 */
export function* saveAudioLinkOnlyWorkerSaga(saveAudioLinkRequest: ISaveAudioLinkRequest) {
  try {
    // if the ISaveAudioLinkRequest is defined...
    if (saveAudioLinkRequest !== undefined) {

      // update store state...      
      const saveAudioLinkWorkflowState: ISaveAudioLinkWorkflowState = {
        audioLink: saveAudioLinkRequest.audioLink,
        workflowState: enumWorkflowState.InProgress,
        workflowStateMessage: MessagesStringAssets.audioLink_SaveRequested,
        fileUploadRequest: undefined
      }
      yield put(audioLinkSaveWorkflowStateChange(saveAudioLinkWorkflowState));

      // get the AudioLink from ISaveAudioLinkRequest
      const audioLink: IAudioLink | undefined = saveAudioLinkRequest.audioLink;

      // if the AudioLink is defined...
      if (audioLink !== undefined) {
        // call services method to save the audioLink
        yield saveAudioLink(audioLink)

        // dispatch an action to indicate that the save operation was successful
        yield put(audioLinkSaveSuccess(audioLink));

        // update the info for store state and dispatch an action to indicate that the workflow was successful
        // update store state...      
        const saveAudioLinkWorkflowState: ISaveAudioLinkWorkflowState = {
          audioLink: saveAudioLinkRequest.audioLink,
          workflowState: enumWorkflowState.Success,
          workflowStateMessage: MessagesStringAssets.audioLink_SaveSuccess,
        }

        saveAudioLinkWorkflowState.workflowState = enumWorkflowState.Success;
        saveAudioLinkWorkflowState.workflowStateMessage = MessagesStringAssets.audioLink_SaveSuccess;
        yield put(audioLinkSaveWorkflowStateChange(saveAudioLinkWorkflowState));
      }
    }
  } catch (error: any) {
    // dispatch an action to indicate that the save operation failed
    yield put(audioLinkSaveError(error));
  }
}