import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface IDigitalMediaParms {
  className?: string;
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  boundingRect: {
    fill: 'none',
  },

  outerSoundWave: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeWidth: '8px',
  },

  middleSoundWave: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeWidth: '6px',
    strokeMiterlimit: 10,
  },

  innerSoundWave: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  },

  speakerBase: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  },

  speakerCone: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  },

  hyperlinkOutline: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '12px',
  },

  bottomLink: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

  topLink: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

  imageOutline: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '12px',
  },

  mountains: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  },

  celestialObject: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  },

  noteOutline: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '12px',
  },

  pageCornerFold: {
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeWidth: '8px',
  },

  pageCornerFoldLine: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeWidth: '3.76px',
    strokeMiterlimit: 10,
  },

  simulatedTextLine: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '4px',
  },

  videoOutline: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeMiterlimit: 10,
    strokeWidth: '12px',
  },

  videoPlayArrow: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

  videoPlayPositionCircle: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

  videoPlayTrackLine: {
    fill: 'none',
    stroke: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
    strokeWidth: '8px',
    strokeMiterlimit: 10,
  },

}));


// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const DigitalMedia = React.forwardRef((props: IDigitalMediaParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 627 513.01" >
      <defs>
      </defs>

      <g id="groupVideo">
        <rect id="videoOutline" className={classes.videoOutline} x="162" y="6" width="216" height="153" rx="10.03" />
        <g id="playArrow">
          <path className={classes.videoPlayArrow} d="M430,98.42,365.11,66.51a2.19,2.19,0,0,0-3.16,2V132.3a2.19,2.19,0,0,0,3.16,2L430,102.36A2.2,2.2,0,0,0,430,98.42Z" transform="translate(-126 -21)" />
        </g>
        <line id="playTrackLine" className={classes.videoPlayTrackLine} x1="189" y1="132" x2="351" y2="132" />
        <circle id="playPositionCircle" className={classes.videoPlayPositionCircle} cx="189" cy="132" r="9" />
      </g>
      <g id="groupAudio">
        <polygon id="speakerCone" className={classes.speakerCone} points="448.09 348 359.91 384.91 359.91 473.09 448.09 510 448.09 348" />
        <rect id="speakerBase" className={classes.speakerBase} x="306" y="384" width="54" height="90" />
        <path id="innerSoundWave" className={classes.innerSoundWave} d="M588,486a50.88,50.88,0,0,0,0-72" transform="translate(-126 -21)" />
        <path id="middleSoundWave" className={classes.middleSoundWave} d="M603,495a63.61,63.61,0,0,0,0-90" transform="translate(-126 -21)" />
        <path id="outerSoundWave" className={classes.outerSoundWave} d="M614.54,508.46C646.85,476.15,647,424,614.9,391.9" transform="translate(-126 -21)" />
      </g>
      <g id="groupImage">
        <path id="imageOutline" className={classes.imageOutline} d="M737,333H541a10,10,0,0,1-10-10V190a10,10,0,0,1,10-10H737a10,10,0,0,1,10,10V323A10,10,0,0,1,737,333Z" transform="translate(-126 -21)" />
        <polygon id="mountains" className={classes.mountains} points="450 213 531 276 576 231 621 276 621 302 611 312 415 312 405 302 405 276 450 213" />
        <ellipse id="celestialObject" className={classes.celestialObject} cx="546.25" cy="201.36" rx="15.63" ry="15.68" />
      </g>
      <g id="groupNote">
        <path id="noteOutline" className={classes.noteOutline} d="M350.2,521.93H198.3a9.43,9.43,0,0,1-9.42-9.43V360.22a9.43,9.43,0,0,1,9.42-9.43H324L359.63,387V512.5A9.43,9.43,0,0,1,350.2,521.93Z" transform="translate(-126 -21)" />
        <line id="pageCornerFoldLine" className={classes.pageCornerFoldLine} x1="198" y1="330" x2="234" y2="366" />
        <path id="pageCornerFold" className={classes.pageCornerFold} d="M322.11,391.93l32.54-.8a3.88,3.88,0,0,0,2.65-6.63l-31.73-31.74a3.89,3.89,0,0,0-6.63,2.65L318.13,388A3.89,3.89,0,0,0,322.11,391.93Z" transform="translate(-126 -21)" />
        <line id="simulatedTextLine1" className={classes.simulatedTextLine} x1="90.69" y1="384.26" x2="207.44" y2="384.4" />
        <line id="simulatedTextLine2" className={classes.simulatedTextLine} x1="90.06" y1="402.33" x2="206.81" y2="402.47" />
        <line id="simulatedTextLine3" className={classes.simulatedTextLine} x1="89.69" y1="420.26" x2="206.44" y2="420.4" />
        <line id="simulatedTextLine4" className={classes.simulatedTextLine} x1="90.06" y1="438.33" x2="206.81" y2="438.47" />
        <line id="simulatedTextLine5" className={classes.simulatedTextLine} x1="89.69" y1="456.26" x2="206.44" y2="456.4" />
        <line id="simulatedTextLine6" className={classes.simulatedTextLine} x1="90.06" y1="474.33" x2="206.81" y2="474.47" />
      </g>
      <g id="groupHyperlinkChain">
        <path id="topLink" className={classes.topLink} d="M177.89,245.44a2.13,2.13,0,0,1,.11-.65c.57-1.07,1.09-2.16,1.75-3.17a34.13,34.13,0,0,1,6.2-7.27c1.92-1.67,3.58-3.64,5.38-5.45,3.11-3.12,6.26-6.2,9.37-9.31,2.2-2.21,4.3-4.53,6.58-6.65,2.91-2.71,5.42-5.82,8.65-8.21a34.25,34.25,0,0,1,12.86-5.93,34.9,34.9,0,0,1,12.72-.41,32,32,0,0,1,11.2,3.88,32.82,32.82,0,0,1,11.85,11.11A31.49,31.49,0,0,1,269.43,226a36.21,36.21,0,0,1,0,11.24,31.61,31.61,0,0,1-8.22,17c-4.07,4.26-8.28,8.4-12.45,12.58-5.42,5.44-10.88,10.84-16.27,16.3a33.51,33.51,0,0,1-8.5,6.36,32.69,32.69,0,0,1-16,3.83,33.77,33.77,0,0,1-14.8-3.44,31,31,0,0,1-10.74-8.55,10.14,10.14,0,0,0-.76-.71,6.36,6.36,0,0,1-1.35-1.61,22.92,22.92,0,0,0-3.06-5.3,4.63,4.63,0,0,1-.53-1.15c-.79-2-.72-2.19,1.14-3.4a1.69,1.69,0,0,0,.59-.57,4.25,4.25,0,0,1,3.13-2c1.32-.25,2.6-.65,3.92-.87,1.12-.19,2.26-.23,3.37-.42a5.92,5.92,0,0,1,2.88.55,2.06,2.06,0,0,1,1.37,1.25,14.52,14.52,0,0,0,2.52,3.67,20.81,20.81,0,0,0,1.66,1.95,7.47,7.47,0,0,0,2,1.42c1.35.58,2.86.82,4,1.89a1.07,1.07,0,0,0,.58.23c.91.14,1.82.23,2.73.37,2.53.37,4.94-.29,7.35-.86a3.84,3.84,0,0,0,1.65-.9,16.93,16.93,0,0,1,2.85-1.84,13.29,13.29,0,0,0,2.63-2.15c1.61-1.5,2.85-3.32,4.41-4.86a11.18,11.18,0,0,1,2.12-2.07c1.55-.84,2.51-2.24,3.74-3.37s2.33-2.29,3.47-3.46,2.36-2.46,3.56-3.67c3.35-3.38,6.77-6.7,10.07-10.13,4.52-4.72,5.71-10.29,3.95-16.54a16.33,16.33,0,0,0-4.15-7.1,17.17,17.17,0,0,0-8.05-4.58c-5.49-1.43-10.37-.13-14.78,3.33a76.47,76.47,0,0,0-6.25,6.09c-3.85,3.78-7.66,7.6-11.49,11.41-2.92,2.9-5.88,5.78-8.77,8.71a2.23,2.23,0,0,1-2.6.76,31.84,31.84,0,0,0-12.66-1,49.46,49.46,0,0,0-5.22,1A3.42,3.42,0,0,1,177.89,245.44Z" transform="translate(-126 -21)" />
        <path id="bottomLink" className={classes.bottomLink} d="M197.81,294.21a37.28,37.28,0,0,0,20,.31,2.87,2.87,0,0,1-.56,2,35.7,35.7,0,0,1-5.87,7.8q-7.5,7.44-15,14.9-6.27,6.27-12.49,12.55A33.35,33.35,0,0,1,158,341.92a37.25,37.25,0,0,1-9.39-1.74,32.18,32.18,0,0,1-8.84-4.63,33.34,33.34,0,0,1-12.68-18.42,34,34,0,0,1-1.09-9.27,33.25,33.25,0,0,1,3.81-15.35,27.81,27.81,0,0,1,4.77-6.77c1.43-1.47,2.88-2.94,4.36-4.37a37.08,37.08,0,0,0,3.39-3.8,9.15,9.15,0,0,1,2.8-2.52,6.64,6.64,0,0,0,2-1.93,19.07,19.07,0,0,1,3.88-3.89,23.47,23.47,0,0,0,2.59-2.5q4.88-4.92,9.72-9.86a33.85,33.85,0,0,1,15-9,35.63,35.63,0,0,1,12-1.14A32.91,32.91,0,0,1,204,250.84a30.53,30.53,0,0,1,8.17,6.42,37.86,37.86,0,0,1,4.84,6.72c.83,1.44,1.78,2.85,2.63,4.25a4.92,4.92,0,0,1-2.18,2.85c-.75.42-.95,1.36-1.86,1.66s-1.57,1.11-2.43,1.27a31.5,31.5,0,0,1-4.67.34c-2.82.08-2.82.06-5.49-1.07a54.46,54.46,0,0,0-3.36-5,13.76,13.76,0,0,0-3.32-2.63,16.53,16.53,0,0,0-14.74-1.37,15.53,15.53,0,0,0-5.59,3.8,6.59,6.59,0,0,1-2.11,1.74,2.45,2.45,0,0,0-.79.69c-.93,1-1.83,2-2.79,2.92a16.12,16.12,0,0,0-2.83,3.16,5.47,5.47,0,0,1-2,1.91,12.44,12.44,0,0,0-2.67,2.38c-2.8,2.73-5.5,5.56-8.26,8.32-2.3,2.32-4.6,4.63-7,6.91a16.46,16.46,0,0,0-4.8,8.85,17.09,17.09,0,0,0,10.29,19.06c5.31,2.21,10.43,1.59,15.34-1.16a15.92,15.92,0,0,0,3.32-2.65c3.89-3.85,7.7-7.77,11.57-11.64,2.91-2.92,5.87-5.79,8.78-8.69C194,298.07,195.86,296.18,197.81,294.21Z" transform="translate(-126 -21)" />
      </g>

    </svg >
  )
});

export default DigitalMedia;
