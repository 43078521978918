
/**
 * @enum {enumObjectPersistenceState} Represents the different possible persistence states of a 
 * Version-Aware Persistable (VAP) type of object
 */
export enum enumObjectPersistenceState {
  Deleted = 'Deleted', // represents a "soft delete"
  Expunged = 'Expunged', // represents a physical delete/expunge
  Modified = 'Modified',
  New = 'New',
  NotSet = 'NotSet',  // default - means that state hasn't been set
  Unchanged = 'Unchanged'
}
