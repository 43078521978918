import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, MenuItem, TextField, Tooltip } from '@mui/material';
import { ColorLens as ThemesIcon } from '@mui/icons-material';
import { ControlsStringAssets, KeyValuePairsStringAssets, MessagesStringAssets, TooltipStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { styled } from '@mui/system';
import { IAppThemesContextData, useAppThemesContext } from '../../providersAndContexts/themes';
import { ICategorizedThemeSpecsViewModel, IThemeSpecsViewModel } from '../../../dataObjects/viewModels/themeSpecsViewModel';
import { useNavigate } from 'react-router-dom';
import { enumThemeType } from '../../../dataObjects/enums';
import { ColorSwatch } from '../../controls/colors/ColorSwatch/ColorSwatch';
import { IThemeColorSpectrum } from '../../../dataObjects/models/themes';
import { generateThemeColorSpectrumFromSingleColor } from '../../../dataObjects/utilities/colors';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/


// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

// a styled Box (equivalent to a <div>), providing an area to display the "Manage Custom Themes" button
const StyledBoxForManageCustomThemesButtonContainer = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  marginTop: theme.spacing(0.5),
}));

// a styled Button for the "Manage Custom Themes" button
const StyledButtonForManageCustomThemesButton = styled((props) => (
  <Button
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

// a styled Box (equivalent to a <div>), providing a fieldset control to surround details for color selections
const StyledBoxForFieldSet = styled((props) => (
  <Box
    component='fieldset'
    {...props}
  />
))(({ theme }) => ({
  border: theme.fieldSet.border,
  borderRadius: theme.fieldSet.borderRadius,
  marginTop: '0.3rem',
}));

// a styled Box (equivalent to a <div>), providing a legend control to associate with the fieldset
const StyledBoxForLegend = styled((props) => (
  <Box
    component='legend'
    {...props}
  />
))(({ theme }) => ({
  color: theme.fieldSet.legendFontColor,
  fontSize: theme.fieldSet.legendFontSize,
}));

// a styled Box (equivalent to a <div>), providing an area for color selection details
const StyledBoxForColorSelectorsArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  padding: '4px 0px',
  flexWrap: 'wrap',
  // justifyContent: "space-between",
  justifyContent: 'start',
}));

// a styled Box (equivalent to a <div>), providing an area for displaying a color swatch with a heading
const StyledBoxForColorSwatchWithHeading = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  // Note: The flexGrow, flexShrink, and flexBasis are all needed to have columns occupy equal horizontal
  //       widths. 'flex: 1 1 0px' is a shorthand for the same representation.
  // flexGrow: 1,
  // flexShrink: 1,
  // flexBasis: '0px',
  flex: '1 1 0px', // to facilitate causing the individual color picker (with swatch) areas to be the same width
  minWidth: '7rem', // to facilitate causing the individual color picker (with swatch) areas to be the same width
  maxWidth: '7rem', // to facilitate causing the individual color picker (with swatch) areas to be the same width

  margin: '5px',
  display: "flex",
  flexDirection: "column",
}));

const COLOR_SWATCH_HEIGHT: string = '2rem';

interface ISetThemeFormValues {
  themeTypeId: string;
  themeCategoryId: string;
  themeId: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  themeTypeId: yup
    .string()
    .required(),
  themeCategoryId: yup
    .string()
    .required(),
  themeId: yup
    .string()
    .required(),
});


/**
 * @interface ISetThemeFormProps Properties for the SetThemeForm component
 */
export interface ISetThemeFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IUserSettings} userSettings The UserSettings details for the form 
   */
  userSettings: IUserSettings,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(themeCategoryId: string, themeId: string) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (themeCategoryId: string, themeId: string) => Promise<void>,
}

const SetThemeForm: React.FC<ISetThemeFormProps> = (props: ISetThemeFormProps) => {
  SetThemeForm.displayName = 'Set Theme Form';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  // get required arguments from props
  const { userSettings, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm<ISetThemeFormValues>({
    defaultValues: {
      themeTypeId: userSettings.themeCategoryId === enumThemeType.Custom ? enumThemeType.Custom.toString() : enumThemeType.BuiltIn.toString(),
      themeCategoryId: userSettings.themeCategoryId,
      // themeId: userSettings.themeId,
    },
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: themeTypeReg } = register("themeTypeId", { required: true });
  const { ref: themeCategoryIdReg } = register("themeCategoryId", { required: true });
  const { ref: themeReg } = register("themeId", { required: true });

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  // currently selected themeType (themeTypeKey is the selected item's value)
  const [themeTypeKey, setThemeTypeKey] = useState<string>("");

  const [themeTypesForDropdown, setThemeTypesForDropdown] = useState<Array<JSX.Element>>([]);

  // currently selected themeCategory (themeCategoryKey is the selected item's value)
  const [themeCategoryKey, setThemeCategoryKey] = useState<string>("");

  const [themeCategoriesForDropdown, setThemeCategoriesForDropdown] = useState<Array<JSX.Element>>([]);

  // currently selected theme (themeKey is the selected item's value)
  const [themeKey, setThemeKey] = useState<string>("");

  const [themesForDropdown, setThemesForDropdown] = useState<Array<JSX.Element>>([]);

  displayConsoleLogs && console.log(`In SetThemeForm. userSettings.themeId: ${userSettings.themeId}; themeKey: ${themeKey}`);

  displayConsoleLogs && console.log(`In SetThemeForm. themeTypeKey: ${themeTypeKey}`);

  // The current color for each of the theme's basic colors
  const [currentThemeSpecs, setCurrentThemeSpecs] = useState<IThemeSpecsViewModel | undefined>(undefined);

  // // The current color for each of the theme's basic colors
  // const [currentPrimaryColor, setCurrentPrimaryColor] = useState<string>('#000000');
  // const [currentSecondaryColor, setCurrentSecondaryColor] = useState<string>('#000000');
  // const [currentCancelColor, setCurrentCancelColor] = useState<string>('#000000');
  // const [currentErrorColor, setCurrentErrorColor] = useState<string>('#000000');
  // const [currentHighlightColor, setCurrentHighlightColor] = useState<string>('#000000');
  // const [currentInfoColor, setCurrentInfoColor] = useState<string>('#000000');
  // const [currentSuccessColor, setCurrentSuccessColor] = useState<string>('#000000');
  // const [currentWarningColor, setCurrentWarningColor] = useState<string>('#000000');

  // // The current color spectrum to be displayed
  // const [currentColorSpectrum, setCurrentColorSpectrum] = useState<IThemeColorSpectrum | undefined>(undefined);

  // The spectrums of colors for each basic color
  const [colorSpectrumForPrimaryColor, setColorSpectrumForPrimaryColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForSecondaryColor, setColorSpectrumForSecondaryColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForCancelColor, setColorSpectrumForCancelColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForErrorColor, setColorSpectrumForErrorColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForHighlightColor, setColorSpectrumForHighlightColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForInfoColor, setColorSpectrumForInfoColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForSuccessColor, setColorSpectrumForSuccessColor] = useState<IThemeColorSpectrum | undefined>(undefined);
  const [colorSpectrumForWarningColor, setColorSpectrumForWarningColor] = useState<IThemeColorSpectrum | undefined>(undefined);

  // determine whether the form is valid for submission, requiring yup validation -AND- themes are listed in dropdown -AND-
  // one of the themes has been selected
  const formIsValid: boolean = isValid && (themesForDropdown.length > 0) && (themeKey.length > 0);

  // useEffect to be executed upon mounting of this component
  useEffect(() => {
    // prepare an array of ThemeType values from the enumThemeType enumerator that can be used to populate MenuItem components for the ThemeType <Select> component
    let themeTypeMenuItems: Array<JSX.Element> = [];
    KeyValuePairsStringAssets.themeTypeValuePairs.forEach((keyValuePair: { key: string, value: string }) => {
      themeTypeMenuItems.push(<MenuItem key={keyValuePair.key} value={keyValuePair.key}>{keyValuePair.value}</MenuItem>);
    });

    setThemeTypesForDropdown(themeTypeMenuItems);
  }, []);

  // execute whenever userSettings.themeCategory changes
  useEffect(() => {
    // set the themeTypeKey from the userSettings.themeCategory value
    const defaultThemeTypeKey: string = (userSettings.themeCategoryId === enumThemeType.Custom) ? enumThemeType.Custom : enumThemeType.BuiltIn;
    setThemeTypeKey(defaultThemeTypeKey);

  }, [userSettings.themeCategoryId]);

  // execute whenever themeTypeKey (the current theme type) changes
  useEffect(() => {
    // prepare an array of MenuItem objects that will display the available theme categories
    let themeCategoryMenuItems: Array<JSX.Element> = [];

    // if the selected theme type is 'Custom' (user-defined)...
    if (themeTypeKey === enumThemeType.Custom.toString()) {
      themeCategoryMenuItems.push(<MenuItem key={enumThemeType.Custom.toString()} value={enumThemeType.Custom.toString()}>{ControlsStringAssets.themeControls_CustomThemes}</MenuItem>);

      setThemeCategoryKey(enumThemeType.Custom.toString());
    } else {
      // The Built-in theme type is selected, so create menu items for each Category in the collection of built-in categories & themes
      // if the Built-in Themes array is not undefined, create a MenuItem for each one and add to the collection of MenuItems
      if (appThemesContextData.state.builtInCategorizedThemeSpecsViewModels !== undefined) {
        appThemesContextData.state.builtInCategorizedThemeSpecsViewModels.forEach((categorizedThemeSpecsViewModel: ICategorizedThemeSpecsViewModel) => {
          themeCategoryMenuItems.push(<MenuItem key={categorizedThemeSpecsViewModel.id} value={categorizedThemeSpecsViewModel.id}>{categorizedThemeSpecsViewModel.name}</MenuItem>);
        });

        // clear the collection of themes to display in dropdown
        setThemesForDropdown([]);
      }
    }

    // set the themeCategoriesForDropdown from the menu items created
    setThemeCategoriesForDropdown(themeCategoryMenuItems);

    // if the current themeCategoryKey is not set to the value of the userSettings.themeCategoryId...
    if (themeCategoryKey !== userSettings.themeCategoryId) {
      // ensure that the themeKey is set to an empty string (''), to have not selected value for the theme
      if (themeKey !== '') {
        setThemeKey('');
      }
    }

  }, [themeTypeKey]);

  // execute whenever themeCategoryKey (the currently selected category of themes) changes
  useEffect(() => {
    // if the current themeCategoryKey is not set to the value of the userSettings.themeCategoryId...
    if (themeCategoryKey !== userSettings.themeCategoryId) {
      // ensure that the themeKey is set to an empty string (''), to have no selected value for the theme
      if (themeKey !== '') {
        setThemeKey('');
      }
    }

    // prepare an array of MenuItem objects that will display the available themes
    let themeMenuItems: Array<JSX.Element> = [];

    let defaultThemeKey: string | undefined = undefined;

    // ensure that theme preparation has been completed
    if (appThemesContextData.state.themePreparationComplete) {
      // if the selected theme category is 'Custom' (user-defined)...
      if (themeTypeKey === enumThemeType.Custom.toString()) {

        // if the User-Defined Themes array is not undefined, create a MenuItem for each one and add to the collection of MenuItems
        // Note: We're going to show all User-Defined themes at the top of the list
        if (appThemesContextData.state.userDefinedThemeSpecsViewModels !== undefined) {
          appThemesContextData.state.userDefinedThemeSpecsViewModels.forEach((themeSpecsViewModel: IThemeSpecsViewModel) => {
            themeMenuItems.push(<MenuItem key={themeSpecsViewModel.id} value={themeSpecsViewModel.id}>{themeSpecsViewModel.name}</MenuItem>);
          });

          // if there's just a single theme available, set it's key to be the first theme in the array
          if (appThemesContextData.state.userDefinedThemeSpecsViewModels.length === 1) {
            defaultThemeKey = appThemesContextData.state.userDefinedThemeSpecsViewModels[0].id;
          }
        }
      } else {
        if (appThemesContextData.state.builtInCategorizedThemeSpecsViewModels !== undefined) {

          // search for a theme categoryId that matches what was passed in
          const categorizedThemeSpecsViewModelFromBuiltin: ICategorizedThemeSpecsViewModel | undefined =
            appThemesContextData.state.builtInCategorizedThemeSpecsViewModels.find(themeCategory => themeCategory.id === themeCategoryKey);

          // if we found a matching category...
          if (categorizedThemeSpecsViewModelFromBuiltin !== undefined) {

            // using the category that was found, get the built-in themes associated with the category
            // and create a menu item for each
            categorizedThemeSpecsViewModelFromBuiltin.themes.forEach((themeSpecsViewModel: IThemeSpecsViewModel) => {
              themeMenuItems.push(<MenuItem key={themeSpecsViewModel.id} value={themeSpecsViewModel.id}>{themeSpecsViewModel.name}</MenuItem>);
            });

            // if there's just a single theme available, set its key to be the first theme in the array
            if (categorizedThemeSpecsViewModelFromBuiltin.themes.length === 1) {
              defaultThemeKey = categorizedThemeSpecsViewModelFromBuiltin.themes[0].id;
            }

          }
        }
      }
    }

    displayConsoleLogs && console.log(`In SetThemeForm useEffect for themeCategoryKey. themeMenuItems: ${JSON.stringify(themeMenuItems)}`);

    setThemesForDropdown(themeMenuItems);

    // if a default themes key was assigned, set the theme key to its value
    if ((defaultThemeKey !== undefined) && (themeKey !== defaultThemeKey)) {
      setThemeKey(defaultThemeKey);
    }

  }, [themeCategoryKey]);


  function setThemeSpecsColorSpectrums(themeSpecsViewModel: IThemeSpecsViewModel | undefined): void {
    // if the theme specs view model is valid...
    if (themeSpecsViewModel) {
      // create and set the color spectrum for each theme specs color entity
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForPrimary).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForPrimaryColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForSecondary).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForSecondaryColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForCancel).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForCancelColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForError).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForErrorColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForHighlight).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForHighlightColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForInfo).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForInfoColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForSuccess).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForSuccessColor(colorSpectrum);
      });
      generateThemeColorSpectrumFromSingleColor(themeSpecsViewModel.themeBaseColors.baseColorForWarning).then((colorSpectrum: IThemeColorSpectrum) => {
        setColorSpectrumForWarningColor(colorSpectrum);
      });
    } else {
      // set each color spectrum to undefined
      setColorSpectrumForPrimaryColor(undefined);
      setColorSpectrumForSecondaryColor(undefined);
      setColorSpectrumForCancelColor(undefined);
      setColorSpectrumForErrorColor(undefined);
      setColorSpectrumForHighlightColor(undefined);
      setColorSpectrumForInfoColor(undefined);
      setColorSpectrumForSuccessColor(undefined);
      setColorSpectrumForWarningColor(undefined);
    }
  }

  // execute whenever themeKey changes
  useEffect(() => {
    let selectedThemeSpecsViewModel: IThemeSpecsViewModel | undefined = undefined;

    // if the current themeKey is set to some value...
    if (themeKey) {
      // based on the current themeType, themeCategory, & themeKey, get the theme specs associated with the theme
      // ensure that theme preparation has been completed
      if (appThemesContextData.state.themePreparationComplete) {
        // if the selected theme category is 'Custom' (user-defined)...
        if (themeTypeKey === enumThemeType.Custom.toString()) {
          // if the User-Defined Themes array is not undefined...
          if (appThemesContextData.state.userDefinedThemeSpecsViewModels !== undefined) {
            // find the user-defined theme where the id matches the themeKey
            const userDefinedThemeSpecsViewModel: IThemeSpecsViewModel | undefined =
              appThemesContextData.state.userDefinedThemeSpecsViewModels.find(themeSpecsViewModel => themeSpecsViewModel.id === themeKey);

            if (userDefinedThemeSpecsViewModel != undefined) {
              selectedThemeSpecsViewModel = userDefinedThemeSpecsViewModel;
            }
          }
        } else {
          // if the Built-in Themes array is not undefined...
          if (appThemesContextData.state.builtInCategorizedThemeSpecsViewModels !== undefined) {

            // search for a theme categoryId that matches what was passed in
            const categorizedThemeSpecsViewModelFromBuiltin: ICategorizedThemeSpecsViewModel | undefined =
              appThemesContextData.state.builtInCategorizedThemeSpecsViewModels.find(themeCategory => themeCategory.id === themeCategoryKey);

            // if a category was found...
            if (categorizedThemeSpecsViewModelFromBuiltin !== undefined) {
              // find the theme where the id matches the themeKey
              const builtInThemeSpecsViewModel: IThemeSpecsViewModel | undefined =
                categorizedThemeSpecsViewModelFromBuiltin.themes.find(themeSpecsViewModel => themeSpecsViewModel.id === themeKey);

              if (builtInThemeSpecsViewModel != undefined) {
                selectedThemeSpecsViewModel = builtInThemeSpecsViewModel;
              }
            }
          }
        }
      }
    }

    // set the currentThemeSpecs to be the specs associated with the currently selected theme
    setCurrentThemeSpecs(selectedThemeSpecsViewModel);

    // call method to set the color spectrums for the colors of the currently selected theme
    setThemeSpecsColorSpectrums(selectedThemeSpecsViewModel);

  }, [themeKey]);

  // use a custom hook to get the App Themes information from the AppThemesProvider
  const appThemesContextData: IAppThemesContextData = useAppThemesContext();

  // execute whenever userSettings.themeCategoryId changes
  useEffect(() => {
    // set the themeCategoryKey from the userSettings.themeCategoryId value
    if (themeCategoryKey !== userSettings.themeCategoryId) {
      setThemeCategoryKey(userSettings.themeCategoryId);
    }
  }, [userSettings.themeCategoryId]);

  // execute whenever userSettings.themeId changes
  useEffect(() => {
    // set the themeKey from the userSettings.themeId value
    if (themeKey !== userSettings.themeId) {
      setThemeKey(userSettings.themeId);
    }
  }, [userSettings.themeId]);


  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  const handleSaveSubmit = async (data: ISetThemeFormValues) => {

    setSaveInProgress(true);

    // call the onSubmit handler passed in, supplying the values for the themeCategoryKey and the themeKey
    await onSubmit(themeCategoryKey, themeKey);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.userSettings_SaveRequested}
        formIsValid={formIsValid}
      >

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        <StyledTextFieldForSelectControl
          inputRef={themeTypeReg}
          label="Theme Type *"
          margin='normal'
          value={themeTypeKey}
          onChange={e => setThemeTypeKey(e.target.value)}
        >
          {themeTypesForDropdown}
        </StyledTextFieldForSelectControl>

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        <StyledTextFieldForSelectControl
          inputRef={themeCategoryIdReg}
          label="Theme Category *"
          margin='normal'
          value={themeCategoryKey}
          onChange={e => setThemeCategoryKey(e.target.value)}
        >
          {themeCategoriesForDropdown}
        </StyledTextFieldForSelectControl>

        {/* We use a TextField with 'select' attribute as a pseudo <Select> (or dropdown) control */}
        {/* only display the Theme select control if themesForDropdown has been populated */}
        {themesForDropdown && themesForDropdown.length > 0 &&
          <StyledTextFieldForSelectControl
            inputRef={themeReg}
            label="Theme *"
            margin='normal'
            value={themeKey}
            onChange={e => setThemeKey(e.target.value)}
          >
            {themesForDropdown}
          </StyledTextFieldForSelectControl>
        }

        <StyledBoxForManageCustomThemesButtonContainer>
          <Tooltip
            title={TooltipStringAssets.customThemes_Manage}
            arrow
          >
            <span>
              <StyledButtonForManageCustomThemesButton
                aria-label="manage custom themes"
                variant='contained'
                size='medium'
                startIcon={<ThemesIcon />}
                onClick={() => navigate('/customThemes')}
              >
                {`Manage Custom Themes`}
              </StyledButtonForManageCustomThemesButton>
            </span>
          </Tooltip>
        </StyledBoxForManageCustomThemesButtonContainer>

        {themeKey && currentThemeSpecs &&
          <StyledBoxForFieldSet>
            <StyledBoxForLegend>Theme Colors Preview</StyledBoxForLegend>
            <StyledBoxForColorSelectorsArea>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Primary Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForPrimary} height={COLOR_SWATCH_HEIGHT} text={'Primary'} textColor={colorSpectrumForPrimaryColor && colorSpectrumForPrimaryColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Secondary Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForSecondary} height={COLOR_SWATCH_HEIGHT} text={'Secondary'} textColor={colorSpectrumForSecondaryColor && colorSpectrumForSecondaryColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Cancel Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForCancel} height={COLOR_SWATCH_HEIGHT} text={'Cancel'} textColor={colorSpectrumForCancelColor && colorSpectrumForCancelColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Error Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForError} height={COLOR_SWATCH_HEIGHT} text={'Error'} textColor={colorSpectrumForErrorColor && colorSpectrumForErrorColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Highlight Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForHighlight} height={COLOR_SWATCH_HEIGHT} text={'Highlight'} textColor={colorSpectrumForHighlightColor && colorSpectrumForHighlightColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Info Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForInfo} height={COLOR_SWATCH_HEIGHT} text={'Information'} textColor={colorSpectrumForInfoColor && colorSpectrumForInfoColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Success Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForSuccess} height={COLOR_SWATCH_HEIGHT} text={'Success'} textColor={colorSpectrumForSuccessColor && colorSpectrumForSuccessColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

              <StyledBoxForColorSwatchWithHeading>
                {/* Color Swatch for the Warning Color */}
                <ColorSwatch background={currentThemeSpecs.themeBaseColors.baseColorForWarning} height={COLOR_SWATCH_HEIGHT} text={'Warning'} textColor={colorSpectrumForWarningColor && colorSpectrumForWarningColor.contrastTextForMainBackground} />
              </StyledBoxForColorSwatchWithHeading>

            </StyledBoxForColorSelectorsArea>
          </StyledBoxForFieldSet>
        }


      </FormWithActionBar>
    </>

  );
}

export default SetThemeForm;