import { collection, DocumentData, Firestore, getDocs, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames } from '../enums';
import { IUser, IUserAsJson, User } from '../../../../../../dataObjects/models/users/User';
import { IFirestoreUserRepository_Ext } from '.';
import { JsonConverter } from '../../../../../../dataObjects/utilities/JsonConverter';
import { enumMdbErrorType } from '../../../../../../errorObjects/enums';
import { MdbError } from '../../../../../../errorObjects/MdbError';

/** 
 * @class FirestoreUserRepository_Ext Provides extended functionality beyond the FirestoreUserRepository class
 * *** IMPORTANT NOTE: FirestoreUserRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestoreUserRepository_Ext is focused on other features.
 *   Therefore, 
 *     1) FirestoreUserRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreUserRepository_Ext.
 */
export class FirestoreUserRepository_Ext implements IFirestoreUserRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method getUserIdFromEmailAddress Retrieves a single User object from the database, using the email address as the search criteria.
   * @param {string} emailAddress The email address of the User object to retrieve.
   * @returns {Promise<typeUniqueId | undefined>} A Promise (to provide asynchrounous capability) with either an Id (if
   * an object was found with the given search criteria) or 'undefined' (if an object wasn't found with the given search criteria).
   */
  getUserIdFromEmailAddress(emailAddress: string): Promise<typeUniqueId | undefined> {
    return new Promise<typeUniqueId | undefined>(async (resolve, reject) => {
      try {
        // value to be returned, defaulted to 'undefined'
        let userId: typeUniqueId | undefined = undefined;

        // call method to get a User record using the given email address
        const user: IUser | undefined = await this.getUserByEmailAddress(emailAddress);

        // if a User record was found, set the userId to be returned; otherwise, we will return an undefined value
        if (user !== undefined) {
          userId = user.id;
        }

        resolve(userId);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getUserByEmailAddress Retrieves a single User object from the database, using the email address as the search criteria.
   * @param {string} emailAddress The email address of the User object to retrieve.
   * @returns {Promise<IUser | undefined>} A Promise (to provide asynchrounous capability) with an object instance (if
   * an object was found with the given search criteria) or 'undefined' (if an object wasn't found with the given search criteria).
   */
  getUserByEmailAddress(emailAddress: string): Promise<IUser | undefined> {
    return new Promise<IUser | undefined>(async (resolve, reject) => {
      try {
        // object to return
        let user: IUser | undefined = undefined;

        // get the reference to the Firestore DB instance
        const firestoreDb: Firestore = this._firestoreDb;

        // get a reference to the appropriate collection
        const usersCollection = collection(firestoreDb, enumFirestoreCollectionNames.UserCollection
        );

        // proceed to get all documents with the parentId and in either the 'New' or 'Modified' state, starting with a query
        const documentQuery: Query<DocumentData> =
          query(usersCollection,
            where("userSettings.email", "==", emailAddress)
          );

        // next, get a snapshot of the document references resulting from executing the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(documentQuery);

        // now, from the snapshot, get the data for each doc and add the data into a JSON objects array
        let usersAsJson: Array<IUserAsJson> = [];
        querySnapshot.forEach((doc) => {
          usersAsJson.push(doc.data() as IUserAsJson);
        });

        // next, ensure that there is, at most, one user that was returned (not allowed to have multiple users with the same 
        // email address)

        if (usersAsJson.length > 1) {
          throw new MdbError(`Multiple users with the same email address (${emailAddress}) have been detected. An email address cannot be shared by multiple users.`, enumMdbErrorType.BadData);
        }

        // next, if a user records was returned, convert the object JSON object for return
        if (usersAsJson.length === 1) {
          user = JsonConverter.fromJSON(User, usersAsJson[0]);
        }

        resolve(user);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

}
