import { fork } from 'redux-saga/effects'
// import { fork } from 'redux-saga/effects'
import * as sagas from '../../sagas';

/**
 * @function rootSaga A generator function that runs (via fork) each saga that's available.
 * We use fork() so that each saga runs in the background.
 */
export function* rootSaga() {

  // traverse the array of defined sagas that appear within the sagas folder and fork() an 
  // instance for each
  const sagasArray = Object.values(sagas);
  for (let idx = 0; idx < Object.values(sagas).length; idx++) {
    yield fork(sagasArray[idx]);
  }
}
