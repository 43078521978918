import React, { PropsWithChildren } from 'react';
import { ControlsStringAssets, MessagesStringAssets } from '../../../assets/stringAssets';
import { Box, Button } from '@mui/material';
import Form from '../Form/Form';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';

/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the Action Buttons Area
const StyledBoxForActionButtonsArea = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
  flexDirection: 'column',
}));

// a styled Button for the 'Proceed to Sign-in' Button
const StyledButtonForProceedToSigninButton = styled((props) => (
  <Button
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  padding: theme.spacing(1, 2),
  color: theme.secondaryButton.textColor,
  border: theme.secondaryButton.border,
  background: theme.secondaryButton.background,
  '&:hover': {
    border: theme.secondaryButton.hoverBorder,
    background: theme.secondaryButton.hoverBackground,
    color: theme.secondaryButton.hoverTextColor,
  },
  '&:disabled': {
    border: theme.secondaryButton.disabledBorder,
    background: theme.secondaryButton.disabledBackground,
    color: theme.secondaryButton.disabledTextColor,
  },
}));

export interface ISigninWithEmailLinkFailureFormProps extends PropsWithChildren<unknown> {
  // /**
  //  * @property {boolean} signinWithEmailLinkSuccessful Whether the email verification process was successful
  //  */
  // signinWithEmailLinkSuccessful: boolean,
}

const SigninWithEmailLinkFailureForm: React.FC<ISigninWithEmailLinkFailureFormProps> = (props: ISigninWithEmailLinkFailureFormProps) => {
  SigninWithEmailLinkFailureForm.displayName = 'Sign-in With Email Link Failure Form';

  const navigate = useNavigate();

  // handles a login (submit) request from the form
  const handleSubmitStub = () => {

    // do nothing in this method
  }

  return (
    <>
      <Form
        onSubmit={handleSubmitStub}
      >
        <p>{MessagesStringAssets.login_SigninWithEmailLinkFailed}</p>

        <StyledBoxForActionButtonsArea>
          <StyledButtonForProceedToSigninButton
            onClick={() => navigate('/')}
          >
            {ControlsStringAssets.proceedToSigninButtonText}
          </StyledButtonForProceedToSigninButton>
        </StyledBoxForActionButtonsArea>
      </Form>
    </>

  );
}

export default SigninWithEmailLinkFailureForm;