import React, { PropsWithChildren } from 'react';
import { PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { styled } from '@mui/system';
import { Box } from '@mui/material';
import BackupDataFilesForm from '../../forms/BackupDataFilesForm/BackupDataFilesForm';
import { requestBackupDataViaHttp } from '../../../firebaseServices/securityServices/userAccount/userAccountActions';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/

// a styled Box (equivalent to a <div>), providing an area to display the overall header
const StyledBoxForPaddingAtopPage = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export interface IBackupDataFilesPageProps extends PropsWithChildren<unknown> {

}

export const BackupDataFilesPage: React.FC<IBackupDataFilesPageProps> = (props: IBackupDataFilesPageProps) => {

  BackupDataFilesPage.displayName = 'Backup Data Files Page';

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  /**
   * @method handleBackupDataFilesFormSubmit Handles a submit request from the BackupDataFilesForm
   * @param event A Submit event
   */
  function handleBackupDataFilesFormSubmit(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`BackupDataFilesPage. Entered handleBackupDataFilesFormSubmit().`);

        // make request to backup data files
        await requestBackupDataViaHttp();

        displayConsoleLogs && console.log(`BackupDataFilesPage. Returned successfully from requestBackupDataFilesViaHttp().`);

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  return (
    <>
      <StyledBoxForPaddingAtopPage />
      <GenericPageContainer
        maxWidth="sm"
        showBackButton={true}
        pageTitle={PageAndViewTitleStringAssets.pageTitle_BackupDataFiles}
      >
        <BackupDataFilesForm
          onSubmit={handleBackupDataFilesFormSubmit}
        />

      </GenericPageContainer>
    </>

  );

} // const BackupDataFilesPage...

export default BackupDataFilesPage;
