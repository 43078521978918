import { take, put } from 'redux-saga/effects';
import { deleteSocialMediaPost } from '../../../firebaseServices/dataServices/dataServiceActions/socialMediaPostActions';
import { socialMediaPostDeleteRequest, socialMediaPostDeleteStatusChange } from '../../slices/socialMediaPost/socialMediaPostDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { socialMediaPostDeleteFailureChange } from '../../slices/socialMediaPost/socialMediaPostDeleteFailureSlice';

/**
 * @function deleteSocialMediaPostRequestedSaga A saga workflow pertaining to a user having requested to delete a socialMediaPost.
 */
export function* deleteSocialMediaPostRequestedSaga() {
  // console.info('(deleteSocialMediaPostRequestedSaga) entered deleteSocialMediaPostRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the socialMediaPostDeleteRequest action to be dispatched and get the payload data...
      const socialMediaPostDeleteRequestData = yield take(socialMediaPostDeleteRequest);
      const { payload: socialMediaPost } = socialMediaPostDeleteRequestData;

      // make a request to delete the socialMediaPost
      yield deleteSocialMediaPost(socialMediaPost);

      // dispatch an action to indicate that the delete operation was successful
      yield put(socialMediaPostDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(socialMediaPostDeleteStatusChange(enumWorkflowState.Failure));
      yield put(socialMediaPostDeleteFailureChange(error));
    }
  }
}
