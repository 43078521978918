import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IVideoLink } from '../../../dataObjects/models/digitalMedia/VideoLink';

const initialState: enumWorkflowState | null = null;

export const videoLinkDeleteStatusSlice = createSlice({
  name: 'videoLinkDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IVideoLink
    videoLinkDeleteRequest: (state, action: PayloadAction<IVideoLink>) => {
      videoLinkDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    videoLinkDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { videoLinkDeleteRequest, videoLinkDeleteStatusChange } = videoLinkDeleteStatusSlice.actions;

// export the core reducer for the slice
export const videoLinkDeleteStatusReducer = videoLinkDeleteStatusSlice.reducer;

export default videoLinkDeleteStatusSlice.reducer;