import { createSlice } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface ILogoutStatusState {
//   value: enumWorkflowState | null,
// }

const initialState: enumWorkflowState | null = null;

export const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    
    // action has a payload with the new enumWorkflowState value or null
    logoutStatusChange(state, action) {
      // change status of logout process
      return action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
// export const { logoutRequest, logoutInProgress, logoutFailure, logoutSuccess, clearLogoutData } = logoutSlice.actions;
export const { logoutStatusChange } = logoutSlice.actions;

// export the core reducer for the slice
export const logoutReducer = logoutSlice.reducer;

export default logoutSlice.reducer;
