import React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

export interface IFilingCabinetComplexLightWithOpenMiddleDrawerParms {
  className?: string
}

const useStyles: () => Record<string, string> = makeStyles((theme: Theme) => ({

  boundingRect: {
    fill: 'none',
  },

  cabinet: {
    fill: theme.stylizedSvgIconGraphics.graphicsPrimaryColor,
  },

}));


// By wrapping this component's definition in React.forwardRef, we enable having the ability to accept a React.Ref (ref?) parameter, in addition to the 
// normal 'props' parameter collection. The 'ref' allows us to forward the embedded <svg> element as a DOM reference
const FilingCabinetComplexLightWithOpenMiddleDrawer = React.forwardRef((props: IFilingCabinetComplexLightWithOpenMiddleDrawerParms, ref?: React.Ref<SVGSVGElement>) => {

  const { className } = props;
  const classes: Record<string, string> = useStyles();

  return (
    <svg ref={ref} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160.26 200" >
      <defs>
      </defs>

      <g id="groupComplexLightCabinetMiddleDrawerOpen">
        <path id="pathBottomDrawerHandle" className={classes.cabinet} d="M223.74,662.68h43.71V650.76H223.74Zm4-7.94h35.76v4H227.72Z" transform="translate(-165.47 -615)" />
        <path id="pathTopDrawerHandle" className={classes.cabinet} d="M267.45,727.58H223.74V739.5h43.71Zm-4,7.95H227.72v-4h35.76Z" transform="translate(-165.47 -615)" />
        <path id="pathCabinetFrameworkWithMiddleDrawer" className={classes.cabinet} d="M320.43,702.09V624.93A9.94,9.94,0,0,0,310.5,615H180.7a10,10,0,0,0-9.94,9.93v77.16l-5.29,4v56h5.29v32.45a9.94,9.94,0,0,0,9.94,9.93h4.11a7.28,7.28,0,0,0,6.48,10.6h15.9a7.28,7.28,0,0,0,6.48-10.6h63.85A7.3,7.3,0,0,0,284,815H299.9a7.28,7.28,0,0,0,6.49-10.6h4.11a9.94,9.94,0,0,0,9.93-9.93V762h5.3v-56Zm-2.65,3h-9.27v-7Zm-143-80.14a6,6,0,0,1,6-6H310.5a6,6,0,0,1,6,6v74.18L297.92,685.2h9.27v-57H184v57h9.27l-18.54,13.91Zm11.92,76.16H304.54v4H186.66Zm-2.65-4,9.27-7v7Zm109.93-4v4H197.25v-4Zm-86.09-4v-4h75.5v4Zm79.47,0v-4h4l5.3,4ZM188,681.23v-49H303.22v49Zm11.92,4h4v4h-9.27Zm98,11.92v-7l9.27,7Zm-115.23,1v7h-9.28ZM207.19,811h-15.9a3.32,3.32,0,0,1,0-6.63h15.9a3.32,3.32,0,0,1,0,6.63Zm92.71,0H284a3.32,3.32,0,0,1,0-6.63H299.9a3.32,3.32,0,1,1,0,6.63Zm16.56-16.56a6,6,0,0,1-6,6H180.7a6,6,0,0,1-6-6V762H184v29.14H307.19V762h9.27Zm-49-25.83V762h35.77v25.17H188V762h35.76v6.62Zm-39.73-4V762h35.76v2.65Zm94-6.62H169.44V709H321.76Z" transform="translate(-165.47 -615)" />
      </g>

    </svg >
  )
});

export default FilingCabinetComplexLightWithOpenMiddleDrawer;
