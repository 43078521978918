import { IFirestoreTopicItemRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreTopicItemRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { ITopicItem, ITopicItemAsJson } from "../../../../dataObjects/models/topics/TopicItem";
import { createDigitalMediaBaseFromTopicItem } from "../digitalMediaActions/createDigitalMediaBaseFromTopicItem";


export function saveTopicItem(topicItem: ITopicItem): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a TopicItem repository from the repository factory
      const firestoreTopicItemRepository: IFirestoreTopicItemRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.TopicItem) as
        IFirestoreBaseRepository<ITopicItem, ITopicItemAsJson>;

      // determine if the topicItem already exists by calling the repository get() method to try and get the object
      const existingTopicItem: ITopicItem | undefined = await firestoreTopicItemRepository.get(topicItem.id);

      // If the topicItem wasn't found, we will create a new instance. If it exists, we'll update the existing instance.
      if (existingTopicItem === undefined) {
        // call repository method to create the topicItem
        await firestoreTopicItemRepository.create(topicItem);
        // need to also create a base DigitalMedia object
        await createDigitalMediaBaseFromTopicItem(topicItem);
      } else {
        // call repository method to update the topicItem
        await firestoreTopicItemRepository.update(topicItem);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}