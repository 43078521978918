import { take } from "redux-saga/effects";
import { saveVideoLinkOnlyWorkerSaga } from "./saveVideoLinkOnlyWorkerSaga";
import { videoLinkSaveWithoutFileUploadRequest } from "../../slices/videoLink/videoLinkSaveStatusSlice";

/**
 * @function saveVideoLinkOnlyWatcherSaga A "watcher" saga function that watches for dispatches of the action that is a request to
 *   save an VideoLink only (without a file upload).
 */
export function* saveVideoLinkOnlyWatcherSaga() {
  // loop to keep the saga running throughout the life of the application
  while (true) {
    // wait on the videoLinkSaveWithoutFileUploadRequest action to be dispatched and get the payload data...
    const saveVideoLinkRequestData = yield take(videoLinkSaveWithoutFileUploadRequest);
    const { payload: saveVideoLinkRequest } = saveVideoLinkRequestData;

    yield saveVideoLinkOnlyWorkerSaga(saveVideoLinkRequest);
  }
}