import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName, enumCategoryType } from '../../../enums';
import { ICategory, ICategoryAsJson } from '.';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { CategoryMetadata, ICategoryMetadata } from '../CategoryMetadata';
import { JsonConverter } from '../../../utilities/JsonConverter';
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';

const UNCATEGORIZED_CATEGORY_SUFFIX: string = '-Uncategorized';

/**
 * @class Category Represents a collection of category of information (topics, topic items, digital media, etc.)
 * {@linkcode ICategory}
 */
export class Category extends VersionAwarePersistable implements ICategory {

  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or category) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {enumCategoryType} categoryType The type of category represented by the associated Object
   * @param {string} name The name of the category
   * @param {string} description (optional) A description for the category
   * @param {string} displayBackground The background specs for displaying the category
   * @param {string} displayBorder The border specs for displaying the category
   * @param {string} displayFontColor The font color specs for displaying the category
   * @param {string} children An array of Ids for the children (eg, Channels, etc) contained in the category
   * @param {number} displayOrder The ordering for displaying the category in the UI
   * @param {IUserPersistenceData} userPersistenceData (optional) User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    categoryType: enumCategoryType,
    name: string,
    description?: string,
    displayBackground?: string,
    displayBorder?: string,
    displayFontColor?: string,
    children?: Array<typeUniqueId>,
    displayOrder?: number,
    userPersistenceData?: IUserPersistenceData,
  ) {
    super(ownerId, enumPersistableObjectClassName.Category, enumPersistableObjectType.Category, id, parentObjectType, parentId, objectState, userPersistenceData);

    this._categoryType = categoryType;

    this.name = name;

    if (description) {
      this.description = description;
    }

    if (displayBackground) {
      this.displayBackground = displayBackground;
    }

    if (displayBorder) {
      this.displayBorder = displayBorder;
    }

    if (displayFontColor) {
      this.displayFontColor = displayFontColor;
    }

    if (children && children.length > 0) {
      this.children = [ ...children ];
    }

    if (displayOrder) {
      this.displayOrder = displayOrder;
    }

    // initlalize the CategoryMetadata
    this._categoryMetadata = new CategoryMetadata(this.ownerId, RandomId.newId(), this.objectType, this.id, enumObjectPersistenceState.New);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumCategoryType} _categoryType The type of category represented by the associated Object
   */
  private _categoryType: enumCategoryType;

  /**
   * @method categoryType is an optional getter method for _categoryType
   */
  get categoryType(): enumCategoryType {
    return this._categoryType;
  }

  /**
   * @method categoryType is an optional setter method for _categoryType
   * @param {enumCategoryType} value is the input value for setting _categoryType
   */
  set categoryType(value: enumCategoryType) {
    this._categoryType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} name represents the name of the category
   */
  private _name: string = '';

  // getter for _propName
  get name() {
    return this._name;
  }

  // setter for _propName
  set name(value) {
    this._name = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _description property is the description for the CategoryItem
   */
  private _description: string = '';

  /**
   * @method description is an optional getter method for _description
   */
  get description() {
    return this._description;
  }

  /**
   * @method description is an optional setter method for _description
   * @param {string} value is the input value for setting _description
   */
  set description(value) {
    this._description = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _displayBackground The background specs for displaying the category
   */
  private _displayBackground: string = '#DDD';

  /**
   * @method displayBackground Getter method for _displayBackground
   */
  get displayBackground() {
    return this._displayBackground;
  }

  /**
   * @method displayBackground Setter method for _displayBackground
   * @param {string} value Input value for setting _displayBackground
   */
  set displayBackground(value) {
    this._displayBackground = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _displayBorder The border specs for displaying the category
   */
  private _displayBorder: string = '#777';

  /**
   * @method displayBorder Getter method for _displayBorder
   */
  get displayBorder() {
    return this._displayBorder;
  }

  /**
   * @method displayBorder Setter method for _displayBorder
   * @param {string} value Input value for setting _displayBorder
   */
  set displayBorder(value) {
    this._displayBorder = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _displayFontColor The font color specs for displaying the category
   */
  private _displayFontColor: string = '#333';

  /**
   * @method displayFontColor Getter method for _displayFontColor
   */
  get displayFontColor() {
    return this._displayFontColor;
  }

  /**
   * @method displayFontColor Setter method for _displayFontColor
   * @param {string} value Input value for setting _displayFontColor
   */
  set displayFontColor(value) {
    this._displayFontColor = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {number} _displayOrder The ordering for displaying the category in the UI
   */
  private _displayOrder: number = 0;

  /**
   * @method displayOrder Getter method for _displayOrder
   */
  get displayOrder(): number {
    return this._displayOrder;
  }

  /**
   * @method displayOrder Setter method for _displayOrder
   * @param {number} value Input value for setting _displayOrder
   */
  set displayOrder(value: number) {
    this._displayOrder = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Array<typeUniqueId>} children An array of Ids for the children (eg, Channels, etc) contained in the category
   */
   ;
   private _children: Array<typeUniqueId> = [];

   /**
    * @method children Getter method for _children
    */
   get children() {
     return this._children;
   }
 
   /**
    * @method children Setter method for _children
    * @param {string} value Input value for setting _children
    */
   set children(value) {
     this._children = value;
   }
   /*-----------------------------------------------*/
 
  /*-----------------------------------------------*/
  /**
   * @property {ICategoryMetadata} _categoryMetadata property (complete the description)
   */
  private _categoryMetadata: ICategoryMetadata;

  /**
   * @method categoryMetadata is an optional getter method for _categoryMetadata
   */
  get categoryMetadata(): ICategoryMetadata {
    return this._categoryMetadata;
  }

  /**
   * @method categoryMetadata is an optional setter method for _categoryMetadata
   * @param {ICategoryMetadata} value is the input value for setting _categoryMetadata
   */
  set categoryMetadata(value: ICategoryMetadata) {
    // ensure that the 'parentObject', 'parentObjectType' and 'parentId' are set to this instance's 'this', 'objectType' and 'id', respectively.
    value.parentObject = this;
    value.parentObjectType = this.objectType;
    value.parentId = this.id;
    this._categoryMetadata = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method addChild Adds a child object to the category.
   * @param {typeUniqueId} childId The Id of the child object to be added to the category.
   * @returns {boolean} Whether the child was added to the category. (Note: If the child is already in the collection before calling this method, 'false' will be returned.)
   */
   addChild(childId: typeUniqueId): boolean {
    let childAddedToCategory: boolean = false;

    // determine whether the given childId already exists within the children array
    const idxChildId: number = this.children.findIndex((existingChildId: typeUniqueId) => existingChildId === childId);
    // if the childId was not found in the array (if the returned index value === -1)
    if (idxChildId === -1) {
      // add the childId to the children array (using the Array.push() method)
      this.children.push(childId);
      childAddedToCategory = true;
    }

    return childAddedToCategory;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method removeChild Removes a child as a member of the children for the category.
   * @param {typeUniqueId} childId The Id of the child that is to be removed.
   * @returns {boolean} Whether the child was removed. (Note: If the child is not one of the category's children before calling this method, 'false' will be returned.)
   */
  removeChild(childId: typeUniqueId): boolean {
    let childRemovedFromCategory = false;

    // determine whether the given child already exists within the children array
    const idxChildId: number = this.children.findIndex((existingChildId: typeUniqueId) => existingChildId === childId);
    // if the childId was found in the array (if the returned index value !== -1)
    if (idxChildId !== -1) {
      // remove the childId from the children array (using the Array.splice() method, where the first parm is the index of the value to be removed and
      // the second parm is the number of values to be removed)
      this.children.splice(idxChildId, 1);
      childRemovedFromCategory = true;
    }

    return childRemovedFromCategory;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method isCategoryUncategorizedForUser Determines whether the given category represents a user's "Uncategorized" Category.
   * @param {typeUniqueId} currentUserId The Id of the current user
   * @returns {boolean} Whether the given categoryId represents a user's "Uncategorized" Category.
   */
   isCategoryUncategorizedForUser(currentUserId: typeUniqueId): boolean {
    // if this category's Id is the same as the userId, it is the user's "Uncategorized" Category.
    let userUncategorizedCategory = this.id === currentUserId;

    return userUncategorizedCategory;
  }

  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ICategory} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ICategory {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ICategory = Object.create(Category.prototype);
    Object.assign(copyOfObject, this);

    if (this.categoryMetadata !== undefined) {
      copyOfObject.categoryMetadata = Object.create(CategoryMetadata.prototype);
      copyOfObject.categoryMetadata = this.categoryMetadata.copy();
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): ICategoryAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: ICategoryAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.categoryType = this.categoryType;
      jsonObject.name = this.name;
      jsonObject.description = this.description;
      jsonObject.displayBackground = this.displayBackground;
      jsonObject.displayBorder = this.displayBorder;
      jsonObject.displayFontColor = this.displayFontColor;
      jsonObject.children = [ ...this.children ];
      jsonObject.displayOrder = this.displayOrder;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        jsonObject.categoryMetadata = this.categoryMetadata.toJSON(true);
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {ICategoryAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A Category instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: ICategoryAsJson, includeContainedObjects: boolean = true): ICategory {
    try {
      // create a new instance of this class
      let categoryObject: Category = Object.create(Category.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      categoryObject = super.fromJSONProtected(categoryObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.categoryType) {
        categoryObject.categoryType = (jsonObject.categoryType === 'undefined' ? enumCategoryType.Unassigned : jsonObject.categoryType);
      }

      if (jsonObject.name) {
        categoryObject.name = jsonObject.name;
      }

      if (jsonObject.description) {
        categoryObject.description = jsonObject.description;
      }

      if (jsonObject.displayBackground) {
        categoryObject.displayBackground = jsonObject.displayBackground;
      }

      if (jsonObject.displayBorder) {
        categoryObject.displayBorder = jsonObject.displayBorder;
      }

      if (jsonObject.displayFontColor) {
        categoryObject.displayFontColor = jsonObject.displayFontColor;
      }

      if (jsonObject.children) {
        categoryObject.children = [ ...jsonObject.children ];
      }

      if (jsonObject.displayOrder) {
        categoryObject.displayOrder = jsonObject.displayOrder;
      }

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        if (jsonObject.categoryMetadata) {
          categoryObject.categoryMetadata = JsonConverter.fromJSON(CategoryMetadata, jsonObject.categoryMetadata);
        }
      }

      return categoryObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method userUncategorizedCategoryIdFromUserId Derives a Category Id from a User Id. (Format: UserId + "-Uncategorized", which stands for "Uncategorized Category".)
   * @param {typeUniqueId} userId The Id of a user for which a Category Id is to be derived.
   * @returns {typeUniqueId} The generated Uncategorized Category Id.
   */
   static userUncategorizedCategoryIdFromUserId(userId: typeUniqueId): typeUniqueId {
    return userId + UNCATEGORIZED_CATEGORY_SUFFIX;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method userUncategorizedCategoryIdFromNonUncategorizedId Derives a Category Id from an Id. (Format: CategoryId + "-Uncategorized", which stands for "Uncategorized Channel".)
   * @param {typeUniqueId} channelId The Id of a channel for which an Uncategorized Category Id is to be derived.
   * @returns {typeUniqueId} The generated Uncategorized Category Id.
   */
  static userUncategorizedCategoryIdFromNonUncategorizedId(categoryId: typeUniqueId): typeUniqueId {
    return categoryId + UNCATEGORIZED_CATEGORY_SUFFIX;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method userIdFromUserUncategorizedCategoryId Derives a User Id from a user's Uncategorized Category Id. (Format: UserId + "-Private", which stands for "Private Channel".)
   * @param {typeUniqueId} uncategorizedCategoryId The Id of an Uncategorized Category for which a User Id is to be derived.
   * @returns {typeUniqueId} The derived User Id.
   */
  static userIdFromUserUncategorizedCategoryId(uncategorizedCategoryId: typeUniqueId): typeUniqueId {
    const idxSubStringLocation = uncategorizedCategoryId.lastIndexOf(UNCATEGORIZED_CATEGORY_SUFFIX);
    if (idxSubStringLocation === -1) {
      // didn't find the suffix "-Uncategorized"
      throw new MdbError(`Category Id does not have a '${UNCATEGORIZED_CATEGORY_SUFFIX}' suffix`, enumMdbErrorType.MissingData);
    }

    const userId = uncategorizedCategoryId.slice(0, idxSubStringLocation);

    return userId;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method isChannelIdForPrivateChannel Determines whether the given channelId represents a user's Private Channel.
   * @param {typeUniqueId} channelId The Id of a Channel for which an evaluation is to be performed.
   * @returns {boolean} Whether the given channelId represents a user's Private Channel.
   */
  static isCategoryIdForUncategorizedCategory(categoryId: typeUniqueId): boolean {
    // if the categoryId ends with the Uncategorized Category Suffix, it is representative of an Uncategorized Category
    let categoryIdIsForUncategorizedCategory = categoryId.endsWith(UNCATEGORIZED_CATEGORY_SUFFIX)

    return categoryIdIsForUncategorizedCategory;
  }

  /*-----------------------------------------------*/
}
