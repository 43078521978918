import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';

// export interface IFetchNotesStatusState {
//   value: enumWorkflowState | null
// }

const initialState: enumWorkflowState | null = null;

export const fetchNotesStatusSlice = createSlice({
  name: 'fetchNotesStatus',
  initialState,
  reducers: {
    fetchNotesStatusChange: (state: enumWorkflowState | null, action: PayloadAction<enumWorkflowState>) => {
      state = action.payload;
    },
    fetchNotesStatusClear: (state: enumWorkflowState | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { fetchNotesStatusChange, fetchNotesStatusClear } = fetchNotesStatusSlice.actions;

// export the core reducer for the slice
export const fetchNotesStatusReducer = fetchNotesStatusSlice.reducer;

export default fetchNotesStatusSlice.reducer;