import { ILoginData } from '.';

/** 
 * @class LoginData Represents data associated with a Login operation
 */
export class LoginData implements ILoginData {
  /**
   * @method Constructor method
   * @param {string | undefined} email An email address (for authenticating by email/password)
   * @param {string | undefined} password A password (for authenticating by password)
   * @param {string | undefined} phoneNumber A phone number (for authenticating by phone number)
   * @param {boolean} keepLoggedIn Whether to keep the user logged in across sessions (unless user logs out)
   */
  constructor(
    email?: string | undefined,
    password?: string | undefined,
    phoneNumber?: string | undefined,
    keepLoggedIn?: boolean
  ) {
    this._email = email;
    this._password = password;
    this._phoneNumber = phoneNumber;
    if (keepLoggedIn) {
      this._keepLoggedIn = keepLoggedIn;
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _email An email address (for authenticating by email/password)
   */
  private _email: string | undefined = undefined;

  /**
   * @method email Getter method for _email
   */
  get email(): string | undefined {
    return this._email;
  }

  /**
   * @method email Setter method for _email
   * @param {string | undefined} value The value to be used in setting _email
   */
  set email(value: string | undefined) {
    this._email = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _password A password (for authenticating by password)
   */
  private _password: string | undefined = undefined;

  /**
   * @method password Getter method for _password
   */
  get password(): string | undefined {
    return this._password;
  }

  /**
   * @method password Setter method for _password
   * @param {string | undefined} value The value to be used in setting _password
   */
  set password(value: string | undefined) {
    this._password = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string | undefined} _phoneNumber A phone number (for authenticating by phone number)
   */
  private _phoneNumber: string | undefined = undefined;

  /**
   * @method phoneNumber Getter method for _phoneNumber
   */
  get phoneNumber(): string | undefined {
    return this._phoneNumber;
  }

  /**
   * @method phoneNumber Setter method for _phoneNumber
   * @param {string | undefined} value The value to be used in setting _phoneNumber
   */
  set phoneNumber(value: string | undefined) {
    this._phoneNumber = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {boolean} _keepLoggedIn Whether to keep the user logged in across sessions (unless user logs out)
   */
  private _keepLoggedIn: boolean = false;

  /**
   * @method keepLoggedIn Getter method for _keepLoggedIn
   */
  get keepLoggedIn(): boolean {
    return this._keepLoggedIn;
  }

  /**
   * @method keepLoggedIn Setter method for _keepLoggedIn
   * @param {boolean} value The value to be used in setting _keepLoggedIn
   */
  set keepLoggedIn(value: boolean) {
    this._keepLoggedIn = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {ILoginData} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): ILoginData {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: ILoginData = Object.create(LoginData.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the Typescript object instance to a JSON object instance
   * @returns {ILoginData} A JSON object instance
   */
  toJSON(includeContainedObjects?: boolean = true): ILoginData {
    try {
      // declare a JSON object to be returned
      const jsonObject: ILoginDataAsJson = {
        email: this.email,
        password: this.password,
        phoneNumber: this.phoneNumber,
        keepLoggedIn: this.keepLoggedIn
      };

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a JSON object instance to a Typescript object instance
   * @param {ILoginDataAsJson} jsonObject A JSON object instance
   * @returns {ILoginData} A Typescript object instance
   */
  static fromJSON(jsonObject: ILoginDataAsJson): ILoginData {
    try {
      // create a new instance of this class
      let loginData: ILoginData = Object.create(LoginData.prototype);

      // copy field values from the json object 
      loginData.email = jsonObject.email;
      loginData.password = jsonObject.password;
      loginData.phoneNumber = jsonObject.phoneNumber;
      loginData.keepLoggedIn = jsonObject.keepLoggedIn;

      // return the object
      return loginData;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
