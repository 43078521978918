import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';
import { IDigitalMedia, IDigitalMediaAsJson } from '.';
import { IAudioLink, IAudioLinkAsJson, AudioLink } from '../../digitalMedia/AudioLink';
import { IImageLink, IImageLinkAsJson, ImageLink } from '../ImageLink';
import { IVideoLink, IVideoLinkAsJson, VideoLink } from '../VideoLink';
import { IVersionAwarePersistableArray, VersionAwarePersistableArray } from '../../persistence/VersionAwarePersistableArray';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { IHyperLink, HyperLink } from '../HyperLink';
import { INote, Note } from '../Note';

/** 
 * @class DigitalMedia Resources (images, videos, audio, notes, etc.) that can be attached to an item
 */
export class DigitalMedia extends VersionAwarePersistable implements IDigitalMedia {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   * @param {Array<IImageLink>} images collection of image links
   * @param {Array<IVideoLink>} videos collection of video links
   * @param {Array<IAudioLink>} audios collection of audio links
   * @param {Array<IHyperLink>} hyperLinks collection of hyperLinks
   * @param {Array<INote>} notes collection of notes
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    userPersistenceData?: IUserPersistenceData,
    images?: Array<IImageLink>,
    videos?: Array<IVideoLink>,
    audios?: Array<IAudioLink>,
    hyperLinks?: Array<IHyperLink>,
    notes?: Array<INote>
  ) {
    super(ownerId, enumPersistableObjectClassName.DigitalMedia, enumPersistableObjectType.DigitalMedia, id, parentObjectType, parentId, objectState, userPersistenceData);

    // initialize for all digital media
    this.initializeDigitalMediaArrays();

    // for each of the individual digital media, if they have data, add them to their respective bins
    if (images !== undefined && this.images !== undefined) {
      this.images.addObjectsArray(images);
    }

    if (videos !== undefined && this.videos !== undefined) {
      this.videos.addObjectsArray(videos);
    }

    if (audios !== undefined && this.audios !== undefined) {
      this.audios.addObjectsArray(audios);
    }

    if (hyperLinks !== undefined && this.hyperLinks !== undefined) {
      this.hyperLinks.addObjectsArray(hyperLinks);
    }

    if (notes !== undefined && this.notes !== undefined) {
      this.notes.addObjectsArray(notes);
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistableArray<IImageLink> | undefined = undefined} _images A collection of image links
   */
  private _images: IVersionAwarePersistableArray<IImageLink> | undefined = undefined;

  /**
   * @method images is a getter method for _images
   */
  get images() {
    // return this._images;
    return this._images;
  }

  get imagesRawArray(): IImageLink[] | undefined {
    let returnValue: IImageLink[] | undefined = undefined;

    if (this._images !== undefined) {
      returnValue = this._images.persistableObjects;
    }

    return returnValue;
  }

  /**
   * @method images is s setter method for _images
   * @param {IVersionAwarePersistableArray<IImageLink> | undefined} value is the input value for setting _images
   */
  set images(value: IVersionAwarePersistableArray<IImageLink> | undefined) {
    // if the value is defined (there are ImageLink objects in an array), 
    // replace the existing array of objects with this new array
    // (Note: Don't replace with an 'undefined' value.)
    if (value !== undefined) {
      this._images = value;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistableArray<IVideoLink>} _videos A collection of video links
   */
  // private _videos: Array<IVideoLink> | undefined = [];
  private _videos: IVersionAwarePersistableArray<IVideoLink> | undefined = undefined;

  /**
   * @method videos is an optional getter method for _videos
   */
  get videos() {
    return this._videos;
  }

  get videosRawArray(): IVideoLink[] | undefined {
    let returnValue: IVideoLink[] | undefined = undefined;

    if (this._videos !== undefined) {
      returnValue = this._videos.persistableObjects;
    }

    return returnValue;
  }

  /**
   * @method videos is an optional setter method for _videos
   * @param {IVersionAwarePersistableArray<IVideoLink>} value is the input value for setting _videos
   */
  set videos(value: IVersionAwarePersistableArray<IVideoLink> | undefined) {
    // if the value is defined (there are VideoLink objects in an array), 
    // replace the existing array of objects with this new array.
    // (Note: Don't replace with an 'undefined' value.)
    if (value !== undefined) {
      this._videos = value;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistableArray<IAudioLink>} _audios A collection of audio links
   */
  private _audios: IVersionAwarePersistableArray<IAudioLink> | undefined = undefined;

  /**
   * @method audios is an optional getter method for _audios
   */
  get audios() {
    return this._audios;
  }

  get audiosRawArray(): IAudioLink[] | undefined {
    let returnValue: IAudioLink[] | undefined = undefined;

    if (this._audios !== undefined) {
      returnValue = this._audios.persistableObjects;
    }

    return returnValue;
  }

  /**
   * @method audios is an optional setter method for _audios
   * @param {IVersionAwarePersistableArray<IAudioLink>} value is the input value for setting _audios
   */
  set audios(value: IVersionAwarePersistableArray<IAudioLink> | undefined) {
    // if the value is defined (there are AudioLink objects in an array), 
    // replace the existing array of objects with this new array.
    // (Note: Don't replace with an 'undefined' value.)
    if (value !== undefined) {
      this._audios = value;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistableArray<IHyperLink>} _hyperLinks A collection of hyperLinks
   */
  private _hyperLinks: IVersionAwarePersistableArray<IHyperLink> | undefined = undefined;

  /**
   * @method hyperLinks Getter method for _hyperLinks
   */
  get hyperLinks(): IVersionAwarePersistableArray<IHyperLink> | undefined {
    return this._hyperLinks;
  }

  get hyperLinksRawArray(): IHyperLink[] | undefined {
    let returnValue: IHyperLink[] | undefined = undefined;

    if (this._hyperLinks !== undefined) {
      returnValue = this._hyperLinks.persistableObjects;
    }

    return returnValue;
  }

  /**
   * @method hyperLinks is an optional setter method for _hyperLinks
   * @param {: IVersionAwarePersistableArray<IHyperLink> | undefined} value is the input value for setting _hyperLinks
   */
  set hyperLinks(value: IVersionAwarePersistableArray<IHyperLink> | undefined) {
    // if the value is defined (there are HyperLink objects in an array), 
    // replace the existing array of objects with this new array.
    // (Note: Don't replace with an 'undefined' value.)
    if (value !== undefined) {
      this._hyperLinks = value;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistableArray<INote>} _notes A collection of notes
   */
  private _notes: IVersionAwarePersistableArray<INote> | undefined = undefined;

  /**
   * @method notes Getter method for _notes
   */
  get notes(): IVersionAwarePersistableArray<INote> | undefined {
    return this._notes;
  }

  get notesRawArray(): INote[] | undefined {
    let returnValue: INote[] | undefined = undefined;

    if (this._notes !== undefined) {
      returnValue = this._notes.persistableObjects;
    }

    return returnValue;
  }

  /**
   * @method notes is an optional setter method for _notes
   * @param {: IVersionAwarePersistableArray<INote> | undefined} value is the input value for setting _notes
   */
  set notes(value: IVersionAwarePersistableArray<INote> | undefined) {
    // if the value is defined (there are Note objects in an array), 
    // replace the existing array of objects with this new array.
    // (Note: Don't replace with an 'undefined' value.)
    if (value !== undefined) {
      this._notes = value;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method initializeDigitalMediaArrays Initializes the individual arrays for each type of digital media
   */
  private initializeDigitalMediaArrays(): void {
    // initialize the arrays for every digital media in this class instance. We do it in the constructor because
    // we need 'this' instance's Id that was set when calling the base class (through 'super()') to establish the 
    // 'parentId' for all contained digital media
    this._images = new VersionAwarePersistableArray<IImageLink>(this, ImageLink, this.objectType, this.id);
    this._videos = new VersionAwarePersistableArray<IVideoLink>(this, VideoLink, this.objectType, this.id);
    this._audios = new VersionAwarePersistableArray<IAudioLink>(this, AudioLink, this.objectType, this.id);
    this._hyperLinks = new VersionAwarePersistableArray<IHyperLink>(this, HyperLink, this.objectType, this.id);
    this._notes = new VersionAwarePersistableArray<INote>(this, Note, this.objectType, this.id);
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /** 
   * @method propagateObjectStateChangeToDescendents Requests to change the state of each VersionAwarePersistable-derived descendant object of this class
   * @param {enumObjectPersistenceState} newState The state to which each descdendant object is to be set
   */
  propagateObjectStateChangeToDescendents(newState: enumObjectPersistenceState): void {
    try {
      // For each standalone child, set the state of the child and request to propagate to its descendants.
      // There are no applicable standalone child members defined for this class.

      // For each array of applicable children, request to propagate the changes to each child object in each array
      this.images!.propagateObjectStateChangeToDescendents(newState);

      this.videos!.propagateObjectStateChangeToDescendents(newState);

      this.audios!.propagateObjectStateChangeToDescendents(newState);

      this.hyperLinks!.propagateObjectStateChangeToDescendents(newState);

      this.notes!.propagateObjectStateChangeToDescendents(newState);

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IDigitalMedia} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IDigitalMedia {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: DigitalMedia = Object.create(DigitalMedia.prototype);
    Object.assign(copyOfObject, this);

    // copy the contained objects
    // initialize for all digital media
    copyOfObject.initializeDigitalMediaArrays();

    if (this.images !== undefined) {
      copyOfObject.images = this.images.copy();
    }

    if (this.videos !== undefined && this.videos.length > 0) {
      copyOfObject.videos = this.videos.copy();
    }

    if (this.audios !== undefined && this.audios.length > 0) {
      copyOfObject.audios = this.audios.copy();
    }

    if (this.hyperLinks !== undefined && this.hyperLinks.length > 0) {
      copyOfObject.hyperLinks = this.hyperLinks.copy();
    }

    if (this.notes !== undefined && this.notes.length > 0) {
      copyOfObject.notes = this.notes.copy();
    }

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IDigitalMediaAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IDigitalMediaAsJson = super.toJSON(includeContainedObjects);

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // if there are any images in this instance, add their serialized representations
        if (this.images !== undefined && this.images.length > 0) {
          jsonObject.images = this.images.toJSON(includeContainedObjects) as Array<IImageLinkAsJson>;
        }

        // if there are any videos in this instance, add their serialized representations
        if (this.videos !== undefined && this.videos.length > 0) {
          jsonObject.videos = this.videos.toJSON(includeContainedObjects) as Array<IVideoLinkAsJson>;
        }

        // if there are any audios in this instance, add their serialized representations
        if (this.audios !== undefined && this.audios.length > 0) {
          jsonObject.audios = this.audios.toJSON(includeContainedObjects) as Array<IAudioLinkAsJson>;
        }

        // if there are any hyperLinks in this instance, add their serialized representations
        if (this.hyperLinks !== undefined && this.hyperLinks.length > 0) {
          jsonObject.hyperLinks = this.hyperLinks.toJSON(includeContainedObjects) as Array<IAudioLinkAsJson>;
        }

        // if there are any notes in this instance, add their serialized representations
        if (this.notes !== undefined && this.notes.length > 0) {
          jsonObject.notes = this.notes.toJSON(includeContainedObjects) as Array<IAudioLinkAsJson>;
        }
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IDigitalMediaAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IDigitalMediaAsJson, includeContainedObjects: boolean = true): IDigitalMedia {
    try {
      // create a new instance of this class
      let digitalMediaObject: DigitalMedia = Object.create(DigitalMedia.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      digitalMediaObject = super.fromJSONProtected(digitalMediaObject, jsonObject, includeContainedObjects);

      // initialize for all digital media
      digitalMediaObject.initializeDigitalMediaArrays();

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // if there are any images in the JSON object, add their deserialized representations to this instance
        if (jsonObject.images !== undefined && digitalMediaObject.images !== undefined) {
          digitalMediaObject.images.replaceWithJsonObjectsArray(jsonObject.images, includeContainedObjects);
        }

        // if there are any videos in the JSON object, add their deserialized representations to this instance
        if (jsonObject.videos !== undefined && digitalMediaObject.videos !== undefined) {
          digitalMediaObject.videos.replaceWithJsonObjectsArray(jsonObject.videos, includeContainedObjects);
        }

        // if there are any audios in the JSON object, add their deserialized representations to this instance
        if (jsonObject.audios !== undefined && digitalMediaObject.audios !== undefined) {
          digitalMediaObject.audios.replaceWithJsonObjectsArray(jsonObject.audios, includeContainedObjects);
        }

        // if there are any hyperLinks in the JSON object, add their deserialized representations to this instance
        if (jsonObject.hyperLinks !== undefined && digitalMediaObject.hyperLinks !== undefined) {
          digitalMediaObject.hyperLinks.replaceWithJsonObjectsArray(jsonObject.hyperLinks, includeContainedObjects);
        }

        // if there are any notes in the JSON object, add their deserialized representations to this instance
        if (jsonObject.notes !== undefined && digitalMediaObject.notes !== undefined) {
          digitalMediaObject.notes.replaceWithJsonObjectsArray(jsonObject.notes, includeContainedObjects);
        }
      }

      return digitalMediaObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/


}
