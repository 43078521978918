import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegistrationData } from '../../../dataObjects/models/registerAndLogin/RegistrationData';
import { userRegistrationStatusChange } from './userRegistrationStatusSlice';
import { loginStatusChange } from '../login/loginStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';

const initialState: IRegistrationData | null = null;

export const userRegistrationDataSlice = createSlice({
  name: 'userRegistrationData',
  initialState,
  reducers: {
    // action has a payload with IRegistrationData
    userRegistrationRequest(state, action) {
      // set userRegistration data into state for use during the userRegistration process
      return action.payload;
    },

    // action has a payload with IRegistrationData that has a masked password
    userRegistrationSuccess(state, action) {
      // set userRegistration data with masked password into state
      return action.payload;
    },

    userRegistrationDataClear(state, action) {
      return null; // action.payload;
    },
  },
  // set up extra reducer logic that will respond to actions from one or more other slices
  extraReducers: (builder) => {
    builder
      .addCase(userRegistrationStatusChange, (state, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
          case enumWorkflowState.Success:
            // do nothing
            break;

          case enumWorkflowState.Failure:
            // clear the userRegistration data from state
            state = null;
            break;
        }
      })
      .addCase(loginStatusChange, (state, action: PayloadAction<enumWorkflowState>) => {
        switch (action.payload) {
          case enumWorkflowState.Requested:
          case enumWorkflowState.InProgress:
          case enumWorkflowState.Failure:
            // do nothing
            break;

          case enumWorkflowState.Success:
            // clear the userRegistration data from state
            state = null;
            break;
        }
      })
  },
})

// Action creators are generated for each case reducer function
export const { userRegistrationRequest, userRegistrationSuccess, userRegistrationDataClear } = userRegistrationDataSlice.actions;

// export the core reducer for the slice
export const userRegistrationDataReducer = userRegistrationDataSlice.reducer;

export default userRegistrationDataSlice.reducer;
