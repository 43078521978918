import { IFirebaseAuthentication, FirebaseAuthentication } from "../../../cloudServices/googleFirebaseServices/authentication/FirebaseAuthentication";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';


export function logout(): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      let logoutSuccessful: boolean = false;

      // create a FirebaseAuthentication instance
      let firebaseAuthObj: IFirebaseAuthentication | null = new FirebaseAuthentication(FirebaseAppSingleton.getInstance().firebaseApp);

      await firebaseAuthObj?.logout();

      logoutSuccessful = true;

      // // *** FOR FUTURE USE (with Redux)
      // const actionPayload: IUserLoggedOutAction = {
      //   actionType: "LOGOUT_COMPLETED",
      //   ???: ???  // (User w/ UserSettings? -or- should firebase.auth.UserCredential be included?)
      // };
      // dispatcher.dispatch(actionPayload);

      // clear the localStorage persistent data from our application (the 'persist:root' key)
      // localStorage.removeItem('persist:root');
      // localStorage.clear();
      // window.localStorage.removeItem('persist:root');
      window.localStorage.clear();

      // // ** TEMPORARY -- REMOVE 
      // alert(`User logged out`);

      resolve(logoutSuccessful);

    } catch (error: any) {
      // alert(`Error. User logout failed: (${error})`);
      console.error(`Error. User logout failed: (${error})`);
      reject(error);
    }
  });
}