import { FirebaseApp, initializeApp } from 'firebase/app';
import { IFirebaseConfiguration, FirebaseConfiguration } from '../FirebaseConfiguration';
import { IBaseFirebase } from './';

/** 
 * @class BaseFirebase Provides a base class for all classes that will provide Firebase-related services
 */
export class BaseFirebase implements IBaseFirebase {
  /**
   * @method Constructor method
   * @param {FirebaseApp} firebaseApp A Firebase Application object that has been initialized, if provided
   */
  constructor(
    // firebaseApp?: FirebaseApp
    firebaseApp?: FirebaseApp
  ) {
    if (firebaseApp !== undefined) {
      this._firebaseApp = firebaseApp;
    } else {
      const firebaseConfig: IFirebaseConfiguration = new FirebaseConfiguration();
      // this._firebaseApp = firebase.initializeApp(firebaseConfig.toJSON());
      this._firebaseApp = initializeApp(firebaseConfig.toJSON());
      // this._firebaseApp.firestore().settings({ timestampsInSnapshots: true });
    }
  }

  /*-----------------------------------------------*/
  /**
   * @property {FirebaseApp} _firebaseApp A Firebase Application object that has been initialized, if provided
   */
  private _firebaseApp: FirebaseApp;

  /**
   * @method firebaseApp Getter method for the Firebase Application object
   */
  get firebaseApp() {
    return this._firebaseApp;
  }

  /**
   * @method firebaseApp Setter method for the Firebase Application object
   * @param {FirebaseApp} value Value to use in setting the Firebase Application object
   */
  set firebaseApp(value: FirebaseApp) {
    this._firebaseApp = value;
  }
  /*-----------------------------------------------*/

}
