import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ITopicItem } from '../../../dataObjects/models/topics/TopicItem';

const initialState: enumWorkflowState | null = null;

export const topicItemDeleteStatusSlice = createSlice({
  name: 'topicItemDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with ITopicItem
    topicItemDeleteRequest: (state, action: PayloadAction<ITopicItem>) => {
      topicItemDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    topicItemDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { topicItemDeleteRequest, topicItemDeleteStatusChange } = topicItemDeleteStatusSlice.actions;

// export the core reducer for the slice
export const topicItemDeleteStatusReducer = topicItemDeleteStatusSlice.reducer;

export default topicItemDeleteStatusSlice.reducer;