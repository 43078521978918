import { createSlice } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

const initialState: IMdbError | null = null;

export const userSaveFailureSlice = createSlice({
  name: 'userSaveFailure',
  initialState,
  reducers: {
    // action has a payload with an error
    userSaveFailureChange(state, action) {
      return action.payload;
    },
        
    // action has a payload with a null value
    userSaveFailureClear(state) {
      return null; // action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { userSaveFailureChange, userSaveFailureClear } = userSaveFailureSlice.actions;

// export the core reducer for the slice
export const userSaveFailureReducer = userSaveFailureSlice.reducer;

export default userSaveFailureSlice.reducer;