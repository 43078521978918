import { IPostalAddress, IPostalAddressAsJson } from ".";

/** 
 * @class PostalAddress Represents a Postal Address
 */
export class PostalAddress {
  /**
   * @method Constructor method
   * @param {string} address1 Address Line 1 information
   * @param {string} address2 Address Line 2 information
   * @param {string} cityTown City or town information
   * @param {string} stateProvince State or Province information
   * @param {string} zipPostalCode Zip Code or Postal Code information
   */
  constructor(
    address1: string,
    address2: string,
    cityTown: string,
    stateProvince: string,
    zipPostalCode: string,
  ) {
    this.address1 = address1;
    this.address2 = address2;
    this.cityTown = cityTown;
    this.stateProvince = stateProvince;
    this.zipPostalCode = zipPostalCode;
  }

  /*-----------------------------------------------*/
  /**
   * @property {string} _address1 Address Line 1 information
   */
  private _address1: string = '';

  /**
   * @method address1 Getter method for _address1
   */
  get address1() {
    return this._address1;
  }

  /**
   * @method address1 Setter method for _address1
   * @param {string} value is the input value for setting _address1
   */
  set address1(value: string) {
    this._address1 = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _address2 Address Line 2 information
   */
  private _address2: string = '';

  /**
   * @method address2 Getter method for _address2
   */
  get address2() {
    return this._address2;
  }

  /**
   * @method address2 Setter method for _address2
   * @param {string} value is the input value for setting _address2
   */
  set address2(value: string) {
    this._address2 = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _cityTown City or town information
   */
  private _cityTown: string = '';

  /**
   * @method cityTown Getter method for _cityTown
   */
  get cityTown() {
    return this._cityTown;
  }

  /**
   * @method cityTown Setter method for _cityTown
   * @param {string} value is the input value for setting _cityTown
   */
  set cityTown(value: string) {
    this._cityTown = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _stateProvince State or Province information
   */
  private _stateProvince: string = '';

  /**
   * @method stateProvince Getter method for _stateProvince
   */
  get stateProvince() {
    return this._stateProvince;
  }

  /**
   * @method stateProvince Setter method for _stateProvince
   * @param {string} value is the input value for setting _stateProvince
   */
  set stateProvince(value: string) {
    this._stateProvince = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _zipPostalCode Zip Code or Postal Code information
   */
  private _zipPostalCode: string = '';

  /**
   * @method zipPostalCode Getter method for _zipPostalCode
   */
  get zipPostalCode() {
    return this._zipPostalCode;
  }

  /**
   * @method zipPostalCode Setter method for _zipPostalCode
   * @param {string} value is the input value for setting _zipPostalCode
   */
  set zipPostalCode(value: string) {
    this._zipPostalCode = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IPostalAddress} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IPostalAddress {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IPostalAddress = Object.create(PostalAddress.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the class instance to a JSON representation
   */
  toJSON(): IPostalAddressAsJson {
    const jsonObject: IPostalAddressAsJson = {
      address1: (this.address1 === undefined ? "undefined" : this.address1),
      address2: (this.address2 === undefined ? "undefined" : this.address2),
      cityTown: (this.cityTown === undefined ? "undefined" : this.cityTown),
      stateProvince: (this.stateProvince === undefined ? "undefined" : this.stateProvince),
      zipPostalCode: (this.zipPostalCode === undefined ? "undefined" : this.zipPostalCode)
    };

    return jsonObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a class instance from a JSON representation
   */
  static fromJSON(jsonObject: IPostalAddressAsJson): IPostalAddress {
    // create a new instance of this class
    let postalAddressObject: PostalAddress = Object.create(PostalAddress.prototype);

    // assign all properties from the JSON object to the data members
    postalAddressObject.address1 = (jsonObject.address1 === "undefined" ? '' : jsonObject.address1);
    postalAddressObject.address2 = (jsonObject.address2 === "undefined" ? '' : jsonObject.address2);
    postalAddressObject.cityTown = (jsonObject.cityTown === "undefined" ? '' : jsonObject.cityTown);
    postalAddressObject.stateProvince = (jsonObject.stateProvince === "undefined" ? '' : jsonObject.stateProvince);
    postalAddressObject.zipPostalCode = (jsonObject.zipPostalCode === "undefined" ? '' : jsonObject.zipPostalCode);

    return postalAddressObject;
  }
  /*-----------------------------------------------*/

}
