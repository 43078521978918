import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { FirestoreObjectSharingRequestTrackerRepository_Ext, IFirestoreObjectSharingRequestTrackerRepository_Ext } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreObjectSharingRequestTrackerRepository';
import { enumSharingRequestStatus } from '../../../../dataObjects/enums';


/*-----------------------------------------------*/
/**
  * @function getRequestsForSharingObject_onSnapshot Makes an onSnapshot() request to firestore for retrieving all ObjectSharingRequestTracker 
  *          objects from the database where the sharingObjectId matches the one provided and, optionally, an array status values for the objects.
  * @param {typeUniqueId} sharingObjectId The Id of the shared object for which records are to be retrieved.
  * @param {(snapshot: QuerySnapshot<DocumentData>) => void} callback A callback function that is enabled to receive 
  *   a snapshot of data from firestore.
  * @param {Array<enumSharingRequestStatus>} statusValues (optional) A collection of status codes for qualifying the search (to be OR'd together, if provided)
  * @returns {Promise<() => void>} A callback for unsubscribing from the firebase onSnapshot request.
 */
export function getRequestsForSharingObject_onSnapshot(sharingObjectId: typeUniqueId,
  callback: (snapshot: QuerySnapshot<DocumentData>) => void,
  statusValues?: Array<enumSharingRequestStatus>): Promise<() => void> {
  return new Promise<() => void>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      const firestoreObjectSharingRequestTrackerRepository_Ext: IFirestoreObjectSharingRequestTrackerRepository_Ext = new FirestoreObjectSharingRequestTrackerRepository_Ext(firestoreObj);

      const unsubscribeCallback: () => void = await firestoreObjectSharingRequestTrackerRepository_Ext.getRequestsForSharingObject_onSnapshot(sharingObjectId, callback, statusValues);

      resolve(unsubscribeCallback);
    }
    catch (error: any) {
      reject(error);
    }
  });
}
