import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { typeUniqueId } from '../../../dataObjects/types';
import GenericPageContainer from '../GenericPageContainer/GenericPageContainer';
import { IUser } from '../../../dataObjects/models/users/User';
import { ISocialMediaPost, SocialMediaPost } from '../../../dataObjects/models/digitalMedia/SocialMediaPost';
import { IStoreState } from '../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById } from '../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumBeaconType, enumObjectPersistenceState, enumPersistableObjectType, enumWorkflowState } from '../../../dataObjects/enums';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../dataObjects/models/persistence/UserPersistenceData';
import { MessagesStringAssets, NotificationStringAssets, PageAndViewTitleStringAssets } from '../../../assets/stringAssets';
import SocialMediaPostForm from '../../forms/SocialMediaPostForm/SocialMediaPostForm';
import { ISaveSocialMediaPostWorkflowState } from '../../../uiMiddleware-redux/store/SaveSocialMediaPostWorkflowState';
import { ISaveSocialMediaPostRequest } from '../../../dataObjects/models/serviceRequests/socialMediaPost/SaveSocialMediaPostRequest';
import { AlertInfo, IAlertInfo } from '../../../dataObjects/models/alerts/AlertInfo';
import { enumAlertType } from '../../enums';
import { Beacon } from '../../../dataObjects/models/alerts/Beacon';
import { ICurrentUserContextData, useCurrentUserContext } from '../../providersAndContexts/currentUser';
import { useAppDispatch, useAppSelector } from '../../../uiMiddleware-redux/store/configureStore';
import { socialMediaPostSaveRequest, socialMediaPostSaveStatusClear, socialMediaPostSaveWorkflowStateChange } from '../../../uiMiddleware-redux/slices/socialMediaPost/socialMediaPostSaveStatusSlice';
import { alertInfoChange } from '../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';
import { beaconChange } from '../../../uiMiddleware-redux/slices/beacon/beaconSlice';

/**
 * @interface IManageSocialMediaPostPageProps Input properties for the ManageSocialMediaPostPage
 */
export interface IManageSocialMediaPostPageProps extends PropsWithChildren<unknown> {
  id?: typeUniqueId,  // if the Id is given, it means that the SocialMediaPost already exists
  parentId?: typeUniqueId  // if the parentId is given, it means that a new SocialMediaPost is to be created
}

/**
 * @function ManageSocialMediaPostPage A React Function Component for manageing (creating or editing) a SocialMediaPost.
 * @param {IManageSocialMediaPostPageProps} props Input properties
 */
const ManageSocialMediaPostPage: React.FC<IManageSocialMediaPostPageProps> = (props: IManageSocialMediaPostPageProps) => {

  ManageSocialMediaPostPage.displayName = "Manage SocialMediaPost Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const params = useParams();

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [socialMediaPostId, setSocialMediaPostId] = useState<typeUniqueId | undefined>(params.id);

  // define an effect that will set the socialMediaPostId anytime the params.id changes
  useEffect(() => {
    setSocialMediaPostId(params.id);
  }, [params.id]);

  // define an effect based on a change to the socialMediaPostId. Having a defined socialMediaPostId means that we will 
  // be editing an existing SocialMediaPost
  useEffect(() => {
    if (socialMediaPostId !== undefined) {
      // try to find an SocialMediaPost object with the Id given in the URL
      getSingleObjectById(socialMediaPostId, enumPersistableObjectType.SocialMediaPost, onSocialMediaPostFetched);
    }
  }, [socialMediaPostId]);

  // will be set to the 'parentId' provided in the URL parms. If a parentId was provided, it means that we will be creating a new object.
  const [socialMediaPostParentId, setSocialMediaPostParentId] = useState<typeUniqueId | undefined>(params.parentId);

  // define an effect that will set the socialMediaPostParentId anytime the params.parentId changes
  useEffect(() => {
    setSocialMediaPostParentId(params.parentId);
  }, [params.parentId]);

  // will be set to the socialMediaPost to be managed, either a new socialMediaPost or an existing one
  const [socialMediaPost, setSocialMediaPost] = useState<ISocialMediaPost | undefined>(undefined);

  // whether we are going to be managing a new socialMediaPost
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  const resetSaveSocialMediaPostStatus: () => void = useCallback(() => {
    dispatch(socialMediaPostSaveStatusClear());
  }, [dispatch])

  const resetAlertInfo: () => void = useCallback(() => {
    dispatch(alertInfoChange(null));
  }, [dispatch])

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    // clear areas in Redux state to start with a clean slate
    resetSaveSocialMediaPostStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveSocialMediaPostStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveSocialMediaPostStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // workflow state related to saving a SocialMediaPost
  const [saveSocialMediaPostWorkflowState, setSaveSocialMediaPostWorkflowState] = useState<ISaveSocialMediaPostWorkflowState | null | undefined>(undefined);

  const [successfullyCompletedPage, setSuccessfullyCompletedPage] = useState<boolean>(false);

  // define an effect that will process whenever the successfullyCompletedPage state changes
  useEffect(() => {
    if (successfullyCompletedPage) {
      // return to previous page
      navigate(-1);

      // dispatch to clear the socialMediaPost save status store state variable
      dispatch(socialMediaPostSaveWorkflowStateChange(null));

      // notify user of the successful operation
      // ensuring that it's not null or undefined, append the SocialMediaPost Title to the end of the success message
      const successMessage: string = MessagesStringAssets.socialMediaPost_SaveSuccess + (socialMediaPost ? ` (${socialMediaPost.description.substring(0, Math.min(29, socialMediaPost.description.length))})` : '');
      dispatch(beaconChange(new Beacon(undefined, enumBeaconType.Success, NotificationStringAssets.heading_SaveSocialMediaPost, successMessage)));
    }
  }, [successfullyCompletedPage, dispatch, socialMediaPost, navigate]);

  // get the Redux workflow state for saving a SocialMediaPost
  const currentSaveSocialMediaPostWorkflowState: ISaveSocialMediaPostWorkflowState | null | undefined = useAppSelector((state: IStoreState) => state.saveSocialMediaPostStatus);

  // whenever the workflow state changes, set the object's value into the local state
  useEffect(() => {
    setSaveSocialMediaPostWorkflowState(currentSaveSocialMediaPostWorkflowState);
  }, [currentSaveSocialMediaPostWorkflowState]);

  /**
   * @function prepareNewSocialMediaPost Prepares/creates a new socialMediaPost that will be used if user is requesting to create/add a new socialMediaPost.
   * @param parentChannel 
   */
  const prepareNewSocialMediaPost: (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => ISocialMediaPost = useCallback(
    (parentId: typeUniqueId, user: IUser, userSettings: IUserSettings) => {
      let newSocialMediaPost: ISocialMediaPost;

      const newSocialMediaPostId: typeUniqueId = RandomId.newId();
      const userName: string = `${userSettings.firstName} ${userSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(user.id, userName);
      newSocialMediaPost = new SocialMediaPost(user.id, newSocialMediaPostId, enumPersistableObjectType.DigitalMedia, parentId, enumObjectPersistenceState.New,
        '', '', userPersistenceData);

      return newSocialMediaPost;
    }, []
  );

  // define an effect based on a change to the socialMediaPostParentId & currentUser. Having a defined socialMediaPostParentId means that we will
  // be creating a new SocialMediaPost
  useEffect(() => {
    if (socialMediaPostParentId !== undefined && currentUser) {
      setSocialMediaPost(prepareNewSocialMediaPost(socialMediaPostParentId, currentUser, currentUser.userSettings));
    }
  }, [socialMediaPostParentId, currentUser, prepareNewSocialMediaPost]);

  /**
   * @function onSocialMediaPostFetched A callback function that will be used when requesting a data object to be managed
   * @param {ISocialMediaPost | undefined} socialMediaPost The data object to be managed
   */
  function onSocialMediaPostFetched(socialMediaPost: ISocialMediaPost | undefined): void {
    if (socialMediaPost !== undefined) {
      // set the returned SocialMediaPost to be the one we are editing
      setSocialMediaPost(socialMediaPost);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing object (default was set to 'true')
      setIsNewObject(false);
    }
  }

  /**
   * @method handleFormSubmit Handles a submit request from the form in this page
   * @param {ISaveSocialMediaPostRequest} saveSocialMediaPostRequest A Submit event
   */
  function handleFormSubmit(saveSocialMediaPostRequest: ISaveSocialMediaPostRequest): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        // dispatch an action to request saving the socialMediaPost
        dispatch(socialMediaPostSaveRequest(saveSocialMediaPostRequest));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  // prepare a variable that may hold alert info
  let alertInfo: IAlertInfo | null = null;

  // prepare a variable to indicate whether a save is in progress
  let saveInProgress: boolean = false;
  if (saveSocialMediaPostWorkflowState !== null && saveSocialMediaPostWorkflowState !== undefined) {

    if (saveSocialMediaPostWorkflowState.workflowState === enumWorkflowState.Success) {
      if (!successfullyCompletedPage) {
        // set flag to indicate that the page has been successfully completed, which will force a re-render to allow for finalizing work for the page
        setSuccessfullyCompletedPage(true);
      }
    } else if (saveSocialMediaPostWorkflowState.workflowState === enumWorkflowState.InProgress || saveSocialMediaPostWorkflowState.workflowState === enumWorkflowState.Requested) {
      // indicate that a save is in progress
      saveInProgress = true;
    } else if (saveSocialMediaPostWorkflowState.workflowState === enumWorkflowState.Failure) {
      let failureMessage = 'Unknown error';
      if (saveSocialMediaPostWorkflowState.workflowError && saveSocialMediaPostWorkflowState.workflowError instanceof Error) {
        failureMessage = saveSocialMediaPostWorkflowState.workflowError.message;
      }
      // create an AlertInfo object and dispatch a request to set the AlertInfo into Redux state
      alertInfo = new AlertInfo(true, enumAlertType.Error, failureMessage);
      dispatch(alertInfoChange(alertInfo));
    }
  }


  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageSocialMediaPost_New : PageAndViewTitleStringAssets.pageTitle_ManageSocialMediaPost_Existing}
      onCloseAlert={resetSaveSocialMediaPostStatus}
    >
      {socialMediaPost &&
        <SocialMediaPostForm
          socialMediaPost={socialMediaPost}
          saveRequestInProgress={saveInProgress}
          onSubmit={handleFormSubmit}
        />
      }

    </GenericPageContainer>
  );

} // const ManageSocialMediaPostPage...

export default ManageSocialMediaPostPage;
