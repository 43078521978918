import { DocumentData, Firestore, QuerySnapshot } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types"
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { IFirestoreSocialMediaPostRepository_Ext, FirestoreSocialMediaPostRepository_Ext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreSocialMediaPostRepository";


export function getSocialMediaPostsForIds_onSnapshot(ids: Array<typeUniqueId>, callback: (snapshot: QuerySnapshot<DocumentData>) => void): Promise<Array<() => void>> {
  return new Promise<Array<() => void>>(async (resolve, reject) => {
    try {
      // initialize a Firestore object instance
      const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

      const firestoreSocialMediaPostRepository_Ext: IFirestoreSocialMediaPostRepository_Ext = new FirestoreSocialMediaPostRepository_Ext(firestoreObj);

      // fetch the topics for the pertinent channelId
      const unsubscribeCallbacks: Array<() => void> = await firestoreSocialMediaPostRepository_Ext.getObjectsForIds_onSnapshot(ids, callback);

      resolve(unsubscribeCallbacks);
    }
    catch (error: any) {
      reject(error);
    }
  });
}