import { IUserIdAndEmailAddress, IUserIdAndEmailAddressAsJson } from '.';

/**
 * @class UserIdAndEmailAddress Represents a view model with a user's UserId, Email Address,
 */
export class UserIdAndEmailAddress implements IUserIdAndEmailAddress {

  /**
   * @method Constructor method
   * @param {string} userId Unique Id for a user instance
   * @param {string} emailAddress The user's email address
   */
  constructor(
    userId: string,
    emailAddress: string,
  ) {
    this._userId = userId;
    this._emailAddress = emailAddress;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} userId Unique Id for a user instance
   */
  private _userId: string;

  // getter for _userId
  get userId(): string {
    return this._userId;
  }

  // setter for _userId
  set userId(value: string) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} emailAddress The user's last name
   */
  private _emailAddress: string;

  /**
   * @method getter for _emailAddress
   */
  get emailAddress() {
    return this._emailAddress;
  }

  /**
   * @method setter for _emailAddress
   * @param {string} value for setting _emailAddress
   */
  set emailAddress(value) {
    this._emailAddress = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IMdbError} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserIdAndEmailAddress {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    const copyOfObject: IUserIdAndEmailAddress = Object.create(UserIdAndEmailAddress.prototype);
    Object.assign(copyOfObject, this);

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(): IUserIdAndEmailAddressAsJson {
    // prepare JSON object for return, including members from the base (super) class and
    // members of this class
    const jsonObject: IUserIdAndEmailAddressAsJson = {
      userId: this.userId,
      emailAddress: this.emailAddress,
    };

    return jsonObject;
  }
  /*-----------------------------------------------*/

}
