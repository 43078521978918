import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption } from '../../../types';
import { enumObjectPersistenceState, enumPersistableObjectType, enumPersistableObjectClassName } from '../../../enums';
import { INote, INoteAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { VersionAwarePersistable } from '../../persistence/VersionAwarePersistable';

/** 
 * @class Note A audio link that can be associated with something
 */
export class Note extends VersionAwarePersistable implements INote {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {string} title The title of the note
   * @param {string} noteContent The content of the note
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    title: string,
    noteContent: string,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.Note, enumPersistableObjectType.Note, id, parentObjectType, parentId, objectState, userPersistenceData);

    this.title = title;
    this.noteContent = noteContent;
  }


  /*-----------------------------------------------*/
  /**
   * @property {string} _title The title of the Note
   */
  private _title: string = '';

  /**
   * @method title Getter method for _title
   */
  get title(): string {
    return this._title;
  }

  /**
   * @method title Setter method for _title
   * @param {string} value The input value for setting _title
   */
  set title(value: string) {
    this._title = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} _noteContent The content for the Note
   */
  private _noteContent: string = '';

  /**
   * @method noteContent Getter method for _noteContent
   */
  get noteContent(): string {
    return this._noteContent;
  }

  /**
   * @method noteContent Setter method for _noteContent
   * @param {string} value The input value for setting _noteContent
   */
  set noteContent(value: string) {
    this._noteContent = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {INote} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): INote {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: INote = Object.create(Note.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): INoteAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: INoteAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.title = this.title;
      jsonObject.noteContent = this.noteContent;

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {INoteAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: INoteAsJson, includeContainedObjects: boolean = true): INote {
    try {
      // create a new instance of this class
      let noteObject: Note = Object.create(Note.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      noteObject = super.fromJSONProtected(noteObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      if (jsonObject.title) {
        noteObject.title = jsonObject.title;
      }

      if (jsonObject.noteContent) {
        noteObject.noteContent = jsonObject.noteContent;
      }
      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return noteObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
