import { take, put } from 'redux-saga/effects';
import { saveTopic } from '../../../firebaseServices/dataServices/dataServiceActions/topicActions';
import { topicSaveRequest, topicSaveStatusChange } from '../../slices/topic/topicSaveStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { topicSaveFailureChange } from '../../slices/topic/topicSaveFailureSlice';

/**
 * @function saveTopicRequestedSaga A saga workflow pertaining to a user having requested to save a topic.
 */
export function* saveTopicRequestedSaga() {
  // console.info('(saveTopicRequestedSaga) entered saveTopicRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the topicSaveRequest action to be dispatched
      const topicSaveRequestData = yield take(topicSaveRequest);
      const { payload: topic } = topicSaveRequestData;

      // make a request to save the topic
      yield saveTopic(topic)

      // dispatch an action to indicate that the save operation was successful
      yield put(topicSaveStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the save operation failed
      yield put(topicSaveStatusChange(enumWorkflowState.Failure));
      yield put(topicSaveFailureChange(error));
    }
  }
}
