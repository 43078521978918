import { IVersionAwarePersistableAsJson } from '../VersionAwarePersistable';
import { IVersionedObjectTrackingRecord, IVersionedObjectTrackingRecordAsJson } from '.';

/** 
 * @class VersionedObjectTrackingRecord Represents a record for tracking a versioned object for different types of operations
 * (Created, Updated, Deleted), including a timestamp, user information, and it also retains a copy of the object at the time 
 * of the operation.
 */
export class VersionedObjectTrackingRecord implements IVersionedObjectTrackingRecord {
  /**
   * @method Constructor method
  //  * @param {enumVersionedObjectTrackingOperationType} operationType The type of operation that was performed (Created, Updated, Deleted).
   * @param {Date} operationTimestamp When the operation is recorded to have occurred.
   * @param {IVersionAwarePersistableAsJson} trackedObjectAsJson The object details, in JSON format, at the time that the operation was performe
   */
  constructor(
    // operationType: enumVersionedObjectTrackingOperationType,
    operationTimestamp: Date,
    // performedByUserId: typeUniqueId,
    // performedByUsername: string,
    // performedOnUserDevice: string,
    // performedInDeviceLocale: enumLocale,
    trackedObjectAsJson: IVersionAwarePersistableAsJson
  ) {
    // this.operationType = operationType;
    this.operationTimestamp = operationTimestamp;
    // this.performedByUserId = performedByUserId;
    // this.performedByUsername = performedByUsername;
    // this.performedOnUserDevice = performedOnUserDevice;
    // this.performedInDeviceLocale = performedInDeviceLocale;
    this._trackedObjectAsJson = trackedObjectAsJson;
  }

  // /*-----------------------------------------------*/
  // /**
  //  * @property {enumVersionedObjectOperationType} _operationType The type of operation that was performed (Created, Updated, Deleted).
  //  */
  // private _operationType: enumVersionedObjectTrackingOperationType = enumVersionedObjectTrackingOperationType.Undefined;

  // /**
  //  * @method operationType is an optional getter method for _operationType
  //  */
  // get operationType() {
  //   return this._operationType;
  // }

  // /**
  //  * @method operationType is an optional setter method for _operationType
  //  * @param {enumVersionedObjectTrackingOperationType} value is the input value for setting _operationType
  //  */
  // set operationType(value: enumVersionedObjectTrackingOperationType) {
  //   this._operationType = value;
  // }
  // /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {Date} _operationTimestamp When the operation occurred
   */
  private _operationTimestamp: Date = new Date();

  /**
   * @method operationTimestamp is an optional getter method for _operationTimestamp
   */
  get operationTimestamp() {
    return this._operationTimestamp;
  }

  /**
   * @method operationTimestamp is an optional setter method for _operationTimestamp
   * @param {Date} value is the input value for setting _operationTimestamp
   */
  set operationTimestamp(value: Date) {
    this._operationTimestamp = value;
  }
  /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {typeUniqueId} _performedByUserId UserId of the user who performed the operation
  //  */
  // private _performedByUserId: typeUniqueId = '';

  // /**
  //  * @method performedByUserId is an optional getter method for _performedByUserId
  //  */
  // get performedByUserId(): typeUniqueId {
  //   return this._performedByUserId;
  // }

  // /**
  //  * @method performedByUserId is an optional setter method for _performedByUserId
  //  * @param {typeUniqueId} value is the input value for setting _performedByUserId
  //  */
  // set performedByUserId(value: typeUniqueId) {
  //   this._performedByUserId = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {string} _performedByUsername Username of the user who performed the operation.
  //  */
  // private _performedByUsername: string = '';

  // /**
  //  * @method performedByUsername is an optional getter method for _performedByUsername
  //  */
  // get performedByUsername() {
  //   return this._performedByUsername;
  // }

  // /**
  //  * @method performedByUsername is an optional setter method for _performedByUsername
  //  * @param {string} value is the input value for setting _performedByUsername
  //  */
  // set performedByUsername(value: string) {
  //   this._performedByUsername = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {string} _performedOnUserDevice User device on which the operation was performed (iPhone, Mac, PC) + browser
  //  */
  // private _performedOnUserDevice: string = '';

  // /**
  //  * @method performedOnUserDevice is an optional getter method for _performedOnUserDevice
  //  */
  // get performedOnUserDevice() {
  //   return this._performedOnUserDevice;
  // }

  // /**
  //  * @method performedOnUserDevice is an optional setter method for _performedOnUserDevice
  //  * @param {string} value is the input value for setting _performedOnUserDevice
  //  */
  // set performedOnUserDevice(value: string) {
  //   this._performedOnUserDevice = value;
  // }
  // /*-----------------------------------------------*/

  // /*-----------------------------------------------*/
  // /**
  //  * @property {enumLocale} performedInDeviceLocale The locale of the user device (e.g., 'en-US') when the operation was performed
  //  */
  // private _performedInDeviceLocale: enumLocale = enumLocale.English;

  // /**
  //  * @method performedInDeviceLocale is an optional getter method for performedInDeviceLocale
  //  */
  // get performedInDeviceLocale() {
  //   return this._performedInDeviceLocale;
  // }

  // /**
  //  * @method performedInDeviceLocale is an optional setter method for performedInDeviceLocale
  //  * @param {enumLocale} value is the input value for setting performedInDeviceLocale
  //  */
  // set performedInDeviceLocale(value: enumLocale) {
  //   this._performedInDeviceLocale = value;
  // }
  // /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {IVersionAwarePersistableAsJson} _trackedObjectAsJson The object details, in JSON format, at the time that the operation was performed
   */
  private _trackedObjectAsJson: IVersionAwarePersistableAsJson;

  /**
   * @method trackedObjectAsJson is an optional getter method for _trackedObjectAsJson
   */
  get trackedObjectAsJson(): IVersionAwarePersistableAsJson {
    return this._trackedObjectAsJson;
  }

  /**
   * @method trackedObjectAsJson is an optional setter method for _trackedObjectAsJson
   * @param {IVersionAwarePersistableAsJson} value is the input value for setting _trackedObjectAsJson
   */
  set trackedObjectAsJson(value: IVersionAwarePersistableAsJson) {
    this._trackedObjectAsJson = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(): IVersionedObjectTrackingRecordAsJson {
    try {
      // declare a JSON object to be returned
      const jsonObject: IVersionedObjectTrackingRecordAsJson = {
        // operationType: this.operationType,
        operationTimestamp: this.operationTimestamp.toISOString(),
        // performedByUserId: this.performedByUserId,
        // performedByUsername: this.performedByUsername,
        // performedOnUserDevice: this.performedOnUserDevice,
        // performedInDeviceLocale: this.performedInDeviceLocale,
        trackedObjectAsJson: this.trackedObjectAsJson
      };

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSONArray Serializes an array of instances of this class to an array of JSON objects
   * @returns An array of JSON objects with serialized data from the array of class instances.
   */
  static toJSONArray(trackingrecords: Array<IVersionedObjectTrackingRecord>): Array<IVersionedObjectTrackingRecordAsJson> {
    try {
      let array = new Array<IVersionedObjectTrackingRecordAsJson>();

      trackingrecords.forEach(trackingrecord => {
        const jsonObject = trackingrecord.toJSON();
        array.push(jsonObject);
      })

      return array;
    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IVersionedObjectTrackingRecordAsJson} jsonObject A JSON version of a class instance.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IVersionedObjectTrackingRecordAsJson): IVersionedObjectTrackingRecord {
    try {
      // create a new instance of this class
      let versionedObjectTrackingRecord: IVersionedObjectTrackingRecord = Object.create(VersionedObjectTrackingRecord.prototype);

      // copy all the fields from the json object 
      // versionedObjectTrackingRecord.operationType = jsonObject.operationType;
      versionedObjectTrackingRecord.operationTimestamp = new Date(jsonObject.operationTimestamp);
      // versionedObjectTrackingRecord.performedByUserId = jsonObject.performedByUserId;
      // versionedObjectTrackingRecord.performedByUsername = jsonObject.performedByUsername;
      // versionedObjectTrackingRecord.performedOnUserDevice = jsonObject.performedOnUserDevice;
      // versionedObjectTrackingRecord.performedInDeviceLocale = jsonObject.performedInDeviceLocale;
      versionedObjectTrackingRecord.trackedObjectAsJson = jsonObject.trackedObjectAsJson;

      return versionedObjectTrackingRecord;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method arrayFromJSONArray Converts an array of JSON objects to an array of instances of 'this' class
   * @param jsonObjectsArray 
   */
  static arrayFromJSONArray(jsonObjectsArray: Array<IVersionedObjectTrackingRecordAsJson>): Array<IVersionedObjectTrackingRecord> {
    try {
      // declare an empty array that will be returned
      let trackingRecordsArray: Array<IVersionedObjectTrackingRecord> = new Array<IVersionedObjectTrackingRecord>();

      // traverse the array of JSON objects...
      jsonObjectsArray.forEach(jsonObject => {
        // ... and call the PersistableJsonConverter.fromJSON method to convert from the JSON object
        const trackingRecord = VersionedObjectTrackingRecord.fromJSON(jsonObject);

        // add to the array of persistable objects
        trackingRecordsArray.push(trackingRecord);
      });

      // return the array of objects
      return trackingRecordsArray;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/
}
