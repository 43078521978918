import {
  UserCredential,
  User as firebaseUser
} from "firebase/auth";
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { enumCategoryType, enumLocale, enumObjectPersistenceState, enumPersistableObjectType, enumSharingPermission } from '../../../../dataObjects/enums';
import { IRegistrationData } from "../../../../dataObjects/models/registerAndLogin/RegistrationData";
import { IFirebaseAuthentication, FirebaseAuthentication } from "../../../cloudServices/googleFirebaseServices/authentication/FirebaseAuthentication";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { IUser, User, IUserAsJson } from '../../../../dataObjects/models/users/User';
import { IFirestoreUserRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreUserRepository';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { IUserSettings, UserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { IChannel, Channel, IChannelAsJson } from '../../../../dataObjects/models/channels/Channel';
import { typeUniqueId } from '../../../../dataObjects/types';
import { IFirestoreChannelRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreChannelRepository';
import { FirestoreDataRepositoryFactory } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory';
import { enumFirestoreDataRepositoryDataType } from '../../../cloudServices/googleFirebaseServices/database/firestore/enums';
import { IFirestoreBaseRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository';
import { Category, ICategory, ICategoryAsJson } from '../../../../dataObjects/models/categories/Category';
import { IUserSharingPermissions, UserSharingPermissions } from '../../../../dataObjects/models/collaboration/UserSharingPermissions';
import { ConstantStringAssets } from '../../../../assets/stringAssets';
import { IFirestoreCategoryRepository } from '../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository';
import { submitEmailRequestForAccountVerification } from "../../../dataServices/dataServiceActions/emailRequestActions";
import { enumMdbErrorType } from "../../../../errorObjects/enums";
import { MdbError } from "../../../../errorObjects/MdbError";


/**
 * @function registerUser Registers a new user account, given regisration data as a parameter
 * @param {IRegistrationData} registrationData Registration data to use in registering the user account
 * @returns {Promise<IRegistrationData>} A Promise with the registration, providing for an asynchronous process.
 */
export function registerUser(registrationData: IRegistrationData): Promise<IRegistrationData> {
  return new Promise<IRegistrationData>(async (resolve, reject) => {
    try {

      // if we haven't been provided with both email and password, reject
      if (!registrationData.email || !registrationData.password) {
        reject(new MdbError(`User registration requires both email and password`, enumMdbErrorType.RequiredInputIsMissing));
      } else {
        // create a FirebaseAuthentication instance
        let firebaseAuthObj: IFirebaseAuthentication | null = new FirebaseAuthentication(FirebaseAppSingleton.getInstance().firebaseApp);

        // testing whether setting persistence on firebase.auth() to LOCAL will allow offline registration
        // firebaseAuthObj.firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        // firebaseAuthObj.firebaseApp.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

        const userAccount: UserCredential = await firebaseAuthObj.registerUserAccount(registrationData.email, registrationData.password);

        // // *** FOR FUTURE USE (with Redux)
        // const actionPayload: IFirebaseUserCreatedAction = {
        //   actionType: "CREATED_FIREBASEUSER",
        //   registerData: registerData
        // };
        // dispatcher.dispatch(actionPayload);

        // ** TEMPORARY -- REMOVE 
        // alert(`User registered in Firebase (${registrationData.firstName} ${registrationData.lastName}: ${userAccount.user?.email})`);

        // ** NOTICE -- The following logic for adding a User record to the DB may be moved to another location
        const user: firebaseUser = userAccount.user!;

        // create User Persistence data
        const userPersistenceData: IUserPersistenceData =
          new UserPersistenceData(user.uid, `${registrationData.firstName} ${registrationData.lastName}`, 'web browser', enumLocale.English);

        // create UserSettings Persistence data
        const userSettingsPersistenceData: IUserPersistenceData =
          new UserPersistenceData(user.uid, `${registrationData.firstName} ${registrationData.lastName}`, 'web browser', enumLocale.English);

        // create UserSettings data
        const userSettingsId = RandomId.newId();
        const userSettings: IUserSettings =
          new UserSettings(user.uid, userSettingsId, enumPersistableObjectType.User, user.uid, enumObjectPersistenceState.New, userSettingsPersistenceData,
            registrationData.firstName, registrationData.lastName, registrationData.email, registrationData.mobilePhone, registrationData.preferredLocale as enumLocale);

        // create a User instance for adding to the DB
        const userForDb: IUser = new User(user.uid, user.uid, undefined, undefined, enumObjectPersistenceState.New, userPersistenceData, userSettings);

        // create a Channel Id that will be used for adding a Channel to the DB. 
        // Note: We create the Id here, because we'll use the Channel Id when creating the Category instance
        const channelId = Channel.userPrivateChannelIdFromUserId(user.uid); // the channel will be owned by the user and have an Id that matches the user's Id, but with '-Private' (means "Private Channel") appended

        // create an "Uncategorized" Category instance that can contain Channels for adding to the DB
        // const userCategoriesId: typeUniqueId = RandomId.newId();
        const userCategoriesId: typeUniqueId = user.uid + '-Uncategorized'; // the user's Uncategorized category will have an Id that is the same 
        // as the user's Id, with '-Uncategorized' appended
        const categoryForDb: ICategory = new Category(user.uid, userCategoriesId, enumPersistableObjectType.User, user.uid, enumObjectPersistenceState.New,
          enumCategoryType.Channel, ConstantStringAssets.uncategorizedCategoryName, ConstantStringAssets.uncategorizedCategoryDescription,
          ConstantStringAssets.uncategorizedCategoryBackground, ConstantStringAssets.uncategorizedCategoryBorder, ConstantStringAssets.uncategorizedCategoryFontColor,
          [channelId] /* array of Ids for child Channels, seeded with an Id that will point to the Private channel */, 1, userPersistenceData);

        // create a Channel instance for adding to the DB
        const channelPersistenceData: IUserPersistenceData =
          new UserPersistenceData(channelId, `${registrationData.firstName} ${registrationData.lastName}`, 'web browser', enumLocale.English);
        const channelName = `Private Channel (${registrationData.firstName} ${registrationData.lastName})`;
        const channelDescription = `Private Channel for user: (${registrationData.firstName} ${registrationData.lastName})`;
        const channelTopicNameAliasSingular = `Topic`;
        const channelTopicNameAliasPlural = `Topics`;
        // the user will have Admin privileges for his/her private channel
        const channelUserSharingPermissions: Array<IUserSharingPermissions> = [new UserSharingPermissions(user.uid, enumSharingPermission.Admin)];
        // const channelForDb: IChannel = new Channel(user.uid, channelId, undefined, undefined, enumObjectPersistenceState.New, channelName,
        //   channelDescription, channelUserSharingPermissions, channelPersistenceData, channelTopicNameAliasSingular, channelTopicNameAliasPlural, undefined);
        const channelForDb: IChannel = new Channel(user.uid, channelId, undefined, undefined, enumObjectPersistenceState.New, channelName,
          channelDescription, channelUserSharingPermissions, channelPersistenceData, channelTopicNameAliasSingular, channelTopicNameAliasPlural, userCategoriesId);

        // create a User record in the DB
        const userRepository: IFirestoreUserRepository =
          FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.User) as
          IFirestoreBaseRepository<IUser, IUserAsJson>;
        await userRepository.create(userForDb);

        // create a Channel record in the DB
        const channelRepository: IFirestoreChannelRepository =
          FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Channel) as
          IFirestoreBaseRepository<IChannel, IChannelAsJson>;
        await channelRepository.create(channelForDb);

        // create a Category record in the DB
        const categoryRepository: IFirestoreCategoryRepository =
          FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
          IFirestoreBaseRepository<ICategory, ICategoryAsJson>;
        await categoryRepository.create(categoryForDb);

        // call method to submit an Account Verification email request, which will trigger an email to be sent
        await submitEmailRequestForAccountVerification(user.uid, registrationData.email, registrationData.firstName ?? '', registrationData.lastName ?? '');
      }

      resolve(registrationData);
    } catch (error: any) {
      reject(error);
    }
  });
}