import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { typeUniqueId } from "../../../../dataObjects/types";
import { IUserIdAndEmailAddress, IUserIdAndEmailAddressAsJson, UserIdAndEmailAddress } from "../../../../dataObjects/models/users/UserIdAndEmailAddress";

/**
 * @function updateUserEmailAddressViaHttp Retrieves user account info via an Http request using an email address and, if found, returns an IUser object
 * @param emailAddress The user account email address to be used in the search operation
 * @returns An IUser object or undefined
 */
export function updateUserEmailAddressViaHttp(userId: typeUniqueId, emailAddress: string): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log('%c Entered updateUserEmailAddressViaHttp()', 'background: #055; color: #fff');

    // prepare object to pass into function call
    const userIdAndEmailAddress: IUserIdAndEmailAddress = new UserIdAndEmailAddress(userId, emailAddress);
    const userIdAndEmailAddressAsJson: IUserIdAndEmailAddressAsJson = userIdAndEmailAddress.toJSON();

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const updateUserEmailAddress =
        httpsCallable<IUserIdAndEmailAddressAsJson>(functions, 'updateUserEmailAddress');
        updateUserEmailAddress(userIdAndEmailAddressAsJson)
        .then((result) => {

          displayConsoleLogs && console.log(`%c updateUserEmailAddressViaHttp(). Returned from Http call. result: ${JSON.stringify(result)}`, 'background: #055; color: #fff');

          resolve();
        });
    } catch (error: any) {
      alert(`Error. userAccountActivities.updateUserEmailAddressViaHttp failed: (${error})`);
      reject(error);
    }
  });
}
