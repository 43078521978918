import { arrayUnion, collection, deleteDoc, doc, DocumentData, DocumentReference, DocumentSnapshot, Firestore, getDoc, getDocs, query, Query, QuerySnapshot, setDoc, updateDoc, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames } from '../enums';
import { JsonConverter } from '../../../../../../dataObjects/utilities/JsonConverter';
import { IFirestorePersistenceMetadataRepository } from '.';
import { IPersistenceMetadata, IPersistenceMetadataAsJson, PersistenceMetadata } from '../../../../../../dataObjects/models/persistence/PersistenceMetadata';
import { IVersionAwarePersistableAsJson } from '../../../../../../dataObjects/models/persistence/VersionAwarePersistable';
import { IVersionedObjectTrackingRecord, IVersionedObjectTrackingRecordAsJson, VersionedObjectTrackingRecord } from '../../../../../../dataObjects/models/persistence/VersionedObjectTrackingRecord';
import { MdbError } from '../../../../../../errorObjects/MdbError';
import { enumMdbErrorType } from '../../../../../../errorObjects/enums';

/** 
 * @class FirestorePersistenceMetadataRepository Manages database repository operations related to a PersistenceMetadata
 * *** IMPORTANT NOTE: FirestorePersistenceMetadataRepository differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestorePersistenceMetadataRepository is focused on tracking instances of other repository data types.
 *   Therefore, 
 *     1) FirestorePersistenceMetadataRepository does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestorePersistenceMetadataRepository.
 */
export class FirestorePersistenceMetadataRepository implements IFirestorePersistenceMetadataRepository {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method initiateTracking Initiates tracking on a versioned object.
   * @param {IVersionAwarePersistableAsJson} trackedObjectAsJson The initial version of the object to be tracked.
   * @returns {Promise<void>} A Promise, to provide asynchronicity, with no value (void)
   */
  initiateTracking(trackedObjectAsJson: IVersionAwarePersistableAsJson): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // get a reference to the appropriate collection
        const persistenceMetadataCollection = collection(this.firestoreDb, enumFirestoreCollectionNames.PersistenceMetadataCollection);

        // create a PersistenceMetadata instance
        const persistenceMetadata: IPersistenceMetadata = new PersistenceMetadata(undefined, trackedObjectAsJson);

        // convert the IPersistenceMetadata object to a JSON object
        const persistenceMetadataAsJson: IPersistenceMetadataAsJson = persistenceMetadata.toJSON(true);

        // insert a persistenceMetadata document into the collection, using the embedded tracked object's Id (the parentId) as the primary key
        // await persistenceMetadataCollection.doc(persistenceMetadata.parentId).set(persistenceMetadataAsJson);
        await setDoc(doc(persistenceMetadataCollection, persistenceMetadata.parentId), persistenceMetadataAsJson);

        // TODO: Save this updated version (JSON representation) to associated PersistenceMetadata

        resolve();

      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method appendNewVersion Appends a new version of the object for tracking
   * @param {IVersionAwarePersistableAsJson} trackedObjectAsJson The next version of the object to be tracked
   * @returns {Promise<void>} A Promise, to provide asynchronicity, with no value (void)
   */
  appendNewVersion(trackedObjectAsJson: IVersionAwarePersistableAsJson): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // call get() function to get the object, both to ensure it exists, and to obtain the full object representation
        const existingPersistenceMetadata: IPersistenceMetadata | undefined = await this.get(trackedObjectAsJson.id);

        if (existingPersistenceMetadata === undefined) {
          // reject(new MdbError(`Unable to locate a PersistenceMetadata record with the given Tracked Object Id: (${trackedObjectAsJson.id})`));

          // Since an expected PersistenceMetadata object doesn't currently exist (may have been inadvertently deleted from Firestore),
          // automatically initiate tracking on the current object (creating a new PersistenceMetadata object).
          // In other words, self-correct for a missing PersistenceMetadata object.
          this.initiateTracking(trackedObjectAsJson);
        } else {
          // ensure that the objectType and className of the input parameter are a proper match
          if (trackedObjectAsJson.className !== existingPersistenceMetadata.trackedObjectClassName ||
            trackedObjectAsJson.objectType !== existingPersistenceMetadata.parentObjectType
          ) {
            reject(new MdbError(`The ClassName and ObjectType of new version of the Tracked Object are inconsistent with the initiated version. className: (${trackedObjectAsJson.className}); objectType: (${trackedObjectAsJson.objectType})`, enumMdbErrorType.InconsistentData));
          } else {
            // get a reference to the appropriate collection
            const persistenceMetadataCollection = collection(this.firestoreDb, enumFirestoreCollectionNames.PersistenceMetadataCollection);

            // create a VersionedObjectTrackingRecord instance
            const trackingRecord: IVersionedObjectTrackingRecord = new VersionedObjectTrackingRecord(new Date(), trackedObjectAsJson);

            // convert the VersionedObjectTrackingRecord instance to JSON
            const trackingRecordAsJson: IVersionedObjectTrackingRecordAsJson = trackingRecord.toJSON();

            // update the document by adding another tracking record element to the objectTrackingRecords array
            // await persistenceMetadataCollection.doc(trackedObjectAsJson.id).update({ objectTrackingRecords: firebase.firestore.FieldValue.arrayUnion(trackingRecordAsJson) });
            await updateDoc(doc(persistenceMetadataCollection, trackedObjectAsJson.id), { objectTrackingRecords: arrayUnion(trackingRecordAsJson) });
          }
        }

        resolve();

      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method get Retrieves a single object from the database.
   * @param {typeUniqueId} trackedObjectId The Id of the object being tracked.
   * @returns {Promise<IPersistenceMetadata | undefined>} A Promise (to provide asynchrounous capability) with an object instance (if
   * an object was found with the given Id) or 'undefined' (if an object wasn't found with the given Id).
   */
  get(trackedObjectId: typeUniqueId): Promise<IPersistenceMetadata | undefined> {
    return new Promise<IPersistenceMetadata | undefined>(async (resolve, reject) => {
      try {
        // object to return
        let persistenceMetadata: IPersistenceMetadata | undefined = undefined;

        // get a reference to the appropriate collection
        const persistenceMetadataCollection = collection(this.firestoreDb, enumFirestoreCollectionNames.PersistenceMetadataCollection);

        // attempt to get a reference to a document with the given Id
        const docRef: DocumentReference<DocumentData> = await doc(persistenceMetadataCollection, trackedObjectId);
        // attempt to retrieve the document
        const docSnapshot: DocumentSnapshot<DocumentData> = await getDoc(docRef);
        // if a document was found (exists)...
        if (docSnapshot.exists()) {
          // fetch the data from the document, which should be a JSON version of the correct type of object
          const persistenceMetadataAsJson: IPersistenceMetadataAsJson = docSnapshot.data() as IPersistenceMetadataAsJson;

          // convert the JSON object to a Typescript object
          persistenceMetadata = JsonConverter.fromJSON(PersistenceMetadata, persistenceMetadataAsJson, true);
        }

        resolve(persistenceMetadata);

      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method getAllIdsForOwner Retrieves just the Ids for all objects from the database associated with the owner (user) of those objects.
   * @param {typeUniqueId} ownerId The unique Id of the owner of the objects to retrieve.
   * @returns {Promise<TEntity | undefined>} A Promise (to provide asynchrounous capability) with an array of typeUniqueIds (if
   * one or more objects were found to be associated with the given ownerId) or 'undefined' (if no objects were found to
   * be associated with the given ownerId).
   */
  getAllIdsForOwner(
    ownerId: typeUniqueId
  ): Promise<Array<typeUniqueId> | undefined> {
    return new Promise<Array<typeUniqueId> | undefined>(
      async (resolve, reject) => {
        try {
          // array of Ids to return
          let persistenceMetadataIds: Array<typeUniqueId> | undefined = undefined;

          // get a reference to the appropriate collection
          const persistenceMetadataCollection = collection(this.firestoreDb, enumFirestoreCollectionNames.PersistenceMetadataCollection);

          // proceed to get all documents with the ownerId, starting with a query
          const queryDocData: Query<DocumentData> =
            query(persistenceMetadataCollection,
              where("ownerId", "==", ownerId)
            );

          // next, get a snapshot of the document references resulting from executing the query (there should be, at most, only 1 document in the case of a AudioLink)
          const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(queryDocData);

          // now, from the snapshot, get the data for each doc and add just the doc ID into an array
          persistenceMetadataIds = [];
          querySnapshot.forEach((doc) => {
            persistenceMetadataIds!.push(doc.id);
          });

          resolve(persistenceMetadataIds);
        } catch (error: any) {
          reject(error);
        }
      }
    );
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expunge Expunges (erases) the PersistenceMetadata details associated with the Id of the object being tracked.
   * @param trackedObjectId The Id of the object being tracked.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is 
   * thrown if an object is not found with the given Id.
   */
  expunge(trackedObjectId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // get a reference to the collection
        const persistenceMetadataCollection = collection(this.firestoreDb, enumFirestoreCollectionNames.PersistenceMetadataCollection);

        // call get() function to get the object, both to ensure it exists, and to obtain the full object representation
        const persistenceMetadata: IPersistenceMetadata | undefined = await this.get(trackedObjectId);

        if (persistenceMetadata !== undefined) {
          // Attempt to delete a document with the given Id. An error will be thrown if the delete operation 
          // fails for any reason
          await deleteDoc(doc(persistenceMetadataCollection, trackedObjectId));
        }

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method expungeAllForOwner Expunges (completely removes) all objects in the database related to a given owner (user).
   * @param {typeUniqueId} ownerId The unique Id of the owner for the objects to be expunged.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no value (a void). No error is
   * thrown if no objects are found to be related to the given ownerId.
   */
  expungeAllForOwner(ownerId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      // call getAllIdsForOwner() method to get all of the object Ids for the given ownerId
      const objectIds: Array<typeUniqueId> | undefined = await this.getAllIdsForOwner(ownerId);

      // if any object Ids were found...
      if (objectIds !== undefined) {
        // iterate the array of Ids and call expunge() for each
        objectIds.forEach((id) => this.expunge(id));
      }

      resolve();
    });
  }
  /*-----------------------------------------------*/
}
