import { useContext } from 'react';
import { AppThemesContext, IAppThemesContextData } from '.';

/**
 * @hook useAppThemeContext  A custom React hook that simplifies the process for obtaining the Theme information
 *       associated with the current user value from within a React component tree that includes an 
 *       AppThemesProvider at a higher level in the tree.
 */
 export const useAppThemesContext = (): IAppThemesContextData => {

  // whether to display console logs (console.log statements)
  const displayConsoleLogs: boolean = false;

  displayConsoleLogs && console.log('%c Entered/Refreshed useAppThemesContext hook', 'background: #008080; color: #fff');

  // get the context data
  const appThemesContextData: IAppThemesContextData = useContext(AppThemesContext);

  displayConsoleLogs && console.log(`%c In useAppThemesContext. appThemesContextData: ${JSON.stringify(appThemesContextData)}`, 'background: #008080; color: #fff');

  return appThemesContextData;
}
