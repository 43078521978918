import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IChannel, Channel } from '../../../../dataObjects/models/channels/Channel';
import { typeUniqueId } from '../../../../dataObjects/types';
import { cardGridViewItemStyles } from '../../../styles/views/cardGridViewItemStyles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  List as ListIcon,
  Share as ShareIcon,
} from '@mui/icons-material';
import { ICategory } from '../../../../dataObjects/models/categories/Category';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IChannelViewModel } from '../../../../dataObjects/viewModels/channelViewModel';
import { IUserCategoriesAndChannelsContextData, useUserCategoriesAndChannelsContext } from '../../../providersAndContexts/userCategoriesAndChannels';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { IUserAccessPermissionsForObject, UserAccessPermissionsForObject } from '../../../../dataObjects/models/collaboration/ObjectUserPermissions';
import { TooltipStringAssets } from '../../../../assets/stringAssets';


export interface IChannelsCardGridViewItemProps extends PropsWithChildren<unknown> {
  currentUser: IUser;
  userPermissions: IUserAccessPermissionsForObject;
  channelViewModel: IChannelViewModel;
  onDelete: (channel: IChannel) => void;
}


export const ChannelsCardGridViewItem: React.FC<IChannelsCardGridViewItemProps> = (props: IChannelsCardGridViewItemProps) => {

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = false;

  // const { onDelete, channel, getUserNameForOwnerId } = props;
  const { currentUser, userPermissions, channelViewModel, onDelete } = props;

  const classes = cardGridViewItemStyles();

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // // use a custom hook to get the Current User information from a CurrentUserContext/Provider higher up in the component tree
  // const currentUserContextData: ICurrentUserContextData = useCurrentUserContext();
  // const currentUser: IUser | undefined = currentUserContextData.currentUser;

  // get UserCategoriesAndChannelsContextData from the UserCategoriesAndChannelsProvider using a custom hook (useUserCategoriesAndChannelsContext)
  // we'll end up using it to set the current channel
  const userCategoriesAndChannelsContext: IUserCategoriesAndChannelsContextData = useUserCategoriesAndChannelsContext();

  // will hold the name of the channel's owner to be displayed ("Me" if the channel is owned by the current user)
  const [channelOwnerName, setChannelOwnerName] = useState<string>("");

  // will be set to the either the Category associated with the Channel or to a new "Uncategorized" Category
  const [category, setCategory] = useState<ICategory | undefined>(undefined);

  // will be set to the either the Category associated with the Channel or to a new "Uncategorized" Category
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  // code to execute whenever the value of either the 'categories' object or 'channelViewModel' object changes
  useEffect(() => {
    if (categories && channelViewModel) {
      // determine which category indicates that it is the parent category of this channel
      // loop through the categories...
      let associatedCategory: ICategory | undefined = undefined;
      for (let idxCategory = 0; idxCategory < categories.length; idxCategory++) {
        // in the current category in the loop, search for a child with the channel.id value
        let idxChannel = categories[idxCategory].children.findIndex(childChannelId => channelViewModel.channel.id === childChannelId);

        // if a non-private channel wasn't found, check for a match for a private channel id
        if (idxChannel === -1) {
          // try to find a channel in the children collection that matches the formulated privateChannelId
          idxChannel = categories[idxCategory].children.findIndex(childChannelId => channelViewModel.channel.id === Channel.userPrivateChannelIdFromNonPrivateId(childChannelId));
        }

        // if a child was found with the channel.id, we have found the category to which the channel is associated, so set this as the category in state 
        // and break from the 'for' loop
        if (idxChannel !== -1) {
          associatedCategory = categories[idxCategory];
          break;
        }
      }

      // set the associated category to be either the default of 'undefined' or a category that was found to have the channel as a child
      setCategory(associatedCategory);

      // set the Channel Owner's Name
      let deducedChannelOwnerName = '';
      if (channelViewModel.ownerUser && channelViewModel.ownerUser.userSettings) {
        const channelOwnerUserSettings: IUserSettings = channelViewModel.ownerUser?.userSettings;
        deducedChannelOwnerName = `${channelOwnerUserSettings.firstName} ${channelOwnerUserSettings.lastName}`
      }
      setChannelOwnerName(deducedChannelOwnerName);
      displayConsoleLogs && console.log(`%c ChannelsCardGridViewItem. useEffect for [categories, channelViewModel, setChannelOwnerName]. deducedChannelOwnerName: ${deducedChannelOwnerName}`, 'background: #0f0; color: #000');
    }
  }, [categories, channelViewModel, setChannelOwnerName]);

  // memoized function to prepare the ChannelId for linking to a collection of topics that can be selected for drilldown
  const prepareChannelIdForTopicsLink: () => typeUniqueId = useCallback((): typeUniqueId => {
    // we need to prepare the Id that will be used with links, since a Channel could be a Private Channel and 
    // would need to have its Id converted to the UserId. So, if the Id is for a Private Channel, convert to a UserId.
    const channelIdForTopicsLink: typeUniqueId = Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id) ? Channel.userIdFromUserPrivateChannelId(channelViewModel.channel.id) : channelViewModel.channel.id;

    return channelIdForTopicsLink;
  }, [channelViewModel]);

  // memoized function that handles click for drilldown to topics
  const handleTopicsDrilldownClicked: () => void = useCallback((): void => {
    // first, set the current channel within the userCategoriesAndChannelsContext
    if (userCategoriesAndChannelsContext.actions.setCurrentChannel) {
      userCategoriesAndChannelsContext.actions.setCurrentChannel(channelViewModel.channel);
    }

    // then, use the navigate object to navigate to the Topics page
    navigate(`/${prepareChannelIdForTopicsLink()}/topics`)
  },
    [navigate, prepareChannelIdForTopicsLink, channelViewModel],
  );

  return (
    <>
      {/* <Card classes={{ root: classes.card }} elevation={6}> */}
      {/* <Card elevation={6} className={classes.card}> */}
      <Card elevation={6} className={classes.card}>
        <div style={{
          margin: '5px', paddingLeft: '5px', paddingRight: '5px',
          backgroundColor: `${category?.displayBackground}`, border: `1px solid ${category?.displayBorder}`, color: `${category?.displayFontColor}`
        }}>
          <Typography variant='caption' align='left'>
            {category?.name}
          </Typography>
        </div>

        {/* Note: Couldn't get proper behavior from <CardHeader>, so will just display a title via <Typography> */}
        <Typography className={classes.cardTitle} variant='h6' align='center'>
          {channelViewModel.channel.name}
        </Typography>

        <CardContent>
          {/* <Typography variant="body1" color="textPrimary" component="p"> */}
          <Typography className={classes.cardDescription} variant="body1">
            {channelViewModel.channel.description}
          </Typography>

          <div className={classes.drillDownButtonContainer}>
            <Tooltip
              // the tooltip for the drilldown button will either be "Drill down" (if user has sufficient permission) or "Drill down disallowed" (insufficient permission)
              title={!userPermissions.hasEditPermission ? TooltipStringAssets.drillDown_Disallowed : TooltipStringAssets.drillDown}
              arrow
            >
              <span>
                <Button
                  // className={classes.drillDownIconButton}
                  className={classes.drillDownButton}
                  aria-label="topics"
                  variant='contained'
                  // color='primary'
                  size='medium'
                  startIcon={<ListIcon />}
                  // disable if user doesn't have View Permission
                  disabled={!userPermissions.hasViewPermission}
                  onClick={() => handleTopicsDrilldownClicked()}
                >
                  {`View ${channelViewModel.channel.topicNameAliasPlural}`}
                </Button>
              </span>
            </Tooltip>
          </div>

        </CardContent>

        <CardActions className={classes.cardActionsContainer}>
          <Tooltip
            // the tooltip for the edit button will either be "Edit" (if user has sufficient permission) or "Edit disallowed" (insufficient permission)
            title={!userPermissions.hasEditPermission ? TooltipStringAssets.delete_Disallowed : TooltipStringAssets.delete}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.editIconButton}`}
                size="small"
                aria-label="edit"
                // this button will be disabled if the user doesn't have Edit permissions
                disabled={!userPermissions.hasEditPermission}
                onClick={() => navigate(`/channel/${channelViewModel.channel?.id}`)}>
                <EditIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            // the tooltip for the delete button will either be "Delete" (for a non-private Channel) or "Delete disallowed" (for a private Channel)
            title={(!userPermissions.hasEditPermission || Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id)) ? "Delete disallowed" : "Delete"}
            arrow
          >
            <span>
              <IconButton
                className={`${classes.actionIconButton} ${classes.deleteIconButton}`}
                size="small"
                aria-label="delete"
                // this button will be disabled if the user doesn't have Edit permissions -OR- the Channel is a private Channel
                disabled={!userPermissions.hasEditPermission || Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id)}
                onClick={() => onDelete(channelViewModel.channel)} >
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </CardActions>

        <Typography className={classes.cardOwnerSection} variant="body2">
          {`Owner: ${channelOwnerName}`}
          <>
            {/* If the channel's ownerId is the same as the current user's Id -AND-
              if this isn't the user's Private Channel and the channel's owner is the current user, display the Share button */}
            {/* {(currentUser?.id === channel.ownerId && !Channel.isChannelIdForPrivateChannel(channel.id)) && */}
            {(currentUser.id === channelViewModel.channel.ownerId) && !Channel.isChannelIdForPrivateChannel(channelViewModel.channel.id) &&
              <Tooltip
                // the tooltip for the delete button will either be "Delete" (for a non-private Channel) or "Delete disallowed" (for a private Channel)
                title="Share"
                arrow
              >
                <span>
                  <IconButton
                    className={`${classes.actionIconButton} ${classes.shareIconButton}`}
                    size="small"
                    aria-label="edit"
                    onClick={() => navigate(`/channelSharing/${channelViewModel.channel.id}`)}>
                    <ShareIcon />
                  </IconButton>
                </span>
              </Tooltip>

            }
          </>

        </Typography>

      </Card>
    </>
  );

}