import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchHyperLinksFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchHyperLinksFailureSlice = createSlice({
  name: 'fetchHyperLinksFailure',
  initialState,
  reducers: {
    setFetchHyperLinksFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchHyperLinksFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchHyperLinksFailure, clearFetchHyperLinksFailure } = fetchHyperLinksFailureSlice.actions;

// export the core reducer for the slice
export const fetchHyperLinksFailureReducer = fetchHyperLinksFailureSlice.reducer;

export default fetchHyperLinksFailureSlice.reducer;