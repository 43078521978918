import { ParsedPhoneNumber, parsePhoneNumber } from 'awesome-phonenumber';

/**
 * @function normalizePhoneNumber Normalizes a raw phone number to an international format
 * @param rawPhoneNumber
 */
export const normalizePhoneNumber: (rawPhoneNumber: string) => string = (rawPhoneNumber: string) => {

  let phoneNumberToReturn: string = rawPhoneNumber;

  const normalizedPhoneNumber: ParsedPhoneNumber = parsePhoneNumber(rawPhoneNumber);

  // if we were able to normalize the phone number, format it as international phone number
  if (normalizedPhoneNumber.valid) {
    phoneNumberToReturn = normalizedPhoneNumber.number.international;
  }

  return phoneNumberToReturn;
}

