import { take, put } from 'redux-saga/effects';
import { actionTypeEnums, deleteTopicFailure, deleteTopicSuccess } from '../../actions';
import { deleteTopic } from '../../../firebaseServices/dataServices/dataServiceActions/topicActions';
import { topicDeleteRequest, topicDeleteStatusChange } from '../../slices/topic/topicDeleteStatusSlice';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { topicDeleteFailureChange } from '../../slices/topic/topicDeleteFailureSlice';

/**
 * @function deleteTopicRequestedSaga A saga workflow pertaining to a user having requested to delete a topic.
 */
export function* deleteTopicRequestedSaga() {
  // console.info('(deleteTopicRequestedSaga) entered deleteTopicRequestedSaga');

  // loop to keep the saga running throughout the life of the application
  while (true) {
    try {
      // wait on the topicDeleteRequest action to be dispatched
      const topicDeleteRequestData = yield take(topicDeleteRequest);
      const { payload: topic } = topicDeleteRequestData;

      // make a request to delete the topic
      yield deleteTopic(topic)

      // dispatch an action to indicate that the delete operation was successful
      yield put(topicDeleteStatusChange(enumWorkflowState.Success));
    } catch (error: any) {
      // dispatch an action to indicate that the delete operation failed
      yield put(topicDeleteStatusChange(enumWorkflowState.Failure));
      yield put(topicDeleteFailureChange(error));
    }
  }
}
