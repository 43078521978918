import { collection, CollectionReference, deleteDoc, doc, DocumentData, Firestore, getDocs, query, Query, QuerySnapshot, where } from 'firebase/firestore';
import { typeUniqueId } from '../../../../../../dataObjects/types';
import { enumFirestoreCollectionNames } from '../enums';
import { IFirestoreEmailRequestRepository_Ext } from '.';

/** 
 * @class FirestoreUserRepository_Ext Provides extended functionality beyond the FirestoreUserRepository class
 * *** IMPORTANT NOTE: FirestoreUserRepository_Ext differs from other types of repositories. Instead of working with individual instances
 *   for creating, update, deleting, the FirestoreUserRepository_Ext is focused on other features.
 *   Therefore, 
 *     1) FirestoreUserRepository_Ext does not extend FirestoreBaseRepository<> as other repository types do; and
 *     2) The FirestoreDataRepositoryFactory cannot be used to instantiate instances of FirestoreUserRepository_Ext.
 */
export class FirestoreEmailRequestRepository_Ext implements IFirestoreEmailRequestRepository_Ext {

  /**
   * @method Constructor method
   * @param {Firestore} firestoreDb A Firestore DB Context
   */
  constructor(
    firestoreDb: Firestore
  ) {
    this._firestoreDb = firestoreDb;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Firestore} _firestoreDb A reference to the configured Firestore DB
   */
  private _firestoreDb: Firestore;

  /**
   * @method firestoreDb Getter method for _firestoreDb
   */
  get firestoreDb(): Firestore {
    return this._firestoreDb;
  }

  /**
   * @method firestoreDb Setter method for _firestoreDb
   * @param {Firestore} value The value to be used in setting _firestoreDb
   */
  set firestoreDb(value: Firestore) {
    this._firestoreDb = value;
  }

  /*-----------------------------------------------*/
  /**
   * @method expungeEmailRequestsForUserId Expunges all Email Request records that have an Id that begins with the given userId.
   * @param {typeUniqueId} userId The userId to match.
   * @returns {Promise<void>} A Promise (to provide asynchrounous capability) with no return value.
   */
  expungeEmailRequestsForUserId(userId: typeUniqueId): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        // get a reference to the collection
        const emailRequestCollection: CollectionReference<DocumentData> = collection(this.firestoreDb, enumFirestoreCollectionNames.EmailRequestCollection);

        // search for all records in the EmailRequest collection with an Id that begins with the given userId
        // Firestore does not support "Begins With" (nor other similar wildcard searches), but we can use a technique to 
        // simulate "Begins With" when working with Firestore.
        // see https://stackoverflow.com/questions/46573804/firestore-query-documents-startswith-a-string 
        const strSearch = userId;
        const strlength = strSearch.length;
        const strFrontCode = strSearch.slice(0, strlength - 1);
        const strEndCode = strSearch.slice(strlength - 1, strSearch.length);

        const startcode = strSearch;
        const endcode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1);

        // prepare query to search for all documents that begin with the userId
        const documentQuery: Query<DocumentData> =
          query(emailRequestCollection,
            where("id", ">=", startcode),
            where("id", "<", endcode)
          );

        // next, get a snapshot of the document references resulting from executing the query
        const querySnapshot: QuerySnapshot<DocumentData> = await getDocs(documentQuery);

        // traverse the documents found and expunge (deleteDoc) each of them
        querySnapshot.forEach(async (document) => {
          await deleteDoc(doc(emailRequestCollection, document.id));
        });

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }
  /*-----------------------------------------------*/

}
