import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { INote } from '../../../dataObjects/models/digitalMedia/Note';

const initialState: enumWorkflowState | null = null;

export const noteDeleteStatusSlice = createSlice({
  name: 'noteDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with INote
    noteDeleteRequest: (state, action: PayloadAction<INote>) => {
      noteDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    noteDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { noteDeleteRequest, noteDeleteStatusChange } = noteDeleteStatusSlice.actions;

// export the core reducer for the slice
export const noteDeleteStatusReducer = noteDeleteStatusSlice.reducer;

export default noteDeleteStatusSlice.reducer;