import { DigitalMediaSearchResultItem, IDigitalMediaSearchResultItem, IDigitalMediaSearchResultItemAsJson, IDigitalMediaSearchResults, IDigitalMediaSearchResultsAsJson } from ".";

/** 
 * @class DigitalMediaSearchResults Results from a search for digital media objects
 */
export class DigitalMediaSearchResults implements IDigitalMediaSearchResults {
  /**
   * @method Constructor method
   * @param {Array<IDigitalMediaSearchResultItem>} searchResults A collection of digital media search result items
   */
  constructor(
    searchResults: Array<IDigitalMediaSearchResultItem>,
  ) {
    this.searchResults = searchResults;
  }

  /*-----------------------------------------------*/
  /**
   * @property {Array<IDigitalMediaSearchResultItem>} _searchResults A collection of digital media search result items
   */
  private _searchResults: Array<IDigitalMediaSearchResultItem> = [];

  /**
   * @method searchResults Getter method for _searchResults
   */
  get searchResults(): Array<IDigitalMediaSearchResultItem> {
    return this._searchResults;
  }

  /**
   * @method searchResults Setter method for _searchResults
   * @param {Array<IDigitalMediaSearchResultItem>} value is the input value for setting _searchResults
   */
  set searchResults(value: Array<IDigitalMediaSearchResultItem>) {
    this._searchResults = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IDigitalMediaSearchResults} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IDigitalMediaSearchResults {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IDigitalMediaSearchResults = Object.create(DigitalMediaSearchResults.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Converts the class instance to a JSON representation
   */
  toJSON(): IDigitalMediaSearchResultsAsJson {
    const jsonObject: IDigitalMediaSearchResultsAsJson = {
      searchResults: [...this.searchResults],
    };

    return jsonObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Converts a class instance from a JSON representation
   */
  static fromJSON(jsonObject: IDigitalMediaSearchResultsAsJson): IDigitalMediaSearchResults {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered DigitalMediaSearchResults.fromJSON(). jsonObject: ${JSON.stringify(jsonObject)}`, 'background: #055; color: #fff');

    displayConsoleLogs && console.log(`%c DigitalMediaSearchResults.fromJSON(). jsonObject.searchResults (stringified): ${JSON.stringify(jsonObject.searchResults)}`, 'background: #055; color: #fff');
    displayConsoleLogs && console.log(`%c DigitalMediaSearchResults.fromJSON(). jsonObject.searchResults (non-stringified): ${jsonObject.searchResults}`, 'background: #055; color: #fff');

    // create a new instance of this class
    let digitalMediaSearchResults: IDigitalMediaSearchResults = Object.create(DigitalMediaSearchResults.prototype);

    // create an empty array of search results items
    const searchResultsItemsArray: Array<IDigitalMediaSearchResultItem> = [];

    // if there are search results and it's not an empty array...
    if (jsonObject && jsonObject.searchResults && !((jsonObject.searchResults.constructor === Array) && jsonObject.searchResults.length === 0)) {
      displayConsoleLogs && console.log(`%c DigitalMediaSearchResults.fromJSON(). jsonObject.searchResults typeof: ${typeof jsonObject.searchResults}`, 'background: #055; color: #fff');

      // now, we need to convert the array of Json object results items (IDigitalMediaSearchResultItemAsJson) to an array of
      // Javascript results items IDigitalMediaSearchResultItem
      jsonObject.searchResults.forEach((sourceSearchResultsItemAsJson: IDigitalMediaSearchResultItemAsJson) => {
        const searchResultItem: IDigitalMediaSearchResultItem = DigitalMediaSearchResultItem.fromJSON(sourceSearchResultsItemAsJson);
        displayConsoleLogs && console.log(`%c DigitalMediaSearchResults.fromJSON(). searchResultItem: ${JSON.stringify(searchResultItem)}`, 'background: #055; color: #fff');
        searchResultsItemsArray.push(searchResultItem);
      })
    }

    digitalMediaSearchResults.searchResults = searchResultsItemsArray;
    displayConsoleLogs && console.log(`%c DigitalMediaSearchResults.fromJSON(). digitalMediaSearchResults.searchResults: ${JSON.stringify(digitalMediaSearchResults.searchResults)}`, 'background: #055; color: #fff');

    return digitalMediaSearchResults;
  }
  /*-----------------------------------------------*/

}
