import { RandomId } from '../../../utilities/RandomId';
import { typeBeaconType, typeUniqueId } from '../../../types';
import { IBeacon, IBeaconAsJson } from '.';

/** 
 * @class Beacon Represents data for a Beacon object that will be displayed in the application.
 */
export class Beacon implements IBeacon {

  /**
   * @method Constructor For Beacon
   * @param {typeUniqueId} id Unique Id of the beacon
   * @param {typeBeaconType} beaconType Type of beacon
   * @param {string} title Title of the beacon
   * @param {string} message Message for the beacon
   * @param {number} autoTimeoutMilliseconds (optional) If provided, a timeout value for the beacon, in milliseconds.
   *        Note: If the value is less than or equal to 0 (<= 0), the timeout value will be ignored.
   */
  constructor(
    id: typeUniqueId = RandomId.newId(),
    beaconType: typeBeaconType,
    title: string,
    message: string,
    autoTimeoutMilliseconds: number = 5000 // default to 5000 milliseconds (5 seconds)
  ) {
    this._id = id;
    this.beaconType = beaconType;
    this.title = title;
    this.message = message;
    this.autoTimeoutMilliseconds = autoTimeoutMilliseconds;
  }

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} id Unique Id for the beacon.
   */
  private _id: typeUniqueId;

  /**
   * @method id (public) Getter for the instance's '_id'.
   */
  get id() {
    return this._id;
  }

  /**
   * @method typeUniqueId (protected) Setter for the instance's '_id'.
   */
  set id(value: typeUniqueId) {
    this._id = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {enumBeaconType} _beaconType Beacon type
   */
  private _beaconType: typeBeaconType = undefined;

  /**
   * @method beaconType (public) Getter for the instance's '_beaconType'.
   */
  get beaconType(): typeBeaconType {
    return this._beaconType;
  }

  /**
   * @method beaconType (protected) Setter for the instance's '_beaconType'.
   * @param {typeBeaconType} value The value to be used to set the 'beaconType'.
   */
  set beaconType(value: typeBeaconType) {
    this._beaconType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} title The title for the beacon.
   */
  private _title: string = '';

  /**
   * @method title (public) Getter for the instance's '_title'.
   */
  get title() {
    return this._title;
  }

  /**
   * @method title (protected) Setter for the instance's 'title'.
   * @param {string} value The value to be used to set the 'title'.
   */
  set title(value: string) {
    this._title = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} message The message for the beacon.
   */
  private _message: string = '';

  /**
   * @method message (public) Getter for the instance's '_message'.
   */
  get message() {
    return this._message;
  }

  /**
   * @method message (protected) Setter for the instance's 'message'.
   * @param {string} value The value to be used to set the 'message'.
   */
  set message(value: string) {
    this._message = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {number | undefined} autoTimeoutMilliseconds The amount of time the beacon will remain alive.
   */
  private _autoTimeoutMilliseconds: number = 5000;

  /**
   * @method autoTimeoutMilliseconds (public) Getter for the instance's '_autoTimeoutMilliseconds'.
   */
  get autoTimeoutMilliseconds(): number {
    return this._autoTimeoutMilliseconds;
  }

  /**
   * @method message (protected) Setter for the instance's 'autoTimeoutMilliseconds'.
   * @param {string} value The value to be used to set the 'autoTimeoutMilliseconds'.
   */
  set autoTimeoutMilliseconds(value: number) {
    // if the value is a defined number that is greater than 0, assign that value; otherwise, set the property value to 'undefined'
    this._autoTimeoutMilliseconds = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IPersistable} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IBeacon {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IBeacon = Object.create(Beacon.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of the derived class instance to JSON, along with all of its descendants. 
   * (Note: This method will be called if JSON.stringify() is executed on an instance.)
   * @returns A JSON object with serialized data from 'this' instance of the derived class.
   */
  toJSON(includeContainedObjects: boolean = true): IBeaconAsJson {
    try {
      // declare a JSON object to be returned
      const jsonObject: IBeaconAsJson = {
        id: this.id,
        beaconType: this.beaconType,
        title: this.title,
        message: this.message,
        autoTimeoutMilliseconds: this.autoTimeoutMilliseconds
      };

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // there are no contained objects in this base class
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IBeaconAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A Beacon instance with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IBeaconAsJson, includeContainedObjects: boolean = true): IBeacon {
    try {
      // create a new instance of this class
      let beaconObject: Beacon = Object.create(Beacon.prototype);

      // copy any additional field values from the json object 
      beaconObject.id = jsonObject.id;
      beaconObject.beaconType = jsonObject.beaconType;
      beaconObject.title = jsonObject.title;
      beaconObject.message = jsonObject.message;
      beaconObject.autoTimeoutMilliseconds = jsonObject.autoTimeoutMilliseconds;

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // there are no contained objects
      }

      return beaconObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}