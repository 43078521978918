import { Firestore } from 'firebase/firestore';
import { typeUniqueId } from "../../../../dataObjects/types";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { FirestoreCategoryRepository_Ext, IFirestoreCategoryRepository, IFirestoreCategoryRepository_Ext } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreCategoryRepository";
import { ICategory, ICategoryAsJson } from "../../../../dataObjects/models/categories/Category";
import { FirebaseAppSingleton } from "../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { enumMdbErrorType } from '../../../../errorObjects/enums';
import { MdbError } from '../../../../errorObjects/MdbError';


/**
 * @function setChannelAsCategoryChild Adds the Id of a Channel to a User's list of Channels
 * @param {typeUniqueId} categoryId The Id of the Category to which the ChannelId will be set as a child
 * @param {typeUniqueId} channelId The Id of the Channel that will be set as a child of the Category
 */
export function setChannelAsCategoryChild(categoryId: typeUniqueId, channelId: typeUniqueId): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a Category repository from the repository factory
      const firestoreCategoryRepository: IFirestoreCategoryRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.Category) as
        IFirestoreBaseRepository<ICategory, ICategoryAsJson>;

      // attempt to get the Category record by calling the repository get() method 
      const existingCategory: ICategory | undefined = await firestoreCategoryRepository.get(categoryId);

      // If the Category was found, get the instance and we'll update that instance, and we'll also have to make adjustments to any
      // other Category records that have the Channel as a child
      if (existingCategory !== undefined) {
        // set a local variable to the ownerId from the Category record found
        const categoryOwnerId: typeUniqueId = existingCategory.ownerId;

        // ***
        // get all Category records that exist for the same owner and that have the Channel as a child
        // *** 

        // initialize a Firestore object instance
        const firestoreObj: Firestore = FirebaseAppSingleton.firestore;

        const firestoreCategoryRepository_Ext: IFirestoreCategoryRepository_Ext = new FirestoreCategoryRepository_Ext(firestoreObj);

        // get any Category records belonging to the given user (the ownerId is identical to the userId) that have the channel as a child 
        // (Note: there should only be one parent category, but if there are multiple ones, this is a time to correct that)
        const categoriesForOwnerIdWithChannelId: Array<ICategory> | undefined = await firestoreCategoryRepository_Ext.getAllCategoriesForOwnerIdAndChannelId(categoryOwnerId, channelId);

        // variable to indicate whether the Category already has the Channel as a child
        let channelAlreadyChildForTargetCategory: boolean = false;

        // determine if any Category records met the criteria
        if (categoriesForOwnerIdWithChannelId !== undefined && categoriesForOwnerIdWithChannelId.length > 0) {
          // traverse the records and remove the channel from any Category records, except for the Category to that will have the Channel as a child
          categoriesForOwnerIdWithChannelId.forEach(async (category: ICategory, idxCategory: number) => {
            // if it's not the target Category that is to have the Channel as a child...
            if (category.id !== categoryId) {
              // search for the channelId in the list of the Category's children
              const idxChannelChild: number = category.children.findIndex(childChannelId => childChannelId === channelId);
              // if the returned index is NOT -1, it means that the channelId was found at that index in the 'children' array
              if (idxChannelChild !== -1) {
                // remove the one element at the index from the array
                category.removeChild(channelId);

                // call repository method to update this Category that has had the Channel child removed from it
                await firestoreCategoryRepository.update(category);
              }
            } else {
              // if we get here, it means that the target Category already has the Channel as a child, so set the boolean variable 
              channelAlreadyChildForTargetCategory = true;
            }
          })
        }

        // back to the target Category that is to have the Channel as a child,
        // if it doesn't already have the Channel as a child...
        if (!channelAlreadyChildForTargetCategory) {
          // add the Channel's Id as a child
          existingCategory.addChild(channelId);

          // call repository method to update the Category that has had the Channel child added to it
          await firestoreCategoryRepository.update(existingCategory);
        }
      } else {
        // we were unable to get the Category record for the given categoryId
        throw new MdbError(`Unable to find Category record for categoryId: ${categoryId}`, enumMdbErrorType.InconsistentData);
      }

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}