import { typeUniqueId } from '../../types';
import { IUserEmailIdAndNameViewModel } from '.';

/**
 * @class UserEmailIdAndNameViewModel Represents a view model with a user's UserId, Email Address,
 *                                         Last Name, and First Name
 */
export class UserEmailIdAndNameViewModel implements IUserEmailIdAndNameViewModel {

  /**
   * @method Constructor method
   * @param {typeUniqueId} userId Unique Id for a user instance
   * @param {string} emailAddress The user's email address
   * @param {string} lastName The user's last name
   * @param {string} firstName The user's first name
   */
  constructor(
    userId: typeUniqueId,
    emailAddress: string,
    lastName: string,
    firstName: string,
  ) {
    this._userId = userId;
    this._emailAddress = emailAddress;
    this._lastName = lastName;
    this._firstName = firstName;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {typeUniqueId} userId Unique Id for a user instance
   */
  private _userId: typeUniqueId;

  // getter for _userId
  get userId(): typeUniqueId {
    return this._userId;
  }

  // setter for _userId
  set userId(value: typeUniqueId) {
    this._userId = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} emailAddress The user's email address
   */
  private _emailAddress: string = '';

  // getter for _emailAddress
  get emailAddress(): string {
    return this._emailAddress;
  }

  // setter for _emailAddress
  set emailAddress(value: string) {
    this._emailAddress = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} lastName The user's last name
   */
  private _lastName: string = '';

  /**
   * @method getter for _lastName
   */
  get lastName() {
    return this._lastName;
  }

  /**
   * @method setter for _lastName
   * @param {string} value for setting _lastName
   */
  set lastName(value) {
    this._lastName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {string} firstName The user's first name
   */
  private _firstName: string = '';

  /**
   * @method firstName Getter method for _firstName
   */
  get firstName(): string {
    return this._firstName;
  }

  /**
   * @method firstName Setter method for _firstName
   * @param {string} value Input value for setting _firstName
   */
  set firstName(value: string) {
    this._firstName = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IMdbError} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IUserEmailIdAndNameViewModel {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IUserEmailIdAndNameViewModel = Object.create(UserEmailIdAndNameViewModel.prototype);
    Object.assign(copyOfObject, this);

    return copyOfObject;
  }
  /*-----------------------------------------------*/

}
