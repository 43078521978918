import { IFirestoreVideoLinkRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreVideoLinkRepository";
import { FirestoreDataRepositoryFactory } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreDataRepositoryFactory";
import { enumFirestoreDataRepositoryDataType } from "../../../cloudServices/googleFirebaseServices/database/firestore/enums";
import { IFirestoreBaseRepository } from "../../../cloudServices/googleFirebaseServices/database/firestore/FirestoreBaseRepository";
import { IVideoLink, IVideoLinkAsJson } from "../../../../dataObjects/models/digitalMedia/VideoLink";


export function deleteVideoLink(videoLink: IVideoLink): Promise<void> {
  return new Promise<void>(async (resolve, reject) => {
    try {
      // get a VideoLink repository from the repository factory
      const firestoreVideoLinkRepository: IFirestoreVideoLinkRepository =
        FirestoreDataRepositoryFactory.CreateDataRepository(enumFirestoreDataRepositoryDataType.VideoLink) as
        IFirestoreBaseRepository<IVideoLink, IVideoLinkAsJson>;

      // call the repository delete() method to delete the object
      await firestoreVideoLinkRepository.delete(videoLink.id);

      resolve();
    } catch (error: any) {
      reject(error);
    }
  });
}