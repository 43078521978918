import { RandomId } from '../../../utilities/RandomId';
import { typeUniqueId, typePersistableParentObjectType, typeUniqueIdWithUndefinedOption, typeAudioLinkType } from '../../../types';
import { FieldValueValidation } from '../../../validations/FieldValueValidation';
import { enumObjectPersistenceState, enumPersistableObjectType, enumAudioLinkType, enumPersistableObjectClassName } from '../../../enums';
import { IAudioLink, IAudioLinkAsJson } from '.';
import { IUserPersistenceData } from '../../persistence/UserPersistenceData';
import { BaseMediaFileLink } from '../BaseMediaFileLink';

/** 
 * @class AudioLink A audio link that can be associated with something
 */
export class AudioLink extends BaseMediaFileLink implements IAudioLink {
  /**
   * @method Constructor method
   * @param {typeUniqueId} ownerId The Id of the owner (user or channel) of the instance
   * @param {typeUniqueId} id Unique Id of the instance
   * @param {typePersistableParentObjectType} parentObjectType The Parent's object type
   * @param {typeUniqueIdWithUndefinedOption} parentId Id of the object's parent
   * @param {enumObjectPersistenceState} objectState The state of the object since it was last persisted.
   * @param {enumAudioLinkType} audioLinkType The type of audio link
   * @param {string} baseStoragePath The source Url (path & filename) that points to the media file
   * @param {string} downloadUrl A full Url for downloading the media file from the storage source that has security (Google Cloud Storage, AWS S3, etc.),
   *   or even a direct location (some file store)
   * @param {string} mediaType The type of media
   * @param {string} description A description of the media file
   * @param {number} playFragmentStartTime The time (in seconds) at which to start playing the audio
   * @param {number | undefined} playFragmentEndTime The time (in seconds) at which to stop playing the audio
   * @param {IUserPersistenceData} userPersistenceData User-related persistence data
   */
  constructor(
    ownerId: typeUniqueId,
    id: typeUniqueId = RandomId.newId(),
    parentObjectType: typePersistableParentObjectType,
    parentId: typeUniqueIdWithUndefinedOption,
    objectState: enumObjectPersistenceState,
    audioLinkType: enumAudioLinkType,
    baseStoragePath: string,
    downloadUrl: string,
    mediaType: string,
    description: string,
    playFragmentStartTime: number = 0,
    playFragmentEndTime?: number,
    userPersistenceData?: IUserPersistenceData
  ) {
    super(ownerId, enumPersistableObjectClassName.AudioLink, enumPersistableObjectType.AudioLink, id, parentObjectType, parentId, objectState, baseStoragePath, downloadUrl, mediaType, description, userPersistenceData);

    this.audioLinkType = audioLinkType;
    this.playFragmentStartTime = playFragmentStartTime;
    this.playFragmentEndTime = this._playFragmentEndTime;

    // validate that the requested play 'Start' and 'End' times are proper, before proceeding
    this.validatePlayFragmentPropertyValues(playFragmentStartTime, playFragmentEndTime);

    this.playFragmentStartTime = playFragmentStartTime;
    this.playFragmentEndTime = playFragmentEndTime;
  }


  /*-----------------------------------------------*/
  /**
   * @property {typeAudioLinkType} _audioLinkType property The type of audio link (Google Cloud Storage, rawLink, youTubeEmbed, etc.)
   */
  private _audioLinkType: typeAudioLinkType = undefined;

  /**
   * @method GetSetPropName Getter method for _audioLinkType
   */
  get audioLinkType(): typeAudioLinkType {
    return this._audioLinkType;
  }

  /**
   * @method audioLinkType Setter method for _audioLinkType
   * @param {typeAudioLinkType} value The value for setting _audioLinkType
   */
  set audioLinkType(value: typeAudioLinkType) {
    this._audioLinkType = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {number | undefined} _playFragmentStartTime Starting point (in seconds) for playing the audio (default: 0 - Start of the audio)
   */
  private _playFragmentStartTime: number | undefined = 0;

  /**
   * @method playFragmentStartTime is an optional getter method for _playFragmentStartTime
   */
  get playFragmentStartTime(): number | undefined {
    return this._playFragmentStartTime;
  }

  /**
   * @method playFragmentStartTime is an optional setter method for _playFragmentStartTime
   * @param {number | undefined} value is the input value for setting _playFragmentStartTime
   */
  set playFragmentStartTime(value: number | undefined) {
    this._playFragmentStartTime = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @property {number | undefined} _playFragmentEndTime property Ending point (in seconds) for playing the audio (default: Start of the audio)
   */
  private _playFragmentEndTime: number | undefined = undefined;

  /**
   * @method playFragmentEndTime is an optional getter method for _playFragmentStartTime
   */
  get playFragmentEndTime() {
    return this._playFragmentEndTime;
  }

  /**
   * @method playFragmentEndTime is an optional setter method for _playFragmentStartTime
   * @param {number} value is the input value for setting _playFragmentStartTime
   */
  set playFragmentEndTime(value) {
    this._playFragmentEndTime = value;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method validatePlayFragmentPropertyValues Validates that the values for playFragmentStartTime & 
   * playFragmentEndTime are valid
   * @param playFragmentStartTime The desired time on the audio to 'Start' playing.
   * @param playFragmentEndTime The desired time on the audio to 'End' playing.
   */
  private validatePlayFragmentPropertyValues(playFragmentStartTime: number, playFragmentEndTime: number | undefined) {
    // ensure that the Start time is not a negative number
    FieldValueValidation.validateNumberIsNonNegative(playFragmentStartTime,
      `'playFragmentStartTime' cannot be a negative number (value requested: ${playFragmentStartTime})`);

    // Validate that, if defined, the playFragmentEndTime is greater than 0
    if (playFragmentEndTime !== undefined) {
      FieldValueValidation.validateOneNumberIsLessThanAnotherNumber(0, playFragmentEndTime,
        `'playFragmentEndTime' must be greater than 0 (value requested: ${playFragmentEndTime})`);
    }

    // if playFragmentEndTime is defined, ensure that 'Start' < 'End'
    if (playFragmentEndTime !== undefined) {
      FieldValueValidation.validateOneNumberIsLessThanAnotherNumber(playFragmentStartTime, playFragmentEndTime,
        `The requested 'playFragmentStartTime' (value requested: ${playFragmentStartTime})' must be less than the ` +
        ` requested 'playFragmentEndTime' (value requested: ${playFragmentStartTime})'`);
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method copy Performs a "deep copy" of the instance, which includes a copy of all contained objects.
   * @returns {IAudioLink} A "deep copy" of the object instance, including a "deep copy" of all contained objects.
   */
  copy(): IAudioLink {
    // use Object.create() to create a new instance, and then Object.assign() to assign all core properties
    let copyOfObject: IAudioLink = Object.create(AudioLink.prototype);
    Object.assign(copyOfObject, this);

    // there are no contained objects to copy

    return copyOfObject;
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method toJSON Serializes an instance of this class to a JSON object, including contained
   * objects (if requested).
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns A JSON object with serialized data from 'this' class instance.
   */
  toJSON(includeContainedObjects: boolean = true): IAudioLinkAsJson {
    try {
      // prepare  JSON object for return, starting with a call to the direct parent base 
      // class to get its members added to the JSON object
      const jsonObject: IAudioLinkAsJson = super.toJSON(includeContainedObjects);

      // copy any additional field values to the json object 
      jsonObject.audioLinkType = this.audioLinkType === undefined ? 'undefined' : this.audioLinkType;
      jsonObject.playFragmentStartTime = (this.playFragmentStartTime === undefined ? 'undefined' : this.playFragmentStartTime);
      jsonObject.playFragmentEndTime = (this.playFragmentEndTime === undefined ? 'undefined' : this.playFragmentEndTime);

      // if requested to include contained objects, serialize contained objects
      if (includeContainedObjects) {
        // this class is currently designed to have no contained objects
      }

      return jsonObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

  /*-----------------------------------------------*/
  /**
   * @method fromJSON Derializes an instance of this class from a JSON object, along with any contained 
   * objects (if requested).
   * @param {IAudioLinkAsJson} jsonObject A JSON version of a class instance.
   * @param {boolean} includeContainedObjects A boolean flag indicating whether to include contained objects.
   * @returns An instance of 'this' class with values copied from the jsonObject
   */
  static fromJSON(jsonObject: IAudioLinkAsJson, includeContainedObjects: boolean = true): IAudioLink {
    try {
      // create a new instance of this class
      let audioLinkObject: AudioLink = Object.create(AudioLink.prototype);

      // call the 'fromJSONProtected()' method on the immediate base to get its property values loaded
      audioLinkObject = super.fromJSONProtected(audioLinkObject, jsonObject, includeContainedObjects);

      // copy any additional field values from the json object 
      audioLinkObject.audioLinkType = (jsonObject.audioLinkType === 'undefined' ? undefined : jsonObject.audioLinkType)

      audioLinkObject.playFragmentStartTime = (jsonObject.playFragmentStartTime === 'undefined' ? undefined : jsonObject.playFragmentStartTime);

      audioLinkObject.playFragmentEndTime = (jsonObject.playFragmentEndTime === 'undefined' ? undefined : jsonObject.playFragmentEndTime);

      // if request is to include contained objects, copy additional fields
      if (includeContainedObjects) {
        // this class doesn't have any contained objects to add
      }

      return audioLinkObject;

    } catch (error: any) {
      // TODO: log error
      // re-throw error
      throw error;
    }
  }
  /*-----------------------------------------------*/

}
