import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMdbError } from '../../../errorObjects/MdbError';

// export interface IFetchImageLinksFailureState {
//   value: IMdbError | null
// }

const initialState: IMdbError | null = null;

export const fetchImageLinksFailureSlice = createSlice({
  name: 'fetchImageLinksFailure',
  initialState,
  reducers: {
    setFetchImageLinksFailure: (state: IMdbError | null, action: PayloadAction<IMdbError>) => {
      state = action.payload;
    },
    clearFetchImageLinksFailure: (state: IMdbError | null) => {
      state = null;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { setFetchImageLinksFailure, clearFetchImageLinksFailure } = fetchImageLinksFailureSlice.actions;

// export the core reducer for the slice
export const fetchImageLinksFailureReducer = fetchImageLinksFailureSlice.reducer;

export default fetchImageLinksFailureSlice.reducer;