import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { ICategory } from '../../../dataObjects/models/categories/Category';

const initialState: enumWorkflowState | null = null;

export const categoryDeleteStatusSlice = createSlice({
  name: 'categoryDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with ICategory
    categoryDeleteRequest: (state, action: PayloadAction<ICategory>) => {
      categoryDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    categoryDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer)
export const { categoryDeleteRequest, categoryDeleteStatusChange } = categoryDeleteStatusSlice.actions;

// export the core reducer for the slice
export const categoryDeleteStatusReducer = categoryDeleteStatusSlice.reducer;

export default categoryDeleteStatusSlice.reducer;