import { getStorage, ref, FirebaseStorage, StorageReference, uploadBytesResumable, UploadTask, UploadTaskSnapshot, getDownloadURL, getMetadata, FullMetadata } from "firebase/storage";
import { typeUniqueId } from '../../../dataObjects/types';
import { FirebaseAppSingleton } from "../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { ICloudStorageFileUploadResult } from ".";
import { IFileUploadProgress } from '../../../dataObjects/models/fileUpload/FileUploadProgress';
import { enumFileUploadType } from '../../../dataObjects/enums';


/**
 * @function uploadFileToCloudStorage Uploads a file to Cloud Storage. The file will be stored in a Cloud Storage path following the pattern:
 *   'http://{cloudStorageBucket}/userFiles/{userId}/{fileClass}/{fileUniqueId}/{fileName}
 * @param {File} fileToUpload Information about the file to be uploaded.
 * @param {typeUniqueId} userId Unique Id of the user performing the upload (will be used as a primary folder name for storing the file).
 * @param {enumFileUploadType} fileClass The class of file being uploaded (will be used as a subfolder for storing the file).
 * @param {typeUniqueId} fileUniqueId Unique Id of the file (will be used as a subfolder for storing the file)
 * @param {(fileUploadProgress: ICloudStorageFileUploadProgress) => void} onProgressUpdateCallback (optional) A callback method for receiving progress updates.
 */
export function uploadFileToCloudStorage(
  fileToUpload: File, // the file details
  userId: typeUniqueId, // the Id of the user requesting the upload
  fileClass: enumFileUploadType, // represents the class of file to be uploaded (imageFiles, videoFiles, audioFiles)
  fileUniqueId: typeUniqueId, // a unique identifier for the file
  onProgressUpdateCallback?: (fileUploadProgress: IFileUploadProgress) => void // (optional) a callback method to receive progress updates
): Promise<ICloudStorageFileUploadResult> {
  return new Promise(async (resolve, reject) => {
    try {
      // prepare a Cloud Storage destination path
      // Path format: 'userFiles/{userId}/{fileClass}/{fileUniqueId}/{fileName}
      const destinationPath: string = `userFiles/${userId}/${fileClass}/${fileUniqueId}/${fileToUpload.name}`;

      // get a reference to the Firebase Cloud Storage Service
      // const storageRef: firebase.storage.Reference = FirebaseAppSingleton.getInstance().firebaseApp.storage().ref();
      const storageService: FirebaseStorage = getStorage(FirebaseAppSingleton.getInstance().firebaseApp);

      // used to measure file upload execution time
      const timeStart = performance.now();

      // get a reference to the full pathname in storage to which the file will be uploaded
      // const fileCloudStorageRef: firebase.storage.Reference = storageRef.child(`${destinationPath}/${fileToUpload.name}`);
      // const fileCloudStorageRef: firebase.storage.Reference = storageRef.child(destinationPath);
      const fileCloudStorageRef: StorageReference = ref(storageService, destinationPath);

      // request to upload (put) the file to Cloud Storage (if metadata to be set, provide as the second parameter in the call to put() )
      // const fileDownloadUrl: string = await fileCloudStorageRef.getDownloadURL() + '/' + fileToUpload.name;
      // const uploadTask: firebase.storage.UploadTask = storageRef.child(destinationPath).put(fileToUpload);
      // const uploadTask: firebase.storage.UploadTask = fileCloudStorageRef.put(fileToUpload);
      const uploadTask: UploadTask = uploadBytesResumable(fileCloudStorageRef, fileToUpload);

      // // listen for events from the uploadTask.
      // uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
      //   // progress updates
      //   function (progressSnapshot: firebase.storage.UploadTaskSnapshot) {
      //     // if a progress update callback function was provided...
      //     if (onProgressUpdateCallback !== undefined) {
      //       // create a progress update object
      //       const progressUpdate: IFileUploadProgress = {
      //         file: fileToUpload,
      //         bytesTransferred: progressSnapshot.bytesTransferred,
      //         percentComplete: (progressSnapshot.bytesTransferred / progressSnapshot.totalBytes) * 100,
      //         elapsedMilliseconds: performance.now() - timeStart
      //       }

      //       // call the callback function
      //       onProgressUpdateCallback(progressUpdate);
      //     }
      //   },
      //   // error occurred
      //   function (error) {
      //     reject(error);
      //   },
      //   // operation completed
      //   async function () {
      //     // get the Url for the file that now sits in Cloud Storage (note: the url includes QueryString parameters for alt=media & token=XXX) 
      //     const fileCloudStorageUrl: string = await fileCloudStorageRef.getDownloadURL();
      //     const fileCloudStorageMetadata: any = await fileCloudStorageRef.getMetadata();

      //     const fileUploadResult: ICloudStorageFileUploadResult = {
      //       file: fileToUpload,
      //       fileId: fileUniqueId,
      //       baseStoragePath: destinationPath,
      //       downloadUrl: fileCloudStorageUrl,
      //       mediaType: fileCloudStorageMetadata.contentType,
      //       uploadCompleted: true,
      //       totalMilliseconds: performance.now() - timeStart
      //     }
      //     resolve(fileUploadResult);
      //   }
      // );

      // listen for events from the uploadTask.
      uploadTask.on('state_changed',
        // snapshot of progress updates
        (progressSnapshot: UploadTaskSnapshot) => {
          // if a progress update callback function was provided...
          if (onProgressUpdateCallback !== undefined) {
            // create a progress update object with details about the upload
            const progressUpdate: IFileUploadProgress = {
              file: fileToUpload,
              bytesTransferred: progressSnapshot.bytesTransferred,
              percentComplete: (progressSnapshot.bytesTransferred / progressSnapshot.totalBytes) * 100,
              elapsedMilliseconds: performance.now() - timeStart
            }

            // call the callback function with the progress update details
            onProgressUpdateCallback(progressUpdate);

            // for potential future use: respond to the different upload progress states
            switch (progressSnapshot.state) {
              case 'paused':
                break;

              case 'running':
                break;

              case 'success':
                break;

              case 'canceled':
                break;

              case 'error':
                break;
            }
          }
        },
        // an error occurred
        (error) => {
          // merely return the error for the promise
          reject(error);
        },
        async () => {
          // Handle successful uploads on completion

          // get the Url for the file that now sits in Cloud Storage (note: the url includes QueryString parameters for alt=media & token=XXX) 
          const fileCloudStorageUrl: string = await getDownloadURL(uploadTask.snapshot.ref);
          // also, get the metadata for the file
          const fileCloudStorageMetadata: FullMetadata = await getMetadata(uploadTask.snapshot.ref);

          const fileUploadResult: ICloudStorageFileUploadResult = {
            file: fileToUpload,
            fileId: fileUniqueId,
            baseStoragePath: destinationPath,
            downloadUrl: fileCloudStorageUrl,
            mediaType: fileCloudStorageMetadata.contentType!,
            uploadCompleted: true,
            totalMilliseconds: performance.now() - timeStart
          }
          resolve(fileUploadResult);
        }
      );

    } catch (error: any) {
      reject(error);
    }
  });
}


// // uploadFile receives a collection of specs for files to be uploaded (from an HTML Input 'files' property)
// // and uploads the files to the default Cloud Storage bucket, under a folder structure for each file in
// // the form of `userFiles/${user.uid}/${uuid}/${file.name}`, where:
// //  o 'userFiles' is a constant
// //  o ${user.uid} is the unique id of the user account for the current user (a user must be logged in)
// //  o ${uuid}' is a unique id to be generated for each file (this will be a folder that will hold the source
// //             file and potentially associated files, such as thumbnail images for a source image)
// //  o ${file.name} is the name of the file
// export function uploadFilesToCloudStorage(userId: typeUniqueId, filesToUpload: FileList): Promise<IFileUploadResult> {
//   // console.info('storage.uploadFile function');

//   // get current (logged in) user
//   const user = firebase.auth().currentUser;

//   // if there are no files to be uploaded, stop the process
//   if (filesToUpload === null || filesToUpload === undefined || filesToUpload.length === 0) {
//     alert('There are no files to be uploaded');
//     // if the user isn't logged in (hasn't been authenticated), stop the process
//   } else if (user === null || user === undefined) {
//     alert('User must be logged in');
//   } else {
//     // get a reference to Firebase Cloud Storage
//     const storageRef = firebase.storage().ref();

//     // used to measure file upload execution time
//     const timeStart = performance.now();

//     // file uploads will be performed in parallel, leveraging the use of JavaScript promises
//     // create an array to hold a collection of promises, one for each file uploaded request
//     let promises = [];

//     // loop through the files to be uploaded
//     for (idxFiles = 0; idxFiles < filesToUpload.length; idxFiles++) {
//       // get a reference to the current file in the loop
//       file = filesToUpload[idxFiles];

//       // if we want to add custom metadata to a file, the next few commented lines demonstrate how to do it
//       // const fileMetadata = {
//       //   customMetadata: {
//       //     metaProperty1: 'value for metaProperty1',
//       //     metaProperty2: 'value for metaProperty2',
//       //     metaProperty3: 'value for metaProperty3'
//       //   }
//       // };

//       // generate a UUID for the file
//       const uuid = generateUUID();

//       // request to upload (put) the file to Cloud Storage (if metadata to be set, provide as the second parameter in the call to put() )
//       resultPromise = storageRef.child(`userFiles/${user.uid}/${uuid}/${file.name}`).put(file);

//       // add the resulting promise to the array of promises
//       promises.push(resultPromise);

//       // console.info(`Requested to uploaded file '${file.name}' to storage`);
//     };

//     // synchronize to ensure that all promises (all file uploads) succeeded
//     Promise.all(promises)
//       .then(values => {
//         const timeEnd = performance.now();
//         // console.info(`The file uploads took ${timeEnd - timeStart} milliseconds`);
//       })
//       .catch(error => {
//         // console.error(`Error attempting to upload files to storage: ${error}`);
//         handleStorageError(error.code);
//       });
//   }
// }

