import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import { useParams } from "react-router-dom";
import { typeUniqueId } from '../../../../dataObjects/types';
import GenericPageContainer from '../../GenericPageContainer/GenericPageContainer';
import { IChannel, Channel, IChannelAsJson } from '../../../../dataObjects/models/channels/Channel';
import { IUser } from '../../../../dataObjects/models/users/User';
import { IStoreState } from '../../../../uiMiddleware-redux/store/IStoreState';
import { getSingleObjectById, getSingleObjectById_onSnapshot } from '../../../../firebaseServices/dataServices/dataServiceActions/genericActions';
import { enumNoParentObjectType, enumObjectPersistenceState, enumPersistableObjectType, enumSharableObjectType, enumSharingPermission } from '../../../../dataObjects/enums';
import { IUserSettings } from '../../../../dataObjects/models/users/UserSettings';
import { RandomId } from '../../../../dataObjects/utilities/RandomId';
import { IUserPersistenceData, UserPersistenceData } from '../../../../dataObjects/models/persistence/UserPersistenceData';
import { PageAndViewTitleStringAssets, MessagesStringAssets } from '../../../../assets/stringAssets';
import { useProcessPerManageObjectPageStatus } from '../../../customHooks';
import { NotificationStringAssets } from '../../../../assets/stringAssets';
import { IProcessPerManageObjectPageStatusInput } from '../../../customHooks/hookInputObjects/ProcessPerManageObjectPage';
import { IObjectSharingRequestTracker, ObjectSharingRequestTracker } from '../../../../dataObjects/models/collaboration/ObjectSharingTracker';
import ChannelSharingRequestForm from '../../../forms/ChannelSharingRequestForm/ChannelSharingRequestForm';
import { JsonConverter } from '../../../../dataObjects/utilities/JsonConverter';
import { ICurrentUserContextData, useCurrentUserContext } from '../../../providersAndContexts/currentUser';
import { useAppDispatch } from '../../../../uiMiddleware-redux/store/configureStore';
import { objectSharingRequestTrackerSaveRequest, objectSharingRequestTrackerSaveStatusChange } from '../../../../uiMiddleware-redux/slices/collaboration/objectSharingRequestTrackerSaveStatusSlice';
import { alertInfoChange } from '../../../../uiMiddleware-redux/slices/alertInfo/alertInfoSlice';


/**
 * @interface IManageChannelSharingRequestPageProps Input properties for the ManageChannelSharingRequestPage
 */
export interface IManageChannelSharingRequestPageProps extends PropsWithChildren<unknown> {
  // channelId?: typeUniqueId; // the Id of the Channel to which a new Channel Sharing Request is to be associated
  id?: typeUniqueId  // if the Id is given (is defined), it means that the Channel Sharing Request (an ObjectSharingRequestTracker object) already exists
  // if the Id is NOT given (is undefined), it means that we will be creating a new Channel Sharing Request
}

const ManageChannelSharingRequestPage: React.FC<IManageChannelSharingRequestPageProps> = (props: IManageChannelSharingRequestPageProps) => {

  ManageChannelSharingRequestPage.displayName = "Manage Channel Sharing Request Page";

  // whether to display console logs (displayConsoleLogs && console.log statements)
  const displayConsoleLogs: boolean = true;

  // get access to the useAppDispatch hook, so we'll be able to dispatch calls to redux
  const dispatch = useAppDispatch();

  // get access to parameters passed into this component by using the useParams hook
  const params = useParams();

  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entering component. channelId: ${params.channelId}; params.id: ${params.id}`);


  // get access to the URL location
  // the base domain of the app (to be used for creating user meta data)
  const [baseDomain] = useState<string>(window.location.hostname);

  // get access to the URL location
  // const baseDomain: string = window.location.hostname;
  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. baseDomain: ${baseDomain}`);

  // will be set to the 'id' provided in the URL parms. If an id was provided, it means that we will be editing an existing object.
  const [objectSharingRequestTrackerId, setObjectSharingRequestTrackerId] = useState<typeUniqueId | undefined>(params.id);

  // will be set to the ObjectSharingRequestTracker to be managed, either a new ObjectSharingRequestTracker or an existing one
  const [objectSharingRequestTracker, setObjectSharingRequestTracker] = useState<IObjectSharingRequestTracker | undefined>(undefined);

  // whether we are going to be managing (creating) a new Channel
  const [isNewObject, setIsNewObject] = useState<boolean>(true);

  // whether an operation (eg, a save operation) is in progress for this page
  const [operationInProgress, setOperationInProgress] = useState<boolean>(false);

  // will be set to the 'channelId' provided in the URL parms if this is for a 'New' Channel Sharing Request
  const [channelId, setChannelId] = useState<typeUniqueId | undefined>(params.channelId);
  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. channelId: ${channelId}`);

  // will be set to the Channel object associated with the channelId provided in the URL parms. The Channel object will be retrieved
  // during the mounting of this component in a useEffect().
  const [channel, setChannel] = useState<IChannel | undefined>(undefined);

  // define an effect that will set the objectSharingRequestTrackerId anytime the params.id changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered useEffect() for [objectSharingRequestTrackerId, params.id]. params.id value: ${params.id}`);
    if (objectSharingRequestTrackerId !== params.id) {
      displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In useEffect() for [objectSharingRequestTrackerId, params.id]. params.id value: ${params.id}. Ready to call setObjectSharingRequestTrackerId()`);
      setObjectSharingRequestTrackerId(params.id);
    }

    return () => {
      // process for 'unmounting'
      displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In 'unmounting' area of useEffect() for [objectSharingRequestTrackerId, params.id]`);
    }

  }, [objectSharingRequestTrackerId, params.id]);

  // define an effect that will set the channelId anytime the params.channelId changes
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered useEffect() for [channelId, params.channelId]. params.channelId value: ${params.channelId}`);
    if (channelId !== params.channelId) {
      displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In useEffect() for [channelId, params.channelId]. params.channelId value: ${params.channelId}. Ready to call setChannelId()`);
      setChannelId(params.channelId);
    }

    return () => {
      // process for 'unmounting'
      displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In 'unmounting' area of useEffect() for [channelId, params.channelId]`);
    }

    // }, [params.channelId]);
  }, [channelId, params.channelId]);

  // define an effect based on a change to solely the channelId. 
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered useEffect() for [channelId]. channelId value: ${channelId}`);

    // Declare an 'unsubscribe' variable that will hold the unsubscribe callback from a firestore onSnapshot() Channel request.
    // We initialize it to a function that does nothing, so if an onSnapshot() is never requested, we can still call unsubscribe() during cleanup. 
    // After an onShapshot() request, the 'unsubscribe' variable will be set to a callback function issued by firestore.
    let unsubscribeCallbackForChannelData: () => void = () => { };

    // if an Id was provided, we will be editing an existing channel; therefore, if we haven't already fetched the channel...
    if (channelId !== undefined) {
      displayConsoleLogs && console.log(`ChannelSharingPage. In useEffect() for [channelId] (${channelId}). Ready to call getSingleObjectById_onSnapshot().`);

      // subscribe to onShapshot updates for a Channel that has the given channelId, providing realtime updates to the data, and 
      // capture the 'unsubscribe' callback method provided by firestore
      getSingleObjectById_onSnapshot(channelId, enumPersistableObjectType.Channel, onChannelSnapshotCallback).then((unsubscribe: () => void) => {
        unsubscribeCallbackForChannelData = unsubscribe;
      });
    }

    // perform cleanup when the component unmounts
    return () => {
      // Call the unsubscribe() callback to unsubscribe from the Channel onSnapshot. 
      displayConsoleLogs && console.info(`ManageChannelSharingRequestPage. Cleanup before unmounting. Ready to call unsubscribeCallbackChannel().`)
      unsubscribeCallbackForChannelData();
      displayConsoleLogs && console.info(`ManageChannelSharingRequestPage. Cleanup before unmounting. After call to unsubscribeCallbackChannel().`)
    }

  }, [channelId]);


  /**
   * @function onChannelSnapshotCallback A callback method to receive firestore Channel data snapshots for dynamic data updates
   * @param {QuerySnapshot<DocumentData>} snapshot A snapshot of data from firestore
   */
  function onChannelSnapshotCallback(snapshot: QuerySnapshot<DocumentData>): void {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered onChannelSnapshotCallback().`);

    // // set state variable indicating that dataLoading is no longer taking place
    // setDataLoading(false);

    // fetch the data from the document, which should be a JSON version of the correct type of object
    const channelAsJson: IChannelAsJson = snapshot.docs[0].data() as IChannelAsJson;

    // convert the JSON object to a Typescript object
    const channelData: IChannel = JsonConverter.fromJSON(Channel, channelAsJson, true);

    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered onChannelSnapshotCallback(). Ready to set channel to channelData: ${JSON.stringify(channelData)}`);

    setChannel(channelData);
  }

  const resetSaveObjectSharingRequestTrackerStatus: () => void = useCallback(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered resetSaveObjectSharingRequestTrackerStatus(). Ready to call dispatch(objectSharingRequestTrackerSaveStatusChange(null))`);
    dispatch(objectSharingRequestTrackerSaveStatusChange(null));
  }, [dispatch]);

  const resetAlertInfo: () => void = useCallback(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered resetAlertInfo(). Ready to call dispatch(alertInfoChange(null))`);
    dispatch(alertInfoChange(null));
  }, [dispatch]);

  // perform any initialization required for this page when it comes into existence
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered useEffect() for [resetAlertInfo, resetSaveObjectSharingRequestTrackerStatus]. channelId value: ${objectSharingRequestTrackerId}`);
    // clear areas in Redux state to start with a clean slate
    resetSaveObjectSharingRequestTrackerStatus();
    resetAlertInfo();

    return () => {
      // upon 'unmounting', clear areas of Redux state
      resetSaveObjectSharingRequestTrackerStatus();
      resetAlertInfo();
    }
  }, [resetAlertInfo, resetSaveObjectSharingRequestTrackerStatus]);

  // use a custom hook to get the Current User information from the CurrentUserProvider
  const { currentUser }: ICurrentUserContextData = useCurrentUserContext();

  // prepare a data structure that will be used to call a custom hook that will take care of the workflow for the page
  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Ready to prepare most details for the processPerManageObjectPageStatusInput object. `);
  const processPerManageObjectPageStatusInput: IProcessPerManageObjectPageStatusInput = {
    workflowStateObj: (state: IStoreState) => state.saveObjectSharingRequestTrackerStatus,
    failureErrorStateObj: (state: IStoreState) => state.saveObjectSharingRequestTrackerFailure,
    notificationFailureMessageTemplate: MessagesStringAssets.channelSharing_SaveFailure,
    notificationSuccessTitle: NotificationStringAssets.heading_SaveChannelSharingRequest,
    notificationSuccessMessage: MessagesStringAssets.channelSharing_SaveSuccess,
  }


  // if there is defined channel sharing request (objectSharingRequestTracker), append the recipient email address to the message for success
  if (objectSharingRequestTracker !== undefined) {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Adding notificationSuccessMessage to processPerManageObjectPageStatusInput object. `);

    processPerManageObjectPageStatusInput.notificationSuccessMessage = `${MessagesStringAssets.channelSharing_SaveSuccess} (${objectSharingRequestTracker.recipientEmail})`;
  } else {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. 'objectSharingRequestTracker' is NOT yet defined. objectSharingRequestTrackerId: ${objectSharingRequestTrackerId}`);
  }

  // // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  // // const { saveInProgress, alertInfo } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  // // displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Ready to call useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput). `);
  // // const { operationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  // // displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Returned from call to useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput). operationInProgress: ${operationInProgress}`);

  // // call a custom hook to handle the workflow for the page (Requested, InProgress, Success, Failure)
  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Ready to call useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput). `);
  const { operationInProgress: pageOperationInProgress } = useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput);
  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Returned from call to useProcessPerManageObjectPageStatus(processPerManageObjectPageStatusInput). pageOperationInProgress: ${pageOperationInProgress}`);

  // // a useEffect for changes in the pageOperationInProgress
  // useEffect(() => {
  //   displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered useEffect() for [operationInProgress, pageOperationInProgress]. operationInProgress: ${operationInProgress}; pageOperationInProgress: ${pageOperationInProgress}`);

  //   if (operationInProgress !== pageOperationInProgress) {
  //     setOperationInProgress(pageOperationInProgress);
  //   }

  // }, [operationInProgress, pageOperationInProgress]);

  /**
   * @function prepareNewChannel Prepares/creates a new Channel that will be used if user is requesting to create/add a new Channel.
   * @param  
   */
  const prepareNewChannelSharingRequest: (requestorUser: IUser, requestorUserSettings: IUserSettings) => IObjectSharingRequestTracker = useCallback(
    (requestorUser: IUser, requestorUserSettings: IUserSettings) => {

      displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered prepareNewChannelSharingRequest(). `);

      let newChannelSharingRequest: IObjectSharingRequestTracker;

      const sharingChannelId: typeUniqueId = channelId ? channelId : 'Unknown Channel';
      const sharingChannelName: string = channel ? channel.name : 'Unknown Channel Name';

      const newObjectSharingRequestTrackerId: typeUniqueId = RandomId.newId();
      const userName: string = `${requestorUserSettings.firstName} ${requestorUserSettings.lastName}`;
      const userPersistenceData: IUserPersistenceData = new UserPersistenceData(requestorUser.id, userName);

      newChannelSharingRequest = new ObjectSharingRequestTracker(requestorUser.id, newObjectSharingRequestTrackerId, enumNoParentObjectType.NoParent, undefined, enumObjectPersistenceState.New,
        baseDomain, sharingChannelId, enumSharableObjectType.Channel, sharingChannelName, requestorUser.id,
        userName, requestorUserSettings.email,
        '', '', enumSharingPermission.ViewContent, userPersistenceData);

      return newChannelSharingRequest;
    }, [channel, channelId, baseDomain]
  );

  // define an effect based on a change to the objectSharingRequestTracker, objectSharingRequestTrackerId, or prepareNewChannelSharingRequest. 
  // Having a defined objectSharingRequestTrackerId means that we will be editing an existing request
  useEffect(() => {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered useEffect() for [currentUser, channelId, prepareNewChannel]. currentUser: ${JSON.stringify(currentUser)}; channelId value: ${objectSharingRequestTrackerId}`);

    // if currentUser information has been established...
    if (currentUser) {
      // if an Id was provided, we will be editing an existing request; therefore, if we haven't already fetched the request...
      if ((objectSharingRequestTracker === undefined) && (objectSharingRequestTrackerId !== undefined)) {
        displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In useEffect() for [currentUser, objectSharingRequestTracker, objectSharingRequestTrackerId, prepareNewChannelSharingRequest] (${objectSharingRequestTrackerId}). Ready to call getSingleObjectById().`);
        // try to find an ObjectSharingRequestTracker object with the Id given in the URL
        getSingleObjectById(objectSharingRequestTrackerId, enumPersistableObjectType.ObjectSharingRequestTracker, onChannelSharingRequestFetched);
      } else {
        // if we haven't already created an ObjectSharingRequestTracker object...
        if ((objectSharingRequestTracker === undefined) && (channel !== undefined) && (channelId !== undefined)) {
          displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In useEffect() for [currentUser, objectSharingRequestTracker, objectSharingRequestTrackerId, prepareNewChannelSharingRequest] & objectSharingRequestTrackerId is undefined). Ready to call prepareNewChannelSharingRequest().`);
          // otherwise, we will be creating a new ObjectSharingRequestTracker, so prepare a new one
          setObjectSharingRequestTracker(prepareNewChannelSharingRequest(currentUser, currentUser.userSettings));
        }
      }
    }
  }, [currentUser, channelId, channel, objectSharingRequestTracker, objectSharingRequestTrackerId, prepareNewChannelSharingRequest]);

  // callback function for fetching the current ObjectSharingRequestTracker for editing
  function onChannelSharingRequestFetched(channelSharingRequestFetched: IObjectSharingRequestTracker | undefined): void {
    displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered onChannelSharingRequestFetched()`);
    if (channelSharingRequestFetched !== undefined) {
      displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. In onChannelSharingRequestFetched(). channelSharingRequestFetched.id: ${channelSharingRequestFetched.id}`);
      // set the returned ObjectSharingRequestTracker to be the one we are editing
      setObjectSharingRequestTracker(channelSharingRequestFetched);

      // set local 'isNewObject' state to 'false' to indicate that we are editing an existing channel (default was set to 'true')
      setIsNewObject(false);
    }
  }

  // function that embedded form component is to call when submitting for a save operation
  function handleFormSubmit(channelSharingRequestToSubmit: IObjectSharingRequestTracker): Promise<void> {
    return new Promise(async (resolve, reject) => {
      try {
        displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Entered handleFormSubmit().  channelSharingRequestToSubmit.recipientEmail: ${channelSharingRequestToSubmit.recipientEmail}; channelSharingRequestToSubmit.recipientName: ${channelSharingRequestToSubmit.recipientName}. objectSharingRequestTrackerId: ${objectSharingRequestTrackerId}`);
        // dispatch an action to request saving the channel sharing request
        dispatch(objectSharingRequestTrackerSaveRequest(channelSharingRequestToSubmit));

        resolve();
      } catch (error: any) {
        reject(error);
      }
    });
  }

  displayConsoleLogs && console.log(`ManageChannelSharingRequestPage. Before 'return' call, channelSharingRequest: ${objectSharingRequestTracker === undefined ? 'undefined' : 'id: ' + objectSharingRequestTracker.id + '; Recipient email: ' + objectSharingRequestTracker.recipientEmail + '; Recipient name: ' + objectSharingRequestTracker.recipientName}. objectSharingRequestTrackerId: ${objectSharingRequestTrackerId}`);

  return (
    <GenericPageContainer
      maxWidth="sm"
      showBackButton={true}
      pageTitle={isNewObject ? PageAndViewTitleStringAssets.pageTitle_ManageChannelSharing_New : PageAndViewTitleStringAssets.pageTitle_ManageChannelSharing_Existing}
      onCloseAlert={resetSaveObjectSharingRequestTrackerStatus}
    >
      {objectSharingRequestTracker &&
        <ChannelSharingRequestForm
          channelSharingRequest={objectSharingRequestTracker}
          // saveRequestInProgress={operationInProgress}
          saveRequestInProgress={false}
          onSubmit={handleFormSubmit}
        />
      }
      {!objectSharingRequestTracker &&
        <p>No request to display</p>

      }

    </GenericPageContainer>
  )

}

export default ManageChannelSharingRequestPage;