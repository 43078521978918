/**
 * @enum enumCategoryType Represents different types of categories for organizing UI viewing of objects
 *       (eg, Channels, Topics, TopicItems)
 * (Note: Try to keep the enumerations in alphabetical order to simplify viewing.)
 */
export enum enumCategoryType {
  Unassigned = 'Unassigned',  // has not been assigned a value
  Channel = 'Channel',  // for organizing/grouping Channels in the UI
  Topic = 'Topic',  // for organizing/grouping Topics in the UI
  TopicItem = 'TopicItem',  // for organizing/grouping TopicItems in the UI
}
