import { getFunctions, httpsCallable } from "firebase/functions";
import { FirebaseAppSingleton } from '../../../cloudServices/googleFirebaseServices/FirebaseAppSingleton';
import { IDigitalMediaSearchCriteria, IDigitalMediaSearchCriteriaAsJson } from "../../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchCriteria";
import { DigitalMediaSearchResults, IDigitalMediaSearchResultItem, IDigitalMediaSearchResults, IDigitalMediaSearchResultsAsJson } from "../../../../dataObjects/models/digitalMediaSearch/digitalMediaSearchResults";

/**
 * @function searchForDigitalMediaObjects Makes request, via Http, to a function that searches for digital media
 * @returns IDigitalMediaSearchResult
 */
export function searchForDigitalMediaObjects(searchCriteria: IDigitalMediaSearchCriteria): Promise<IDigitalMediaSearchResults> {
  return new Promise<IDigitalMediaSearchResults>(async (resolve, reject) => {

    // whether to display console logs (displayConsoleLogs && console.log statements)
    const displayConsoleLogs: boolean = false;

    displayConsoleLogs && console.log(`%c Entered searchForDigitalMediaObjects(). searchCriteria: ${JSON.stringify(searchCriteria)}`, 'background: #055; color: #fff');

    // convert the search criteria to Json for passing to cloud function via Http
    const searchCriteriaAsJson: IDigitalMediaSearchCriteriaAsJson = searchCriteria.toJSON();

    displayConsoleLogs && console.log(`%c In searchForDigitalMediaObjects(). Returned from searchCriteria.toJSON(). searchCriteriaAsJson: ${JSON.stringify(searchCriteriaAsJson)}`, 'background: #055; color: #fff');

    try {
      const functions = getFunctions(FirebaseAppSingleton.getInstance().firebaseApp);
      const searchForDigitalMedia =
        httpsCallable<IDigitalMediaSearchCriteriaAsJson, IDigitalMediaSearchResultsAsJson>(functions, 'searchForDigitalMedia');
      searchForDigitalMedia(searchCriteriaAsJson)
        .then((result) => {

          displayConsoleLogs && console.log(`%c searchForDigitalMediaObjects(). Returned from searchForDigitalMediaObjects() Http call. result: ${JSON.stringify(result)}`, 'background: #055; color: #fff');
          displayConsoleLogs && console.log(`%c searchForDigitalMediaObjects(). result.data: ${JSON.stringify(result.data)}`, 'background: #055; color: #fff');
          const searchResultsFromHttpCall: IDigitalMediaSearchResultsAsJson = result.data;

          const resultsArray = searchResultsFromHttpCall.searchResults;

          let searchResultsToReturn: IDigitalMediaSearchResults;
          if (resultsArray.length === 0) {
            displayConsoleLogs && console.log(`%c searchForDigitalMediaObjects(). No search results found.`, 'background: #055; color: #fff');
            searchResultsToReturn = new DigitalMediaSearchResults([]);
          } else {
            // For some reason, the non-empty array of search results item objects that have been returned from an Http Call have the characteristic of being a
            // string instead of a pure Json object. Yet, we also need to deal with a pure Json object. Therefore, we will apply toString()
            // to the search results and then use JSON.parse() to parse it into a Json object (which, in this case, is an array of objects).
            const parsedSearchResultsArray: Array<IDigitalMediaSearchResultItem> = JSON.parse(searchResultsFromHttpCall.searchResults.toString());
            const parsedSearchResults: IDigitalMediaSearchResultsAsJson = { searchResults: parsedSearchResultsArray };
            displayConsoleLogs && console.log(`%c searchForDigitalMediaObjects(). parsedSearchResults: ${JSON.stringify(parsedSearchResults)}`, 'background: #055; color: #fff');

            searchResultsToReturn = DigitalMediaSearchResults.fromJSON(parsedSearchResults);
            displayConsoleLogs && console.log(`%c searchForDigitalMediaObjects(). searchResultsToReturn: ${JSON.stringify(searchResultsToReturn)}`, 'background: #055; color: #fff');
          }

          resolve(searchResultsToReturn);
        });
    } catch (error: any) {
      alert(`Error. searchForDigitalMediaObjects failed: (${error})`);
      reject(error);
    }

  });
}
