import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Grid, IconButton, TextField, Tooltip } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { ControlsStringAssets, MessagesStringAssets, TooltipStringAssets } from '../../../assets/stringAssets';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FormWithActionBar from '../FormWithActionBar/FormWithActionBar';
import { IUserSettings } from '../../../dataObjects/models/users/UserSettings';
import { normalizePhoneNumber } from '../../utilities';
import { styled } from '@mui/system';
import { RegularExpressions } from '../../../assets/regularExpressionAssets';
import { useNavigate } from 'react-router-dom';


/*** Using the Material UI Emotion Styling library, declare 'styled' instances for each area/object. 
 *** NOTE: These must be declared outside of the React Functional Component to ensure that the styled 
 *** objects will be properly rendered within the DOM. 
 ***/


// a styled Box (equivalent to a <div>), providing an area to display the overall Progress Bar Area
const StyledBoxForEmailTextContainer = styled((props) => (
  <Box
    {...props}
  />
))(({ theme }) => ({
  paddingRight: theme.spacing(2),
  width: "100%",
}));

// a styled TextField that is serving as a Select control
const StyledTextFieldForSelectControl = styled((props) => (
  <TextField
    select
    fullWidth
    {...props}
  />
))(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

// a style IconButton to be used for the Edit Icon buttons next to the email address
const StyledIconButton = styled((props) => (
  <IconButton
    size='small'
    {...props}
  />
))(({ theme }) => ({
  border: theme.listViewTable.detailRow.editIconButton.border,
  borderRadius: theme.shape.borderRadius,
  background: theme.listViewTable.detailRow.editIconButton.background,
  color: theme.listViewTable.detailRow.editIconButton.iconColor,
  '&:hover': {
    border: theme.listViewTable.detailRow.editIconButton.hoverBorder,
    background: theme.listViewTable.detailRow.editIconButton.hoverBackground,
    color: theme.listViewTable.detailRow.editIconButton.hoverIconColor,
  }
}));

interface IUserSettingsFormValues {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
}

// using 'yup', set up a schema for the form field values
const schema = yup.object().shape({
  firstName: yup
    .string()
    .required(ControlsStringAssets.firstNameRequired),
  lastName: yup
    .string()
    .required(ControlsStringAssets.lastNameRequired),
  email: yup
    .string()
    .email(ControlsStringAssets.emailInvalid),
  // .required(ControlsStringAssets.emailRequired),
  mobilePhone: yup
    .string()
    .matches(RegularExpressions.PhoneNumber, ControlsStringAssets.phoneNumberInvalid)
    .required(ControlsStringAssets.phoneNumberRequired),
});


/**
 * @interface IUserSettingsFormProps Properties for the UserForm component
 */
export interface IUserSettingsFormProps extends PropsWithChildren<unknown> {
  /**
   * @property {IUserSettings} userSettings The UserSettings details for the form 
   */
  userSettings: IUserSettings,
  /**
   * @property {boolean} saveRequestInProgress Whether a save request is in progress
   */
  saveRequestInProgress?: boolean,
  /**
   * @property {(userSettings: IUserSettings) => Promise<void>} onSubmit Method to call for submitting the form for a save operation
   */
  onSubmit: (userSettings: IUserSettings) => Promise<void>,
}

const UserSettingsForm: React.FC<IUserSettingsFormProps> = (props: IUserSettingsFormProps) => {
  UserSettingsForm.displayName = 'User Settings Form';

  // get required arguments from props
  const { userSettings, onSubmit } = props;

  // set up details for ReactHookForm
  const { register, formState, formState: { errors }, handleSubmit } = useForm({
    defaultValues: {
      firstName: userSettings.firstName,
      lastName: userSettings.lastName,
      email: userSettings.email,
      mobilePhone: userSettings.mobilePhone ? normalizePhoneNumber(userSettings.mobilePhone) : '',
    },
    // mode: "onBlur",
    mode: "all",
    resolver: yupResolver(schema)
  });

  const { ref: emailReg, ...emailProps } = register("email", { required: true });
  const { ref: firstNameReg, ...firstNameProps } = register("firstName", { required: true });
  const { ref: lastNameReg, ...lastNameProps } = register("lastName", { required: true });
  const { ref: mobilePhoneReg, ...mobilePhoneProps } = register("mobilePhone", { required: true });
  // const { ref: preferredLocaleReg, ...preferredLocaleProps } = register("preferredLocale", { required: true });

  // the 'useNavigate' hook will be used to navigate the browser
  const navigate = useNavigate();

  // for testing whether the form is in a valid state
  const { isValid } = formState;

  const [formIsValid, setFormIsValid] = useState<boolean>(false);


  useEffect(() => {
    setFormIsValid(isValid);
  }, [isValid]);

  // capture whether a save is currently being submitted
  const saveRequestInProgress: boolean = props.saveRequestInProgress ?? false;

  // state value indicating whether a save is in progress
  const [saveInProgress, setSaveInProgress] = useState<boolean>(saveRequestInProgress);

  // useEffect hook for setting the 'saveInProgress' local state based on whether a save is currently in progress
  useEffect(() => {
    setSaveInProgress(saveRequestInProgress);
  }, [saveRequestInProgress]);


  // handles a save/submit request from the form
  // const handleSaveSubmit = async (data: IUserSettingsFormValues) => {
  const handleSaveSubmit = async (data: any) => {

    setSaveInProgress(true);

    // fill in details for the UserSettings object passed in
    userSettings.firstName = data.firstName;
    userSettings.lastName = data.lastName;
    // userSettings.email = data.email; // this is commented out, since we don't allow user to change email address
    userSettings.mobilePhone = data.mobilePhone;

    // call the onSubmit handler passed in, supplying the UserSettings object
    await onSubmit(userSettings);
  }

  // present the form
  return (
    <>
      <FormWithActionBar
        onSubmit={handleSubmit(handleSaveSubmit)}
        actionInProgress={saveInProgress}
        actionInProgressLabel={MessagesStringAssets.userSettings_SaveRequested}
        formIsValid={formIsValid}
      >
        <TextField
          inputRef={firstNameReg}
          {...firstNameProps}
          autoFocus
          label="First Name *"
          margin='normal'
          fullWidth
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
        />

        <TextField
          inputRef={lastNameReg}
          {...lastNameProps}
          label="Last Name *"
          margin='normal'
          fullWidth
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
        />

        <Grid container flexDirection='row'>
          <Grid item container xs={11} justifySelf='stretch' alignItems='center'>
            <StyledBoxForEmailTextContainer>
              <TextField
                inputRef={emailReg}
                {...emailProps}
                label="Email (change via Edit Button)"
                disabled
                margin='normal'
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
              />
            </StyledBoxForEmailTextContainer>
          </Grid>
          <Grid item container xs={1} justifyContent='flex-end' alignItems='center' >
            <Tooltip
              title={TooltipStringAssets.changeEmailAddress}
              arrow
            >
              <span>
                <StyledIconButton
                  onClick={() => navigate(`/updateEmailAddress`)}
                >
                  <EditIcon />
                </StyledIconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>


        <TextField
          inputRef={mobilePhoneReg}
          {...mobilePhoneProps}
          type="tel"
          label="Mobile Phone *"
          margin='normal'
          fullWidth
          error={!!errors.mobilePhone}
          helperText={errors?.mobilePhone?.message}
          // we need a custom onChange handler to normalize (format) the phone number as the user is entering the digits
          onChange={(event) => {
            event.target.value = normalizePhoneNumber(event.target.value)
          }}
        />

      </FormWithActionBar>
    </>

  );
}

export default UserSettingsForm;