import { getStorage, ref, FirebaseStorage, StorageReference, getDownloadURL } from "firebase/storage";
import { FirebaseAppSingleton } from "../../cloudServices/googleFirebaseServices/FirebaseAppSingleton";
import { convertCloudStorageErrorCodeToMessage } from "../cloudStorageErrorHandling";

/**
 * @function doesCloudStorageFileExist Determines whether a cloud storage file exists, given a full pathname for the file
 * @param {string} fileFullPathname The full cloud storage file pathname for the file to be checked for existence
 * @returns {Promise<boolean>} Returns a promise with a boolean value, indicating whether file exists, providing asynchronous functionality.
 */
export function doesCloudStorageFileExist(fileFullPathname: string): Promise<boolean> {
  return new Promise<boolean>(async (resolve, reject) => {
    try {
      // get a reference to the Firebase Cloud Storage Service
      const storageService: FirebaseStorage = getStorage(FirebaseAppSingleton.getInstance().firebaseApp);

      // Create a reference to the file for which we are checking existence
      const fileRef: StorageReference = ref(storageService, fileFullPathname);

      // There is no "exists" function provided by Cloud Storage, but we can test for existence by determining whether a URL
      // exists for the full pathname
      const downloadUrl: string = await getDownloadURL(fileRef);

      // set boolean value for whether file exists in storage
      const doesFileExist: boolean = downloadUrl.length > 0;

      resolve(doesFileExist);
    } catch (error: any) {
      reject(convertCloudStorageErrorCodeToMessage(error.code));
    }
  });
}
