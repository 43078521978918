import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enumWorkflowState } from '../../../dataObjects/enums';
import { IChannel } from '../../../dataObjects/models/channels/Channel';

const initialState: enumWorkflowState | null = null;

export const channelDeleteStatusSlice = createSlice({
  name: 'channelDeleteStatus',
  initialState,
  reducers: {
    // action has a payload with IChannel
    channelDeleteRequest: (state, action: PayloadAction<IChannel>) => {
      channelDeleteStatusChange(enumWorkflowState.Requested);
    },
    // action has a payload with the new enumWorkflowState value or null
    channelDeleteStatusChange(state, action) {
      // change status of process
      return action.payload;
    },
  },
})

// Action creators are generated for each function in reducer
export const { channelDeleteRequest, channelDeleteStatusChange } = channelDeleteStatusSlice.actions;

// export the core reducer for the slice
export const channelDeleteStatusReducer = channelDeleteStatusSlice.reducer;

export default channelDeleteStatusSlice.reducer;